import { Box, Stack, Typography, useTheme } from "@mui/material";
import Button from "../../Components/MaterialUI/Button";
import { useAuthedContext } from "../../context/AuthContext";
import { useEffect, useState } from "react";
import callApi from "../../Api/callApi";
import {
  getQueryOrderNewProject,
  getQueryOrdersProjectTasks,
  getQueryOrdersScheduleTasks,
  getQueryOrdersUnScheduleTasks,
} from "../../Api/Orders/apiOrdersGetQueries";
import cssSpacingStyles from "../../Global/Styles/spacing";
import cssLayoutStyles from "../../Global/Styles/layout";
import {
  GetQueryOrdersProjectTasksSnippet,
  OrdersProject,
} from "../../Api/Orders/apiOrdersSnippets";
import ResponsiveTableGrid from "../../Components/SmallComponents/TableGrid/ResponsiveTableGrid";
import { TableGridColumnSchema } from "../../Components/SmallComponents/TableGrid/constructTableGrid";
import GanttChart from "../../Components/PageComponents/GanttChartProjects/GanttChart/GanttChart";
import LoadingBackdrop from "../../Components/MaterialUI/LoadingBackdrop";
import { GanttTask } from "../../Components/PageComponents/GanttChartProjects/GanttChart/ganttChartUtils";
import CellActionButton from "../../Components/SmallComponents/TableGrid/CellActionButton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useNavigate, useOutletContext } from "react-router-dom";
import ROUTES_MAPPING from "../../Layout/Router/routesMapping";
import LabelWithBoldedPart from "../../Components/MaterialUI/LabelWithBoldedPart";
import { formatDateAndTime } from "../../Global/Utils/commonFunctions";
import { useLanguageContext } from "../../context/LanguageContext";
import { AppRouterProps } from "../../Layout/layoutVariables";
import PageTitle from "../../Components/SmallComponents/PageTitle/PageTitle";

type TableRow = {
  name: string;
  priority: number;
  duration: number;
  needed_capacity: number;
  project_name: string;
  progress: number;
  task_id: string;
  machine_name: React.ReactNode;
  parent_name: string;
  predecessor_name: string;
};

const colSchemaTasks: TableGridColumnSchema[] = [
  { id: "name", label: "Name", type: "string" },
  { id: "priority", label: "Priority", type: "string" },
  { id: "duration", label: "Duration", type: "number" },
  { id: "needed_capacity", label: "Needed Capacity", type: "number" },
  { id: "project_name", label: "Project Name", type: "string" },
  { id: "machine_name", label: "Machine Name", type: "string" },
  { id: "parent_name", label: "Parent Name", type: "string" },
  { id: "progress", label: "Progress", type: "number" },
  { id: "predecessor_name", label: "Predecessor Name", type: "string" },
];

const OrdersPage2: React.FC = () => {
  const { t } = useLanguageContext();
  const theme = useTheme();
  const styles = { ...cssSpacingStyles(theme), ...cssLayoutStyles };
  const [tasks, setTasks] = useState<GetQueryOrdersProjectTasksSnippet>([]);
  const [project, setProject] = useState<OrdersProject>();
  const [loading, setLoading] = useState<boolean>(true);

  const [rows, setRows] = useState<TableRow[]>([]);
  const [ganttData, setGanttData] = useState<GanttTask[]>([]);
  const tasksAreNotPlanned = Boolean(tasks.length && !tasks[0]?.end_date);

  const navigate = useNavigate();
  const { setAuthedUser } = useAuthedContext();
  const { smMediaQuery, setExtraTopNavMenu } = useOutletContext<AppRouterProps>();

  useEffect(() => {
    if (smMediaQuery) {
      setExtraTopNavMenu(null);
    } else {
      setExtraTopNavMenu(<PageTitle title={t("Production Planning")} />);
    }

    return () => {
      setExtraTopNavMenu(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [smMediaQuery]);

  useEffect(() => {
    (async () => {
      try {
        const newProject = await callApi<OrdersProject>({
          query: getQueryOrderNewProject("4cfbba2e-8539-4632-96d1-f19f69d98f7d"),
          auth: { setAuthedUser },
        });

        await fetchTasks(newProject);
        setProject(newProject);
      } catch (error) {
        console.log("There was an error: ", error);
      }
      setLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTasksPlanning = async () => {
    try {
      if (!project?.id) {
        throw new Error("Project ID not found");
      }

      setLoading(true);
      if (tasksAreNotPlanned) {
        await callApi<any>({
          query: getQueryOrdersScheduleTasks(),
          auth: { setAuthedUser },
        });
        await fetchTasks(project);
      } else {
        await callApi<any>({
          query: getQueryOrdersUnScheduleTasks(),
          auth: { setAuthedUser },
        });
        await fetchTasks(project);
      }
    } catch (err) {
      console.log(err);
    }

    setLoading(false);
  };

  const fetchTasks = async (newProject: OrdersProject) => {
    const projectTasks = await callApi<GetQueryOrdersProjectTasksSnippet>({
      query: getQueryOrdersProjectTasks(newProject.id),
      auth: { setAuthedUser },
    });

    const notPlanned = Boolean(projectTasks.length && !projectTasks[0]?.end_date);

    if (notPlanned) {
      const tableRows: TableRow[] = projectTasks.map((task) => ({
        name: task.name,
        priority: task.priority,
        duration: task.duration,
        needed_capacity: task.needed_capacity,
        project_name: task.project_name,
        progress: task.progress,
        task_id: task.id,
        machine_name: (
          <CellActionButton
            text={task.machine_name}
            icon={<VisibilityIcon />}
            handleOnClick={() => {
              navigate({
                pathname: ROUTES_MAPPING["Maintenance-Machine Park"],
              });
            }}
          />
        ),
        parent_name: task.parent_name || "",
        predecessor_name: task.predecessor_name || "",
      }));

      setRows(tableRows);
      setGanttData([]);
    } else {
      if (newProject) {
        const ganttTasks: GanttTask[] = [];

        const ganttProject: GanttTask = {
          id: newProject.id,
          type: "project",
          name: newProject.name,
          start: new Date(newProject.start_date),
          end: new Date(newProject.end_date),
          progress: newProject.progress,
          hideChildren: true,
        };
        ganttTasks.push(ganttProject);

        projectTasks.forEach((task) => {
          ganttTasks.push({
            id: task.id,
            type: "task",
            name: task.name,
            start: task.start_date ? new Date(task.start_date) : new Date(),
            end: task.end_date ? new Date(task.end_date) : new Date(),
            progress: task.progress,
            project: newProject.id,
          });
        });

        setGanttData(ganttTasks);
        setTasks(projectTasks);
      }
    }
    setTasks(projectTasks);
  };

  const toggleTaskVisibility = (taskId: string) => {
    setGanttData((prevGanttData) =>
      prevGanttData.map((task) =>
        task.id === taskId ? { ...task, hideChildren: !task.hideChildren } : task
      )
    );
  };

  return (
    <Box component="div">
      <Stack
        css={[styles.width100, styles.contentBreak]}
        spacing={2}
        alignItems="center"
        justifyContent="space-between"
        direction="row"
      >
        <Box component="div" />
        {smMediaQuery ? (
          <PageTitle css={styles.labelBreak} title={t("Production Planning")} />
        ) : null}
        <Button onClick={handleTasksPlanning} disabled={!tasks.length || !project?.id}>
          {tasksAreNotPlanned ? "Plan" : "Unschedule"} Tasks
        </Button>
      </Stack>

      {!project?.id && loading ? (
        <Typography variant="h3">{t("Fetching Data...")}</Typography>
      ) : (
        <>
          {tasksAreNotPlanned ? (
            <ResponsiveTableGrid
              rows={rows}
              colSchema={colSchemaTasks}
              responsive="responsive"
              configuration={{
                density: "compact",
                initialRowsPerPage: 15,
                columnVisibility: { task_id: false, progress: false },
              }}
              tableID="OrdersPage2_table"
            />
          ) : (
            <GanttChart
              ganttChartData={ganttData}
              onExpanderClick={(task) => toggleTaskVisibility(task.id)}
              TooltipContent={({ task }) => {
                return (
                  <Stack spacing={1}>
                    <LabelWithBoldedPart variant="body2" text="Name" bolded={task.name} />
                    <LabelWithBoldedPart
                      variant="body2"
                      text="Start Date"
                      bolded={formatDateAndTime(task.start)}
                    />
                    <LabelWithBoldedPart
                      variant="body2"
                      text="End Date"
                      bolded={formatDateAndTime(task.end)}
                    />
                    <LabelWithBoldedPart
                      variant="body2"
                      text="Progress"
                      bolded={task.progress}
                    />
                    {task.other?.priority ? (
                      <LabelWithBoldedPart
                        variant="body2"
                        text="Priority"
                        bolded={task.other.priority}
                      />
                    ) : null}
                    {task.other?.duration ? (
                      <LabelWithBoldedPart
                        variant="body2"
                        text="Duration"
                        bolded={task.other.duration}
                      />
                    ) : null}
                    {task.other?.needed_capacity ? (
                      <LabelWithBoldedPart
                        variant="body2"
                        text="Needed Capacity"
                        bolded={task.other.needed_capacity}
                      />
                    ) : null}
                    <LabelWithBoldedPart
                      variant="body2"
                      text="Task ID"
                      bolded={task.id}
                    />
                    {task.other?.project_name ? (
                      <LabelWithBoldedPart
                        variant="body2"
                        text="Order"
                        bolded={task.other.project_name}
                      />
                    ) : null}
                    {task.other?.machine_name ? (
                      <LabelWithBoldedPart
                        variant="body2"
                        text="Machine"
                        bolded={task.other.machine_name}
                      />
                    ) : null}
                    {task.other?.predecessor_name ? (
                      <LabelWithBoldedPart
                        variant="body2"
                        text="Predecessor"
                        bolded={task.other.predecessor_name}
                      />
                    ) : null}
                    {task.other?.parent_name ? (
                      <LabelWithBoldedPart
                        variant="body2"
                        text="Parent"
                        bolded={task.other.parent_name}
                      />
                    ) : null}
                  </Stack>
                );
              }}
            />
          )}

          <LoadingBackdrop loading={loading} />
        </>
      )}
    </Box>
  );
};

export default OrdersPage2;
