import { gql } from "@apollo/client";

export const TIME_CHART_LIVE_DATA_SUBSCRIPTION = gql`
  subscription LiveData($input: LiveDataInput!) {
    liveData(input: $input) {
      id: parameterId
      name
      data {
        x: timestamp
        y: value
        highAlarmLimit
        highWarningLimit
        lowAlarmLimit
        lowWarningLimit
      }
    }
  }
`;

export const GAUGE_LIVE_DATA_SUBSCRIPTION = gql`
  subscription GaugeLiveData($input: ParameterInput!) {
    gaugeLiveData(input: $input) {
      value
      timestamp
      parameterId
      name
    }
  }
`;
