import { Box, Grid } from "@mui/material";
import { Formik } from "formik";
import { object } from "yup";
import { YUP_REQUIRED_STRING } from "../../../../../Global/Constants/yupConstants";
import TextField from "../../../../MaterialUI/FormFields/TextFields";
import { v4 as uuidv4 } from "uuid";
import Button from "../../../../MaterialUI/Button";
import cssLayoutStyles from "../../../../../Global/Styles/layout";
import {
  FLOW_NODE_TYPE,
  FlowAllTaskNodesData,
  FlowEnhancedNodeData,
} from "../flowNodesTypes";
import { useLanguageContext } from "../../../../../context/LanguageContext";

const fieldValidation = object({
  name: YUP_REQUIRED_STRING,
  color: YUP_REQUIRED_STRING,
});

interface FlowEnhancedFormProps {
  handleCreateSubmit?: (nodeData: FlowAllTaskNodesData) => void;
  handleEditSubmit?: (nodeData: FlowEnhancedNodeData) => void;
  data?: FlowEnhancedNodeData;
}

const FlowEnhancedForm: React.FC<FlowEnhancedFormProps> = ({
  handleCreateSubmit,
  handleEditSubmit,
  data,
}) => {
  const { t } = useLanguageContext();
  const styles = { ...cssLayoutStyles };

  const initialValues: FlowEnhancedNodeData = {
    id: data?.id || uuidv4().split("-")[0],
    createdOn: data?.createdOn || new Date().toISOString(),
    updatedOn: data?.updatedOn || new Date().toISOString(),
    name: data?.name || "",
    color: data?.color || "",
  };

  const handleFormSubmit = (values: FlowEnhancedNodeData) => {
    if (handleCreateSubmit) {
      handleCreateSubmit({
        [FLOW_NODE_TYPE.Enhanced]: values,
      });
    }
    if (handleEditSubmit) {
      handleEditSubmit(values);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleFormSubmit}
      validationSchema={fieldValidation}
    >
      {({ handleSubmit, handleChange, touched, errors, values }) => (
        <form onSubmit={handleSubmit}>
          <Grid spacing={3} container>
            <Grid item xs={12} sm={6}>
              <TextField
                name="name"
                label={t("Title")}
                error={touched["name"] && !!errors["name"]}
                helperText={touched["name"] && errors["name"]}
                onChange={handleChange}
                value={values.name}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                name="color"
                label={t("Color")}
                error={touched["color"] && !!errors["color"]}
                helperText={touched["color"] && errors["color"]}
                onChange={handleChange}
                value={values.color}
              />
            </Grid>

            <Grid item xs={12}>
              <Box component="div" css={styles.flexCenter}>
                <Button type="submit">
                  {data?.id ? t("Edit") : t("Create New")} {t("Node")}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  );
};

export default FlowEnhancedForm;
