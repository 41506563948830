import React from "react";
import Typography from "@mui/material/Typography";
import { SerializedStyles } from "@emotion/react";

interface CenteredTextProps {
  css?: SerializedStyles[] | SerializedStyles;
  className?: string;
  title: string;
}

const PageTitle: React.FC<CenteredTextProps> = ({ css, className, title }) => {
  return (
    <>
      <Typography
        css={css}
        className={className}
        variant="h2"
        align="center"
        color="textSecondary"
      >
        {title}
      </Typography>
    </>
  );
};

export default PageTitle;
