import { Box, Card, IconButton, Stack, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { FormStatuses } from "../../../../Global/Types/commonTypes";
import { useAuthedContext } from "../../../../context/AuthContext";
import callApi from "../../../../Api/callApi";
import { getExcellenceFile } from "../../../../Api/Excellence/apiExcellenceGetQueries";
import {
  GetExcellenceFileSnippet,
  PostQueryExcellenceUploadFileSnippet,
} from "../../../../Api/Excellence/apiExcellenceSnippets";
import cssSpacingStyles from "../../../../Global/Styles/spacing";
import Button from "../../../MaterialUI/Button";
import FileDropzone from "../../../SmallComponents/Dropzone/FileDropzone";
import { FileWithPath } from "react-dropzone";
import Alert from "../../../MaterialUI/Alert";
import Modal from "../../../MaterialUI/Modal";
import { PostQueryExcellenceUploadFileInput } from "../../../../Api/Excellence/apiExcellenceInputs";
import {
  deleteQueryExcellenceDashboardFile,
  postQueryExcellenceUploadFile,
} from "../../../../Api/Excellence/apiExcellencePostQueries";
import { ExcellenceUploadedFile } from "../excellenceUtils";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import DownloadIcon from "@mui/icons-material/Download";
import { useLanguageContext } from "../../../../context/LanguageContext";

interface ExcellenceUploadFileFormProps {
  handleCloseModal: () => void;
  dashboardID: string;
  setFileExists: React.Dispatch<React.SetStateAction<boolean>>;
  fileExists: boolean;
  handleFetchParams: () => Promise<void>;
}

const ExcellenceUploadFileForm: React.FC<ExcellenceUploadFileFormProps> = ({
  dashboardID,
  setFileExists,
  handleCloseModal,
  handleFetchParams,
}) => {
  const theme = useTheme();
  const styles = { ...cssSpacingStyles(theme) };

  const { t } = useLanguageContext();
  const [pageStatus, setPageStatus] = useState<FormStatuses>("loading");
  const [alertMessage, setAlertMessage] = useState<string | null>(null);
  const [uploadedFiles, setUploadedFiles] = useState<ExcellenceUploadedFile[]>([]);

  const [alertStatus, setAlertStatus] = useState<FormStatuses | null>(null);
  const [files, setFiles] = useState<FileWithPath[] | null>(null);
  const [deleteFileID, setDeleteFileID] = useState<string | null>(null);

  const { setAuthedUser } = useAuthedContext();

  useEffect(() => {
    (async () => {
      try {
        setPageStatus("loading");
        setAlertMessage("Loading...");

        const data = await callApi<GetExcellenceFileSnippet>({
          query: getExcellenceFile(dashboardID),
          auth: { setAuthedUser },
        });

        setUploadedFiles(data);
        setPageStatus("success");
        setAlertMessage(null);
      } catch (err) {
        console.log("useEffect err ", err);
        setPageStatus("error");
        setAlertMessage("Something went wrong");
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    (async () => {
      if (files?.length) {
        setAlertMessage(null);
        setAlertStatus(null);

        try {
          const nameExists = uploadedFiles.some((item) =>
            files.some((ele) => ele.name === item.name)
          );
          if (nameExists) {
            setAlertMessage("File with this name exists");
            setAlertStatus("warning");
            return;
          }
          setAlertMessage("Loading...");
          setAlertStatus("loading");

          const input: PostQueryExcellenceUploadFileInput = { files };

          const result = await callApi<PostQueryExcellenceUploadFileSnippet>({
            query: postQueryExcellenceUploadFile(dashboardID, input),
            auth: { setAuthedUser },
          });
          await handleFetchParams();

          setAlertMessage("File uploaded successfully");
          setAlertStatus("success");
          setUploadedFiles((prev) => [...prev, ...result.result.success]);
          setFileExists(true);
        } catch (error) {
          setFiles(null);
          console.log("err: ", error);
          setAlertMessage("File has not been processed - incorrect file data");
          setAlertStatus("error");
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files]);

  const handleDownloadFile = async (link: string) => {
    try {
      if (!link) {
        return;
      }
      const downloadLink = document.createElement("a");
      downloadLink.href = link;

      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    } catch (err) {
      console.log("err: ", err);
      setAlertMessage("Something went wrong");
      setAlertStatus("error");
    }
  };

  const handleConfirmDelete = async () => {
    try {
      if (!deleteFileID) {
        return;
      }
      setAlertMessage("Loading...");
      setAlertStatus("loading");

      await callApi({
        query: deleteQueryExcellenceDashboardFile(dashboardID, deleteFileID),
        auth: { setAuthedUser },
      });

      await handleFetchParams();

      setUploadedFiles((prev) => prev.filter((item) => item.id !== deleteFileID));
      setDeleteFileID(null);
      setAlertMessage(null);
      setAlertStatus(null);
      setFileExists(false);
    } catch (err) {
      console.log("err ", err);
      setAlertMessage("Something went wrong");
      setAlertStatus("error");
    }
  };

  return (
    <Box component="div">
      <Typography css={styles.contentBreak} variant="body1">
        {t(
          "Data from the uploaded files will be available for the charts in this dashboard."
        )}
      </Typography>

      {pageStatus !== "loading" ? (
        <>
          {uploadedFiles.length ? (
            <Stack css={[styles.sectionBreak, styles.leftRightPadding1]} spacing={3}>
              {uploadedFiles.map((item) => (
                <Card key={item.id}>
                  <Box component="div" css={styles.fullPadding1}>
                    <Stack direction={"row"} spacing={3} alignItems="center">
                      <Typography style={{ flex: 1 }} variant="body1">
                        {item.name}
                      </Typography>
                      <Stack spacing={2} direction="row" alignItems="center">
                        <IconButton
                          aria-label="download file"
                          onClick={() => handleDownloadFile(item.url)}
                        >
                          <DownloadIcon color="primary" />
                        </IconButton>

                        <IconButton
                          aria-label="delete file"
                          onClick={() => setDeleteFileID(item.id)}
                        >
                          <DeleteOutlineOutlinedIcon color="error" />
                        </IconButton>
                      </Stack>
                    </Stack>

                    {/* <Grid spacing={3} container>
                      <Grid item xs={9}>
                        <Typography variant="body1">{item.name}</Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <Stack spacing={2} direction="row" alignItems="center">
                          <IconButton
                            aria-label="download file"
                            onClick={() => setDeleteFileID(item.name)}
                          >
                            <DownloadIcon color="primary" />
                          </IconButton>

                          <IconButton
                            aria-label="delete file"
                            onClick={() => handleDownloadFile(item.url)}
                          >
                            <DeleteOutlineOutlinedIcon color="error" />
                          </IconButton>
                        </Stack>
                      </Grid>
                    </Grid> */}
                  </Box>
                </Card>
              ))}
            </Stack>
          ) : null}
          <Stack
            css={styles.sectionBreak}
            spacing={2}
            alignItems="center"
            justifyContent="center"
          >
            <FileDropzone
              setFiles={(files) => setFiles(files)}
              setErrorMessage={setAlertMessage}
              multiFileUpload
              accept={{
                ".xlsx, .xls, csv": [
                  "application/vnd.ms-excel",
                  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                  "text/csv",
                ],
              }}
            >
              <Button
                disabled={alertStatus === "loading"}
                color="secondary"
                loading={alertStatus === "loading"}
              >
                {t("Upload File")}
              </Button>
            </FileDropzone>

            <Typography variant="caption" color="textSecondary">
              {t("Accepted file formats")}: .xlsx, .xls, csv
            </Typography>
          </Stack>

          <Stack spacing={2} alignItems="center" justifyContent="center">
            <Alert
              css={styles.reverseContentBreak}
              message={alertMessage || ""}
              showAlert={!!alertMessage}
              severity={alertStatus}
            />

            {alertStatus === "success" ? (
              <Button onClick={handleCloseModal}>{t("Complete")}</Button>
            ) : null}
          </Stack>
        </>
      ) : (
        <Alert
          message={alertMessage || ""}
          showAlert={!!alertMessage}
          severity={pageStatus}
        />
      )}

      <Modal
        open={!!deleteFileID}
        onClose={() => setDeleteFileID(null)}
        fullWidth
        label={t("Confirm File Deletion")}
      >
        <Stack spacing={3} alignItems="center" justifyContent="center">
          <Typography variant="body1">
            {t("Selected file data will be lost for all charts in this dashboard.")}
          </Typography>

          <Button
            disabled={alertStatus === "loading"}
            color="error"
            onClick={handleConfirmDelete}
          >
            {t("Confirm Delete File")}
          </Button>

          <Alert
            message={alertMessage || ""}
            showAlert={!!alertMessage}
            severity={alertStatus}
          />
        </Stack>
      </Modal>
    </Box>
  );
};

export default ExcellenceUploadFileForm;
