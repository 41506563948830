import { SelectOption } from "../../../../Global/Types/commonTypes";
import { handleGetSelectOption } from "../../../../Global/Utils/commonFunctions";
import { BasicTableRow } from "../../../MaterialUI/BasicTable/basicTableUtils";

export const staticForms: BasicTableRow[] = [
  { id: "Manufacturing-Order", name: "Manufacturing Order Form", type: "Static" },
  {
    id: "Quality-Parameters",
    name: "Collecting and Processing Quality Parameters Form",
    type: "Static",
  },
];

interface CheckboxOptionManufacturingOrder {
  label: string;
  name: string;
  price: number;
}

export const ingredientsCheckboxes: CheckboxOptionManufacturingOrder[] = [
  {
    label: "Wood",
    price: 500,
    name: "isWoodChecked",
  },
  {
    label: "Glue",
    price: 20,
    name: "isGlueChecked",
  },
  {
    label: "Paint",
    price: 50,
    name: "isPaintChecked",
  },
];

export const operationsCheckboxes: CheckboxOptionManufacturingOrder[] = [
  {
    label: "Cutting",
    price: 20,
    name: "isCuttingChecked",
  },
  {
    label: "Gluing",
    price: 15,
    name: "isGluingChecked",
  },
  {
    label: "Assembly",
    price: 30,
    name: "isAssemblyChecked",
  },
  {
    label: "Packaging",
    price: 15,
    name: "isPackagingChecked",
  },
];

type CheckboxOptionQualityControl = {
  label: string;
  name: string;
};

export const qualityControlTestsCheckboxes: CheckboxOptionQualityControl[] = [
  { label: "pH Testing", name: "isPhTestingChecked" },
  {
    label: "Microbial Analysis",
    name: "isMicrobialAnalysisChecked",
  },
  {
    label: "Stability Testing",
    name: "isStabilityTestingChecked",
  },
];

export type ManufacturingOrderFormValues = {
  companyName: string;
  firstName: string;
  lastName: string;
  email: string;
  creatingDate: Date | null;
  productionDeadline: Date | null;
  product: string;
  isWoodChecked: boolean;
  isGlueChecked: boolean;
  isPaintChecked: boolean;
  isCuttingChecked: boolean;
  isGluingChecked: boolean;
  isAssemblyChecked: boolean;
  isPackagingChecked: boolean;
  termsAccepted: boolean;
};

export type QualityParametersFormValues = {
  productName: string;
  manufacturingingDate: Date | null;
  parameterType: string;
  isPhTestingChecked: boolean;
  isMicrobialAnalysisChecked: boolean;
  isStabilityTestingChecked: boolean;
  temperatureControl: string;
  measurementUnit: string;
  parameterValue: number | null;
  confirmationDate: Date | null;
  recommededActions: string;
};

export type CheckedItem = {
  label: string;
  price: number;
  total?: number;
  order?: number;
};

type productsScheme =
  | "Dinner table - nature"
  | "Dinner table - beige"
  | "Dinner table - black";
const productsSchemeArray: productsScheme[] = [
  "Dinner table - nature",
  "Dinner table - beige",
  "Dinner table - black",
];
export const productsSchemeOptions: SelectOption[] = handleGetSelectOption(
  productsSchemeArray,
  true
);

export type parameterTypeScheme = "Physical" | "Chemical" | "Microbiological";
const parameterTypeSchemeArray: parameterTypeScheme[] = [
  "Physical",
  "Chemical",
  "Microbiological",
];
export const parameterTypeSchemeOptions = handleGetSelectOption(
  parameterTypeSchemeArray,
  true
);

type parameterStatusScheme = "Within Specification" | "Out of Specification";
const parameterStatusSchemeArray: parameterStatusScheme[] = [
  "Within Specification",
  "Out of Specification",
];
export const parameterStatusSchemeOptions: SelectOption[] = handleGetSelectOption(
  parameterStatusSchemeArray,
  true
);

export type temperatureControlScheme = "Ambient" | "Controlled Environment";
const temperatureControlSchemeArray: temperatureControlScheme[] = [
  "Ambient",
  "Controlled Environment",
];
export const temperatureControlSchemeOptions = handleGetSelectOption(
  temperatureControlSchemeArray,
  true
);

export const measurementUnits = [
  "Colony Forming Units (CFU)",
  "Parts Per Million (ppm)",
  "Degrees Celsius (°C)",
  "Grams (g)",
  "Milliliters (ml)",
];

export const measurementUnitsByTest = {
  isPhTestingChecked: ["-"],
  isMicrobialAnalysisChecked: ["Colony Forming Units (CFU)", "Parts Per Million (ppm)"],
  isStabilityTestingChecked: ["Degrees Celsius (°C)", "Grams (g)", "Milliliters (ml)"],
};
