import useTheme from "@mui/material/styles/useTheme";
import cssSpacingStyles from "../../../../Global/Styles/spacing";
import cssFontStyles from "../../../../Global/Styles/font";
import cssComponentsStyles from "../../../../Global/Styles/components";
import { Card, Divider, Typography } from "@mui/material";
import { BasicTableColumnCell } from "../../../MaterialUI/BasicTable/basicTableUtils";
import BasicTable from "../../../MaterialUI/BasicTable/BasicTable";
import { CheckedItem } from "./staticFormsUtils";
import cssLayoutStyles from "../../../../Global/Styles/layout";
import { useLanguageContext } from "../../../../context/LanguageContext";
import { useTranslateFields } from "../../../../Global/Hooks/useTranslations";

interface OrderSummaryProps {
  checkedItems: CheckedItem[];
  totalPrice: number;
  isWithDiscount: boolean;
  delivery?: string;
}

const TABLE_COLUMNS_WITH_DISCOUNT: BasicTableColumnCell[] = [
  { id: "order", label: "#" },
  { id: "label", label: "Item" },
  { id: "price", label: "Price" },
  { id: "total", label: "Total" },
];

const TABLE_COLUMNS_NO_DISCOUNT: BasicTableColumnCell[] = [
  { id: "order", label: "#" },
  { id: "label", label: "Item" },
  { id: "price", label: "Price" },
];

const OrderSummary: React.FC<OrderSummaryProps> = ({
  checkedItems,
  totalPrice,
  isWithDiscount,
  delivery,
}) => {
  const { t } = useLanguageContext();
  const theme = useTheme();
  const styles = {
    ...cssLayoutStyles,
    ...cssSpacingStyles(theme),
    ...cssFontStyles,
    ...cssComponentsStyles(theme),
  };

  const sanitizedDelivery = delivery ? delivery.replace(/\(.*?\)/g, "").trim() : "";

  const discountThreshold = 550;
  const discountRate = 0.2;
  const finalTotalPrice =
    isWithDiscount && totalPrice >= discountThreshold
      ? totalPrice * (1 - discountRate)
      : totalPrice;

  const sortedCheckedItems = checkedItems.slice().sort((a, b) => b.price - a.price);
  const checkedItemsWithOrder = sortedCheckedItems.map((item, index) => ({
    ...item,
    order: index + 1,
    price: `${item.price.toFixed(2)}€`,
    ...(isWithDiscount && {
      total: `${(
        item.total! * (totalPrice >= discountThreshold ? 1 - discountRate : 1)
      ).toFixed(2)}€`,
    }),
  }));

  const tableColumns = isWithDiscount
    ? useTranslateFields(TABLE_COLUMNS_WITH_DISCOUNT, ["label"])
    : useTranslateFields(TABLE_COLUMNS_NO_DISCOUNT, ["label"]);

  return (
    <>
      <Typography mb={1} variant="h4" style={theme.customizedTextStyles.labelStyles}>
        {t("Order Summary")}
      </Typography>
      <BasicTable
        data={{ rows: checkedItemsWithOrder, columns: tableColumns }}
        dense={true}
        defaultOrderBy="order"
        emptyTableMessage={t("You haven't added any items yet.")}
        noPagination={true}
      />

      <Card
        elevation={0}
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          gap: 5,
        }}
        css={[styles.card, styles.fullPadding2, styles.textBreak]}
      >
        {isWithDiscount ? (
          <>
            <Typography variant="h4" width="60%">
              {totalPrice >= discountThreshold
                ? t("A 20% discount has been applied to your order.")
                : `${t("Reach a total of")} ${discountThreshold}€ ${t(
                    "for a 20% discount!"
                  )}`}
            </Typography>
          </>
        ) : null}

        {delivery && (
          <Typography width="60%">
            {t("Delivery")}: {sanitizedDelivery}
          </Typography>
        )}
        <Divider orientation="vertical" flexItem />

        <Typography
          variant="h4"
          sx={{ color: "secondary.main", paddingRight: isWithDiscount ? "40px" : "60px" }}
        >
          {t("Total")}: {`${finalTotalPrice.toFixed(2)}€`}
        </Typography>
      </Card>
    </>
  );
};

export default OrderSummary;
