import { Box, Stack, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { Node } from "reactflow";
import cssSpacingStyles from "../../../../../../Global/Styles/spacing";
import cssLayoutStyles from "../../../../../../Global/Styles/layout";
import {
  AutocompleteGroupedOption,
  AutocompleteOption,
  FormStatuses,
  SelectOption,
} from "../../../../../../Global/Types/commonTypes";
import Button from "../../../../../../Components/MaterialUI/Button";
import Alert from "../../../../../../Components/MaterialUI/Alert";
import Modal from "../../../../../../Components/MaterialUI/Modal";
import { PidWorkflowsInputNodeData } from "./pidInputNodeUtils";
import { PID_FLOW_NODE_TYPE } from "../pidNodesUtils";
import { ExcellenceParameter } from "../../../../../../GraphQL/Excellence/graphQLTypesExcellence";
import { graphQlQueryExcellenceParameters } from "../../../../../../GraphQL/Excellence/graphQLQueriesExcellence";
import { useQuery } from "@apollo/client";
import Autocomplete from "../../../../../../Components/MaterialUI/FormFields/Autocomplete";
import { useLanguageContext } from "../../../../../../context/LanguageContext";
import { ExcellenceParamMapping } from "../../../../../../Components/ExcellenceWidgets/EditExcellenceChartForms/excellenceChartFormUtils";
import AutocompleteGrouped from "../../../../../../Components/MaterialUI/FormFields/AutocompleteGrouped";
import UnitsOfMeasureDropdown from "../../../../../../Components/SmallComponents/UnitsOfMeasureDropdown/UnitsOfMeasureDropdown";
import { useAuthedContext } from "../../../../../../context/AuthContext";
import callApi from "../../../../../../Api/callApi";
import { getQueryPidSingleParam } from "../../../../../../Api/PidWorkflows/apiPidWorkflowsGetQueries";
import { getQueryPidSingleParamSnippet } from "../../../../../../Api/PidWorkflows/apiPidWorkflowsSnippets";
import TextField from "../../../../../../Components/MaterialUI/FormFields/TextFields";
import { GetQueryPidSingleParamInput } from "../../../../../../Api/PidWorkflows/apiPidWorkflowsInputs";
import { useTranslatedModalTitle } from "../../../../../../Global/Hooks/useTranslations";

const modalTitleTranslations = {
  "Add New Template": "Add New Template",
  "Condition Details": "Condition Details",
} as const;

type ModalType = keyof typeof modalTitleTranslations;

type SelectedUnit = {
  value: string;
  description: string;
  symbol: string;
};

interface PidNodeInputFormProps {
  handleCreateSubmit?: (data: PidWorkflowsInputNodeData) => void;
  handleEditSubmit?: (data: PidWorkflowsInputNodeData) => void;
  data?: PidWorkflowsInputNodeData;
  nodes: Node<PidWorkflowsInputNodeData>[];
  handleSetUnsavedChanges: (unsavedChanges: boolean) => void;
  setUnsavedChanges: React.Dispatch<React.SetStateAction<boolean>>;
  unsavedChanges: boolean;
}

const PidNodeInputForm: React.FC<PidNodeInputFormProps> = ({
  handleCreateSubmit,
  handleEditSubmit,
  data,
  setUnsavedChanges,
  unsavedChanges,
}) => {
  const { t } = useLanguageContext();
  const theme = useTheme();
  const styles = { ...cssSpacingStyles(theme), ...cssLayoutStyles };
  const [alertMessage, setAlertMessage] = useState<string | null>(null);
  const [formStatus, setFormStatus] = useState<FormStatuses>(null);

  const [connectionOptions, setConnectionOptions] = useState<SelectOption[]>([]);
  const [selectedConnection, setSelectedConnection] = useState<AutocompleteOption | null>(
    null
  );
  const [selectedParam, setSelectedParam] = useState<AutocompleteGroupedOption | null>(
    null
  );
  const [paramMapping, setParamMapping] = useState<ExcellenceParamMapping | null>(null);
  const [paramOptions, setParamOptions] = useState<AutocompleteGroupedOption[]>([]);
  const [unitOfMeasure, setUnitOfMeasure] = useState<SelectedUnit | null>(null);
  const [altName, setAltName] = useState<string>(data?.altName || "");

  const [openModal, setOpenModal] = useState<ModalType | null>(null);
  const getTranslatedModalTitle = useTranslatedModalTitle(modalTitleTranslations);
  const { data: params, loading } = useQuery<{ parameters: ExcellenceParameter[] }>(
    graphQlQueryExcellenceParameters
  );

  const { setAuthedUser } = useAuthedContext();

  const nodeType = PID_FLOW_NODE_TYPE.Input;

  console.log("connectionOptions ", connectionOptions);

  useEffect(() => {
    const parameters = params?.parameters;
    if (parameters?.length) {
      // 1. get all connections
      const connectionsRecord: Record<string, string> = {};
      parameters.forEach((item) => {
        connectionsRecord[item.container] = item.containerName;
      });

      const allConnectionOptions: SelectOption[] = Object.entries(connectionsRecord).map(
        ([key, value]) => ({
          value: key,
          description: value,
        })
      );

      // 2. get the param mapping
      const parMapping: ExcellenceParamMapping = {};
      parameters.forEach((param) => {
        if (param.id && !parMapping?.[param.id]) {
          parMapping[param.id] = {
            connection: param.container,
            paramID: param.id,
            paramName: param.name,
            connectionName: param.containerName,
            type: param.type,
          };
        }
      });

      // 3. set the selected values
      const selectedConn: AutocompleteOption | null = connectionsRecord[
        data?.dataField.container_id || ""
      ]
        ? {
            description: connectionsRecord[data!.dataField.container_id],
            value: data!.dataField.container_id,
          }
        : null;

      const selectedPar: AutocompleteGroupedOption | null = parMapping[
        data?.dataField.parameter_id || ""
      ]
        ? {
            description: data!.dataField.parameter_name,
            value: data!.dataField.parameter_id,
            groupName: connectionsRecord[data!.dataField.container_id],
          }
        : null;

      setConnectionOptions(allConnectionOptions);
      setParamMapping(parMapping);
      setSelectedConnection(selectedConn);
      setSelectedParam(selectedPar);
      setUnitOfMeasure({
        value: data?.dataField.unit_of_measure_id || "",
        description: data?.dataField.unit_of_measure_name || "",
        symbol: data?.dataField.unit_of_measure_symbol || "",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  useEffect(() => {
    if (params?.parameters && selectedConnection && paramMapping) {
      const paramOptions = handleGetParamOptions(
        params.parameters || [],
        selectedConnection
      );

      setParamOptions(paramOptions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedConnection, paramMapping]);

  const handleFormSubmit = async () => {
    try {
      setFormStatus(null);
      if (!selectedParam || !selectedConnection) {
        setAlertMessage(t("Parameter is required"));
        return;
      }
      setFormStatus("loading");
      // 1. fetch data
      const input: GetQueryPidSingleParamInput = {
        container_id: selectedConnection.value,
        parameter_id: selectedParam.value,
      };
      const resultValue = await callApi<getQueryPidSingleParamSnippet>({
        query: getQueryPidSingleParam(input),
        auth: { setAuthedUser },
      });

      const current = new Date().toISOString();
      const nodeID = uuidv4().split("-")[0];
      const result: PidWorkflowsInputNodeData = {
        id: nodeID,
        type: nodeType,
        dataField: {
          container_id: selectedConnection.value,
          node_id: nodeID,
          unit_of_measure_id: unitOfMeasure?.value || "",
          unit_of_measure_name: unitOfMeasure?.description || "",
          unit_of_measure_symbol: unitOfMeasure?.symbol || "",
          parameter_id: selectedParam.value,
          parameter_name: selectedParam.description,
          value: resultValue,
        },
        altName,
        label: "",
        createdOn: current,
        updatedOn: current,
        mode: "Edit Mode",
      };

      if (handleCreateSubmit) {
        handleCreateSubmit(result);
      }
      if (handleEditSubmit) {
        handleEditSubmit(result);
      }
      setUnsavedChanges(false);
      setFormStatus(null);
    } catch (err) {
      console.log("PidFlow err ", err);
      setAlertMessage(t("Something went wrong"));
      setFormStatus("error");
    }
  };

  const handleOnConnectionChange = (val: SelectOption) => {
    setSelectedConnection(val);
    setSelectedParam(null);
  };
  const handleUnitChange = (
    option: AutocompleteOption | null,
    isEmpty: boolean,
    symbol: string
  ) => {
    if (isEmpty) {
      setUnitOfMeasure(null);
    } else {
      setUnitOfMeasure({
        description: option?.description || "",
        value: option?.value || "",
        symbol: symbol,
      });
    }
  };

  return (
    <Stack css={[styles.textBreak, styles.width100]} spacing={3}>
      <Autocomplete
        css={styles.width100}
        label={t("Selected Connection")}
        options={connectionOptions}
        value={selectedConnection}
        handleOnChange={handleOnConnectionChange}
        disabled={loading}
      />
      <AutocompleteGrouped
        css={styles.width100}
        label={t("Parameter")}
        options={paramOptions}
        value={selectedParam}
        handleOnChange={(val: AutocompleteGroupedOption) => setSelectedParam(val)}
        disabled={loading}
      />
      <Box component="div" css={styles.width100}>
        <UnitsOfMeasureDropdown
          targetUnitValue={unitOfMeasure?.value || ""}
          handleOnUnitsChange={handleUnitChange}
        />
      </Box>

      <Box component="div" css={styles.width100}>
        <TextField
          fullWidth
          label={t("Custom Parameter Label")}
          onChange={(e) => setAltName(e.target.value)}
          value={altName}
        />
      </Box>
      <Alert
        message={alertMessage || ""}
        showAlert={!!alertMessage}
        severity={formStatus || "warning"}
      />

      <Box component="div" css={styles.flexCenter}>
        <Button
          css={[styles.width100, styles.widthLimit20]}
          onClick={handleFormSubmit}
          loading={formStatus === "loading"}
          disabled={formStatus === "loading"}
        >
          {t("Save Changes")}
        </Button>
      </Box>

      <Modal
        onClose={() => setOpenModal(null)}
        open={!!openModal}
        fullWidth
        label={openModal ? getTranslatedModalTitle(openModal) : ""}
        unsavedChanges={unsavedChanges}
        setUnsavedChanges={setUnsavedChanges}
      ></Modal>
    </Stack>
  );
};

export default PidNodeInputForm;

const handleGetParamOptions = (
  parameters: ExcellenceParameter[],
  selectedConnection: SelectOption
): AutocompleteGroupedOption[] => {
  const result =
    parameters
      .map((item) => ({
        groupName: item.container,
        value: item.id,
        description: item.name,
      }))
      .filter((node) => selectedConnection.value === node.groupName) || [];

  const resultWithGroupNames = result.map((item) => {
    return {
      ...item,
      groupName: selectedConnection.description,
    };
  });

  return resultWithGroupNames;
};
