import React, { memo, useState } from "react";
import useTheme from "@mui/material/styles/useTheme";
import { Box, Divider, Typography } from "@mui/material";
import cssLayoutStyles from "../../../../../../../Global/Styles/layout";
import cssSpacingStyles from "../../../../../../../Global/Styles/spacing";
import { useReactFlow } from "reactflow";
import AdsClickIcon from "@mui/icons-material/AdsClick";
import {
  KAN_FLOW_NODE_TYPE,
  KanbanWorkflowsGeneralNodeData,
} from "../../../../../kanbanWorkflowUtils";
import KanFlowNode from "../KanFlowNode";
import Button from "../../../../../../../Components/MaterialUI/Button";
import Modal from "../../../../../../../Components/MaterialUI/Modal";
import NodeGeneralDataModal from "./NodeGeneralDataModal";
import NodeGeneralForm from "./NodeGeneralForm";
import { useTranslatedModalTitle } from "../../../../../../../Global/Hooks/useTranslations";
import { useLanguageContext } from "../../../../../../../context/LanguageContext";

const modalTitleTranslations = {
  "Edit Node": "Edit Node",
  "Node Data": "Node Data",
} as const;

type ModalType = keyof typeof modalTitleTranslations;

interface FlowCustomNodeProps {
  id: string;
  data: KanbanWorkflowsGeneralNodeData;
}

const KanbanWorkflowGeneralNode: React.FC<FlowCustomNodeProps> = ({ id, data }) => {
  const { t } = useLanguageContext();
  const theme = useTheme();
  const styles = {
    ...cssSpacingStyles(theme),
    ...cssLayoutStyles,
  };
  const [modalType, setModalType] = useState<ModalType | null>(null);
  const getTranslatedModalTitle = useTranslatedModalTitle(modalTitleTranslations);
  const [unsavedChanges, setUnsavedChanges] = useState<boolean>(false);

  const { setNodes, getNodes } = useReactFlow();

  const handleEditSubmit = (nodeData: KanbanWorkflowsGeneralNodeData) => {
    setNodes((nds) =>
      nds.map((node) => {
        if (node.id === id) {
          return {
            ...node,
            data: nodeData,
          };
        }
        return node;
      })
    );

    setModalType(null);
  };

  const handleOpenEditModal = () => {
    setModalType("Edit Node");
  };

  const handleSetUnsavedChanges = (unsavedChanges: boolean) => {
    if (unsavedChanges) {
      setUnsavedChanges(true);
    }
  };

  return (
    <>
      <KanFlowNode
        id={id}
        handleOpenEditModal={handleOpenEditModal}
        icon={<AdsClickIcon fontSize="small" />}
        nodeType={KAN_FLOW_NODE_TYPE.General}
      >
        <Typography css={styles.textBreak} variant="h3" align="center">
          {data.name}
        </Typography>
        <Divider css={styles.textBreak} color="lightgrey" />
        <Box component="div" css={styles.flexCenter}>
          <Button variant="outlined" onClick={() => setModalType("Node Data")}>
            {t("View Data")}
          </Button>
        </Box>
      </KanFlowNode>

      <Modal
        onClose={() => setModalType(null)}
        open={!!modalType}
        fullWidth
        label={modalType ? getTranslatedModalTitle(modalType) : ""}
        unsavedChanges={unsavedChanges}
        setUnsavedChanges={setUnsavedChanges}
      >
        {modalType === "Edit Node" ? (
          <NodeGeneralForm
            handleEditSubmit={handleEditSubmit}
            data={data}
            nodes={getNodes()}
            handleSetUnsavedChanges={handleSetUnsavedChanges}
            setUnsavedChanges={setUnsavedChanges}
            unsavedChanges={unsavedChanges}
          />
        ) : null}
        {modalType === "Node Data" ? <NodeGeneralDataModal data={data} /> : null}
      </Modal>
    </>
  );
};

export default memo(KanbanWorkflowGeneralNode);
