import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DifferenceOutlinedIcon from "@mui/icons-material/DifferenceOutlined";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { IconButton, Stack, Typography } from "@mui/material";
import useTheme from "@mui/material/styles/useTheme";
import cssSpacingStyles from "../../../../Global/Styles/spacing";
import {
  WidgetGridItem,
  WidgetGridItemLayout,
} from "../../../LargeComponents/WidgetsGrid/widgetsGridUtils";
import { v4 as uuidv4 } from "uuid";
import Modal from "../../../MaterialUI/Modal";
import { useState } from "react";
import Button from "../../../MaterialUI/Button";
import { DynamicFormGridItemData } from "./dynamicFormsUtils";
import { useTranslatedModalTitle } from "../../../../Global/Hooks/useTranslations";
import { useLanguageContext } from "../../../../context/LanguageContext";

const modalTitleTranslations = {
  "Edit layout configuration": "Edit layout configuration",
  "Confirm Delete": "Confirm Delete",
  "Edit configuration": "Edit configuration",
} as const;

type ModalTitle = keyof typeof modalTitleTranslations;

interface DynamicFormItemRightMenuProps {
  widgetItem: WidgetGridItem<DynamicFormGridItemData>;
  widgetIndex: number;
  setWidgetItems: React.Dispatch<
    React.SetStateAction<WidgetGridItem<DynamicFormGridItemData>[]>
  >;
  handleCloseMenu: () => void;
}

const DynamicFormItemRightMenu: React.FC<DynamicFormItemRightMenuProps> = ({
  widgetItem,
  widgetIndex,
  setWidgetItems,
  handleCloseMenu,
}) => {
  const theme = useTheme();
  const styles = { ...cssSpacingStyles(theme) };
  const { t } = useLanguageContext();
  const getTranslatedModalTitle = useTranslatedModalTitle(modalTitleTranslations);
  const [modalTitle, setModalTitle] = useState<ModalTitle | null>(null);

  const handleEdit = () => {
    setModalTitle("Edit configuration");
  };

  const handleCopy = () => {
    const newId = uuidv4().split("-")[0];
    const position = {
      x: 0,
      y: Infinity,
    };

    const updatedLayout: WidgetGridItemLayout = Object.keys(widgetItem.layout).reduce(
      (acc, curr) => {
        return {
          ...acc,
          [curr]: {
            ...widgetItem.layout[curr as keyof WidgetGridItemLayout],
            i: newId,
            ...position,
          },
        };
      },
      {} as WidgetGridItemLayout
    );

    const newItem: WidgetGridItem<DynamicFormGridItemData> = {
      ...widgetItem,
      title: `${widgetItem.title} - Copy`,
      layout: updatedLayout,
    };

    setWidgetItems((prev) => [newItem, ...prev]);
    handleCloseMenu();
  };

  const handleRemoveWidget = () => {
    setModalTitle("Confirm Delete");
  };

  const handleConfirmRemove = () => {
    setWidgetItems((prev) => {
      const arr = [...prev];
      arr.splice(widgetIndex, 1);
      return arr;
    });
    setModalTitle(null);
    handleCloseMenu();
  };

  const handleLockWidget = () => {
    setWidgetItems((prev) => {
      const copyItems = [...prev];
      const indexItem = copyItems[widgetIndex];
      if (indexItem.layout.xl.isResizable) {
        indexItem.layout.xl.isResizable = false;
      } else {
        indexItem.layout.xl.isResizable = true;
      }

      return copyItems;
    });
    handleCloseMenu();
  };

  const handleCloseModal = () => {
    setModalTitle(null);
  };

  return (
    <>
      <Stack css={styles.leftRightPadding1} direction="row" spacing={1}>
        <Stack alignItems="center">
          <IconButton
            aria-label="edit widget configuration"
            onClick={handleEdit}
            disabled
          >
            <EditOutlinedIcon />
          </IconButton>
          <Typography variant="caption" align="center" color="textSecondary">
            {t("Edit")}
          </Typography>
        </Stack>
        <Stack alignItems="center">
          <IconButton aria-label="copy widget" onClick={handleCopy}>
            <DifferenceOutlinedIcon />
          </IconButton>
          <Typography variant="caption" align="center">
            {t("Copy")}
          </Typography>
        </Stack>
        <Stack alignItems="center">
          <IconButton
            aria-label={widgetItem.layout.xl.isResizable ? "lock" : "unlock"}
            onClick={handleLockWidget}
          >
            <>
              {!widgetItem.layout.xl.isResizable ? (
                <LockOutlinedIcon />
              ) : (
                <LockOpenOutlinedIcon />
              )}
            </>
          </IconButton>
          <Typography variant="caption" align="center">
            {!widgetItem.layout.xl.isResizable ? t("Unlock") : t("Lock")}
          </Typography>
        </Stack>
        <Stack alignItems="center">
          <IconButton aria-label="delete" onClick={handleRemoveWidget}>
            <DeleteOutlineOutlinedIcon />
          </IconButton>
          <Typography variant="caption" align="center">
            {t("Delete")}
          </Typography>
        </Stack>
      </Stack>

      <Modal
        open={!!modalTitle}
        onClose={handleCloseModal}
        fullWidth
        maxWidth={modalTitle === "Edit configuration" ? "lg" : "sm"}
        label={modalTitle ? getTranslatedModalTitle(modalTitle) : ""}
      >
        {modalTitle === "Confirm Delete" ? (
          <Stack spacing={2} justifyContent="center" alignItems="center">
            <Typography>
              {t("Clicking the below button will delete the widget")}
            </Typography>
            <Button onClick={handleConfirmRemove}>{t("Confirm Delete")}</Button>
          </Stack>
        ) : null}
      </Modal>
    </>
  );
};

export default DynamicFormItemRightMenu;
