import { IconButton, Stack, useTheme } from "@mui/material";
import cssSpacingStyles from "../../Global/Styles/spacing";
import { useOutletContext } from "react-router-dom";
import { useEffect, useState } from "react";
import { AppRouterProps } from "../../Layout/layoutVariables";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import Modal from "../../Components/MaterialUI/Modal";
import {
  ModalTitle,
  getAssetsOptions,
  getOEEMainCategories,
  oEEModalTitleTranslations,
} from "../../Components/PageComponents/OEE/oEEUtils";
import OEEAddLossForm from "../../Components/PageComponents/OEE/OEEAddLossForm";
import callApi from "../../Api/callApi";
import {
  GetQueryOEEAseetsFunctionalLocationsSnippet,
  GetQueryOEELossesSnippet,
  GetQueryOEEMainCategoriesSnippet,
  GetQueryOEEMappedCategoriesWithSubcategoriesSnippet,
} from "../../Api/OEE/apiOEESnippets";
import {
  getQueryAssetsFunctionalLocations,
  getQueryOEELosses,
  getQueryOEEMainCategories,
  getQueryOEEMappedCategoriesWithSubcategories,
} from "../../Api/OEE/apiOEEGetQueries";
import { useAuthedContext } from "../../context/AuthContext";
import {
  AutocompleteGroupedOption,
  AutocompleteOption,
} from "../../Global/Types/commonTypes";
import OEELossesTable from "../../Components/PageComponents/OEE/OEELossesTable";
import { useTranslatedModalTitle } from "../../Global/Hooks/useTranslations";
import { useLanguageContext } from "../../context/LanguageContext";
import CustomIconsTooltip from "../../Components/SmallComponents/Tooltip/CustomIconsTooltip";
import PageTitle from "../../Components/SmallComponents/PageTitle/PageTitle";

const OEELossesPage: React.FC = () => {
  const { t } = useLanguageContext();
  const theme = useTheme();
  const styles = {
    ...cssSpacingStyles(theme),
  };
  const { setAuthedUser } = useAuthedContext();
  const { smMediaQuery, setExtraRightNavMenu, setExtraTopNavMenu } =
    useOutletContext<AppRouterProps>();
  const getTranslatedModalTitle = useTranslatedModalTitle(oEEModalTitleTranslations);
  const [modalTitle, setModalTitle] = useState<ModalTitle | null>(null);
  const [assetTypeOptions, setAssetTypeOptions] = useState<AutocompleteOption[]>([]);
  const [oEEMainCategoriesOptions, setOEEMainCategoriesOptions] = useState<
    AutocompleteGroupedOption[]
  >([]);
  const [oEEMappedCategoriesWithSubcategories, setOEEMappedCategoriesWithSubcategories] =
    useState<GetQueryOEEMappedCategoriesWithSubcategoriesSnippet>([]);
  const [oEELosses, setOEELosses] = useState<GetQueryOEELossesSnippet>([]);
  const [unsavedChanges, setUnsavedChanges] = useState<boolean>(false);

  const fetchOEEData = async () => {
    try {
      const oEEAssets = await callApi<GetQueryOEEAseetsFunctionalLocationsSnippet>({
        query: getQueryAssetsFunctionalLocations,
        auth: { setAuthedUser },
      });
      setAssetTypeOptions(getAssetsOptions(oEEAssets));

      const mainCategories = await callApi<GetQueryOEEMainCategoriesSnippet>({
        query: getQueryOEEMainCategories,
        auth: { setAuthedUser },
      });

      setOEEMainCategoriesOptions(getOEEMainCategories(mainCategories));

      const categoriesWithSubcategories =
        await callApi<GetQueryOEEMappedCategoriesWithSubcategoriesSnippet>({
          query: getQueryOEEMappedCategoriesWithSubcategories,
          auth: { setAuthedUser },
        });
      setOEEMappedCategoriesWithSubcategories(categoriesWithSubcategories);

      const losses = await callApi<GetQueryOEELossesSnippet>({
        query: getQueryOEELosses,
        auth: { setAuthedUser },
      });
      setOEELosses(losses);
    } catch (error) {
      console.log("There was an error fetching assets ", error);
    }
  };

  useEffect(() => {
    fetchOEEData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (smMediaQuery) {
      setExtraTopNavMenu(null);
      setExtraRightNavMenu(null);
    } else {
      setExtraTopNavMenu(<PageTitle title={`OEE ${t("Losses")}`} />);
      setExtraRightNavMenu(<CreateNewOEELoss setModalTitle={setModalTitle} />);
    }

    return () => {
      setExtraTopNavMenu(null);
      setExtraRightNavMenu(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [smMediaQuery]);

  const handleSetUnsavedChanges = (unsavedChanges: boolean) => {
    if (unsavedChanges) {
      setUnsavedChanges(true);
    }
  };

  return (
    <>
      {smMediaQuery ? (
        <PageTitle css={styles.labelBreak} title={`OEE ${t("Losses")}`} />
      ) : null}

      <Stack spacing={2}>
        <OEELossesTable
          oEELosses={oEELosses}
          assetTypeOptions={assetTypeOptions}
          oEEMainCategoriesOptions={oEEMainCategoriesOptions}
          oEEMappedCategoriesWithSubcategories={oEEMappedCategoriesWithSubcategories}
          setModalTitle={setModalTitle}
          fetchOEEData={fetchOEEData}
        />
      </Stack>

      <Modal
        open={!!modalTitle}
        onClose={() => setModalTitle(null)}
        fullWidth
        label={modalTitle ? getTranslatedModalTitle(modalTitle) : ""}
        unsavedChanges={unsavedChanges}
        setUnsavedChanges={setUnsavedChanges}
      >
        {modalTitle === "Add Loss" ? (
          <OEEAddLossForm
            assetTypeOptions={assetTypeOptions}
            oEEMainCategoriesOptions={oEEMainCategoriesOptions}
            oEEMappedCategoriesWithSubcategories={oEEMappedCategoriesWithSubcategories}
            setModalTitle={setModalTitle}
            fetchOEEData={fetchOEEData}
            handleSetUnsavedChanges={handleSetUnsavedChanges}
            setUnsavedChanges={setUnsavedChanges}
          />
        ) : null}
      </Modal>
    </>
  );
};

export default OEELossesPage;

interface CreateNewOEELossProps {
  setModalTitle: React.Dispatch<React.SetStateAction<ModalTitle | null>>;
}

const CreateNewOEELoss: React.FC<CreateNewOEELossProps> = ({ setModalTitle }) => {
  const { t } = useLanguageContext();

  return (
    <Stack alignItems="center">
      <CustomIconsTooltip tooltipText={t("Add")}>
        <IconButton
          aria-label="create new loss"
          onClick={() => setModalTitle("Add Loss")}
        >
          <AddOutlinedIcon />
        </IconButton>
      </CustomIconsTooltip>
    </Stack>
  );
};
