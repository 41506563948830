import React, { useState, useEffect } from "react";
import { Grid, IconButton, InputAdornment, useTheme } from "@mui/material";
import TextField from "../../MaterialUI/FormFields/TextFields";
import Select from "../../MaterialUI/FormFields/Select";
import { SelectOption } from "../../../Global/Types/commonTypes";
import { handleGetSelectOption } from "../../../Global/Utils/commonFunctions";
import DatePicker from "../../MaterialUI/DateTimePickers/DatePicker";
import { formatDateAndTime } from "../../../Global/Utils/commonFunctions";
import cssLayoutStyles from "../../../Global/Styles/layout";
import CloseIcon from "@mui/icons-material/Close";
import cssComponentsStyles from "../../../Global/Styles/components";
import { GetQueryLocationsSnippet } from "../../../Api/Locations/apiLocationSnippets";
import { useLanguageContext } from "../../../context/LanguageContext";
import { useTranslateSchemeArray } from "../../../Global/Hooks/useTranslations";

type Filters = {
  locationCode: string;
  locationType: string;
  createdAt: Date | null;
  updatedAt: Date | null;
};

const INITIAL_FILTERS: Filters = {
  locationCode: "",
  locationType: "",
  createdAt: null,
  updatedAt: null,
};

const locationTypeOptions: SelectOption[] = handleGetSelectOption([
  "Warehouse",
  "Room",
  "Rack",
  "Shelf",
]);

interface LocationChartFiltersProps {
  data: GetQueryLocationsSnippet;
  setFilteredNodeIds: React.Dispatch<React.SetStateAction<string[]>>;
}

const LocationChartFilters: React.FC<LocationChartFiltersProps> = ({
  data,
  setFilteredNodeIds,
}) => {
  const { t } = useLanguageContext();
  const theme = useTheme();
  const styles = {
    ...cssLayoutStyles,
    ...cssComponentsStyles(theme),
  };
  const [filters, setFilters] = useState<Filters>(INITIAL_FILTERS);

  useEffect(() => {
    filterNodeIds();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, data]);

  const filterNodeIds = () => {
    const filteredNodeIds = data
      .filter((item) => {
        const createdAtValid =
          !filters.createdAt ||
          formatDateAndTime(item.created_at, "date") ===
            formatDateAndTime(filters.createdAt, "date");
        const updatedAtValid =
          !filters.updatedAt ||
          formatDateAndTime(item.updated_at, "date") ===
            formatDateAndTime(filters.updatedAt, "date");
        return (
          (!filters.locationCode || item.location_code.includes(filters.locationCode)) &&
          (!filters.locationType || item.location_type === filters.locationType) &&
          createdAtValid &&
          updatedAtValid
        );
      })
      .map((item) => item.id);

    if (
      filters.locationCode ||
      filters.locationType ||
      filters.createdAt ||
      filters.updatedAt
    ) {
      setFilteredNodeIds(filteredNodeIds);
    } else {
      setFilteredNodeIds([]);
    }
  };

  const handleFiltersChange = (filterType: string, value: string | Date | null) => {
    setFilters((prev) => ({ ...prev, [filterType]: value }));
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={3}>
        <TextField
          label={t("Location Code")}
          value={filters.locationCode}
          onChange={(e) => handleFiltersChange("locationCode", e.target.value)}
          InputProps={{
            endAdornment: filters.locationCode && (
              <InputAdornment position="end">
                <IconButton
                  aria-label="clear search field"
                  onClick={() => handleFiltersChange("locationCode", "")}
                  edge="end"
                >
                  <CloseIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Grid>

      <Grid item xs={3}>
        <Select
          selectOptions={useTranslateSchemeArray(locationTypeOptions)}
          value={filters.locationType}
          onChange={(e) => handleFiltersChange("locationType", e.target.value)}
          label={t("Location Type")}
          fullWidth
          InputProps={{
            endAdornment: filters.locationType && (
              <InputAdornment position="end">
                <IconButton
                  aria-label="clear location type"
                  onClick={() => handleFiltersChange("locationType", "")}
                  size="small"
                  sx={{
                    position: "absolute",
                    right: "20px",
                    zIndex: 1,
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Grid>

      <Grid item xs={3}>
        <DatePicker
          css={[styles.width100]}
          label={t("Created At")}
          onChange={(value) => handleFiltersChange("createdAt", value)}
          value={filters.createdAt}
        />
      </Grid>

      <Grid item xs={3}>
        <DatePicker
          css={[styles.width100]}
          label={t("Updated At")}
          onChange={(value) => handleFiltersChange("updatedAt", value)}
          value={filters.updatedAt}
        />
      </Grid>
    </Grid>
  );
};

export default LocationChartFilters;
