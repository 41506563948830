import React, { memo } from "react";
import useTheme from "@mui/material/styles/useTheme";
import { Divider, Typography } from "@mui/material";
import PidFlowNode from "../PidFlowNode";
import cssSpacingStyles from "../../../../../../Global/Styles/spacing";
import { PidWorkflowInitialNodeData } from "./pidInitialNodeUtils";
import { PID_FLOW_NODE_TYPE } from "../pidNodesUtils";
import { useLanguageContext } from "../../../../../../context/LanguageContext";

interface FlowCustomNodeProps {
  id: string;
  data: PidWorkflowInitialNodeData;
}

const PidWorkflowInitialNode: React.FC<FlowCustomNodeProps> = ({ id, data }) => {
  const { t } = useLanguageContext();
  const theme = useTheme();
  const styles = {
    ...cssSpacingStyles(theme),
  };
  const isViewMode = data.mode === "View Mode";

  return (
    <PidFlowNode id={id} nodeType={PID_FLOW_NODE_TYPE.initial}>
      {isViewMode ? null : (
        <>
          <Typography variant="body1" align="center">
            {`<< P&ID ${t("Chart")} >>`}
          </Typography>

          <Divider css={[styles.textBreak, styles.reverseTextBreak]} color="lightgrey" />

          <Typography variant="h2" align="center">
            {data.name}
          </Typography>
        </>
      )}
    </PidFlowNode>
  );
};

export default memo(PidWorkflowInitialNode);
