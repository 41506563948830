import React from "react";
import { Stack, Typography, Theme, useTheme } from "@mui/material";
import { format } from "date-fns";
import { css } from "@emotion/react";
import { LineAreaHeatMapScatterPlotData } from "../../../ExcellenceWidgets/EditExcellenceChartForms/excellenceChartFormUtils";
import cssLayoutStyles from "../../../../Global/Styles/layout";
import cssSpacingStyles from "../../../../Global/Styles/spacing";
import cssComponentsStyles from "../../../../Global/Styles/components";
import { DatumValue, Point } from "@nivo/line";
import { useLanguageContext } from "../../../../context/LanguageContext";

const cssStyles = (theme: Theme) => ({
  tooltipStyle: css({
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1),
    boxShadow: theme.shadows[1],
    alignItems: "flex-start",
  }),
});

interface CustomSliceTooltipProps {
  slice: {
    id: DatumValue;
    height: number;
    width: number;
    x0: number;
    x: number;
    y0: number;
    y: number;
    points: readonly Point[];
  };
  chartData: LineAreaHeatMapScatterPlotData;
  axis: "x" | "y";
}

const PredictiveMaintenanceSliceTooltip: React.FC<CustomSliceTooltipProps> = ({
  slice,
  chartData,
  axis,
}) => {
  const { t } = useLanguageContext();
  const theme = useTheme();
  const styles = {
    ...cssLayoutStyles,
    ...cssSpacingStyles(theme),
    ...cssComponentsStyles(theme),
    ...cssStyles(theme),
  };

  const upperBoundData = chartData.find((d) => d.id === "upperBound")?.data;
  const lowerBoundData = chartData.find((d) => d.id === "lowerBound")?.data;

  const hoveredDate =
    axis === "x" ? format(slice.points[0].data.x as Date, "yyyy-MM-dd") : undefined;

  const upperBound = upperBoundData?.find(
    (d) => format(new Date(d.x), "yyyy-MM-dd") === hoveredDate
  )?.y;
  const lowerBound = lowerBoundData?.find(
    (d) => format(new Date(d.x), "yyyy-MM-dd") === hoveredDate
  )?.y;

  return (
    <>
      {slice.points.map((point) => {
        return (
          <Stack
            key={point.id}
            css={[styles.card, styles.leftRightPadding2, styles.tooltipStyle]}
            gap={1}
          >
            <Typography variant="body2" color={point.serieColor}>
              {t("Prediction")}
            </Typography>
            <Stack direction="row" css={[styles.flexCenter]} gap={1}>
              <Typography variant="body2" color={theme.palette.text.secondary}>
                {format(point.data.x as Date, "MM/dd/yyyy")}:
              </Typography>
              <Typography variant="body2">{point.data.yFormatted}</Typography>
            </Stack>
            {upperBound && lowerBound ? (
              <Stack direction="row" css={[styles.flexCenter]} gap={1}>
                <Typography variant="body2" color={theme.palette.error.main}>
                  {t("Confidence Interval")}:
                </Typography>
                <Typography variant="body2">{`${upperBound} - ${lowerBound}`}</Typography>
              </Stack>
            ) : null}
          </Stack>
        );
      })}
    </>
  );
};

export default PredictiveMaintenanceSliceTooltip;
