import { Query } from "../callApi";

export const getQueryOrdersNewProject = (): Query => ({
  endpoint: "/resource_planning/detailed_planning/project-new",
  method: "GET",
});

export const getQueryOrderNewProject = (projectID: string): Query => ({
  endpoint: `/resource_planning/detailed_planning/project-new/${projectID}`,
  method: "GET",
});

export const getQueryOrdersProjectTasks = (projectID: string): Query => ({
  endpoint: `/resource_planning/detailed_planning/project/${projectID}/tasks`,
  method: "GET",
});

export const getQueryOrdersScheduleTasks = (): Query => ({
  endpoint: "/resource_planning/detailed_planning/schedule-tasks",
  method: "GET",
});

export const getQueryOrdersUnScheduleTasks = (): Query => ({
  endpoint: "/resource_planning/detailed_planning/unschedule-tasks",
  method: "GET",
});
