import add from "date-fns/add";
import { SelectOption } from "../../../Global/Types/commonTypes";
import { handleGetSelectOption } from "../../../Global/Utils/commonFunctions";

export type ExcellenceDateTimeConfigurationData = {
  dateFrom: Date | string | null;
  dateTo: Date | string | null;
  //
  liveDataPeriodNumber: string | null;
  liveDataPeriodTime: string | null;
  //
  commonPeriod: string | null;
  commonPeriodStart: Date | string | null;
  commonPeriodEnd: Date | string | null;
  commonPeriodFetchRate: string | null;
};

export type PeriodTimeSymbol = "m" | "h" | "d" | "w" | "M" | "y";

export const periodSymbolMapping: Record<PeriodTimeSymbol, string> = {
  m: "minute",
  h: "hour",
  d: "day",
  w: "week",
  M: "month",
  y: "year",
};

export const periodSymbolOptions: SelectOption[] = [
  { value: "m", description: "Minutes" },
  { value: "h", description: "Hours" },
  { value: "d", description: "Days" },
  { value: "w", description: "Weeks" },
  { value: "M", description: "Months" },
  { value: "y", description: "Years" },
];

export const periodAgoSymbolOptions: SelectOption[] = [
  { value: "now", description: "Now" },
  { value: "m", description: "Minutes ago" },
  { value: "h", description: "Hours ago" },
  { value: "d", description: "Days ago" },
  { value: "w", description: "Weeks ago" },
  { value: "M", description: "Months ago" },
  { value: "y", description: "Years ago" },
];

export const dateTimeFetchRateOptions: SelectOption[] = [
  // Seconds
  { value: `${1000 * 5}`, description: "Every 5 seconds" },
  { value: `${1000 * 15}`, description: "Every 15 seconds" },
  { value: `${1000 * 30}`, description: "Every 30 seconds" },
  // Minutes
  { value: `${1000 * 60}`, description: "Every 1 minute" },
  { value: `${1000 * 60 * 2}`, description: "Every 2 minutes" },
  { value: `${1000 * 60 * 3}`, description: "Every 3 minutes" },
  { value: `${1000 * 60 * 5}`, description: "Every 5 minutes" },
  { value: `${1000 * 60 * 10}`, description: "Every 10 minutes" },
  { value: `${1000 * 60 * 15}`, description: "Every 15 minutes" },
  { value: `${1000 * 60 * 30}`, description: "Every 30 minutes" },
  { value: `${1000 * 60 * 60}`, description: "Every 1 hour" },
];

export type DateTimeCommonPeriod =
  | "Today"
  | "Yesterday"
  | "Current week"
  | "Last week"
  | "Current month"
  | "Last month"
  | "Last quarter"
  | "Current quarter"
  | "Current year"
  | "Last year";

export const dateTimeCommonPeriodOptions: SelectOption[] = handleGetSelectOption([
  "Today",
  "Yesterday",
  "Current week",
  "Last week",
  "Current month",
  "Last month",
  "Current quarter",
  "Last quarter",
  "Current year",
  "Last year",
]);

export const dateTimeConfigCalculateDateFromTimeValue = (
  periodNumber: number,
  periodTime: string,
  startDate?: Date
): Date | null => {
  switch (periodTime) {
    case "s":
      return add(startDate || new Date(), { seconds: -periodNumber });
    case "m":
      return add(startDate || new Date(), { minutes: -periodNumber });
    case "h":
      return add(startDate || new Date(), { hours: -periodNumber });
    case "d":
      return add(startDate || new Date(), { days: -periodNumber });
    case "w":
      return add(startDate || new Date(), { weeks: -periodNumber });
    case "M":
      return add(startDate || new Date(), { months: -periodNumber });
    case "y":
      return add(startDate || new Date(), { years: -periodNumber });
    default:
      return null;
  }
};

export const getPeriodValueNumber = (
  timeSymbol: PeriodTimeSymbol | null
): SelectOption[] => {
  if (!timeSymbol) {
    return [];
  }

  let maxNumb = 0;
  switch (timeSymbol) {
    case "m": {
      maxNumb = 60;
      break;
    }
    case "h": {
      maxNumb = 24;
      break;
    }
    case "d": {
      maxNumb = 90;
      break;
    }
    case "w": {
      maxNumb = 52;
      break;
    }
    case "M": {
      maxNumb = 12;
      break;
    }
    case "y": {
      maxNumb = 3;
      break;
    }
  }

  return Array.from({ length: maxNumb }, (_, i) => i + 1).map((item) => ({
    value: `${item}`,
    description: `${item}`,
  }));
};

export function timeChartConvertToSeconds(
  liveDataPeriodNumber: string | undefined | null,
  liveDataPeriodTime: PeriodTimeSymbol | null | undefined
): number {
  if (!liveDataPeriodNumber || !liveDataPeriodTime) {
    return 10;
  }
  const periodNumber = parseInt(liveDataPeriodNumber, 10);

  if (isNaN(periodNumber) || periodNumber <= 0) {
    throw new Error("Invalid period number");
  }

  switch (liveDataPeriodTime) {
    case "m":
      return periodNumber * 60;
    case "h":
      return periodNumber * 60 * 60;
    case "d":
      return periodNumber * 60 * 60 * 24;
    case "w":
      return periodNumber * 60 * 60 * 24 * 7;
    case "M":
      return periodNumber * 60 * 60 * 24 * 30; // Approximate month
    case "y":
      return periodNumber * 60 * 60 * 24 * 365; // Approximate year
    default:
      throw new Error("Invalid period time symbol");
  }
}
