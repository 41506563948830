import { useLazyQuery } from "@apollo/client";
import { Grid, useTheme, Typography } from "@mui/material";
import { useState, useEffect } from "react";
import cssLayoutStyles from "../../../../Global/Styles/layout";
import cssSpacingStyles from "../../../../Global/Styles/spacing";
import { FormStatuses } from "../../../../Global/Types/commonTypes";
import { graphQlQueryExcellenceHeatMapData } from "../../../../GraphQL/Excellence/graphQLQueriesExcellence";
import LoadingBackdrop from "../../../MaterialUI/LoadingBackdrop";
import { DynamicGridChartOptions } from "../../../SmallComponents/DynamicGridLayout.tsx/dynamicGridUtils";
import ExcellenceChartFormWrapper from "../../EditExcellenceChartForms/ExcellenceChartFormWrapper";
import {
  ExcellenceChartFormProps,
  ChartConfigFormValuesWithTitle,
  LineAreaHeatMapScatterPlotData,
} from "../../EditExcellenceChartForms/excellenceChartFormUtils";
import Alert from "../../../MaterialUI/Alert";
import { HeatMapConfig, HeatMapDataSchema } from "./heatMapTypes";
import ExcellenceHeatmap from "../../../PageComponents/Excellence/ExcellenceCharts/ExcellenceHeatmap/ExcellenceHeatmap";
import EditHeatMapConfig from "./EditHeatMapConfig";
import EditHeatMapDataSchema from "./EditHeatMapDataSchema";
import { useLanguageContext } from "../../../../context/LanguageContext";
import useExcellenceChartUnsavedChanges from "../../../../Global/Hooks/useExcellenceChartUnsavedChanges";

interface EditHeatMapFormProps extends Omit<ExcellenceChartFormProps, "dataSchema"> {
  config: HeatMapConfig;
  data: LineAreaHeatMapScatterPlotData;
  dataSchema: HeatMapDataSchema | null;
}

const EditHeatMapForm: React.FC<EditHeatMapFormProps> = ({
  chartClass,
  config,
  dataSchema,
  data,
  widgetTitle,
  handleSaveChanges,
  parameters,
  handleSetUnsavedChanges,
}) => {
  const theme = useTheme();
  const styles = {
    ...cssLayoutStyles,
    ...cssSpacingStyles(theme),
  };
  const { t } = useLanguageContext();
  const [tabValue, setTabValue] = useState(0);

  const { updatedConfig, setUpdatedConfig, updatedSchema, setUpdatedSchema } =
    useExcellenceChartUnsavedChanges<
      ChartConfigFormValuesWithTitle<HeatMapConfig>,
      HeatMapDataSchema | null
    >({
      initialConfig: {
        title: widgetTitle,
        ...config,
      },
      initialSchema: dataSchema as HeatMapDataSchema,
      handleSetUnsavedChanges,
    });

  // tab number 0
  const [fieldAlert, setFieldAlert] = useState<string | null>(null);

  // tab number 1
  const [updatedData, setUpdatedData] = useState<LineAreaHeatMapScatterPlotData>(data);
  const [fetchStatus, setFetchStatus] = useState<FormStatuses>(null);
  const [resetChartFetch, setResetChartFetch] = useState<boolean>(false);

  const [getHeatData] = useLazyQuery(graphQlQueryExcellenceHeatMapData);

  useEffect(() => {
    (async () => {
      if (updatedSchema) {
        try {
          setFetchStatus("loading");
          const result = await getHeatData({
            variables: {
              input: updatedSchema,
            },
          });
          if (!result.data || result.error) {
            throw new Error(JSON.stringify(result));
          }
          const resultData = result.data.heatMapData.chartData;
          setUpdatedData(resultData);
          setResetChartFetch((prev) => !prev);
          setFetchStatus(null);
        } catch (err) {
          console.log("err ", err);
          setFetchStatus("error");
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatedSchema]);

  const handleSubmit = () => {
    const { title, ...otherConfig } = updatedConfig;
    const updatedChart: DynamicGridChartOptions = {
      heatMap: { config: otherConfig, data: updatedData, dataSchema: updatedSchema },
    };

    // do some field checks
    if (!title) {
      setFieldAlert(t("Title is required"));
    } else {
      // all fields checks have passed
      handleSaveChanges(updatedChart, title);
    }
  };

  return (
    <ExcellenceChartFormWrapper
      alertMessage={fieldAlert}
      handleSubmit={handleSubmit}
      tabValue={tabValue}
      setTabValue={setTabValue}
      disabledDataTab={false}
    >
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          {tabValue === 0 ? (
            <EditHeatMapConfig
              config={updatedConfig}
              setUpdatedConfig={setUpdatedConfig}
            />
          ) : null}

          {tabValue === 1 ? (
            <EditHeatMapDataSchema
              parameters={parameters?.parameters || []}
              dataSchema={updatedSchema}
              handleUpdateDataSchema={(val) => setUpdatedSchema(val)}
            />
          ) : null}

          <Alert
            css={styles.reverseContentBreak}
            message={t("Something went wrong")}
            showAlert={fetchStatus === "error"}
            severity={"error"}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <Typography css={styles.labelBreak} variant="h3">
            {updatedConfig.title}
          </Typography>
          <ExcellenceHeatmap
            css={chartClass}
            configuration={updatedConfig}
            data={updatedData}
            schema={updatedSchema}
            resetChartFetch={resetChartFetch}
            dataFetchFromParent
            parameters={parameters?.parameters}
            tempFilters={[]}
          />
        </Grid>
      </Grid>

      <LoadingBackdrop loading={fetchStatus === "loading"} />
    </ExcellenceChartFormWrapper>
  );
};

export default EditHeatMapForm;
