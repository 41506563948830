import { Box, Grid, Stack, useTheme } from "@mui/material";
import { Formik, FormikHelpers } from "formik";
import {
  ConfigureNewAssetFormValues,
  ModalTitle,
  applyCapitalizationOnOptions,
  findMappingId,
  formatAssetDateToISO,
  typeOptions,
} from "./oEEUtils";
import {
  YUP_DATE_REQUIRED,
  YUP_REQUIRED_STRING,
} from "../../../Global/Constants/yupConstants";
import { object } from "yup";
import { useState, useEffect } from "react";
import {
  AutocompleteOption,
  FormStatuses,
  AutocompleteGroupedOption,
  SelectOption,
} from "../../../Global/Types/commonTypes";
import cssLayoutStyles from "../../../Global/Styles/layout";
import cssSpacingStyles from "../../../Global/Styles/spacing";
import cssComponentsStyles from "../../../Global/Styles/components";
import Button from "../../MaterialUI/Button";
import Alert from "../../MaterialUI/Alert";
import Select from "../../MaterialUI/FormFields/Select";
import Autocomplete from "../../MaterialUI/FormFields/Autocomplete";
import AutocompleteGrouped from "../../MaterialUI/FormFields/AutocompleteGrouped";
import DatePicker from "../../MaterialUI/DateTimePickers/DatePicker";
import { GetQueryOEEMappedCategoriesWithSubcategoriesSnippet } from "../../../Api/OEE/apiOEESnippets";
import callApi from "../../../Api/callApi";
import { postQueryCreateAsset, putQueryAssets } from "../../../Api/OEE/apiOEEPostQueries";
import { useAuthedContext } from "../../../context/AuthContext";
import {
  PostQueryCreateAssetInput,
  PutQueryAssetInput,
} from "../../../Api/OEE/apiOEEInputs";
import {
  AddedAssetsToCategories,
  MainCategory,
  SubCategory,
} from "../../../Api/OEE/apiOEEDataTypes";
import { capitalizeFirstLetterOfEachWord } from "../../../Global/Utils/commonFunctions";
import { useDetectFormsUnsavedChanges } from "../../../Global/Hooks/useDetectFormsUnsavedChanges";
import { useLanguageContext } from "../../../context/LanguageContext";

const fieldValidation = object({
  assetType: YUP_REQUIRED_STRING,
  type: YUP_REQUIRED_STRING,
  mainCategory_id: YUP_REQUIRED_STRING,
  subcategory_id: YUP_REQUIRED_STRING,
  inactive_from: YUP_DATE_REQUIRED,
});

interface OEEAddAssetFormProps {
  assetTypeOptions: AutocompleteOption[];
  oEEMainCategoriesOptions: AutocompleteGroupedOption[];
  oEEMappedCategoriesWithSubcategories: GetQueryOEEMappedCategoriesWithSubcategoriesSnippet;
  fetchOEEData: () => void;
  setModalTitle: React.Dispatch<React.SetStateAction<ModalTitle | null>>;
  assetData?: AddedAssetsToCategories;
  mappingId?: string;
  subcategory?: SubCategory;
  mainCategory?: MainCategory;
  handleSetUnsavedChanges: (unsavedChanges: boolean) => void;
  setUnsavedChanges: React.Dispatch<React.SetStateAction<boolean>>;
}

const OEEAddAssetForm: React.FC<OEEAddAssetFormProps> = ({
  assetTypeOptions,
  oEEMainCategoriesOptions,
  oEEMappedCategoriesWithSubcategories,
  fetchOEEData,
  setModalTitle,
  assetData,
  subcategory,
  mainCategory,
  handleSetUnsavedChanges,
  setUnsavedChanges,
}) => {
  const { t } = useLanguageContext();
  const theme = useTheme();
  const styles = {
    ...cssLayoutStyles,
    ...cssSpacingStyles(theme),
    ...cssComponentsStyles(theme),
  };
  const { setAuthedUser } = useAuthedContext();
  const [formStatus, setFormStatus] = useState<FormStatuses>(null);
  const [alertMessage, setAlertMessage] = useState<string | null>(null);
  const [selectedSubcategories, setSelectedSubcategories] = useState<
    AutocompleteGroupedOption[]
  >([]);
  const [selectedCategories, setSelectedCategories] = useState<
    AutocompleteGroupedOption[]
  >([]);
  const [filteredSubcategories, setFilteredSubcategories] = useState<
    AutocompleteGroupedOption[]
  >([]);
  const [filteredCategories, setFilteredCategories] = useState<
    AutocompleteGroupedOption[]
  >([]);
  const [selectedTypes, setSelectedTypes] = useState<AutocompleteOption[]>([]);

  const getInitialValues = () => {
    if (assetData) {
      const mainCategory = assetData.main_categories[0];
      const subcategory = mainCategory.sub_categories[0];
      return {
        assetType: assetData.asset_id,
        type: subcategory.type,
        mainCategory_id: mainCategory.id,
        subcategory_id: subcategory.id,
      };
    } else {
      return {
        assetType: "",
        type: "",
        mainCategory_id: "",
        subcategory_id: "",
        inactive_from: null,
      };
    }
  };

  useEffect(() => {
    const transformedSubcategories = oEEMappedCategoriesWithSubcategories.flatMap(
      (category) =>
        category.subcategories.map((subcategory) => ({
          value: subcategory.id,
          groupName: category.name,
          description: subcategory.name,
          key: `${category.name}-${subcategory.id}`,
        }))
    );
    setFilteredSubcategories(transformedSubcategories);
  }, [oEEMappedCategoriesWithSubcategories]);

  useEffect(() => {
    if (selectedCategories.length > 0) {
      const filtered = oEEMappedCategoriesWithSubcategories.flatMap((category) =>
        selectedCategories.some(
          (selectedCategory) => selectedCategory.description === category.name
        )
          ? category.subcategories.map((subcategory) => ({
              value: subcategory.id,
              groupName: category.name,
              description: subcategory.name,
              key: `${category.name}-${subcategory.id}`,
            }))
          : []
      );
      setFilteredSubcategories(filtered);
    } else {
      const transformedSubcategories = oEEMappedCategoriesWithSubcategories.flatMap(
        (category) =>
          category.subcategories.map((subcategory) => ({
            value: subcategory.id,
            groupName: category.name,
            description: subcategory.name,
            key: `${category.name}-${subcategory.id}`,
          }))
      );
      setFilteredSubcategories(transformedSubcategories);
    }
  }, [selectedCategories, oEEMappedCategoriesWithSubcategories]);

  useEffect(() => {
    if (selectedTypes.length > 0) {
      const filtered = oEEMainCategoriesOptions.filter((category) =>
        selectedTypes.some((type) => type.value === category.groupName)
      );
      setFilteredCategories(filtered);
    } else {
      setFilteredCategories(oEEMainCategoriesOptions);
    }
  }, [selectedTypes, oEEMainCategoriesOptions]);

  const handleFormSubmit = async (values: ConfigureNewAssetFormValues) => {
    try {
      setFormStatus("loading");
      setAlertMessage(null);

      if (assetData && subcategory) {
        if (!values.inactive_from) {
          setFormStatus("warning");
          setAlertMessage(t("All required fields must be filled out"));
          return;
        }

        const putAssetInput: PutQueryAssetInput = {
          inactive_from: formatAssetDateToISO(values.inactive_from),
        };

        await callApi<string>({
          query: putQueryAssets(
            putAssetInput,
            assetData.asset_id,
            subcategory?.mapping_id
          ),
          auth: { setAuthedUser },
        });
      } else {
        if (!values.assetType || !values.mainCategory_id || !values.subcategory_id) {
          setFormStatus("warning");
          setAlertMessage(t("All required fields must be filled out"));
          return;
        }

        const mapping_ids = selectedSubcategories
          .map((subcategory) => {
            const mainCategory = selectedCategories.find(
              (category) => category.description === subcategory.groupName
            );
            if (mainCategory) {
              const mapping_id = findMappingId(
                oEEMappedCategoriesWithSubcategories,
                mainCategory.value,
                subcategory.value
              );
              return mapping_id;
            }
            return null;
          })
          .filter((mapping_id) => mapping_id !== null) as string[];

        const postAssetInput: PostQueryCreateAssetInput = {
          categories: mapping_ids,
        };

        await callApi<string[]>({
          query: postQueryCreateAsset(values.assetType, postAssetInput),
          auth: { setAuthedUser },
        });
      }

      setFormStatus("success");
      setUnsavedChanges(false);
      fetchOEEData();
      setModalTitle(null);
    } catch (error) {
      console.error("OEEConfigureAssetForm handleFormSubmit error:", error);
      setFormStatus("error");
      setAlertMessage(t("Something went wrong"));
    }
  };

  const handleOnSubcategoriesChange = (
    val: AutocompleteGroupedOption[],
    setFieldValue: FormikHelpers<ConfigureNewAssetFormValues>["setFieldValue"]
  ) => {
    setSelectedSubcategories(val || []);
    if (val && val.length > 0) {
      const mainCategory = oEEMainCategoriesOptions.find(
        (category) => category.description === val[0].groupName
      );
      if (
        mainCategory &&
        !selectedCategories.some(
          (category) => category.description === mainCategory.description
        )
      ) {
        setSelectedCategories([...selectedCategories, mainCategory]);
        setFieldValue(
          "mainCategory_id",
          [...selectedCategories, mainCategory].map((cat) => cat.value).join("")
        );
        const type = typeOptions.find((type) => type.value === mainCategory.groupName);
        if (type && !selectedTypes.some((t) => t.value === type.value)) {
          setSelectedTypes([...selectedTypes, type]);
          setFieldValue("type", [...selectedTypes, type].map((t) => t.value).join(""));
        }
      }
      setFieldValue("subcategory_id", val[0].value);
    } else {
      setFieldValue("subcategory_id", "");
    }
  };

  const handleOnCategoriesChange = (
    val: AutocompleteGroupedOption[],
    setFieldValue: FormikHelpers<ConfigureNewAssetFormValues>["setFieldValue"]
  ) => {
    setSelectedCategories(val || []);
    if (val.length === 0) {
      const transformedSubcategories = oEEMappedCategoriesWithSubcategories.flatMap(
        (category) =>
          category.subcategories.map((subcategory) => ({
            value: subcategory.id,
            groupName: category.name,
            description: subcategory.name,
            key: `${category.name}-${subcategory.id}`,
          }))
      );
      setFilteredSubcategories(transformedSubcategories);
      setSelectedSubcategories([]);
      setFieldValue("mainCategory_id", "");
    } else {
      const filtered = oEEMappedCategoriesWithSubcategories.flatMap((category) =>
        val.some((selectedCategory) => selectedCategory.description === category.name)
          ? category.subcategories.map((subcategory) => ({
              value: subcategory.id,
              groupName: category.name,
              description: subcategory.name,
              key: `${category.name}-${subcategory.id}`,
            }))
          : []
      );
      setFilteredSubcategories(filtered);
      const filteredSelectedSubcategories = selectedSubcategories.filter((subcategory) =>
        val.some((category) => category.description === subcategory.groupName)
      );
      setSelectedSubcategories(filteredSelectedSubcategories);
      setFieldValue("mainCategory_id", val.map((cat) => cat.value).join(""));
      setFieldValue(
        "subcategory_id",
        filteredSelectedSubcategories.map((subcat) => subcat.value).join("")
      );

      const uniqueTypes = [...new Set(val.map((category) => category.groupName))];
      if (uniqueTypes.length === 1) {
        const selectedType = typeOptions.find(
          (type) => type.description === uniqueTypes[0]
        );
        setSelectedTypes(
          selectedType
            ? [{ value: selectedType.value, description: selectedType.description }]
            : []
        );
        setFieldValue("type", selectedType ? selectedType.description : "");
      }
    }
  };

  const handleOnTypesChange = (
    val: SelectOption[],
    setFieldValue: FormikHelpers<ConfigureNewAssetFormValues>["setFieldValue"]
  ) => {
    setSelectedTypes(val || []);
    setFieldValue("type", val.map((type) => type.value).join(""));
    if (val.length > 0) {
      const filteredCategories = oEEMainCategoriesOptions.filter((category) =>
        val.some((type) => type.value === category.groupName)
      );
      setFilteredCategories(filteredCategories);
      const filteredSelectedCategories = selectedCategories.filter((category) =>
        filteredCategories.some(
          (filteredCategory) => filteredCategory.value === category.value
        )
      );
      setSelectedCategories(filteredSelectedCategories);
      setFieldValue(
        "mainCategory_id",
        filteredSelectedCategories.map((cat) => cat.value).join("")
      );
      const filteredSelectedSubcategories = selectedSubcategories.filter((subcategory) =>
        filteredSelectedCategories.some(
          (category) => category.description === subcategory.groupName
        )
      );
      setSelectedSubcategories(filteredSelectedSubcategories);
      setFieldValue(
        "subcategory_id",
        filteredSelectedSubcategories.map((subcat) => subcat.value).join("")
      );
    } else {
      setFilteredCategories(oEEMainCategoriesOptions);
      setSelectedCategories([]);
      setFilteredSubcategories([]);
      setSelectedSubcategories([]);
      setFieldValue("mainCategory_id", "");
      setFieldValue("subcategory_id", "");
    }
  };

  return (
    <Box component="div" pr={2}>
      <Formik
        initialValues={getInitialValues()}
        onSubmit={handleFormSubmit}
        validationSchema={fieldValidation}
      >
        {({
          handleSubmit,
          handleChange,
          touched,
          errors,
          values,
          setFieldValue,
          setValues,
        }) => {
          const initialValues = getInitialValues();
          useDetectFormsUnsavedChanges(initialValues, values, handleSetUnsavedChanges);

          useEffect(() => {
            if (assetData && subcategory && mainCategory) {
              setValues({
                assetType: assetData.asset_id,
                type: subcategory?.type,
                mainCategory_id: mainCategory?.id,
                subcategory_id: subcategory?.id,
              });
              setSelectedTypes([
                {
                  value: subcategory.type,
                  description: capitalizeFirstLetterOfEachWord(subcategory.type),
                },
              ]);
              setSelectedCategories([
                {
                  value: mainCategory.id,
                  description: mainCategory.name,
                  groupName: subcategory.type,
                },
              ]);
              setSelectedSubcategories([
                {
                  value: subcategory.id,
                  description: subcategory.name,
                  groupName: mainCategory.name,
                },
              ]);
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
          }, [assetData, setValues, subcategory, mainCategory]);

          return (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Select
                    selectOptions={assetTypeOptions}
                    name="assetType"
                    label={t("Select Asset")}
                    error={touched["assetType"] && !!errors["assetType"]}
                    helperText={touched["assetType"] && errors["assetType"]}
                    onChange={handleChange}
                    value={values.assetType}
                    disabled={!!assetData || formStatus === "loading"}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Autocomplete
                    css={styles.width100}
                    label={t("Type")}
                    multiple
                    options={typeOptions}
                    value={selectedTypes}
                    handleOnChange={(val) => handleOnTypesChange(val, setFieldValue)}
                    disabled={!!assetData || formStatus === "loading"}
                  />
                </Grid>

                <Grid item xs={12}>
                  <AutocompleteGrouped
                    css={styles.width100}
                    label={t("Main Category")}
                    multiple
                    options={applyCapitalizationOnOptions(filteredCategories)}
                    value={selectedCategories}
                    handleOnChange={(val) => handleOnCategoriesChange(val, setFieldValue)}
                    disabled={!!assetData || formStatus === "loading"}
                  />
                </Grid>

                <Grid item xs={12}>
                  <AutocompleteGrouped
                    css={styles.width100}
                    label={t("Subcategories")}
                    multiple
                    options={filteredSubcategories}
                    value={selectedSubcategories}
                    handleOnChange={(val) =>
                      handleOnSubcategoriesChange(val, setFieldValue)
                    }
                    disabled={!!assetData || formStatus === "loading"}
                  />
                </Grid>

                {assetData ? (
                  <Grid item xs={12}>
                    <DatePicker
                      name="inactive_from"
                      label={t("Inactive from")}
                      error={touched["inactive_from"] && !!errors["inactive_from"]}
                      helperText={touched["inactive_from"] && errors["inactive_from"]}
                      onChange={(value) => setFieldValue("inactive_from", value)}
                      value={values.inactive_from}
                      css={styles.width100}
                      disabled={formStatus === "loading"}
                    />
                  </Grid>
                ) : null}
              </Grid>

              <Stack spacing={1} justifyContent={"center"} alignItems={"center"} mt={2}>
                <Button
                  type="submit"
                  onClick={() => handleFormSubmit(values)}
                  loading={formStatus === "loading"}
                  css={[styles.width100, styles.widthLimit15]}
                >
                  {assetData ? t("Edit") : t("Add")} {t("Asset")}
                </Button>

                <Alert
                  message={alertMessage || ""}
                  showAlert={!!alertMessage}
                  severity={formStatus}
                  css={styles.reverseLabelBreak}
                />
              </Stack>
            </form>
          );
        }}
      </Formik>
    </Box>
  );
};

export default OEEAddAssetForm;
