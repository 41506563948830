import ResponsiveTableGrid from "../../Components/SmallComponents/TableGrid/ResponsiveTableGrid";
import { GetQueryMaterialsAndProductsSnippet } from "../../Api/SupplyChain/apiSupplyChainSnippets";
import { useTranslateFields } from "../../Global/Hooks/useTranslations";

interface MaterialsTableProps {
  tableData: GetQueryMaterialsAndProductsSnippet | undefined;
  loading: boolean;
}

const MaterialsTable: React.FC<MaterialsTableProps> = ({ tableData, loading }) => {
  return (
    <>
      {tableData ? (
        <ResponsiveTableGrid
          rows={tableData.rows}
          colSchema={useTranslateFields(tableData.columns, ["label"])}
          loading={loading}
          responsive="responsive"
          configuration={{
            density: "compact",
            initialRowsPerPage: 15,
            columnVisibility: { material_id: false },
          }}
          tableID="MaterialsTable_table"
        />
      ) : null}
    </>
  );
};

export default MaterialsTable;
