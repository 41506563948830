import { Query } from "../callApi";

export const getQueryUserPreference = (preference_key: string): Query => ({
  endpoint: `users/user_preferences/user-preference/${preference_key}`,
  method: "GET",
});

export const getQueryAllUserPreferences = (): Query => ({
  endpoint: `users/user_preferences/user-preferences`,
  method: "GET",
});
