import { Box, useTheme } from "@mui/material";
import cssSpacingStyles from "../../Global/Styles/spacing";
import cssLayoutStyles from "../../Global/Styles/layout";
import cssComponentsStyles from "../../Global/Styles/components";
import { useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import { graphQlQueryExcellenceParameters } from "../../GraphQL/Excellence/graphQLQueriesExcellence";
import { ExcellenceParameter } from "../../GraphQL/Excellence/graphQLTypesExcellence";
import { FormStatuses } from "../../Global/Types/commonTypes";
import callApi from "../../Api/callApi";
import { GetQueryExcellenceFullDashboardSnippet } from "../../Api/Excellence/apiExcellenceSnippets";
import { getQueryExcellenceFullDashboard } from "../../Api/Excellence/apiExcellenceGetQueries";
import { useAuthedContext } from "../../context/AuthContext";
import {
  ExcellenceGridItemData,
  excellenceHandlePrepareWidgetsData,
} from "../../Components/PageComponents/Excellence/excellenceUtils";
import { WidgetGridItem } from "../../Components/LargeComponents/WidgetsGrid/widgetsGridUtils";
import Alert from "../../Components/MaterialUI/Alert";
import WidgetsGridLayout from "../../Components/LargeComponents/WidgetsGrid/WidgetsGridLayout";
import ExcellenceChartItem from "../../Components/PageComponents/Excellence/ExcellenceChartItem";
import { ErrorBoundary } from "react-error-boundary";
import { useLanguageContext } from "../../context/LanguageContext";
import { AppRouterProps } from "../../Layout/layoutVariables";
import PageTitle from "../../Components/SmallComponents/PageTitle/PageTitle";
import { useOutletContext } from "react-router-dom";

const EXCELLENCE_DASHBOARD_ID = "09e81377-fd34-44f2-8f41-ce0aa62fbebe";

const MaintenanceKPIsPage: React.FC = () => {
  const { t } = useLanguageContext();
  const theme = useTheme();
  const styles = {
    ...cssSpacingStyles(theme),
    ...cssLayoutStyles,
    ...cssComponentsStyles(theme),
  };

  const [pageStatus, setPageStatus] = useState<FormStatuses>(null); // initial loading
  const [alertMessage, setAlertMessage] = useState<string | null>(null);
  const [title, setTitle] = useState<string | null>(null);
  const [widgetItems, setWidgetItems] = useState<
    WidgetGridItem<ExcellenceGridItemData>[]
  >([]);
  const [parameters, setParameters] = useState<ExcellenceParameter[]>([]);

  const [getParamsData] = useLazyQuery<{ parameters: ExcellenceParameter[] }>(
    graphQlQueryExcellenceParameters
  );
  const { setAuthedUser } = useAuthedContext();
  const { smMediaQuery, setExtraTopNavMenu } = useOutletContext<AppRouterProps>();

  useEffect(() => {
    (async () => {
      try {
        setPageStatus("loading");
        setAlertMessage(t("Loading..."));
        const data = await callApi<GetQueryExcellenceFullDashboardSnippet>({
          query: getQueryExcellenceFullDashboard(EXCELLENCE_DASHBOARD_ID),
          auth: { setAuthedUser },
        });

        await handleFetchParams();

        const items = excellenceHandlePrepareWidgetsData(data.widgets);
        setWidgetItems(items);
        setTitle(data.name);
        setPageStatus("success");
        setAlertMessage(null);
      } catch (err) {
        console.log("useEffect on mount err ", err);
        setPageStatus("error");
        setAlertMessage(t("Something went wrong"));
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (smMediaQuery) {
      setExtraTopNavMenu(null);
    } else {
      setExtraTopNavMenu(<PageTitle title={title || ""} />);
    }

    return () => {
      setExtraTopNavMenu(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [smMediaQuery]);

  const handleFetchParams = async () => {
    try {
      const paramsResult = await getParamsData();
      if (!paramsResult.data || paramsResult.error) {
        throw new Error(JSON.stringify(paramsResult));
      }
      const allParams = paramsResult.data.parameters;
      setParameters(allParams);
    } catch (err) {
      console.log("handleFetchParams err ", err);
    }
  };

  return (
    <>
      <Alert
        css={[styles.widthLimit25]}
        message={alertMessage}
        showAlert={false}
        severity={pageStatus}
      />

      {title && smMediaQuery ? <PageTitle css={styles.labelBreak} title={title} /> : null}

      <WidgetsGridLayout
        dataItems={widgetItems}
        updateDataItems={() => {}}
        rowHeight={110}
        isStatic
        render={(item) => (
          <ErrorBoundary
            FallbackComponent={() => (
              <Box component="div">
                <Alert severity="error" message={t("Something went wrong")} showAlert />
              </Box>
            )}
          >
            <ExcellenceChartItem
              chart={item.widget.chart}
              title={item.title}
              widgetLoading={item.loading}
              parameters={parameters}
              timeParameters={parameters}
              widgetID={item.layout.lg.i}
              tempFilters={[]}
              refetchOnFilters={false}
            />
          </ErrorBoundary>
        )}
      />
    </>
  );
};

export default MaintenanceKPIsPage;
