import { Box, IconButton, Stack, Tab, Tabs, Typography, useTheme } from "@mui/material";
import cssLayoutStyles from "../../../Global/Styles/layout";
import cssSpacingStyles from "../../../Global/Styles/spacing";
import { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import LoadingBackdrop from "../../../Components/MaterialUI/LoadingBackdrop";
import { FormStatuses } from "../../../Global/Types/commonTypes";
import { AppRouterProps } from "../../../Layout/layoutVariables";
import InvoiceRightMenu from "../../../Components/PageComponents/Finance/Invoice/InvoiceRightMenu";
import { Invoice, InvoiceType } from "../../../Components/PageComponents/Finance/Invoice/InvoiceTypes";
import callApi from "../../../Api/callApi";
import { getQueryInvoices } from "../API/apiFinanceGetQueries";
import { TableGridConfiguration } from "../../../Components/SmallComponents/TableGrid/tableGridUtils";
import { TableGridColumnSchema } from "../../../Components/SmallComponents/TableGrid/constructTableGrid";
import { useAuthedContext } from "../../../context/AuthContext";
import ResponsiveTableGrid from "../../../Components/SmallComponents/TableGrid/ResponsiveTableGrid";
import { ModalLayoutData } from "../../../Components/SmallComponents/TableGrid/CellActionButton";
import { formatNumber } from "../../../Global/Utils/commonFunctions";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useTranslateFields } from "../../../Global/Hooks/useTranslations";
import Modal from "../../../Components/MaterialUI/Modal";
import InvoiceViewInfo from "../../../Components/PageComponents/Finance/Invoice/InvoiceViewInfo";
import EditInvoice from "../../../Components/PageComponents/Finance/Invoice/EditInvoice";
import Button from "../../../Components/MaterialUI/Button";
import { deleteQueryInvoice } from "../API/apiFinancePostQueries";
import { useLanguageContext } from "../../../context/LanguageContext";

type TableResponse = {
  configuration: TableGridConfiguration,
  columns: TableGridColumnSchema[],
  rows: Invoice[]
}

type TabType = "ingoing" | "outgoing";

const InvoicePage: React.FC<any> = ({
}) => {
  const { t } = useLanguageContext();
  const { setAuthedUser } = useAuthedContext();
  const theme = useTheme();
  const styles = {
    ...cssLayoutStyles,
    ...cssSpacingStyles(theme),
  };

  const [pageStatus, setPageStatus] = useState<FormStatuses>(null);
  const [openInfo, setOpenInfo] = useState<boolean>(false);
  const [modalData, setModalData] = useState<ModalLayoutData | null>(null);
  const [tabView, setTabView] = useState<TabType>("ingoing");
  const { smMediaQuery, setExtraRightNavMenu } = useOutletContext<AppRouterProps>();
  const [refreshTable, setRefreshTable] = useState<boolean>(false);
  const [invoicesTable, setInvoicesTable] = useState<TableResponse>();

  const formattedRows = handleFormatRows(setOpenInfo, setModalData, invoicesTable?.rows);

  useEffect(() => {
    setPageStatus("loading");
    fetchInvoices();
    setPageStatus(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabView, refreshTable]);

  useEffect(() => {
    setPageStatus(null); // remove it
    if (smMediaQuery) {
      setExtraRightNavMenu(null);
    } else {
      setExtraRightNavMenu(<InvoiceRightMenu setRefreshTable={setRefreshTable} tabView={tabView} />);
    }

    return () => {
      setExtraRightNavMenu(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [smMediaQuery, tabView]);

  const fetchInvoices = async () => {
    try {
      const invoiceType: InvoiceType = { invoice_type: tabView === "ingoing" ? "ingoing" : "outgoing" };
      const invoicesTable: TableResponse = await callApi<any>({
        query: getQueryInvoices(invoiceType),
        auth: { setAuthedUser },
      });

      invoicesTable.columns.push({ id: "actions", label: "Actions", type: "button" });
      setInvoicesTable(invoicesTable);
    } catch (error) {
      console.log(error)
    }
  };

  const handleDeleteInvoice = async (id: string) => {
    try {
      await callApi({
        query: deleteQueryInvoice(id),
        auth: { setAuthedUser },
      });
      fetchInvoices();
    } catch (error) {
      console.log("Invoice delete error: ", error.message);
    } finally {
      setOpenInfo(false);
    }
  };

  return (
    <>
      <Stack alignItems={"center"} justifyContent="center">
        <Box sx={{ width: { xs: "100%", md: "auto" } }} component="div">
          <Tabs
            css={styles.contentBreak}
            sx={{ overflowX: "auto" }}
            value={tabView}
            onChange={(_: any, val: TabType) => setTabView(val)}
            aria-label="configure tabs"
            variant="scrollable"
            scrollButtons="auto"
          >
            <Tab value="ingoing" label="Incoming Invoices" />
            <Tab value="outgoing" label="Outgoing Invoices" />
          </Tabs>
        </Box>
      </Stack>

      {pageStatus === "loading" ? (
        <Box css={styles.width100} sx={{ height: "10rem" }} component="div">
          <LoadingBackdrop loading={pageStatus === "loading"} />
        </Box>
      ) : (
        invoicesTable &&
        <ResponsiveTableGrid
          rows={formattedRows || []}
          colSchema={useTranslateFields(invoicesTable.columns || [], ["label"])}
          editMode={false}
          responsive="desktop"
          configuration={invoicesTable.configuration}
          tableID="IngoingInvoice_modal_table"
        />
      )}

      {modalData ? (
        <Modal
          open={openInfo}
          fullWidth
          label={getModalLabel(modalData)}
          onClose={() => setOpenInfo(false)}
        >
          {modalData && modalData.type === "viewInvoiceInfo" ? (
            <InvoiceViewInfo invoice={modalData.data} />
          ) : null}
          {modalData && modalData.type === "invoiceForm" ? (
            <EditInvoice invoice={modalData.data} onSubmit={() => { setOpenInfo(false) }} />
          ) : null}
          {modalData && modalData.type === "deleteForm" ? (
            <Stack spacing={2}>
              <Typography variant="body1">
                {t("Are you sure you want to delete this Invoice document?")}
              </Typography>

              <Stack direction="row" justifyContent="center" alignItems="center" gap={1}>
                <Button
                  css={[styles.width100, styles.widthLimit10]}
                  onClick={() => setOpenInfo(false)}
                >
                  {t("Cancel")}
                </Button>
                <Button
                  css={[styles.width100, styles.widthLimit10]}
                  onClick={() => handleDeleteInvoice(modalData.data)}
                  color="error"
                >
                  {t("Confirm Deletion")}
                </Button>
              </Stack>
            </Stack>
          ) : null}
        </Modal>
      ) : null}
    </>
  );
};

export default InvoicePage;

const handleFormatRows = (
  setOpenInfo: React.Dispatch<React.SetStateAction<boolean>>,
  setModalData: React.Dispatch<React.SetStateAction<ModalLayoutData | null>>,
  invoiceRows: Invoice[] | undefined
): Invoice[] => {
  if (!invoiceRows) return [];

  const formatedRows: Invoice[] = [];
  if (invoiceRows) {
    for (let i = 0; i < invoiceRows.length; i++) {
      const invoice = invoiceRows[i];
      formatedRows.push({
        currency: invoice.currency,
        customer_name: invoice.customer_name,
        customer_vat_number: invoice.customer_vat_number,
        date: invoice.date,
        discount: formatNumber(invoice.discount, true),
        due_date: invoice.due_date,
        invoice_number: invoice.invoice_number,
        net_total: formatNumber(invoice.net_total, true),
        protocol_id: invoice.protocol_id,
        purchase_order_id: invoice.purchase_order_id,
        quantity: invoice.quantity,
        sales_order_id: invoice.sales_order_id,
        status: invoice.status,
        supplier_name: invoice.supplier_name,
        supplier_vat_number: invoice.supplier_vat_number,
        total_excise_amount: formatNumber(invoice.total_excise_amount, true),
        unit_price: formatNumber(invoice.unit_price, true),
        vat_amount: formatNumber(invoice.vat_amount, true),
        actions: (
          <Stack direction="row" justifyContent="flex-end" alignItems="center">
            <IconButton
              onClick={() => {
                setOpenInfo(true);
                setModalData({ type: "viewInvoiceInfo", data: invoice });
              }}
            >
              <VisibilityIcon />
            </IconButton>
            <IconButton
              onClick={() => {
                setOpenInfo(true);
                setModalData({ type: "invoiceForm", data: invoice });
              }}
            >
              <EditIcon />
            </IconButton>
            <IconButton
              onClick={() => {
                setOpenInfo(true);
                setModalData({ type: "deleteForm", data: invoice._id });
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Stack>
        ),
      });
    }
  }
  return formatedRows;
};

const getModalLabel = (modalData: ModalLayoutData | null): string => {
  const { t } = useLanguageContext();

  if (!modalData) return "";

  switch (modalData.type) {
    case "viewInvoiceInfo":
      return t("View Invoice Information");
    case "invoiceForm":
      return t("Edit Invoice");
    case "deleteForm":
      return t("Delete Invoice");
    default:
      return "";
  }
};
