import { Query } from "../callApi";
import { PostQueryUserPreferenceInput } from "./apiUserPreferencesInputs";

export const postQueryUserPreference = (input: PostQueryUserPreferenceInput): Query => ({
  endpoint: `users/user_preferences/user-preference`,
  method: "POST",
  variables: input,
});

export const deleteQueryUserPreference = (preference_key: string): Query => ({
  endpoint: `users/user_preferences/user-preference/${preference_key}`,
  method: "DELETE",
});
