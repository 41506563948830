import { Box, IconButton, Stack, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import callApi from "../../Api/callApi";
import { useAuthedContext } from "../../context/AuthContext";
import { FormStatuses } from "../../Global/Types/commonTypes";
import { getQueryElpromOrderMaterials } from "./API/apiElpromGetQueries";
import Alert from "../../Components/MaterialUI/Alert";
import cssFontStyles from "../../Global/Styles/font";
import cssLayoutStyles from "../../Global/Styles/layout";
import cssSpacingStyles from "../../Global/Styles/spacing";
import LoadingBackdrop from "../../Components/MaterialUI/LoadingBackdrop";
import { useLanguageContext } from "../../context/LanguageContext";
import { ElpromTableData, ElpromTableDataCols } from "./API/apiElpromSnippets";
import {
  calcTableFooterAggregations,
  TableAggregationFns,
  TableGridColumnSchema,
} from "../../Components/SmallComponents/TableGrid/constructTableGrid";
import ResponsiveTableGrid from "../../Components/SmallComponents/TableGrid/ResponsiveTableGrid";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import ROUTES_MAPPING from "../../Layout/Router/routesMapping";

type TableData = {
  cols: TableGridColumnSchema[];
  rows: Record<string, any>[];
};
const TABLE_HEIGHT = 1150;
const INITIAL_ROWS = 25;

const ElpromClientOrderPage: React.FC = () => {
  const { t } = useLanguageContext();
  const theme = useTheme();
  const styles = { ...cssSpacingStyles(theme), ...cssLayoutStyles, ...cssFontStyles };
  const [pageStatus, setPageStatus] = useState<FormStatuses>(null);
  const [alertMessage, setAlertMessage] = useState<string | null>(null);
  const [data, setData] = useState<TableData | null>(null);

  const { id } = useParams();
  const { setAuthedUser } = useAuthedContext();
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      try {
        if (!id) {
          return;
        }
        setPageStatus("loading");

        const result = await callApi<ElpromTableData>({
          query: getQueryElpromOrderMaterials(id),
          auth: { setAuthedUser },
        });

        setData({
          cols: handleColsData(result.columns, result.rows),
          rows: result.rows,
        });
        setPageStatus(null);
        setAlertMessage(null);
      } catch (err) {
        console.log("err ", err);
        setPageStatus("error");
        setAlertMessage(t("Something went wrong"));
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <Box component="div">
      <Stack
        mb={3}
        spacing={2}
        justifyContent="space-between"
        alignItems="center"
        direction="row"
      >
        <div />
        <Typography css={styles.contentBreak} variant="h2" textAlign="center">
          {t("Materials for Client Order")}
        </Typography>
        <Stack spacing={0.5} alignItems="flex-end" justifyContent="flex-end">
          <IconButton onClick={() => navigate(`${ROUTES_MAPPING["projectHubData"]}`)}>
            <KeyboardBackspaceIcon />
          </IconButton>
          <Typography variant="body2" color="textSecondary" align="right">
            {`${t("Client Order")}: ${id}`}
          </Typography>
        </Stack>
      </Stack>

      <Alert
        css={[styles.widthLimit20, styles.contentBreak]}
        message={alertMessage || ""}
        showAlert={!!alertMessage}
        severity={pageStatus}
      />

      <LoadingBackdrop loading={pageStatus === "loading"} />

      {data ? (
        <ResponsiveTableGrid
          rows={data.rows}
          colSchema={data.cols}
          responsive="desktop"
          configuration={{
            density: "compact",
            columnVisibility: { id: false },
            enableStickyHeader: true,
            grouping: ["category"],
            initialRowsPerPage: INITIAL_ROWS,
            maxTableHeight: TABLE_HEIGHT,
          }}
          tableID="ElpromClientOrderPage_table"
          virtualizedColsNumber={10}
        />
      ) : null}
    </Box>
  );
};

export default ElpromClientOrderPage;

const aggregations = [TableAggregationFns.sum];
const handleColsData = (
  cols: ElpromTableDataCols[],
  rows: Record<string, any>[]
): TableGridColumnSchema[] => {
  const result: TableGridColumnSchema[] = cols.map((item) => {
    const isNumb = item.type === "number";

    return {
      id: item.id,
      label: item.name,
      type: item.type,
      ...(item.id === "category" && { width: 230 }),
      formatNumb: true,
      aggregationFn: isNumb ? aggregations : undefined,
      footerAggregations: isNumb
        ? calcTableFooterAggregations(
            aggregations,
            rows.map((row) => row[item.id]),
            item.decimal_points
          )
        : undefined,
      alignCol: item.alignment,
      formatNumbDecimals: item.decimal_points,
      symbol: {
        align: item.symbol_alignment === "before" ? "left" : "right",
        value: item.symbol || "",
      },
    };
  });

  return result as TableGridColumnSchema[];
};
