import { useCallback, useEffect, useState } from "react";
import MultiStageForm from "../../../../SmallComponents/MultiStageForm/MultiStageForm";
import { FormStatuses } from "../../../../../Global/Types/commonTypes";
import {
  UAConnectionTree,
  UaSubscription,
} from "../../../../../Api/DataSources/apiDSDataTypes";
import UaConnectionParameters from "./UaConnectionParameters";
import { UaConnectionParametersData, UaSecurity } from "./uaConnectionTypes";
import { uaChecksConnectToServer, uaHandleConnectToServer } from "./uaConnectionUtils";
import { useAuthedContext } from "../../../../../context/AuthContext";
import UaNodesStage from "./UaNodesStage";
import UaDataSnapshot from "./UaDataSnapshot";
import callApi from "../../../../../Api/callApi";
import {
  postQueryUAEditSubscription,
  postQueryUAStartSubscription,
} from "../../../../../Api/DataSources/apiDSPostQueries";
import {
  PostQueryUAEditSubscriptionInput,
  PostQueryUAStartSubscriptionInput,
} from "../../../../../Api/DataSources/apiDSInputs";
import { useNavigate } from "react-router-dom";
import UaFileUploadStage from "./UaFileUploadStage";
import { useLanguageContext } from "../../../../../context/LanguageContext";
import { useTranslateArray } from "../../../../../Global/Hooks/useTranslations";

const EMPTY_SUB_DATA: UaSubscription = {
  id: "",
  name: "",
  user_id: null,
  nodes: [],
  status: "active",
  start_time: "",
  end_time: "",
  update_rate: 30000,
  server_url: "opc.tcp://giant-integrator.com:53530/OPCUA/SimulationServer",
};

const STAGES = ["Connection Parameters", "Data Nodes", "Data Overview"];

interface UaConnectionProps {
  handleStartOver?: () => void;
  editSubForm?: {
    sub: UaSubscription;
  };
  onSuccessUrl: string;
  title: string;
}

const UaConnection: React.FC<UaConnectionProps> = ({
  handleStartOver,
  editSubForm,
  onSuccessUrl,
  title,
}) => {
  const { t } = useLanguageContext();
  const [alertMessage, setAlertMessage] = useState<string | null>(null);
  const [alertStatus, setAlertStatus] = useState<FormStatuses>(null);
  const [activeStep, setActiveStep] = useState<number>(0);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState<boolean>(false);

  // activeStep === 1
  const [sub, setSub] = useState<UaSubscription>(editSubForm?.sub || EMPTY_SUB_DATA);
  const [security, setSecurity] = useState<UaSecurity | null>(null);
  const [isFileUploadView, setIsFileUploadView] = useState<boolean>(false);

  // activeStep === 2
  const [selectedNodes, setSelectedNodes] = useState<string[]>(
    () => editSubForm?.sub?.nodes.map((node) => node.node_id) || []
  );
  // ua tree view
  const [uaTree, setUaTree] = useState<UAConnectionTree | null>(null);
  // file upload view
  const [fileIsUploaded, setFileIsUploaded] = useState<boolean>(false);
  const [fileSelectedNodes, setFileSelectedNodes] = useState<string[]>([]);
  // initial values
  const [initialSub, setInitialSub] = useState<UaSubscription>(sub);
  const [initialSelectedNodes, setInitialSelectedNodes] =
    useState<string[]>(selectedNodes);

  const { setAuthedUser } = useAuthedContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (fileIsUploaded) {
      setAlertStatus(null);
      setAlertMessage(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileIsUploaded]);

  useEffect(() => {
    const areEqual = JSON.stringify(initialSub) === JSON.stringify(sub);
    const nodesAreEqual =
      JSON.stringify(selectedNodes) === JSON.stringify(initialSelectedNodes);
    if (areEqual && nodesAreEqual) {
      setHasUnsavedChanges(false);
    } else {
      setHasUnsavedChanges(true);
    }
  }, [sub, initialSub, selectedNodes, initialSelectedNodes]);

  const handleUaStageOperations = (): boolean => {
    setAlertMessage(t("Loading..."));

    if (activeStep === 0) {
      const checks = uaChecksConnectToServer(sub, security);
      if (checks) {
        setAlertStatus("warning");
        setAlertMessage(checks);
        return false;
      }
    }

    if (activeStep === 1) {
      if (isFileUploadView) {
        // file upload view only
        if (!fileIsUploaded) {
          setAlertStatus("warning");
          setAlertMessage(t("You must upload a file"));
          return false;
        }
        if (!fileSelectedNodes.length) {
          // file upload view only
          setAlertStatus("warning");
          setAlertMessage(t("Connection must have at least one node"));
          return false;
        } else {
          setSelectedNodes(fileSelectedNodes);
        }
      } else {
        // ua tree view
        if (!selectedNodes.length) {
          setAlertStatus("warning");
          setAlertMessage(t("You must select at least one node"));
          return false;
        }
      }
    }
    return true;
  };

  const handleMakeUaRequest = async () => {
    const startSessionInput: PostQueryUAStartSubscriptionInput = {
      url: sub.server_url,
      name: sub.name,
      update_rate: +sub.update_rate,
      nodes: selectedNodes,
    };

    if (editSubForm?.sub.id) {
      // edit sub
      const editSubInput: PostQueryUAEditSubscriptionInput = {
        ...startSessionInput,
        subscription_id: editSubForm.sub.id,
      };
      await callApi({
        query: postQueryUAEditSubscription(editSubInput),
        auth: { setAuthedUser },
      });
    } else {
      // create new sub
      await callApi({
        query: postQueryUAStartSubscription(startSessionInput),
        auth: { setAuthedUser },
      });
    }

    setAlertStatus("success");
    setAlertMessage(
      editSubForm?.sub.id
        ? t("Connection successfully updated")
        : t("Connection successfully created")
    );
  };

  const handleOnNextStage = async () => {
    try {
      const operations = handleUaStageOperations();
      if (!operations) {
        return false;
      }

      if (activeStep === 0) {
        if (!uaTree && !isFileUploadView) {
          setAlertMessage(t("Fetching data...."));
          const dataTree = await uaHandleConnectToServer(sub, setAuthedUser);
          console.log("dataTree ", dataTree);
          setUaTree(() => dataTree);
        }
        setFileIsUploaded(false);
      }

      if (activeStep === 2) {
        handleUpdateInitials();
        await handleMakeUaRequest();
        navigate({ pathname: onSuccessUrl });
        return false;
      }

      setAlertStatus(null);
      setAlertMessage(null);
      return true;
    } catch (err) {
      setAlertStatus("error");
      setAlertMessage(t("Something went wrong"));
      return false;
    }
  };

  const handleUpdateConnectionParamsData = useCallback(
    (data: UaConnectionParametersData) => {
      setSub((prev) => ({
        ...prev,
        server_url: data.server,
        update_rate: +data.updateRate,
        name: data.connectionName,
      }));
    },
    []
  );

  const handleUpdateConnectionParamsSecurity = useCallback((data: UaSecurity) => {
    setSecurity((prev) => {
      const usernamePass = prev?.usernamePass || data.usernamePass || null;

      return {
        ...prev,
        ...data,
        usernamePass,
      };
    });
  }, []);

  const handleUpdateSelectedNodes = useCallback((nodes: string[]) => {
    setSelectedNodes(() => [...nodes]);
  }, []);

  const handleSaveChanges = async () => {
    try {
      if (!editSubForm?.sub.id) {
        return;
      }
      const operations = handleUaStageOperations();
      if (operations) {
        handleUpdateInitials();
        await handleMakeUaRequest();
        navigate({ pathname: onSuccessUrl });
      }
    } catch (err) {
      console.log("handleSaveChanges ", err);
    }
  };

  const handleCancelForm = () => {
    navigate({ pathname: onSuccessUrl });
  };
  const handleUpdateInitials = () => {
    setInitialSub(sub);
    setInitialSelectedNodes(selectedNodes);
  };
  return (
    <MultiStageForm
      steps={useTranslateArray(STAGES)}
      activeStep={activeStep}
      setActiveStep={setActiveStep}
      handleOnNextStage={handleOnNextStage}
      alertMessage={alertMessage}
      alertStatus={alertStatus}
      disableNextButton={alertStatus === "success"}
      disablePrevButton={alertStatus === "success"}
      firstBack={handleStartOver}
      lastNextButtonLabel={
        editSubForm?.sub.id ? t("Confirm and Apply Changes") : t("Create New Connection")
      }
      title={title}
      saveCurrentStageData={editSubForm?.sub.id ? handleSaveChanges : undefined}
      handleExitForm={handleCancelForm}
      hasUnsavedChanges={hasUnsavedChanges}
    >
      <>
        {activeStep === 0 ? (
          <UaConnectionParameters
            data={{
              server: sub.server_url,
              updateRate: `${sub.update_rate}`,
              connectionName: sub.name,
            }}
            isLoading={alertStatus === "loading"}
            handleUpdateData={handleUpdateConnectionParamsData}
            security={security}
            handleUpdateSecurity={handleUpdateConnectionParamsSecurity}
            isFileUpload={isFileUploadView}
            setIsFileUpload={setIsFileUploadView}
            isEdit={!!editSubForm?.sub.id}
          />
        ) : null}
        {activeStep === 1 && uaTree && !isFileUploadView ? (
          <UaNodesStage
            treeData={uaTree}
            serverURL={sub.server_url}
            selectedNodes={selectedNodes}
            handleChange={handleUpdateSelectedNodes}
          />
        ) : null}
        {activeStep === 1 && isFileUploadView ? (
          <UaFileUploadStage
            setFileIsUploaded={setFileIsUploaded}
            serverURL={sub.server_url}
            setSelectedNodes={setFileSelectedNodes}
          />
        ) : null}

        {activeStep === 2 ? (
          <UaDataSnapshot
            serverName={sub.server_url}
            selectedNodes={selectedNodes}
            updateTime={sub.update_rate}
          />
        ) : null}
      </>
    </MultiStageForm>
  );
};

export default UaConnection;
