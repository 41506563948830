import { useCallback, useEffect, useState } from "react";
import {
  ModbusSubMapping,
  ModbusSubscription,
} from "../../../../../Api/DataSources/apiDSDataTypes";
import { FormStatuses } from "../../../../../Global/Types/commonTypes";
import MultiStageForm from "../../../../SmallComponents/MultiStageForm/MultiStageForm";
import callApi from "../../../../../Api/callApi";
import { useAuthedContext } from "../../../../../context/AuthContext";
import { Box } from "@mui/material";
import useTheme from "@mui/material/styles/useTheme";
import cssLayoutStyles from "../../../../../Global/Styles/layout";
import cssSpacingStyles from "../../../../../Global/Styles/spacing";
import {
  PostQueryModbusConnectInput,
  PostQueryModbusConnectionStartInput,
  PostQueryModbusMapAddressInput,
} from "../../../../../Api/DataSources/apiDSInputs";
import {
  postQueryModbusConnect,
  postQueryModbusConnectionStart,
  postQueryModbusDeleteConnection,
  postQueryModbusMapAddress,
} from "../../../../../Api/DataSources/apiDSPostQueries";
import ModbusConnectionParameters from "./ModbusConnectionParameters";
import { ModbusConnectionParametersData } from "./modbusConnectionTypes";
import ModbusMapping from "./ModbusMapping";
import {
  PostQueryModbusConnectSnippet,
  PostQueryModbusConnectionStartSnippet,
} from "../../../../../Api/DataSources/apiDSSnippets";
import { ModbusMappingRow } from "./modbusUtils";
import { v4 as uuidv4 } from "uuid";
import { useNavigate } from "react-router-dom";
import { useLanguageContext } from "../../../../../context/LanguageContext";
import { useTranslateArray } from "../../../../../Global/Hooks/useTranslations";

const EMPTY_SUB_DATA: ModbusSubscription = {
  id: "",
  name: "",
  host: "giant-modbus-server",
  port: 5020,
  polling_time: 30,
  user_id: "",
  status: "active",
  start_time: "",
  end_time: "",
  values_addresses_map: [],
};

const STAGES = ["Connection Parameters", "Address Mapping"];

interface ModbusConnectionProps {
  handleStartOver?: () => void;
  editSubForm?: {
    sub: ModbusSubscription;
  };
  onSuccessUrl: string;
  title: string;
}

const ModbusConnection: React.FC<ModbusConnectionProps> = ({
  handleStartOver,
  editSubForm,
  onSuccessUrl,
  title,
}) => {
  const { t } = useLanguageContext();
  const theme = useTheme();
  const styles = { ...cssLayoutStyles, ...cssSpacingStyles(theme) };

  const [alertMessage, setAlertMessage] = useState<string | null>(null);
  const [alertStatus, setAlertStatus] = useState<FormStatuses>(null);
  const [activeStep, setActiveStep] = useState<number>(0);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState<boolean>(false);

  // activeStep === 0
  const [sub, setSub] = useState<ModbusSubscription>(editSubForm?.sub || EMPTY_SUB_DATA);

  // activeStep === 1
  const [mappingRows, setMappingRows] = useState<ModbusMappingRow[]>(() =>
    getMappingRows(sub.values_addresses_map)
  );
  // initial values
  const [initialMappingRows, setInitialMappingRows] = useState<ModbusMappingRow[]>(
    () => mappingRows
  );
  const [initialSub, setInitialSub] = useState<ModbusSubscription>(sub);

  const { setAuthedUser } = useAuthedContext();
  const navigate = useNavigate();

  useEffect(() => {
    const areEqual = JSON.stringify(initialSub) === JSON.stringify(sub);
    const mappingsAreEqual =
      JSON.stringify(mappingRows) === JSON.stringify(initialMappingRows);

    if (areEqual && mappingsAreEqual) {
      setHasUnsavedChanges(false);
    } else {
      setHasUnsavedChanges(true);
    }
  }, [sub, initialSub, initialMappingRows, mappingRows]);

  const handleMakeModbusRequest = async () => {
    if (activeStep === 0) {
      if (sub.id) {
        // sub exists so we can only update the polling_time and name
        if (sub.status === "active") {
          // we need to stop the sub, before we start it
          await handleStopSub();
        }
        await handleStartSub();
      }
    }

    if (activeStep === 1) {
      if (sub.status === "active") {
        // cannot update an active sub
        await handleStopSub();
      }
      await handleUpdateSubMapping();

      if (sub.status === "active") {
        // sub was initially active, so we start it
        await handleStartSub();
      }
    }
  };

  const handleModbusStageOperations = (): boolean => {
    setAlertStatus("loading");
    if (activeStep === 0) {
      if (!sub.name || !sub.host || !sub.port || !sub.polling_time) {
        setAlertStatus("warning");
        setAlertMessage(
          t("You must provide connection name, host, port, and update rate")
        );
        return false;
      }
    }

    if (activeStep === 1) {
      if (!mappingRows.length) {
        setAlertStatus("warning");
        setAlertMessage(t("You must configure at least one mapping row"));
        return false;
      }
    }

    return true;
  };

  const handleOnNextStage = async () => {
    try {
      setAlertStatus("loading");

      const operations = handleModbusStageOperations();
      if (!operations) {
        return false;
      }

      if (activeStep === 0) {
        if (!sub.id) {
          const input: PostQueryModbusConnectInput = {
            name: sub.name,
            host: sub.host,
            port: sub.port,
            polling_time: sub.polling_time,
          };

          const result = await callApi<PostQueryModbusConnectSnippet>({
            query: postQueryModbusConnect(input),
            auth: { setAuthedUser },
          });

          setSub((prev) => ({ ...prev, id: result.id }));
        }
      }

      if (activeStep === 1) {
        handleUpdateInitials();
        await handleMakeModbusRequest();

        setAlertStatus("success");
        setAlertMessage(
          editSubForm?.sub.id
            ? t("Connection successfully updated")
            : t("Connection mapping successfully saved")
        );
        navigate({ pathname: onSuccessUrl });
        return false;
      }

      setAlertStatus(null);
      setAlertMessage(null);
      return true;
    } catch (err) {
      console.log("err ", err);
      setAlertStatus("error");
      setAlertMessage(t("Something went wrong"));
      return false;
    }
  };

  const handleUpdateConnectionParamsData = useCallback(
    (data: ModbusConnectionParametersData) => {
      setSub((prev) => ({
        ...prev,
        host: data.host,
        port: +data.port,
        name: data.name,
        polling_time: +data.updateRate,
      }));
    },
    []
  );

  /** we cannot update an active sub data */
  const handleStopSub = async () => {
    await callApi({
      query: postQueryModbusDeleteConnection(sub.id),
      auth: { setAuthedUser },
    });
  };

  const handleStartSub = async () => {
    const input: PostQueryModbusConnectionStartInput = {
      id: sub.id,
      name: sub.name,
      polling_time: sub.polling_time,
    };

    await callApi<PostQueryModbusConnectionStartSnippet>({
      query: postQueryModbusConnectionStart(input),
      auth: {
        setAuthedUser,
      },
    });
  };

  const handleUpdateSubMapping = async () => {
    const input: PostQueryModbusMapAddressInput = mappingRows.map((item) => ({
      value_name: item.name as string,
      starting_address: item.address,
      value_type: item.type as string,
      unit_of_measure: item.unit as string,
    }));

    await callApi({
      query: postQueryModbusMapAddress(sub.id, input),
      auth: { setAuthedUser },
    });
  };

  const handleSaveChanges = async () => {
    try {
      if (!editSubForm?.sub.id) {
        return;
      }
      const operations = handleModbusStageOperations();
      if (operations) {
        handleUpdateInitials();
        await handleMakeModbusRequest();
        navigate({ pathname: onSuccessUrl });
      }
    } catch (err) {
      console.log("handleSaveChanges ", err);
    }
  };

  const handleCancelForm = () => {
    navigate({ pathname: onSuccessUrl });
  };

  const handleUpdateInitials = () => {
    setInitialSub(sub);
    setInitialMappingRows(mappingRows);
  };

  return (
    <MultiStageForm
      steps={useTranslateArray(STAGES)}
      activeStep={activeStep}
      setActiveStep={setActiveStep}
      handleOnNextStage={handleOnNextStage}
      alertMessage={alertMessage}
      alertStatus={alertStatus}
      disableNextButton={alertStatus === "success"}
      disablePrevButton={alertStatus === "success"}
      firstBack={handleStartOver}
      lastNextButtonLabel={
        editSubForm?.sub.id ? t("Confirm and Apply Changes") : t("Create New Connection")
      }
      title={title}
      saveCurrentStageData={editSubForm?.sub.id ? handleSaveChanges : undefined}
      handleExitForm={handleCancelForm}
      hasUnsavedChanges={hasUnsavedChanges}
    >
      <>
        {activeStep === 0 ? (
          <Box component="div" css={styles.flexCenter}>
            <Box component="div" css={styles.widthLimit40}>
              <ModbusConnectionParameters
                data={{
                  host: sub.host,
                  port: `${sub.port}`,
                  name: sub.name,
                  updateRate: `${sub.polling_time}`,
                }}
                isLoading={alertStatus === "loading"}
                handleUpdateData={handleUpdateConnectionParamsData}
                subExists={!!sub.id}
              />
            </Box>
          </Box>
        ) : null}

        {activeStep === 1 ? (
          <ModbusMapping
            css={styles.contentBreak}
            mappingRows={mappingRows}
            setMappingRows={setMappingRows}
            host={sub.host}
            port={sub.port}
          />
        ) : null}
      </>
    </MultiStageForm>
  );
};

export default ModbusConnection;

const getMappingRows = (
  subMapping: ModbusSubMapping[] | undefined
): ModbusMappingRow[] => {
  if (!subMapping?.length) {
    return [];
  }

  return subMapping.map((item) => ({
    id: uuidv4().split("-")[0],
    name: item.value_name,
    type: item.value_type,
    address: item.starting_address,
    unit: item.unit_of_measure,
  }));
};
