import React, { memo, useState } from "react";
import useTheme from "@mui/material/styles/useTheme";
import { Typography } from "@mui/material";
import cssSpacingStyles from "../../../../../Global/Styles/spacing";
import { FLOW_NODE_TYPE, FlowEnhancedNodeData } from "../flowNodesTypes";
import FlowNode from "./FlowNode";
import FlowEnhancedForm from "../Forms/FlowEnhancedForm";
import Modal from "../../../../MaterialUI/Modal";
import { useReactFlow } from "reactflow";
import { useTranslatedModalTitle } from "../../../../../Global/Hooks/useTranslations";

interface FlowEnhancedNodeProps {
  id: string;
  data: FlowEnhancedNodeData;
}
const modalTitleTranslations = {
  "Edit enhanced node": "Edit enhanced node",
} as const;

type ModalType = keyof typeof modalTitleTranslations;

const FlowEnhancedNode: React.FC<FlowEnhancedNodeProps> = ({ id, data }) => {
  const theme = useTheme();
  const styles = {
    ...cssSpacingStyles(theme),
  };
  const [modalType, setModalType] = useState<ModalType | null>(null);
  const getTranslatedModalTitle = useTranslatedModalTitle(modalTitleTranslations);

  const { setNodes } = useReactFlow();

  const handleEditSubmit = (nodeData: FlowEnhancedNodeData) => {
    setNodes((nds) =>
      nds.map((node) => {
        if (node.id === id) {
          return {
            ...node,
            data: nodeData,
          };
        }
        return node;
      })
    );

    setModalType(null);
  };

  const handleOpenEditModal = () => {
    setModalType("Edit enhanced node");
  };

  return (
    <>
      <FlowNode
        id={id}
        nodeType={FLOW_NODE_TYPE.Text}
        handleOpenEditModal={handleOpenEditModal}
      >
        <Typography css={styles.contentBreak} variant="h2" align="center">
          {data.name}
        </Typography>
        <Typography css={styles.contentBreak} variant="body1" align="center">
          <input type="color" />
        </Typography>
      </FlowNode>

      <Modal
        onClose={() => setModalType(null)}
        open={!!modalType}
        fullWidth
        label={modalType ? getTranslatedModalTitle(modalType) : ""}
      >
        {modalType === "Edit enhanced node" ? (
          <FlowEnhancedForm handleEditSubmit={handleEditSubmit} data={data} />
        ) : null}
      </Modal>
    </>
  );
};

export default memo(FlowEnhancedNode);
