import { FileWithPath } from "react-dropzone";
import { Query } from "../../../Api/callApi";

export type File = {
  file: FileWithPath;
};
export const postQueryUploadInvoice = (
  input: any
): Query => ({
  endpoint: `payments_and_receivables/invoices/upload-invoice`,
  method: "POST",
  variables: input,
  multipartForm: true,
});

export const deleteQueryInvoice = (id: string): Query => ({
  endpoint: `payments_and_receivables/invoices/${id}`,
  method: "DELETE",
});
