import { Grid, IconButton, Stack, useTheme } from "@mui/material";
import { ConditionMonitoring } from "../../Components/PageComponents/Maintenance/ConditionMonitoring/conditionMonitoringUtils";
import { useEffect, useState } from "react";
import ConditionMonitoringProductionPlant from "../../Components/PageComponents/Maintenance/ConditionMonitoring/ConditionMonitoringProductionPlant";
import { useOutletContext } from "react-router-dom";
import { AppRouterProps } from "../../Layout/layoutVariables";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import Modal from "../../Components/MaterialUI/Modal";
import { conditionMonitoringData } from "../../Components/PageComponents/Maintenance/ConditionMonitoring/conditionMonitoringData";
import cssSpacingStyles from "../../Global/Styles/spacing";
import { useLanguageContext } from "../../context/LanguageContext";
import CustomIconsTooltip from "../../Components/SmallComponents/Tooltip/CustomIconsTooltip";
import PageTitle from "../../Components/SmallComponents/PageTitle/PageTitle";

const ConditionMonitoringPage: React.FC = () => {
  const { t } = useLanguageContext();
  const theme = useTheme();
  const styles = {
    ...cssSpacingStyles(theme),
  };
  const [monitoringData, setMonitoringData] = useState<ConditionMonitoring[]>([]);
  const { smMediaQuery, setExtraRightNavMenu, setExtraTopNavMenu } =
    useOutletContext<AppRouterProps>();
  const [openAddModal, setOpenAddModal] = useState<boolean>(false);

  useEffect(() => {
    if (smMediaQuery) {
      setExtraTopNavMenu(null);
      setExtraRightNavMenu(null);
    } else {
      setExtraTopNavMenu(<PageTitle title={t("Condition Monitoring")} />);
      setExtraRightNavMenu(
        <CreateNewProductionPlant
          handleAdd={() => {
            setOpenAddModal(true);
          }}
        />
      );
    }

    return () => {
      setExtraTopNavMenu(null);
      setExtraRightNavMenu(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [smMediaQuery]);

  useEffect(() => {
    setMonitoringData(conditionMonitoringData);
  }, []);

  return (
    <>
      {smMediaQuery ? (
        <PageTitle css={styles.labelBreak} title={t("Condition Monitoring")} />
      ) : null}
      <Grid container spacing={2}>
        {monitoringData.map((productionPlantData) => (
          <Grid item xs={12} sm={4} key={`productPlant-${productionPlantData.id}`}>
            <ConditionMonitoringProductionPlant
              productionPlantData={productionPlantData}
            />
          </Grid>
        ))}
      </Grid>
      <Modal
        open={openAddModal}
        fullWidth
        label={t("Add Production Plant")}
        onClose={() => setOpenAddModal(false)}
      ></Modal>
    </>
  );
};

export default ConditionMonitoringPage;

interface CreateNewProductionPlantProps {
  handleAdd: () => void;
}

const CreateNewProductionPlant: React.FC<CreateNewProductionPlantProps> = ({
  handleAdd,
}) => {
  const { t } = useLanguageContext();
  return (
    <Stack alignItems="center">
      <CustomIconsTooltip tooltipText={t("Add")}>
        <IconButton aria-label="create new production plant" onClick={handleAdd} disabled>
          <AddOutlinedIcon />
        </IconButton>
      </CustomIconsTooltip>
    </Stack>
  );
};
