import {
  ExcellenceChartProps,
  PieDonutChartData,
} from "../../../../ExcellenceWidgets/EditExcellenceChartForms/excellenceChartFormUtils";
import {
  PieChartConfiguration,
  PieChartDataSchema,
  PieChartDataSchemaInput,
} from "../../../../ExcellenceWidgets/PieChart/pieChartTypes";
import PieChart from "../../../../ExcellenceWidgets/PieChart/PieChart";
import { useLazyQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { graphQlQueryExcellencePieData } from "../../../../../GraphQL/Excellence/graphQLQueriesExcellence";
import { FormStatuses } from "../../../../../Global/Types/commonTypes";
import Alert from "../../../../MaterialUI/Alert";
import { useTheme } from "@mui/material";
import cssSpacingStyles from "../../../../../Global/Styles/spacing";
import LoadingBackdrop from "../../../../MaterialUI/LoadingBackdrop";
import pieChartDefaultData from "../../../../ExcellenceWidgets/ExcellenceDefaultConfig/pieChartDefaultData";
import { useLanguageContext } from "../../../../../context/LanguageContext";
import {
  buildGraphqlFilters,
  GraphqlFilter,
} from "../../../../SmallComponents/GraphqlFilters/graphqlFiltersUtils";

interface ExcellencePieChartProps extends ExcellenceChartProps {
  data: PieDonutChartData | null;
  configuration: PieChartConfiguration;
  schema: PieChartDataSchema | null;
  isDonut: boolean;
}

const ExcellencePieChart: React.FC<ExcellencePieChartProps> = ({
  className,
  data,
  configuration,
  isStatic,
  schema,
  resetChartFetch,
  isDonut,
  noAnimation,
  parameters,
  dataFetchFromParent,
  tempFilters,
  refetchOnFilters,
}) => {
  const theme = useTheme();
  const styles = {
    ...cssSpacingStyles(theme),
  };
  const { t } = useLanguageContext();
  const [updatedData, setUpdatedData] = useState<PieDonutChartData | null>(data);
  const [fetchStatus, setFetchStatus] = useState<FormStatuses>(null);
  const [initialFetchDone, setInitialFetchDone] = useState<boolean>(false);

  const [getPieData, { loading: loadingPieData }] = useLazyQuery(
    graphQlQueryExcellencePieData
  );

  const paramMapping: Record<string, string> | undefined = parameters?.reduce(
    (acc, curr) => {
      return {
        ...acc,
        [curr.id]: curr.name,
      };
    },
    {}
  );

  useEffect(() => {
    (async () => {
      if (initialFetchDone) {
        await handleFetch(tempFilters);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetchOnFilters]);

  useEffect(() => {
    (async () => {
      if (!dataFetchFromParent) {
        await handleFetch(configuration?.graphqlFilters);
        setInitialFetchDone(true);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetChartFetch, dataFetchFromParent]);

  useEffect(() => {
    if (dataFetchFromParent) {
      setUpdatedData(data);
    }
  }, [dataFetchFromParent, data]);

  const handleFetch = async (graphqlFilters: GraphqlFilter[] | undefined) => {
    try {
      if (schema) {
        setFetchStatus("loading");
        const schemaInput: PieChartDataSchemaInput = {
          ...schema,
          parameters: schema.parameters[0],
          legend: schema.legend,
        };

        const result = await getPieData({
          variables: {
            input: {
              ...schemaInput,
              filters: buildGraphqlFilters(graphqlFilters),
            },
          },
        });
        if (!result.data || result.error) {
          throw new Error(JSON.stringify(result));
        }
        const resultData = result.data.pieData.chartData;

        setUpdatedData(resultData);
        setFetchStatus(null);
      }
    } catch (err) {
      console.log("err ", err);
      setFetchStatus("error");
    }
  };

  return (
    <>
      <PieChart
        className={className}
        configuration={configuration}
        data={schema && updatedData ? updatedData : pieChartDefaultData}
        isStatic={isStatic}
        schema={schema}
        isDonut={isDonut}
        noAnimation={noAnimation}
        paramMapping={paramMapping}
      />
      {isStatic ? null : <LoadingBackdrop loading={loadingPieData} />}

      <Alert
        css={styles.reverseContentBreak}
        message={t("Something went wrong")}
        showAlert={fetchStatus === "error"}
        severity={"error"}
      />
    </>
  );
};

export default ExcellencePieChart;
