import { Box, Typography } from "@mui/material";
import Select from "../../Components/MaterialUI/FormFields/Select";
import ContentBox from "../../Components/MaterialUI/ContentBox";
import { handleGetSelectOption } from "../../Global/Utils/commonFunctions";
import cssSpacingStyles from "../../Global/Styles/spacing";
import cssLayoutStyles from "../../Global/Styles/layout";
import useTheme from "@mui/material/styles/useTheme";
import { useEffect, useState } from "react";
import UaConnection from "../../Components/PageComponents/Integrator/CreateOrEditConnection/UaConnection";
import MqttConnection from "../../Components/PageComponents/Integrator/CreateOrEditConnection/MqttConnection";
import ModbusConnection from "../../Components/PageComponents/Integrator/CreateOrEditConnection/ModbusConnection";
import DaConnection from "../../Components/PageComponents/Integrator/CreateOrEditConnection/DaConnection";
import { useLanguageContext } from "../../context/LanguageContext";
import { useOutletContext } from "react-router-dom";
import { AppRouterProps } from "../../Layout/layoutVariables";
import PageTitle from "../../Components/SmallComponents/PageTitle/PageTitle";

// const CONNECTIONS_LIST = [
//   "OPC UA",
//   "OPC DA",
//   "MQTT",
//   "SQL Database",
//   "NoSQL Database",
//   "RestAPI / OData",
//   "SAP R/3",
//   "SAP S4 HANA",
//   "File upload",
//   "Stream (Data, Audio, Video)",
//   "Modbus",
//   "Profibus",
//   "Profinet",
// ];

type SubscriptionType = "OPC UA" | "OPC DA" | "MQTT" | "Modbus";
const CONNECTION_OPTIONS: SubscriptionType[] = ["OPC UA", "OPC DA", "MQTT", "Modbus"];
const CONNECTION_PAGE_URL = "/GIANT-Toolbox-Manager/Integrator/Connections";
const TITLE = "Configure Connection";

const CreateConnection: React.FC = () => {
  const { t } = useLanguageContext();
  const theme = useTheme();
  const styles = { ...cssSpacingStyles(theme), ...cssLayoutStyles };
  const [selectedType, setSelectedType] = useState<SubscriptionType | "">("");
  const { smMediaQuery, setExtraTopNavMenu } = useOutletContext<AppRouterProps>();

  useEffect(() => {
    if (smMediaQuery) {
      setExtraTopNavMenu(null);
    } else {
      setExtraTopNavMenu(<PageTitle title={t("Data Sources")} />);
    }

    return () => {
      setExtraTopNavMenu(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [smMediaQuery]);

  const handleStartOver = () => {
    setSelectedType("");
  };

  return (
    <Box component="div">
      {smMediaQuery ? (
        <PageTitle css={styles.labelBreak} title={t("Data Sources")} />
      ) : null}
      <ContentBox>
        {!selectedType ? (
          <Box component="div">
            <Typography css={styles.labelBreak} variant="h4" align="center">
              {t(TITLE)}
            </Typography>
            <Select
              css={[styles.width50, styles.textBreak]}
              value={selectedType}
              onChange={(e) => setSelectedType(e.target.value as SubscriptionType)}
              selectOptions={handleGetSelectOption(CONNECTION_OPTIONS)}
              fullWidth
              label={t("Select Connection Type")}
            />
          </Box>
        ) : null}

        {selectedType === "OPC UA" ? (
          <UaConnection
            handleStartOver={handleStartOver}
            onSuccessUrl={CONNECTION_PAGE_URL}
            title={t(TITLE)}
          />
        ) : null}

        {selectedType === "MQTT" ? (
          <MqttConnection
            handleStartOver={handleStartOver}
            onSuccessUrl={CONNECTION_PAGE_URL}
            title={t(TITLE)}
          />
        ) : null}

        {selectedType === "Modbus" ? (
          <ModbusConnection
            handleStartOver={handleStartOver}
            onSuccessUrl={CONNECTION_PAGE_URL}
            title={t(TITLE)}
          />
        ) : null}
        {selectedType === "OPC DA" ? (
          <DaConnection
            handleStartOver={handleStartOver}
            onSuccessUrl={CONNECTION_PAGE_URL}
            title={t(TITLE)}
          />
        ) : null}
      </ContentBox>
    </Box>
  );
};

export default CreateConnection;
