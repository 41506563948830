import { Box, Stack, Typography, Theme, Divider } from "@mui/material";
import useTheme from "@mui/material/styles/useTheme";
import cssSpacingStyles from "../../../Global/Styles/spacing";
import { css } from "@emotion/react";
import { useState } from "react";
import cssLayoutStyles from "../../../Global/Styles/layout";
import isValid from "date-fns/isValid";
import { ExcellenceDataSchemaForm } from "../EditExcellenceChartForms/excellenceChartFormUtils";
import {
  ExcellenceChartDataSchema,
  ExcellenceChartDataSchemaParameter,
  ExcellenceParameter,
} from "../../../GraphQL/Excellence/graphQLTypesExcellence";
import {
  AutocompleteGroupedOption,
  AutocompleteOption,
  FormStatuses,
  SelectOption,
} from "../../../Global/Types/commonTypes";
import AutocompleteGrouped from "../../MaterialUI/FormFields/AutocompleteGrouped";
import { dateOrStringTransform } from "../../../Global/Utils/commonFunctions";
import Autocomplete from "../../MaterialUI/FormFields/Autocomplete";
import DateTimeConfiguration from "../../SmallComponents/DateTimeConfiguration/DateTimeConfiguration";
import { ExcellenceDateTimeConfigurationData } from "../../SmallComponents/DateTimeConfiguration/dateTimeConfigurationUtils";
import Alert from "../../MaterialUI/Alert";
import isBefore from "date-fns/isBefore";
import { getTimeChartStartEndTimes } from "./timeChartUtils";
import LoadingBackdrop from "../../MaterialUI/LoadingBackdrop";
import UnitsOfMeasureDropdown from "../../SmallComponents/UnitsOfMeasureDropdown/UnitsOfMeasureDropdown";
import Modal from "../../MaterialUI/Modal";
import Button from "../../MaterialUI/Button";
import { GetUnitsOfMeasureSnippet } from "../../../Api/Excellence/apiExcellenceSnippets";
import { useLanguageContext } from "../../../context/LanguageContext";
import {
  GraphqlFilter,
  handleExcellenceParamToGraphqlFilters,
} from "../../SmallComponents/GraphqlFilters/graphqlFiltersUtils";
import GraphqlFilters from "../../SmallComponents/GraphqlFilters/GraphqlFilters";

const cssStyles = (theme: Theme) => ({
  autocompleteGroup: css({
    position: "sticky",
    top: "-8px",
    padding: "4px 10px",
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.secondary.light,
  }),
  autocompleteList: css({
    padding: 0,
  }),
});

type HandleFetchDataInput = {
  input: ExcellenceChartDataSchema;
  updatedTimeConfigData: ExcellenceDateTimeConfigurationData | null;
};

interface EditTimeChartDataSchemaFormProps extends ExcellenceDataSchemaForm {
  handleFetchData: (data: HandleFetchDataInput) => Promise<void>;
  handleOnConnectionChange: (subs: AutocompleteOption[] | null) => Promise<void>;
  selectedNodes: AutocompleteGroupedOption[];
  nodeOptions: AutocompleteGroupedOption[];
  selectedConnections: SelectOption[];
  setSelectedNodes: React.Dispatch<React.SetStateAction<AutocompleteGroupedOption[]>>;
  dateTimeConfig: ExcellenceDateTimeConfigurationData | null;
  setRemoveInterval: React.Dispatch<React.SetStateAction<boolean>>;
  handleOnUnitChange: (
    unitID: string | null,
    newNodes: AutocompleteGroupedOption[],
    noRequest?: boolean
  ) => Promise<void>;
  disabledNodeOptions: string[];
  configUnit: string | null;
  fetchedUnits: GetUnitsOfMeasureSnippet | null;
  unitsLoading: boolean;
  graphqlFilters: GraphqlFilter[];
  setGraphqlFilters: React.Dispatch<React.SetStateAction<GraphqlFilter[]>>;
}

const EditTimeChartDataSchemaForm: React.FC<EditTimeChartDataSchemaFormProps> = ({
  parameters,
  dataSchema,
  handleFetchData,
  handleOnConnectionChange,
  selectedNodes,
  nodeOptions,
  selectedConnections,
  setSelectedNodes,
  dateTimeConfig,
  setRemoveInterval,
  handleOnUnitChange,
  disabledNodeOptions,
  configUnit,
  fetchedUnits,
  unitsLoading,
  graphqlFilters,
  setGraphqlFilters,
}) => {
  const theme = useTheme();
  const styles = {
    ...cssSpacingStyles(theme),
    ...cssStyles(theme),
    ...cssLayoutStyles,
  };
  const { t } = useLanguageContext();
  const [unitModal, setUnitModal] = useState<{
    option: SelectOption | null;
    isEmpty: boolean;
  } | null>(null);
  const [timeConfigData, setTimeConfigData] =
    useState<ExcellenceDateTimeConfigurationData | null>(() =>
      handlePreparePropsData(dateTimeConfig)
    );

  // system-used
  const [formStatus, setFormStatus] = useState<FormStatuses>(null);
  const [fieldAlertMessage, setFieldAlertMessage] = useState<string | null>(null);

  const allConnectionOptions: SelectOption[] = handleGetConnectionsOptions(
    parameters?.parameters || []
  );
  const thresholdParams = getThresholdParams(disabledNodeOptions, nodeOptions);

  console.log("selectedNodes ", selectedNodes);
  console.log("allConnectionOptions ", allConnectionOptions);

  const handleOnNodeChange = async (nodes: AutocompleteGroupedOption[] | null) => {
    try {
      setFormStatus("loading");

      const inputParameters: ExcellenceChartDataSchemaParameter[] =
        nodes?.map((node) => {
          const containerID = allConnectionOptions.find(
            (conn) => conn.description === node.groupName
          )?.value;

          return {
            container: containerID || "",
            parameterId: node.value,
          };
        }) || [];

      if (dataSchema?.endTime && dataSchema.startTime) {
        const input: ExcellenceChartDataSchema = {
          parameters: [...inputParameters, ...thresholdParams],
          startTime: dataSchema?.startTime || null,
          endTime: dataSchema?.endTime || null,
        };

        await handleFetchData({ input, updatedTimeConfigData: timeConfigData });
      }

      setSelectedNodes(nodes || []); 
      if (nodes?.length) {
        const foundUnitLessNode = parameters?.parameters.find(
          (item) => item.id === nodes[nodes.length - 1]?.value
        );

        if (foundUnitLessNode?.unitName && foundUnitLessNode?.unitId) {
          setUnitModal({
            option: {value: foundUnitLessNode?.unitId, description: foundUnitLessNode?.unitName},
            isEmpty: false
          });
          // await handleOnUnitChange(foundUnitLessNode.unitId, nodes, true);
        }
      }

      setFormStatus("success");
    } catch (err) {
      console.log("err handleOnNodeChange() ", err);
      setFormStatus("error");
    }
  };

  const handleUpdateTimeConfig = async (data: ExcellenceDateTimeConfigurationData) => {
    try {
      setFieldAlertMessage(null);
      setTimeConfigData(data);

      const checks = timeConfigChecks(data, t);
      if (checks) {
        setFieldAlertMessage(checks);
        return;
      }
      setFormStatus("loading");
      const connectionsReverseMap: Record<string, string> = {};
      parameters?.parameters.forEach((element) => {
        if (!connectionsReverseMap[element.containerName]) {
          connectionsReverseMap[element.containerName] = element.container;
        }
      });

      const { startTime, endTime } = getTimeChartStartEndTimes(data);
      const inputParameters: ExcellenceChartDataSchemaParameter[] = selectedNodes.map(
        (node) => ({
          container: connectionsReverseMap[node.groupName],
          parameterId: node.value,
        })
      );

      const input: ExcellenceChartDataSchema = {
        parameters: [...inputParameters, ...thresholdParams],
        startTime,
        endTime,
      };

      await handleFetchData({ input, updatedTimeConfigData: data });
      setFormStatus("success");
    } catch (err) {
      console.log("err onDateRangeChange() ", err);
      setFormStatus("error");
    }
  };

  const handleSelectUnit = async (option: SelectOption | null, isEmpty: boolean) => {
    try {
      const nodeWithUnit = selectedNodes.some((node) => {
        const foundNode = parameters?.parameters.find((item) => item.id === node.value);
        return !!foundNode?.unitId;
      });

      if (nodeWithUnit && unitModal === null) {
        setUnitModal({
          option,
          isEmpty,
        });
      } else {
        setFormStatus("loading");
        const unitID = isEmpty ? null : option?.value;
        setUnitModal(null);
        await handleOnUnitChange(unitID || null, selectedNodes);
        setFormStatus("success");
      }
    } catch (err) {
      console.log("err handleSelectUnit() ", err);
      setFormStatus("error");
    }
  };

  if (!parameters?.parameters.length) {
    return (
      <Box component="div">
        <Typography variant="h3" color="error">
          {t("You don't have access to any active connections")}
        </Typography>
      </Box>
    );
  }

  if (formStatus === "error") {
    return (
      <Box component="div">
        <Typography variant="h3" color="error">
          {t("Something went wrong")}
        </Typography>
      </Box>
    );
  }

  return (
    <Stack spacing={2} alignItems="center" justifyContent="center">
      <Box component="div" css={styles.width100}>
        <Box component="div" css={[styles.width100]} style={{marginBottom: '0px'}}>
          <DateTimeConfiguration
            css={styles.textBreak}
            handleUpdateData={handleUpdateTimeConfig}
            timeConfigData={timeConfigData}
            setRemoveInterval={setRemoveInterval}
            disabled={formStatus === "loading"}
          />
          <Alert
            showAlert={!!fieldAlertMessage}
            severity="warning"
            message={fieldAlertMessage}
          />
        </Box>
      </Box>

      <Box component="div" css={styles.width100}>
        <Divider css={styles.textBreak} flexItem />
      </Box>

      <Box component="div" css={styles.width100} style={{marginTop: '5px'}}>
        <UnitsOfMeasureDropdown
          css={styles.widthLimit20}
          targetUnitValue={configUnit}
          handleOnUnitsChange={handleSelectUnit}
          fetchedUnits={fetchedUnits}
          unitsLoading={unitsLoading}
        />
      </Box>

      <Stack css={styles.width100} spacing={3} direction={{ xs: "column", sm: "row" }} style={{marginTop: '5px'}}>
        <Autocomplete
          css={styles.width100}
          label={t("Selected Connections")}
          multiple
          options={allConnectionOptions}
          value={selectedConnections}
          handleOnChange={handleOnConnectionChange}
          disabled={formStatus === "loading"}
        />

        <Box css={[styles.flex, styles.flexAlignItemsEnd]} component="div">
          <GraphqlFilters
            data={handleExcellenceParamToGraphqlFilters(parameters?.parameters || [])}
            graphqlFilters={graphqlFilters}
            setGraphqlFilters={setGraphqlFilters}
            selectedContainers={selectedConnections.map((item) => item.value)}
          />
        </Box>
      </Stack>

      <AutocompleteGrouped
        css={styles.width100}
        label={t("Selected Nodes")}
        multiple
        options={nodeOptions}
        value={selectedNodes}
        handleOnChange={handleOnNodeChange}
        disabled={formStatus === "loading"}
        disabledOptions={disabledNodeOptions}
      />

      <LoadingBackdrop loading={formStatus === "loading"} />

      <Modal
        open={unitModal !== null}
        onClose={() => setUnitModal(null)}
        fullWidth
        label={t("Confirm action")}
      >
        {unitModal ? (
          <Stack spacing={3} alignItems="center" justifyContent="center">
            <Typography variant="body1">
              {t(
                "Changing the unit of measure will remove all of the chart selected nodes which have a different unit of measure."
              )}
            </Typography>


            <Stack
              spacing={3}
              direction="row"
              alignItems="center"
              justifyContent="center"
            >
              <Button
                color="error"
                onClick={() => handleSelectUnit(unitModal.option, unitModal.isEmpty)}
              >
                {t("Proceed")}
              </Button>
              <Button
                onClick={() => setUnitModal(null)}
              >
                {t("Cancel")}
              </Button>
            </Stack>
          </Stack>
        ) : null}
      </Modal>
    </Stack>
  );
};

export default EditTimeChartDataSchemaForm;

const timeConfigChecks = (
  data: ExcellenceDateTimeConfigurationData,
  t: (key: string) => string
): null | string => {
  const {
    dateFrom,
    dateTo,
    //
    liveDataPeriodNumber,
    liveDataPeriodTime,
    //
    commonPeriod,
    commonPeriodEnd,
    commonPeriodFetchRate,
    commonPeriodStart,
  } = data;

  const allNulls = Object.values(data).every((value) => value === null);
  if (allNulls) {
    return t("Awaiting your input");
  }

  // 1. Static Data
  if (dateFrom || dateTo) {
    if (!dateFrom || !dateTo) {
      return t("Both 'Date from' and 'Date to' fields must have a value");
    }
    const dateFromDate = dateOrStringTransform<Date>(dateFrom, "date");
    const dateToDate = dateOrStringTransform<Date>(dateTo, "date");

    if (!isValid(dateFromDate) || !isValid(dateToDate)) {
      return t("Both 'Date from' and 'Date to' must be valid dates");
    }
    if (isBefore(dateToDate, dateFromDate)) {
      return t("'Date from' must be before 'Date to'");
    }
  } else if (commonPeriod) {
    if (!commonPeriodStart || !commonPeriodEnd) {
      return t("Common period must be specified");
    }
    if (commonPeriod.startsWith("Current") && !commonPeriodFetchRate) {
      return t("Fetch rate must be specified for periods involving a future end date");
    }
  } else {
    // 2. Live Data
    if (!liveDataPeriodNumber) {
      return t("'Period number' must be specified");
    }
    if (!liveDataPeriodTime) {
      return t("'Period time' must be selected");
    }
  }

  return null;
};

const handlePreparePropsData = (
  timeConfig: ExcellenceDateTimeConfigurationData | null
): ExcellenceDateTimeConfigurationData | null => {
  if (!timeConfig) {
    return null;
  }
  const { dateFrom, commonPeriodStart, dateTo, commonPeriodEnd } = timeConfig;

  return {
    ...timeConfig,
    dateFrom: dateFrom ? dateOrStringTransform<Date>(dateFrom, "date") : null,
    dateTo: dateTo ? dateOrStringTransform<Date>(dateTo, "date") : null,
    commonPeriodStart: commonPeriodStart
      ? dateOrStringTransform<Date>(commonPeriodStart, "date")
      : null,
    commonPeriodEnd: commonPeriodEnd
      ? dateOrStringTransform<Date>(commonPeriodEnd, "date")
      : null,
  };
};

const handleGetConnectionsOptions = (parameters: ExcellenceParameter[]) => {
  const allConnectionOptionsMap: Record<string, SelectOption> = {};

  parameters.forEach((param) => {
    if (!allConnectionOptionsMap[param.container]) {
      allConnectionOptionsMap[param.container] = {
        value: param.container,
        description: param.containerName,
      };
    }
  });

  const result: SelectOption[] = Object.values(allConnectionOptionsMap);

  return result;
};

const getThresholdParams = (
  disabledNodeOptions: string[],
  nodeOptions: AutocompleteGroupedOption[]
): ExcellenceChartDataSchemaParameter[] => {
  const thresholdParams: ExcellenceChartDataSchemaParameter[] = [];
  disabledNodeOptions.forEach((threshNode) => {
    if (threshNode) {
      const foundNode = nodeOptions.find((node) => node.value === threshNode);
      if (foundNode) {
        thresholdParams.push({
          parameterId: foundNode.value,
          container: foundNode.groupName,
        });
      }
    }
  });

  return thresholdParams;
};
