import { Query } from "../callApi";

export const getQueryExcellenceDashboards = (breakpoint: string): Query => ({
  endpoint: `/excellence/dashboards/${breakpoint}`,
  method: "GET",
});

export const getQueryExcellenceFullDashboard = (layoutID: string): Query => ({
  endpoint: `/excellence/dashboard/${layoutID}`,
  method: "GET",
});
export const getUnitsOfMeasure: Query = {
  endpoint: `/data_manager/units`,
  method: "GET",
};

export const getExcellenceFile = (dashboardID: string): Query => ({
  endpoint: `/excellence/dashboard/${dashboardID}/files`,
  method: "GET",
});

export const getQueryExcellenceDashboardPermissions = (dashboardId: string): Query => ({
  endpoint: `/excellence/dashboard/${dashboardId}/permissions`,
  method: "GET",
});

export const getQueryExcellenceRolesWithPermissions = (): Query => ({
  endpoint: "users/access_control/roles/excellence",
  method: "GET",
});

export const getQueryExcellenceUsersWithPermissions = (searchQuery: string): Query => ({
  endpoint: `users/access_control/users/privileges/excellence?search_query=${searchQuery}`,
  method: "GET",
});
