import { Box, Stack, useTheme } from "@mui/material";
import { useRef, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { Node } from "reactflow";
import cssSpacingStyles from "../../../../../../Global/Styles/spacing";
import cssLayoutStyles from "../../../../../../Global/Styles/layout";
import { FormStatuses } from "../../../../../../Global/Types/commonTypes";
import { useDetectFormsUnsavedChanges } from "../../../../../../Global/Hooks/useDetectFormsUnsavedChanges";
import Button from "../../../../../../Components/MaterialUI/Button";
import TextField from "../../../../../../Components/MaterialUI/FormFields/TextFields";
import Alert from "../../../../../../Components/MaterialUI/Alert";
import Modal from "../../../../../../Components/MaterialUI/Modal";
import { PID_FLOW_NODE_TYPE } from "../pidNodesUtils";
import { PidWorkflowsTextNodeData } from "./pidTextNodeUtils";
import { useLanguageContext } from "../../../../../../context/LanguageContext";
import { useTranslatedModalTitle } from "../../../../../../Global/Hooks/useTranslations";

const modalTitleTranslations = {
  "Add New Template": "Add New Template",
  "Condition Details": "Condition Details",
} as const;

type ModalType = keyof typeof modalTitleTranslations;

interface PidNodeTextFormProps {
  handleCreateSubmit?: (data: PidWorkflowsTextNodeData) => void;
  handleEditSubmit?: (data: PidWorkflowsTextNodeData) => void;
  data?: PidWorkflowsTextNodeData;
  nodes: Node<PidWorkflowsTextNodeData>[];
  handleSetUnsavedChanges: (unsavedChanges: boolean) => void;
  setUnsavedChanges: React.Dispatch<React.SetStateAction<boolean>>;
  unsavedChanges: boolean;
}

const PidNodeTextForm: React.FC<PidNodeTextFormProps> = ({
  handleCreateSubmit,
  handleEditSubmit,
  data,
  handleSetUnsavedChanges,
  setUnsavedChanges,
  unsavedChanges,
}) => {
  const { t } = useLanguageContext();
  const theme = useTheme();
  const styles = { ...cssSpacingStyles(theme), ...cssLayoutStyles };
  const [label, setLabel] = useState<string>(data?.label || "");
  const [formStatus, _] = useState<FormStatuses>("loading");
  const [alertMessage, setAlertMessage] = useState<string | null>(null);

  const [openModal, setOpenModal] = useState<ModalType | null>(null);
  const getTranslatedModalTitle = useTranslatedModalTitle(modalTitleTranslations);

  const nodeType = PID_FLOW_NODE_TYPE.Text;

  const initialFormState = useRef({
    label,
  }).current;

  useDetectFormsUnsavedChanges(initialFormState, { label }, handleSetUnsavedChanges);

  const handleFormSubmit = () => {
    if (!label) {
      setAlertMessage(t("Label is required"));
      return;
    }
    const current = new Date().toISOString();
    const result: PidWorkflowsTextNodeData = {
      id: uuidv4().split("-")[0],
      type: nodeType,
      label: label,
      createdOn: current,
      updatedOn: current,
      mode: "Edit Mode",
    };

    if (handleCreateSubmit) {
      handleCreateSubmit(result);
    }
    if (handleEditSubmit) {
      handleEditSubmit(result);
    }
    setUnsavedChanges(false);
  };

  return (
    <Stack css={[styles.textBreak, styles.width100]} spacing={4}>
      <TextField
        name="label"
        label={t("Label")}
        onChange={(e) => setLabel(e.target.value)}
        value={label}
      />

      <Alert
        message={alertMessage || ""}
        showAlert={!!alertMessage}
        severity={formStatus || "warning"}
      />

      <Box component="div" css={styles.flexCenter}>
        <Button css={[styles.width100, styles.widthLimit20]} onClick={handleFormSubmit}>
          {t("Save Changes")}
        </Button>
      </Box>

      <Modal
        onClose={() => setOpenModal(null)}
        open={!!openModal}
        fullWidth
        label={openModal ? getTranslatedModalTitle(openModal) : ""}
        unsavedChanges={unsavedChanges}
        setUnsavedChanges={setUnsavedChanges}
      ></Modal>
    </Stack>
  );
};

export default PidNodeTextForm;
