import React, { useState } from "react";
import { Box, Grid, Stack, useTheme } from "@mui/material";
import { Formik } from "formik";
import {
  AddNewSubcategoryFormValues,
  ModalTitle,
  formatDate,
  getDefaultSubcategoryValidFromDate,
  isCurrentYear,
  isNextYear,
} from "./oEEUtils";
import { object } from "yup";
import { AutocompleteOption, FormStatuses } from "../../../Global/Types/commonTypes";
import cssLayoutStyles from "../../../Global/Styles/layout";
import cssSpacingStyles from "../../../Global/Styles/spacing";
import cssComponentsStyles from "../../../Global/Styles/components";
import Button from "../../MaterialUI/Button";
import Alert from "../../MaterialUI/Alert";
import TextField from "../../MaterialUI/FormFields/TextFields";
import {
  YUP_DATE,
  YUP_REQUIRED_STRING,
  YUP_STRING,
} from "../../../Global/Constants/yupConstants";
import DatePicker from "../../MaterialUI/DateTimePickers/DatePicker";
import Select from "../../MaterialUI/FormFields/Select";
import callApi from "../../../Api/callApi";
import { useAuthedContext } from "../../../context/AuthContext";
import {
  postQueryCreateSubcategory,
  putQuerySubcategory,
} from "../../../Api/OEE/apiOEEPostQueries";
import {
  PostQueryCreateSubcategoryInput,
  PutQuerySubcategoryInput,
} from "../../../Api/OEE/apiOEEInputs";
import { useDetectFormsUnsavedChanges } from "../../../Global/Hooks/useDetectFormsUnsavedChanges";
import { useLanguageContext } from "../../../context/LanguageContext";

const fieldValidation = object({
  name: YUP_REQUIRED_STRING,
  description: YUP_STRING,
  valid_from: YUP_DATE,
  main_category: YUP_REQUIRED_STRING,
});

interface OEEAddSubcategoryFormProps {
  oEEMainCategoriesOptions: AutocompleteOption[];
  fetchOEEData: () => void;
  setModalTitle: React.Dispatch<React.SetStateAction<ModalTitle | null>>;
  setOpenInfo?: React.Dispatch<React.SetStateAction<boolean>>;
  subcategoryData?: AddNewSubcategoryFormValues;
  subcategoryId?: string;
  handleSetUnsavedChanges: (unsavedChanges: boolean) => void;
  setUnsavedChanges: React.Dispatch<React.SetStateAction<boolean>>;
}

const OEEAddSubcategoryForm: React.FC<OEEAddSubcategoryFormProps> = ({
  oEEMainCategoriesOptions,
  fetchOEEData,
  setModalTitle,
  setOpenInfo,
  subcategoryData,
  subcategoryId,
  handleSetUnsavedChanges,
  setUnsavedChanges,
}) => {
  const { t } = useLanguageContext();
  const theme = useTheme();
  const styles = {
    ...cssLayoutStyles,
    ...cssSpacingStyles(theme),
    ...cssComponentsStyles(theme),
  };
  const { setAuthedUser } = useAuthedContext();
  const [formStatus, setFormStatus] = useState<FormStatuses>(null);
  const [alertMessage, setAlertMessage] = useState<string | null>(null);
  const [defaultDate] = useState<Date>(getDefaultSubcategoryValidFromDate());

  const findMainCategoryOption = (
    maincategory: string,
    options: AutocompleteOption[]
  ) => {
    return options.find((option) => option.description === maincategory)?.value || "";
  };

  const initialValues: AddNewSubcategoryFormValues = {
    name: subcategoryData?.name || "",
    description: subcategoryData?.description || "",
    valid_from: subcategoryData?.valid_from
      ? typeof subcategoryData.valid_from === "string"
        ? new Date(subcategoryData.valid_from.split("/").reverse().join("-"))
        : subcategoryData.valid_from
      : subcategoryData
      ? null
      : defaultDate,
    main_category: findMainCategoryOption(
      subcategoryData?.main_category || "",
      oEEMainCategoriesOptions
    ),
  };

  const handleFormSubmit = async (values: AddNewSubcategoryFormValues) => {
    try {
      setFormStatus("loading");
      setAlertMessage(null);

      if (!values.name || !values.valid_from || !values.main_category) {
        setFormStatus("warning");
        setAlertMessage(t("All required fields must be filled out"));
        return;
      }

      if (subcategoryData && subcategoryId) {
        const formattedValues: PutQuerySubcategoryInput = {
          name: values.name,
          description: values.description,
          valid_from: values.valid_from ? formatDate(values.valid_from as Date) : "",
        };

        const updatedSubcategory = await callApi<string>({
          query: putQuerySubcategory(formattedValues, subcategoryId),
          auth: { setAuthedUser },
        });

        if (updatedSubcategory) {
          setFormStatus("success");
          setUnsavedChanges(false);
          fetchOEEData();
          setModalTitle(null);
          if (setOpenInfo !== undefined) {
            setOpenInfo(false);
          }
        }
      } else {
        const formattedValues: PostQueryCreateSubcategoryInput = {
          name: values.name,
          main_category: values.main_category,
          description: values.description,
          valid_from: values.valid_from ? formatDate(values.valid_from as Date) : "",
        };

        const newSubcategory = await callApi<string>({
          query: postQueryCreateSubcategory(formattedValues),
          auth: { setAuthedUser },
        });

        if (newSubcategory) {
          setFormStatus("success");
          fetchOEEData();
          setModalTitle(null);
        }
      }
    } catch (error) {
      console.error("OEEAddSubcategoryForm handleFormSubmit error:", error);
      setFormStatus("error");
      setAlertMessage(t("Something went wrong"));
    }
  };

  return (
    <Box component="div" pr={2}>
      <Formik
        initialValues={initialValues}
        onSubmit={handleFormSubmit}
        validationSchema={fieldValidation}
      >
        {({ handleSubmit, handleChange, touched, errors, values, setFieldValue }) => {
          useDetectFormsUnsavedChanges(initialValues, values, handleSetUnsavedChanges);

          return (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Select
                    css={styles.width100}
                    label={t("Main Category")}
                    name="main_category"
                    selectOptions={oEEMainCategoriesOptions}
                    value={values.main_category}
                    onChange={handleChange}
                    error={touched["main_category"] && !!errors["main_category"]}
                    helperText={touched["main_category"] && errors["main_category"]}
                    disabled={formStatus === "loading"}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    name="name"
                    label={t("Subcategory Name")}
                    error={touched["name"] && !!errors["name"]}
                    helperText={touched["name"] && errors["name"]}
                    onChange={handleChange}
                    value={values.name}
                    multiline
                  />
                </Grid>

                <Grid item xs={12}>
                  <DatePicker
                    name="valid_from"
                    label={t("Valid from")}
                    error={touched["valid_from"] && !!errors["valid_from"]}
                    helperText={touched["valid_from"] && errors["valid_from"]}
                    onChange={(value) => {
                      setFieldValue("valid_from", value);
                      if (value) {
                        if (isCurrentYear(value)) {
                          setAlertMessage(
                            t(
                              "Changing the default date might distort the current OEE statistics of the year."
                            )
                          );
                        } else if (
                          isNextYear(value) &&
                          value.getDate() === defaultDate.getDate() &&
                          value.getMonth() === defaultDate.getMonth()
                        ) {
                          setAlertMessage(null);
                        } else {
                          setAlertMessage(null);
                        }
                      }
                    }}
                    value={values.valid_from as Date | null}
                    css={styles.width100}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    name="description"
                    label={t("Description")}
                    error={touched["description"] && !!errors["description"]}
                    helperText={touched["description"] && errors["description"]}
                    onChange={handleChange}
                    value={values.description}
                    multiline
                  />
                </Grid>
              </Grid>

              <Stack spacing={1} justifyContent={"center"} alignItems={"center"} mt={2}>
                <Button
                  type="submit"
                  onClick={() => handleFormSubmit(values)}
                  loading={formStatus === "loading"}
                  css={[styles.width100, styles.widthLimit15]}
                >
                  {subcategoryData ? t("Edit") : t("Add")} {t("Subcategory")}
                </Button>

                <Alert
                  message={alertMessage || ""}
                  showAlert={!!alertMessage}
                  severity={formStatus}
                  css={styles.reverseLabelBreak}
                />
              </Stack>
            </form>
          );
        }}
      </Formik>
    </Box>
  );
};

export default OEEAddSubcategoryForm;
