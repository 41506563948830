import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  GetQueryUASubscriptionHistorySnippet,
  GetQueryUaSubscriptionIdSnippet,
} from "../../../../Api/DataSources/apiDSSnippets";
import { FormStatuses } from "../../../../Global/Types/commonTypes";
import callApi from "../../../../Api/callApi";
import { getQueryUaSubscriptionId } from "../../../../Api/DataSources/apiDSGetQueries";
import { Box, Grid, Stack, Typography } from "@mui/material";
import ContentBox from "../../../../Components/MaterialUI/ContentBox";
import Alert from "../../../../Components/MaterialUI/Alert";
import useTheme from "@mui/material/styles/useTheme";
import cssSpacingStyles from "../../../../Global/Styles/spacing";
import cssLayoutStyles from "../../../../Global/Styles/layout";
import LabelWithBoldedPart from "../../../../Components/MaterialUI/LabelWithBoldedPart";
import Collapse from "../../../../Components/MaterialUI/Collapse";
import OpcConnectionHistory from "../../../../Components/PageComponents/Integrator/Connections/OpcConnectionHistory";
import { useAuthedContext } from "../../../../context/AuthContext";
import Button from "../../../../Components/MaterialUI/Button";
import { INTEGRATOR_EDIT_INFO_TEXT } from "../../../../Components/PageComponents/Integrator/Connections/opcConnectionUtils";
import UaConnection from "../../../../Components/PageComponents/Integrator/CreateOrEditConnection/UaConnection";
import PageNotFound from "../../../../Components/SmallComponents/PageNotFound/PageNotFound";
import { useLanguageContext } from "../../../../context/LanguageContext";

type Mode = "initial" | "edit";
const CONNECTION_PAGE_URL = "/GIANT-Toolbox-Manager/Integrator/Connections";

const OpcUaConnectionInformationPage: React.FC = () => {
  const { t } = useLanguageContext();
  const theme = useTheme();
  const styles = { ...cssSpacingStyles(theme), ...cssLayoutStyles };
  const [subscription, setSubscription] =
    useState<GetQueryUaSubscriptionIdSnippet | null>(null);
  const [pageStatus, setPageStatus] = useState<FormStatuses>(null);
  const [alertMessage, setAlertMessage] = useState<string | null>(null);
  const [hideHistory, setHideHistory] = useState<boolean>(false);
  const [historyData, setHistoryData] = useState<GetQueryUASubscriptionHistorySnippet>(
    []
  );
  const [mode, setMode] = useState<Mode>("initial");

  const params = useParams();
  const { setAuthedUser } = useAuthedContext();

  useEffect(() => {
    (async () => {
      await handleFetchData();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id]);

  const handleFetchData = async () => {
    try {
      if (!params.id) {
        throw new Error("ID from params not found for this OPC UA subscription");
      }

      setPageStatus("loading");
      setAlertMessage(t("Loading..."));
      const data = await callApi<GetQueryUaSubscriptionIdSnippet>({
        query: getQueryUaSubscriptionId(params.id),
        auth: { setAuthedUser },
      });
      setSubscription(data);
      setPageStatus("success");
      setAlertMessage(null);
    } catch (err) {
      console.log("useEffect on mount err ", err);
      setPageStatus("error");
      setAlertMessage(t("Something went wrong"));
    }
  };

  const handleBackToInitialMode = async () => {
    setMode("initial");
    await handleFetchData();
  };

  if (!params.id) {
    return <PageNotFound />;
  }

  return (
    <Box component="div">
      {mode === "initial" ? (
        <>
          <Alert
            css={[styles.widthLimit25, styles.contentBreak]}
            message={alertMessage}
            showAlert={!!alertMessage}
            severity={pageStatus}
          />
          <Grid css={styles.labelBreak} container spacing={2}>
            <Grid item xs={12} md={6}>
              <ContentBox css={styles.height100} label={t("Current Configuration")}>
                {subscription?.id ? (
                  <Collapse in={!!subscription?.id}>
                    <Stack spacing={1} justifyContent="center">
                      <LabelWithBoldedPart
                        text={"Status"}
                        bolded={`${
                          subscription.status === "active"
                            ? t("Active")
                            : subscription.status === "inactive"
                            ? t("Not active")
                            : t("Reconnecting...")
                        }`}
                      />
                      <LabelWithBoldedPart text={t("Type")} bolded="OPC UA" />
                      <LabelWithBoldedPart
                        text={t("Server URL")}
                        bolded={`${subscription.server_url}`}
                      />
                      <LabelWithBoldedPart
                        text={t("Name")}
                        bolded={`${subscription.name}`}
                      />
                      <LabelWithBoldedPart
                        text={t("Update Rate")}
                        bolded={`${subscription.update_rate}`}
                      />
                    </Stack>
                  </Collapse>
                ) : null}
              </ContentBox>
            </Grid>
            <Grid item xs={12} md={6}>
              <ContentBox
                css={styles.height100}
                label={t("Edit Connection Configuration")}
              >
                <Stack
                  spacing={2}
                  alignItems="center"
                  justifyContent="space-between"
                  css={styles.height100}
                >
                  <Typography css={styles.widthLimit55} variant="body1">
                    {t(INTEGRATOR_EDIT_INFO_TEXT)}
                  </Typography>
                  <Button onClick={() => setMode("edit")} variant="contained">
                    {t("Edit Connection")}
                  </Button>
                </Stack>
              </ContentBox>
            </Grid>
          </Grid>

          {subscription?.id ? (
            <OpcConnectionHistory
              subID={params.id}
              setHideHistory={setHideHistory}
              hideHistory={hideHistory}
              connection={{
                ua: {
                  history: historyData,
                  setHistory: setHistoryData,
                },
              }}
            />
          ) : null}
        </>
      ) : null}

      {mode === "edit" && subscription?.id ? (
        <ContentBox>
          <UaConnection
            editSubForm={{
              sub: subscription,
            }}
            handleStartOver={handleBackToInitialMode}
            onSuccessUrl={CONNECTION_PAGE_URL}
            title={t("Edit Connection Configuration")}
          />
        </ContentBox>
      ) : null}
    </Box>
  );
};

export default OpcUaConnectionInformationPage;
