import { Accept, FileWithPath, useDropzone } from "react-dropzone";
import cssLayoutStyles from "../../../Global/Styles/layout";
import { SerializedStyles } from "@emotion/react";
import { Box } from "@mui/material";

interface FileDropzoneProps {
  css?: SerializedStyles[] | SerializedStyles;
  className?: string;
  setFiles: (files: FileWithPath[]) => void;
  setErrorMessage: React.Dispatch<React.SetStateAction<string | null>>;
  children?: React.ReactElement;
  useCamera?: boolean;
  handleOnDrop?: () => void;
  accept?: Accept;
  multiFileUpload?: boolean;
  acceptVideos?: boolean;
  acceptImages?: boolean;
}

const FileDropzone: React.FC<FileDropzoneProps> = ({
  className,
  setFiles,
  setErrorMessage,
  children,
  useCamera,
  handleOnDrop,
  accept,
  multiFileUpload,
  acceptVideos = false,
  acceptImages = false,
}) => {
  const styles = {
    ...cssLayoutStyles,
  };

  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: undefined,
    accept: accept
      ? accept
      : acceptVideos
      ? {
          "video/*": [],
        }
      : acceptImages
      ? {
          "image/*": [],
        }
      : {
          "image/*": [],
        },
    onDrop: async (acceptedFiles) => {
      setErrorMessage(null);
      if (!multiFileUpload && acceptedFiles.length > 1) {
        setErrorMessage(`Only one file can be uploaded.`);
        return;
      }
      let notAllowedMessage: string | null = null;

      acceptedFiles.forEach((file) => {
        if (!file?.type) {
          if (typeof accept === "object" && Object.keys(accept).length > 0) {
            const allowedFormats = Object.values(accept).flat();
            const uploadedFormat = file?.type;

            if (!allowedFormats.includes(uploadedFormat)) {
              const allowedTypes = Object.keys(accept).join(", ");
              notAllowedMessage = `Invalid file format. Allowed file formats: ${allowedTypes}.`;
            }
          }
        }
      });

      if (notAllowedMessage) {
        setErrorMessage(notAllowedMessage);
        return;
      }
      setFiles(acceptedFiles);

      if (handleOnDrop) {
        handleOnDrop();
      }
    },
  });

  return (
    <Box component="div" className={className}>
      <Box
        component="div"
        css={[styles.pointer, styles.height100, styles.width100]}
        {...getRootProps()}
      >
        <input {...getInputProps()} capture={!!useCamera} />
        {children ? children : null}
      </Box>
    </Box>
  );
};

export default FileDropzone;
