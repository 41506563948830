import React, { useEffect, useState } from "react";
import { Box, Typography, Stack } from "@mui/material";
import {
  findTextInNavItems,
  getSelectionOrder,
  ModalHelpTitle,
  modalHelpTitleTranslations,
  PageKey,
} from "../../Components/PageComponents/Help/helpUtils";
import {
  GetQueryHelpDraftSelectionsForPage,
  GetQueryHelpPageKeysSnippet,
  GetQueryHelpSelectionsForPage,
} from "../../Api/Help/apiHelpSnippets";
import callApi from "../../Api/callApi";
import { useAuthedContext } from "../../context/AuthContext";
import {
  getQueryHelpDraftSelections,
  getQueryHelpPageKeys,
  getQueryHelpPageSelections,
} from "../../Api/Help/apiHelpGetQueries";
import Button from "../../Components/MaterialUI/Button";
import Modal from "../../Components/MaterialUI/Modal";
import AddHelpContentForm from "../../Components/PageComponents/Help/AddHelpContentForm";
import { getModulePermissions } from "../../context/authContextUtils";
import HelpContentSelectionCard from "../../Components/PageComponents/Help/HelpContentSelectionCard";
import { useLanguageContext } from "../../context/LanguageContext";
import { FormStatuses } from "../../Global/Types/commonTypes";
import ReportBugProposeAnImprovementForm from "../../Components/PageComponents/Help/ReportBugProposeAnImprovementForm";
import { useTranslatedModalTitle } from "../../Global/Hooks/useTranslations";
import {
  deleteQueryHelpSelection,
  postQueryHelpCreateDraft,
  postQueryHelpCreateSelection,
} from "../../Api/Help/apiHelpPostQueries";
import HelpContentDisplay from "../../Components/PageComponents/Help/HelpContentDisplay";
import { isArray } from "../../Global/Utils/commonFunctions";
import HelpPageButtons from "../../Components/PageComponents/Help/HelpPageButtons";

interface HelpContentProps {
  locationPath: string;
}

const HelpPage: React.FC<HelpContentProps> = ({ locationPath }) => {
  const { t } = useLanguageContext();
  const { authedUser, setAuthedUser } = useAuthedContext();
  const userHelpPermissions = getModulePermissions(authedUser, "help");
  const getTranslatedModalTitle = useTranslatedModalTitle(modalHelpTitleTranslations);
  const [modalTitle, setModalTitle] = useState<ModalHelpTitle | null>(null);
  const [formStatus, setFormStatus] = useState<FormStatuses>(null);
  const [alertMessage, setAlertMessage] = useState<string | null>(null);
  const [unsavedEditChanges, setUnsavedEditChanges] = useState<boolean>(false);
  const [doOpenUnsavedChangesModal, setDoOpenUnsavedChangesModal] =
    useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [currentPage, setCurrentPage] = useState<PageKey>();
  const [pageTitle, setPageTitle] = useState<string>("");
  const [editorContent, setEditorContent] = useState<string>("");
  const [uploadType, setUploadType] = useState<"video" | "image" | null>(null);
  const [isEditMode, setIsEditMode] = useState(false);
  const [localHelpSelections, setLocalHelpSelections] =
    useState<GetQueryHelpSelectionsForPage>([]);
  const [tempSelections, setTempSelections] = useState<GetQueryHelpSelectionsForPage>([]);
  const [allSelections, setAllSelections] = useState<GetQueryHelpSelectionsForPage>([]);
  const [draftSelections, setDraftSelections] = useState<GetQueryHelpSelectionsForPage>(
    []
  );

  const canUserEditAddDelete =
    userHelpPermissions?.edit && userHelpPermissions?.add && userHelpPermissions?.delete;

  useEffect(() => {
    if (isEditMode) {
      setAllSelections(() => [...draftSelections, ...tempSelections]);
    } else {
      setAllSelections(() => localHelpSelections);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localHelpSelections, tempSelections, draftSelections]);

  useEffect(() => {
    fetchHelpContent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationPath]);

  const handleSetUnsavedChanges = (unsavedChanges: boolean) => {
    if (unsavedChanges) {
      setUnsavedEditChanges(true);
    }
  };

  const handleExitEditMode = () => {
    if (unsavedEditChanges) {
      setDoOpenUnsavedChangesModal(true);
    } else {
      setModalTitle(null);
      toggleEditMode();
    }
  };

  const toggleEditMode = () => {
    if (currentPage) {
      fetchDraft(currentPage.id);
    }
    setIsEditMode((prev) => !prev);
    setTempSelections([]);
    setAlertMessage("");
  };

  const handleSaveChanges = async () => {
    try {
      setFormStatus("loading");
      setAlertMessage(t("Loading..."));

      if (currentPage) {
        if (localHelpSelections.length > 0) {
          for (const localHelpSelection of localHelpSelections) {
            if (localHelpSelection.id) {
              await callApi({
                query: deleteQueryHelpSelection(localHelpSelection.id),
                auth: { setAuthedUser },
              });
            }
          }
        }

        if (allSelections.length > 0) {
          const allSelectionsWithoutId = allSelections.map(
            ({ id, draft_id, ...rest }) => rest
          );

          await callApi({
            query: postQueryHelpCreateSelection(currentPage.id, allSelectionsWithoutId),
            auth: { setAuthedUser },
          });

          setTempSelections([]);
        }

        fetchHelpContent();
        setFormStatus("success");
        setAlertMessage(t("Successfully published"));
        setModalTitle(null);
        setUnsavedEditChanges(false);
        handleSaveDraft();
      }
    } catch (error) {
      console.log(error);
      setFormStatus("error");
      setAlertMessage(t("Something went wrong"));
    }
  };

  const handleSaveDraft = async () => {
    try {
      setFormStatus("loading");
      setAlertMessage(t("Loading..."));

      if (currentPage) {
        if (allSelections.length > 0) {
          const allSelectionsWithoutId = allSelections.map(({ id, ...rest }) => rest);
          const input = { selections: allSelectionsWithoutId };

          await callApi({
            query: postQueryHelpCreateDraft(currentPage.id, input),
            auth: { setAuthedUser },
          });
          setTempSelections([]);
          fetchDraft(currentPage.id);
        }

        toggleEditMode();
        setModalTitle(null);
        setUnsavedEditChanges(false);
        setFormStatus("success");
        setAlertMessage(t("Successfully saved draft"));
      }
    } catch (error) {
      console.log(error);
      setFormStatus("error");
      setAlertMessage(t("Something went wrong"));
    }
  };

  const fetchHelpContent = async () => {
    try {
      setLoading(true);
      const foundPageText = findTextInNavItems(locationPath);

      if (foundPageText) {
        setPageTitle(foundPageText);
        const pageKeys = await callApi<GetQueryHelpPageKeysSnippet>({
          query: getQueryHelpPageKeys(),
          auth: { setAuthedUser },
        });
        const page = pageKeys.find((key) => key.page_key === foundPageText);

        if (page) {
          setCurrentPage(page);
          const helpSelections = await callApi<GetQueryHelpSelectionsForPage>({
            query: getQueryHelpPageSelections(page.id),
            auth: { setAuthedUser },
          });
          setLocalHelpSelections(helpSelections);
          fetchDraft(page.id);
        }
      }

      setLoading(false);
    } catch (error) {
      console.log("Failed to load Help content", error.message);
      setFormStatus("error");
      setAlertMessage(t("Something went wrong"));
    }
  };

  const fetchDraft = async (pageId: string) => {
    const draftSelections = await callApi<GetQueryHelpDraftSelectionsForPage>({
      query: getQueryHelpDraftSelections(pageId),
      auth: { setAuthedUser },
    });

    if (isArray(draftSelections)) {
      setDraftSelections([]);
    } else {
      setDraftSelections(draftSelections.selections);
    }
  };

  const handleModalOpen = (label: ModalHelpTitle) => {
    if (label === "Add Help Content") {
      setEditorContent("");
    }
    setModalTitle(label);
  };

  const handleModalClose = () => {
    setModalTitle(null);
    if (modalTitle === "Save Changes" || doOpenUnsavedChangesModal) {
      toggleEditMode();
    }
  };

  return (
    <>
      <Box
        component="div"
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        height="100%"
        padding={2}
      >
        <Box component="div">
          <Typography variant="h4" mb={2}>
            {pageTitle}
          </Typography>

          {loading ? (
            <Typography variant="body2">{t("Loading...")}</Typography>
          ) : (
            <>
              {allSelections.length === 0 ? (
                <Typography variant="body2">
                  {t("No help content available at the moment.")}
                </Typography>
              ) : (
                allSelections.map((pageSelection) =>
                  isEditMode ? (
                    <HelpContentSelectionCard
                      key={pageSelection.id}
                      helpSelection={pageSelection}
                      canUserEditAddDelete={canUserEditAddDelete}
                      order={getSelectionOrder(allSelections)}
                      setModalTitle={setModalTitle}
                      editorContent={editorContent}
                      setEditorContent={setEditorContent}
                      uploadType={uploadType}
                      setUploadType={setUploadType}
                      currentPage={currentPage}
                      setUnsavedEditChanges={setUnsavedEditChanges}
                      setTempSelections={setTempSelections}
                      draftSelections={draftSelections}
                      setDraftSelections={setDraftSelections}
                      setFormStatus={setFormStatus}
                      setAlertMessage={setAlertMessage}
                    />
                  ) : (
                    <Box component="div" key={pageSelection.id}>
                      <HelpContentDisplay
                        type={pageSelection.type}
                        content={pageSelection.content}
                      />
                    </Box>
                  )
                )
              )}
            </>
          )}
        </Box>

        <HelpPageButtons
          isEditMode={isEditMode}
          canUserEditAddDelete={canUserEditAddDelete}
          alertMessage={alertMessage}
          formStatus={formStatus}
          handleModalOpen={handleModalOpen}
          toggleEditMode={toggleEditMode}
          handleExitEditMode={handleExitEditMode}
          allSelections={allSelections}
          setUnsavedEditChanges={setUnsavedEditChanges}
          uploadType={uploadType}
          setUploadType={setUploadType}
          setAlertMessage={setAlertMessage}
          setFormStatus={setFormStatus}
          setTempSelections={setTempSelections}
          setDraftSelections={setDraftSelections}
          currentPage={currentPage}
        />
      </Box>

      <Modal
        open={!!modalTitle}
        onClose={handleModalClose}
        fullWidth
        maxWidth={modalTitle === "Save Changes" ? "sm" : "md"}
        label={modalTitle ? getTranslatedModalTitle(modalTitle) : ""}
        unsavedChanges={unsavedEditChanges}
        setUnsavedChanges={setUnsavedEditChanges}
        doOpenUnsavedChangesModal={doOpenUnsavedChangesModal}
        setDoOpenUnsavedChangesModal={setDoOpenUnsavedChangesModal}
      >
        {modalTitle === "Add Help Content" ? (
          <AddHelpContentForm
            order={getSelectionOrder(allSelections)}
            setModalTitle={setModalTitle}
            handleSetUnsavedChanges={handleSetUnsavedChanges}
            editorContent={editorContent}
            setEditorContent={setEditorContent}
            setUnsavedEditChanges={setUnsavedEditChanges}
            setTempSelections={setTempSelections}
            setDraftSelections={setDraftSelections}
          />
        ) : null}

        {modalTitle === "Report Bug" ? (
          <ReportBugProposeAnImprovementForm
            type={"bug"}
            handleSetUnsavedChanges={handleSetUnsavedChanges}
            setUnsavedEditChanges={setUnsavedEditChanges}
            setModalTitle={setModalTitle}
            setAlertMessage={setAlertMessage}
            formStatus={formStatus}
            setFormStatus={setFormStatus}
          />
        ) : null}

        {modalTitle === "Propose an Improvement" ? (
          <ReportBugProposeAnImprovementForm
            type={"feature_request"}
            handleSetUnsavedChanges={handleSetUnsavedChanges}
            setUnsavedEditChanges={setUnsavedEditChanges}
            setModalTitle={setModalTitle}
            setAlertMessage={setAlertMessage}
            formStatus={formStatus}
            setFormStatus={setFormStatus}
          />
        ) : null}

        {modalTitle === "Save Changes" ? (
          <Stack spacing={3} direction="row" alignItems="center" justifyContent="center">
            <Button onClick={handleSaveChanges} color="primary">
              {t("Publish Changes")}
            </Button>
            <Button
              onClick={handleSaveDraft}
              color="primary"
              variant="outlined"
              disabled={allSelections.length === 0}
            >
              {t("Save as Draft")}
            </Button>
          </Stack>
        ) : null}
      </Modal>
    </>
  );
};

export default HelpPage;
