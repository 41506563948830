import { LeftNavList } from "../layoutVariables";

export const findFavouriteItems = (
  favourites: string[],
  menuItems: LeftNavList
): LeftNavList => {
  const findInNested = (items: LeftNavList): LeftNavList => {
    return items.reduce((acc: LeftNavList, item) => {
      if (favourites.includes(item.text)) {
        acc.push(item);
      }
      if (item.nested) {
        const nestedMatches = findInNested(item.nested);
        acc.push(...nestedMatches);
      }

      return acc;
    }, []);
  };

  return findInNested(menuItems);
};
