import {
  BarColumnRadarData,
  ChartConfigFormValuesWithTitle,
  ExcellenceChartFormProps,
  ExcellenceParamMapping,
} from "../EditExcellenceChartForms/excellenceChartFormUtils";
import { BarChartConfiguration, BarChartDataSchema } from "./barChartTypes";
import ExcellenceChartFormWrapper from "../EditExcellenceChartForms/ExcellenceChartFormWrapper";
import useTheme from "@mui/material/styles/useTheme";
import { Grid, Typography } from "@mui/material";
import cssLayoutStyles from "../../../Global/Styles/layout";
import cssSpacingStyles from "../../../Global/Styles/spacing";
import { useEffect, useState } from "react";
import { DynamicGridChartOptions } from "../../SmallComponents/DynamicGridLayout.tsx/dynamicGridUtils";
import EditBarChartConfig from "./EditBarChartConfig";
import { AutocompleteOption, FormStatuses } from "../../../Global/Types/commonTypes";
import Alert from "../../MaterialUI/Alert";
import LoadingBackdrop from "../../MaterialUI/LoadingBackdrop";
import { useLazyQuery } from "@apollo/client";
import { graphQlQueryExcellenceBarData } from "../../../GraphQL/Excellence/graphQLQueriesExcellence";
import EditBarChartDataSchema from "./EditBarChartDataSchema";
import ExcellenceBarChart from "../../PageComponents/Excellence/ExcellenceCharts/ExcellenceBarChart/ExcellenceBarChart";
import barChartDefaultData from "../ExcellenceDefaultConfig/barChartDefaultData";
import { useLanguageContext } from "../../../context/LanguageContext";
import useExcellenceChartUnsavedChanges from "../../../Global/Hooks/useExcellenceChartUnsavedChanges";
import {
  buildGraphqlFilters,
  GraphqlFilter,
} from "../../SmallComponents/GraphqlFilters/graphqlFiltersUtils";

interface EditBarChartFormProps extends Omit<ExcellenceChartFormProps, "dataSchema"> {
  config: BarChartConfiguration;
  data: BarColumnRadarData;
  isColumnChart?: boolean;
  dataSchema: BarChartDataSchema | null;
}

const EditBarChartForm: React.FC<EditBarChartFormProps> = ({
  chartClass,
  config,
  dataSchema,
  data,
  widgetTitle,
  handleSaveChanges,
  parameters,
  isColumnChart,
  handleSetUnsavedChanges,
}) => {
  const theme = useTheme();
  const styles = {
    ...cssLayoutStyles,
    ...cssSpacingStyles(theme),
  };
  const { t } = useLanguageContext();
  const [tabValue, setTabValue] = useState(0);

  const { updatedConfig, setUpdatedConfig, updatedSchema, setUpdatedSchema } =
    useExcellenceChartUnsavedChanges<
      ChartConfigFormValuesWithTitle<BarChartConfiguration>,
      BarChartDataSchema | null
    >({
      initialConfig: {
        title: widgetTitle,
        ...config,
      },
      initialSchema: dataSchema as BarChartDataSchema,
      handleSetUnsavedChanges,
    });

  // tab number 0
  const [fieldAlert, setFieldAlert] = useState<string | null>(null);

  // tab number 1
  const [updatedData, setUpdatedData] = useState<BarColumnRadarData>(data);
  const [fetchStatus, setFetchStatus] = useState<FormStatuses>(null);
  const [resetChartFetch, setResetChartFetch] = useState<boolean>(false);
  // data tab
  const [dateFrom, setDateFrom] = useState<Date | null>(
    dataSchema?.startTime ? new Date(dataSchema.startTime) : null
  );
  const [dateTo, setDateTo] = useState<Date | null>(
    dataSchema?.endTime ? new Date(dataSchema.endTime) : null
  );
  const [selectedConnection, setSelectedConnection] = useState<AutocompleteOption>({
    value: '',
    description: ''
  });
  const [graphqlFilters, setGraphqlFilters] = useState<GraphqlFilter[]>(
    config?.graphqlFilters || []
  );
  const [isLegendEnabled, setIsLegendEnabled] = useState<boolean>(
    config?.filtersToDashboard?.isLegendEnabled ?? true
  );
  const [isGroupParameterEnabled, setIsGroupParameterEnabled] = useState<boolean>(
    config?.filtersToDashboard?.isGroupParameterEnabled ?? true
  );
  const [initialSetupComplete, setInitialSetupComplete] = useState<boolean>(false);
  const [paramMapping, setParamMapping] = useState<ExcellenceParamMapping | null>(null);

  const [getBarData] = useLazyQuery(graphQlQueryExcellenceBarData);

  useEffect(() => {
    const mapping: ExcellenceParamMapping = {};
    parameters?.parameters.forEach((param) => {
      if (param.id && !mapping?.[param.id]) {
        mapping[param.id] = {
          connection: param.container,
          paramID: param.id,
          paramName: param.name,
          connectionName: param.containerName,
          type: param.type,
        };
      }
    });
    setParamMapping(mapping);
  }, [parameters]);

  useEffect(() => {
    if (paramMapping && initialSetupComplete && Object.keys?.(paramMapping)?.[0]) {
      setGraphqlFilters((prev) =>
        prev.filter((item) => {
          const paramConn = paramMapping[item.paramID].connection;
          return selectedConnection?.value === paramConn;
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialSetupComplete, selectedConnection]);

  useEffect(() => {
    if ( graphqlFilters && graphqlFilters.length ) {
      setUpdatedConfig((prev) => ({
        ...prev,
        graphqlFilters
      }));
    }
    (async () => {
      if (updatedSchema) {
        try {
          setFetchStatus("loading");
          const result = await getBarData({
            variables: {
              input: { ...updatedSchema, filters: buildGraphqlFilters(graphqlFilters) },
            },
          });
          if (!result.data || result.error) {
            throw new Error(JSON.stringify(result));
          }
          const resultData = result.data.barchartData.chartData;

          setUpdatedData(resultData);
          setResetChartFetch((prev) => !prev);
          setFetchStatus(null);
        } catch (err) {
          console.log("err ", err);
          setFetchStatus("error");
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatedSchema, graphqlFilters]);

  const handleSubmit = () => {
    const { title, ...otherConfig } = updatedConfig;
    const updatedChart: DynamicGridChartOptions = isColumnChart
      ? {
          columnChart: {
            config: { ...otherConfig, graphqlFilters },
            data: updatedData,
            dataSchema: updatedSchema,
          },
        }
      : {
          barChart: {
            config: { ...otherConfig, graphqlFilters },
            data: updatedData,
            dataSchema: updatedSchema,
          },
        };

    // do some field checks
    if (!title) {
      setFieldAlert(t("Title is required"));
    } else {
      // all fields checks have passed
      handleSaveChanges(updatedChart, title);
    }
  };

  const setLegendFilter = (isVisible: boolean | null) => {
    setIsLegendEnabled((prev) => isVisible !== null ? isVisible : !prev);
    setUpdatedConfig((prev) => {
      const updatedIsLegendEnabled = !(prev?.filtersToDashboard?.isLegendEnabled ?? false);
      return {
        ...prev,
        filtersToDashboard: {
          ...prev.filtersToDashboard,
          isLegendEnabled: isVisible !== null ? isVisible : updatedIsLegendEnabled,
        },
      };
    });
  }

  const setGroupParameterFilter = (isVisible: boolean | null) => {
    setIsGroupParameterEnabled((prev) => isVisible !== null ? isVisible : !prev);
    setUpdatedConfig((prev) => {
      const updatedIsGroupParameterEnabled = !(prev?.filtersToDashboard?.isGroupParameterEnabled ?? false);
      return {
        ...prev,
        filtersToDashboard: {
          ...prev.filtersToDashboard,
          isGroupParameterEnabled: isVisible !== null ? isVisible : updatedIsGroupParameterEnabled,
        },
      }
    });
  }

  return (
    <ExcellenceChartFormWrapper
      alertMessage={fieldAlert}
      handleSubmit={handleSubmit}
      tabValue={tabValue}
      setTabValue={setTabValue}
      disabledDataTab={false}
    >
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          {tabValue === 0 ? (
            <EditBarChartConfig
              config={updatedConfig}
              setUpdatedConfig={setUpdatedConfig}
              isColumn={isColumnChart}
            />
          ) : null}

          {tabValue === 1 ? (
            <EditBarChartDataSchema
              parameters={parameters?.parameters || []}
              dataSchema={updatedSchema}
              handleUpdateDataSchema={(val) => setUpdatedSchema(val)}
              dateFrom={dateFrom}
              setDateFrom={setDateFrom}
              dateTo={dateTo}
              setDateTo={setDateTo}
              selectedConnection={selectedConnection}
              setSelectedConnection={setSelectedConnection}
              graphqlFilters={graphqlFilters}
              setGraphqlFilters={setGraphqlFilters}
              initialSetupComplete={initialSetupComplete}
              setInitialSetupComplete={setInitialSetupComplete}
              paramMapping={paramMapping}
              isLegendEnabled={isLegendEnabled}
              isGroupParameterEnabled={isGroupParameterEnabled}
              setLegendFilter={setLegendFilter}
              setGroupParameterFilter={setGroupParameterFilter}
            />
          ) : null}

          <Alert
            css={styles.reverseContentBreak}
            message={t("Something went wrong")}
            showAlert={fetchStatus === "error"}
            severity={"error"}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <Typography css={styles.labelBreak} variant="h3">
            {updatedConfig.title}
          </Typography>
          <ExcellenceBarChart
            css={chartClass}
            configuration={updatedConfig}
            data={!updatedSchema ? barChartDefaultData : updatedData}
            schema={updatedSchema}
            resetChartFetch={resetChartFetch}
            dataFetchFromParent
            parameters={parameters?.parameters}
            tempFilters={[]}
          />
        </Grid>
      </Grid>

      <LoadingBackdrop loading={fetchStatus === "loading"} />
    </ExcellenceChartFormWrapper>
  );
};

export default EditBarChartForm;
