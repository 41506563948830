import { Query } from "../callApi";

export const getQueryUserDeputy = (user_deputy_id: string): Query => ({
  endpoint: `users/access_control/user_deputy/${user_deputy_id}`,
  method: "GET",
});

export const getQueryAllUserDeputy = (): Query => ({
  endpoint: `users/access_control/user_deputies`,
  method: "GET",
});

export const getQueryDeputyUsersSearchList = (searchQuery: string): Query => ({
  endpoint: `users/access_control/users/privileges/excellence?search_query=${searchQuery}`,
  method: "GET",
});

export const getQueryPossibleDeputyUsers = (): Query => ({
  endpoint: `users/access_control/possible_deputy_users`,
  method: "GET",
});
