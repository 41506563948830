import { useEffect, useMemo, useState } from "react";
import {
  DndContext,
  DragEndEvent,
  DragOverEvent,
  DragOverlay,
  DragStartEvent,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { SortableContext, arrayMove } from "@dnd-kit/sortable";
import { createPortal } from "react-dom";
import { KanbanBoardColumn, KanbanBoardId } from "./kanbanBoardUtils";
import KanbanBoardTaskCard from "./KanbanBoardTaskCard";
import KanbanBoardColumnContainer from "./KanbanBoardColumnContainer";
import { Box, Stack, Typography } from "@mui/material";
import Modal from "../../MaterialUI/Modal";
import {
  generateKanAlerts,
  KanbanTask,
} from "../../../pages/TaskManager/Components/taskManagerUtils";
import ViewKanbanTask from "../../../pages/TaskManager/Components/KanbanTask/ViewKanbanTask";
import { PostQueryKanbanTaskCheckMoveSnippet } from "../../../pages/KanbanWorkflows/api/mutations";
import { v4 as uuidv4 } from "uuid";
import { useAuthedContext } from "../../../context/AuthContext";
import { useLanguageContext } from "../../../context/LanguageContext";

type DroppedTaskModal = {
  showModal: boolean;
  message?: string;
  allowedToDrop: boolean;
  droppedTaskID: string;
  droppedColumnID: string;
  isSameColumn: boolean;
} | null;

type ColumnsMapping = Record<string, KanbanBoardColumn>;

interface KanbanBoardProps {
  tasks: KanbanTask[];
  handleUpdateTask: (updated: KanbanTask) => Promise<void>;
  handleCheckTaskMove: (
    taskID: string,
    nodeID: string
  ) => Promise<PostQueryKanbanTaskCheckMoveSnippet | undefined>;
  refetchTasks: () => Promise<void>;
  columns: KanbanBoardColumn[];
  setColumns: React.Dispatch<React.SetStateAction<KanbanBoardColumn[]>>;
  disableColDrag?: boolean;
  disableColNameChange?: boolean;
}

const KanbanBoard: React.FC<KanbanBoardProps> = ({
  tasks,
  refetchTasks,
  handleCheckTaskMove,
  columns,
  setColumns,
  disableColDrag,
  disableColNameChange,
}) => {
  const { t } = useLanguageContext();
  const [stateTasks, setStateTasks] = useState<KanbanTask[]>(() => tasks);
  const [activeColumn, setActiveColumn] = useState<KanbanBoardColumn | null>(null);
  const [activeTask, setActiveTask] = useState<KanbanTask | null>(null);
  const [openedTask, setOpenedTask] = useState<KanbanTask | null>(null);
  const [dropTaskModal, setDropTaskModal] = useState<DroppedTaskModal>(null);
  const [colsMapping, setColsMapping] = useState<ColumnsMapping>({});

  const columnsId = useMemo(() => columns.map((col) => col.id), [columns]);
  const { setReFetchAlerts } = useAuthedContext();

  console.log("tasks ", tasks);

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 10,
      },
    })
  );

  useEffect(() => {
    setStateTasks(() => tasks);
  }, [tasks]);

  useEffect(() => {
    const mapping: ColumnsMapping = {};
    columns.forEach((item) => {
      mapping[item.id] = item;
    });
    setColsMapping(() => mapping);
  }, [columns]);

  const handleCloseModal = () => {
    if (dropTaskModal?.showModal) {
      setStateTasks(tasks);
    }
    setDropTaskModal(null);
  };

  return (
    <Stack spacing={2} alignItems="center">
      <Box
        component="div"
        style={{
          display: "flex",
          width: "100%",
          alignItems: "center",
          justifyContent: "flex-start",
          overflowX: "auto",
          overflowY: "hidden",
          paddingLeft: "20px",
          paddingRight: "20px",
        }}
      >
        <DndContext
          sensors={sensors}
          onDragStart={onDragStart}
          onDragEnd={onDragEnd}
          onDragOver={onDragOver}
        >
          <Box
            component="div"
            style={{
              margin: "auto",
              display: "flex",
              gap: "1rem",
            }}
          >
            <Box
              component="div"
              style={{
                display: "flex",
                gap: "1rem",
              }}
            >
              <SortableContext items={columnsId}>
                {columns.map((col) => (
                  <KanbanBoardColumnContainer
                    key={col.id}
                    column={col}
                    updateTask={updateTask}
                    tasks={stateTasks.filter((task) => task.node_id === col.id)}
                    handleOnTaskClick={(task) => setOpenedTask(task)}
                    refetchTasks={refetchTasks}
                    disableColDrag={disableColDrag}
                    disableColNameChange={disableColNameChange}
                  />
                ))}
              </SortableContext>
            </Box>
            {/* <Button
            onClick={() => {
              createNewColumn();
            }}
            variant="outlined"
          >
            Add Column
          </Button> */}
          </Box>

          {createPortal(
            <DragOverlay>
              {activeColumn && (
                <KanbanBoardColumnContainer
                  column={activeColumn}
                  updateTask={updateTask}
                  tasks={stateTasks.filter((task) => task.node_id === activeColumn.id)}
                  handleOnTaskClick={(task) => setOpenedTask(task)}
                  refetchTasks={refetchTasks}
                  disableColDrag={disableColDrag}
                  disableColNameChange={disableColNameChange}
                />
              )}
              {activeTask && (
                <KanbanBoardTaskCard
                  task={activeTask}
                  handleOnClick={(task) => setOpenedTask(task)}
                  refetchTasks={refetchTasks}
                />
              )}
            </DragOverlay>,
            document.body
          )}
        </DndContext>

        <Modal
          open={!!openedTask}
          onClose={() => setOpenedTask(null)}
          fullWidth
          label={openedTask?.workflow_name || ""}
        >
          {openedTask?.id ? <ViewKanbanTask task={openedTask} /> : null}
        </Modal>
      </Box>

      <Modal
        open={!!dropTaskModal?.showModal}
        onClose={handleCloseModal}
        fullWidth
        label={t("Not Allowed")}
      >
        <Typography variant="h4">{dropTaskModal?.message}</Typography>
      </Modal>
    </Stack>
  );

  // function createTask(columnId: KanbanBoardId) {
  //   const newTask: KanbanBoardTask = {
  //     id: generateId(),
  //     columnId,
  //     content: `Column ${stateTasks.length + 1}`,
  //   };

  //   setStateTasks([...stateTasks, newTask]);
  // }

  // function deleteTask(id: KanbanBoardId) {
  //   const newTasks = stateTasks.filter((task) => task.id !== id);
  //   setStateTasks(newTasks);
  // }

  function updateTask(id: KanbanBoardId, content: string) {
    const newTasks = stateTasks.map((task) => {
      if (task.id !== id) return task;
      return { ...task, content };
    });

    setStateTasks(newTasks);
  }

  // function createNewColumn() {
  //   const columnToAdd: KanbanBoardColumn = {
  //     id: generateId(),
  //     title: `Column ${columns.length + 1}`,
  //   };

  //   setColumns([...columns, columnToAdd]);
  // }

  // function deleteColumn(id: KanbanBoardId) {
  //   const filteredColumns = columns.filter((col) => col.id !== id);
  //   setColumns(filteredColumns);

  //   const newTasks = stateTasks.filter((t) => t.columnId !== id);
  //   setStateTasks(newTasks);
  // }

  // function updateColumn(id: KanbanBoardId, title: string) {
  //   const newColumns = columns.map((col) => {
  //     if (col.id !== id) return col;
  //     return { ...col, title };
  //   });

  //   setColumns(newColumns);
  // }

  function onDragStart(event: DragStartEvent) {
    if (event.active.data.current?.type === "Column") {
      setActiveColumn(event.active.data.current.column);
      return;
    }

    if (event.active.data.current?.type === "Task") {
      setActiveTask(event.active.data.current.task);
      return;
    }
  }

  async function onDragEnd(event: DragEndEvent) {
    const { t } = useLanguageContext();
    const isSameCol = dropTaskModal?.isSameColumn;

    // 1. check if allowed to move the task to this column
    if (!isSameCol) {
      let allowedToMove = false;

      // If not allowed to drop show dialog
      if (!dropTaskModal?.allowedToDrop) {
        setDropTaskModal((prev) => {
          if (prev) {
            return {
              ...prev,
              showModal: true,
              message: t("Not allowed to move task to this column"),
            };
          }
          return prev;
        });
      } else {
        // allowed to drop in this column, but run back-end check
        const check = await handleCheckTaskMove(
          dropTaskModal.droppedTaskID,
          dropTaskModal.droppedColumnID
        );

        if (check?.status) {
          allowedToMove = true;

          const prevColumn = activeTask?.node_id
            ? colsMapping?.[activeTask.node_id]?.title
            : "";
          const newColumn = dropTaskModal.droppedColumnID
            ? colsMapping?.[dropTaskModal.droppedColumnID].title
            : "";

          // generate an alert
          generateKanAlerts([
            {
              id: uuidv4(),
              message: `${t("Task")} ${activeTask?.order_name || ""} ${t(
                "moved from"
              )} ${prevColumn} ${t("to")} ${newColumn}`,
              rule_id: 1,
              triggered_at: new Date().toISOString(),
              status: "unread",
              messageOnlyForText: true,
            },
          ]);
          setReFetchAlerts((prev) => !prev);
          setDropTaskModal(null);
        } else {
          setDropTaskModal((prev) => {
            if (prev) {
              return {
                ...prev,
                showModal: true,
                message: check?.message || t("Not Allowed"),
              };
            }
            return prev;
          });
        }
      }

      // 2. run request to update the tasks
      if (allowedToMove) {
        await refetchTasks();
      }
    }

    // 3. reset the active column and task
    setActiveColumn(null);
    setActiveTask(null);

    if (disableColDrag) {
      return;
    }

    const { active, over } = event;
    if (!over) return;

    const activeId = active.id;
    const overId = over.id;

    if (activeId === overId) return;

    const isActiveAColumn = active.data.current?.type === "Column";
    if (!isActiveAColumn) return;

    setColumns((columns) => {
      const activeColumnIndex = columns.findIndex((col) => col.id === activeId);

      const overColumnIndex = columns.findIndex((col) => col.id === overId);

      return arrayMove(columns, activeColumnIndex, overColumnIndex);
    });
  }

  function onDragOver(event: DragOverEvent) {
    const { active, over } = event;
    if (!over) return;

    const activeId = active.id;
    const overId = over.id;

    if (activeId === overId) return;

    const isActiveATask = active.data.current?.type === "Task";
    const isOverATask = over.data.current?.type === "Task";

    if (!isActiveATask) return;

    // check if allowed to drop on this column

    const overColumnID =
      over.data.current?.type === "Column"
        ? over.data.current?.column?.id
        : over.data.current?.task?.node_id;

    if (overColumnID && activeTask?.id) {
      setDropTaskModal(() => ({
        showModal: false,
        message: undefined,
        allowedToDrop: activeTask.allowed_nodes?.includes(overColumnID),
        droppedColumnID: overColumnID,
        droppedTaskID: activeTask.id,
        isSameColumn: overColumnID === activeTask.node_id,
      }));
    }

    // Dropping a KanbanBoardTask over another KanbanBoardTask
    if (isActiveATask && isOverATask) {
      setStateTasks((prev) => {
        const activeIndex = prev.findIndex((t) => t.id === activeId);
        const overIndex = prev.findIndex((t) => t.id === overId);

        // Return early if indices are invalid
        if (activeIndex === -1 || overIndex === -1) return prev;

        const updatedTasks = [...prev];

        if (updatedTasks[activeIndex].node_id !== updatedTasks[overIndex].node_id) {
          updatedTasks[activeIndex] = {
            ...updatedTasks[activeIndex],
            node_id: updatedTasks[overIndex].node_id,
          };

          // Perform the array move
          return arrayMove(updatedTasks, activeIndex, overIndex - 1);
        }

        // Perform the array move without updating node_id
        return arrayMove(updatedTasks, activeIndex, overIndex);
      });
    }

    const isOverAColumn = over.data.current?.type === "Column";

    // Dropping a KanbanBoardTask over a column
    if (isActiveATask && isOverAColumn) {
      setStateTasks((prev) => {
        const activeIndex = prev.findIndex((t) => t.id === activeId);

        // Return early if index is invalid
        if (activeIndex === -1) return prev;

        const updatedTasks = [...prev];
        updatedTasks[activeIndex] = {
          ...updatedTasks[activeIndex],
          node_id: `${overId}`,
        };

        return updatedTasks;
      });
    }
  }
};

// function generateId() {
//   /* Generate a random number between 0 and 10000 */
//   return Math.floor(Math.random() * 10001);
// }

export default KanbanBoard;
