import { Box, Stack, useTheme } from "@mui/material";
import cssLayoutStyles from "../../Global/Styles/layout";
import cssSpacingStyles from "../../Global/Styles/spacing";
import {
  AutocompleteOption,
  FormStatuses,
  SelectOption,
} from "../../Global/Types/commonTypes";
import { useEffect, useState } from "react";
import { TableGridColumnSchema } from "../../Components/SmallComponents/TableGrid/constructTableGrid";
import ResponsiveTableGrid from "../../Components/SmallComponents/TableGrid/ResponsiveTableGrid";
import Alert from "../../Components/MaterialUI/Alert";
import callApi from "../../Api/callApi";
import { useAuthedContext } from "../../context/AuthContext";
import { useLanguageContext } from "../../context/LanguageContext";
import { useTranslateFields } from "../../Global/Hooks/useTranslations";
import { TableGridConfiguration } from "../../Components/SmallComponents/TableGrid/tableGridUtils";
import { handleGetSelectOption } from "../../Global/Utils/commonFunctions";
import Autocomplete from "../../Components/MaterialUI/FormFields/Autocomplete";
import { useOutletContext } from "react-router-dom";
import { AppRouterProps } from "../../Layout/layoutVariables";
import PageTitle from "../../Components/SmallComponents/PageTitle/PageTitle";
import { Currency } from "../../Api/Currencies/apiCurrenciesSnippets";
import { getQueryCurrencies } from "../../Api/Currencies/apiCurrenciesGetQueries";
import { postQueryCurrencies } from "../../Api/Currencies/apiCurrenciesPostQueries";

const cellWidth = 250;

const colSchema: TableGridColumnSchema[] = [
  { id: "code", label: "Code", type: "string", disableEditing: true, width: cellWidth },
  { id: "id", label: "ID", type: "string", disableEditing: true, width: cellWidth },
  {
    id: "rate",
    label: "Rate",
    type: "number",
    width: cellWidth,
    formatNumb: true,
    formatNumbDecimals: 4,
  },
  { id: "auto_update", label: "Auto Updated", type: "boolean", width: cellWidth },
  {
    id: "last_updated",
    label: "Last Updated",
    type: "dateTime",
    disableEditing: true,
    width: cellWidth,
  },
  { id: "name", label: "Name", type: "string", disableEditing: true, width: cellWidth },
];

const configuration: TableGridConfiguration = {
  density: "compact",
  initialRowsPerPage: 15,
  columnVisibility: { id: false },
  hideAddButton: true,
  hideDeleteRowButton: true,
};

const CurrenciesFinancePage: React.FC = () => {
  const { t } = useLanguageContext();
  const theme = useTheme();
  const styles = { ...cssSpacingStyles(theme), ...cssLayoutStyles };

  const [syncStatus, setSyncStatus] = useState<FormStatuses>(null);
  const [pageStatus, setPageStatus] = useState<FormStatuses>(null);
  const [alertMessage, setAlertMessage] = useState<string | null>(null);
  const [rows, setRows] = useState<Currency[]>([]);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [tableLoading, setTableLoading] = useState<boolean>(false);
  const [selectedCurr, setSelectedCurr] = useState<string>("EUR");

  const options: SelectOption[] = handleGetSelectOption(rows.map((item) => item.code));

  const { setAuthedUser } = useAuthedContext();
  const { smMediaQuery, setExtraTopNavMenu } = useOutletContext<AppRouterProps>();

  useEffect(() => {
    if (smMediaQuery) {
      setExtraTopNavMenu(null);
    } else {
      setExtraTopNavMenu(<PageTitle title={t("Currencies")} />);
    }

    return () => {
      setExtraTopNavMenu(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [smMediaQuery]);

  useEffect(() => {
    (async () => {
      await handleFetch();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCurr]);

  const handleFetch = async () => {
    try {
      setPageStatus("loading");

      const currenciesData = await callApi<Currency[]>({
        query: getQueryCurrencies(selectedCurr),
        auth: { setAuthedUser },
      });
      setRows(currenciesData);

      setPageStatus(null);
    } catch (err) {
      console.log("err ", err);
      setPageStatus("error");
      setAlertMessage(t("Something went wrong"));
    }
  };

  const handleSaveCurrencies = async (
    _: Currency[],
    editedRows: Currency[]
    // updatedConfig?: TableGridConfiguration
  ) => {
    try {
      setAlertMessage(null);
      setSyncStatus(null);
      setTableLoading(true);

      // @ts-ignore
      const input: PostQueryCurrenciesInput = editedRows.map((item) => ({
        code: item.code,
        rate_to_eur: item.rate,
        auto_update: item.auto_update,
        name: item.name,
      }));

      await callApi({
        query: postQueryCurrencies(input),
        auth: { setAuthedUser },
      });
      await handleFetch();
      setEditMode(false);
    } catch (err) {
      console.log("err ", err);
      setSyncStatus("error");
      setAlertMessage(t("Something went wrong"));
    }
    setTableLoading(false);
  };

  return (
    <Box component="div">
      {smMediaQuery ? <PageTitle css={styles.labelBreak} title={t("Products")} /> : null}

      <Stack
        css={[styles.width50, styles.labelBreak]}
        spacing={2}
        alignItems="center"
        justifyContent="space-between"
        direction="row"
      >
        <Box component="div" />
        <Autocomplete
          css={[styles.widthLimit10, styles.width100]}
          value={{ value: selectedCurr, description: selectedCurr }}
          handleOnChange={(val: AutocompleteOption) => setSelectedCurr(val.value)}
          options={options}
          label="Rate to Currency"
        />
      </Stack>

      <Box component="div" display="flex" justifyContent="center" mt={2}>
        <Alert
          css={[styles.widthLimit20]}
          message={alertMessage || ""}
          showAlert={!!alertMessage}
          severity={syncStatus || pageStatus}
        />
      </Box>

      {rows.length ? (
        <ResponsiveTableGrid
          rows={rows}
          colSchema={useTranslateFields(colSchema, ["label"])}
          responsive="desktop"
          configuration={configuration}
          setEditMode={setEditMode}
          editMode={editMode}
          // @ts-ignore
          onSaveRows={handleSaveCurrencies}
          // @ts-ignore
          setRows={setRows}
          backdropLoading={tableLoading}
          tableID="planning_currency_page_table"
        />
      ) : null}
    </Box>
  );
};

export default CurrenciesFinancePage;
