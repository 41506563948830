import { Formik } from "formik";
import { YUP_STRING, YUP_STRING_ARRAY } from "../../../Global/Constants/yupConstants";
import { boolean, object } from "yup";
import { useEffect, useState } from "react";
import { AutocompleteOption, FormStatuses } from "../../../Global/Types/commonTypes";
import { Divider, Grid, Tab, Tabs } from "@mui/material";
import Alert from "../../MaterialUI/Alert";
import Button from "../../MaterialUI/Button";
import {
  formatPermissionTypes,
  ExcellenceGridLayoutSettings,
  ModalTitle,
  ShareDashboardFormValues,
} from "./excellenceUtils";
import {
  RolePermissionType,
  UserPermissionType,
} from "../../../context/authContextUtils";
import callApi from "../../../Api/callApi";
import { useAuthedContext } from "../../../context/AuthContext";
import { PostQueryCreateDashboardPermission } from "../../../Api/Excellence/apiExcellenceInputs";
import { postQueryExcellenceDashboardPermission } from "../../../Api/Excellence/apiExcellencePostQueries";
import { GetExcellenceDashboardPermissions } from "../../../Api/Excellence/apiExcellenceSnippets";
import { getQueryExcellenceDashboardPermissions } from "../../../Api/Excellence/apiExcellenceGetQueries";
import ShareDashboardPublic from "./ShareDashboardPublic";
import SharedUsersTable from "./SharedUsersTable";
import SharedRolesTable from "./SharedRolesTable";
import { useLanguageContext } from "../../../context/LanguageContext";

const fieldValidation = object({
  userOrRole: YUP_STRING,
  user_ids: YUP_STRING_ARRAY,
  role_ids: YUP_STRING_ARRAY,
  permission_type: YUP_STRING_ARRAY,
  isPublic: boolean().required(),
});

interface ShareDashboardFormProps {
  id: string;
  isInitiallyPublic: boolean;
  unsavedEditChanges: boolean;
  setUnsavedEditChanges: React.Dispatch<React.SetStateAction<boolean>>;
  setDoOpenUnsavedChangesModal: React.Dispatch<React.SetStateAction<boolean>>;
  setModalTitle: React.Dispatch<React.SetStateAction<ModalTitle | null>>;
  setSettings: React.Dispatch<React.SetStateAction<ExcellenceGridLayoutSettings | null>>;
  handleSetUnsavedChanges: (unsavedChanges: boolean) => void;
}

const ShareDashboardForm: React.FC<ShareDashboardFormProps> = ({
  id,
  isInitiallyPublic,
  unsavedEditChanges,
  setUnsavedEditChanges,
  setDoOpenUnsavedChangesModal,
  setModalTitle,
  setSettings,
}) => {
  const { t } = useLanguageContext();
  const { setAuthedUser } = useAuthedContext();
  const [isPublic, setIsPublic] = useState<boolean>(isInitiallyPublic);
  const [addedRolesPermissions, setAddedRolesPermissions] = useState<
    RolePermissionType[]
  >([]);
  const [initialRolePermissions, setInitialRolePermissions] = useState<
    RolePermissionType[]
  >([]);
  const [addedUsersPermissions, setAddedUsersPermissions] = useState<
    UserPermissionType[]
  >([]);
  const [initialUserPermissions, setInitialUserPermissions] = useState<
    UserPermissionType[]
  >([]);
  const [changedUserPermissions, setChangedUserPermissions] = useState<
    PostQueryCreateDashboardPermission[] | []
  >([]);
  const [changedRolePermissions, setChangedRolePermissions] = useState<
    PostQueryCreateDashboardPermission[] | []
  >([]);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [selectedUser, setSelectedUser] = useState<AutocompleteOption | null>(null);
  const [selectedRole, setSelectedRole] = useState<AutocompleteOption | null>(null);
  const [formStatus, setFormStatus] = useState<FormStatuses>(null);
  const [alertMessage, setAlertMessage] = useState<string | null>(null);
  const [selectedTab, setSelectedTab] = useState<number>(0);

  useEffect(() => {
    fetchDashboardPermissions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchDashboardPermissions = async () => {
    const rolesAndUserData = await callApi<GetExcellenceDashboardPermissions>({
      query: getQueryExcellenceDashboardPermissions(id),
      auth: { setAuthedUser },
    });

    setAddedUsersPermissions(rolesAndUserData.users);
    setAddedRolesPermissions(rolesAndUserData.roles);

    setInitialUserPermissions(rolesAndUserData.users);
    setInitialRolePermissions(rolesAndUserData.roles);
  };

  const initialValues: ShareDashboardFormValues = {
    usersPermissions: [],
    rolesPermissions: [],
    isPublic: isInitiallyPublic,
  };

  const handleCancel = () => {
    if (unsavedEditChanges) {
      setDoOpenUnsavedChangesModal(true);
    } else {
      setModalTitle(null);
    }
  };

  const handleFormSubmit = async () => {
    try {
      setFormStatus("loading");
      setAlertMessage(t("Loading..."));

      if (changedUserPermissions.length > 0 || changedRolePermissions.length > 0) {
        const inputUsers = changedUserPermissions.map((userPermission) => ({
          ...userPermission,
          permission_type: formatPermissionTypes(userPermission.permission_type),
        }));

        const inputRoles = changedRolePermissions.map((rolePermission) => ({
          ...rolePermission,
          permission_type: formatPermissionTypes(rolePermission.permission_type),
        }));

        if (inputUsers.length > 0) {
          for (const userPermission of inputUsers) {
            await callApi<PostQueryCreateDashboardPermission>({
              query: postQueryExcellenceDashboardPermission(id, userPermission),
              auth: { setAuthedUser },
            });
          }
        }

        if (inputRoles.length > 0) {
          for (const rolePermission of inputRoles) {
            await callApi<PostQueryCreateDashboardPermission>({
              query: postQueryExcellenceDashboardPermission(id, rolePermission),
              auth: { setAuthedUser },
            });
          }
        }
      }

      setUnsavedEditChanges(false);
      setSearchTerm("");
      setSelectedUser(null);
      setFormStatus("success");
      fetchDashboardPermissions();
      setAlertMessage(t("Successfully saved"));
    } catch (err) {
      setFormStatus("error");
      setAlertMessage(t("Something went wrong"));
    }
  };

  const handleTabChange = (_: React.ChangeEvent<{}>, newValue: number) => {
    setSelectedTab(newValue);
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleFormSubmit}
      validationSchema={fieldValidation}
      enableReinitialize
    >
      {({ handleSubmit }) => {
        return (
          <Grid container justifyContent="center" alignItems="center" spacing={1}>
            <Grid item xs={12} ml={1}>
              <form
                onSubmit={(e) => {
                  handleSubmit(e);
                }}
              >
                <Grid container>
                  <Grid item xs={12}>
                    <ShareDashboardPublic
                      id={id}
                      isPublic={isPublic}
                      setIsPublic={setIsPublic}
                      setSettings={setSettings}
                      setFormStatus={setFormStatus}
                      setAlertMessage={setAlertMessage}
                    />
                  </Grid>

                  <Grid item xs={12} mt={1}>
                    <Divider />
                  </Grid>

                  <Grid item xs={12}>
                    <Tabs value={selectedTab} onChange={handleTabChange} centered>
                      <Tab label={t("Shared Users")} />
                      <Tab label={t("Shared Roles")} />
                    </Tabs>
                  </Grid>

                  {selectedTab === 0 ? (
                    <Grid item xs={12}>
                      <SharedUsersTable
                        addedUsersPermissions={addedUsersPermissions}
                        setAddedPermissions={setAddedUsersPermissions}
                        setChangedPermissions={setChangedUserPermissions}
                        initialPermissions={initialUserPermissions}
                        dashboardId={id}
                        setFormStatus={setFormStatus}
                        setAlertMessage={setAlertMessage}
                        fetchDashboardPermissions={fetchDashboardPermissions}
                        searchTerm={searchTerm}
                        setSearchTerm={setSearchTerm}
                        selectedUser={selectedUser}
                        setSelectedUser={setSelectedUser}
                        setUnsavedEditChanges={setUnsavedEditChanges}
                      />
                    </Grid>
                  ) : null}

                  {selectedTab === 1 ? (
                    <Grid item xs={12}>
                      <SharedRolesTable
                        addedRolesPermissions={addedRolesPermissions}
                        setAddedPermissions={setAddedRolesPermissions}
                        setChangedPermissions={setChangedRolePermissions}
                        initialPermissions={initialRolePermissions}
                        dashboardId={id}
                        setFormStatus={setFormStatus}
                        setAlertMessage={setAlertMessage}
                        fetchDashboardPermissions={fetchDashboardPermissions}
                        searchTerm={searchTerm}
                        setSearchTerm={setSearchTerm}
                        selectedRole={selectedRole}
                        setSelectedRole={setSelectedRole}
                        setUnsavedEditChanges={setUnsavedEditChanges}
                      />
                    </Grid>
                  ) : null}

                  <Grid item xs={12} mt={2}>
                    <Grid
                      container
                      sx={{ display: "flex", flexDirection: "column", gap: 2 }}
                    >
                      <Grid item>
                        <Alert
                          message={alertMessage || ""}
                          showAlert={!!alertMessage}
                          severity={formStatus}
                        />
                      </Grid>

                      <Grid container justifyContent="center" alignItems="center" gap={3}>
                        <Grid item xs={2.3}>
                          <Button color="error" fullWidth onClick={handleCancel}>
                            {t("Cancel")}
                          </Button>
                        </Grid>
                        <Grid item xs={2.3}>
                          <Button type="submit" fullWidth disabled={!unsavedEditChanges}>
                            {t("Save Changes")}
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            </Grid>
          </Grid>
        );
      }}
    </Formik>
  );
};

export default ShareDashboardForm;
