import { Query } from "../../../Api/callApi";
import {
  PostQueryElpromCurrenciesInput,
  PostQueryElpromUploadSupcoInput,
} from "./apiElpromInputs";

export const postQueryElpromCurrencies = (
  input: PostQueryElpromCurrenciesInput
): Query => ({
  endpoint: "lprom/currencies",
  method: "PUT",
  variables: input,
});

export const postQueryElpromUploadSupco = (
  input: PostQueryElpromUploadSupcoInput
): Query => ({
  endpoint: `lprom/upload-supco`,
  method: "POST",
  variables: input,
  multipartForm: true,
});

export const postQueryElpromUploadKso = (
  input: PostQueryElpromUploadSupcoInput
): Query => ({
  endpoint: `lprom/upload-kso`,
  method: "POST",
  variables: input,
  multipartForm: true,
});

export const postQueryElpromUploadPrices = (
  input: PostQueryElpromUploadSupcoInput
): Query => ({
  endpoint: `lprom/upload-prices`,
  method: "POST",
  variables: input,
  multipartForm: true,
});

export const postQueryElpromUploadPriorities = (
  input: PostQueryElpromUploadSupcoInput
): Query => ({
  endpoint: `lprom/upload-priorities`,
  method: "POST",
  variables: input,
  multipartForm: true,
});
