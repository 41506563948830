import { Box, IconButton, Stack, Theme, Typography, useTheme } from "@mui/material";
import { css } from "@emotion/react";
import { useEffect, useState } from "react";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import {
  TOP_NAV_SPACING_WITH_SITE_CONTENT,
  AUTH_LAYOUT_PADDING,
  AppRouterProps,
} from "../../Layout/layoutVariables";
import cssSpacingStyles from "../../Global/Styles/spacing";
import { FormStatuses } from "../../Global/Types/commonTypes";
import { PidWorkflowType, PidWorkflowViewMode } from "./Components/pidWorkflowUtils";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { useAuthedContext } from "../../context/AuthContext";
import { AuthedUser } from "../../context/authContextTypes";
import ROUTES_MAPPING from "../../Layout/Router/routesMapping";
import Alert from "../../Components/MaterialUI/Alert";
import Button from "../../Components/MaterialUI/Button";
import Modal from "../../Components/MaterialUI/Modal";
import PidWorkflow from "./Components/PidWorkflow/PidWorkflow";
import EditPidWorkflowForm from "./Components/PidWorkflow/Forms/EditPidWorkflowForm";
import DesignServicesIcon from "@mui/icons-material/DesignServices";
import VisibilityIcon from "@mui/icons-material/Visibility";
import callApi from "../../Api/callApi";
import {
  deleteQueryPidWorkflow,
  putQueryPidWorkflow,
} from "../../Api/PidWorkflows/apiPidWorkflowsPostQueries";
import { GetQueryPidSingleWorkflowSnippet } from "../../Api/PidWorkflows/apiPidWorkflowsSnippets";
import { getQueryPidSingleWorkflow } from "../../Api/PidWorkflows/apiPidWorkflowsGetQueries";
import {
  PidWorkflowDataFieldInput,
  PutQueryPidWorkflowInput,
} from "../../Api/PidWorkflows/apiPidWorkflowsInputs";
import { PID_FLOW_NODE_TYPE } from "./Components/PidWorkflow/Nodes/pidNodesUtils";
import { PidWorkflowsInputNodeData } from "./Components/PidWorkflow/Nodes/Input/pidInputNodeUtils";
import { useTranslatedModalTitle } from "../../Global/Hooks/useTranslations";
import { useLanguageContext } from "../../context/LanguageContext";
import CustomIconsTooltip from "../../Components/SmallComponents/Tooltip/CustomIconsTooltip";

const cssStyles = (theme: Theme) => ({
  flowWrapper: css({
    width: "100%",
    // minHeight: parent's minHeight - the padding
    minHeight: `calc(100vh - ${TOP_NAV_SPACING_WITH_SITE_CONTENT} - ${AUTH_LAYOUT_PADDING} - ${AUTH_LAYOUT_PADDING})`,
    [theme.breakpoints.down("sm")]: {
      // minHeight: parent's minHeight - the padding - extra_nav_menu
      minHeight: `calc(100vh - ${TOP_NAV_SPACING_WITH_SITE_CONTENT} - ${AUTH_LAYOUT_PADDING} - ${AUTH_LAYOUT_PADDING} - 75px)`,
    },
  }),
  flow: css({
    minHeight: "inherit",
    "& > div": {
      minHeight: "inherit",
    },
  }),
});

const modalTitleTranslations = {
  "Edit chart": "Edit chart",
  "Delete chart": "Delete chart",
} as const;

type ModalType = keyof typeof modalTitleTranslations;

const SinglePidChart: React.FC = () => {
  const { t } = useLanguageContext();
  const theme = useTheme();
  const styles = { ...cssStyles(theme), ...cssSpacingStyles(theme) };
  const [workflow, setWorkflow] = useState<PidWorkflowType | null>(null);
  const [initialWorkflow, setInitialWorkflow] = useState<PidWorkflowType | null>(null);
  const [viewMode, setViewMode] = useState<PidWorkflowViewMode>("View Mode");

  const [modalType, setModalType] = useState<ModalType | null>(null);
  const getTranslatedModalTitle = useTranslatedModalTitle(modalTitleTranslations);
  const [alertMessage, setAlertMessage] = useState<string | null>(null);
  const [formStatus, setFormStatus] = useState<FormStatuses>(null);
  const [initialNodeUpdated, setInitialNodeUpdated] = useState<boolean>(false);
  const [unsavedEditChanges, setUnsavedEditChanges] = useState<boolean>(false);

  const { smMediaQuery, setExtraRightNavMenu, setUnsavedChanges } =
    useOutletContext<AppRouterProps>();
  const navigate = useNavigate();
  const initialNode = workflow?.nodes.find((node) => node?.type === "initial");
  const { setAuthedUser } = useAuthedContext();
  const { id } = useParams();

  useEffect(() => {
    (async () => {
      try {
        const res: GetQueryPidSingleWorkflowSnippet = await callApi({
          query: getQueryPidSingleWorkflow(id!),
          auth: { setAuthedUser },
        });

        setWorkflow(res.workflow);
        setInitialWorkflow(res.workflow);
      } catch (err) {
        console.error("error - fetch workflow", err);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (smMediaQuery) {
      setExtraRightNavMenu(null);
    } else {
      setExtraRightNavMenu(
        <ExtraTopNav
          setModalType={setModalType}
          workflow={workflow}
          setAuthedUser={setAuthedUser}
          setUnsavedChanges={setUnsavedChanges}
          viewMode={viewMode}
          setViewMode={setViewMode}
        />
      );
    }

    return () => {
      setExtraRightNavMenu(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [smMediaQuery, workflow, viewMode]);

  useEffect(() => {
    if (workflow && initialWorkflow) {
      const areEqual = JSON.stringify(workflow) === JSON.stringify(initialWorkflow);
      setUnsavedChanges(!areEqual);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workflow]);

  const handleDeleteWorkflow = async () => {
    try {
      setFormStatus("loading");
      setAlertMessage(t("Loading..."));

      if (workflow?.nodes.length) {
        await callApi({
          query: deleteQueryPidWorkflow(workflow.id),
          auth: { setAuthedUser },
        });
      }

      setFormStatus("success");
      setAlertMessage(null);
      navigate({
        pathname: `${ROUTES_MAPPING["Layouts-P&ID"]}`,
      });
    } catch (err) {
      console.log("error - handleDeleteWorkflow() ", err);
      setFormStatus("error");
      setAlertMessage(t("Something went wrong"));
    }
  };

  const handleEdit = (
    updatedName: string,
    updatedPlant: string,
    updatedDetails: string
  ) => {
    if (workflow?.nodes.length) {
      const updatedNodes = workflow.nodes.map((item) => {
        if (item.type === "initial") {
          return {
            ...item,
            data: {
              ...item.data,
              name: updatedName,
            },
          };
        }
        return item;
      });
      const updatedWorkflow: PidWorkflowType = {
        ...workflow,
        name: updatedName,
        details: updatedDetails,
        plantArea: updatedPlant,
        nodes: updatedNodes,
        updatedOn: new Date().toISOString(),
      };

      setWorkflow(updatedWorkflow);
      setInitialNodeUpdated((prev) => !prev);
      setModalType(null);
    }
  };

  const handleSetUnsavedChanges = (unsavedChanges: boolean) => {
    if (unsavedChanges) {
      setUnsavedEditChanges(true);
    }
  };

  return (
    <Box component="div" css={styles.flowWrapper}>
      {smMediaQuery ? (
        <Box component="div" css={styles.labelBreak}>
          <ExtraTopNav
            setModalType={setModalType}
            workflow={workflow}
            setAuthedUser={setAuthedUser}
            setUnsavedChanges={setUnsavedChanges}
            viewMode={viewMode}
            setViewMode={setViewMode}
          />
        </Box>
      ) : null}

      {workflow?.id ? (
        <PidWorkflow
          css={styles.flow}
          workflow={workflow}
          setWorkflow={setWorkflow}
          initialNodeUpdated={initialNodeUpdated}
          viewMode={viewMode}
        />
      ) : null}

      <Modal
        open={!!modalType}
        onClose={() => setModalType(null)}
        fullWidth
        maxWidth="sm"
        label={modalType ? getTranslatedModalTitle(modalType) : ""}
        unsavedChanges={unsavedEditChanges}
        setUnsavedChanges={setUnsavedEditChanges}
      >
        {modalType === "Edit chart" ? (
          <EditPidWorkflowForm
            currentName={initialNode?.data.name || ""}
            currentPlantArea={workflow?.plantArea || ""}
            currentDetails={workflow?.details || ""}
            handleEdit={handleEdit}
            handleSetUnsavedChanges={handleSetUnsavedChanges}
            setUnsavedChanges={setUnsavedEditChanges}
          />
        ) : null}

        {modalType === "Delete chart" ? (
          <Stack spacing={2} justifyContent="center" alignItems="center">
            <Typography>
              {t("Clicking the below button will delete your P&ID chart")}
            </Typography>
            <Alert
              message={alertMessage}
              showAlert={!!alertMessage}
              severity={formStatus}
            />
            <Button onClick={handleDeleteWorkflow}>{t("Confirm Delete")}</Button>
          </Stack>
        ) : null}
      </Modal>
    </Box>
  );
};

export default SinglePidChart;

interface ExtraTopNavProps {
  setModalType: React.Dispatch<React.SetStateAction<ModalType | null>>;
  workflow: PidWorkflowType | null;
  setAuthedUser: React.Dispatch<React.SetStateAction<AuthedUser | null>>;
  setUnsavedChanges: React.Dispatch<React.SetStateAction<boolean>>;
  viewMode: PidWorkflowViewMode;
  setViewMode: React.Dispatch<React.SetStateAction<PidWorkflowViewMode>>;
}

const ExtraTopNav: React.FC<ExtraTopNavProps> = ({
  setModalType,
  workflow,
  setAuthedUser,
  setUnsavedChanges,
  viewMode,
  setViewMode,
}) => {
  const { t } = useLanguageContext();
  const handleSaveWorkflow = async () => {
    try {
      if (workflow?.nodes.length) {
        const dataFields: PidWorkflowDataFieldInput[] = workflow.nodes
          .filter((node) => node.data.type === PID_FLOW_NODE_TYPE.Input)
          .map((item) => {
            const data = item.data as PidWorkflowsInputNodeData;
            return {
              container_id: data.dataField.container_id,
              node_id: data.dataField.node_id,
              parameter_id: data.dataField.parameter_id,
            };
          });

        const putInput: PutQueryPidWorkflowInput = {
          workflow: workflow,
          data_fields: dataFields,
        };

        callApi({
          query: putQueryPidWorkflow(putInput, workflow.id),
          auth: { setAuthedUser },
        });

        setUnsavedChanges(false);
      }
    } catch (err) {
      console.error("error - handleSaveWorkflow()", err);
    }
  };

  const handleChangeMode = () => {
    setViewMode((prev) => {
      if (prev === "Edit Mode") {
        return "View Mode";
      }
      return "Edit Mode";
    });
  };

  return (
    <Stack spacing={1} justifyContent="flex-end" direction="column">
      <Stack alignItems="center">
        <CustomIconsTooltip tooltipText={!viewMode ? t("Edit Mode") : t("View Mode")}>
          <IconButton aria-label="edit workflow" onClick={handleChangeMode}>
            {viewMode === "View Mode" ? <DesignServicesIcon /> : <VisibilityIcon />}
          </IconButton>
        </CustomIconsTooltip>
      </Stack>

      <Stack alignItems="center">
        <CustomIconsTooltip tooltipText={t("Edit")}>
          <IconButton
            aria-label="edit workflow"
            onClick={() => setModalType("Edit chart")}
            disabled={viewMode === "View Mode"}
          >
            <EditOutlinedIcon />
          </IconButton>
        </CustomIconsTooltip>
      </Stack>

      <Stack alignItems="center">
        <CustomIconsTooltip tooltipText={t("Export")}>
          <IconButton aria-label="export" disabled>
            <FileDownloadOutlinedIcon />
          </IconButton>
        </CustomIconsTooltip>
      </Stack>

      <Stack alignItems="center">
        <CustomIconsTooltip tooltipText={t("Save")}>
          <IconButton aria-label="save changes" onClick={() => handleSaveWorkflow()}>
            <SaveOutlinedIcon />
          </IconButton>
        </CustomIconsTooltip>
      </Stack>

      <Stack alignItems="center">
        <CustomIconsTooltip tooltipText={t("Delete")}>
          <IconButton
            aria-label="delete workflow"
            onClick={() => setModalType("Delete chart")}
          >
            <DeleteOutlineOutlinedIcon />
          </IconButton>
        </CustomIconsTooltip>
      </Stack>
    </Stack>
  );
};
