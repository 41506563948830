import React, { memo } from "react";
import useTheme from "@mui/material/styles/useTheme";
import { Divider, Typography } from "@mui/material";
import cssSpacingStyles from "../../../../../../Global/Styles/spacing";
import { KanbanWorkflowInitialNodeData } from "../../../../kanbanWorkflowUtils";
import KanFlowNode from "./KanFlowNode";
import { useLanguageContext } from "../../../../../../context/LanguageContext";

interface FlowCustomNodeProps {
  id: string;
  data: KanbanWorkflowInitialNodeData;
}

const KanbanWorkflowInitialNode: React.FC<FlowCustomNodeProps> = ({ id, data }) => {
  const { t } = useLanguageContext();
  const theme = useTheme();
  const styles = {
    ...cssSpacingStyles(theme),
  };

  return (
    <KanFlowNode id={id} nodeType={"initial"}>
      <Typography variant="body1" align="center">
        {`<< ${t("Workflow")} >>`}
      </Typography>

      <Divider css={[styles.textBreak, styles.reverseTextBreak]} color="lightgrey" />

      <Typography variant="h2" align="center">
        {data.name}
      </Typography>
    </KanFlowNode>
  );
};

export default memo(KanbanWorkflowInitialNode);
