import { SerializedStyles } from "@emotion/react";
import GaugeWidget from "../../../../ExcellenceWidgets/GaugeWidget/GaugeWidget";
import { GaugeWidgetData } from "../../../../ExcellenceWidgets/EditExcellenceChartForms/excellenceChartFormUtils";
import gaugeWidgetDefaultData from "../../../../ExcellenceWidgets/ExcellenceDefaultConfig/gaugeWidgetDefaultData";
import {
  GaugeWidgetConfiguration,
  GaugeWidgetDataSchema,
} from "../../../../ExcellenceWidgets/GaugeWidget/gaugeWidgetUtils";
import { GAUGE_LIVE_DATA_SUBSCRIPTION } from "../../../../../GraphQL/Excellence/graphQLSubscriptions";
import { useSubscription } from "@apollo/client";
import { useEffect, useState } from "react";

interface ExcellenceGaugeProps {
  css?: SerializedStyles[] | SerializedStyles;
  className?: string;
  data: GaugeWidgetData | null;
  configuration: GaugeWidgetConfiguration;
  isStatic?: boolean;
  dataSchema: GaugeWidgetDataSchema | null;
}

const ExcellenceGauge: React.FC<ExcellenceGaugeProps> = ({
  className,
  data,
  configuration,
  isStatic,
  dataSchema,
}) => {
  const [shouldSubscribe, setShouldSubscribe] = useState<boolean>(false);

  const { data: subData } = useSubscription(GAUGE_LIVE_DATA_SUBSCRIPTION, {
    variables: {
      input: {
        ...dataSchema,
      },
      skip: !dataSchema || !shouldSubscribe,
    },
  });
  const liveData: GaugeWidgetData = subData?.gaugeLiveData
    ? {
        currentValue: subData.gaugeLiveData.value - 50,
      }
    : data || gaugeWidgetDefaultData;

  useEffect(() => {
    // Toggle the subscription to trigger re-subscription on variable change
    setShouldSubscribe(false);
    if (dataSchema?.container && dataSchema.parameterId) {
      setTimeout(() => setShouldSubscribe(true), 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSchema]);

  return (
    <GaugeWidget
      className={className}
      configuration={configuration}
      data={liveData}
      isStatic={isStatic}
    />
  );
};

export default ExcellenceGauge;
