import { Box, IconButton, Stack, useTheme } from "@mui/material";
import { useAuthedContext } from "../../context/AuthContext";
import { useEffect, useState } from "react";
import callApi from "../../Api/callApi";
import cssSpacingStyles from "../../Global/Styles/spacing";
import cssLayoutStyles from "../../Global/Styles/layout";
import ResponsiveTableGrid from "../../Components/SmallComponents/TableGrid/ResponsiveTableGrid";
import { TableGridColumnSchema } from "../../Components/SmallComponents/TableGrid/constructTableGrid";
import {
  generateKanAlerts,
  KAN_TASK_STATUS_OPTIONS,
  KanbanTask,
  KanbanTaskRow,
  KanbanTaskStatus,
} from "./Components/taskManagerUtils";
import Modal from "../../Components/MaterialUI/Modal";
import { FormStatuses } from "../../Global/Types/commonTypes";
import Select from "../../Components/MaterialUI/FormFields/Select";
import Alert from "../../Components/MaterialUI/Alert";
import ViewKanbanTask from "./Components/KanbanTask/ViewKanbanTask";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import KanTaskFiles from "./Components/KanbanTask/KanTaskFiles";
import { getQueryKanbanTasks } from "../KanbanWorkflows/api/queries";
import { putQueryKanbanTaskStatus } from "../KanbanWorkflows/api/mutations";
import CellDoubleActionButton from "../../Components/SmallComponents/TableGrid/MobileTableGrid/CellDoubleActionButton";
import EditKanTaskFields from "./Components/KanbanTask/EditKanTaskFields";
import { v4 as uuidv4 } from "uuid";
import { useLanguageContext } from "../../context/LanguageContext";
import { useTranslateFields } from "../../Global/Hooks/useTranslations";
import { useOutletContext } from "react-router-dom";
import { AppRouterProps } from "../../Layout/layoutVariables";
import PageTitle from "../../Components/SmallComponents/PageTitle/PageTitle";

type ColActionType = {
  type: "View Task" | "Edit Task" | "Task Files";
  task: KanbanTask;
} | null;

const colSchema: TableGridColumnSchema[] = [
  { id: "order", label: "Order", type: "string" },
  { id: "name", label: "Template", type: "string" },
  { id: "workflow", label: "Workflow", type: "string" },
  { id: "description", label: "Description", type: "string" },
  { id: "status", label: "Status", type: "button" },
  { id: "created_at", label: "Created At", type: "date" },
  { id: "updated_at", label: "Updated At", type: "date" },
  { id: "actions", label: "Actions", type: "button" },
];

const TaskManager: React.FC = () => {
  const { t } = useLanguageContext();
  const theme = useTheme();
  const styles = { ...cssSpacingStyles(theme), ...cssLayoutStyles };
  const [pageLoading, setPageLoading] = useState<boolean>(true);
  const [tasks, setTasks] = useState<KanbanTaskRow[]>([]);
  const [openModal, setOpenModal] = useState<ColActionType>(null);
  const [formStatus, setFormStatus] = useState<FormStatuses>(null);
  const [formAlert, setFormAlert] = useState<string | null>(null);
  const [unsavedChanges, setUnsavedChanges] = useState<boolean>(false);

  const { setAuthedUser, setReFetchAlerts } = useAuthedContext();
  const { smMediaQuery, setExtraTopNavMenu } = useOutletContext<AppRouterProps>();

  useEffect(() => {
    if (smMediaQuery) {
      setExtraTopNavMenu(null);
    } else {
      setExtraTopNavMenu(<PageTitle title={t("Task Manager")} />);
    }

    return () => {
      setExtraTopNavMenu(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [smMediaQuery]);

  useEffect(() => {
    (async () => {
      setPageLoading(true);
      await handleFetchTasks();
      setPageLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFetchTasks = async () => {
    try {
      const tasks = await callApi<KanbanTask[]>({
        query: getQueryKanbanTasks,
        auth: { setAuthedUser },
      });

      setTasks(
        tasks.map((item) => ({
          id: item.id,
          name: item.workflow_name,
          order: item.order_name,
          workflow: item.workflow_name,
          status: (
            <Select
              fullWidth
              label="Status"
              value={item.status}
              onChange={(e) =>
                onChangeTask(e.target.value, item.id, item.status, item.order_name)
              }
              selectOptions={KAN_TASK_STATUS_OPTIONS}
              disabled={formStatus === "loading"}
            />
          ),
          description: item.template.description,
          created_at: item.created_at,
          updated_at: item.updated_at,
          actions: (
            <Stack direction="row" alignItems="center" spacing={1}>
              <CellDoubleActionButton
                justify="flex-start"
                handleOnViewClick={() => {
                  onActionClick({
                    task: item,
                    type: "View Task",
                  });
                }}
                handleOnEditClick={() => {
                  onActionClick({
                    task: item,
                    type: "Edit Task",
                  });
                }}
              />
              <IconButton
                onClick={() =>
                  onActionClick({
                    task: item,
                    type: "Task Files",
                  })
                }
              >
                <AttachFileIcon />
              </IconButton>
            </Stack>
          ),
        }))
      );
    } catch (err) {
      console.log("err", err);
    }
  };

  const onActionClick = (data: ColActionType) => {
    setOpenModal(data);
  };

  const onChangeTask = async (
    val: string,
    taskID: string,
    prevStatus: string,
    taskName: string
  ) => {
    try {
      if (formStatus === "loading") {
        return;
      }
      setFormStatus("loading");
      setFormAlert(null);

      await callApi({
        query: putQueryKanbanTaskStatus(taskID, val as KanbanTaskStatus),
        auth: { setAuthedUser },
      });
      await handleFetchTasks();

      // generate an alert
      generateKanAlerts([
        {
          id: uuidv4(),
          message: `${t("Status for task")} ${taskName} ${t(
            "changed from"
          )} ${prevStatus} ${t("to")} ${val}`,
          rule_id: 1,
          triggered_at: new Date().toISOString(),
          status: "unread",
          messageOnlyForText: true,
        },
      ]);

      setReFetchAlerts((prev) => !prev);
      setFormStatus(null);
      setFormAlert(null);
      setOpenModal(null);
    } catch (err) {
      console.log("onChangeTask() err", err);
      setFormStatus("error");
      setFormAlert(t("Something went wrong"));
    }
  };

  const handleSetUnsavedChanges = (unsavedChanges: boolean) => {
    if (unsavedChanges) {
      setUnsavedChanges(true);
    }
  };

  return (
    <Box component="div">
      {smMediaQuery ? (
        <PageTitle css={styles.labelBreak} title={t("Task Manager")} />
      ) : null}
      <Alert message={formAlert || ""} showAlert={!!formAlert} severity={formStatus} />

      <ResponsiveTableGrid
        rows={tasks}
        colSchema={useTranslateFields(colSchema, ["label"])}
        responsive="responsive"
        configuration={{
          density: "compact",
          initialRowsPerPage: 15,
          columnVisibility: { task_id: false, progress: false },
        }}
        backdropLoading={formStatus === "loading"}
        loading={pageLoading}
        tableID="TaskManager_table"
      />

      <Modal
        open={!!openModal}
        onClose={() => setOpenModal(null)}
        fullWidth
        label={openModal?.type ? t(openModal.type) : ""}
        unsavedChanges={unsavedChanges}
        setUnsavedChanges={setUnsavedChanges}
      >
        {openModal?.type === "View Task" ? (
          <ViewKanbanTask task={openModal.task} />
        ) : null}

        {openModal?.type === "Task Files" ? <KanTaskFiles task={openModal.task} /> : null}
        {openModal?.type === "Edit Task" ? (
          <EditKanTaskFields
            task={openModal.task}
            onSubmit={handleFetchTasks}
            handleSetUnsavedChanges={handleSetUnsavedChanges}
            setUnsavedChanges={setUnsavedChanges}
          />
        ) : null}
      </Modal>
    </Box>
  );
};

export default TaskManager;
