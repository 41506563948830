import { Box, Stack, Theme, Typography } from "@mui/material";
import Alert from "../../../MaterialUI/Alert";
import useTheme from "@mui/material/styles/useTheme";
import useContainerDimensions from "../../../../Global/Hooks/useContainerDimensions";
import { useRef, useState } from "react";
import { handleGetSelectOption } from "../../../../Global/Utils/commonFunctions";
import cssLayoutStyles from "../../../../Global/Styles/layout";
import cssSpacingStyles from "../../../../Global/Styles/spacing";
import { SerializedStyles } from "@emotion/react";
import { LabelRecognitionTestedModalData } from "./labelRecognitionUtils";
import RadioGroup from "../../../MaterialUI/FormFields/RadioGroup";
import { useLanguageContext } from "../../../../context/LanguageContext";
import { useTranslateSchemeArray } from "../../../../Global/Hooks/useTranslations";

type ModalRadio = "Both" | "Original" | "Sample";
const MODAL_RADIO_OPTIONS = handleGetSelectOption(["Both", "Original", "Sample"]);

interface LabelTestedImageModalProps {
  css?: SerializedStyles[] | SerializedStyles;
  className?: string;
  stylesImageContainer: SerializedStyles;
  stylesImageText: SerializedStyles;
  testedModalData: LabelRecognitionTestedModalData;
}

const LabelTestedImageModal: React.FC<LabelTestedImageModalProps> = ({
  className,
  stylesImageContainer,
  stylesImageText,
  testedModalData,
}) => {
  const { t } = useLanguageContext();
  const theme = useTheme();
  const styles = {
    ...cssSpacingStyles(theme),
    ...cssLayoutStyles,
  };
  const [modalRadio, setModalRadio] = useState<ModalRadio | null>("Both");
  const masterImgRef = useRef<HTMLImageElement>(null);
  const sampleImgRef = useRef<HTMLImageElement>(null);
  const { width: masterImgWidth } = useContainerDimensions(masterImgRef);
  const { width: sampleImgWidth } = useContainerDimensions(sampleImgRef);

  const confidenceColor = getConfidenceColor(testedModalData?.confidence, theme);

  return (
    <Box component="div" className={className}>
      <RadioGroup
        css={styles.contentBreak}
        radioOptions={useTranslateSchemeArray(MODAL_RADIO_OPTIONS)}
        label={t("Displayed Labels")}
        value={modalRadio}
        onChange={(e) => setModalRadio(e.target.value as ModalRadio)}
        row
      />

      <Stack css={styles.labelBreak} spacing={2}>
        <Typography variant="h4">
          {t("Confidence")}:{" "}
          <Typography
            variant="h4"
            component="span"
            sx={confidenceColor ? { color: confidenceColor } : undefined}
          >
            {testedModalData.confidence}
          </Typography>
        </Typography>
        <Alert
          message={t(
            "The uploaded label either does not match the original label or its image quality is not good enough"
          )}
          showAlert={+`${testedModalData.confidence}`.split("%")[0] < 50}
          severity="warning"
        />
      </Stack>

      <Stack spacing={0} direction="row" flexWrap="wrap">
        {modalRadio === "Both" || modalRadio === "Original" ? (
          <Box component="div" css={stylesImageContainer}>
            <Box component="div" css={stylesImageText}>
              <Typography
                sx={masterImgWidth ? { maxWidth: `${masterImgWidth}px` } : undefined}
                variant="h4"
              >
                {testedModalData.masterLabelName}
              </Typography>
            </Box>

            <img
              style={{ maxWidth: `${theme.breakpoints.values.xl}px` }}
              ref={masterImgRef}
              src={testedModalData.masterLabelUrl}
              alt="original label"
              loading="lazy"
            />
          </Box>
        ) : null}

        {modalRadio === "Both" || modalRadio === "Sample" ? (
          <Box component="div" css={stylesImageContainer}>
            <Box component="div" css={stylesImageText}>
              <Typography sx={{ maxWidth: `${sampleImgWidth}px` }} variant="h4">
                {testedModalData.testedLabelName}
              </Typography>
            </Box>
            <img
              style={{ maxWidth: `${theme.breakpoints.values.xl}px` }}
              ref={sampleImgRef}
              src={testedModalData.testedLabelUrl}
              alt="sample label"
              loading="lazy"
            />
          </Box>
        ) : null}
      </Stack>
    </Box>
  );
};

export default LabelTestedImageModal;

const getConfidenceColor = (
  confidence: number | string | undefined | null,
  theme: Theme
): string | null => {
  if (confidence === null || confidence === undefined) {
    return null;
  }
  const confNum = +`${confidence}`.split("%")[0];

  if (confNum < 50) {
    return theme.palette.error.main;
  }
  if (confNum < 85) {
    return theme.palette.warning.main;
  }
  return theme.palette.success.main;
};
