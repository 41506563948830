import { Stack } from "@mui/material";
import Modal from "../../MaterialUI/Modal";
import { Node, Edge } from "reactflow";
import { useState } from "react";
import { FLOW_TEXT_NODE_CONFIG, FlowAllTaskNodesData } from "./FlowNodes/flowNodesTypes";
import { WorkflowNewNodeModal } from "./reactFlowUtils";
import FlowTextForm from "./FlowNodes/Forms/FlowTextForm";
import { useLanguageContext } from "../../../context/LanguageContext";

interface CreateNewFlowNodeModalProps {
  newNodeModal: WorkflowNewNodeModal;
  setNewNodeModal: React.Dispatch<React.SetStateAction<WorkflowNewNodeModal>>;
  setNodes: React.Dispatch<React.SetStateAction<Node<any>[]>>;
  setEdges: React.Dispatch<React.SetStateAction<Edge<any>[]>>;
  connectingNodeId: React.MutableRefObject<any>;
  nodes: Node<any, string | undefined>[];
}

const CreateNewFlowNodeModal: React.FC<CreateNewFlowNodeModalProps> = ({
  newNodeModal,
  setNewNodeModal,
  setNodes,
  setEdges,
  connectingNodeId,
}) => {
  const { t } = useLanguageContext();
  // const [selectedType] = useState<string>(FLOW_TEXT_NODE_CONFIG.type);
  const [typeConfig] = useState<any>(FLOW_TEXT_NODE_CONFIG);
  const [unsavedChanges, setUnsavedChanges] = useState<boolean>(false);

  // const handleOnTypeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   const val = e.target.value as FLOW_NODE_TYPE;
  //   switch (val) {
  //     case FLOW_NODE_TYPE.Text: {
  //       setTypeConfig(FLOW_TEXT_NODE_CONFIG);
  //       setSelectedType(FLOW_TEXT_NODE_CONFIG.type);
  //       break;
  //     }
  //     case FLOW_NODE_TYPE.Enhanced: {
  //       setTypeConfig(FLOW_ENHANCED_NODE_CONFIG);
  //       setSelectedType(FLOW_ENHANCED_NODE_CONFIG.type);
  //       break;
  //     }
  //   }
  //   setSelectedType(val);
  // };

  const handleCreateSubmit = (nodeData: FlowAllTaskNodesData) => {
    console.log("newNodeModal ", newNodeModal);
    console.log("typeConfig ", typeConfig);
    if (newNodeModal && typeConfig) {
      const newNode = {
        id: newNodeModal.id,
        position: newNodeModal.position,
        data: getNodeData(nodeData),
        ...typeConfig,
      };

      setNodes((nds) => nds.concat(newNode));
      setEdges((eds) =>
        eds.concat({
          id: newNodeModal.id,
          source: connectingNodeId.current,
          target: newNodeModal.id,
          type: "step",
        })
      );
      setNewNodeModal(null);
    }
  };

  const handleSetUnsavedChanges = (unsavedChanges: boolean) => {
    if (unsavedChanges) {
      setUnsavedChanges(true);
    }
  };

  return (
    <Modal
      onClose={() => setNewNodeModal(null)}
      open={!!newNodeModal}
      fullWidth
      label={t("Creating new node")}
      unsavedChanges={unsavedChanges}
      setUnsavedChanges={setUnsavedChanges}
    >
      <Stack spacing={3} alignItems="center" justifyContent="center">
        {/* <Select
          selectOptions={FLOW_NODES_OPTIONS}
          fullWidth
          value={selectedType}
          onChange={handleOnTypeChange}
        /> */}

        <FlowTextForm
          handleCreateSubmit={handleCreateSubmit}
          handleSetUnsavedChanges={handleSetUnsavedChanges}
          setUnsavedChanges={setUnsavedChanges}
        />

        {/* <Collapse in={!!selectedType && !!typeConfig}>
          {selectedType === FLOW_NODE_TYPE.Text ? (
            <FlowTextForm handleCreateSubmit={handleCreateSubmit} />
          ) : null}

          {selectedType === FLOW_NODE_TYPE.Enhanced ? (
            <FlowEnhancedForm handleCreateSubmit={handleCreateSubmit} />
          ) : null}
        </Collapse> */}
      </Stack>
    </Modal>
  );
};

export default CreateNewFlowNodeModal;

const getNodeData = (nodeData: FlowAllTaskNodesData) => {
  const foundData = Object.values(nodeData).find((val) => !!val);
  return foundData;
};
