import { Box } from "@mui/material";
import ResponsiveTableGrid from "../../../../SmallComponents/TableGrid/ResponsiveTableGrid";
import { TableGridColumnSchema } from "../../../../SmallComponents/TableGrid/constructTableGrid";
import { SerializedStyles } from "@emotion/react";
import { MaintenancePlanTableTaskRow } from "../maintenancePlanUtils";
import { useTranslateFields } from "../../../../../Global/Hooks/useTranslations";

const colSchema: TableGridColumnSchema[] = [
  { id: "setup", label: "Machine", type: "string" },
  { id: "taskDescription", label: "Task Description", type: "string" },
  {
    id: "status",
    label: "Status",
    type: "string",
  },
  { id: "startDate", label: "Planned Start Date", type: "date" },
  { id: "plannedDuration", label: "Planned Duration", type: "string" },
  { id: "executionDuration", label: "Execution Duration", type: "string" },
  {
    id: "repetitive",
    label: "Repetitive",
    type: "boolean",
  },
  {
    id: "extraDescription",
    label: "Extra Description",
    type: "string",
  },
  {
    id: "comments",
    label: "Comments",
    type: "button",
    disableEditing: true,
  },
  {
    id: "responsible",
    label: "Maintenance Responsible",
    type: "button",
    disableEditing: true,
  },
  { id: "documents", label: "Documents", type: "button", disableEditing: true },
  { id: "workHours", label: "Working Hours", type: "button", disableEditing: true },
  { id: "actions", label: "Actions", type: "button" },
];

type ColumnsToHide = keyof MaintenancePlanTableTaskRow | "actions";

interface MaintenanceTableProps {
  css?: (SerializedStyles | false)[] | SerializedStyles;
  className?: string;
  rows: MaintenancePlanTableTaskRow[];
  loading?: boolean;
  mobileView?: boolean;
  hideColumns?: ColumnsToHide[];
}

const MaintenancePlanTable: React.FC<MaintenanceTableProps> = ({
  className,
  rows,
  loading,
  mobileView,
  hideColumns = [],
}) => {
  return (
    <Box component="div" className={className}>
      <ResponsiveTableGrid
        rows={rows}
        colSchema={useTranslateFields(colSchema, ["label"]).filter(
          (col) => !hideColumns.includes(col.id as ColumnsToHide)
        )}
        responsive={mobileView ? "mobile" : "responsive"}
        configuration={{
          hideAddButton: true,
          density: "compact",
          hideMobileSearch: mobileView,
          hideMobilePagination: mobileView,
        }}
        loading={loading}
        tableID="MaintenancePlanTable_table"
      />
    </Box>
  );
};

export default MaintenancePlanTable;
