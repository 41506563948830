import { Box, IconButton, Stack, Typography } from "@mui/material";
import { SerializedStyles } from "@emotion/react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useEffect, useState } from "react";
import {
  startOfMonth,
  subMonths,
  endOfMonth,
  startOfQuarter,
  subQuarters,
  endOfQuarter,
  startOfYear,
  subYears,
  endOfYear,
} from "date-fns";
import {
  formatDateAndTime,
  handleGetSelectOption,
} from "../../../Global/Utils/commonFunctions";
import Select from "../../MaterialUI/FormFields/Select";
import { SelectOption } from "../../../Global/Types/commonTypes";
import cssLayoutStyles from "../../../Global/Styles/layout";
import { useLanguageContext } from "../../../context/LanguageContext";
import { useTranslateSchemeArray } from "../../../Global/Hooks/useTranslations";

type CalendarStartEnd = {
  from: string;
  to: string;
};

type PeriodOption = "month" | "quarter" | "year";
const PERIOD_OPTIONS = handleGetSelectOption(["month", "quarter", "year"], true);

const workingDaysOptions2: SelectOption[] = handleGetSelectOption([
  "Shift A",
  "Shift B",
  "Shift C",
]);

interface MaintenanceCalendarPeriodProps {
  css?: (SerializedStyles | false)[] | SerializedStyles;
  className?: string;
  calendarFromTo: CalendarStartEnd;
  setCalendarFromTo: React.Dispatch<React.SetStateAction<CalendarStartEnd>>;
  period: PeriodOption;
  setPeriod: React.Dispatch<React.SetStateAction<PeriodOption>>;
}

const ShiftCalendarPeriod: React.FC<MaintenanceCalendarPeriodProps> = ({
  className,
  setCalendarFromTo,
  calendarFromTo,
  period,
  setPeriod,
}) => {
  const { t } = useLanguageContext();
  const styles = { ...cssLayoutStyles };
  const [interval, setInterval] = useState<number>(0);

  useEffect(() => {
    const currentDate = new Date();
    let startDate = new Date();
    let endDate = new Date();

    switch (period) {
      case "month": {
        startDate = startOfMonth(subMonths(currentDate, interval));
        endDate = endOfMonth(subMonths(currentDate, interval));
        break;
      }
      case "quarter": {
        startDate = startOfQuarter(subQuarters(currentDate, interval));
        endDate = endOfQuarter(subQuarters(currentDate, interval));
        break;
      }
      case "year": {
        startDate = startOfYear(subYears(currentDate, interval));
        endDate = endOfYear(subYears(currentDate, interval));
        break;
      }
    }

    setCalendarFromTo({ from: startDate.toISOString(), to: endDate.toISOString() });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [period, interval]);

  const handleChangeInterval = (direction: "back" | "next") => {
    if (direction === "back") {
      setInterval((prev) => prev + 1);
    }
    if (direction === "next") {
      setInterval((prev) => prev - 1);
    }
  };

  const handleChangePeriod = (value: string) => {
    setPeriod(value as PeriodOption);
    setInterval(0);
  };

  return (
    <Box component="div" className={className}>
      <Stack
        spacing={3}
        direction={{ xs: "column", sm: "row" }}
        alignItems="center"
        justifyContent="center"
      >
        <Typography>
          {`${formatDateAndTime(calendarFromTo.from, "date")} - ${formatDateAndTime(
            calendarFromTo.to,
            "date"
          )}`}
        </Typography>

        <Stack spacing={1.25} direction="row" alignItems="center" justifyContent="center">
          <IconButton
            aria-label="previous period"
            onClick={() => handleChangeInterval("back")}
          >
            <ArrowBackIcon />
          </IconButton>

          <Box component="div" style={{ minWidth: "150px" }}>
            <Select
              fullWidth
              selectOptions={useTranslateSchemeArray(PERIOD_OPTIONS)}
              value={period}
              onChange={(e) => handleChangePeriod(e.target.value)}
            />
          </Box>

          <IconButton
            aria-label="next period"
            onClick={() => handleChangeInterval("next")}
          >
            <ArrowForwardIcon />
          </IconButton>
        </Stack>

        <Box
          component="div"
          css={[styles.width100, styles.widthLimit15]}
          style={{ paddingBottom: "14px" }}
        >
          <Select
            css={styles.widthLimit15}
            label={t("Working Shift")}
            selectOptions={useTranslateSchemeArray(workingDaysOptions2)}
          />
        </Box>
      </Stack>
    </Box>
  );
};

export default ShiftCalendarPeriod;
