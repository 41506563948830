import React, { memo, useState } from "react";
import useTheme from "@mui/material/styles/useTheme";
import { Box, Stack, Typography } from "@mui/material";
import { useReactFlow } from "reactflow";
import AdsClickIcon from "@mui/icons-material/AdsClick";
import Modal from "../../../../../../Components/MaterialUI/Modal";
import PidFlowNode from "../PidFlowNode";
import { PID_FLOW_NODE_TYPE, PID_WORKFLOW_TOP_PART_HEIGHT } from "../pidNodesUtils";
import { PidWorkflowsInputNodeData } from "./pidInputNodeUtils";
import PidNodeInputForm from "./PidNodeInputForm";
import cssFontStyles from "../../../../../../Global/Styles/font";
import { useTranslatedModalTitle } from "../../../../../../Global/Hooks/useTranslations";

const modalTitleTranslations = {
  "Edit Node": "Edit Node",
  "Node Data": "Node Data",
} as const;

type ModalType = keyof typeof modalTitleTranslations;

interface FlowCustomNodeProps {
  id: string;
  data: PidWorkflowsInputNodeData;
}

const PidWorkflowInputNode: React.FC<FlowCustomNodeProps> = ({ id, data }) => {
  const theme = useTheme();
  const styles = { ...cssFontStyles };
  const [modalType, setModalType] = useState<ModalType | null>(null);
  const getTranslatedModalTitle = useTranslatedModalTitle(modalTitleTranslations);
  const [unsavedChanges, setUnsavedChanges] = useState<boolean>(false);

  const { setNodes, getNodes } = useReactFlow();
  const isViewMode = data.mode === "View Mode";
  let nodeLabel = data.altName
    ? `${data.dataField.parameter_name} (${data.altName})`
    : data.dataField.parameter_name;
  // let borderColor = theme.palette.background.paper;

  const textColor =
    theme.palette.mode === "dark" && data.mode === "Edit Mode"
      ? theme.palette.common.white
      : theme.palette.text.primary;

  if (isViewMode) {
    // borderColor = theme.palette.common.black;
    if (data.altName) {
      nodeLabel = data.altName;
    } else {
      nodeLabel = data.dataField.parameter_name;
    }
  }

  const handleEditSubmit = (nodeData: PidWorkflowsInputNodeData) => {
    setNodes((nds) =>
      nds.map((node) => {
        if (node.id === id) {
          return {
            ...node,
            data: nodeData,
          };
        }
        return node;
      })
    );

    setModalType(null);
  };

  const handleOpenEditModal = () => {
    setModalType("Edit Node");
  };

  const handleSetUnsavedChanges = (unsavedChanges: boolean) => {
    if (unsavedChanges) {
      setUnsavedChanges(true);
    }
  };

  return (
    <>
      <PidFlowNode
        id={id}
        handleOpenEditModal={handleOpenEditModal}
        icon={<AdsClickIcon fontSize="small" />}
        nodeType={PID_FLOW_NODE_TYPE.Input}
      >
        {data.mode === "View Mode" ? (
          <Box sx={{ height: PID_WORKFLOW_TOP_PART_HEIGHT }} component="div" />
        ) : null}
        {data.dataField ? (
          <Stack
            sx={{
              // border: data.mode === "View Mode" ? `1px solid ${borderColor}` : undefined,
              padding: `${theme.spacing(0.5)} ${theme.spacing(1.2)}`,
              borderRadius: "4px",
              marginTop: "-7px",
            }}
            spacing={0.5}
          >
            <Typography css={styles.bolderText} variant="body1" color={textColor}>
              {nodeLabel}
            </Typography>
            <Typography css={styles.bolderText} color={textColor}>
              {data.dataField.value || ""}
              <Typography sx={{ paddingLeft: "5px" }} component="span" color={textColor}>
                ({data.dataField.unit_of_measure_symbol})
              </Typography>
            </Typography>
          </Stack>
        ) : null}
      </PidFlowNode>

      <Modal
        onClose={() => setModalType(null)}
        open={!!modalType}
        fullWidth
        label={modalType ? getTranslatedModalTitle(modalType) : ""}
        unsavedChanges={unsavedChanges}
        setUnsavedChanges={setUnsavedChanges}
      >
        {modalType === "Edit Node" ? (
          <PidNodeInputForm
            handleEditSubmit={handleEditSubmit}
            data={data}
            nodes={getNodes()}
            handleSetUnsavedChanges={handleSetUnsavedChanges}
            setUnsavedChanges={setUnsavedChanges}
            unsavedChanges={unsavedChanges}
          />
        ) : null}
      </Modal>
    </>
  );
};

export default memo(PidWorkflowInputNode);
