import { Box, Stack } from "@mui/material";
import { ViewMode } from "gantt-task-react";
import Checkbox from "../../../MaterialUI/FormFields/Checkbox";
import Select from "../../../MaterialUI/FormFields/Select";
import { SelectOption } from "../../../../Global/Types/commonTypes";
import { handleGetSelectOption } from "../../../../Global/Utils/commonFunctions";
import { useLanguageContext } from "../../../../context/LanguageContext";
import { useTranslateSchemeArray } from "../../../../Global/Hooks/useTranslations";

const viewOptions: SelectOption[] = handleGetSelectOption([
  "Day",
  "Week",
  "Month",
  "Year",
]);

type GanttChartViewSwitcherProps = {
  view: ViewMode;
  isChecked: boolean;
  showPeriodSwitcher?: boolean;
  onViewListChange: (isChecked: boolean) => void;
  onViewModeChange: (viewMode: ViewMode) => void;
};
const GanttChartViewSwitcher: React.FC<GanttChartViewSwitcherProps> = ({
  view,
  onViewModeChange,
  onViewListChange,
  isChecked,
  showPeriodSwitcher,
}) => {
  const { t } = useLanguageContext();

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      mb={2}
      mt={1}
      px={2}
      spacing={3}
    >
      <Box component="div" style={{ minWidth: "170px" }}>
        <Checkbox
          label={t("Show Task List")}
          onChange={() => onViewListChange(!isChecked)}
          defaultChecked={isChecked}
        />
      </Box>

      {showPeriodSwitcher ? (
        <Box component="div">
          <Select
            fullWidth
            label={t("View Mode")}
            value={view}
            selectOptions={useTranslateSchemeArray(viewOptions)}
            onChange={(e) => onViewModeChange(e.target.value as ViewMode)}
          />
        </Box>
      ) : null}
    </Stack>
  );
};

export default GanttChartViewSwitcher;
