import React, { memo, useState } from "react";
import useTheme from "@mui/material/styles/useTheme";
import { Box, Typography } from "@mui/material";
import { useReactFlow } from "reactflow";
import AdsClickIcon from "@mui/icons-material/AdsClick";
import cssSpacingStyles from "../../../../../../Global/Styles/spacing";
import cssLayoutStyles from "../../../../../../Global/Styles/layout";
import Modal from "../../../../../../Components/MaterialUI/Modal";
import PidFlowNode from "../PidFlowNode";
import { PID_FLOW_NODE_TYPE, PID_WORKFLOW_TOP_PART_HEIGHT } from "../pidNodesUtils";
import { PidWorkflowsTextNodeData } from "./pidTextNodeUtils";
import PidNodeTextForm from "./PidNodeTextForm";
import { useTranslatedModalTitle } from "../../../../../../Global/Hooks/useTranslations";

const modalTitleTranslations = {
  "Edit Node": "Edit Node",
  "Node Data": "Node Data",
} as const;

type ModalType = keyof typeof modalTitleTranslations;

interface FlowCustomNodeProps {
  id: string;
  data: PidWorkflowsTextNodeData;
}

const PidWorkflowTextNode: React.FC<FlowCustomNodeProps> = ({ id, data }) => {
  const theme = useTheme();
  const styles = {
    ...cssSpacingStyles(theme),
    ...cssLayoutStyles,
  };
  const [modalType, setModalType] = useState<ModalType | null>(null);
  const getTranslatedModalTitle = useTranslatedModalTitle(modalTitleTranslations);
  const [unsavedChanges, setUnsavedChanges] = useState<boolean>(false);

  const { setNodes, getNodes } = useReactFlow();

  const handleEditSubmit = (nodeData: PidWorkflowsTextNodeData) => {
    setNodes((nds) =>
      nds.map((node) => {
        if (node.id === id) {
          return {
            ...node,
            data: nodeData,
          };
        }
        return node;
      })
    );

    setModalType(null);
  };

  const handleOpenEditModal = () => {
    setModalType("Edit Node");
  };

  const handleSetUnsavedChanges = (unsavedChanges: boolean) => {
    if (unsavedChanges) {
      setUnsavedChanges(true);
    }
  };

  return (
    <>
      <PidFlowNode
        id={id}
        handleOpenEditModal={handleOpenEditModal}
        icon={<AdsClickIcon fontSize="small" />}
        nodeType={PID_FLOW_NODE_TYPE.Text}
      >
        {data.mode === "View Mode" ? (
          <Box sx={{ height: PID_WORKFLOW_TOP_PART_HEIGHT }} component="div" />
        ) : null}
        <Typography
          css={styles.textBreak}
          variant="h3"
          align="center"
          color={data.mode === "View Mode" ? "textPrimary" : undefined}
        >
          {data.label}
        </Typography>
      </PidFlowNode>

      <Modal
        onClose={() => setModalType(null)}
        open={!!modalType}
        fullWidth
        label={modalType ? getTranslatedModalTitle(modalType) : ""}
        unsavedChanges={unsavedChanges}
        setUnsavedChanges={setUnsavedChanges}
      >
        {modalType === "Edit Node" ? (
          <PidNodeTextForm
            handleEditSubmit={handleEditSubmit}
            data={data}
            nodes={getNodes()}
            handleSetUnsavedChanges={handleSetUnsavedChanges}
            setUnsavedChanges={setUnsavedChanges}
            unsavedChanges={unsavedChanges}
          />
        ) : null}
      </Modal>
    </>
  );
};

export default memo(PidWorkflowTextNode);
