import { Box, Stack, Tooltip, Typography } from "@mui/material";
import { LegendDatum } from "@nivo/pie";
import { PieDonutChartData } from "../EditExcellenceChartForms/excellenceChartFormUtils";
import {
  getPieCustomLegendParamName,
  PieChartDataSchema,
} from "./pieChartTypes";

interface PieChartLegendProps {
  title?: string;
  customLegends: LegendDatum<PieDonutChartData>[];
  width: number;
  schema: PieChartDataSchema | null;
  paramMapping: Record<string, string>;
  handleToggleVisibility: (id: string) => void;
  direction?: "row" | "column";
}

const PieChartLegend: React.FC<PieChartLegendProps> = ({
  title,
  customLegends,
  width,
  schema,
  paramMapping,
  handleToggleVisibility,
  direction = "column",
}) => {
  const legendText = schema && schema.parameters.length 
  ? `by ${paramMapping[schema.parameters[0].parameterId]} (${schema.parameters[0].aggregation})` 
  : '';

  return (
    <>
      {
        customLegends.length > 0 && 
        <Typography variant="body1" textAlign="center" sx={{ fontSize: '10px', textAlign: 'left' }}>
          {title} {legendText}
        </Typography>
      }
      <Box
        component="div"
        style={{
          overflowY: direction === "column" ? "auto" : "hidden",
          overflowX: direction === "row" ? "hidden" : "auto",
          height: "fit-content",
          maxHeight: "100%",
          maxWidth: `${width}px`,
          // paddingBottom: "4rem",
        }}
      >
        <Stack
          spacing={1}
          justifyContent="center"
          direction={direction}
          flexWrap={direction === "row" ? "wrap" : "nowrap"}
        >
          {customLegends.map((item) => {
            return (
              <Tooltip
                key={`${item.label}-${item.color}`}
                arrow
                title={item.label}
              >
                <Box
                  key={item.label}
                  onClick={() => handleToggleVisibility(String(item.id))}
                  component="div"
                  style={{
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    marginRight: direction === "row" ? 2 : "0",
                  }}
                >
                  <Box
                    component="div"
                    mr={1}
                    style={{
                      width: "15px",
                      height: "15px",
                      borderRadius: "100%",
                      backgroundColor: item.color,
                      opacity: item.hidden ? 0.3 : 1,
                    }}
                  />

                  <Typography
                    style={{
                      flex: 1,
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                    }}
                    variant="caption"
                  >
                    {getPieCustomLegendParamName(item.label, paramMapping)}
                  </Typography>
                </Box>
              </Tooltip>
            )
          })}
        </Stack>
      </Box>
    </>
  );
};

export default PieChartLegend;
