import { Stack } from "@mui/material";
import TextField from "../../../../MaterialUI/FormFields/TextFields";
import useTheme from "@mui/material/styles/useTheme";
import cssLayoutStyles from "../../../../../Global/Styles/layout";
import cssSpacingStyles from "../../../../../Global/Styles/spacing";
import { MqttConnectionParametersData } from "./mqttConnectionTypes";
import { useCallback, useEffect, useState } from "react";
import debounce from "lodash.debounce";
import { useLanguageContext } from "../../../../../context/LanguageContext";

interface MqttConnectionParametersProps {
  data: MqttConnectionParametersData;
  handleUpdateData: (data: MqttConnectionParametersData) => void;
  isLoading: boolean;
  isEditSub?: boolean;
}

const MqttConnectionParameters: React.FC<MqttConnectionParametersProps> = ({
  data,
  handleUpdateData,
  isLoading,
  isEditSub,
}) => {
  const { t } = useLanguageContext();
  const theme = useTheme();
  const styles = { ...cssSpacingStyles(theme), ...cssLayoutStyles };
  const [stateData, setStateData] = useState<MqttConnectionParametersData>(data);

  useEffect(() => {
    if (JSON.stringify(data) !== JSON.stringify(stateData)) {
      setStateData(() => data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (JSON.stringify(data) !== JSON.stringify(stateData)) {
      debounceUpdateData(stateData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateData]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceUpdateData = useCallback(debounce(handleUpdateData, 500), []);

  const handleChangeStateData = (key: string, value: string) => {
    setStateData((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  return (
    <Stack css={styles.width100} spacing={2}>
      <TextField
        label={t("Connection Name")}
        value={stateData.name}
        onChange={(e) => handleChangeStateData("name", e.target.value)}
        fullWidth
        disabled={isLoading}
      />

      <Stack direction="row" spacing={2}>
        <TextField
          css={styles.width100}
          label={t("Broker Host")}
          value={stateData.broker}
          onChange={(e) => handleChangeStateData("broker", e.target.value)}
          fullWidth
          disabled={isLoading || isEditSub}
        />
        <TextField
          css={styles.widthLimit15}
          label={t("Broker Port")}
          value={stateData.port}
          onChange={(e) => handleChangeStateData("port", e.target.value)}
          fullWidth
          disabled={isLoading || isEditSub}
          numberField
          noThousandSeparator
          allowNegatives={false}
        />
      </Stack>
    </Stack>
  );
};

export default MqttConnectionParameters;
