import { Box, Stack, Typography } from "@mui/material";
import { LongPlanningProductRow, LongPlanningMachineRow } from "./longTermPlanningUtils";
import { useState } from "react";
import { FormStatuses } from "../../../Global/Types/commonTypes";
import Button from "../../../Components/MaterialUI/Button";
import Alert from "../../../Components/MaterialUI/Alert";
import callApi from "../../../Api/callApi";
import {
  deleteQueryNeoperlMachine,
  deleteQueryNeoperlProduct,
} from "./longTermEndpoints";
import { useAuthedContext } from "../../../context/AuthContext";
import { useLanguageContext } from "../../../context/LanguageContext";

interface LongTermDeleteModalProps {
  product: LongPlanningProductRow | null;
  machine: LongPlanningMachineRow | null;
  onSubmit: () => Promise<void>;
}

const LongTermDeleteModal: React.FC<LongTermDeleteModalProps> = ({
  product,
  machine,
  onSubmit,
}) => {
  const { t } = useLanguageContext();
  const [formStatus, setFormStatus] = useState<FormStatuses>(null);
  const [alertMessage, setAlertMessage] = useState<string | null>(null);
  const { setAuthedUser } = useAuthedContext();

  const handleConfirmDelete = async () => {
    try {
      setFormStatus("loading");
      setAlertMessage(null);
      if (machine?.machine_id) {
        await callApi({
          query: deleteQueryNeoperlMachine(machine.machine_id),
          auth: { setAuthedUser },
        });
      }
      if (product?.product_id) {
        await callApi({
          query: deleteQueryNeoperlProduct(product.product_id),
          auth: { setAuthedUser },
        });
      }
      await onSubmit();

      setFormStatus(null);
      setAlertMessage(null);
    } catch (error) {
      setFormStatus("error");
      setAlertMessage(t("Something went wrong"));
      console.log("There was an error: ", error);
    }
  };

  //  utilisation machines if 100 to be red

  return (
    <Box component="div">
      <Stack spacing={3} alignItems="center" justifyContent="center">
        <Typography variant="body1">{t("Are you sure you want to delete?")}</Typography>

        <Button
          disabled={formStatus === "loading"}
          color="error"
          onClick={handleConfirmDelete}
        >
          {`${t("Confirm Delete")} ${product?.product_id ? t("Product") : t("Machine")}`}
        </Button>

        <Alert
          message={alertMessage || ""}
          showAlert={!!alertMessage}
          severity={formStatus}
        />
      </Stack>
    </Box>
  );
};

export default LongTermDeleteModal;

/**
 *
 * 3 sections = Europe, Asia
 *
 * Europe | Bulgaria, Italy, Germany
 *
 * Asia | China, Thailand
 *
 */
