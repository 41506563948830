import { Stack, useTheme } from "@mui/material";
import { Node, Edge, useReactFlow } from "reactflow";
import { useState } from "react";
import cssLayoutStyles from "../../../../../Global/Styles/layout";
import cssSpacingStyles from "../../../../../Global/Styles/spacing";
import Modal from "../../../../../Components/MaterialUI/Modal";
import Select from "../../../../../Components/MaterialUI/FormFields/Select";
import { PidFlowNewNodeModal } from "../../pidWorkflowUtils";
import {
  PID_FLOW_NODE_TYPE,
  PidFlowAllNodesData,
  PidFlowNodeTypeKey,
} from "../Nodes/pidNodesUtils";
import { SelectOption } from "../../../../../Global/Types/commonTypes";
import { PID_WORKFLOW_TEXT_NODE_CONFIG } from "../Nodes/Text/pidTextNodeUtils";
import PidNodeTextForm from "../Nodes/Text/PidNodeTextForm";
import { PID_WORKFLOW_SVG_NODE_CONFIG } from "../Nodes/Svg/pidSvgNodeUtils";
import PidNodeSvgForm from "../Nodes/Svg/PidNodeSvgForm";
import { PID_WORKFLOW_INPUT_NODE_CONFIG } from "../Nodes/Input/pidInputNodeUtils";
import { PID_WORKFLOW_BACKGROUND_NODE_CONFIG } from "../Nodes/Background/pidBackgroundNodeUtils";
import PidNodeInputForm from "../Nodes/Input/PidNodeInputForm";
import PidNodeBackgroundForm from "../Nodes/Background/PidNodeBackgroundForm";
import { useLanguageContext } from "../../../../../context/LanguageContext";

const NODE_OPTIONS: SelectOption<PidFlowNodeTypeKey>[] = [
  {
    value: "Text",
    description: "Label",
  },
  {
    value: "Input",
    description: "Process Parameter",
  },
  { value: "SVG", description: "P&ID Component" },
  { value: "Background", description: "Background" },
];

interface PidFlowCreateNewNodeModalProps {
  newNodeModal: PidFlowNewNodeModal;
  setNewNodeModal: React.Dispatch<React.SetStateAction<PidFlowNewNodeModal>>;
  setNodes: React.Dispatch<React.SetStateAction<Node<any>[]>>;
  setEdges: React.Dispatch<React.SetStateAction<Edge<any>[]>>;
  connectingNodeId: React.MutableRefObject<any>;
}

const PidFlowCreateNewNodeModal: React.FC<PidFlowCreateNewNodeModalProps> = ({
  newNodeModal,
  setNewNodeModal,
  setNodes,
  setEdges,
  connectingNodeId,
}) => {
  const { t } = useLanguageContext();
  const theme = useTheme();
  const styles = { ...cssSpacingStyles(theme), ...cssLayoutStyles };
  const [selectedType, setSelectedType] = useState<PID_FLOW_NODE_TYPE | "">("");
  const [typeConfig, setTypeConfig] = useState<any>(null);
  const [unsavedChanges, setUnsavedChanges] = useState<boolean>(false);
  const { getNodes } = useReactFlow();

  const handleOnTypeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value as PID_FLOW_NODE_TYPE;
    switch (val) {
      case PID_FLOW_NODE_TYPE.Text: {
        setTypeConfig(PID_WORKFLOW_TEXT_NODE_CONFIG);
        break;
      }
      case PID_FLOW_NODE_TYPE.Input: {
        setTypeConfig(PID_WORKFLOW_INPUT_NODE_CONFIG);
        break;
      }
      case PID_FLOW_NODE_TYPE.SVG: {
        setTypeConfig(PID_WORKFLOW_SVG_NODE_CONFIG);
        break;
      }
      case PID_FLOW_NODE_TYPE.Background: {
        setTypeConfig(PID_WORKFLOW_BACKGROUND_NODE_CONFIG);
        break;
      }
    }
    setSelectedType(val);
  };

  const handleCreateSubmit = (nodeData: PidFlowAllNodesData) => {
    if (newNodeModal && typeConfig) {
      const data = getNodeData(nodeData);
      const dataID = data!.id!;
      const newNode = {
        id: dataID,
        position: newNodeModal.position,
        data: data,
        ...typeConfig,
      };

      setNodes((nds) => nds.concat(newNode));
      setEdges((eds) =>
        eds.concat({
          id: newNodeModal.id,
          source: connectingNodeId.current,
          target: dataID,
          type: "step",
        })
      );
      setNewNodeModal(null);
      setSelectedType("");
    }
  };

  const handleSetUnsavedChanges = (unsavedChanges: boolean) => {
    if (unsavedChanges) {
      setUnsavedChanges(true);
    }
  };

  return (
    <Modal
      onClose={() => setNewNodeModal(null)}
      open={!!newNodeModal}
      fullWidth
      label={t("Creating New Node")}
      unsavedChanges={unsavedChanges}
      setUnsavedChanges={setUnsavedChanges}
    >
      <Stack
        css={styles.width100}
        spacing={3}
        alignItems="center"
        justifyContent="center"
      >
        <Select
          selectOptions={NODE_OPTIONS}
          fullWidth
          value={selectedType}
          onChange={handleOnTypeChange}
        />

        {selectedType === PID_FLOW_NODE_TYPE.Text ? (
          <PidNodeTextForm
            handleCreateSubmit={(data) =>
              handleCreateSubmit({
                Text: data,
              })
            }
            nodes={getNodes()}
            handleSetUnsavedChanges={handleSetUnsavedChanges}
            setUnsavedChanges={setUnsavedChanges}
            unsavedChanges={unsavedChanges}
          />
        ) : null}

        {selectedType === PID_FLOW_NODE_TYPE.SVG ? (
          <PidNodeSvgForm
            handleCreateSubmit={(data) =>
              handleCreateSubmit({
                SVG: data,
              })
            }
            nodes={getNodes()}
            handleSetUnsavedChanges={handleSetUnsavedChanges}
            setUnsavedChanges={setUnsavedChanges}
            unsavedChanges={unsavedChanges}
          />
        ) : null}

        {selectedType === PID_FLOW_NODE_TYPE.Input ? (
          <PidNodeInputForm
            handleCreateSubmit={(data) =>
              handleCreateSubmit({
                Input: data,
              })
            }
            nodes={getNodes()}
            handleSetUnsavedChanges={handleSetUnsavedChanges}
            setUnsavedChanges={setUnsavedChanges}
            unsavedChanges={unsavedChanges}
          />
        ) : null}

        {selectedType === PID_FLOW_NODE_TYPE.Background ? (
          <PidNodeBackgroundForm
            handleCreateSubmit={(data) =>
              handleCreateSubmit({
                Background: data,
              })
            }
            nodes={getNodes()}
            handleSetUnsavedChanges={handleSetUnsavedChanges}
            setUnsavedChanges={setUnsavedChanges}
            unsavedChanges={unsavedChanges}
          />
        ) : null}
      </Stack>
    </Modal>
  );
};

export default PidFlowCreateNewNodeModal;

const getNodeData = (nodeData: PidFlowAllNodesData) => {
  const foundData = Object.values(nodeData).find((val) => !!val);
  return foundData;
};
