import { SerializedStyles } from "@emotion/react";
import { Box, Stack, Typography, useTheme } from "@mui/material";
import { RadarSliceTooltipProps, ResponsiveRadar } from "@nivo/radar";
import { RadarChartConfiguration } from "./radarChartTypes";
import { BarColumnRadarData } from "../EditExcellenceChartForms/excellenceChartFormUtils";
import { getChartColors } from "../nivoTheme";
import { BarChartDataSchema } from "../BarChart/barChartTypes";
import { formatNumber } from "../../../Global/Utils/commonFunctions";
import { getBarKeysAndLegend, LegendItem } from "../excellenceUtils";
import useContainerDimensions from "../../../Global/Hooks/useContainerDimensions";
import { memo, useMemo, useRef, useState } from "react";
import { CustomLegend, ChartNotRendered } from "../ExcellenceSmallComponents";
import {
  BarDownSampledCombinedData,
  getBarDownSampledData,
} from "../BarChart/barChartUtils";
import cssComponentsStyles from "../../../Global/Styles/components";
import cssLayoutStyles from "../../../Global/Styles/layout";
import cssSpacingStyles from "../../../Global/Styles/spacing";

interface RadarChartProps {
  css?: SerializedStyles[] | SerializedStyles;
  className?: string;
  configuration: RadarChartConfiguration;
  data: BarColumnRadarData | null;
  isStatic?: boolean;
  indexBy?: string;
  noAnimation?: boolean;
  schema: BarChartDataSchema | null;
  paramMapping?: Record<string, string>;
  height?: number;
}

const RadarChart: React.FC<RadarChartProps> = ({
  className,
  data,
  configuration,
  isStatic,
  indexBy,
  height,
}) => {
  const theme = useTheme();
  const { enableDotLabel, enableDots, interpolate } = configuration;
  const chartContainerRef = useRef<HTMLDivElement>(null);
  const { width: chartWidth } = useContainerDimensions(chartContainerRef);
  const legendWidth = Math.max(chartWidth * 0.1, 100);
  const { dynamicData, notRenderedData, combinedSegments } = useMemo(
    () => getBarDownSampledData(data || [], indexBy),
    [data, indexBy]
  );

  const colors = getChartColors();
  const keysAndLegend = useMemo(
    () => getBarKeysAndLegend(dynamicData[0], getChartColors(), indexBy),
    [dynamicData, indexBy]
  );

  const [visibleKeys, setVisibleKeys] = useState<string[]>(
    () => keysAndLegend?.formattedKeys?.keys || []
  );

  const groupOfParam = dynamicData.map((item) => item.group);
  const keysAndGroupOfParam: LegendItem[] = groupOfParam.map((item, index) => {
    return {
      label: String(item),
      color: String(colors[index % colors.length]),
    };
  })
  const [visibleGroupOfParamKeys, setVisibleGroupOfParamKeysKeys] = useState<string[]>(
    () => keysAndGroupOfParam.map((item) => item.label) || []
  );

  const handleToggleVisibility = (key: string) => {
    setVisibleKeys((prev) =>
      prev.includes(key) ? prev.filter((item) => item !== key) : [...prev, key]
    );
  };

  const handleToggleVisibilityOfGroupOfParams = (key: string) => {
    setVisibleGroupOfParamKeysKeys((prev) =>
      prev.includes(key) ? prev.filter((item) => item !== key) : [...prev, key]
    );
  };

  const filteredData = useMemo(
    () =>
      dynamicData
        .filter((item) => {
          if (!configuration?.filtersToDashboard?.isGroupParameterEnabled) {
            return true;
          }
          return visibleGroupOfParamKeys.includes(String(item.group));
        })
        .map((item) =>
          Object.fromEntries(
            Object.entries(item).filter(
              ([key]) => visibleKeys.includes(key) || key === "group"
            )
          )
        ),
    [dynamicData, visibleKeys, visibleGroupOfParamKeys, configuration?.filtersToDashboard?.isGroupParameterEnabled]
  );

  const filteredKeys = useMemo(
    () => keysAndLegend?.formattedKeys.keys.filter((key) => visibleKeys.includes(key)),
    [keysAndLegend, visibleKeys]
  );

  const chartMargin = {
    top: isStatic ? 5 : 60,
    right: isStatic ? 5 : 25,
    bottom: isStatic ? 5 : 45,
    left: isStatic ? 5 : 25,
  };

  const getFormattedValue = (value: number | null) => {
    const result = formatNumber(
      value,
      configuration.fixedDecimalValue,
      configuration.decimalPoints
    );
    if (!result) {
      return `${value}`;
    }
    return `${result}`;
  };

  return (
    <Box className={className} ref={chartContainerRef} component="div">
      <Stack
        style={{ width: "100%", height: height ? `${height}px` : "100%" }}
        direction="row"
        spacing={0}
      >
        {notRenderedData.length ? (
          <ChartNotRendered />
        ) : (
          <>
            <Box
              component="div"
              style={{
                width: isStatic ? "100%" : `calc(${chartWidth}px - ${legendWidth}px)`,
                height: "100%",
              }}
            >
              <ResponsiveRadar
                data={filteredData}
                keys={filteredKeys || []}
                indexBy={keysAndLegend?.formattedKeys?.indexBy || ""}
                colors={keysAndLegend?.colors}
                theme={{
                  text: {
                    fill: theme.palette.common.black,
                  },
                  tooltip: {
                    container: {
                      background: theme.palette.common.white,
                    },
                  },
                }}
                enableDotLabel={isStatic ? false : enableDotLabel}
                isInteractive={isStatic ? false : true}
                enableDots={enableDots}
                curve={interpolate}
                margin={chartMargin}
                gridLabelOffset={25}
                dotSize={10}
                dotColor={{ theme: "background" }}
                dotBorderWidth={2}
                blendMode={theme.palette.mode === "light" ? "multiply" : undefined}
                dotLabel={(d) => getFormattedValue(d.value)}
                motionConfig="wobbly"
                legends={undefined}
                valueFormat={(d) => getFormattedValue(d)}
                sliceTooltip={(tooltipProps) => {
                  return (
                    <CustomTooltip
                      radarData={tooltipProps}
                      combinedSegments={combinedSegments}
                    />
                  );
                }}
              />
            </Box>

            {isStatic ? null : (
              <Box
                component="div"
                style={{display: "flex", gap: "10px", flexDirection: "column", height: "93%"}}
              >
                {
                configuration?.filtersToDashboard?.isGroupParameterEnabled && 
                  <CustomLegend
                    title="Group Prameter"
                    items={
                      keysAndGroupOfParam.map((item) => ({
                        label: item.label,
                        color: item.color,
                      })) || []
                    }
                    width={legendWidth}
                    visibleItems={visibleGroupOfParamKeys}
                    onToggleVisibility={handleToggleVisibilityOfGroupOfParams}
                    maxHeight={height ? `${height}px` : "100%"}
                    combinedSegments={combinedSegments}
                  />
                }
                {
                (configuration?.filtersToDashboard?.isLegendEnabled ?? true) && 
                  <CustomLegend
                    title="Legend"
                    items={
                      keysAndLegend?.legend.map((item) => ({
                        label: item.label,
                        color: item.color,
                      })) || []
                    }
                    width={legendWidth}
                    visibleItems={visibleKeys}
                    onToggleVisibility={handleToggleVisibility}
                    maxHeight={height ? `${height}px` : "100%"}
                    combinedSegments={combinedSegments}
                  />
                }
              </Box>
            )}
          </>
        )}
      </Stack>
    </Box>
  );
};

export default memo(RadarChart);

interface CustomTooltipProps {
  radarData: RadarSliceTooltipProps;
  combinedSegments: BarDownSampledCombinedData;
}

const CustomTooltip: React.FC<CustomTooltipProps> = memo(({ radarData }) => {
  const theme = useTheme();
  const styles = {
    ...cssLayoutStyles,
    ...cssSpacingStyles(theme),
    ...cssComponentsStyles(theme),
  };

  return (
    <Box
      css={[styles.tooltipStyle, styles.card, styles.leftRightPadding2]}
      component="div"
    >
      <Typography variant="h5">{radarData.index}</Typography>
      <Stack spacing={0.5}>
        {radarData.data.map((item) => (
          <Box component="div" css={[styles.flex]} gap={1} key={item.id}>
            <Box
              component="div"
              sx={{
                width: 14,
                height: 14,
                backgroundColor: item.color,
                borderRadius: "50%",
                mt: 0.5,
              }}
            />
            <Typography>
              {item.id} - <strong>{item.formattedValue}</strong>
            </Typography>
          </Box>
        ))}
      </Stack>
    </Box>
  );
});
