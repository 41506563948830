import { Stack, Box, IconButton, useTheme } from "@mui/material";
import { AutocompleteOption, SelectOption } from "../../../Global/Types/commonTypes";
import {
  GRAPHQL_FILTER_OPERATIONS_NUMBER,
  GRAPHQL_FILTER_OPERATIONS_STRING,
  GraphqlFilter,
  GraphqlFiltersData,
} from "./graphqlFiltersUtils";
import cssLayoutStyles from "../../../Global/Styles/layout";
import cssSpacingStyles from "../../../Global/Styles/spacing";
import Modal from "../../MaterialUI/Modal";
import Select from "../../MaterialUI/FormFields/Select";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import CloseIcon from "@mui/icons-material/Close";
import Alert from "../../MaterialUI/Alert";
import Button from "../../MaterialUI/Button";
import { useEffect, useMemo, useState } from "react";
import Autocomplete from "../../MaterialUI/FormFields/Autocomplete";
import { useLazyQuery } from "@apollo/client";
import { graphQlQueryExcellenceParametersSuggestedValue } from "../../../GraphQL/Excellence/graphQLQueriesExcellence";

const EMPTY_FILTER: GraphqlFilter = {
  containerId: "",
  containerName: "",
  paramID: "",
  paramName: "",
  filterOperation: "",
  filterValue: "",
  type: "",
  suggestedValue: []
};

interface GraphqlFiltersModalProps {
  openFilters: boolean;
  setOpenFilters: React.Dispatch<React.SetStateAction<boolean>>;
  graphqlFilters: GraphqlFilter[];
  onSave: (currentFilters: GraphqlFilter[], coreFilters?: GraphqlFilter[]) => void;
  data: GraphqlFiltersData[];
  selectedContainers: string[];
  onResetToCoreFilters?: () => void;
  customOperations?: SelectOption[];
}

const GraphqlFiltersModal: React.FC<GraphqlFiltersModalProps> = ({
  openFilters,
  setOpenFilters,
  graphqlFilters,
  onSave,
  data,
  selectedContainers,
  onResetToCoreFilters,
  customOperations
}) => {
  const theme = useTheme();
  const styles = {
    ...cssLayoutStyles,
    ...cssSpacingStyles(theme),
  };
  const [alertMessage, setAlertMessage] = useState<string | null>(null);
  const [stateFilters, setStateFilters] = useState<GraphqlFilter[]>(
    graphqlFilters.length ? graphqlFilters : [EMPTY_FILTER]
  );
  const [isLoadingSuggestedValues, setIsLoadingSuggestedValues] = useState<boolean>(false);

  const paramOptions = useMemo(
    () => getParamOptions(selectedContainers, data, stateFilters),
    [data, selectedContainers, stateFilters]
  );

  const [getParamSuggestedValue] = useLazyQuery(graphQlQueryExcellenceParametersSuggestedValue);

  useEffect(() => {
    const fetchAndUpdateFilters = async () => {
      const updatedFilters = [...stateFilters];
  
      const suggestedValuesPromises = stateFilters.map(async (item) => {
        const paramSuggestedValues = await getParamSuggestedValuesFromGQL(item.paramID);
        item.suggestedValue = paramSuggestedValues || [];
      });
      await Promise.all(suggestedValuesPromises);
  
      setStateFilters(updatedFilters);
    };
    fetchAndUpdateFilters();
  
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [graphqlFilters]);

  const handleAddNewFilter = () => {
    setStateFilters((prev) => [...prev, EMPTY_FILTER]);
  };

  const handleOnChange = async (
    fieldIndex: number,
    field: "param" | "operation" | "value",
    value: any
  ) => {
    if ( field === 'param' ) {
      setStateFilters((prev) =>
        prev.map((item, index) => {
          let updatedItem = { ...item };
          updatedItem = {
            ...EMPTY_FILTER,
            paramID: value ? value.value : '',
            paramName: value ? value.description : '',
          };

          if (index === fieldIndex) {
            return { ...updatedItem };
          }
          return item;
        })
      );
    }

    const paramSuggestedValues = await getParamSuggestedValuesFromGQL(value && value.value);
    setStateFilters((prev) =>
      prev.map((item, index) => {
        let updatedItem = { ...item };
        switch (field) {
          case "param": {
            const container = data.find((item) => item.paramID === (value && value.value));
            updatedItem = {
              ...EMPTY_FILTER,
              containerId: (container && container.paramContainer) ? container.paramContainer : '',
              containerName: (container && container.paramContainerName) ? container.paramContainerName : '',
              paramID: value ? value.value : '',
              paramName: value ? value.description : '',
              suggestedValue: value ? paramSuggestedValues : []
            };
            break;
          }
          case "operation": {
            updatedItem = {
              ...updatedItem,
              filterOperation: value,
            };
            break;
          }
          case "value": {
            updatedItem = {
              ...updatedItem,
              filterValue: value ? value.description : '',
            };
            break;
          }
        }

        if (index === fieldIndex) {
          return { ...updatedItem };
        }
        return item;
      })
    );
  };

  const getParamSuggestedValuesFromGQL = async (paramID: string | null) => {
    if (paramID) {
      setIsLoadingSuggestedValues(true);
      const container = data.find((item) => item.paramID === paramID);
      if (container) {
        try {
          const paramSuggestedData = await getParamSuggestedValue({
            variables: {
              input: {
                parameterId: container?.paramID,
                container: container?.paramContainer,
              },
            },
          });

          const paramSuggestedValues: AutocompleteOption[] = [];
          paramSuggestedData.data.parameterValues.values.forEach((value: string, index: string) => {
            paramSuggestedValues.push({
              description: value,
              value: index
            });
          });

          return paramSuggestedValues;
        } catch (err) {
          console.log(err);
        } finally {
          setIsLoadingSuggestedValues(false);
        }
      }
      setIsLoadingSuggestedValues(false);
    }
    return [];
  }

  const handleRemoveFilter = (fieldIndex: number) => {
    setStateFilters((prev) => prev.filter((_, index) => index !== fieldIndex));
  };

  const handleSaveChanges = () => {
    const checkFilters = stateFilters.every(
      (item) =>
        !!item.filterOperation && !!item.filterValue && !!item.paramID && !!item.paramName
    );
    if (!checkFilters) {
      setAlertMessage("All filter fields are mandatory");
      return;
    } else {
      setAlertMessage(null);
    }

    onSave(stateFilters);

    setOpenFilters(false);
  };

  const handleCloseModal = () => {
    setOpenFilters(false);
    setStateFilters(graphqlFilters.length ? graphqlFilters : [EMPTY_FILTER]);
  };

  const handleResetTempFilters = () => {
    setOpenFilters(false);
    if (onResetToCoreFilters) {
      onResetToCoreFilters();
    }
  };

  return (
    <Modal
      open={openFilters}
      onClose={handleCloseModal}
      fullWidth
      label={"Apply Filters"}
      maxWidth="md"
    >
      {stateFilters.map((item, index) => (
        <Stack
          css={styles.width100}
          spacing={2}
          direction="row"
          key={`${item.paramID}-${index}`}
          alignItems="center"
        >
          <Autocomplete
            css={styles.width100}
            value={{ value: item.paramID, description: item.paramName }}
            label="Parameter"
            options={paramOptions}
            handleOnChange={(val: AutocompleteOption) =>
              handleOnChange(index, "param", val)
            }
          />
          <Box sx={{ maxWidth: "200px", width: "100%" }} component="div">
            {/* To DO: selectOptions with this commented filters */}
            <Select
              fullWidth
              label="Operation"
              selectOptions={customOperations ? customOperations : 
                item.type === "number"
                  ? GRAPHQL_FILTER_OPERATIONS_NUMBER
                  : GRAPHQL_FILTER_OPERATIONS_STRING
              }
              value={item.filterOperation}
              disabled={isLoadingSuggestedValues}
              onChange={(e) => handleOnChange(index, "operation", e.target.value)}
            />
          </Box>
          <Box sx={{ maxWidth: "200px", width: "100%" }} component="div">
            <Autocomplete
              css={styles.width100}
              value={{ value: item.filterValue, description: item.filterValue }}
              label="Value"
              options={item.suggestedValue}
              disabled={isLoadingSuggestedValues}
              handleOnChange={(val: AutocompleteOption) =>
                handleOnChange(index, "value", val)
              }
            />
          </Box>
          <Box component="div">
            <IconButton onClick={() => handleRemoveFilter(index)}>
              <CloseIcon />
            </IconButton>
          </Box>
        </Stack>
      ))}
      <Stack
        css={styles.reverseTextBreak}
        spacing={2}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Button
          variant="text"
          onClick={handleAddNewFilter}
          color="secondary"
          endIcon={<AddOutlinedIcon color="secondary" />}
          disabled={isLoadingSuggestedValues}
        >
          Add New Filter
        </Button>
        {onResetToCoreFilters ? (
          <Button variant="text" onClick={handleResetTempFilters} color="error">
            Reset Temporary Filters
          </Button>
        ) : null}
      </Stack>

      <Box
        css={[
          styles.reverseContentBreak,
          styles.flexCenter,
          styles.flexColumn,
          styles.textBreak,
        ]}
        component="div"
      >
        <Button 
          css={[styles.width100, styles.widthLimit15]} 
          onClick={handleSaveChanges}
          disabled={isLoadingSuggestedValues}
        >
          Save Changes
        </Button>

        <Alert
          css={styles.reverseTextBreak}
          message={alertMessage || ""}
          showAlert={!!alertMessage}
          severity={"warning"}
        />
      </Box>
    </Modal>
  );
};

export default GraphqlFiltersModal;

const getParamOptions = (
  selectedContainers: string[],
  data: GraphqlFiltersData[],
  stateFilters: GraphqlFilter[]
): AutocompleteOption[] => {
  const result: AutocompleteOption[] = [];
  data.forEach((item) => {
    const connectionIsSelected = selectedContainers.includes(item.paramContainer!);
    const isAlreadyAdded = stateFilters.some((filt) => filt.paramID === item.paramID);

    if (connectionIsSelected && !isAlreadyAdded) {
      result.push({ description: item.paramName, value: item.paramID });
    }
  });

  return result;
};
