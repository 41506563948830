import { Box, Stack } from "@mui/material";
import { useLanguageContext } from "../../../context/LanguageContext";
import { StockRow } from "../MyWarehouses/stocksUtils";
import LabelWithBoldedPart from "../../MaterialUI/LabelWithBoldedPart";
import { formatNumber } from "../../../Global/Utils/commonFunctions";

interface StockViewInfoProps {
  stock: StockRow | null;
}

const StockViewInfo: React.FC<StockViewInfoProps> = ({ stock }) => {
  const { t } = useLanguageContext();
  if (!stock) {
    return null;
  }

  return (
    <Box component="div">
      <Stack spacing={1} mb={2}>
        <LabelWithBoldedPart text={t("Material Code")} bolded={stock.material_code} />
        <LabelWithBoldedPart text={t("Instance Code")} bolded={stock.instance_code} />
        <LabelWithBoldedPart text={t("Name")} bolded={stock.name} />
        <LabelWithBoldedPart
          text={t("Quantity")}
          bolded={`${formatNumber(stock.quantity)}`}
        />
        <LabelWithBoldedPart text={t("Location")} bolded={stock.location} />
        <LabelWithBoldedPart text={t("Status")} bolded={stock.status} />
      </Stack>
    </Box>
  );
};

export default StockViewInfo;
