import { ExcellenceTableConfiguration } from "../ExcellenceTable/editExcellenceTabletypes";

const excellenceTableDefaultConfiguration: ExcellenceTableConfiguration = {
  dataFormat: "custom",
  tableGridConfig: {
    enableStickyHeader: true,
  },
};

export default excellenceTableDefaultConfiguration;
