import { SerializedStyles } from "@emotion/react";
import { Box, Stack, useTheme } from "@mui/material";
import { ResponsiveAreaBump } from "@nivo/bump";
import { LineAreaHeatMapScatterPlotData } from "../EditExcellenceChartForms/excellenceChartFormUtils";
import { AreaChartConfiguration, AreaChartDataSchema } from "./areaChartTypes";
import { getChartColors } from "../nivoTheme";
import { memo, useMemo, useRef, useState } from "react";
import useContainerDimensions from "../../../Global/Hooks/useContainerDimensions";
import areaChartDefaultData from "../ExcellenceDefaultConfig/areaChartDefaultData";
import { CustomLegend } from "../ExcellenceSmallComponents";

interface AreaChartProps {
  css?: SerializedStyles[] | SerializedStyles;
  className?: string;
  configuration: AreaChartConfiguration;
  data: LineAreaHeatMapScatterPlotData;
  isStatic?: boolean;
  schema: AreaChartDataSchema | null;
  paramMapping?: Record<string, string>;
}

const AreaChart: React.FC<AreaChartProps> = ({
  className,
  configuration,
  data,
  isStatic,
}) => {
  const theme = useTheme();
  const chartContainerRef = useRef<HTMLDivElement>(null);
  const { axisTopLegend, axisBottomLegend, startLabel, endLabel, enableGridX } =
    configuration;
  const { width: chartWidth } = useContainerDimensions(chartContainerRef);

  const dynamicData = getDynamicData(chartWidth, data || areaChartDefaultData);
  const longestLegend = getLongestLegendWordChars(dynamicData);
  const widthRotation = calculateLabelRotation(chartWidth);

  const [visibleKeys, setVisibleKeys] = useState<string[]>(
    dynamicData.map((item) => item.name)
  );

  const handleToggleVisibility = (key: string) => {
    setVisibleKeys((prev) =>
      prev.includes(key) ? prev.filter((item) => item !== key) : [...prev, key]
    );
  };

  const filteredData = useMemo(
    () => dynamicData.filter((item) => visibleKeys.includes(item.name)),
    [dynamicData, visibleKeys]
  );

  const chartMargin = {
    top: isStatic ? 10 : 40,
    right: isStatic ? 10 : 30 + longestLegend * 6,
    bottom: isStatic ? 10 : 60,
    left: isStatic ? 10 : 70,
  };

  return (
    <Box className={className} ref={chartContainerRef} component="div">
      <Stack direction="row" style={{ width: "100%", height: "100%" }} spacing={0}>
        <Box
          component="div"
          style={{
            flexGrow: 1,
          }}
        >
          <ResponsiveAreaBump
            data={filteredData}
            margin={chartMargin}
            spacing={8}
            enableGridX={enableGridX}
            endLabel={isStatic ? undefined : endLabel}
            startLabel={isStatic ? undefined : startLabel}
            isInteractive={isStatic ? false : filteredData.length > 0}
            colors={getChartColors()}
            theme={{
              text: {
                fill: theme.palette.common.black,
              },
              tooltip: {
                container: {
                  background: theme.palette.common.white,
                },
              },
            }}
            defs={[
              {
                id: "dots",
                type: "patternDots",
                background: "inherit",
                color: "#38bcb2",
                size: 4,
                padding: 1,
                stagger: true,
              },
              {
                id: "lines",
                type: "patternLines",
                background: "inherit",
                color: "#eed312",
                rotation: -45,
                lineWidth: 6,
                spacing: 10,
              },
            ]}
            fill={[
              {
                match: {
                  id: "CoffeeScript",
                },
                id: "dots",
              },
              {
                match: {
                  id: "TypeScript",
                },
                id: "lines",
              },
            ]}
            axisTop={
              isStatic
                ? null
                : {
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: axisTopLegend,
                    legendPosition: "middle",
                    legendOffset: -35,
                  }
            }
            axisBottom={
              isStatic
                ? null
                : {
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: widthRotation,
                    legend: axisBottomLegend,
                    legendPosition: "middle",
                    legendOffset: 36,
                  }
            }
          />
        </Box>

        {!isStatic && (
          <Box
            component="div"
            sx={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <CustomLegend
              items={dynamicData.map((item) => ({ label: item.name, color: item.color }))}
              width={longestLegend * 12}
              visibleItems={visibleKeys}
              onToggleVisibility={handleToggleVisibility}
            />
          </Box>
        )}
      </Stack>
    </Box>
  );
};

export default memo(AreaChart);

type LineDatum = {
  x: string;
  y: number;
};
const getDynamicData = (width: number, data: LineAreaHeatMapScatterPlotData) => {
  // 1. Calculate max numb of points based on chart width
  const maxDataPoints = Math.round(width / 27);

  // 2. Down-sample the data to have a length up to maxPoints
  const adjustedData = data.map((item, dataIndex) => {
    const typedDatum = item.data as LineDatum[];

    const downSampledArray = downSampleData(typedDatum, maxDataPoints);

    return {
      ...item,
      name: item.name || item.id,
      color: getChartColors()?.[dataIndex] || "black",
      data: downSampledArray,
    };
  });

  return adjustedData;
};

const downSampleData = (data: LineDatum[], maxDataPoints: number): LineDatum[] => {
  const dataLength = data.length;

  // If the data length is less than or equal to maxDataPoints, no need to downsample
  if (dataLength <= maxDataPoints) {
    return data;
  }

  // Calculate the step size for downsampling
  const stepSize = Math.ceil(dataLength / maxDataPoints);

  // Downsample the data by picking every nth point
  const downSampledData = data.filter((_, index) => index % stepSize === 0);

  return downSampledData;
};

const getLongestLegendWordChars = (data: LineAreaHeatMapScatterPlotData) => {
  const wordsArr = data.map((item) => item.name);

  let longestLength = 0;

  wordsArr.forEach((word) => {
    if (word.length > longestLength) {
      longestLength = word.length;
    }
  });

  return longestLength;
};

const calculateLabelRotation = (chartWidth: number): number => {
  const maxChartWidth = 1400;
  const maxRotation = 90;
  const initialRotation = 2;

  if (chartWidth >= maxChartWidth) {
    return 0; // No rotation if chartWidth is 1400px or more
  } else {
    // Calculate rotation based on chartWidth
    const additionalRotation =
      maxRotation * ((maxChartWidth - chartWidth) / maxChartWidth);
    return Math.min(maxRotation, initialRotation + additionalRotation);
  }
};
