import { Query } from "../callApi";
import {
  PostQueryCodeScanningReadCodeInput,
  PostQueryLabelRecognitionImageInput,
  PostQueryLabelRecognitionTestImageInput,
} from "./apiVisionControlInputs";

//  ------- Label Recognition -------
export const postQueryLabelRecognitionMasterImage = (
  input: PostQueryLabelRecognitionImageInput
): Query => ({
  endpoint: "vision_control/label_recognition/image",
  method: "POST",
  variables: input,
  multipartForm: true,
});

export const postQueryLabelRecognitionTestImage = (
  input: PostQueryLabelRecognitionTestImageInput
): Query => ({
  endpoint: "vision_control/label_recognition/test",
  method: "POST",
  variables: input,
  multipartForm: true,
});

export const deleteQueryLabelImage = (bucket: string, filename: string): Query => ({
  endpoint: `vision_control/label_recognition/image/${bucket}`,
  method: "DELETE",
  variables: { filename },
});

//  ------- Code Scanning -------
export const postQueryCodeScanningReadCode = (
  input: PostQueryCodeScanningReadCodeInput
): Query => ({
  endpoint: "vision_control/label_recognition/read/code",
  method: "POST",
  variables: input,
  multipartForm: true,
  receiveErrorMessage: true,
});
