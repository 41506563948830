import { Grid, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { addMonths, format } from "date-fns";
import {
  StartEndDates,
  ThyssenData,
} from "../../Components/PageComponents/AnomalyDetection/anomalyDetectionUtils";
import AnomalyDetectionTable from "../../Components/PageComponents/AnomalyDetection/AnomalyDetectionTable";
import DatePicker from "../../Components/MaterialUI/DateTimePickers/DatePicker";
import cssLayoutStyles from "../../Global/Styles/layout";
import cssSpacingStyles from "../../Global/Styles/spacing";
import Checkbox from "../../Components/MaterialUI/FormFields/Checkbox";
import { useLanguageContext } from "../../context/LanguageContext";
import PageTitle from "../../Components/SmallComponents/PageTitle/PageTitle";
import { useOutletContext } from "react-router-dom";
import { AppRouterProps } from "../../Layout/layoutVariables";
import callApi from "../../Api/callApi";
import { useAuthedContext } from "../../context/AuthContext";
import { getQueryThyssenData } from "../../Api/AnomalyDetection/apiAnomalyDetectionGetQueries";
import { GetQueryAnomalyDetectionThyssenData } from "../../Api/AnomalyDetection/apiAnomalyDetectionSnippets";

const AnomalyDetectionPage: React.FC = () => {
  const { t } = useLanguageContext();
  const theme = useTheme();
  const styles = { ...cssLayoutStyles, ...cssSpacingStyles(theme) };
  const { setAuthedUser } = useAuthedContext();

  const { smMediaQuery, setExtraTopNavMenu } = useOutletContext<AppRouterProps>();
  const [rows, setRows] = useState<ThyssenData[]>([]);
  const [thyssenData, setThyssenData] = useState<ThyssenData[]>([]);
  const [fromDate, setFromDate] = useState<Date>(addMonths(new Date(), -1));
  const [toDate, setToDate] = useState<Date>(new Date());
  const [showAboveProcessLimit, setShowAboveProcessLimit] = useState<boolean>(false);

  useEffect(() => {
    if (smMediaQuery) {
      setExtraTopNavMenu(null);
    } else {
      setExtraTopNavMenu(<PageTitle title={t("Anomaly Detection")} />);
    }

    return () => {
      setExtraTopNavMenu(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [smMediaQuery]);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fromDate, toDate, showAboveProcessLimit]);

  const fetchData = async () => {
    try {
      const startEndDates: StartEndDates = {
        start_date: format(fromDate, "yyyy-MM-dd'T'HH:mm:ss"),
        end_date: format(toDate, "yyyy-MM-dd'T'HH:mm:ss"),
      };

      const data = await callApi<GetQueryAnomalyDetectionThyssenData>({
        query: getQueryThyssenData(startEndDates),
        auth: { setAuthedUser },
      });

      setThyssenData(data.response);
      setRows(filterRows(data.response, showAboveProcessLimit));
    } catch (error) {
      console.error("AnomalyDetectionPage err: ", error);
    }
  };

  const handleDateChange = (value: Date | null, type: "from" | "to") => {
    if (value) {
      if (type === "from") {
        setFromDate(value);
      } else {
        setToDate(value);
      }
    }
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowAboveProcessLimit(event.target.checked);
  };

  return (
    <>
      {smMediaQuery ? (
        <PageTitle css={styles.labelBreak} title={t("Anomaly Detection")} />
      ) : null}
      <Grid container spacing={2} mb={2} alignItems="flex-end">
        <Grid item xs={6} sm={3} lg={2.5}>
          <DatePicker
            css={[styles.width100]}
            label={t("From")}
            value={fromDate}
            onChange={(date) => handleDateChange(date, "from")}
          />
        </Grid>
        <Grid item xs={6} sm={3} lg={2.5}>
          <DatePicker
            css={[styles.width100]}
            label={t("To")}
            value={toDate}
            onChange={(date) => handleDateChange(date, "to")}
          />
        </Grid>

        <Grid item xs={12} sm={6} lg={2.5}>
          <Checkbox
            checked={showAboveProcessLimit}
            onChange={handleCheckboxChange}
            label={t("Select all outliers")}
            size="small"
          />
        </Grid>
      </Grid>

      <AnomalyDetectionTable rows={rows} loading={!thyssenData.length} />
    </>
  );
};

export default AnomalyDetectionPage;

const filterRows = (data: ThyssenData[], showAboveProcessLimit: boolean) => {
  if (!showAboveProcessLimit) {
    return data;
  }

  return data.filter((row) => {
    const conditions = [
      row.min < row.soc_min,
      row.max > row.soc_max,
      row.soc_min > row.min,
      row.soc_max < row.max,
      row.avg > row.soc_avg,
      row.soc_avg < row.avg,
      row.vark > row.soc_vark,
      row.soc_vark < row.vark,
    ];
    return conditions.some(Boolean);
  });
};
