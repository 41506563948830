import { Query } from "../../../Api/callApi";
import { InvoiceType } from "../../../Components/PageComponents/Finance/Invoice/InvoiceTypes";

export const getQueryInvoices = (
  invoice_type: InvoiceType
): Query => ({
  endpoint: `payments_and_receivables/invoices`,
  method: "GET",
  variables: invoice_type,
});

export const getQueryExportIngoingInvoices = (
): Query => ({
  endpoint: `payments_and_receivables/invoices/incoming-export`,
  method: "GET",
  returnJson: false,
});

export const getQueryExportOutgoingInvoices = (
): Query => ({
  endpoint: `payments_and_receivables/invoices/outgoing-export`,
  method: "GET",
  returnJson: false,
});
