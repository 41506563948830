export type Invoice = {
  _id?: string;
  currency: string;
  customer_name: string;
  customer_vat_number: string;
  date: string;
  discount: number | null | string;
  due_date: string;
  invoice_number: string;
  net_total: number | null | string;
  protocol_id: string;
  purchase_order_id: string;
  quantity: number;
  sales_order_id: string;
  status: string;
  supplier_name: string;
  supplier_vat_number: string;
  total_excise_amount: number | null | string;
  unit_price: number | null | string;
  vat_amount: number | null | string;
  actions?: React.ReactNode;
}

export const modalTitleTranslations = {
  "Create new Invoice": "Create new Invoice",
  "Upload File": "Upload File",
} as const;

export type ModalTitle = keyof typeof modalTitleTranslations;

export type InvoiceType = {
  invoice_type: "ingoing" | "outgoing"
};
