import React, { memo, useRef, useState } from "react";
import { Box, Stack, Typography, useTheme } from "@mui/material";
import { useReactFlow } from "reactflow";
import AdsClickIcon from "@mui/icons-material/AdsClick";
import Modal from "../../../../../../Components/MaterialUI/Modal";
import PidFlowNode from "../PidFlowNode";
import { PID_FLOW_NODE_TYPE, PID_WORKFLOW_TOP_PART_HEIGHT } from "../pidNodesUtils";
import { PidWorkflowsSvgNodeData } from "./pidSvgNodeUtils";
import PidNodeSvgForm from "./PidNodeSvgForm";
import useContainerDimensions from "../../../../../../Global/Hooks/useContainerDimensions";
import PidSvgSymbolComponent from "./PidSvgSymbolComponent";
import { useTranslatedModalTitle } from "../../../../../../Global/Hooks/useTranslations";

const modalTitleTranslations = {
  "Edit Node": "Edit Node",
  "Node Data": "Node Data",
} as const;

type ModalType = keyof typeof modalTitleTranslations;

interface FlowCustomNodeProps {
  id: string;
  data: PidWorkflowsSvgNodeData;
}

const PidWorkflowSvgNode: React.FC<FlowCustomNodeProps> = ({ id, data }) => {
  const theme = useTheme();
  const [modalType, setModalType] = useState<ModalType | null>(null);
  const getTranslatedModalTitle = useTranslatedModalTitle(modalTitleTranslations);
  const [unsavedChanges, setUnsavedChanges] = useState<boolean>(false);
  const nodeContainerRef = useRef<HTMLDivElement>(null);
  const labelRef = useRef<HTMLDivElement>(null);

  const { setNodes, getNodes } = useReactFlow();
  const { width: nodeWidth, height: nodeHeight } =
    useContainerDimensions(nodeContainerRef);
  const { height: labelHeight } = useContainerDimensions(labelRef);

  const textColor =
    theme.palette.mode === "dark" && data.mode === "Edit Mode"
      ? theme.palette.common.white
      : theme.palette.text.primary;

  const handleEditSubmit = (nodeData: PidWorkflowsSvgNodeData) => {
    setNodes((nds) =>
      nds.map((node) => {
        if (node.id === id) {
          return {
            ...node,
            data: nodeData,
          };
        }
        return node;
      })
    );

    setModalType(null);
  };

  const handleOpenEditModal = () => {
    setModalType("Edit Node");
  };

  const handleSetUnsavedChanges = (unsavedChanges: boolean) => {
    if (unsavedChanges) {
      setUnsavedChanges(true);
    }
  };

  return (
    <>
      <PidFlowNode
        id={id}
        handleOpenEditModal={handleOpenEditModal}
        icon={<AdsClickIcon fontSize="small" />}
        nodeType={PID_FLOW_NODE_TYPE.SVG}
      >
        {data.mode === "View Mode" ? (
          <Box sx={{ height: PID_WORKFLOW_TOP_PART_HEIGHT }} component="div" />
        ) : null}
        <Box
          component="div"
          ref={nodeContainerRef}
          sx={{
            display: "flex",
            width: "100%",
            height: `calc(100% - 40px - ${labelHeight + 30}px)`,
            justifyContent: "center",
          }}
        >
          {data.pidSymbol && nodeHeight && nodeWidth ? (
            <Stack spacing={3}>
              <PidSvgSymbolComponent
                width={nodeWidth}
                height={nodeHeight}
                color={textColor}
                symbolText={data.pidSymbol.svg}
              />

              <Box ref={labelRef} sx={{ width: "100%" }} component="div">
                <Typography
                  sx={{ marginTop: "-20px !important" }}
                  variant="h4"
                  color={textColor}
                  align="center"
                >
                  {data.customLabel || data.pidSymbol.label}
                </Typography>
              </Box>
            </Stack>
          ) : null}
        </Box>
      </PidFlowNode>

      <Modal
        onClose={() => setModalType(null)}
        open={!!modalType}
        fullWidth
        label={modalType ? getTranslatedModalTitle(modalType) : ""}
        unsavedChanges={unsavedChanges}
        setUnsavedChanges={setUnsavedChanges}
      >
        {modalType === "Edit Node" ? (
          <PidNodeSvgForm
            handleEditSubmit={handleEditSubmit}
            data={data}
            nodes={getNodes()}
            handleSetUnsavedChanges={handleSetUnsavedChanges}
            setUnsavedChanges={setUnsavedChanges}
            unsavedChanges={unsavedChanges}
          />
        ) : null}
      </Modal>
    </>
  );
};

export default memo(PidWorkflowSvgNode);
