import ResponsiveTableGrid from "../../Components/SmallComponents/TableGrid/ResponsiveTableGrid";
import { useTranslateFields } from "../../Global/Hooks/useTranslations";
import { GetQueryMaterialsAndProductsSnippet } from "../../Api/SupplyChain/apiSupplyChainSnippets";

interface ProductsTableProps {
  tableData: GetQueryMaterialsAndProductsSnippet | undefined;
  loading: boolean;
}

const ProductsTable: React.FC<ProductsTableProps> = ({ tableData, loading }) => {
  return tableData ? (
    <ResponsiveTableGrid
      rows={tableData.rows}
      colSchema={useTranslateFields(tableData.columns, ["label"])}
      loading={loading}
      responsive="responsive"
      configuration={{
        density: "compact",
        initialRowsPerPage: 15,
      }}
      tableID="ProductsTable_table"
    />
  ) : null;
};

export default ProductsTable;
