import { Grid, IconButton, Stack, Theme, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { css } from "@emotion/react";
import {
  getAssetsToCategoriesOptions,
  ModalTitle,
  oEEModalTitleTranslations,
} from "../../Components/PageComponents/OEE/oEEUtils";
import Modal from "../../Components/MaterialUI/Modal";
import cssSpacingStyles from "../../Global/Styles/spacing";
import ContentBox from "../../Components/MaterialUI/ContentBox";
import OEEBulletChart from "../../Components/PageComponents/OEE/OEEDashboarCharts/OEEBulletChart";
import cssLayoutStyles from "../../Global/Styles/layout";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { AppRouterProps } from "../../Layout/layoutVariables";
import { useOutletContext } from "react-router-dom";
import callApi from "../../Api/callApi";
import { GetQueryOEEAddedAssetsToCategoriesSnippet } from "../../Api/OEE/apiOEESnippets";
import { useAuthedContext } from "../../context/AuthContext";
import { getQueryOEEAddedCategoriesWithSubcategories } from "../../Api/OEE/apiOEEGetQueries";
import { SelectOption } from "../../Global/Types/commonTypes";
import OEEAddDashboardForm from "../../Components/PageComponents/OEE/OEEDashboarCharts/OEEAddDashboardForm";
import OEEBarChartLossesByType from "../../Components/PageComponents/OEE/OEEDashboarCharts/OEEBarChartLossesByType";
import OEEBarChartLossesByMainCategory from "../../Components/PageComponents/OEE/OEEDashboarCharts/OEEBarChartLossesByMainCategory";
import OEEBarChartLossesWeeklyView from "../../Components/PageComponents/OEE/OEEDashboarCharts/OEEBarChartLossesWeeklyView";
import OEEBarChartLossesBySubcategory from "../../Components/PageComponents/OEE/OEEDashboarCharts/OEEBarChartLossesBySubcategory";
import OEEBarChartAssets from "../../Components/PageComponents/OEE/OEEDashboarCharts/OEEBarChartAssets";
import OEERadialChart from "../../Components/PageComponents/OEE/OEEDashboarCharts/OEERadialChart";
import OEEBarChartForSingleType from "../../Components/PageComponents/OEE/OEEDashboarCharts/OEEBarChartForSingleType";
import OEELineChartForSingleAsset from "../../Components/PageComponents/OEE/OEEDashboarCharts/OEELineChartForSingleAsset";
import OEEBarChartPareto from "../../Components/PageComponents/OEE/OEEDashboarCharts/OEEBarChartPareto";
import OEEBarChartAssetByHour from "../../Components/PageComponents/OEE/OEEDashboarCharts/OEEBarChartAssetByHour";
import { useLanguageContext } from "../../context/LanguageContext";
import { useTranslatedModalTitle } from "../../Global/Hooks/useTranslations";
import CustomIconsTooltip from "../../Components/SmallComponents/Tooltip/CustomIconsTooltip";
import PageTitle from "../../Components/SmallComponents/PageTitle/PageTitle";

const cssStyles = (theme: Theme) => ({
  boxStyle: css({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    height: "300px",
    gap: 10,
    color: theme.palette.grey[300],
  }),
  chartBoxStyle: css({
    display: "flex",
    flexDirection: "column",
  }),
});

const OEEDashboardPage: React.FC = () => {
  const { t } = useLanguageContext();
  const theme = useTheme();
  const styles = {
    ...cssLayoutStyles,
    ...cssSpacingStyles(theme),
    ...cssStyles(theme),
  };
  const { setAuthedUser } = useAuthedContext();
  const [addedAssetsToCategories, setAddedAssetsToCategories] = useState<SelectOption[]>(
    []
  );
  const getTranslatedModalTitle = useTranslatedModalTitle(oEEModalTitleTranslations);
  const [modalTitle, setModalTitle] = useState<ModalTitle | null>(null);
  const { smMediaQuery, setExtraRightNavMenu, setExtraTopNavMenu } =
    useOutletContext<AppRouterProps>();

  const fetchOEEData = async () => {
    try {
      const assetsAddedToCategories =
        await callApi<GetQueryOEEAddedAssetsToCategoriesSnippet>({
          query: getQueryOEEAddedCategoriesWithSubcategories,
          auth: { setAuthedUser },
        });

      setAddedAssetsToCategories(getAssetsToCategoriesOptions(assetsAddedToCategories));
    } catch (error) {
      console.log("There was an error fetching assets ", error);
    }
  };

  useEffect(() => {
    fetchOEEData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (smMediaQuery) {
      setExtraTopNavMenu(null);
      setExtraRightNavMenu(null);
    } else {
      setExtraTopNavMenu(<PageTitle title={`OEE ${t("Dashboard")}`} />);
      setExtraRightNavMenu(<CreateNewOEEDashboard setModalTitle={setModalTitle} />);
    }

    return () => {
      setExtraTopNavMenu(null);
      setExtraRightNavMenu(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [smMediaQuery]);

  return (
    <>
      {smMediaQuery ? (
        <PageTitle css={styles.labelBreak} title={`OEE ${t("Dashboard")}`} />
      ) : null}

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <ContentBox css={styles.chartBoxStyle}>
            <OEEBulletChart
              assetIds={[
                "667020b8aafe32a08a125f2a",
                "667020b8aafe32a08a125f28",
                "667020b8aafe32a08a125f2e",
              ]}
              title={"Current Cycle Rate"}
            />
          </ContentBox>
        </Grid>

        <Grid item xs={4}>
          <ContentBox css={styles.chartBoxStyle}>
            <OEERadialChart />
          </ContentBox>
        </Grid>

        <Grid item xs={8}>
          <ContentBox css={styles.chartBoxStyle}>
            <OEEBarChartForSingleType />
          </ContentBox>
        </Grid>

        <Grid item xs={12}>
          <ContentBox css={styles.chartBoxStyle}>
            <OEEBarChartAssetByHour />
          </ContentBox>
        </Grid>

        <Grid item xs={12}>
          <ContentBox css={styles.chartBoxStyle}>
            <OEEBarChartPareto />
          </ContentBox>
        </Grid>

        <Grid item xs={12}>
          <ContentBox css={styles.chartBoxStyle}>
            <OEELineChartForSingleAsset />
          </ContentBox>
        </Grid>

        <Grid item xs={12}>
          <ContentBox css={styles.chartBoxStyle}>
            <OEEBarChartAssets />
          </ContentBox>
        </Grid>

        <Grid item xs={12}>
          <ContentBox css={styles.chartBoxStyle}>
            <OEEBarChartLossesByType />
          </ContentBox>
        </Grid>

        <Grid item xs={12}>
          <ContentBox css={styles.chartBoxStyle}>
            <OEEBarChartLossesByMainCategory />
          </ContentBox>
        </Grid>

        <Grid item xs={12}>
          <ContentBox css={styles.chartBoxStyle}>
            <OEEBarChartLossesBySubcategory />
          </ContentBox>
        </Grid>

        <Grid item xs={12}>
          <ContentBox css={styles.chartBoxStyle}>
            <OEEBarChartLossesWeeklyView />
          </ContentBox>
        </Grid>
      </Grid>

      <Modal
        open={!!modalTitle}
        onClose={() => setModalTitle(null)}
        fullWidth
        label={modalTitle ? getTranslatedModalTitle(modalTitle) : ""}
      >
        {modalTitle === "Add Dashboard" ? (
          <OEEAddDashboardForm addedAssetsToCategories={addedAssetsToCategories} />
        ) : null}
      </Modal>
    </>
  );
};

export default OEEDashboardPage;

interface CreateNewOEEDashboardProps {
  setModalTitle: React.Dispatch<React.SetStateAction<ModalTitle | null>>;
}

const CreateNewOEEDashboard: React.FC<CreateNewOEEDashboardProps> = ({
  setModalTitle,
}) => {
  const { t } = useLanguageContext();

  return (
    <Stack alignItems="center">
      <CustomIconsTooltip tooltipText={t("Add")}>
        <IconButton
          aria-label="create new dashboard"
          onClick={() => setModalTitle("Add Dashboard")}
        >
          <AddOutlinedIcon />
        </IconButton>
      </CustomIconsTooltip>
    </Stack>
  );
};
