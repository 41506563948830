import { useState } from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { KanbanBoardId } from "./kanbanBoardUtils";
import {
  Box,
  IconButton,
  Stack,
  Theme,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import {
  KAN_TASK_STATUS_MAP,
  KanbanTask,
  KanbanTaskStatus,
} from "../../../pages/TaskManager/Components/taskManagerUtils";
import { FormStatuses } from "../../../Global/Types/commonTypes";
import EventNoteIcon from "@mui/icons-material/EventNote";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import LoadingBackdrop from "../../MaterialUI/LoadingBackdrop";
import EditIcon from "@mui/icons-material/Edit";
import Modal from "../../MaterialUI/Modal";
import EditKanTaskFields from "../../../pages/TaskManager/Components/KanbanTask/EditKanTaskFields";
import { useLanguageContext } from "../../../context/LanguageContext";

interface Props {
  task: KanbanTask;
  deleteTask?: (id: KanbanBoardId) => void;
  handleOnClick: (task: KanbanTask) => void;
  refetchTasks: () => Promise<void>;
}

function KanbanBoardTaskCard({ task, deleteTask, handleOnClick, refetchTasks }: Props) {
  const { t } = useLanguageContext();
  const theme = useTheme();
  const [mouseIsOver, setMouseIsOver] = useState(false);
  const [formStatus, setFormStatus] = useState<FormStatuses>(null);
  const [openEditModal, setOpenEditModal] = useState<boolean>(false);
  const [unsavedChanges, setUnsavedChanges] = useState<boolean>(false);

  const { setNodeRef, attributes, listeners, transform, transition, isDragging } =
    useSortable({
      id: task.id,
      data: {
        type: "Task",
        task,
      },
    });

  const style = {
    transition,
    transform: CSS.Transform.toString(transform),
  };

  const handleClick = () => {
    handleOnClick(task);
    setMouseIsOver(false);
  };

  const handleOpenModal = () => {
    setFormStatus(null);
    setOpenEditModal(true);
  };

  const handleSetUnsavedChanges = (unsavedChanges: boolean) => {
    if (unsavedChanges) {
      setUnsavedChanges(true);
    }
  };

  if (isDragging) {
    return (
      <Box
        component="div"
        ref={setNodeRef}
        style={{
          ...style,
          opacity: 0.3,
          border: `1px solid ${getCardIndicator(task.status, theme).color}`,
          background: theme.palette.common.white,
          padding: "0.625rem",
          height: "100px",
          minHeight: "100px",
          alignItems: "center",
          display: "flex",
          textAlign: "left",
          borderRadius: "1rem",
          cursor: "grab",
          position: "relative",
        }}
      />
    );
  }

  // if (editMode) {
  //   return (
  //     <Box component="div"
  //       ref={setNodeRef}
  //       {...attributes}
  //       {...listeners}
  //       style={{
  //         ...style,
  //         padding: "0.625rem",
  //         border: `1px solid ${theme.palette.primary.main}`,
  //         background: theme.palette.common.white,
  //         height: "100px",
  //         minHeight: "100px",
  //         alignItems: "center",
  //         display: "flex",
  //         textAlign: "left",
  //         borderRadius: "1rem",
  //         transition: "box-shadow 0.3s",
  //         cursor: "grab",
  //         position: "relative",
  //       }}
  //     >
  //       <TextField
  //         style={{
  //           height: "90%",
  //           width: "100%",
  //           border: "none",
  //           borderRadius: "0.375rem",
  //           backgroundColor: "transparent",
  //           color: "white",
  //           outline: "none",
  //           overflowY: "auto",
  //         }}
  //         multiline
  //         value={task.content}
  //         autoFocus
  //         placeholder="Task content here"
  //         onBlur={toggleEditMode}
  //         onKeyDown={(e) => {
  //           if (e.key === "Enter" && e.shiftKey) {
  //             toggleEditMode();
  //           }
  //         }}
  //         onChange={(e) => updateTask(task.id, e.target.value)}
  //       />
  //     </Box>
  //   );
  // }

  return (
    <Box
      component="div"
      ref={setNodeRef}
      {...attributes}
      {...listeners}
      style={{
        ...style,
        border: `1px solid ${getCardIndicator(task.status, theme).color}`,
        background: theme.palette.common.white,
        padding: "0.625rem",
        height: "100px",
        minHeight: "100px",
        alignItems: "center",
        display: "flex",
        textAlign: "left",
        borderRadius: "1rem",
        transition: "box-shadow 0.3s",
        cursor: "grab",
        position: "relative",
      }}
      onMouseEnter={() => {
        setMouseIsOver(true);
      }}
      onMouseLeave={() => {
        setMouseIsOver(false);
      }}
    >
      <Stack
        style={{
          margin: "auto",
          width: "100%",
          maxHeight: "95%",
          overflowY: "auto",
          overflowX: "hidden",
        }}
        spacing={0.5}
      >
        <Stack direction="row" spacing={2} justifyContent="space-between">
          <Typography onClick={handleClick} style={{ flex: 1 }} whiteSpace="pre-wrap">
            {task.order_name}
          </Typography>
          <Tooltip arrow title={KAN_TASK_STATUS_MAP[task.status]}>
            <IconButton aria-label="add new widget" onClick={handleOpenModal}>
              <EditIcon />
            </IconButton>
          </Tooltip>
        </Stack>
      </Stack>

      {mouseIsOver && deleteTask ? (
        <IconButton
          aria-label="delete task"
          onClick={() => {
            deleteTask(task.id);
          }}
        >
          <DeleteOutlineOutlinedIcon />
        </IconButton>
      ) : null}

      <Modal
        open={openEditModal}
        onClose={() => setOpenEditModal(false)}
        fullWidth
        label={t("Edit Task")}
        unsavedChanges={unsavedChanges}
        setUnsavedChanges={setUnsavedChanges}
      >
        <EditKanTaskFields
          task={task}
          onSubmit={refetchTasks}
          handleSetUnsavedChanges={handleSetUnsavedChanges}
          setUnsavedChanges={setUnsavedChanges}
        />
      </Modal>
      <LoadingBackdrop loading={formStatus === "loading"} />
    </Box>
  );
}

export default KanbanBoardTaskCard;

type CardIndicator = {
  color: string;
  icon: React.ReactNode;
};

const getCardIndicator = (status: KanbanTaskStatus, theme: Theme): CardIndicator => {
  switch (status) {
    case "planned": {
      return {
        color: theme.palette.secondary.main,
        icon: <EventNoteIcon />,
      };
    }
    case "active": {
      return {
        color: theme.palette.primary.main,
        icon: <AutorenewIcon />,
      };
    }
    case "finished": {
      return {
        color: theme.palette.success.main,
        icon: <CheckCircleOutlineIcon />,
      };
    }
  }
};
