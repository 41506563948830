import { useLazyQuery } from "@apollo/client";
import { Grid, useTheme, Typography } from "@mui/material";
import { useState, useEffect } from "react";
import cssLayoutStyles from "../../../Global/Styles/layout";
import cssSpacingStyles from "../../../Global/Styles/spacing";
import { FormStatuses } from "../../../Global/Types/commonTypes";
import { graphQlQueryExcellenceLineChartData } from "../../../GraphQL/Excellence/graphQLQueriesExcellence";
import LoadingBackdrop from "../../MaterialUI/LoadingBackdrop";
import { DynamicGridChartOptions } from "../../SmallComponents/DynamicGridLayout.tsx/dynamicGridUtils";
import ExcellenceChartFormWrapper from "../EditExcellenceChartForms/ExcellenceChartFormWrapper";
import {
  ExcellenceChartFormProps,
  ChartConfigFormValuesWithTitle,
  LineAreaHeatMapScatterPlotData,
  ExcellenceParamMapping,
} from "../EditExcellenceChartForms/excellenceChartFormUtils";
import Alert from "../../MaterialUI/Alert";
import EdiLineChartDataSchema from "./EdiLineChartDataSchema";
import { useLanguageContext } from "../../../context/LanguageContext";
import EditLineChartConfig from "./EditLineChartConfig";
import ExcellenceLineChart from "../../PageComponents/Excellence/ExcellenceCharts/ExcellenceLineChart/ExcellenceLineChart";
import { LineChartConfig, LineChartDataSchema } from "./lineChartTypes";
import useExcellenceChartUnsavedChanges from "../../../Global/Hooks/useExcellenceChartUnsavedChanges";
import {
  buildGraphqlFilters,
  GraphqlFilter,
} from "../../SmallComponents/GraphqlFilters/graphqlFiltersUtils";

interface EditLineChartFormProps extends Omit<ExcellenceChartFormProps, "dataSchema"> {
  config: LineChartConfig;
  data: LineAreaHeatMapScatterPlotData;
  dataSchema: LineChartDataSchema | null;
}

const EditLineChartForm: React.FC<EditLineChartFormProps> = ({
  chartClass,
  config,
  dataSchema,
  data,
  widgetTitle,
  handleSaveChanges,
  parameters,
  handleSetUnsavedChanges,
}) => {
  const theme = useTheme();
  const styles = {
    ...cssLayoutStyles,
    ...cssSpacingStyles(theme),
  };
  const { t } = useLanguageContext();
  const [tabValue, setTabValue] = useState(0);

  const { updatedConfig, setUpdatedConfig, updatedSchema, setUpdatedSchema } =
    useExcellenceChartUnsavedChanges<
      ChartConfigFormValuesWithTitle<LineChartConfig>,
      LineChartDataSchema | null
    >({
      initialConfig: {
        title: widgetTitle,
        ...config,
      },
      initialSchema: dataSchema as LineChartDataSchema,
      handleSetUnsavedChanges,
    });

  // tab number 0
  const [fieldAlert, setFieldAlert] = useState<string | null>(null);

  // tab number 1
  const [fetchStatus, setFetchStatus] = useState<FormStatuses>(null);
  const [resetChartFetch, setResetChartFetch] = useState<boolean>(false);
  const [updatedData, setUpdatedData] = useState<LineAreaHeatMapScatterPlotData>(data);
  const [graphqlFilters, setGraphqlFilters] = useState<GraphqlFilter[]>(
    config?.graphqlFilters || []
  );
  const [initialSetupComplete, setInitialSetupComplete] = useState<boolean>(false);
  const [paramMapping, setParamMapping] = useState<ExcellenceParamMapping | null>(null);

  const [getLineData] = useLazyQuery(graphQlQueryExcellenceLineChartData);

  useEffect(() => {
    const mapping: ExcellenceParamMapping = {};
    parameters?.parameters.forEach((param) => {
      if (param.id && !mapping?.[param.id]) {
        mapping[param.id] = {
          connection: param.container,
          paramID: param.id,
          paramName: param.name,
          connectionName: param.containerName,
          type: param.type,
        };
      }
    });
    setParamMapping(mapping);
  }, [parameters]);

  useEffect(() => {
    if ( graphqlFilters && graphqlFilters.length ) {
      setUpdatedConfig((prev) => ({
        ...prev,
        graphqlFilters
      }));
    }
    (async () => {
      if (updatedSchema) {
        try {
          setFetchStatus("loading");
          const result = await getLineData({
            variables: {
              input: { ...updatedSchema, filters: buildGraphqlFilters(graphqlFilters) },
            },
          });
          if (!result.data || result.error) {
            throw new Error(JSON.stringify(result));
          }
          const resultData = result.data.lineChartData.chartData;
          setUpdatedData(resultData);
          setResetChartFetch((prev) => !prev);
          setFetchStatus(null);
        } catch (err) {
          console.log("err ", err);
          setFetchStatus("error");
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatedSchema, graphqlFilters]);

  const handleSubmit = () => {
    const { title, ...otherConfig } = updatedConfig;
    const updatedChart: DynamicGridChartOptions = {
      lineChart: {
        config: { ...otherConfig, graphqlFilters },
        data: updatedData,
        dataSchema: updatedSchema,
      },
    };

    // do some field checks
    if (!title) {
      setFieldAlert(t("Title is required"));
    } else {
      // all fields checks have passed
      handleSaveChanges(updatedChart, title);
    }
  };

  return (
    <ExcellenceChartFormWrapper
      alertMessage={fieldAlert}
      handleSubmit={handleSubmit}
      tabValue={tabValue}
      setTabValue={setTabValue}
      disabledDataTab={false}
    >
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          {tabValue === 0 ? (
            <EditLineChartConfig
              config={updatedConfig}
              setUpdatedConfig={setUpdatedConfig}
            />
          ) : null}

          {tabValue === 1 ? (
            <EdiLineChartDataSchema
              parameters={parameters?.parameters || []}
              dataSchema={updatedSchema}
              handleUpdateDataSchema={(val) => setUpdatedSchema(val)}
              graphqlFilters={graphqlFilters}
              setGraphqlFilters={setGraphqlFilters}
              initialSetupComplete={initialSetupComplete}
              setInitialSetupComplete={setInitialSetupComplete}
              paramMapping={paramMapping}
            />
          ) : null}

          <Alert
            css={styles.reverseContentBreak}
            message={t("Something went wrong")}
            showAlert={fetchStatus === "error"}
            severity={"error"}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <Typography css={styles.labelBreak} variant="h3">
            {updatedConfig.title}
          </Typography>
          <ExcellenceLineChart
            css={chartClass}
            configuration={updatedConfig}
            data={updatedData}
            schema={updatedSchema}
            resetChartFetch={resetChartFetch}
            dataFetchFromParent
            parameters={parameters?.parameters}
            tempFilters={[]}
          />
        </Grid>
      </Grid>

      <LoadingBackdrop loading={fetchStatus === "loading"} />
    </ExcellenceChartFormWrapper>
  );
};

export default EditLineChartForm;
