import React, { RefObject, useEffect, useRef, useState } from "react";
import html2canvas from "html2canvas";
import { Box, IconButton, Menu, Stack, Typography, useTheme } from "@mui/material";
import Logo from "../../../Layout/AppNavigation/Logo";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import PrintOutlinedIcon from "@mui/icons-material/PrintOutlined";
import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";
import PictureAsPdfOutlinedIcon from "@mui/icons-material/PictureAsPdfOutlined";
import { useLanguageContext } from "../../../context/LanguageContext";
import cssSpacingStyles from "../../../Global/Styles/spacing";
import jsPDF from "jspdf";
import CustomIconsTooltip from "../Tooltip/CustomIconsTooltip";

type ExportType = "jpeg" | "png" | "pdf";

interface ExportAsImageProps {
  elementRef: RefObject<HTMLDivElement>;
  fileName?: string;
  isPrint?: boolean;
  iconButton?: React.ReactElement;
}

const ExportAsImage: React.FC<ExportAsImageProps> = ({
  elementRef,
  fileName = "exported-image",
  isPrint,
  iconButton
}) => {
  const { t } = useLanguageContext();
  const theme = useTheme();
  const styles = { ...cssSpacingStyles(theme) };
  const refToUseAsExport = useRef<HTMLDivElement>(null);
  const [exportMenuAnchor, setExportMenuAnchor] = useState<null | HTMLElement>(null);
  const openExportMenu = Boolean(exportMenuAnchor);

  const handleExport = async (exportAs: ExportType) => {
    if (!elementRef.current || !refToUseAsExport.current) {
      console.error("Element reference is not provided.");
      return;
    }

    // Create a hidden div in memory
    const wrapperDiv = document.createElement("div");

    // Apply custom styles to the wrapper div
    wrapperDiv.style.background = theme.palette.background.default;
    wrapperDiv.style.position = "absolute";
    wrapperDiv.style.left = "-9999px"; // Offscreen, so it's never visible

    const originalDisplay = refToUseAsExport.current.style.display;
    refToUseAsExport.current.style.display = "block"; // Make it visible for the export

    wrapperDiv.style.width = `${refToUseAsExport.current.offsetWidth}px`;
    wrapperDiv.style.height = `${refToUseAsExport.current.offsetHeight}px`;

    // Append the original element to the off-screen wrapper
    wrapperDiv.appendChild(refToUseAsExport.current.cloneNode(true));

    // Append the wrapper div to the body (offscreen)
    document.body.appendChild(wrapperDiv);

    try {
      // Export the wrapper div as an image
      const canvas = await html2canvas(wrapperDiv, {
        scale: 2,
      });

      if (exportAs === "pdf") {
        handleExportAsPdf(canvas);
      } else {
        // Export as image
        const imageURL = canvas.toDataURL(`image/${exportAs}`, 1.0);
        const link = document.createElement("a");
        link.href = imageURL;
        link.download = `${fileName}.${exportAs}`;
        link.click();
      }
    } catch (error) {
      console.error("Failed to export image:", error);
    } finally {
      // Clean up: remove the wrapper div from the DOM
      refToUseAsExport.current.style.display = originalDisplay;
      document.body.removeChild(wrapperDiv);
    }
  };

  const handleExportAsPdf = (canvas: HTMLCanvasElement) => {
    const imageData = canvas.toDataURL("image/png");
    const pdf = new jsPDF("portrait", "pt", "a4");

    const imgWidth = 595.28; // A4 width in points
    const pageHeight = 841.89; // A4 height in points
    const imgHeight = (canvas.height * imgWidth) / canvas.width;
    let heightLeft = imgHeight;
    let position = 0;

    // Add the first page
    pdf.addImage(imageData, "PNG", 0, position, imgWidth, imgHeight);
    heightLeft -= pageHeight;

    // Add new pages while there’s content left
    while (heightLeft > 0) {
      position -= pageHeight;
      pdf.addPage();
      pdf.addImage(imageData, "PNG", 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;
    }

    if (isPrint) {
      pdf.autoPrint();
      window.open(pdf.output("bloburl"), "_blank");
    } else {
      pdf.save(`${fileName}.pdf`);
    }
  };

  const handleOpenExportMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setExportMenuAnchor(event.currentTarget);
  };

  const handleCloseExportMenu = () => {
    setExportMenuAnchor(null);
  };

  return (
    <Box style={{ zIndex: 5, position: "relative", overflow: "hidden" }} component="div">
      <Stack style={{ zIndex: 5 }} alignItems="center">
        {
          !iconButton ? (
            <CustomIconsTooltip tooltipText={isPrint ? t("Print") : t("Export")}>
              <IconButton
                aria-label="open export menu"
                onClick={isPrint ? () => handleExport("pdf") : handleOpenExportMenu}
              >
                {isPrint ? <PrintOutlinedIcon /> : <FileDownloadOutlinedIcon />}
              </IconButton>
            </CustomIconsTooltip>
          ) : (
            React.cloneElement(iconButton, {
              onClick: isPrint ? () => handleExport("pdf") : handleOpenExportMenu,

            })
          )}
      </Stack>

      {/* the exported component */}
      <Box
        component="div"
        ref={refToUseAsExport}
        style={{
          position: "absolute",
          // display: "none",
          zIndex: -1,
        }}
      >
        <EleWithLogo eleFromRef={elementRef.current} />
      </Box>

      <Menu
        anchorEl={exportMenuAnchor}
        open={openExportMenu}
        onClose={handleCloseExportMenu}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <Box component="div" css={[styles.leftRightPadding1]}>
          <Stack direction="row" spacing={1}>
            <Stack alignItems="center">
              <IconButton aria-label="jpg file" onClick={() => handleExport("jpeg")}>
                <ImageOutlinedIcon />
              </IconButton>
              <Typography variant="caption" align="center">
                JPG
              </Typography>
            </Stack>
            <Stack alignItems="center">
              <IconButton aria-label="png file" onClick={() => handleExport("png")}>
                <ImageOutlinedIcon />
              </IconButton>
              <Typography variant="caption" align="center">
                PNG
              </Typography>
            </Stack>
            <Stack alignItems="center">
              <IconButton aria-label="pdf file" onClick={() => handleExport("pdf")}>
                <PictureAsPdfOutlinedIcon />
              </IconButton>
              <Typography variant="caption" align="center">
                PDF
              </Typography>
            </Stack>
          </Stack>
        </Box>
      </Menu>
    </Box>
  );
};

export default ExportAsImage;

interface EleWithLogoProps {
  eleFromRef: HTMLDivElement | null;
}

const EleWithLogo: React.FC<EleWithLogoProps> = ({ eleFromRef }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const observerRef = useRef<MutationObserver | null>(null);

  useEffect(() => {
    if (containerRef.current && eleFromRef) {
      // Clear previous children (if any)
      containerRef.current.innerHTML = "";
      // Append the cloned DOM element to avoid side-effects
      const clonedNode = eleFromRef.cloneNode(true);
      containerRef.current.appendChild(clonedNode);

      // Set up a MutationObserver to detect changes in the original element and update the clone
      observerRef.current = new MutationObserver(() => {
        // Clear the container and append the updated clone
        containerRef.current!.innerHTML = "";
        const updatedClone = eleFromRef.cloneNode(true);
        containerRef.current!.appendChild(updatedClone);
      });

      // Observe mutations in the original element
      observerRef.current.observe(eleFromRef, {
        attributes: true,
        childList: true,
        subtree: true,
      });
    }

    // Cleanup observer on unmount
    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect();
      }
    };
  }, [eleFromRef]);

  return (
    <Box component="div" sx={{ width: "100%", height: "100%" }}>
      <Box component="div" mb={1} mt={1} ml={1}>
        <Logo />
      </Box>

      <div
        ref={containerRef}
        style={{
          width: eleFromRef?.offsetWidth,
          height: eleFromRef?.offsetHeight,
        }}
      ></div>

      <Box
        component="div"
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "1rem",
          paddingTop: "2rem",
        }}
      >
        <Typography variant="h6" component="h6">
          © evniko GIANT. All Rights Reserved.
        </Typography>
        <Logo />
      </Box>
    </Box>
  );
};
