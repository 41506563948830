import React from "react";
import { Grid, FormControlLabel } from "@mui/material";
import Switch from "../../MaterialUI/FormFields/Switch";
import { useLanguageContext } from "../../../context/LanguageContext";
import callApi from "../../../Api/callApi";
import {
  deleteQueryExcellenceDashboardPublic,
  postQueryExcellenceDashboardPublic,
} from "../../../Api/Excellence/apiExcellencePostQueries";
import { ExcellenceGridLayoutSettings } from "./excellenceUtils";
import { useAuthedContext } from "../../../context/AuthContext";
import { FormStatuses } from "../../../Global/Types/commonTypes";

interface ShareDashboardTabProps {
  id: string;
  isPublic: boolean;
  setIsPublic: React.Dispatch<React.SetStateAction<boolean>>;
  setSettings: React.Dispatch<React.SetStateAction<ExcellenceGridLayoutSettings | null>>;
  setFormStatus: React.Dispatch<React.SetStateAction<FormStatuses>>;
  setAlertMessage: React.Dispatch<React.SetStateAction<string | null>>;
}

const ShareDashboardPublic: React.FC<ShareDashboardTabProps> = ({
  id,
  isPublic,
  setIsPublic,
  setSettings,
  setFormStatus,
  setAlertMessage,
}) => {
  const { t } = useLanguageContext();
  const { setAuthedUser } = useAuthedContext();

  const handlePublicToggle = async () => {
    try {
      if (isPublic) {
        await callApi({
          query: deleteQueryExcellenceDashboardPublic(id),
          auth: { setAuthedUser },
        });
      } else {
        await callApi({
          query: postQueryExcellenceDashboardPublic(id),
          auth: { setAuthedUser },
        });
      }

      setIsPublic((state) => !state);
      setSettings((state) => {
        if (!state) return state;

        return {
          ...state,
          public: isPublic,
        };
      });
      setFormStatus("success");
      setAlertMessage(t("Dashboard visibility successfully updated"));
    } catch (err) {
      setFormStatus("error");
      setAlertMessage(t("Something went wrong"));
    }
  };

  return (
    <Grid container>
      <Grid item xs={6}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Switch
                  checked={isPublic}
                  onChange={handlePublicToggle}
                  color="primary"
                />
              }
              label={t("Share dashboard with all users (read-only)")}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ShareDashboardPublic;
