import { Box, Divider, Grid, InputAdornment, Stack, useTheme } from "@mui/material";
import DateTimeConfiguration from "../../../SmallComponents/DateTimeConfiguration/DateTimeConfiguration";
import cssSpacingStyles from "../../../../Global/Styles/spacing";
import { ExcellenceDateTimeConfigurationData } from "../../../SmallComponents/DateTimeConfiguration/dateTimeConfigurationUtils";
import { useEffect, useState } from "react";
import { AutocompleteOption, FormStatuses } from "../../../../Global/Types/commonTypes";
import Alert from "../../../MaterialUI/Alert";
import cssLayoutStyles from "../../../../Global/Styles/layout";
import Select from "../../../MaterialUI/FormFields/Select";
import {
  getAssetsOptions,
  LossesChartType,
  chartTypeOptions,
  oEETypes,
  OEETypesOptions,
} from "../oEEUtils";
import Button from "../../../MaterialUI/Button";
import { GetQueryOEEAseetsFunctionalLocationsSnippet } from "../../../../Api/OEE/apiOEESnippets";
import { getQueryAssetsFunctionalLocations } from "../../../../Api/OEE/apiOEEGetQueries";
import callApi from "../../../../Api/callApi";
import { useAuthedContext } from "../../../../context/AuthContext";
import TextField from "../../../MaterialUI/FormFields/TextFields";
import UnitsOfMeasureDropdown from "../../../SmallComponents/UnitsOfMeasureDropdown/UnitsOfMeasureDropdown";
import { extractUnitSymbol } from "../../../SmallComponents/UnitsOfMeasureDropdown/unitsOfMeasureDropdownUtils";

interface EditOEEBarChartFormProps {
  selectedDesiredTarget?: number;
  setSelectedDesiredTarget?: React.Dispatch<React.SetStateAction<number>>;
  isForSingleType?: boolean;
  isForSingleAsset?: boolean;
  isForSingleAssetByHour?: boolean;
}

const EditOEEBarChartForm: React.FC<EditOEEBarChartFormProps> = ({
  selectedDesiredTarget,
  setSelectedDesiredTarget,
  isForSingleType,
  isForSingleAsset,
  isForSingleAssetByHour,
}) => {
  const theme = useTheme();
  const styles = {
    ...cssSpacingStyles(theme),
    ...cssLayoutStyles,
  };
  const { setAuthedUser } = useAuthedContext();
  const [timeConfigData] = useState<ExcellenceDateTimeConfigurationData | null>(
    null
    // () =>
    // handlePreparePropsData(dateTimeConfig)
  );
  const [removeInterval, setRemoveInterval] = useState<boolean>(false);
  const [formStatus] = useState<FormStatuses>(null);
  const [fieldAlertMessage, setFieldAlertMessage] = useState<string | null>(null);
  const [selectedLossesTypeChart, setSelectedLossesTypeChart] =
    useState<LossesChartType>("byType");
  const [assetTypeOptions, setAssetTypeOptions] = useState<AutocompleteOption[]>([]);
  const [selectedAsset, setSelectedAsset] = useState<string>("");
  const [selectedType, setSelectedType] = useState<string>("");
  const [highValue, setHighValue] = useState<number | null>(null);
  const [lowValue, setLowValue] = useState<number | null>(null);
  const [unitId, setUnitId] = useState<string | null>(null);
  const [selectedUnit, setSelectedUnit] = useState<string>("");

  console.log(removeInterval, setFieldAlertMessage);

  const handleUpdateTimeConfig = () => {};

  const fetchOEEData = async () => {
    try {
      const oEEAssets = await callApi<GetQueryOEEAseetsFunctionalLocationsSnippet>({
        query: getQueryAssetsFunctionalLocations,
        auth: { setAuthedUser },
      });
      setAssetTypeOptions(getAssetsOptions(oEEAssets));
    } catch (error) {
      console.log("There was an error fetching assets ", error);
    }
  };

  useEffect(() => {
    fetchOEEData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAssetChange = (e: React.ChangeEvent<{ value: string }>) => {
    const selectedValue = e.target.value as string;
    const selectedOption = assetTypeOptions.find(
      (option) => option.value === selectedValue
    );
    if (selectedOption) {
      setSelectedAsset(selectedOption.value);
    }
  };

  const handleUnitChange = (option: AutocompleteOption | null, isEmpty: boolean) => {
    if (isEmpty) {
      setUnitId(null);
      setSelectedUnit("");
    } else {
      setUnitId(option ? option.value : "");
      setSelectedUnit(option ? option.description : "");
    }
  };

  return (
    <Stack alignItems="center" justifyContent="center">
      <Grid container spacing={3} mb={3}>
        <Grid item xs={6}>
          <Select
            selectOptions={assetTypeOptions}
            label="Select Asset"
            onChange={(e) => handleAssetChange(e)}
            value={selectedAsset}
          />
        </Grid>

        {selectedDesiredTarget && setSelectedDesiredTarget ? (
          <Grid item xs={6}>
            <TextField
              label="Target in Percentage"
              numberField={true}
              onChange={(e) => setSelectedDesiredTarget(Number(e.target.value))}
              value={selectedDesiredTarget}
              InputProps={{
                endAdornment: <InputAdornment position="start">%</InputAdornment>,
              }}
            />
          </Grid>
        ) : isForSingleType || isForSingleAsset ? (
          <Grid item xs={6}>
            <Select
              selectOptions={OEETypesOptions}
              value={selectedType}
              label="Type"
              onChange={(e) => setSelectedType(e.target.value as oEETypes)}
            />
          </Grid>
        ) : !isForSingleAssetByHour ? (
          <Grid item xs={6}>
            <Select
              selectOptions={chartTypeOptions}
              value={selectedLossesTypeChart}
              label="Category Type"
              onChange={(e) =>
                setSelectedLossesTypeChart(e.target.value as LossesChartType)
              }
            />
          </Grid>
        ) : null}

        {isForSingleAsset ? (
          <>
            <Grid item xs={12}>
              <Divider flexItem />
            </Grid>

            <Grid item xs={6}>
              <UnitsOfMeasureDropdown
                css={styles.widthLimit20}
                targetUnitValue={unitId}
                handleOnUnitsChange={handleUnitChange}
              />
            </Grid>

            <Grid item xs={6}></Grid>

            <Grid item xs={6}>
              <TextField
                label="Low"
                numberField={true}
                onChange={(e) => setLowValue(Number(e.target.value))}
                value={lowValue}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      {extractUnitSymbol(selectedUnit)}
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                label="High"
                numberField={true}
                onChange={(e) => setHighValue(Number(e.target.value))}
                value={highValue}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      {extractUnitSymbol(selectedUnit)}
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </>
        ) : null}
      </Grid>

      <Box component="div" css={styles.width100} mb={2}>
        <Divider flexItem />
      </Box>

      <Box component="div" css={styles.width100}>
        <Box component="div" css={[styles.width100, styles.labelBreak]}>
          <DateTimeConfiguration
            css={styles.contentBreak}
            handleUpdateData={handleUpdateTimeConfig}
            timeConfigData={timeConfigData}
            setRemoveInterval={setRemoveInterval}
            disabled={formStatus === "loading"}
            isOEEConfiguration={true}
          />
          <Alert
            showAlert={!!fieldAlertMessage}
            severity="warning"
            message={fieldAlertMessage}
          />
        </Box>
      </Box>
      <Button css={styles.widthLimit50}>Save Changes</Button>
    </Stack>
  );
};

export default EditOEEBarChartForm;
