import { useEffect, useState } from "react";
import ResponsiveTableGrid from "../../Components/SmallComponents/TableGrid/ResponsiveTableGrid";
import callApi from "../../Api/callApi";
import { useAuthedContext } from "../../context/AuthContext";
import PageTitle from "../../Components/SmallComponents/PageTitle/PageTitle";
import { useOutletContext } from "react-router-dom";
import { AppRouterProps } from "../../Layout/layoutVariables";
import { Box, IconButton, Stack, Tab, Tabs, Typography, useTheme } from "@mui/material";
import { useLanguageContext } from "../../context/LanguageContext";
import { useTranslateFields } from "../../Global/Hooks/useTranslations";
import {
  GetQueryStocks,
  GetQueryStocksOverview,
} from "../../Api/SupplyChain/apiSupplyChainSnippets";
import {
  getQueryStocks,
  getQueryStocksOverview,
} from "../../Api/SupplyChain/apiSupplyChainGetQueries";
import {
  StockRow,
  StocksOverviewRow,
} from "../../Components/PageComponents/MyWarehouses/stocksUtils";
import {
  TableDynamicCondition,
  TableGridColumnSchema,
} from "../../Components/SmallComponents/TableGrid/constructTableGrid";
import cssSpacingStyles from "../../Global/Styles/spacing";
import { ModalLayoutData } from "../../Components/SmallComponents/TableGrid/CellActionButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Modal from "../../Components/MaterialUI/Modal";
import Button from "../../Components/MaterialUI/Button";
import StockViewInfo from "../../Components/PageComponents/StockControl/StockViewInfo";
import cssLayoutStyles from "../../Global/Styles/layout";
import { deleteQueryStocks } from "../../Api/SupplyChain/apiSupplyChainPostQueries";
import StockEditForm from "../../Components/PageComponents/StockControl/StockEditForm";

const StocksPage = () => {
  const { t } = useLanguageContext();
  const theme = useTheme();
  const styles = {
    ...cssLayoutStyles,
    ...cssSpacingStyles(theme),
  };
  const [tabView, setTabView] = useState<number>(0);
  const [openInfo, setOpenInfo] = useState<boolean>(false);
  const [modalData, setModalData] = useState<ModalLayoutData | null>(null);
  const [stocksRows, setStocksRows] = useState<StockRow[]>();
  const [stocksColumns, setStocksColumns] = useState<TableGridColumnSchema[]>();
  const [stockOverviewRows, setStockOverviewRows] = useState<StocksOverviewRow[]>();
  const [stockoverviewColumns, setStockOverviewColumns] =
    useState<TableGridColumnSchema[]>();
  const [pageLoading, setPageLoading] = useState<boolean>(true);
  const { setAuthedUser } = useAuthedContext();
  const { smMediaQuery, setExtraTopNavMenu } = useOutletContext<AppRouterProps>();
  const [unsavedChanges, setUnsavedChanges] = useState<boolean>(false);

  const formattedRows = handleFormatRows(setOpenInfo, setModalData, stocksRows);

  useEffect(() => {
    if (smMediaQuery) {
      setExtraTopNavMenu(null);
    } else {
      setExtraTopNavMenu(<PageTitle title={t("Stock Control")} />);
    }

    return () => {
      setExtraTopNavMenu(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [smMediaQuery]);

  useEffect(() => {
    fetchStocksData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchStocksData = async () => {
    try {
      setPageLoading(true);
      const stocks = await callApi<GetQueryStocks>({
        query: getQueryStocks(),
        auth: { setAuthedUser },
      });

      const stocksOverview = await callApi<GetQueryStocksOverview>({
        query: getQueryStocksOverview(),
        auth: { setAuthedUser },
      });

      setStockOverviewRows(stocksOverview.rows);
      setStockOverviewColumns(
        stocksOverview.columns.map((col) => ({
          ...col,
          dynamicBgColor: dynamicBgColors(col),
        }))
      );

      setStocksRows(stocks.rows);
      setStocksColumns([
        ...stocks.columns,
        { id: "actions", label: "Actions", type: "button" },
      ]);
    } catch (error) {
      console.error("There was an error fetching stocks ", error);
    } finally {
      setPageLoading(false);
    }
  };

  const handleDeleteStock = async (instanceId: string) => {
    try {
      await callApi({
        query: deleteQueryStocks(instanceId),
        auth: { setAuthedUser },
      });
      setOpenInfo(false);
    } catch (error) {
      console.log("Stock delete error: ", error.message);
    }
  };

  const handleSetUnsavedChanges = (unsavedChanges: boolean) => {
    if (unsavedChanges) {
      setUnsavedChanges(true);
    }
  };

  return (
    <>
      {smMediaQuery ? (
        <PageTitle css={styles.labelBreak} title={t("Stock Control")} />
      ) : null}

      <Box component="div">
        <Tabs
          css={styles.sectionBreak}
          value={tabView}
          onChange={(_, val) => setTabView(val)}
          aria-label="configure chart tabs"
          centered
        >
          <Tab label={t("Stocks")} disabled={pageLoading} />
          <Tab label={t("Overview")} disabled={pageLoading} />
        </Tabs>

        {tabView === 0 ? (
          <Box component="div">
            <ResponsiveTableGrid
              rows={formattedRows || []}
              colSchema={useTranslateFields(stocksColumns || [], ["label"])}
              editMode={false}
              responsive="responsive"
              configuration={{
                density: "compact",
              }}
              loading={pageLoading}
              tableID="Stocks_table"
            />
          </Box>
        ) : null}

        {tabView === 1 ? (
          <ResponsiveTableGrid
            rows={stockOverviewRows || []}
            colSchema={useTranslateFields(stockoverviewColumns || [], ["label"])}
            editMode={false}
            responsive="responsive"
            configuration={{
              density: "compact",
            }}
            loading={pageLoading}
            tableID="StocksOverview_table"
          />
        ) : null}
      </Box>

      {modalData ? (
        <Modal
          open={openInfo}
          fullWidth
          label={getModalLabel(modalData)}
          onClose={() => setOpenInfo(false)}
          unsavedChanges={unsavedChanges}
          setUnsavedChanges={setUnsavedChanges}
        >
          {modalData && modalData.type === "viewStockInfo" ? (
            <StockViewInfo stock={modalData.data} />
          ) : null}
          {modalData && modalData.type === "stockForm" ? (
            <StockEditForm
              stock={modalData.data}
              onSubmit={fetchStocksData}
              setOpenInfo={setOpenInfo}
              handleSetUnsavedChanges={handleSetUnsavedChanges}
              setUnsavedChanges={setUnsavedChanges}
            />
          ) : null}
          {modalData && modalData.type === "deleteForm" ? (
            <Stack spacing={2}>
              <Typography variant="body1">
                {t("Are you sure you want to delete this stock?")}
              </Typography>

              <Stack direction="row" justifyContent="center" alignItems="center" gap={1}>
                <Button
                  css={[styles.width100, styles.widthLimit10]}
                  onClick={() => setOpenInfo(false)}
                >
                  {t("Cancel")}
                </Button>
                <Button
                  css={[styles.width100, styles.widthLimit10]}
                  onClick={() => handleDeleteStock(modalData.data)}
                  color="error"
                >
                  {t("Confirm Deletion")}
                </Button>
              </Stack>
            </Stack>
          ) : null}
        </Modal>
      ) : null}
    </>
  );
};

export default StocksPage;

const handleFormatRows = (
  setOpenInfo: React.Dispatch<React.SetStateAction<boolean>>,
  setModalData: React.Dispatch<React.SetStateAction<ModalLayoutData | null>>,
  stocksRows: StockRow[] | undefined
): StockRow[] => {
  if (!stocksRows) return [];

  const formatedRows: StockRow[] = [];
  if (stocksRows) {
    for (let i = 0; i < stocksRows.length; i++) {
      const stock = stocksRows[i];
      formatedRows.push({
        instance_id: stock.instance_id,
        material_code: stock.material_code,
        instance_code: stock.instance_code,
        name: stock.name,
        quantity: stock.quantity,
        location: stock.location,
        status: stock.status,
        actions: (
          <Stack direction="row" justifyContent="flex-end" alignItems="center">
            <IconButton
              onClick={() => {
                setOpenInfo(true);
                setModalData({ type: "viewStockInfo", data: stock });
              }}
            >
              <VisibilityIcon />
            </IconButton>
            <IconButton
              onClick={() => {
                setOpenInfo(true);
                setModalData({ type: "stockForm", data: stock });
              }}
            >
              <EditIcon />
            </IconButton>
            <IconButton
              onClick={() => {
                setOpenInfo(true);
                setModalData({ type: "deleteForm", data: stock.instance_id });
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Stack>
        ),
      });
    }
  }
  return formatedRows;
};

const getModalLabel = (modalData: ModalLayoutData | null): string => {
  const { t } = useLanguageContext();

  if (!modalData) return "";

  switch (modalData.type) {
    case "viewStockInfo":
      return t("View Stock Information");
    case "stockForm":
      return t("Edit Stock");
    case "deleteForm":
      return t("Delete Stock");
    default:
      return "";
  }
};

const dynamicBgColors = (
  col: TableGridColumnSchema
): (TableDynamicCondition & { bgColor: string }) | undefined => {
  if (col.id === "quantity") {
    return {
      operation: "always",
      cellValue: 0,
      bgColor: "transparent",
    };
  }
  return undefined;
};
