import { useEffect, useState } from "react";
import { FormStatuses, SelectOption } from "../../../Global/Types/commonTypes";
import { useAuthedContext } from "../../../context/AuthContext";
import { ShiftPlan } from "./shiftPlanningUtils";
import { createDemoShift } from "./tempApiCalls";
import callApi from "../../../Api/callApi";
import { Box, Card, Divider, Grid, Stack, Typography, useTheme } from "@mui/material";
import Alert from "../../MaterialUI/Alert";
import Button from "../../MaterialUI/Button";
import cssLayoutStyles from "../../../Global/Styles/layout";
import cssSpacingStyles from "../../../Global/Styles/spacing";
import TextField from "../../MaterialUI/FormFields/TextFields";
import DatePicker from "../../MaterialUI/DateTimePickers/DatePicker";
import TimePicker from "../../MaterialUI/DateTimePickers/TimePicker";
import Select from "../../MaterialUI/FormFields/Select";
import { v4 as uuidv4 } from "uuid";
import { handleGetSelectOption } from "../../../Global/Utils/commonFunctions";
import { useDetectFormsUnsavedChanges } from "../../../Global/Hooks/useDetectFormsUnsavedChanges";
import { useLanguageContext } from "../../../context/LanguageContext";
import { useTranslateSchemeArray } from "../../../Global/Hooks/useTranslations";

// const workingDaysOptions: SelectOption[] = [
//   {
//     // @ts-ignore
//     value: 0,
//     description: "Monday",
//   },
//   {
//     // @ts-ignore
//     value: 1,
//     description: "Tuesday",
//   },
//   {
//     // @ts-ignore
//     value: 2,
//     description: "Wednesday",
//   },
//   {
//     // @ts-ignore
//     value: 3,
//     description: "Thursday",
//   },
//   {
//     // @ts-ignore
//     value: 4,
//     description: "Friday",
//   },
//   {
//     // @ts-ignore
//     value: 5,
//     description: "Saturday",
//   },
//   {
//     // @ts-ignore
//     value: 6,
//     description: "Sundary",
//   },
// ];

const workingDaysOptions2: SelectOption[] = handleGetSelectOption([
  "Shift A",
  "Shift B",
  "Shift C",
]);

const startTime = new Date();
const endTime = new Date();

startTime.setHours(6);
startTime.setMinutes(0);
startTime.setSeconds(0);
startTime.setMilliseconds(0);

endTime.setHours(14);
endTime.setMinutes(0);
endTime.setSeconds(0);
endTime.setMilliseconds(0);

// @ts-ignore
const initialValues: ShiftPlan = {
  name: "Shift Plan A",
  startDate: new Date(),
  weeksDuration: 1,
  people: [],
};

interface ShiftPlanningAddModalProps {
  handleOnCreate: () => Promise<void>;
  handleSetUnsavedChanges: (unsavedChanges: boolean) => void;
  setUnsavedChanges: React.Dispatch<React.SetStateAction<boolean>>;
}

const ShiftPlanningAddModal: React.FC<ShiftPlanningAddModalProps> = ({
  handleOnCreate,
  handleSetUnsavedChanges,
  setUnsavedChanges,
}) => {
  const { t } = useLanguageContext();
  const theme = useTheme();
  const styles = { ...cssSpacingStyles(theme), ...cssLayoutStyles };

  const [alertMessage, setAlertMessage] = useState<string | null>(null);
  const [alertStatus, setAlertStatus] = useState<FormStatuses>(null);
  const [formInputs, setFormInputs] = useState<ShiftPlan>(initialValues);
  const { setAuthedUser } = useAuthedContext();

  useDetectFormsUnsavedChanges(initialValues, formInputs, handleSetUnsavedChanges);

  useEffect(() => {
    return () => {
      setFormInputs(initialValues);
    };
  }, []);

  const handleAdd = () => {
    setFormInputs((prev) => {
      const start = new Date();

      start.setHours(6);
      start.setMinutes(0);
      start.setSeconds(0);
      start.setMilliseconds(0);

      const end = new Date();

      // Set the time to 14:00 (2:00 PM)
      end.setHours(14);
      end.setMinutes(0);
      end.setSeconds(0);
      end.setMilliseconds(0);

      return {
        ...prev,
        people: [
          ...prev.people,
          {
            name: "",
            working_days: [],
            qualifications: "",
            role: "",
            start_time: start,
            end_time: end,
          },
        ],
      };
    });
  };

  const handleChangeField = (key: keyof any, value: any) => {
    setFormInputs((prev) => ({ ...prev, [key]: value }));
  };

  const handleChangePersonField = (key: keyof any, value: any, index: number) => {
    setFormInputs((prev) => {
      const people = prev.people.map((item, eleIndex) => {
        if (eleIndex === index) {
          return {
            ...item,
            [key]: value,
          };
        }
        return item;
      });

      return { ...prev, people: people };
    });
  };

  const handleRemove = (index: number) => {
    setFormInputs((prev) => ({
      ...prev,
      people: prev.people.filter((_, itemIndex) => index !== itemIndex),
    }));
  };

  const handleSubmit = async () => {
    try {
      setAlertStatus("loading");
      setAlertMessage(null);

      const input: ShiftPlan = {
        ...formInputs,
        id: uuidv4(),
        people: formInputs.people.map((item) => {
          const working_days =
            // @ts-ignore
            item.working_days === "Shift A"
              ? [0, 1, 2, 3, 4]
              : // @ts-ignore
              item.working_days === "Shift B"
              ? [0, 1, 2]
              : [5, 6];

          return {
            ...item,
            working_days,
          };
        }),
      };

      await callApi({
        // @ts-ignore
        query: createDemoShift(input),
        auth: { setAuthedUser },
      });
      await handleOnCreate();

      setAlertStatus("success");
      setUnsavedChanges(false);
      setAlertMessage(t("Successfully added"));
    } catch (err) {
      console.log("err ", err);
      setAlertStatus("error");
      setAlertMessage(t("Something went wrong"));
    }
  };

  return (
    <Box component="div">
      <Grid css={styles.contentBreak} container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label={t("Shift Plan Name")}
            value={formInputs.name}
            onChange={(e) => handleChangeField("name", e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <DatePicker
            label={t("Start date")}
            value={formInputs.startDate}
            onChange={(val) => handleChangeField("startDate", val)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label={t("Weeks Duration")}
            value={formInputs.weeksDuration}
            onChange={(e) => handleChangeField("weeksDuration", +e.target.value)}
            numberField
          />
        </Grid>
      </Grid>

      <Divider css={styles.labelBreak} />

      <Stack
        css={styles.contentBreak}
        spacing={2}
        alignItems="center"
        direction="row"
        justifyContent="space-between"
      >
        <Typography variant="body1">
          {t("Here, you can add people to the shift plan")}
        </Typography>

        <Button onClick={handleAdd}>Add New</Button>
      </Stack>

      {formInputs.people.length ? (
        <>
          <Box component="div" css={styles.contentBreak}>
            {formInputs.people.map((item, index) => (
              <Card
                style={{ padding: theme.spacing(2), paddingTop: theme.spacing(1) }}
                css={styles.labelBreak}
                key={`item=${index}`}
              >
                <Stack flexDirection="row" justifyContent="flex-end">
                  <Button
                    variant="text"
                    color="error"
                    onClick={() => handleRemove(index)}
                  >
                    {t("Remove")}
                  </Button>
                </Stack>

                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label={t("Name")}
                      value={item.name}
                      onChange={(e) =>
                        handleChangePersonField("name", e.target.value, index)
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Select
                      label={t("Working Shift")}
                      onChange={(e) =>
                        handleChangePersonField("working_days", e.target.value, index)
                      }
                      value={item.working_days}
                      selectOptions={useTranslateSchemeArray(workingDaysOptions2)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label={t("Qualifications")}
                      value={item.qualifications}
                      onChange={(e) =>
                        handleChangePersonField("qualifications", e.target.value, index)
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label={t("Role")}
                      value={item.role}
                      onChange={(e) =>
                        handleChangePersonField("role", e.target.value, index)
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TimePicker
                      label={t("Start Time")}
                      value={item.start_time}
                      onChange={(val) =>
                        handleChangePersonField("start_time", val, index)
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TimePicker
                      label={t("End Time")}
                      value={item.end_time}
                      onChange={(val) => handleChangePersonField("end_time", val, index)}
                    />
                  </Grid>
                </Grid>
              </Card>
            ))}
          </Box>
        </>
      ) : (
        <Typography variant="body1" textAlign="center">
          {t("You haven't added any working hours yet")}
        </Typography>
      )}

      <Stack css={styles.reverseContentBreak} alignItems="center" justifyContent="center">
        <Alert message={alertMessage} showAlert={!!alertMessage} severity={alertStatus} />
      </Stack>
      <Stack css={styles.width100} alignItems="center" justifyContent="center">
        <Button
          css={styles.widthLimit20}
          onClick={handleSubmit}
          disabled={alertStatus === "success"}
        >
          {t("Submit")}
        </Button>
      </Stack>
    </Box>
  );
};

export default ShiftPlanningAddModal;
