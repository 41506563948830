import { SelectOption } from "../../../Global/Types/commonTypes";

export enum TrendDirection {
  UP = "UP",
  DOWN = "DOWN",
  FLAT = "FLAT",
}

export const positiveTrendSelectOptions: SelectOption[] = [
  { value: TrendDirection.UP, description: "Upwards" },
  { value: TrendDirection.DOWN, description: "Downwards" },
  { value: "target", description: "On Target" },
];

export const defaultIndicatorData = [
  72, 315, 95, 189, 165, 188, 270, 58, 276, 22, 175, 10, 200, 157, 905, 
  81, 240, 180, 298, 147, 199, 330, 94, 167, 50, 112, 8, 372, 180, 650, 
  110, 365, 88, 190, 139, 135, 310, 77, 245, 18, 134, 9, 405, 220, 700,
  150, 280, 140, 320, 100, 250, 290, 180, 200, 90, 200, 50, 275, 215, 500, 
  130, 310, 195, 260, 175, 215, 326, 105, 245, 65, 140, 45, 380, 250, 500, 
  190, 210, 125, 200, 185, 160, 140, 120, 110, 80, 100, 55, 300, 270, 810
];


