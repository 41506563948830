import { Box, css, Stack, Theme, Typography, useTheme } from "@mui/material";
import {
  AutocompleteGroupedOption,
  AutocompleteOption,
  FormStatuses,
  SelectOption,
} from "../../../Global/Types/commonTypes";
import { ExcellenceParameter } from "../../../GraphQL/Excellence/graphQLTypesExcellence";
import { ExcellenceTableDataSchema } from "./editExcellenceTabletypes";
import { useEffect, useMemo, useState } from "react";
import { useLanguageContext } from "../../../context/LanguageContext";
import cssLayoutStyles from "../../../Global/Styles/layout";
import cssSpacingStyles from "../../../Global/Styles/spacing";
import { ExcellenceParamMapping } from "../EditExcellenceChartForms/excellenceChartFormUtils";
import { handleGetSelectOption } from "../../../Global/Utils/commonFunctions";
import { isValid } from "date-fns";
import DateAndTimePicker from "../../MaterialUI/DateTimePickers/DateAndTimePicker";
import Autocomplete from "../../MaterialUI/FormFields/Autocomplete";
import AutocompleteGrouped from "../../MaterialUI/FormFields/AutocompleteGrouped";
import {
  GraphqlFilter,
  handleExcellenceParamToGraphqlFilters,
} from "../../SmallComponents/GraphqlFilters/graphqlFiltersUtils";
import GraphqlFilters from "../../SmallComponents/GraphqlFilters/GraphqlFilters";
import Modal from "../../MaterialUI/Modal";
import { FiltersModalLayoutData } from "../excellenceUtils";
import { RemoveFiltersWarning } from "../ExcellenceSmallComponents";

const cssStyles = (theme: Theme) => ({
  autocompleteGroup: css({
    position: "sticky",
    top: "-8px",
    padding: "4px 10px",
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.secondary.light,
  }),
  autocompleteList: css({
    padding: 0,
  }),
});

interface EditExcellenceTableDataSchemaProps {
  parameters: ExcellenceParameter[];
  dataSchema: ExcellenceTableDataSchema | null;
  handleUpdateDataSchema: (schema: ExcellenceTableDataSchema | null) => void;
  dateFrom: Date | null;
  setDateFrom: React.Dispatch<React.SetStateAction<Date | null>>;
  dateTo: Date | null;
  setDateTo: React.Dispatch<React.SetStateAction<Date | null>>;
  selectedConnection: SelectOption;
  setSelectedConnection: React.Dispatch<React.SetStateAction<SelectOption>>;
  graphqlFilters: GraphqlFilter[];
  setGraphqlFilters: React.Dispatch<React.SetStateAction<GraphqlFilter[]>>;
  initialSetupComplete: boolean;
  setInitialSetupComplete: React.Dispatch<React.SetStateAction<boolean>>;
  paramMapping: ExcellenceParamMapping | null;
}

const EditExcellenceTableDataSchema: React.FC<EditExcellenceTableDataSchemaProps> = ({
  parameters,
  dataSchema,
  handleUpdateDataSchema,
  dateFrom,
  setDateFrom,
  dateTo,
  setDateTo,
  selectedConnection,
  setSelectedConnection,
  graphqlFilters,
  setGraphqlFilters,
  initialSetupComplete,
  setInitialSetupComplete,
  paramMapping,
}) => {
  const theme = useTheme();
  const styles = {
    ...cssSpacingStyles(theme),
    ...cssStyles(theme),
    ...cssLayoutStyles,
  };
  const { t } = useLanguageContext();
  // options
  const [nodeOptions, setNodeOptions] = useState<AutocompleteGroupedOption[]>([]);
  const [connectionOptions, setConnectionOptions] = useState<SelectOption[]>([]);
  const [appliedFiltersModal, setAppliedFiltersModal] = useState<FiltersModalLayoutData>({
    isOpen: false,
    data: { value: '', description: '' }
  });
  // selected values
  const [groupRows, setGroupRows] = useState<AutocompleteGroupedOption[]>([]);
  // system-used
  const [formStatus, setFormStatus] = useState<FormStatuses>(null);
  const [reUpdateSchema, setReUpdateSchema] = useState<boolean>(false);

  const filtersData = useMemo(() => {
    return handleExcellenceParamToGraphqlFilters(parameters);
  }, [parameters]);

  useEffect(() => {
    if (graphqlFilters.length) {
      const selectedConnections: AutocompleteOption[] = Array.from(
        new Map(
          graphqlFilters.map((item) => [item.containerId, {
            value: item.containerId,
            description: item.containerName
          }])
        ).values()
      );
      setSelectedConnection(selectedConnections[0]);
      setGroupRows([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (dataSchema && paramMapping) {
      const groupParams: AutocompleteGroupedOption[] = dataSchema?.parameters?.map(
        (item) => ({
          value: item,
          groupName: dataSchema.container,
          description: paramMapping?.[item].paramName || item,
        })
      );

      const connName = paramMapping?.[groupParams[0]?.value || ""]?.connectionName;
      const selectedConnection: SelectOption | null = {
        value: dataSchema.container,
        description: connName || dataSchema.container,
      };

      setSelectedConnection(selectedConnection);
      setGroupRows(groupParams || []);
      setInitialSetupComplete(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paramMapping]);

  useEffect(() => {
    const connectionsRecord: Record<string, string> = {};
    parameters.forEach((item) => {
      connectionsRecord[item.container] = item.containerName;
    });

    const allConnectionOptions: SelectOption[] = Object.entries(connectionsRecord).map(
      ([key, value]) => ({
        value: key,
        description: value,
      })
    );

    handleGetSelectOption([...new Set(parameters.map((item) => item.container))]);

    setConnectionOptions(allConnectionOptions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parameters]);

  useEffect(() => {
    const paramOptions = handleGetParamOptions(parameters, selectedConnection);
    setNodeOptions(paramOptions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedConnection]);

  useEffect(() => {
    const startTime = dateFrom && isValid(dateFrom) ? dateFrom.toISOString() : null;
    const endTime = dateTo && isValid(dateTo) ? dateTo.toISOString() : null;
    const groupParams: string[] = [];

    if (initialSetupComplete && !selectedConnection.value) {
      handleUpdateDataSchema(null);
    } else {
      for (const row of groupRows) {
        if (paramMapping && row?.value) {
          groupParams.push(row.value);
        }
      }

      if (groupParams.length && groupParams?.[0] && selectedConnection?.value) {
        const updatedSchema: ExcellenceTableDataSchema = {
          startTime,
          endTime,
          container: selectedConnection.value,
          parameters: groupParams,
        };
        handleUpdateDataSchema(updatedSchema);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reUpdateSchema]);

  const onDateRangeChange = async (val: Date | null, type: "dateTo" | "dateFrom") => {
    try {
      let fullDataRow: boolean = false;
      if (type === "dateTo") {
        setDateTo(val);
        fullDataRow = !!dateFrom && isValid(dateFrom);
      } else {
        setDateFrom(val);
        fullDataRow = !!dateTo && isValid(dateTo);
      }

      if (val && isValid(val) && fullDataRow) {
        setReUpdateSchema((prev) => !prev);
      }
    } catch (err) {
      console.log("err onDateRangeChange() ", err);
      setFormStatus("error");
    }
  };

  const handleOnConnectionChange = (val: SelectOption) => {
    if ( !val && graphqlFilters.length ) {
      setAppliedFiltersModal({isOpen: true, data: {value: "", description: ""}});
    } else if (val && selectedConnection.value !== val.value && graphqlFilters.length) {
      setAppliedFiltersModal({isOpen: true, data: {value: val.value, description: val.description}});
    } else {
      confirmChangeConnection(val);
    }
  };

  const handleOnNodeChange = (val: AutocompleteGroupedOption[]) => {
    setGroupRows(val);
    setReUpdateSchema((prev) => !prev);
  };

  const confirmChangeConnection = (val: SelectOption) => {
    setSelectedConnection(val || { value: "", description: "" });
    setGroupRows([]);
    if (!val) {
      setReUpdateSchema((prev) => !prev);
    }
    setGraphqlFilters([]);
    setAppliedFiltersModal({ isOpen: false, data: { value: '', description: '' } });
  }

  if (!parameters?.length) {
    return (
      <Box component="div">
        <Typography variant="h3" color="error">
          {t("You don't have access to any active connections")}
        </Typography>
      </Box>
    );
  }

  if (formStatus === "error") {
    return (
      <Box component="div">
        <Typography variant="h3" color="error">
          {t("Something went wrong")}
        </Typography>
      </Box>
    );
  }

  return (
    <Stack
      css={styles.contentBreak}
      spacing={3}
      alignItems="center"
      justifyContent="center"
    >
      <Stack css={styles.width100} spacing={3} direction={{ xs: "column", sm: "row" }}>
        <DateAndTimePicker
          css={styles.width100}
          label={t("Date from")}
          value={dateFrom}
          onChange={(val) => onDateRangeChange(val, "dateFrom")}
        />
        <DateAndTimePicker
          css={styles.width100}
          label={t("Date to")}
          value={dateTo}
          onChange={(val) => onDateRangeChange(val, "dateTo")}
        />
      </Stack>

      <Stack css={styles.width100} spacing={3} direction={{ xs: "column", sm: "row" }}>
        <Box component="div" style={{ flex: 1 }}>
          <Autocomplete
            css={styles.width100}
            label={t("Selected Connection")}
            options={connectionOptions}
            value={selectedConnection}
            handleOnChange={handleOnConnectionChange}
            disabled={formStatus === "loading"}
          />
        </Box>

        <Box css={[styles.flex, styles.flexAlignItemsEnd]} component="div">
          <GraphqlFilters
            key={graphqlFilters.length}
            data={filtersData}
            graphqlFilters={graphqlFilters}
            setGraphqlFilters={setGraphqlFilters}
            selectedContainers={[selectedConnection.value]}
            customOperations={[{ value: "EQ", description: "Equals" }]}
          />
        </Box>
      </Stack>

      {selectedConnection?.value ? (
        <AutocompleteGrouped
          css={styles.width100}
          label={`${t("Group Parameter")}`}
          options={nodeOptions}
          value={groupRows}
          handleOnChange={handleOnNodeChange}
          disabled={formStatus === "loading"}
          multiple
        />
      ) : null}

      <Modal
        open={appliedFiltersModal.isOpen}
        onClose={() => setAppliedFiltersModal(prev => ({ ...prev, isOpen: false }))}
        fullWidth
        label={t("Warning")}
      >
        <RemoveFiltersWarning
          confirmChangeConnection={() => confirmChangeConnection(appliedFiltersModal.data)}
          onClose={() => setAppliedFiltersModal(prev => ({ ...prev, isOpen: false }))}
        />
      </Modal>
    </Stack>
  );
};

export default EditExcellenceTableDataSchema;

const handleGetParamOptions = (
  parameters: ExcellenceParameter[],
  selectedConnection: SelectOption
): AutocompleteGroupedOption[] => {
  const result =
    parameters
      .map((item) => ({
        groupName: item.container,
        value: item.id,
        description: item.name,
      }))
      .filter((node) =>
        // selectedConnectionsArr.some((sub) => sub.value === node.groupName)
        node.groupName === selectedConnection.value
      ) || [];

  const resultWithGroupNames = result.map((item) => {
    // const found = selectedConnectionsArr.find((conn) => conn.value === item.groupName);
    const found = selectedConnection.value === item.groupName ? selectedConnection : null;
    const name = found?.description || item.groupName;
    return {
      ...item,
      groupName: name,
    };
  });

  return resultWithGroupNames;
};
