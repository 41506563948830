import { Box, Card, IconButton, Menu, Stack, Typography, useTheme } from "@mui/material";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import { GetQueryHelpSelectionsForPage } from "../../../Api/Help/apiHelpSnippets";
import { useState } from "react";
import cssComponentsStyles from "../../../Global/Styles/components";
import cssSpacingStyles from "../../../Global/Styles/spacing";
import cssLayoutStyles from "../../../Global/Styles/layout";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { ModalLayoutData } from "../../SmallComponents/TableGrid/CellActionButton";
import Modal from "../../MaterialUI/Modal";
import Button from "../../MaterialUI/Button";
import AddHelpContentForm from "./AddHelpContentForm";
import { HelpSelection, ModalHelpTitle, PageKey } from "./helpUtils";
import { FormStatuses } from "../../../Global/Types/commonTypes";
import UploadHelpFile from "./UploadHelpFile";
import { useLanguageContext } from "../../../context/LanguageContext";
import HelpContentDisplay from "./HelpContentDisplay";

interface HelpContentSelectionCardProps {
  helpSelection: HelpSelection;
  canUserEditAddDelete: boolean | undefined;
  order: number;
  setModalTitle: React.Dispatch<React.SetStateAction<ModalHelpTitle | null>>;
  editorContent: string;
  setEditorContent: React.Dispatch<React.SetStateAction<string>>;
  uploadType: "video" | "image" | null;
  setUploadType: React.Dispatch<React.SetStateAction<"video" | "image" | null>>;
  currentPage: PageKey | undefined;
  setUnsavedEditChanges: React.Dispatch<React.SetStateAction<boolean>>;
  setTempSelections: React.Dispatch<React.SetStateAction<GetQueryHelpSelectionsForPage>>;
  draftSelections: GetQueryHelpSelectionsForPage;
  setDraftSelections: React.Dispatch<React.SetStateAction<GetQueryHelpSelectionsForPage>>;
  setFormStatus: React.Dispatch<React.SetStateAction<FormStatuses | null>>;
  setAlertMessage: React.Dispatch<React.SetStateAction<string | null>>;
}

const HelpContentSelectionCard: React.FC<HelpContentSelectionCardProps> = ({
  helpSelection,
  canUserEditAddDelete,
  order,
  setModalTitle,
  editorContent,
  setEditorContent,
  uploadType,
  setUploadType,
  currentPage,
  setUnsavedEditChanges,
  setTempSelections,
  draftSelections,
  setDraftSelections,
  setFormStatus,
  setAlertMessage,
}) => {
  const { t } = useLanguageContext();
  const theme = useTheme();
  const styles = {
    ...cssSpacingStyles(theme),
    ...cssLayoutStyles,
    ...cssComponentsStyles(theme),
  };
  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);
  const openMenu = Boolean(menuAnchor);
  const [openInfo, setOpenInfo] = useState<boolean>(false);
  const [modalData, setModalData] = useState<ModalLayoutData | null>(null);
  const [unsavedChanges, setUnsavedChanges] = useState<boolean>(false);

  const isExistingSelection = draftSelections.some(
    (draftSelection) => draftSelection.id === helpSelection.id
  );

  const handleDeleteSelection = () => {
    if (!isExistingSelection) {
      setTempSelections((prevSelections) =>
        prevSelections.filter((selection) => selection.id !== helpSelection.id)
      );
    } else {
      setDraftSelections((prev) => {
        const updatedSelections = prev.filter((sel) => sel.id !== helpSelection?.id);
        return [...updatedSelections];
      });
    }

    setUnsavedEditChanges(true);
    setOpenInfo(false);
  };

  const handleSetUnsavedChanges = (unsavedChanges: boolean) => {
    if (unsavedChanges) {
      setUnsavedChanges(true);
    }
  };

  return (
    <>
      <Card css={[styles.width100, styles.fullPadding2, styles.textBreak]}>
        {canUserEditAddDelete ? (
          <Box component="div" style={{ display: "flex", justifyContent: "flex-end" }}>
            <IconButton
              aria-label="widget setting menu"
              onClick={(e) => setMenuAnchor(e.currentTarget)}
              size="small"
            >
              <TuneOutlinedIcon css={styles.greyIcon} fontSize="medium" />
            </IconButton>
          </Box>
        ) : null}

        <Box component="div">
          <HelpContentDisplay type={helpSelection.type} content={helpSelection.content} />
        </Box>

        <Menu
          anchorEl={menuAnchor}
          open={openMenu}
          onClose={() => setMenuAnchor(null)}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
        >
          <Stack css={styles.leftRightPadding1} direction="row" spacing={1}>
            <Stack alignItems="center">
              <IconButton
                aria-label="edit selection"
                onClick={() => {
                  setMenuAnchor(null);
                  setOpenInfo(true);
                  setModalData({
                    type: "editSelection",
                    data: { data: helpSelection, id: helpSelection.id },
                  });
                  setEditorContent(helpSelection.content);
                  if (helpSelection.type === "video") {
                    setUploadType("video");
                  } else if (helpSelection.type === "image") {
                    setUploadType("image");
                  }
                }}
              >
                <EditOutlinedIcon />
              </IconButton>
              <Typography variant="caption" align="center" color="textPrimary">
                {t("Edit")}
              </Typography>
            </Stack>

            <Stack alignItems="center">
              <IconButton
                aria-label="delete selection"
                onClick={() => {
                  setOpenInfo(true);
                  setModalData({ type: "deleteSelection", data: helpSelection.id });
                }}
              >
                <DeleteOutlineOutlinedIcon />
              </IconButton>
              <Typography variant="caption" align="center" color="textPrimary">
                {t("Delete")}
              </Typography>
            </Stack>
          </Stack>
        </Menu>
      </Card>

      {modalData ? (
        <Modal
          open={openInfo}
          fullWidth
          maxWidth={
            modalData.type === "deleteSelection" ||
            modalData.data.data.type === "image" ||
            modalData.data.data.type === "video"
              ? "sm"
              : "md"
          }
          label={getModalLabel(modalData)}
          onClose={() => {
            setOpenInfo(false);
            setModalTitle(null);
          }}
          unsavedChanges={unsavedChanges}
          setUnsavedChanges={setUnsavedChanges}
        >
          {modalData.type === "editSelection" ? (
            modalData.data.data.type === "text" ? (
              <AddHelpContentForm
                handleSetUnsavedChanges={handleSetUnsavedChanges}
                order={order}
                setModalTitle={setModalTitle}
                setOpenInfo={setOpenInfo}
                editorContent={editorContent}
                setEditorContent={setEditorContent}
                helpSelection={helpSelection}
                setUnsavedEditChanges={setUnsavedEditChanges}
                setTempSelections={setTempSelections}
                setDraftSelections={setDraftSelections}
                isExistingSelection={isExistingSelection}
              />
            ) : modalData.data.data.type === "image" ||
              modalData.data.data.type === "video" ? (
              <UploadHelpFile
                currentPage={currentPage}
                order={order}
                setUnsavedEditChanges={setUnsavedEditChanges}
                uploadType={uploadType}
                setUploadType={setUploadType}
                helpSelectionId={helpSelection.id}
                setAlertMessage={setAlertMessage}
                setFormStatus={setFormStatus}
                setOpenInfo={setOpenInfo}
                setModalTitle={setModalTitle}
                setTempSelections={setTempSelections}
                setDraftSelections={setDraftSelections}
                isExistingSelection={isExistingSelection}
              />
            ) : null
          ) : null}

          {modalData.type === "deleteSelection" ? (
            <Stack spacing={2}>
              <Typography variant="body1">
                {t("Are you sure you want to delete this help selection?")}
              </Typography>

              <Stack css={styles.width100} alignItems="center" justifyContent="center">
                <Button
                  css={styles.widthLimit20}
                  color="error"
                  onClick={() => handleDeleteSelection()}
                >
                  {t("Confirm Deletion")}
                </Button>
              </Stack>
            </Stack>
          ) : null}
        </Modal>
      ) : null}
    </>
  );
};

export default HelpContentSelectionCard;

const getModalLabel = (modalData: ModalLayoutData | null): string => {
  const { t } = useLanguageContext();

  if (!modalData) return "";

  switch (modalData.type) {
    case "deleteSelection":
      return t("Delete Selection");
    case "editSelection":
      return t("Edit Selection");
    default:
      return "";
  }
};
