import { Box, IconButton, Stack, Theme, Typography, useTheme } from "@mui/material";
import { css } from "@emotion/react";
import {
  TOP_NAV_SPACING_WITH_SITE_CONTENT,
  AUTH_LAYOUT_PADDING,
  AppRouterProps,
} from "../../../Layout/layoutVariables";
import { useEffect, useState } from "react";
import { useOutletContext, useNavigate, useParams } from "react-router-dom";
import { useAuthedContext } from "../../../context/AuthContext";
import cssSpacingStyles from "../../../Global/Styles/spacing";
import { FormStatuses } from "../../../Global/Types/commonTypes";
import { KanbanWorkflowType } from "../kanbanWorkflowUtils";
import callApi from "../../../Api/callApi";
import { GetQueryKanbanWorkflowSnippet, getQueryKanbanWorkflow } from "../api/queries";
import ROUTES_MAPPING from "../../../Layout/Router/routesMapping";
import {
  deleteQueryKanbanWorkflow,
  PostQueryKanbanWorkflowCreateInput,
  putQueryKanbanWorkflow,
} from "../api/mutations";
import Modal from "../../../Components/MaterialUI/Modal";
import Alert from "../../../Components/MaterialUI/Alert";
import Button from "../../../Components/MaterialUI/Button";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import { AuthedUser } from "../../../context/authContextTypes";
import EditKanbanWorkflowForm from "./Components/EditKanbanWorkflowForm";
import KanbanWorkflow from "./Components/KanbanWorkflow/KanbanWorkflow";
import { useTranslatedModalTitle } from "../../../Global/Hooks/useTranslations";
import { useLanguageContext } from "../../../context/LanguageContext";
import CustomIconsTooltip from "../../../Components/SmallComponents/Tooltip/CustomIconsTooltip";

const cssStyles = (theme: Theme) => ({
  flowWrapper: css({
    width: "100%",
    // minHeight: parent's minHeight - the padding
    minHeight: `calc(100vh - ${TOP_NAV_SPACING_WITH_SITE_CONTENT} - ${AUTH_LAYOUT_PADDING} - ${AUTH_LAYOUT_PADDING})`,
    [theme.breakpoints.down("sm")]: {
      // minHeight: parent's minHeight - the padding - extra_nav_menu
      minHeight: `calc(100vh - ${TOP_NAV_SPACING_WITH_SITE_CONTENT} - ${AUTH_LAYOUT_PADDING} - ${AUTH_LAYOUT_PADDING} - 75px)`,
    },
  }),
  flow: css({
    minHeight: "inherit",
    "& > div": {
      minHeight: "inherit",
    },
  }),
});

const modalTitleTranslations = {
  "Edit Workflow": "Edit Workflow",
  "Delete Workflow": "Delete Workflow",
} as const;

type ModalType = keyof typeof modalTitleTranslations;

const WorkflowSinglePage: React.FC = () => {
  const { t } = useLanguageContext();
  const theme = useTheme();
  const styles = { ...cssStyles(theme), ...cssSpacingStyles(theme) };
  const [workflow, setWorkflow] = useState<KanbanWorkflowType | null>(null);
  const [initialWorkflow, setInitialWorkflow] = useState<KanbanWorkflowType | null>(null);

  const [modalType, setModalType] = useState<ModalType | null>(null);
  const getTranslatedModalTitle = useTranslatedModalTitle(modalTitleTranslations);
  const [alertMessage, setAlertMessage] = useState<string | null>(null);
  const [formStatus, setFormStatus] = useState<FormStatuses>(null);
  const [initialNodeUpdated, setInitialNodeUpdated] = useState<boolean>(false);
  const [unsavedEditChanges, setUnsavedEditChanges] = useState<boolean>(false);

  const { smMediaQuery, setExtraRightNavMenu, setUnsavedChanges } =
    useOutletContext<AppRouterProps>();
  const navigate = useNavigate();
  const initialNode = workflow?.nodes.find((node) => node?.type === "initial");
  const { setAuthedUser } = useAuthedContext();
  const { id } = useParams();

  useEffect(() => {
    (async () => {
      try {
        const res: GetQueryKanbanWorkflowSnippet = await callApi({
          query: getQueryKanbanWorkflow(id!),
          auth: { setAuthedUser },
        });

        setWorkflow(res.data);
        setInitialWorkflow(res.data);
      } catch (err) {
        console.error("error - fetch workflow", err);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (smMediaQuery) {
      setExtraRightNavMenu(null);
    } else {
      setExtraRightNavMenu(
        <ManageWorkflow
          setModalType={setModalType}
          workflow={workflow}
          setAuthedUser={setAuthedUser}
          setUnsavedChanges={setUnsavedChanges}
        />
      );
    }

    return () => {
      setExtraRightNavMenu(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [smMediaQuery, workflow]);

  useEffect(() => {
    if (workflow && initialWorkflow) {
      const areEqual = JSON.stringify(workflow) === JSON.stringify(initialWorkflow);
      setUnsavedChanges(!areEqual);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workflow]);

  const handleDeleteWorkflow = async () => {
    try {
      setFormStatus("loading");
      setAlertMessage(t("Loading..."));

      if (workflow?.nodes.length) {
        await callApi({
          query: deleteQueryKanbanWorkflow(workflow.id),
          auth: { setAuthedUser },
        });
      }

      setFormStatus("success");
      setAlertMessage(null);
      navigate({
        pathname: `${ROUTES_MAPPING["Organizer-Workflow Modeler"]}`,
      });
    } catch (err) {
      console.log("error - handleDeleteWorkflow() ", err);
      setFormStatus("error");
      setAlertMessage(t("Something went wrong"));
    }
  };

  const handleEdit = (updatedName: string) => {
    if (workflow?.nodes.length) {
      const updatedNodes = workflow.nodes.map((item) => {
        if (item.type === "initial") {
          return {
            ...item,
            data: {
              ...item.data,
              name: updatedName,
            },
          };
        }
        return item;
      });
      const updatedWorkflow: KanbanWorkflowType = {
        ...workflow,
        name: updatedName,
        nodes: updatedNodes,
        updatedOn: new Date().toISOString(),
      };

      callApi({
        query: putQueryKanbanWorkflow({ data: updatedWorkflow }, workflow.id),
        auth: { setAuthedUser },
      });

      setWorkflow(updatedWorkflow);
      setInitialNodeUpdated((prev) => !prev);
      setModalType(null);
    }
  };

  const handleSetUnsavedChanges = (unsavedChanges: boolean) => {
    if (unsavedChanges) {
      setUnsavedEditChanges(true);
    }
  };

  return (
    <Box component="div" css={styles.flowWrapper}>
      {smMediaQuery ? (
        <Box component="div" css={styles.labelBreak}>
          <ManageWorkflow
            setModalType={setModalType}
            workflow={workflow}
            setAuthedUser={setAuthedUser}
            setUnsavedChanges={setUnsavedChanges}
          />
        </Box>
      ) : null}

      {workflow?.id ? (
        <KanbanWorkflow
          css={styles.flow}
          workflow={workflow}
          setWorkflow={setWorkflow}
          initialNodeUpdated={initialNodeUpdated}
        />
      ) : null}

      <Modal
        open={!!modalType}
        onClose={() => setModalType(null)}
        fullWidth
        maxWidth="sm"
        label={modalType ? getTranslatedModalTitle(modalType) : ""}
        unsavedChanges={unsavedEditChanges}
        setUnsavedChanges={setUnsavedEditChanges}
      >
        {modalType === "Edit Workflow" ? (
          <EditKanbanWorkflowForm
            currentName={initialNode?.data.name || ""}
            handleEdit={handleEdit}
            handleSetUnsavedChanges={handleSetUnsavedChanges}
            setUnsavedChanges={setUnsavedEditChanges}
          />
        ) : null}

        {modalType === "Delete Workflow" ? (
          <Stack spacing={2} justifyContent="center" alignItems="center">
            <Typography>
              {t("Clicking the below button will delete your workflow")}
            </Typography>
            <Alert
              message={alertMessage}
              showAlert={!!alertMessage}
              severity={formStatus}
            />
            <Button onClick={handleDeleteWorkflow}>{t("Confirm Delete")}</Button>
          </Stack>
        ) : null}
      </Modal>
    </Box>
  );
};

export default WorkflowSinglePage;

interface ManageWorkflowProps {
  setModalType: React.Dispatch<React.SetStateAction<ModalType | null>>;
  workflow: KanbanWorkflowType | null;
  setAuthedUser: React.Dispatch<React.SetStateAction<AuthedUser | null>>;
  setUnsavedChanges: React.Dispatch<React.SetStateAction<boolean>>;
}

const ManageWorkflow: React.FC<ManageWorkflowProps> = ({
  setModalType,
  workflow,
  setAuthedUser,
  setUnsavedChanges,
}) => {
  const { t } = useLanguageContext();
  const handleSaveWorkflow = async () => {
    try {
      if (workflow?.nodes.length) {
        const input: PostQueryKanbanWorkflowCreateInput = {
          data: { ...workflow, updatedOn: new Date().toISOString() },
        };

        await callApi({
          query: putQueryKanbanWorkflow(input, workflow.id),
          auth: { setAuthedUser },
        });
        setUnsavedChanges(false);
      }
    } catch (err) {
      console.error("error - handleSaveWorkflow()", err);
    }
  };

  return (
    <Stack spacing={1} justifyContent="flex-end" direction="column">
      <Stack alignItems="center">
        <CustomIconsTooltip tooltipText={t("Edit")}>
          <IconButton
            aria-label="edit workflow"
            onClick={() => setModalType("Edit Workflow")}
          >
            <EditOutlinedIcon />
          </IconButton>
        </CustomIconsTooltip>
      </Stack>

      <Stack alignItems="center">
        <CustomIconsTooltip tooltipText={t("Export")}>
          <IconButton aria-label="export" disabled>
            <FileDownloadOutlinedIcon />
          </IconButton>
        </CustomIconsTooltip>
      </Stack>

      <Stack alignItems="center">
        <CustomIconsTooltip tooltipText={t("Save")}>
          <IconButton aria-label="save changes" onClick={() => handleSaveWorkflow()}>
            <SaveOutlinedIcon />
          </IconButton>
        </CustomIconsTooltip>
      </Stack>

      <Stack alignItems="center">
        <CustomIconsTooltip tooltipText={t("Delete")}>
          <IconButton
            aria-label="delete workflow"
            onClick={() => setModalType("Delete Workflow")}
          >
            <DeleteOutlineOutlinedIcon />
          </IconButton>
        </CustomIconsTooltip>
      </Stack>
    </Stack>
  );
};
