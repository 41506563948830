import { GaugeWidgetConfiguration } from "../GaugeWidget/gaugeWidgetUtils";

export const gaugeChartDefaultConfiguration: GaugeWidgetConfiguration = {
  enableArcLabels: false,
  enableArcLinkLabels: true,
  colors: "Theme colors",
  colorsSequence: "green-yellow-red",
  unitOfMeasure: "",
  sections: "1",
  gaugeMinValue: -100,
  gaugeMaxValue: 1000,
  sectionRanges: [
    {
      min: -100,
      max: 1000,
    },
  ],
  sectionTitles: [],
};
