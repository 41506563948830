import { Box, Stack } from "@mui/material";
import LabelWithBoldedPart from "../../../Components/MaterialUI/LabelWithBoldedPart";
import { LongPlanningMachineRow, LongPlanningProductRow } from "./longTermPlanningUtils";
import { formatNumber } from "../../../Global/Utils/commonFunctions";
import { useLanguageContext } from "../../../context/LanguageContext";

interface LongTermProductViewProps {
  product: LongPlanningProductRow | null;
}

export const LongTermProductView: React.FC<LongTermProductViewProps> = ({ product }) => {
  const { t } = useLanguageContext();
  if (!product) {
    return null;
  }

  return (
    <Box component="div">
      <Stack spacing={1} mb={2}>
        <LabelWithBoldedPart text={t("ID")} bolded={product.product_id} />
        <LabelWithBoldedPart text={t("Name")} bolded={product.product_name} />
        <LabelWithBoldedPart
          text={t("Forecast Quantity for 2025")}
          bolded={`${formatNumber(product.quantity)}`}
        />
        <LabelWithBoldedPart
          text={t("Planned Percentage")}
          bolded={`${formatNumber(Math.round(product.planned_percentage))} %`}
        />
        <LabelWithBoldedPart
          text={t("Produced on Full Capacity")}
          bolded={`${formatNumber(product.produced_on_full_capacity)}`}
        />
      </Stack>
    </Box>
  );
};

interface LongTermMachineViewProps {
  machine: LongPlanningMachineRow | null;
}

export const LongTermMachineView: React.FC<LongTermMachineViewProps> = ({ machine }) => {
  const { t } = useLanguageContext();
  if (!machine) {
    return null;
  }

  return (
    <Box component="div">
      <Stack spacing={1} mb={2}>
        <LabelWithBoldedPart text={t("ID")} bolded={machine.machine_id} />
        <LabelWithBoldedPart text={t("Type")} bolded={machine.machine_type} />
        <LabelWithBoldedPart
          text={t("Production Plant")}
          bolded={machine.production_plant}
        />
        <LabelWithBoldedPart
          text={t("Products")}
          bolded={machine.products.map((ele) => ele).join(", ")}
        />
        <LabelWithBoldedPart
          text={t("Maximum Speed")}
          bolded={`${formatNumber(machine.maximum_speed)}`}
        />
        <LabelWithBoldedPart
          text={t("Average Output Production Per Hour")}
          bolded={`${formatNumber(machine.average_output_production_per_hour)}`}
        />
        <LabelWithBoldedPart
          text={t("Average Output Production Per One Shift")}
          bolded={`${formatNumber(machine.average_output_production_one_shift)}`}
        />
        <LabelWithBoldedPart
          text={t("Average Output Production Two Shifts")}
          bolded={`${formatNumber(machine.average_output_production_two_shift)}`}
        />
        <LabelWithBoldedPart
          text={t("Average Output Production Three Shifts")}
          bolded={`${formatNumber(machine.average_output_production_three_shift)}`}
        />
        <LabelWithBoldedPart
          text={t("Average Output Production One Week")}
          bolded={`${formatNumber(machine.average_output_production_one_week)}`}
        />
        <LabelWithBoldedPart
          text={t("Average Output Production One Month")}
          bolded={`${formatNumber(machine.average_output_production_one_month)}`}
        />
        <LabelWithBoldedPart
          text={t("Average Output Production Three Months")}
          bolded={`${formatNumber(machine.average_output_production_three_month)}`}
        />
        <LabelWithBoldedPart
          text={t("Average Output Production Six Months")}
          bolded={`${formatNumber(machine.average_output_production_six_month)}`}
        />
        <LabelWithBoldedPart
          text={t("Average Output Production One Year")}
          bolded={`${formatNumber(machine.average_output_production_one_year)}`}
        />
        <LabelWithBoldedPart
          text={t("Utilization")}
          bolded={`${formatNumber(machine.utilization)}%`}
        />
        <LabelWithBoldedPart
          text={t("Free Capacity")}
          bolded={`${formatNumber(machine.free_capacity)}%`}
        />
      </Stack>
    </Box>
  );
};
