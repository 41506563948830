import { ComputedDatum, LegendDatum, ResponsivePie } from "@nivo/pie";
import { SerializedStyles } from "@emotion/react";
import { Box, Stack, Typography, useTheme } from "@mui/material";
import { PieChartConfiguration, PieChartDataSchema } from "./pieChartTypes";
import { PieDonutChartData } from "../EditExcellenceChartForms/excellenceChartFormUtils";
import {
  DynamicGridChartType,
  getChartColors,
  getCurrentColorScheme,
} from "../nivoTheme";
import pieChartDefaultData from "../ExcellenceDefaultConfig/pieChartDefaultData";
import { memo, useEffect, useMemo, useRef, useState } from "react";
import { formatNumber } from "../../../Global/Utils/commonFunctions";
import PieChartLegend from "./PieChartLegend";
import { ChartNotRendered, CustomTooltip } from "../ExcellenceSmallComponents";
import { MAX_DATA_BARS } from "../BarChart/barChartUtils";

interface PieChartProps {
  css?: SerializedStyles[] | SerializedStyles;
  className?: string;
  isDonut?: boolean;
  configuration: PieChartConfiguration;
  data: PieDonutChartData | null;
  isStatic?: boolean;
  noAnimation?: boolean;
  schema: PieChartDataSchema | null;
  paramMapping?: Record<string, string>;
}

const PieChart: React.FC<PieChartProps> = ({
  className,
  isDonut,
  data,
  configuration,
  isStatic,
  noAnimation,
  schema,
  paramMapping,
}) => {
  const theme = useTheme();
  const { enableArcLabels, enableArcLinkLabels } = configuration;
  const [visibleItems, setVisibleItems] = useState<string[]>(
    data ? data.map((d) => d.id) : []
  );

  const colors: string[] = getChartColors();
  const filteredData = useMemo(() => {
    return data 
      ? data
        .map((item, index) => ({
          ...item,
          color: colors[index % colors.length],
        }))
        .filter((d) => visibleItems.includes(d.id)) 
      : pieChartDefaultData;
  }, [data, visibleItems, colors]);
  const { dynamicData, notRenderedData } = getDownSampledData(filteredData);
  const chartContainerRef = useRef<HTMLDivElement>(null);
  // const { width: chartWidth } = useContainerDimensions(chartContainerRef);

  useEffect(() => {
    if (data) {
      setVisibleItems(data.map((d) => d.id));
    }
  }, [data]);

  // const dynamicData = downSampleGenericArrayData(
  //   dataToUse || [],
  //   Math.round(chartWidth / 30)
  // );

  const handleToggleVisibility = (id: string) => {
    setVisibleItems((prev) =>
      prev.includes(id) ? prev.filter((item) => item !== id) : [...prev, id]
    );
  };

  const customLegends = data?.map((datum, index) => ({
    id: datum.id,
    label: datum.label || datum.id,
    color: colors[index % colors.length],
    hidden: !visibleItems.includes(datum.id),
    data: datum,
  })) as LegendDatum<PieDonutChartData>[];

  const chartMargin = {
    top: isStatic ? 10 : 40,
    right: isStatic ? 10 : 80,
    bottom: isStatic ? 10 : 30,
    left: isStatic ? 10 : 80,
  };

  const getFormattedValue = (value: number | null) => {
    const result = formatNumber(
      value,
      configuration.fixedDecimalValue,
      configuration.decimalPoints
    );
    if (!result) {
      return `${value}`;
    }
    return `${result}`;
  };

  const renderPieTooltipContent = (
    datum: ComputedDatum<{ id: string; label: string; value: number }>
  ) => (
    <Typography>
      {datum.label}: <strong>{getFormattedValue(datum.value)}</strong>
    </Typography>
  );

  return (
    <Box className={className} ref={chartContainerRef} component="div">
      <Stack 
        style={{ width: "100%", height: "100%" }} 
        direction="row"
        spacing={3}
      >
        {notRenderedData.length ? (
          <ChartNotRendered />
        ) : (
          <>
            <Box
              component="div"
              style={{
                maxHeight: "400px",
                width: "80%",
                height: schema ? "calc(100% - 60px)" : "100%",
              }}
            >
              <ResponsivePie
                // @ts-ignore
                data={dynamicData}
                margin={chartMargin}
                enableArcLabels={isStatic ? false : enableArcLabels}
                enableArcLinkLabels={isStatic ? false : enableArcLinkLabels}
                colors={filteredData.map((item) => item?.color)}
                theme={{
                  text: {
                    fill: theme.palette.common.black,
                  },
                  tooltip: {
                    container: {
                      background: theme.palette.common.white,
                    },
                  },
                }}
                animate={!noAnimation}
                startAngle={-180}
                padAngle={0.7}
                cornerRadius={3}
                innerRadius={isDonut ? 0.5 : 0}
                activeOuterRadiusOffset={8}
                borderWidth={1}
                borderColor={{
                  from: "color",
                  modifiers: [["darker", 0.2]],
                }}
                arcLinkLabelsSkipAngle={10}
                arcLinkLabelsThickness={2}
                arcLinkLabelsColor={{ from: "color" }}
                arcLabelsSkipAngle={10}
                arcLabelsTextColor={
                  getCurrentColorScheme() === DynamicGridChartType.GIANT_PALETTE_ONE ||
                  getCurrentColorScheme() === DynamicGridChartType.GIANT_PALETTE_TWO
                    ? {
                        from: "color",
                        modifiers: [["darker", 10]],
                      }
                    : undefined
                }
                // arcLinkLabel={(d) => `(${d.label})`}
                arcLabel={(d) => {
                  const result = formatNumber(
                    d.value,
                    configuration.fixedDecimalValue,
                    configuration.decimalPoints
                  );
                  if (!result) {
                    return `${d.value}`;
                  }
                  return `${result}`;
                }}
                defs={[
                  {
                    id: "dots",
                    type: "patternDots",
                    background: "inherit",
                    color: "rgba(255, 255, 255, 0.3)",
                    size: 4,
                    padding: 1,
                    stagger: true,
                  },
                  {
                    id: "lines",
                    type: "patternLines",
                    background: "inherit",
                    color: "rgba(255, 255, 255, 0.3)",
                    rotation: -45,
                    lineWidth: 6,
                    spacing: 10,
                  },
                ]}
                isInteractive={isStatic ? false : true}
                fill={[
                  {
                    match: {
                      id: "ruby",
                    },
                    id: "dots",
                  },
                  {
                    match: {
                      id: "c",
                    },
                    id: "dots",
                  },
                  {
                    match: {
                      id: "go",
                    },
                    id: "dots",
                  },
                  {
                    match: {
                      id: "python",
                    },
                    id: "dots",
                  },
                  {
                    match: {
                      id: "scala",
                    },
                    id: "lines",
                  },
                  {
                    match: {
                      id: "lisp",
                    },
                    id: "lines",
                  },
                  {
                    match: {
                      id: "elixir",
                    },
                    id: "lines",
                  },
                  {
                    match: {
                      id: "javascript",
                    },
                    id: "lines",
                  },
                ]}
                tooltip={(datum) => (
                  <CustomTooltip
                    data={datum.datum}
                    tooltipColor={datum.datum.color}
                    renderContent={renderPieTooltipContent}
                  />
                )}
              />
            </Box>

            {paramMapping ? (
              <Box
                component="div"
                style={{ 
                  display: "flex", 
                  gap: "10px", 
                  flexDirection: "column", 
                  height: "93%" 
                }}
              >
                {
                  (configuration?.filtersToDashboard?.isLegendEnabled ?? true) &&
                  <PieChartLegend
                    title="Legend"
                    customLegends={customLegends}
                    width={100}
                    schema={schema}
                    paramMapping={paramMapping}
                    handleToggleVisibility={handleToggleVisibility}
                  />
                }
              </Box>
            ) : null}
          </>
        )}
      </Stack>
    </Box>
  );
};

export default memo(PieChart);

export const getDownSampledData = (data: PieDonutChartData) => {
  const dynamicData = data.slice(0, MAX_DATA_BARS);
  const notRenderedData: PieDonutChartData =
    data.length > MAX_DATA_BARS ? data.slice(MAX_DATA_BARS, data.length) : [];

  return { dynamicData, notRenderedData };
};
