import { Tooltip, Box, Typography, Stack, Grid, useTheme, Theme } from "@mui/material";
import { useState, useEffect } from "react";
import { useLanguageContext } from "../../../context/LanguageContext";
import cssLayoutStyles from "../../../Global/Styles/layout";
import cssSpacingStyles from "../../../Global/Styles/spacing";
import {
  AutocompleteGroupedOption,
  SelectOption,
} from "../../../Global/Types/commonTypes";
import { handleGetSelectOption } from "../../../Global/Utils/commonFunctions";
import { ExcellenceParameter } from "../../../GraphQL/Excellence/graphQLTypesExcellence";
import AutocompleteGrouped from "../../MaterialUI/FormFields/AutocompleteGrouped";
import GraphqlFilters from "../../SmallComponents/GraphqlFilters/GraphqlFilters";
import {
  GraphqlFilter,
  handleExcellenceParamToGraphqlFilters,
} from "../../SmallComponents/GraphqlFilters/graphqlFiltersUtils";
import {
  ChartConfigFormValuesWithTitle,
  ExcellenceParamMapping,
} from "../EditExcellenceChartForms/excellenceChartFormUtils";
import { css } from "@emotion/react";
import Autocomplete from "../../MaterialUI/FormFields/Autocomplete";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Alert from "../../MaterialUI/Alert";
import {
  GaugeParameter,
  GaugeWidgetConfiguration,
  GaugeWidgetDataSchema,
} from "./gaugeWidgetUtils";

const cssStyles = (theme: Theme) => ({
  autocompleteGroup: css({
    position: "sticky",
    top: "-8px",
    padding: "4px 10px",
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.secondary.light,
  }),
  autocompleteList: css({
    padding: 0,
  }),
});
type ParamRow = AutocompleteGroupedOption | null;
const EMPTY_PARAM_ROW: ParamRow = null;

interface EditGaugeWidgetDataSchemaProps {
  parameters: ExcellenceParameter[];
  dataSchema: GaugeWidgetDataSchema | null;
  handleUpdateDataSchema: (schema: GaugeWidgetDataSchema | null) => void;
  config: ChartConfigFormValuesWithTitle<GaugeWidgetConfiguration>;
  setUpdatedConfig: React.Dispatch<
    React.SetStateAction<ChartConfigFormValuesWithTitle<GaugeWidgetConfiguration>>
  >;
  selectedConnections: SelectOption[];
  setSelectedConnections: React.Dispatch<React.SetStateAction<SelectOption[]>>;
  graphqlFilters: GraphqlFilter[];
  setGraphqlFilters: React.Dispatch<React.SetStateAction<GraphqlFilter[]>>;
  initialSetupComplete: boolean;
  setInitialSetupComplete: React.Dispatch<React.SetStateAction<boolean>>;
  paramMapping: ExcellenceParamMapping | null;
}

const EditGaugeWidgetDataSchema: React.FC<EditGaugeWidgetDataSchemaProps> = ({
  parameters,
  dataSchema,
  handleUpdateDataSchema,
  selectedConnections,
  setSelectedConnections,
  graphqlFilters,
  setGraphqlFilters,
  initialSetupComplete,
  setInitialSetupComplete,
  paramMapping,
}) => {
  const theme = useTheme();
  const styles = {
    ...cssSpacingStyles(theme),
    ...cssStyles(theme),
    ...cssLayoutStyles,
  };
  const { t } = useLanguageContext();
  // options
  const [nodeNumbOptions, setNodeNumbOptions] = useState<AutocompleteGroupedOption[]>([]);
  const [connectionOptions, setConnectionOptions] = useState<SelectOption[]>([]);
  // selected values
  const [selectedParam, setSelectedParam] = useState<ParamRow>(EMPTY_PARAM_ROW);

  // system-used
  const [reUpdateSchema, setReUpdateSchema] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string | null>(null);

  console.log(selectedConnections);

  useEffect(() => {
    if (dataSchema && paramMapping) {
      const selectedConnectionsSet: Set<string> = new Set();
      const param: AutocompleteGroupedOption = {
        value: dataSchema.parameterId,
        description:
          paramMapping?.[dataSchema.parameterId].paramName || dataSchema.parameterId,
        groupName: dataSchema.container,
      };
      selectedConnectionsSet.add(dataSchema.container);

      const selectedConnectionsArr: SelectOption[] = Array.from(
        selectedConnectionsSet
      ).map((item) => {
        const conn = Object.values(paramMapping).find(
          (param) => param.connection === item
        );
        const connName = conn?.connectionName || item;

        return {
          value: item,
          description: connName,
        };
      });

      setSelectedConnections(selectedConnectionsArr);
      setSelectedParam(param);
      setInitialSetupComplete(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paramMapping]);

  useEffect(() => {
    const connectionsRecord: Record<string, string> = {};
    parameters.forEach((item) => {
      connectionsRecord[item.container] = item.containerName;
    });

    const allConnectionOptions: SelectOption[] = Object.entries(connectionsRecord).map(
      ([key, value]) => ({
        value: key,
        description: value,
      })
    );

    handleGetSelectOption([...new Set(parameters.map((item) => item.container))]);
    setConnectionOptions(allConnectionOptions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parameters]);

  useEffect(() => {
    if (paramMapping) {
      const paramOptions = handleGetParamOptions(parameters, selectedConnections);
      const numbOnlyOptions = paramOptions.filter(
        (node) =>
          paramMapping?.[node.value]?.type && paramMapping[node.value].type === "number"
      );

      setNodeNumbOptions(numbOnlyOptions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedConnections, paramMapping]);

  useEffect(() => {
    if (
      initialSetupComplete &&
      selectedConnections.length &&
      selectedParam &&
      paramMapping
    ) {
      const param: GaugeParameter | null = {
        container: paramMapping[selectedParam.value].connection,
        parameterId: selectedParam.value,
      };

      if (param) {
        const updatedSchema: GaugeWidgetDataSchema = {
          ...param,
        };

        handleUpdateDataSchema(updatedSchema);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialSetupComplete, selectedParam, reUpdateSchema]);

  const handleOnConnectionChange = (val: SelectOption[]) => {
    setAlertMessage(null);
    setSelectedConnections(val || []);
    setInitialSetupComplete(true);
    setReUpdateSchema((prev) => !prev);
  };

  const handleOnParamChange = (val: AutocompleteGroupedOption) => {
    const newParam = val?.value
      ? {
          ...val,
        }
      : null;

    setSelectedParam(newParam);
    setInitialSetupComplete(true);
    setReUpdateSchema((prev) => !prev);
  };

  if (!parameters?.length) {
    return (
      <Box component="div">
        <Typography variant="h3" color="error">
          {t("You don't have access to any active connections")}
        </Typography>
      </Box>
    );
  }

  return (
    <Stack
      css={styles.contentBreak}
      spacing={3}
      alignItems="center"
      justifyContent="center"
    >
      <Autocomplete
        css={styles.width100}
        label={t("Selected Connections")}
        multiple
        options={connectionOptions}
        value={selectedConnections}
        handleOnChange={handleOnConnectionChange}
      />

      <Box css={styles.width100} component="div">
        <GraphqlFilters
          data={handleExcellenceParamToGraphqlFilters(parameters)}
          graphqlFilters={graphqlFilters}
          setGraphqlFilters={setGraphqlFilters}
          selectedContainers={selectedConnections.map((item) => item.value)}
          customOperations={[{ value: "EQ", description: "Equals" }]}
        />
      </Box>

      {selectedConnections.length ? (
        <Grid style={{ margin: 0 }} container spacing={3}>
          <Grid style={{ paddingLeft: theme.spacing(1.5) }} item xs={12}>
            <Stack direction="row" spacing={2} alignItems="center">
              <AutocompleteGrouped
                css={styles.width100}
                label={t("Parameter")}
                options={nodeNumbOptions}
                value={selectedParam}
                handleOnChange={handleOnParamChange}
              />
              <Tooltip title={t("Only parameters with numeric values are available")}>
                <InfoOutlinedIcon style={{ color: theme.palette.text.secondary }} />
              </Tooltip>
            </Stack>
          </Grid>
        </Grid>
      ) : null}

      <Alert
        css={[styles.reverseContentBreak, styles.contentBreak]}
        message={alertMessage}
        showAlert={!!alertMessage}
        severity={"warning"}
      />
    </Stack>
  );
};

export default EditGaugeWidgetDataSchema;

const handleGetParamOptions = (
  parameters: ExcellenceParameter[],
  selectedConnectionsArr: SelectOption[]
): AutocompleteGroupedOption[] => {
  const result =
    parameters
      .map((item) => ({
        groupName: item.container,
        value: item.id,
        description: item.name,
      }))
      .filter((node) =>
        selectedConnectionsArr.some((sub) => sub.value === node.groupName)
      ) || [];

  const resultWithGroupNames = result.map((item) => {
    const found = selectedConnectionsArr.find((conn) => conn.value === item.groupName);
    const name = found?.description || item.groupName;
    return {
      ...item,
      groupName: name,
    };
  });

  return resultWithGroupNames;
};
