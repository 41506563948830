import { Stack, Box, Typography, InputAdornment } from "@mui/material";
import Checkbox from "../../../MaterialUI/FormFields/Checkbox";
import TextField from "../../../MaterialUI/FormFields/TextFields";
import Button from "../../../MaterialUI/Button";
import { useEffect, useState } from "react";
import { FormStatuses, SelectOption } from "../../../../Global/Types/commonTypes";
import { PostQueryLabelRecognitionImageInput } from "../../../../Api/VisionControl/apiVisionControlInputs";
import { postQueryLabelRecognitionMasterImage } from "../../../../Api/VisionControl/apiVisionControlPostQueries";
import { PostQueryLabelRecognitionMasterImageSnippet } from "../../../../Api/VisionControl/apiVisionControlSnippets";
import callApi from "../../../../Api/callApi";
import Alert from "../../../MaterialUI/Alert";
import cssLayoutStyles from "../../../../Global/Styles/layout";
import { useAuthedContext } from "../../../../context/AuthContext";
import CameraOrDeviceUpload from "./CameraOrDeviceUpload";
import { FileWithPath } from "react-dropzone";
import Collapse from "../../../MaterialUI/Collapse";
import { useLanguageContext } from "../../../../context/LanguageContext";

interface UploadMasterLabelProps {
  allLanguage: SelectOption[];
  fetchAllLabels: (type: "all" | "master" | "tested") => Promise<void>;
}

const UploadMasterLabel: React.FC<UploadMasterLabelProps> = ({
  allLanguage,
  fetchAllLabels,
}) => {
  const { t } = useLanguageContext();
  const styles = {
    ...cssLayoutStyles,
  };
  const [selectedLanguage, setSelectedLanguage] = useState<SelectOption[]>([]);
  const [masterFormStatus, setMasterFormStatus] = useState<FormStatuses>(null);
  const [masterAlert, setMasterAlert] = useState<string | null>(null);
  const [masterFile, setMasterFile] = useState<FileWithPath | null>(null);
  const [masterLabelFilename, setMasterLabelFilename] = useState<string>("");

  const { setAuthedUser } = useAuthedContext();
  const languageMidpointIndex = Math.ceil(allLanguage.length / 2);

  /**
   * When file is uploaded, if filename input field
   * for this file is empty, autofill it
   */
  useEffect(() => {
    if (masterFile?.name) {
      if (!masterLabelFilename) {
        const nameArr = masterFile.name.split(".");
        const name = nameArr.slice(0, nameArr.length - 1).join(".");
        setMasterLabelFilename(name);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [masterFile]);

  const handleOnLanguageChange = (lang: SelectOption) => {
    if (selectedLanguage.some((item) => item.value === lang.value)) {
      setSelectedLanguage((prev) => prev.filter((item) => item.value !== lang.value));
    } else {
      setSelectedLanguage((prev) => [...prev, lang]);
    }
  };

  const handleUploadMasterLabel = async () => {
    try {
      if (!selectedLanguage.length) {
        setMasterFormStatus("warning");
        setMasterAlert(t("You must select at least one language"));
        return;
      }
      if (!masterFile) {
        setMasterFormStatus("warning");
        setMasterAlert(t("You must upload an original label"));
        return;
      }
      if (!masterLabelFilename) {
        setMasterFormStatus("warning");
        setMasterAlert(t("You must specify an image name"));
        return;
      }
      setMasterFormStatus("loading");
      setMasterAlert(t("Loading..."));

      const input: PostQueryLabelRecognitionImageInput = {
        languages: selectedLanguage.map((item) => item.value),
        image: masterFile,
        file_name: masterLabelFilename,
      };
      const masterLabel = await callApi<PostQueryLabelRecognitionMasterImageSnippet>({
        query: postQueryLabelRecognitionMasterImage(input),
        auth: { setAuthedUser },
      });

      if (!masterLabel?.url) {
        throw new Error(JSON.stringify(masterLabel));
      }
      await fetchAllLabels("master");

      setMasterFormStatus("success");
      setMasterAlert(t("Success"));
      setMasterFile(null);
      setSelectedLanguage([]);
      setMasterLabelFilename("");
    } catch (err) {
      setMasterFormStatus("error");
      setMasterAlert(t("Something went wrong"));
      console.log("handleUploadMasterLabel err", err);
    }
  };

  return (
    <>
      <Stack spacing={3}>
        <Box component="div">
          <Typography variant="body1" color="textSecondary">
            {t("Select Languages")}
          </Typography>
          <Stack direction="row" spacing={6}>
            <Box component="div">
              {allLanguage.slice(0, languageMidpointIndex).map((item) => (
                <Box component="div" key={item.value}>
                  <Checkbox
                    label={t(item.description)}
                    checked={selectedLanguage.some((lang) => item.value === lang.value)}
                    onChange={() => handleOnLanguageChange(item)}
                  />
                </Box>
              ))}
            </Box>
            <Box component="div">
              {allLanguage.slice(languageMidpointIndex).map((item) => (
                <Box component="div" key={item.value}>
                  <Checkbox
                    label={t(item.description)}
                    checked={selectedLanguage.some((lang) => item.value === lang.value)}
                    onChange={() => handleOnLanguageChange(item)}
                  />
                </Box>
              ))}
            </Box>
          </Stack>
        </Box>

        <Collapse in={!!masterFile}>
          <TextField
            css={styles.width100}
            value={masterLabelFilename}
            label={t("Friendly image name")}
            onChange={(e) => setMasterLabelFilename(e.target.value)}
            InputProps={
              masterFile
                ? {
                    endAdornment: (
                      <InputAdornment position="end">
                        {`.${masterFile.type.split("/")[1]}`}
                      </InputAdornment>
                    ),
                  }
                : undefined
            }
          />
        </Collapse>

        <CameraOrDeviceUpload file={masterFile} setFile={setMasterFile} />

        <Alert
          message={masterAlert}
          showAlert={!!masterAlert}
          severity={masterFormStatus}
        />
        <Box component="div" css={styles.flexCenter}>
          <Button
            css={[styles.widthLimit15, styles.width100]}
            onClick={handleUploadMasterLabel}
          >
            {t("Upload Label")}
          </Button>
        </Box>
      </Stack>
    </>
  );
};

export default UploadMasterLabel;
