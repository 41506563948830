import { Query } from "../callApi";
import { PostQueryCreateLocationInput } from "./apiLocationsInputs";

export const postQueryCreateLocation = (input: PostQueryCreateLocationInput): Query => ({
  endpoint: `warehouse/locations/locations`,
  method: "POST",
  variables: input,
});

export const putQueryLocation = (
  input: PostQueryCreateLocationInput,
  location_id: string
): Query => ({
  endpoint: `warehouse/locations/locations/${location_id}`,
  method: "PUT",
  variables: input,
});

export const postQueryDeleteLocation = (location_id: string): Query => ({
  endpoint: `warehouse/locations/locations/${location_id}`,
  method: "DELETE",
});
