import { useState } from "react";
import { LabelImage } from "../../../../Api/VisionControl/apiVisionControlSnippets";
import { handleGetSelectOption } from "../../../../Global/Utils/commonFunctions";
import useTheme from "@mui/material/styles/useTheme";
import cssSpacingStyles from "../../../../Global/Styles/spacing";
import Select from "../../../MaterialUI/FormFields/Select";
import { Box, IconButton, InputAdornment, Stack, useMediaQuery } from "@mui/material";
import { SelectOption } from "../../../../Global/Types/commonTypes";
import TextField from "../../../MaterialUI/FormFields/TextFields";
import cssLayoutStyles from "../../../../Global/Styles/layout";
import CloseIcon from "@mui/icons-material/Close";
import { LabelSortOption, labelsSortByValue } from "./labelRecognitionUtils";
import { useLanguageContext } from "../../../../context/LanguageContext";
import { useTranslateSchemeArray } from "../../../../Global/Hooks/useTranslations";

type LabelType = "All labels" | "Original labels" | "Sample labels";
type StackDirection = "row" | "column";
const IMAGE_VIEW_OPTIONS: LabelType[] = [
  "All labels",
  "Original labels",
  "Sample labels",
];
const SORT_BY_OPTIONS: LabelSortOption[] = [
  "Newest",
  "Oldest",
  "Alphabetic",
  "Alphabetic reverse",
];

const INITIAL_LABEL_TYPE = "All labels";
const INITIAL_SORT_BY = "Newest";
type FilterType = "labelType" | "langs" | "sortBy";

interface LabelImageListFiltersProps {
  allLabels: LabelImage[];
  setDisplayedLabels: React.Dispatch<React.SetStateAction<LabelImage[]>>;
  allLanguages: SelectOption[];
}

const LabelImageListFilters: React.FC<LabelImageListFiltersProps> = ({
  allLabels,
  setDisplayedLabels,
  allLanguages,
}) => {
  const { t } = useLanguageContext();
  const theme = useTheme();
  const smMediaQuery = useMediaQuery("(max-width:600px)");
  const styles = {
    ...cssSpacingStyles(theme),
    ...cssLayoutStyles,
  };
  const INITIAL_LANGS = allLanguages.map((lang) => lang.value);

  const [searchQuery, setSearchQuery] = useState<string>("");
  const [filteredLabels, setFilteredLabels] = useState<LabelImage[]>(allLabels);
  // filters and sort
  const [sortBy, setSortBy] = useState<LabelSortOption>(INITIAL_SORT_BY);
  const [labelType, setLabelType] = useState<LabelType>(INITIAL_LABEL_TYPE);
  const [filterLanguages, setFilterLanguages] = useState<string[]>(INITIAL_LANGS);

  const handleSearch = (query: string, labels?: LabelImage[]) => {
    const dataToUse = labels || filteredLabels;
    setSearchQuery(query);

    if (!query) {
      setDisplayedLabels(dataToUse);
    } else {
      setDisplayedLabels(() =>
        dataToUse.filter((label) =>
          label.image_name.toLowerCase().includes(query.toLowerCase())
        )
      );
    }
  };

  const handleApplyFilters = (newVal: string | string[], fieldType: FilterType) => {
    let result = [...allLabels];
    const newLabelType = fieldType === "labelType" ? newVal : labelType;
    const newLangs = fieldType === "langs" ? newVal : filterLanguages;
    const newSortBy = fieldType === "sortBy" ? (newVal as LabelSortOption) : sortBy;

    // filter by type
    if (newLabelType !== "All labels") {
      result = result.filter((item) => {
        if (newLabelType === "Original labels") {
          return item.bucket === "master";
        }
        if (newLabelType === "Sample labels") {
          return item.bucket === "tested";
        }
        return true;
      });
    }

    // filter by langs
    result = result.filter((item) => {
      return item.languages.some((lang) => newLangs.includes(lang));
    });

    // sort and return
    const sortedResult = labelsSortByValue(result, newSortBy);
    setFilteredLabels(() => sortedResult);
    handleSearch(searchQuery, sortedResult);

    // update the state value
    switch (fieldType) {
      case "labelType": {
        setLabelType(newVal as LabelType);
        break;
      }
      case "langs": {
        setFilterLanguages(newVal as string[]);
        break;
      }
      case "sortBy": {
        setSortBy(newVal as LabelSortOption);
        break;
      }
    }
  };

  // const handleResetFilters = () => {
  //   setSortBy(INITIAL_SORT_BY);
  //   setLabelType(INITIAL_LABEL_TYPE);
  //   setFilterLanguages(INITIAL_LANGS);
  //   setSearchQuery("");
  // };

  const selectWidthStyle = smMediaQuery ? { width: "100%" } : null;
  const stackStyles = smMediaQuery
    ? { spacing: 2, direction: "column", alignItems: "stretch" }
    : { spacing: 4, direction: "row", alignItems: "center" };

  return (
    <Box component="div" css={styles.sectionBreak}>
      <Stack
        spacing={stackStyles.spacing}
        direction={stackStyles.direction as StackDirection}
        alignItems={stackStyles.alignItems}
      >
        <TextField
          css={styles.widthLimit30}
          label={t("Search Labels")}
          value={searchQuery}
          onChange={(e) => handleSearch(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="clear search field"
                  onClick={() => handleSearch("")}
                  edge="end"
                >
                  <CloseIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <Select
          css={selectWidthStyle || styles.widthLimit10}
          selectOptions={useTranslateSchemeArray(
            handleGetSelectOption(IMAGE_VIEW_OPTIONS)
          )}
          label={t("Label Type")}
          value={labelType}
          onChange={(e) => handleApplyFilters(e.target.value, "labelType")}
        />
        <Select
          css={selectWidthStyle || styles.widthLimit35}
          selectOptions={useTranslateSchemeArray(allLanguages)}
          label={t("Languages")}
          value={filterLanguages}
          onChange={(e) => handleApplyFilters(e.target.value, "langs")}
          multiple
        />
        <Select
          css={selectWidthStyle || styles.widthLimit15}
          selectOptions={useTranslateSchemeArray(handleGetSelectOption(SORT_BY_OPTIONS))}
          label={t("Sort by")}
          value={sortBy}
          onChange={(e) => handleApplyFilters(e.target.value, "sortBy")}
        />
      </Stack>
    </Box>
  );
};

export default LabelImageListFilters;
