import { useState } from "react";
import { MaintenanceMachineTask } from "../../../../../Api/Maintenance/apiMaintenanceDataTypes";
import { GetQueryMaintenanceMachinesSnippet } from "../../../../../Api/Maintenance/apiMaintenanceSnippets";
import Modal from "../../../../MaterialUI/Modal";
import MaintenancePlanTable from "../MaintenancePlanTable/MaintenancePlanTable";
import {
  MaintenancePlanAddEditFormResult,
  MaintenancePlanModalType,
  MaintenancePlanTableTaskRow,
  handleGetMaintenancePlanTableRows,
} from "../maintenancePlanUtils";
import MaintenancePlanComments from "./MaintenancePlanComments";
import MaintenancePlanDeleteForm from "./MaintenancePlanDeleteForm";
import MaintenancePlanDocuments from "./MaintenancePlanDocuments";
import MaintenancePlanEditForm from "./MaintenancePlanEditForm";
import MaintenancePlanResponsible from "./MaintenancePlanResponsible";
import MaintenancePlanWorkingHours from "./MaintenancePlanWorkingHours";
import { useLanguageContext } from "../../../../../context/LanguageContext";

interface MaintenancePlanModalProps {
  modalData: MaintenancePlanModalType;
  machines: GetQueryMaintenanceMachinesSnippet;
  task: MaintenanceMachineTask;
  setModalType: React.Dispatch<React.SetStateAction<MaintenancePlanModalType>>;
  handleSubmit: (value?: MaintenancePlanAddEditFormResult) => Promise<void>;
}

const MaintenancePlanModal: React.FC<MaintenancePlanModalProps> = ({
  modalData,
  setModalType,
  machines,
  task,
  handleSubmit,
}) => {
  const { t } = useLanguageContext();
  const infoRows: MaintenancePlanTableTaskRow[] = handleGetMaintenancePlanTableRows(
    setModalType,
    [task]
  );
  const [unsavedChanges, setUnsavedChanges] = useState<boolean>(false);

  const handleOnSubmit = async (value?: MaintenancePlanAddEditFormResult) => {
    await handleSubmit(value);
  };

  const handleSetUnsavedChanges = (unsavedChanges: boolean) => {
    if (unsavedChanges) {
      setUnsavedChanges(true);
    }
  };

  return (
    <Modal
      open={modalData !== null}
      fullWidth
      label={modalData ? t(modalData.type) : ""}
      onClose={() => setModalType(null)}
      unsavedChanges={unsavedChanges}
      setUnsavedChanges={setUnsavedChanges}
    >
      {modalData ? (
        <>
          {modalData.type === "Task Comments" || modalData.type === "Add Comment" ? (
            <MaintenancePlanComments
              task={task}
              handleChange={handleOnSubmit}
              isAddComment={modalData.type === "Add Comment"}
            />
          ) : null}

          {modalData.type === "Task Documents" || modalData.type === "Add Document" ? (
            <MaintenancePlanDocuments
              task={task}
              handleChange={handleOnSubmit}
              isAddNew={modalData.type === "Add Document"}
            />
          ) : null}

          {modalData.type === "Maintenance Responsible" ||
          modalData.type === "Add Responsible" ? (
            <MaintenancePlanResponsible
              task={task}
              handleChange={handleOnSubmit}
              isAddNew={modalData.type === "Add Responsible"}
            />
          ) : null}

          {modalData.type === "Task Information" ? (
            <MaintenancePlanTable rows={infoRows} hideColumns={["actions"]} mobileView />
          ) : null}

          {modalData.type === "Edit Task" ? (
            <MaintenancePlanEditForm
              machines={machines}
              task={task}
              handleOnEdit={handleOnSubmit}
              handleSetUnsavedChanges={handleSetUnsavedChanges}
              setUnsavedChanges={setUnsavedChanges}
            />
          ) : null}

          {modalData.type === "Delete Task" ? (
            <MaintenancePlanDeleteForm task={task} handleOnDelete={handleOnSubmit} />
          ) : null}

          {modalData.type === "Working Hours" ||
          modalData.type === "Add Working Hours" ? (
            <MaintenancePlanWorkingHours
              task={task}
              handleChange={handleOnSubmit}
              isAddNew={modalData.type === "Add Working Hours"}
            />
          ) : null}
        </>
      ) : (
        <></>
      )}
    </Modal>
  );
};

export default MaintenancePlanModal;
