import { SerializedStyles } from "@emotion/react";
import { AutocompleteOption, SelectOption } from "../../../Global/Types/commonTypes";
import { DynamicGridHandleSaveChartData } from "../../SmallComponents/DynamicGridLayout.tsx/dynamicGridUtils";
import { handleGetSelectOption } from "../../../Global/Utils/commonFunctions";
import { TableGridColumnSchema } from "../../SmallComponents/TableGrid/constructTableGrid";
import {
  ExcellenceChartDataSchema,
  ExcellenceParameter,
} from "../../../GraphQL/Excellence/graphQLTypesExcellence";
import { UnitOfMeasure } from "../../../Api/Excellence/apiExcellenceSnippets";
import { GraphqlFilter } from "../../SmallComponents/GraphqlFilters/graphqlFiltersUtils";

export interface ExcellenceChartFormProps {
  chartClass: SerializedStyles;
  widgetTitle: string;
  handleSaveChanges: DynamicGridHandleSaveChartData;
  parameters: { parameters: ExcellenceParameter[] } | null;
  dataSchema: ExcellenceChartDataSchema | null;
  handleSetUnsavedChanges: (unsavedChanges: boolean) => void;
}
export interface ExcellenceDataSchemaForm {
  parameters: { parameters: ExcellenceParameter[] } | null;
  dataSchema: ExcellenceChartDataSchema | null;
}

export type ChartConfigFormValuesWithTitle<T> = T & {
  title: string;
};

export type ChartColorScheme = "paired" | "accent" | "blues" | "greens";
const colorSchemeArray: ChartColorScheme[] = ["paired", "accent", "blues", "greens"];
export const chartColorSchemeOptions: SelectOption[] = handleGetSelectOption(
  colorSchemeArray,
  true
);

export type GaugeChartColorScheme = "Theme colors" | "Custom set";
const gaugeColorSchemeArray: GaugeChartColorScheme[] = ["Theme colors", "Custom set"];
export const gaugeChartColorSchemeOptions: SelectOption[] =
  handleGetSelectOption(gaugeColorSchemeArray);

export type ColorsSequence1 = "green" | "yellow" | "red";
export type ColorsSequence2 = "green-red" | "red-green";
export type ColorsSequence3 =
  | "green-yellow-red"
  | "red-yellow-green"
  | "red-green-red"
  | "green-red-green";
export type ColorsSequence4 = ColorsSequence2;
export type ColorsSequence5 = ColorsSequence3;

export type CustomSetSequenceChartColorScheme =
  | ColorsSequence1
  | ColorsSequence2
  | ColorsSequence3
  | ColorsSequence4
  | ColorsSequence5;

const colorsSequenceMapping: Record<string, string[]> = {
  "1": ["green", "yellow", "red"],
  "2": ["green-red", "red-green"],
  "3": ["green-yellow-red", "red-yellow-green", "red-green-red", "green-red-green"],
  "4": ["green-red", "red-green"],
  "5": ["green-yellow-red", "red-yellow-green", "red-green-red", "green-red-green"],
};

export const getCustomSetSequenceColorSchemeOptions = (
  sections: string
): SelectOption[] => {
  const options: SelectOption[] = colorsSequenceMapping[sections].map(
    (colorSequence) => ({
      value: colorSequence,
      description: colorSequence,
    })
  );
  return options;
};

export type ChartSectionsNumberScheme = "1" | "2" | "3" | "4" | "5";
const sectionsNumberSchemeArray: ChartSectionsNumberScheme[] = ["1", "2", "3", "4", "5"];
export const chartSectionsNumberShemeOptions: SelectOption[] = handleGetSelectOption(
  sectionsNumberSchemeArray
);

type DatumValue = string | number;
type Datum = {
  x: DatumValue;
  y: number;
  [key: string]: DatumValue;
};

// DATA FOR: Line / Area / Time Charts
export type LineAreaHeatMapScatterPlotData = {
  id: string;
  name: string;
  data: Datum[];
}[];

// DATA FOR: Pie / Donut
export type PieDonutChartData = {
  id: string;
  label: string;
  value: number;
}[];

// DATA FOR: Bar / Column / Radar
export type BarColumnRadarData = {
  [key: string]: DatumValue;
}[];

// DATA FOR: Excellence Table
export type ExcellenceTableData = {
  rows: Record<string, any>[];
  columns: TableGridColumnSchema[];
};

// DATA FOR: Gauge Widget
export type GaugeWidgetData = {
  currentValue: number;
};

// DATA FOR: Text Widget
export type TextWidgetData = string;

// DATA FOR: Indicator Widget
export type IndicatorWidgetData = {
  latest: number;
  average: number;
  trendAverage: string;
  trendLatest: string;
  chart: number[];
};

export const EMPTY_UNIT = "EMPTY";
export const unitsOfMeasureOptions = (
  units: UnitOfMeasure[] | null
): AutocompleteOption[] => {
  if (!units?.length) {
    return [];
  }

  const empty: AutocompleteOption = { value: EMPTY_UNIT, description: "Set to Empty" };

  const options: AutocompleteOption[] = units.map((item) => ({
    value: item.id,
    description: `${item.name} (${item.symbol})`,
  }));

  return [empty, ...options];
};

export type ExcellenceChartProps = {
  css?: SerializedStyles[] | SerializedStyles;
  className?: string;
  resetChartFetch: boolean;
  dataFetchFromParent?: boolean;
  parameters?: ExcellenceParameter[];
  isStatic?: boolean;
  noAnimation?: boolean;
  tempFilters: GraphqlFilter[];
  refetchOnFilters?: boolean;
};

export type ExcellenceParamMapping = Record<
  string,
  {
    connection: string;
    connectionName: string;
    paramID: string;
    paramName: string;
    type: string;
  }
>;

export type ExcellenceMargin = {
  top: number;
  right: number;
  bottom: number;
  left: number;
};
