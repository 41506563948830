import { useQuill } from "react-quilljs";
import "quill/dist/quill.snow.css";
import { Box, Theme, Typography } from "@mui/material";
import useTheme from "@mui/material/styles/useTheme";
import { css, SerializedStyles } from "@emotion/react";
import { KanbanTemplateFile } from "../../../pages/TaskManager/Components/taskManagerUtils";
import { useEffect, useState } from "react";

const cssStyles = (theme: Theme, error?: boolean) => ({
  editorWrap: css({
    width: "100%",
    height: "100%",
    minHeight: "200px",
    maxHeight: "500px",
    fontFamily: `${theme.typography.fontFamily} !important`,
    ".ql-container.ql-snow": css({
      border: `1px solid ${error ? theme.palette.error.main : "rgba(0, 0, 0, 0.23)"}`,
    }),
    ".ql-stroke": css({
      stroke: `${theme.palette.text.secondary} !important`,
    }),
    ".ql-fill": css({
      fill: `${theme.palette.text.secondary} !important`,
    }),

    ".ql-toolbar.ql-snow": css({
      border: `1px solid ${error ? theme.palette.error.main : "rgba(0, 0, 0, 0.23)"}`,
      ".ql-picker": {
        color: `${theme.palette.text.primary} !important`,
      },
      ".ql-active": {
        color: `${theme.palette.primary.main} !important`,
        ".ql-stroke": {
          stroke: `${theme.palette.primary.main} !important`,
        },
        ".ql-fill": {
          fill: `${theme.palette.primary.main} !important`,
        },
      },
    }),
    ".ql-picker-item.ql-selected": css({
      color: `${theme.palette.primary.main} !important`,
    }),

    // hover
    ".ql-picker-label:hover": css({
      color: `${theme.palette.secondary.main} !important`,
      ".ql-stroke": {
        stroke: `${theme.palette.secondary.main} !important`,
      },
      ".ql-fill": {
        fill: `${theme.palette.secondary.main} !important`,
      },
    }),
    ".ql-picker-item:hover": css({
      color: `${theme.palette.secondary.main} !important`,
      ".ql-stroke": {
        stroke: `${theme.palette.secondary.main} !important`,
      },
      ".ql-fill": {
        fill: `${theme.palette.secondary.main} !important`,
      },
    }),
    ".ql-snow.ql-toolbar button:hover": css({
      color: `${theme.palette.secondary.main} !important`,

      ".ql-stroke": {
        stroke: `${theme.palette.secondary.main} !important`,
      },
      ".ql-fill": {
        fill: `${theme.palette.secondary.main} !important`,
      },
    }),
    ".ql-picker-options": css({
      background: `${theme.palette.background.default} !important`,
    }),
  }),
  quillEditor: css({
    p: {
      marginBottom: "1rem",
      marginTop: "1rem",
    },
  }),
  editor: css({
    width: "100%",
    height: "80%",
  }),
  relativeBox: css({
    position: "relative",
    width: "100%",
    height: "100%",
  }),
  label: css({
    paddingLeft: "10px",
    marginBottom: "5px",
  }),
  helperText: css({
    marginLeft: "1rem",
  }),
});

interface QuillEditorProps {
  css?: SerializedStyles[] | SerializedStyles;
  className?: string;
  editorState: string;
  onEditorStateChange: (state: string) => void;
  label?: string;
  placeholder?: string;
  error?: boolean | string;
  disabled?: boolean;
  handleUploadImage?: (file: File) => Promise<KanbanTemplateFile | string>;
  handleUploadVideo?: (file: File) => Promise<string>;
}

const QuillEditor: React.FC<QuillEditorProps> = ({
  editorState,
  onEditorStateChange,
  label,
  placeholder,
  error,
  className,
  disabled,
  handleUploadImage,
  handleUploadVideo,
}) => {
  const modules = {
    toolbar: [
      [{ size: [] }],
      ["bold", "italic", "underline", "strike"],
      [{ color: [] }, { background: [] }],
      [{ list: "ordered" }, { list: "bullet" }],
      [...(handleUploadImage ? ["image"] : [])],
      [...(handleUploadVideo ? ["video"] : [])],
      [{ align: [] }],
    ],
  };

  const theme = useTheme();
  const styles = cssStyles(theme, !!error);
  const { quill, quillRef } = useQuill({ placeholder, modules });
  const [wrapFocus, setWrapFocus] = useState(false);

  useEffect(() => {
    if (quill) {
      if (editorState) {
        quill.clipboard.dangerouslyPasteHTML(editorState);
      }
      quill.on("text-change", () => {
        onEditorStateChange(quill.root.innerHTML);
      });
      quill.enable(!disabled);
      // Listen for focus and blur events
      quill.on("selection-change", (range) => {
        if (range) {
          setWrapFocus(true); // Editor is focused
        } else {
          setWrapFocus(false); // Editor is blurred
        }
      });

      if (handleUploadImage) {
        // @ts-ignore
        quill.getModule("toolbar").addHandler("image", selectLocalImage);
      }

      if (handleUploadVideo) {
        // @ts-ignore
        quill.getModule("toolbar").addHandler("video", selectLocalVideo);
      }

      return () => {
        quill.off("text-change");
        quill.off("selection-change");
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quill, disabled]);

  // Upload Image to Image Server
  const saveToServer = async (file: File, type: "image" | "video") => {
    const handler = type === "image" ? handleUploadImage : handleUploadVideo;

    if (!handler || !quill) {
      return "";
    }

    // 1. handle upload
    const res = await handler(file);

    // 2. insert to editor
    const range = quill.getSelection();

    if (range) {
      if (type === "image") {
        if (typeof res === "string") {
          quill.insertEmbed(range!.index, "image", `https://${res}`);
          return res;
        } else if (res && (res as KanbanTemplateFile).url) {
          quill.insertEmbed(range!.index, "image", (res as KanbanTemplateFile).url);
          return (res as KanbanTemplateFile).url;
        }
      } else if (type === "video") {
        quill.insertEmbed(range.index, "video", `https://${res}`);
      }
    }

    return "";
  };

  // Open Dialog to select Image File
  const selectLocalImage = () => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();

    input.onchange = () => {
      if (input?.files) {
        const file = input.files[0];
        saveToServer(file, "image");
      }
    };
  };

  // Open Dialog to select Video File
  const selectLocalVideo = () => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "video/*");
    input.click();

    input.onchange = () => {
      if (input?.files) {
        const file = input.files[0];
        saveToServer(file, "video");
      }
    };
  };

  return (
    <Box component="div" css={styles.relativeBox} className={className}>
      {label ? (
        <Typography
          css={styles.label}
          color={error ? "error" : wrapFocus ? "primary" : "textPrimary"}
          variant="body2"
        >
          {label}
        </Typography>
      ) : null}

      <Box component="div" css={styles.editorWrap}>
        <Box component="div" css={styles.editor}>
          <div ref={quillRef} css={styles.quillEditor} />
        </Box>
      </Box>
    </Box>
  );
};

export default QuillEditor;
