import { object } from "yup";
import { YUP_REQUIRED_STRING } from "../../../../../Global/Constants/yupConstants";
import { useState } from "react";
import { FormStatuses } from "../../../../../Global/Types/commonTypes";
import { useFormik } from "formik";
import TextField from "../../../../MaterialUI/FormFields/TextFields";
import {
  Box,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Stack,
} from "@mui/material";
import Button from "../../../../MaterialUI/Button";
import Alert from "../../../../MaterialUI/Alert";
import useTheme from "@mui/material/styles/useTheme";
import cssLayoutStyles from "../../../../../Global/Styles/layout";
import cssSpacingStyles from "../../../../../Global/Styles/spacing";
import { v4 as uuidv4 } from "uuid";
import { ModbusMappingRow } from "./modbusUtils";
import LabelWithBoldedPart from "../../../../MaterialUI/LabelWithBoldedPart";
import Collapse from "../../../../MaterialUI/Collapse";
import callApi from "../../../../../Api/callApi";
import { getQueryModbusRegister } from "../../../../../Api/DataSources/apiDSGetQueries";
import { useAuthedContext } from "../../../../../context/AuthContext";
import { GetQueryModbusRegisterInput } from "../../../../../Api/DataSources/apiDSInputs";
import { GetQueryModbusRegisterSnippet } from "../../../../../Api/DataSources/apiDSSnippets";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Modal from "../../../../MaterialUI/Modal";
import cssComponentsStyles from "../../../../../Global/Styles/components";
import { useLanguageContext } from "../../../../../context/LanguageContext";

const infoList = [
  "Temperature in °C (float), starting address: 0",
  "Pressure in bar (float), starting address: 2",
  "Humidity in % (float), starting address: 4",
  "Flow Rate in L/min (float), starting address: 6",
  "Voltage in V (float), starting address: 8",
  "Rotation Speed in RPM (int16) starting address: 10",
  "Vibration Level in mm/s (float), starting address: 12",
  "Operational Time in h (int16), starting address: 14",
];

const fieldValidation = object({
  name: YUP_REQUIRED_STRING,
  address: YUP_REQUIRED_STRING,
  type: YUP_REQUIRED_STRING,
  unit: YUP_REQUIRED_STRING,
});

type FormValues = Omit<ModbusMappingRow, "id">;

interface ModbusMappingFormProps {
  setRows: React.Dispatch<React.SetStateAction<ModbusMappingRow[]>>;
  editRow: ModbusMappingRow | null;
  handleCloseModal: () => void;
  host: string;
  port: number;
}

const ModbusMappingForm: React.FC<ModbusMappingFormProps> = ({
  setRows,
  editRow,
  handleCloseModal,
  host,
  port,
}) => {
  const { t } = useLanguageContext();
  const theme = useTheme();
  const styles = {
    ...cssLayoutStyles,
    ...cssSpacingStyles(theme),
    ...cssComponentsStyles(theme),
  };
  const [formStatus, setFormStatus] = useState<FormStatuses>(null);
  const [alertMessage, setAlertMessage] = useState<string | null>(null);
  const [registerValue, setRegisterValue] = useState<string | null>(null);
  const [infoModal, setInfoModal] = useState<boolean>(false);

  const { setAuthedUser } = useAuthedContext();
  const initialValues: FormValues = {
    name: editRow?.name || "",
    address: editRow?.address !== undefined ? editRow.address : "",
    type: editRow?.type || "",
    unit: editRow?.unit || "",
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: fieldValidation,
    onSubmit: async (values) => {
      if (editRow) {
        // edit existing row
        setRows((prev) =>
          prev.map((item) => {
            if (item.id === editRow.id) {
              return {
                ...editRow,
                name: values.name,
                address: values.address,
                type: values.type,
                unit: values.unit,
              };
            }
            return item;
          })
        );
      } else {
        // add new row
        setRows((prev) => [
          ...prev,
          {
            id: uuidv4().split("-")[0],
            name: values.name,
            address: values.address,
            type: values.type,
            unit: values.unit,
          },
        ]);
      }

      handleCloseModal();
    },
  });

  const handleGetRegisterValue = async () => {
    try {
      setFormStatus("loading");
      setAlertMessage(t("Loading..."));
      const type = formik.values.type;
      const address = formik.values.address;
      const input: GetQueryModbusRegisterInput = {
        host,
        port,
        address: address as number,
        type,
      };
      const result = await callApi<GetQueryModbusRegisterSnippet>({
        query: getQueryModbusRegister(input),
        auth: { setAuthedUser },
      });
      const stringResult = result.detail;
      setRegisterValue(stringResult);

      setFormStatus(null);
      setAlertMessage(null);
    } catch (err) {
      setFormStatus("warning");
      setAlertMessage(t("Invalid parameters"));
      console.log("useEffect err ", err);
    }
  };

  const handleDeleteRow = () => {
    if (editRow?.id) {
      setRows((prev) => prev.filter((item) => item.id !== editRow.id));
    }
    handleCloseModal();
  };

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Grid css={styles.contentBreak} container spacing={3}>
          <Grid item xs={12} sm={8}>
            <TextField
              name="name"
              label={t("Name")}
              error={formik.touched["name"] && !!formik.errors["name"]}
              helperText={formik.touched["name"] && formik.errors["name"]}
              onChange={formik.handleChange}
              value={formik.values.name}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              name="unit"
              label={t("Unit")}
              error={formik.touched["unit"] && !!formik.errors["unit"]}
              helperText={formik.touched["unit"] && formik.errors["unit"]}
              onChange={formik.handleChange}
              value={formik.values.unit}
            />
          </Grid>
          <Grid item xs={12} sm={8}>
            <TextField
              name="type"
              label={t("Type")}
              error={formik.touched["type"] && !!formik.errors["type"]}
              helperText={formik.touched["type"] && formik.errors["type"]}
              onChange={formik.handleChange}
              value={formik.values.type}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              name="address"
              label={t("Address")}
              error={formik.touched["address"] && !!formik.errors["address"]}
              helperText={formik.touched["address"] && formik.errors["address"]}
              onChange={formik.handleChange}
              value={formik.values.address}
              numberField
            />
          </Grid>
        </Grid>

        <Stack
          css={styles.contentBreak}
          direction="row"
          spacing={2}
          justifyContent="space-between"
        >
          <Button variant="outlined" type="button" onClick={handleGetRegisterValue}>
            {t("Get Register Value")}
          </Button>
          <Button variant="outlined" color="error" onClick={handleDeleteRow}>
            {t("Delete Mapping")}
          </Button>
        </Stack>

        <Collapse css={styles.contentBreak} in={registerValue !== null}>
          <LabelWithBoldedPart
            text={t("Register value")}
            bolded={registerValue !== null ? registerValue : ""}
          />
        </Collapse>

        <Alert
          css={styles.contentBreak}
          message={alertMessage || ""}
          showAlert={!!alertMessage}
          severity={formStatus}
        />

        <Stack spacing={1} direction="row" justifyContent="space-between">
          <IconButton onClick={() => setInfoModal(true)} type="button">
            <InfoOutlinedIcon css={styles.infoIcon} />
          </IconButton>
          <Button
            css={[styles.width100, styles.widthLimit10]}
            type="submit"
            loading={formStatus === "loading"}
          >
            {t("Submit")}
          </Button>
          <Box component="div" />
        </Stack>
      </form>

      <Modal
        open={infoModal}
        onClose={() => setInfoModal(false)}
        fullWidth
        label={t("Information")}
      >
        <List dense>
          {infoList.map((item) => (
            <ListItem key={item}>
              <ListItemText primary={item} />
            </ListItem>
          ))}
        </List>
      </Modal>
    </>
  );
};

export default ModbusMappingForm;
