import {
  PostQueryDAConnectInput,
  PostQueryDAEditSubscriptionInput,
  PostQueryDaNodesInput,
  PostQueryDAStartSubscriptionInput,
  PostQueryUAConnectInput,
  PostQueryUADeleteSubscriptionInput,
  PostQueryUAEditSubscriptionInput,
  PostQueryUANodeInput,
  PostQueryUAStartSubscriptionInput,
  PostQueryMQTTStartSubscriptionInput,
  PostQueryMQTTSubscriptionMappingInput,
  PostQueryModbusMapAddressInput,
  PostQueryModbusConnectInput,
  PostQueryModbusConnectionStartInput,
  PostQueryUABulkUploadConfigInput,
  PostQueryDABulkUploadConfigInput,
} from "./apiDSInputs";
import { Query } from "../callApi";

// ------------- OPC DA -------------
export const postQueryDAConnect = (input: PostQueryDAConnectInput): Query => ({
  endpoint: `integrator/opc_da/connect`,
  method: "POST",
  variables: input,
});
export const postQueryDaNodes = (input: PostQueryDaNodesInput): Query => ({
  endpoint: "integrator/opc_da/nodes",
  method: "POST",
  variables: input,
});
export const postQueryDAStartSubscription = (
  input: PostQueryDAStartSubscriptionInput
): Query => ({
  endpoint: `integrator/opc_da/subscription/start`,
  method: "POST",
  variables: input,
});
export const postQueryDADeleteSubscription = (subID: string): Query => ({
  endpoint: `integrator/opc_da/subscription/${subID}`,
  method: "DELETE",
});
export const postQueryDAEditSubscription = (
  input: PostQueryDAEditSubscriptionInput
): Query => ({
  endpoint: "integrator/opc_da/subscription/edit",
  method: "POST",
  variables: input,
});
export const postQueryDABulkUploadConfig = (
  input: PostQueryDABulkUploadConfigInput
): Query => ({
  endpoint: `integrator/opc_da/bulk-config`,
  method: "POST",
  variables: input,
  multipartForm: true,
});

// ------------- OPC UA -------------
export const postQueryUAConnect = (input: PostQueryUAConnectInput): Query => ({
  endpoint: `integrator/opc_ua/connect`,
  method: "POST",
  variables: input,
});
export const postQueryUANode = (input: PostQueryUANodeInput): Query => ({
  endpoint: `integrator/opc_ua/node`,
  method: "POST",
  variables: input,
});
export const postQueryUAStartSubscription = (
  input: PostQueryUAStartSubscriptionInput
): Query => ({
  endpoint: `integrator/opc_ua/subscription/start`,
  method: "POST",
  variables: input,
});
export const postQueryUAEditSubscription = (
  input: PostQueryUAEditSubscriptionInput
): Query => ({
  endpoint: `integrator/opc_ua/subscription/edit`,
  method: "POST",
  variables: input,
});
export const postQueryUADeleteSubscription = (
  input: PostQueryUADeleteSubscriptionInput
): Query => ({
  endpoint: `integrator/opc_ua/subscription/%7Bid%7D`,
  method: "DELETE",
  variables: input,
});

export const postQueryUABulkUploadConfig = (
  input: PostQueryUABulkUploadConfigInput
): Query => ({
  endpoint: `integrator/opc_ua/bulk-config`,
  method: "POST",
  variables: input,
  multipartForm: true,
});

// ------------- MQTT -------------
export const postQueryMQTTStartSubscription = (
  input: PostQueryMQTTStartSubscriptionInput
): Query => ({
  endpoint: `integrator/mqtt/subscription/start`,
  method: "POST",
  variables: input,
});
export const postQueryMQTTDeleteSubscription = (subID: string): Query => ({
  endpoint: `integrator/mqtt/subscription/${subID}`,
  method: "DELETE",
});
export const postQueryMQTTSubscriptionMapping = (
  subID: string,
  input: PostQueryMQTTSubscriptionMappingInput
): Query => ({
  endpoint: `integrator/mqtt/subscription/${subID}/mapping`,
  method: "POST",
  variables: input,
});

// ------------- Modbus -------------
export const postQueryModbusConnect = (input: PostQueryModbusConnectInput): Query => ({
  endpoint: "integrator/modbus/connect",
  method: "POST",
  variables: input,
});
export const postQueryModbusConnectionStart = (
  input: PostQueryModbusConnectionStartInput
): Query => ({
  endpoint: "integrator/modbus/connection/start",
  method: "POST",
  variables: input,
});
export const postQueryModbusDeleteConnection = (subID: string): Query => ({
  endpoint: `integrator/modbus/connection/${subID}`,
  method: "DELETE",
});
export const postQueryModbusMapAddress = (
  id: string,
  input: PostQueryModbusMapAddressInput
): Query => ({
  endpoint: `integrator/modbus/connection/${id}/mapping`,
  method: "POST",
  variables: input,
});
