import { useCallback, useEffect, useState } from "react";
import { FormStatuses } from "../../../../../Global/Types/commonTypes";
import MultiStageForm from "../../../../SmallComponents/MultiStageForm/MultiStageForm";
import callApi from "../../../../../Api/callApi";
import { useAuthedContext } from "../../../../../context/AuthContext";
import { Box } from "@mui/material";
import useTheme from "@mui/material/styles/useTheme";
import cssLayoutStyles from "../../../../../Global/Styles/layout";
import cssSpacingStyles from "../../../../../Global/Styles/spacing";
import { DaSubscription } from "../../../../../Api/DataSources/apiDSDataTypes";
import { DaConnectionParametersData } from "./daConnectionTypes";
import DaConnectionParameters from "./DaConnectionParameters";
import {
  getQueryDAAttributes,
  getQueryDACanonicalDataTypes,
  getQueryDAServers,
} from "../../../../../Api/DataSources/apiDSGetQueries";
import {
  postQueryDAConnect,
  postQueryDAEditSubscription,
  postQueryDAStartSubscription,
} from "../../../../../Api/DataSources/apiDSPostQueries";
import {
  GetQueryDAAttributesSnippet,
  GetQueryDACanonicalDataTypesSnippet,
  GetQueryDAServersSnippet,
  PostQueryDAConnectSnippet,
} from "../../../../../Api/DataSources/apiDSSnippets";
import {
  DaFormattedCanonical,
  FormattedDaGroupProperties,
  formatDaCanonicalData,
  prepareDaGroupProperties,
} from "./daUtils";
import Alert from "../../../../MaterialUI/Alert";
import DaGroupProperties from "./DaGroupProperties";
import DaItemsStage from "./DaItemsStage";
import DaDataSnapshot from "./DaDataSnapshot";
import {
  PostQueryDAEditSubscriptionInput,
  PostQueryDAStartSubscriptionInput,
} from "../../../../../Api/DataSources/apiDSInputs";
import { useNavigate } from "react-router-dom";
import DaFileUploadStage from "./DaFileUploadStage";
import { useLanguageContext } from "../../../../../context/LanguageContext";
import { useTranslateArray } from "../../../../../Global/Hooks/useTranslations";

const EMPTY_SUB_DATA: DaSubscription = {
  id: "",
  name: "",
  server_name: "",
  update_rate: "30000",
  user_id: "",
  status: "active",
  start_time: "",
  end_time: "",
  attributes: [],
  nodes: [],
};

const STAGES = [
  "Connection Parameters",
  "Select Attributes",
  "Select Nodes",
  "Date Overview",
];

interface DaConnectionProps {
  handleStartOver?: () => void;
  editSubForm?: {
    sub: DaSubscription;
  };
  onSuccessUrl: string;
  title: string;
}

const DaConnection: React.FC<DaConnectionProps> = ({
  handleStartOver,
  editSubForm,
  onSuccessUrl,
  title,
}) => {
  const { t } = useLanguageContext();
  const theme = useTheme();
  const styles = { ...cssLayoutStyles, ...cssSpacingStyles(theme) };

  const [alertMessage, setAlertMessage] = useState<string | null>(null);
  const [alertStatus, setAlertStatus] = useState<FormStatuses>(null);
  const [activeStep, setActiveStep] = useState<number>(0);
  const [serverFetchStatus, setServerFetchStatus] = useState<FormStatuses>(null);
  const [serverFetchMessage, setServerFetchMessage] = useState<string | null>(null);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState<boolean>(false);

  // activeStep === 0
  const [sub, setSub] = useState<DaSubscription>(editSubForm?.sub || EMPTY_SUB_DATA);
  const [serversList, setServersList] = useState<string[]>([]);

  // activeStep === 1
  const [selectedGroupProperties, setSelectedGroupProperties] =
    useState<GetQueryDAAttributesSnippet>(sub?.attributes || []);
  const [selectedItems, setSelectedItems] = useState<string[]>(sub?.nodes || []);
  const [isFileUploadView, setIsFileUploadView] = useState<boolean>(false);

  // static
  const [formattedGroupProperties, setFormattedGroupProperties] =
    useState<FormattedDaGroupProperties | null>(null);
  const [canonicalDetails, setCanonicalDetails] = useState<DaFormattedCanonical | null>(
    null
  );
  const [itemsList, setItemsList] = useState<string[]>([]);
  // initial values
  const [initialSub, setInitialSub] = useState<DaSubscription>(sub);
  const [initialSelectedGroupProperties, setInitialSelectedGroupProperties] =
    useState<GetQueryDAAttributesSnippet>(selectedGroupProperties);
  const [initialSelectedItems, setInitialSelectedItems] =
    useState<string[]>(selectedItems);

  // file upload view
  const [fileIsUploaded, setFileIsUploaded] = useState<boolean>(false);
  const [fileSelectedNodes, setFileSelectedNodes] = useState<string[]>([]);

  const { setAuthedUser } = useAuthedContext();
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      try {
        setServerFetchMessage(t("Fetching data..."));
        setServerFetchStatus("loading");
        const servers: string[] = [];

        // back-end often cannot make the first request to fetch
        // servers. So, we need a follow-up request in case of an error
        const serversRequest = await handleFetchServers(false);
        if (!serversRequest?.length) {
          const serversRequest2 = await handleFetchServers(true);
          if (!serversRequest2?.length) {
            throw new Error("Follow-up fetch servers request failed");
          } else {
            servers.push(...serversRequest2);
          }
        } else {
          servers.push(...serversRequest);
        }

        setServersList(servers);
        setServerFetchStatus(null);
        setServerFetchMessage(null);
      } catch (err) {
        console.log("err", err);
        setServerFetchStatus("error");
        setServerFetchMessage(t("Something went wrong - couldn't find servers"));
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (fileIsUploaded) {
      setAlertStatus(null);
      setAlertMessage(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileIsUploaded]);

  useEffect(() => {
    const areEqual = JSON.stringify(initialSub) === JSON.stringify(sub);
    const propsAreEqual =
      JSON.stringify(selectedGroupProperties) ===
      JSON.stringify(initialSelectedGroupProperties);
    const itemsAreEqual =
      JSON.stringify(selectedItems) === JSON.stringify(initialSelectedItems);
    if (areEqual && propsAreEqual && itemsAreEqual) {
      setHasUnsavedChanges(false);
    } else {
      setHasUnsavedChanges(true);
    }
  }, [
    sub,
    initialSub,
    selectedGroupProperties,
    initialSelectedGroupProperties,
    selectedItems,
    initialSelectedItems,
  ]);

  const handleFetchServers = async (isFollowUp: boolean) => {
    try {
      const servers = await callApi<GetQueryDAServersSnippet>({
        query: getQueryDAServers,
        auth: { setAuthedUser },
      });
      return servers;
    } catch (err) {
      console.log("err", err);
      if (isFollowUp) {
        setServerFetchStatus("error");
        setServerFetchMessage(t("Something went wrong - couldn't find servers"));
      }
    }
  };

  const handleOnDaOperations = (): boolean => {
    setAlertStatus("loading");
    setAlertMessage(t("Loading..."));

    if (activeStep === 0) {
      if (!sub.name || !sub.server_name || !sub.update_rate) {
        setAlertStatus("warning");
        setAlertMessage(
          t("You must provide connection name, update rate, and select server")
        );
        return false;
      }
    }

    if (activeStep === 1) {
      if (!selectedGroupProperties.length) {
        setAlertStatus("warning");
        setAlertMessage(t("You must select at least one attribute"));
        return false;
      }
    }

    if (activeStep === 2) {
      if (isFileUploadView) {
        // file upload view only
        if (!fileIsUploaded) {
          setAlertStatus("warning");
          setAlertMessage(t("You must upload a file"));
          return false;
        }
        if (!fileSelectedNodes.length) {
          // file upload view only
          setAlertStatus("warning");
          setAlertMessage(t("Connection must have at least one node"));
          return false;
        } else {
          setSelectedItems(fileSelectedNodes);
        }
      } else {
        // da nodes tree view
        if (!selectedItems.length) {
          setAlertStatus("warning");
          setAlertMessage(t("You must select at least one node"));
          return false;
        }
      }
    }

    return true;
  };

  const handleMakeDaRequest = async () => {
    const properties = selectedGroupProperties.map((prop) => prop.opcda_attribute_id);
    const startSessionInput: PostQueryDAStartSubscriptionInput = {
      server_name: sub.server_name,
      name: sub.name,
      update_rate: +sub.update_rate,
      nodes: selectedItems,
      attributes: properties,
    };

    if (editSubForm?.sub.id) {
      // edit sub
      const editSubInput: PostQueryDAEditSubscriptionInput = {
        ...startSessionInput,
        subscription_id: editSubForm.sub.id,
      };
      await callApi({
        query: postQueryDAEditSubscription(editSubInput),
        auth: { setAuthedUser },
      });
    } else {
      // start sub
      await callApi({
        query: postQueryDAStartSubscription(startSessionInput),
        auth: { setAuthedUser },
      });
    }
  };

  const handleOnNextStage = async () => {
    try {
      const operations = handleOnDaOperations();
      if (!operations) {
        return false;
      }

      if (activeStep === 0) {
        await stageOneFetch();
      }

      if (activeStep === 1) {
        setFileIsUploaded(false);
      }

      if (activeStep === 3) {
        handleUpdateInitials();
        await handleMakeDaRequest();
        navigate({ pathname: onSuccessUrl });
        return false;
      }

      setAlertStatus(null);
      setAlertMessage(null);
      return true;
    } catch (err) {
      console.log("err ", err);
      setAlertStatus("error");
      setAlertMessage(t("Something went wrong"));
      return false;
    }
  };

  const handleUpdateConnectionParamsData = useCallback(
    (data: DaConnectionParametersData) => {
      setSub((prev) => ({
        ...prev,
        server_name: data.server,
        name: data.name,
        update_rate: data.updateRate,
      }));
    },
    []
  );

  const stageOneFetch = async () => {
    const items = await callApi<PostQueryDAConnectSnippet>({
      query: postQueryDAConnect({ server_name: sub.server_name }),
      auth: { setAuthedUser },
    });
    const properties = await callApi<GetQueryDAAttributesSnippet>({
      query: getQueryDAAttributes,
      auth: { setAuthedUser },
    });
    const canonical = await callApi<GetQueryDACanonicalDataTypesSnippet>({
      query: getQueryDACanonicalDataTypes,
      auth: { setAuthedUser },
    });
    const formattedCanonical = formatDaCanonicalData(canonical);
    const preparedProps = prepareDaGroupProperties(properties);
    const preCheckedProps = [...preparedProps.mandatory, ...preparedProps.recommended];

    // if not sub pre-select some props
    if (!editSubForm?.sub?.id) {
      setSelectedGroupProperties(preCheckedProps);
    }
    setFormattedGroupProperties(preparedProps);
    setItemsList(items.response);
    setCanonicalDetails(formattedCanonical);
  };

  const handleUpdateSelectedItems = useCallback((nodes: string[]) => {
    setSelectedItems(() => [...nodes]);
  }, []);

  const handleSaveChanges = async () => {
    try {
      if (!editSubForm?.sub.id) {
        return;
      }
      const operations = handleOnDaOperations();
      if (operations) {
        handleUpdateInitials();
        await handleMakeDaRequest();
        navigate({ pathname: onSuccessUrl });
      }
    } catch (err) {
      console.log("handleSaveChanges ", err);
    }
  };
  const handleCancelForm = () => {
    navigate({ pathname: onSuccessUrl });
  };
  const handleUpdateInitials = () => {
    setInitialSub(sub);
    setInitialSelectedGroupProperties(selectedGroupProperties);
    setInitialSelectedItems(selectedItems);
  };

  return (
    <MultiStageForm
      steps={useTranslateArray(STAGES)}
      activeStep={activeStep}
      setActiveStep={setActiveStep}
      handleOnNextStage={handleOnNextStage}
      alertMessage={alertMessage}
      alertStatus={alertStatus}
      disableNextButton={alertStatus === "success"}
      disablePrevButton={alertStatus === "success"}
      firstBack={handleStartOver}
      lastNextButtonLabel={
        editSubForm?.sub.id ? t("Confirm and Apply Changes") : t("Create New Connection")
      }
      title={title}
      saveCurrentStageData={editSubForm?.sub.id ? handleSaveChanges : undefined}
      handleExitForm={handleCancelForm}
      hasUnsavedChanges={hasUnsavedChanges}
    >
      <>
        {!!serverFetchMessage ? (
          <Box component="div" css={styles.contentBreak} p={3}>
            <Alert
              severity={serverFetchStatus}
              message={serverFetchMessage}
              showAlert={!!serverFetchMessage}
            />
          </Box>
        ) : null}

        {!serverFetchMessage ? (
          <>
            {activeStep === 0 ? (
              <Box component="div" css={styles.flexCenter}>
                <Box component="div">
                  <DaConnectionParameters
                    data={{
                      server: sub.server_name,
                      name: sub.name,
                      updateRate: `${sub.update_rate}`,
                    }}
                    isLoading={alertStatus === "loading"}
                    handleUpdateData={handleUpdateConnectionParamsData}
                    serversList={serversList}
                    isEdit={!!editSubForm?.sub.id}
                  />
                </Box>
              </Box>
            ) : null}
          </>
        ) : null}

        {activeStep === 1 ? (
          <DaGroupProperties
            formattedGroupProperties={formattedGroupProperties}
            selectedGroupProperties={selectedGroupProperties}
            setSelectedGroupProperties={setSelectedGroupProperties}
            isFileUpload={isFileUploadView}
            setIsFileUpload={setIsFileUploadView}
          />
        ) : null}

        {activeStep === 2 && !isFileUploadView ? (
          <DaItemsStage
            itemsList={itemsList}
            selectedItems={selectedItems}
            serverName={sub.server_name}
            selectedGroupProperties={selectedGroupProperties}
            canonicalDetails={canonicalDetails}
            handleChange={handleUpdateSelectedItems}
          />
        ) : null}

        {activeStep === 2 && isFileUploadView ? (
          <DaFileUploadStage
            setFileIsUploaded={setFileIsUploaded}
            serverURL={sub.server_name}
            setSelectedNodes={setFileSelectedNodes}
          />
        ) : null}

        {activeStep === 3 ? (
          <DaDataSnapshot
            serverName={sub.server_name}
            selectedItems={selectedItems}
            selectedGroupProperties={selectedGroupProperties}
            updateTime={sub.update_rate}
            canonicalDetails={canonicalDetails}
          />
        ) : null}
      </>
    </MultiStageForm>
  );
};

export default DaConnection;
