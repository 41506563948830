import {
  Box,
  Divider,
  Grid,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { GetQueryDAAttributesSnippet } from "../../../../../Api/DataSources/apiDSSnippets";
import { FormattedDaGroupProperties } from "./daUtils";
import Checkbox from "../../../../MaterialUI/FormFields/Checkbox";
import useTheme from "@mui/material/styles/useTheme";
import cssSpacingStyles from "../../../../../Global/Styles/spacing";
import cssLayoutStyles from "../../../../../Global/Styles/layout";
import { DaAttribute } from "../../../../../Api/DataSources/apiDSDataTypes";
import { memo, useState } from "react";
import InfoIcon from "@mui/icons-material/Info";
import Modal from "../../../../MaterialUI/Modal";
import { CONNECTION_BULK_UPLOAD_INFO_TEXT } from "../createEdiConnectionUtils";
import { useLanguageContext } from "../../../../../context/LanguageContext";

interface DaGroupPropertiesProps {
  formattedGroupProperties: FormattedDaGroupProperties | null;
  selectedGroupProperties: GetQueryDAAttributesSnippet;
  setSelectedGroupProperties: React.Dispatch<
    React.SetStateAction<GetQueryDAAttributesSnippet>
  >;
  isFileUpload: boolean;
  setIsFileUpload: React.Dispatch<React.SetStateAction<boolean>>;
}

const DaGroupProperties: React.FC<DaGroupPropertiesProps> = ({
  formattedGroupProperties,
  selectedGroupProperties,
  setSelectedGroupProperties,
  isFileUpload,
  setIsFileUpload,
}) => {
  const { t } = useLanguageContext();
  const theme = useTheme();
  const styles = { ...cssLayoutStyles, ...cssSpacingStyles(theme) };
  const [openModal, setOpenModal] = useState<boolean>(false);

  const sxMediaQuery = useMediaQuery("(max-width:599px)");
  const mdMediaQuery = useMediaQuery("(max-width:899px)");
  const xlMediaQuery = useMediaQuery("(max-width:1535px)");

  const mandatory = formattedGroupProperties?.mandatory || [];
  const recommended = formattedGroupProperties?.recommended || [];
  const optional = formattedGroupProperties?.optional || [];
  const optional2 = formattedGroupProperties?.optional2 || [];
  const optional3 = formattedGroupProperties?.optional3 || [];

  const gridItemProps = {
    item: true,
    xs: 12,
    sm: 6,
    md: 3,
    lg: 3,
    xl: 2.4,
  };

  const responsiveOptional2Start = optional2.slice(0, 2);
  const responsiveOptional2End = optional2.slice(2);

  const optionalArr = mdMediaQuery
    ? [...optional, ...optional2, ...optional3]
    : xlMediaQuery
    ? [...optional, ...responsiveOptional2Start]
    : optional;

  const optional2Arr = xlMediaQuery
    ? [...responsiveOptional2End, ...optional3]
    : optional2;

  const handleOnSelectGroupProperty = (event: React.ChangeEvent<HTMLInputElement>) => {
    const property = JSON.parse(event.target.value);

    setSelectedGroupProperties((prev) => {
      const propertyExists = prev.findIndex(
        (prop) => prop.opcda_attribute_id === property.opcda_attribute_id
      );

      if (propertyExists !== -1) {
        return prev.filter(
          (prop) => prop.opcda_attribute_id !== property.opcda_attribute_id
        );
      } else {
        return [...prev, property];
      }
    });
  };

  return (
    <Box
      component="div"
      css={[
        sxMediaQuery
          ? styles.height100
          : mdMediaQuery
          ? styles.heightUnset
          : styles.height100,
        mdMediaQuery ? styles.overflowYAuto : styles.overflowYUnset,
      ]}
      p={3}
    >
      <Grid css={[styles.height100, styles.doubleSectionBreak]} container spacing={1}>
        <Grid {...gridItemProps}>
          <Typography css={styles.labelBreak} variant="h4">
            {t("Mandatory")}
          </Typography>
          <CheckboxGroup
            properties={mandatory}
            handleOnSelectGroupProperty={handleOnSelectGroupProperty}
            selectedGroupProperties={selectedGroupProperties}
            xlMediaQuery={xlMediaQuery}
            type="mandatory"
            unsetHeight={mdMediaQuery}
          />
          {mdMediaQuery && !sxMediaQuery ? (
            <>
              <Typography
                css={[styles.labelBreak, styles.reverseSectionBreak]}
                variant="h4"
              >
                {t("Recommended")}
              </Typography>
              <CheckboxGroup
                properties={recommended}
                handleOnSelectGroupProperty={handleOnSelectGroupProperty}
                selectedGroupProperties={selectedGroupProperties}
                xlMediaQuery={xlMediaQuery}
                type="recommended"
                unsetHeight={mdMediaQuery}
              />
            </>
          ) : null}
        </Grid>

        {mdMediaQuery && !sxMediaQuery ? null : (
          <Grid {...gridItemProps}>
            <Typography css={styles.labelBreak} variant="h4">
              {t("Recommended")}
            </Typography>
            <CheckboxGroup
              properties={recommended}
              handleOnSelectGroupProperty={handleOnSelectGroupProperty}
              selectedGroupProperties={selectedGroupProperties}
              xlMediaQuery={xlMediaQuery}
              type="recommended"
            />
          </Grid>
        )}

        <Grid {...gridItemProps}>
          <Typography css={styles.labelBreak} variant="h4">
            {t("Optional")}
          </Typography>
          <CheckboxGroup
            properties={optionalArr}
            handleOnSelectGroupProperty={handleOnSelectGroupProperty}
            selectedGroupProperties={selectedGroupProperties}
            xlMediaQuery={xlMediaQuery}
            type="optional"
          />
        </Grid>

        {mdMediaQuery ? null : (
          <Grid {...gridItemProps}>
            <Typography css={styles.labelBreak} variant="h4">
              {t("Optional")}
            </Typography>
            <CheckboxGroup
              properties={optional2Arr}
              handleOnSelectGroupProperty={handleOnSelectGroupProperty}
              selectedGroupProperties={selectedGroupProperties}
              xlMediaQuery={xlMediaQuery}
              type="optional2"
            />
          </Grid>
        )}

        {xlMediaQuery ? null : (
          <Grid {...gridItemProps}>
            <Typography css={styles.labelBreak} variant="h4">
              {t("Optional")}
            </Typography>
            <CheckboxGroup
              properties={optional3}
              handleOnSelectGroupProperty={handleOnSelectGroupProperty}
              selectedGroupProperties={selectedGroupProperties}
              xlMediaQuery={xlMediaQuery}
              type="optional3"
            />
          </Grid>
        )}
      </Grid>

      <Divider css={styles.textBreak} />
      <Stack direction="row" spacing={2} alignItems="center">
        <IconButton size="small" onClick={() => setOpenModal(true)}>
          <InfoIcon color="info" />
        </IconButton>
        <Checkbox
          label={t("Use File Upload")}
          checked={isFileUpload}
          onChange={(e) => setIsFileUpload(e.target.checked)}
        />
      </Stack>

      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        fullWidth
        maxWidth={"sm"}
        label={t("File Upload")}
      >
        <Box component="div" css={styles.fullPadding1}>
          <Typography>{t(CONNECTION_BULK_UPLOAD_INFO_TEXT)}</Typography>
        </Box>
      </Modal>
    </Box>
  );
};

export default memo(DaGroupProperties);

interface CheckboxGroupProps {
  properties: GetQueryDAAttributesSnippet;
  unsetHeight?: boolean;
  handleOnSelectGroupProperty: (event: React.ChangeEvent<HTMLInputElement>) => void;
  selectedGroupProperties: GetQueryDAAttributesSnippet;
  xlMediaQuery: boolean;
  type: keyof FormattedDaGroupProperties;
}

const CheckboxGroup: React.FC<CheckboxGroupProps> = ({
  properties,
  unsetHeight,
  handleOnSelectGroupProperty,
  selectedGroupProperties,
  xlMediaQuery,
  type,
}) => {
  const styles = { ...cssLayoutStyles };

  let hideDivider = false;

  if (type === "optional2") {
    if (xlMediaQuery) {
      hideDivider = true;
    }
  }
  if (type === "optional3") {
    hideDivider = true;
  }

  return (
    <Stack
      css={unsetHeight ? styles.heightUnset : styles.height100}
      direction="row"
      spacing={1}
      justifyContent="space-between"
    >
      <Box component="div">
        {properties.map((property, index) => (
          <Box component="div" key={`${property.name}-${index}`}>
            <Checkbox
              label={property.name}
              onChange={handleOnSelectGroupProperty}
              value={JSON.stringify(property)}
              checked={isChecked(selectedGroupProperties, property)}
              size="small"
            />
          </Box>
        ))}
      </Box>
      {hideDivider ? null : <Divider orientation="vertical" flexItem />}
    </Stack>
  );
};

const isChecked = (
  selectedProperties: GetQueryDAAttributesSnippet,
  currentProperty: DaAttribute
) => {
  const foundProperty = selectedProperties.find(
    (prop) => prop.opcda_attribute_id === currentProperty.opcda_attribute_id
  );
  return !!foundProperty;
};
