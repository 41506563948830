import { Query } from "../callApi";

export const getQueryMaterialsAndProducts = (
  type: "Material" | "Semi-Product" | "Product"
): Query => ({
  endpoint: `procurement_and_materials/materials/material-master-data?type=${type}`,
  method: "GET",
});

export const getQueryErpClassifications = (): Query => ({
  endpoint: "supply_chain/erp/classifications/tree",
  method: "GET",
});

export const getQuerySupplyProducts = (): Query => ({
  endpoint: "supply_chain/products/products",
  method: "GET",
});

export const getQuerySupplySemiProducts = (): Query => ({
  endpoint: "supply_chain/materials/semi-products",
  method: "GET",
});

export const getQueryStocks = (): Query => ({
  endpoint: `warehouse/stock_control/stocks`,
  method: "GET",
});

export const getQueryStocksOverview = (): Query => ({
  endpoint: `warehouse/stock_control/stocks/overview`,
  method: "GET",
});
