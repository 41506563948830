import useTheme from "@mui/material/styles/useTheme";
import cssLayoutStyles from "../../../../Global/Styles/layout";
import cssSpacingStyles from "../../../../Global/Styles/spacing";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { Box, Divider } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import cssComponentsStyles from "../../../../Global/Styles/components";
import { useLanguageContext } from "../../../../context/LanguageContext";

interface ValueDisplayCardProps {
  label: string;
  referentValue?: number;
  unit: string;
  parameterValue: number | null;
  isParameterInRange?: boolean | null;
}

const ValueDisplayCard: React.FC<ValueDisplayCardProps> = ({
  label,
  referentValue,
  unit,
  parameterValue,
  isParameterInRange,
}) => {
  const { t } = useLanguageContext();
  const theme = useTheme();
  const styles = {
    ...cssLayoutStyles,
    ...cssSpacingStyles(theme),
    ...cssComponentsStyles(theme),
  };

  let dividerColor = "primary.main";
  if (isParameterInRange === false && unit && parameterValue !== null) {
    dividerColor = "error.main";
  }

  return (
    <Card
      elevation={0}
      css={[styles.width100, styles.fullMargin1, styles.flexCenter, styles.card]}
    >
      <CardContent
        sx={{
          textAlign: "center",
          width: "100%",
          padding: "16px",
          "&:last-child": { paddingBottom: 2 },
        }}
      >
        <Box component="div" css={styles.flexCenter} mb={1}>
          <Typography variant="h4" fontWeight={400} component="span">
            {label}
          </Typography>

          {parameterValue !== null && !referentValue && unit && (
            <Box component="div" css={styles.flexCenter} ml={1}>
              <Typography variant="inherit" component="span">
                {isParameterInRange ? t("is Within") : t("is Out of")}{" "}
                {t("Specification")}
              </Typography>
              {isParameterInRange !== null &&
                (isParameterInRange ? (
                  <CheckCircleIcon sx={{ fontSize: 20, ml: 0.5 }} />
                ) : (
                  <CancelIcon sx={{ fontSize: 20, ml: 0.5 }} />
                ))}
            </Box>
          )}
        </Box>

        <Divider
          sx={{ width: "100%", height: 2, bgcolor: dividerColor }}
          css={[styles.textBreak]}
        />

        {unit && parameterValue !== null ? (
          <Typography variant="h2">
            {referentValue ? referentValue : parameterValue}{" "}
            <Typography
              variant="h5"
              component="span"
              style={{ fontWeight: 300, color: theme.palette.customColors.greyText }}
            >
              {unit}
            </Typography>
          </Typography>
        ) : null}
      </CardContent>
    </Card>
  );
};

export default ValueDisplayCard;
