import { SerializedStyles, css } from "@emotion/react";
import { Box, Drawer, Theme, useTheme } from "@mui/material";
import { HELP_PAGE_WIDTH, TOP_NAV_SPACING_WITH_SITE_CONTENT } from "../layoutVariables";
import HelpPage from "../../pages/Help/HelpPage";

const cssStyles = (theme: Theme, openRightNav: boolean) => ({
  drawer: css({
    width: openRightNav ? HELP_PAGE_WIDTH : 0,
    flexShrink: 0,
    "& .MuiDrawer-paper": {
      width: HELP_PAGE_WIDTH,
      boxSizing: "border-box",
      border: "none",
    },
  }),
  rightNavContent: css({
    marginTop: TOP_NAV_SPACING_WITH_SITE_CONTENT,
    overflow: "auto",
    height: "100%",
    padding: theme.spacing(1),
    background:
      theme.palette.mode === "light"
        ? theme.palette.background.paper
        : theme.palette.background.default,
  }),
});

interface HelpNavigationProps {
  css?: SerializedStyles[] | SerializedStyles;
  className?: string;
  openHelpNav: boolean;
  setOpenHelpNav: React.Dispatch<React.SetStateAction<boolean>>;
}

const HelpNavigation: React.FC<HelpNavigationProps> = ({
  className,
  openHelpNav,
  setOpenHelpNav,
}) => {
  const theme = useTheme();
  const styles = { ...cssStyles(theme, openHelpNav) };

  const handleClose = () => {
    setOpenHelpNav(false);
  };

  return (
    <Drawer
      className={className}
      css={styles.drawer}
      anchor="right"
      open={openHelpNav}
      onClose={handleClose}
      variant="temporary"
    >
      <Box component="div" css={styles.rightNavContent}>
        <HelpPage locationPath={location.pathname} />
      </Box>
    </Drawer>
  );
};

export default HelpNavigation;
