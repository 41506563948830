import { TableGridColumnSchema } from "../../SmallComponents/TableGrid/constructTableGrid";

export type StartEndDates = {
  start_date: string;
  end_date: string;
};

export type AnomalyDetectionTableColumnsAndRowsShema = {
  id: string;
  label: string;
  type: string;
  columns: TableGridColumnSchema[];
};

export interface AnomalyDetectionTableRow {
  id: string;
  tagNumber: string;
  description: string;
  system: string;
  unit: string;
  startDate: Date;
  endDate: Date;
  asIsAverage: number;
  asIsDeviation: number;
  asIsMin: number;
  asIsMax: number;
  toBeAverage: number;
  toBeDeviation: number;
  toBeMin: number;
  toBeMax: number;
}

export type ThyssenData = {
  avg: number;
  description: string;
  max: number;
  median: number;
  min: number;
  parameter_id: number;
  priority: number;
  q1: number;
  q3: number;
  soc_avg: number;
  soc_max: number;
  soc_min: number;
  soc_vark: number;
  system: string;
  tag_no: string;
  unit: string;
  vark: number;
};

export const colSchema: AnomalyDetectionTableColumnsAndRowsShema[] = [
  {
    id: "meta",
    label: "Meta",
    type: "group",
    columns: [
      { id: "tag_no", label: "Tag No.", type: "string" },
      { id: "description", label: "Description", type: "string" },
      { id: "system", label: "System", type: "string" },
      { id: "unit", label: "Unit", type: "string" },
    ],
  },
  {
    id: "asIs",
    label: "As-Is",
    type: "group",
    columns: [
      {
        id: "avg",
        label: "Avg",
        type: "number",
        marked: true,
        markedId: "avg",
      },
      {
        id: "vark",
        label: "σ",
        type: "number",
        marked: true,
        markedId: "vark",
      },
      {
        id: "min",
        label: "Min",
        type: "number",
        marked: true,
        markedId: "min",
      },
      {
        id: "max",
        label: "Max",
        type: "number",
        marked: true,
        markedId: "max",
      },
    ],
  },
  {
    id: "toBe",
    label: "To-Be",
    type: "group",
    columns: [
      {
        id: "soc_avg",
        label: "Avg",
        type: "number",
        marked: true,
        markedId: "soc_avg",
      },
      {
        id: "soc_vark",
        label: "σ",
        type: "number",
        marked: true,
        markedId: "soc_vark",
      },
      {
        id: "soc_min",
        label: "Min",
        type: "number",
        marked: true,
        markedId: "soc_min",
      },
      {
        id: "soc_max",
        label: "Max",
        type: "number",
        marked: true,
        markedId: "soc_max",
      },
    ],
  },
];
