import { Formik } from "formik";
import { Stack } from "@mui/material";
import { object } from "yup";
import { YUP_REQUIRED_STRING } from "../../../../Global/Constants/yupConstants";
import { useState } from "react";
import { FormStatuses } from "../../../../Global/Types/commonTypes";
import { SerializedStyles } from "@emotion/react";
import TextField from "../../../../Components/MaterialUI/FormFields/TextFields";
import Button from "../../../../Components/MaterialUI/Button";
import Alert from "../../../../Components/MaterialUI/Alert";
import { useDetectFormsUnsavedChanges } from "../../../../Global/Hooks/useDetectFormsUnsavedChanges";
import { useLanguageContext } from "../../../../context/LanguageContext";
import { postQueryTriggerWorkflowForOrder } from "../../api/mutations";
import { useAuthedContext } from "../../../../context/AuthContext";
import callApi from "../../../../Api/callApi";

const fieldValidation = object({
  name: YUP_REQUIRED_STRING,
});

type FormValues = {
  name: string;
};

interface CreateWorkflowOrderNameProps {
  css?: SerializedStyles[] | SerializedStyles;
  className?: string;
  handleSetUnsavedChanges: (unsavedChanges: boolean) => void;
  setUnsavedChanges: React.Dispatch<React.SetStateAction<boolean>>;
  workflowId: string;
}

const CreateWorkflowOrderName: React.FC<CreateWorkflowOrderNameProps> = ({
  className,
  handleSetUnsavedChanges,
  setUnsavedChanges,
  workflowId,
}) => {
  const { t } = useLanguageContext();
  const [formStatus, setFormStatus] = useState<FormStatuses>(null);
  const [alertMessage, setAlertMessage] = useState<string | null>(null);
  const { setAuthedUser } = useAuthedContext();

  const initialValues: FormValues = {
    name: "",
  };

  const handleFormSubmit = async (values: FormValues) => {
    try {
      setFormStatus("loading");
      setAlertMessage(null);

      await callApi({
        query: postQueryTriggerWorkflowForOrder(workflowId, values.name),
        auth: { setAuthedUser },
      });

      setFormStatus("success");
      setAlertMessage(t("Successfully added workflow name"));
      setUnsavedChanges(false);
    } catch (err) {
      console.log("CreateWorkflowOrderName err ", err);
      setFormStatus("error");
      setAlertMessage(t("Something went wrong"));
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleFormSubmit}
      validationSchema={fieldValidation}
    >
      {({ handleSubmit, handleChange, touched, errors, values }) => {
        useDetectFormsUnsavedChanges(initialValues, values, handleSetUnsavedChanges);

        return (
          <form onSubmit={handleSubmit}>
            <Stack
              className={className}
              spacing={2}
              alignItems="center"
              justifyContent="center"
            >
              <TextField
                name="name"
                label={t("Workflow Order Name")}
                error={touched["name"] && !!errors["name"]}
                helperText={touched["name"] && errors["name"]}
                onChange={handleChange}
                value={values.name}
              />

              <Button type="submit" loading={formStatus === "loading"}>
                {t("Create Workflow Order")}
              </Button>
              <Alert
                message={alertMessage || ""}
                showAlert={!!alertMessage}
                severity={formStatus}
              />
            </Stack>
          </form>
        );
      }}
    </Formik>
  );
};

export default CreateWorkflowOrderName;