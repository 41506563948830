import {
  Box,
  Divider,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  Stack,
  Tab,
  Tabs,
  useTheme,
} from "@mui/material";
import cssSpacingStyles from "../../Global/Styles/spacing";
import ResponsiveTableGrid from "../../Components/SmallComponents/TableGrid/ResponsiveTableGrid";
import { useEffect, useState } from "react";
import cssLayoutStyles from "../../Global/Styles/layout";
import { FormStatuses } from "../../Global/Types/commonTypes";
import { TableGridColumnSchema } from "../../Components/SmallComponents/TableGrid/constructTableGrid";
import Alert from "../../Components/MaterialUI/Alert";
import CellDoubleActionButton from "../../Components/SmallComponents/TableGrid/MobileTableGrid/CellDoubleActionButton";
import Modal from "../../Components/MaterialUI/Modal";
import {
  LongPlanningProductRow,
  LongPlanningMachineRow,
} from "./Components/longTermPlanningUtils";
import {
  LongTermMachineView,
  LongTermProductView,
} from "./Components/LongTermViewComponents";
import LabelWithBoldedPart from "../../Components/MaterialUI/LabelWithBoldedPart";
import LongTermProductForm from "./Components/LongTermProductForm";
import LongTermMachineForm from "./Components/LongTermMachineForm";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { useOutletContext } from "react-router-dom";
import { AppRouterProps } from "../../Layout/layoutVariables";
import callApi from "../../Api/callApi";
import {
  getQueryNeoperlData,
  GetQueryNeoperlDataSnippet,
} from "./Components/longTermEndpoints";
import { useAuthedContext } from "../../context/AuthContext";
import LongTermAddNew from "./Components/LongTermAddNew";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import LongTermDeleteModal from "./Components/LongTermDeleteModal";
import Checkbox from "../../Components/MaterialUI/FormFields/Checkbox";
import { handleGetSelectOption } from "../../Global/Utils/commonFunctions";
import RadioGroup from "../../Components/MaterialUI/FormFields/RadioGroup";
import { useLanguageContext } from "../../context/LanguageContext";
import {
  useTranslateFields,
  useTranslateSchemeArray,
} from "../../Global/Hooks/useTranslations";
import CustomIconsTooltip from "../../Components/SmallComponents/Tooltip/CustomIconsTooltip";
import PageTitle from "../../Components/SmallComponents/PageTitle/PageTitle";

const planOptions = handleGetSelectOption([
  "Shortest Time",
  "Utilization Balance",
  "Minimal Machine Park",
]);

type ColActionType = {
  type:
    | "Edit Product"
    | "View Product"
    | "Edit Machine"
    | "View Machine"
    | "Add New"
    | "Delete Machine"
    | "Delete Product";
  product: LongPlanningProductRow | null;
  machine: LongPlanningMachineRow | null;
} | null;

const LongTermPlanning: React.FC = () => {
  const { t } = useLanguageContext();
  const theme = useTheme();
  const styles = { ...cssSpacingStyles(theme), ...cssLayoutStyles };
  const [tabView, setTabView] = useState<number>(0);
  const [pageLoading, setPageLoading] = useState<boolean>(true);
  const [formStatus, setFormStatus] = useState<FormStatuses>(null);
  const [alertMessage, setAlertMessage] = useState<string | null>(null);
  const [machineColumns, setMachineColumns] = useState<TableGridColumnSchema[]>([]);
  const [productColumns, setProductColumns] = useState<TableGridColumnSchema[]>([]);
  const [productsRows, setProductsRows] = useState<LongPlanningProductRow[]>([]);
  const [machineRows, setMachineRows] = useState<LongPlanningMachineRow[]>([]);
  const [openModal, setOpenModal] = useState<ColActionType>(null);
  const [totalFreeCapacity, setTotalFreeCapacity] = useState<number>(0);
  const [totalCapacity, setTotalCapacity] = useState<number>(0);
  const [unsavedChanges, setUnsavedChanges] = useState<boolean>(false);

  const [eu] = useState({
    Bulgaria: true,
    Italy: false,
    Germany: false,
  });

  const { smMediaQuery, setExtraRightNavMenu, setExtraTopNavMenu } =
    useOutletContext<AppRouterProps>();
  const { setAuthedUser } = useAuthedContext();

  useEffect(() => {
    (async () => {
      setPageLoading(true);
      await handleFetch();
      setPageLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (smMediaQuery) {
      setExtraTopNavMenu(null);
      setExtraRightNavMenu(null);
    } else {
      setExtraTopNavMenu(<PageTitle title={`${t("Long-term Planning")} (2025)`} />);
      setExtraRightNavMenu(<ExtraTopNavMenu handleOpenModal={openAddNewTemplateModal} />);
    }

    return () => {
      setExtraTopNavMenu(null);
      setExtraRightNavMenu(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [smMediaQuery]);

  const openAddNewTemplateModal = () => {
    setOpenModal({
      type: "Add New",
      product: null,
      machine: null,
    });
  };

  const handleFetch = async () => {
    try {
      setFormStatus("loading");
      setAlertMessage(null);
      const data = await callApi<GetQueryNeoperlDataSnippet>({
        query: getQueryNeoperlData,
        auth: { setAuthedUser },
      });
      console.log("data ", data);

      setProductsRows(
        data.product_rows.map((item) => {
          return {
            ...item,
            actions: (
              <Stack direction="row" alignItems="center" spacing={1}>
                <CellDoubleActionButton
                  justify="flex-start"
                  handleOnViewClick={() => {
                    onActionClick({
                      product: item,
                      machine: null,
                      type: "View Product",
                    });
                  }}
                  handleOnEditClick={() => {
                    onActionClick({
                      product: item,
                      machine: null,
                      type: "Edit Product",
                    });
                  }}
                />
                <IconButton
                  onClick={() =>
                    onActionClick({
                      machine: null,
                      product: item,
                      type: "Delete Product",
                    })
                  }
                >
                  <DeleteOutlineOutlinedIcon />
                </IconButton>
              </Stack>
            ),
          };
        })
      );
      setMachineRows(
        // @ts-ignore
        data.machine_rows.map((item) => {
          return {
            ...item,
            products: item.products.map((ele) => ele).join(", "),
            utilization: Math.round(+item.utilization),
            free_capacity: Math.round(+item.free_capacity),
            actions: (
              <Stack direction="row" alignItems="center" spacing={1}>
                <CellDoubleActionButton
                  justify="flex-start"
                  handleOnViewClick={() => {
                    onActionClick({
                      machine: item,
                      product: null,
                      type: "View Machine",
                    });
                  }}
                  handleOnEditClick={() => {
                    onActionClick({
                      machine: item,
                      product: null,
                      type: "Edit Machine",
                    });
                  }}
                />
                <IconButton
                  onClick={() =>
                    onActionClick({
                      machine: item,
                      product: null,
                      type: "Delete Machine",
                    })
                  }
                >
                  <DeleteOutlineOutlinedIcon />
                </IconButton>
              </Stack>
            ),
          };
        })
      );
      const machineCols = data.machine_columns
        .filter((item) => item.id !== "utilization" && item.id !== "free_capacity")
        .map((ele) => {
          if (ele.type === "number") {
            return {
              ...ele,
              formatNumb: true,
            };
          }

          return {
            ...ele,
          };
        });

      setMachineColumns([
        ...machineCols,
        {
          id: "utilization",
          label: "Utilization (%)",
          type: "number",
          bolded100: true,
        },
        {
          id: "free_capacity",
          label: "Free Capacity (%)",
          type: "number",
          bolded100: true,
        },
        { id: "actions", label: "Actions", type: "button" },
      ]);

      const productCols = data.product_columns.map((item) => {
        if (item.type === "number") {
          return {
            ...item,
            formatNumb: true,
          };
        }

        return {
          ...item,
        };
      });

      setProductColumns([
        ...productCols,
        { id: "actions", label: "Actions", type: "button" },
      ]);
      setTotalFreeCapacity(data.total_free_capacity);
      setTotalCapacity(data.total_occupied_capacity);
      setFormStatus(null);
    } catch (error) {
      setFormStatus("error");
      setAlertMessage(t("Something went wrong"));
      console.log("There was an error: ", error);
    }
  };

  const onActionClick = (data: ColActionType) => {
    setOpenModal(data);
  };

  const handleOnDelete = async () => {
    await handleFetch();
    setOpenModal(null);
  };

  const handleSetUnsavedChanges = (unsavedChanges: boolean) => {
    if (unsavedChanges) {
      setUnsavedChanges(true);
    }
  };

  return (
    <Box component="div">
      <Stack
        css={[styles.width100, styles.contentBreak]}
        spacing={3}
        alignItems="center"
        justifyContent="center"
      >
        {smMediaQuery ? (
          <PageTitle
            css={styles.labelBreak}
            title={`${t("Long-term Planning")} (2025)`}
          />
        ) : null}

        <Stack flexDirection="row" spacing={0} alignItems="flex-end">
          <Box component="div">
            <Grid style={{ width: "40rem" }} container spacing={5}>
              <Grid item xs={12} sm={5}>
                <FormLabel component="legend">{t("Europe")}</FormLabel>
                <Stack flexDirection="row">
                  <FormControlLabel
                    control={<Checkbox checked={eu.Bulgaria} size="small" />}
                    label={t("Bulgaria")}
                  />
                  <FormControlLabel
                    control={<Checkbox checked={eu.Germany} size="small" />}
                    label={t("Italy")}
                  />
                  <FormControlLabel
                    control={<Checkbox checked={eu.Italy} size="small" />}
                    label={t("Spain")}
                  />
                </Stack>
              </Grid>
              <Grid item xs={1}>
                <Divider orientation="vertical" />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormLabel component="legend">{t("Asia")}</FormLabel>
                <Stack flexDirection="row">
                  <FormControlLabel
                    control={<Checkbox checked={eu.Italy} size="small" />}
                    label={t("China")}
                  />
                  <FormControlLabel
                    control={<Checkbox checked={eu.Germany} size="small" />}
                    label={t("Vietnam")}
                  />
                </Stack>
              </Grid>
            </Grid>
          </Box>

          <Divider
            style={{ height: "51px", marginTop: "auto", transform: "translateX(-70px)" }}
            orientation="vertical"
            flexItem
          />
          <Box component="div">
            <Box
              component="div"
              style={{ marginBottom: "-0.3rem", transform: "translateX(-25px)" }}
            >
              <RadioGroup
                label={t("Planning Mode")}
                radioOptions={useTranslateSchemeArray(planOptions)}
                value={t("Minimal Machine Park")}
                defaultChecked
                defaultValue={t("Minimal Machine Park")}
                row
              />
            </Box>
          </Box>
        </Stack>

        <Tabs
          css={styles.sectionBreak}
          value={tabView}
          onChange={(_, val) => setTabView(val)}
          aria-label="configure chart tabs"
        >
          <Tab label={t("Products")} disabled={pageLoading} />
          <Tab label={t("Machines")} disabled={pageLoading} />
        </Tabs>

        <Alert
          css={[styles.width100, styles.widthLimit25]}
          message={alertMessage || ""}
          showAlert={!!alertMessage}
          severity={formStatus}
        />
      </Stack>

      <Stack
        css={styles.contentBreak}
        direction="row"
        spacing={4}
        justifyContent="center"
      >
        <LabelWithBoldedPart
          text={t("Total Occupied Capacity")}
          bolded={`${Math.round(totalCapacity)} %`}
        />
        <LabelWithBoldedPart
          text={t("Total Free Capacity")}
          bolded={`${Math.round(totalFreeCapacity)} %`}
        />
      </Stack>

      {tabView === 0 ? (
        <ResponsiveTableGrid
          rows={productsRows}
          colSchema={useTranslateFields(productColumns, ["label"])}
          responsive="responsive"
          configuration={{
            density: "compact",
            initialRowsPerPage: 15,
            columnVisibility: { product_id: false },
          }}
          backdropLoading={formStatus === "loading"}
          loading={pageLoading}
          tableID="LongTermPlanning_Products_table"
        />
      ) : null}

      {tabView === 1 ? (
        <ResponsiveTableGrid
          rows={machineRows}
          colSchema={useTranslateFields(machineColumns, ["label"])}
          responsive="responsive"
          configuration={{
            density: "compact",
            initialRowsPerPage: 15,
            columnVisibility: {
              machine_id: false,
              maximum_speed: false,
              average_output_production_one_shift: false,
              average_output_production_two_shift: false,
              average_output_production_three_shift: false,
              average_output_production_one_week: false,
              average_output_production_one_month: false,
              average_output_production_three_month: false,
              average_output_production_six_month: false,
              average_output_production_one_year: false,
            },
          }}
          backdropLoading={formStatus === "loading"}
          loading={pageLoading}
          tableID="LongTermPlanning_Machines_table"
        />
      ) : null}

      <Modal
        open={!!openModal}
        onClose={() => setOpenModal(null)}
        fullWidth
        label={openModal?.type ? t(openModal?.type) : ""}
        unsavedChanges={unsavedChanges}
        setUnsavedChanges={setUnsavedChanges}
      >
        {openModal?.type === "Edit Machine" ? (
          <LongTermMachineForm
            machine={openModal.machine}
            onSubmit={handleFetch}
            allProducts={productsRows}
            handleSetUnsavedChanges={handleSetUnsavedChanges}
            setUnsavedChanges={setUnsavedChanges}
          />
        ) : null}

        {openModal?.type === "View Machine" ? (
          <LongTermMachineView machine={openModal.machine} />
        ) : null}

        {openModal?.type === "Edit Product" ? (
          <LongTermProductForm
            product={openModal.product}
            onSubmit={handleFetch}
            handleSetUnsavedChanges={handleSetUnsavedChanges}
            setUnsavedChanges={setUnsavedChanges}
          />
        ) : null}

        {openModal?.type === "View Product" ? (
          <LongTermProductView product={openModal.product} />
        ) : null}

        {openModal?.type === "Add New" ? (
          <LongTermAddNew
            onSubmit={handleFetch}
            allProducts={productsRows}
            handleSetUnsavedChanges={handleSetUnsavedChanges}
            setUnsavedChanges={setUnsavedChanges}
          />
        ) : null}

        {openModal?.type === "Delete Machine" ? (
          <LongTermDeleteModal
            product={openModal.product}
            machine={openModal.machine}
            onSubmit={handleOnDelete}
          />
        ) : null}
        {openModal?.type === "Delete Product" ? (
          <LongTermDeleteModal
            product={openModal.product}
            machine={openModal.machine}
            onSubmit={handleOnDelete}
          />
        ) : null}
      </Modal>
    </Box>
  );
};

export default LongTermPlanning;

interface ExtraTopNavMenuProps {
  handleOpenModal: () => void;
}

const ExtraTopNavMenu: React.FC<ExtraTopNavMenuProps> = ({ handleOpenModal }) => {
  const { t } = useLanguageContext();
  return (
    <Stack alignItems="center">
      <CustomIconsTooltip tooltipText={t("Add")}>
        <IconButton aria-label="add new template" onClick={handleOpenModal}>
          <AddOutlinedIcon />
        </IconButton>
      </CustomIconsTooltip>
    </Stack>
  );
};
