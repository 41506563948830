import { Query } from "../callApi";
import {
  PostQueryPidWorkflowCreateInput,
  PostQueryPidWorkflowUploadImageInput,
  PutQueryPidWorkflowInput,
} from "./apiPidWorkflowsInputs";

export const postQueryPidWorkflowCreate = (
  input: PostQueryPidWorkflowCreateInput
): Query => ({
  endpoint: "layouts/p_and_id/workflow",
  method: "POST",
  variables: input,
});

export const putQueryPidWorkflow = (
  input: PutQueryPidWorkflowInput,
  id: string
): Query => ({
  endpoint: `layouts/p_and_id/workflow/${id}`,
  method: "PUT",
  variables: input,
});

export const deleteQueryPidWorkflow = (id: string): Query => ({
  endpoint: `layouts/p_and_id/workflow/${id}`,
  method: "DELETE",
});

export const postQueryPidWorkflowUploadImage = (
  nodeID: string,
  input: PostQueryPidWorkflowUploadImageInput
): Query => ({
  endpoint: `layouts/p_and_id/node/${nodeID}/image`,
  method: "POST",
  variables: input,
  multipartForm: true,
});
