import { GraphqlFilter } from "../../SmallComponents/GraphqlFilters/graphqlFiltersUtils";
import {
  ChartColorScheme,
  PieDonutChartData,
} from "../EditExcellenceChartForms/excellenceChartFormUtils";

export type PieChartConfigAndData = {
  config: PieChartConfiguration;
  dataSchema: PieChartDataSchema | null;
  data: PieDonutChartData | null;
};

export type FiltersToDashboard = {
  isLegendEnabled?: boolean,
  isGroupParameterEnabled?: boolean
}

export type PieChartConfiguration = {
  enableArcLabels: boolean;
  enableArcLinkLabels: boolean;
  colors: ChartColorScheme;
  fixedDecimalValue?: boolean;
  decimalPoints?: number | string;
  graphqlFilters?: GraphqlFilter[];
  filtersToDashboard?: FiltersToDashboard;
};

export enum PieAggregationFunction {
  Average = "AVERAGE",
  Count = "COUNT",
  Max = "MAX",
  Min = "MIN",
  Sum = "SUM",
}
export type PieChartDataSchema = {
  startTime: string | null;
  endTime: string | null;
  parameters: PieChartParameter[];
  legend: PieChartLegend[];
};

export type PieChartParameter = {
  parameterId: string;
  parameterName?: string;
  container: string;
  aggregation?: "" | PieAggregationFunction;
};
export type PieChartLegend = {
  parameterId: string;
  parameterName?: string;
  container: string;
};

export type PieChartDataSchemaInput = {
  startTime: string | null;
  endTime: string | null;
  parameters?: Omit<PieChartParameter, "parameterName">;
  legend: Omit<PieChartLegend, "parameterName">[];
};

export const getPieCustomLegendParamName = (
  customLegendsLabel: string | number,
  paramMapping: Record<string, string>
): string => {
  const idMatch = `${customLegendsLabel}`.match(/id='([^']+)'/);

  if (idMatch) {
    const byIndex = (customLegendsLabel as string).indexOf(" by ");
    const byResult = (customLegendsLabel as string).substring(byIndex + 4);
    const id = idMatch[1];
    return `${paramMapping[id]} - ${byResult}`;
  }
  return `${customLegendsLabel}`;
};
