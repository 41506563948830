import { Typography, Stack } from "@mui/material";
import { useMemo, useState } from "react";
import { MQTTSubMapping } from "../../../../../Api/DataSources/apiDSDataTypes";
import { GetQueryMQTTTopicMessageSnippet } from "../../../../../Api/DataSources/apiDSSnippets";
import MultiStageForm from "../../../../SmallComponents/MultiStageForm/MultiStageForm";
import Select from "../../../../MaterialUI/FormFields/Select";
import { FormStatuses, SelectOption } from "../../../../../Global/Types/commonTypes";
import { CollapsibleTreeItemData } from "../../../../SmallComponents/CollapsibleSelectTree/collapsibleTreeTypes";
import { isObject } from "../../../../../Global/Utils/commonFunctions";
import CollapsibleSelectTree from "../../../../SmallComponents/CollapsibleSelectTree/CollapsibleSelectTree";
import { useTranslateArray } from "../../../../../Global/Hooks/useTranslations";
import { useLanguageContext } from "../../../../../context/LanguageContext";

const STAGE_TOPIC = "Select topic";
const STAGE_TIMESTAMP = "Select timestamp";
const STAGE_PARAMS = "Specify parameters";

const FORM_STAGES_WITH_ADD = [STAGE_TOPIC, STAGE_TIMESTAMP, STAGE_PARAMS];
const FORM_STAGES = FORM_STAGES_WITH_ADD.slice(1);

type ParameterMapping = {
  [key: string]: {
    path: string[];
    key: string;
  };
};

interface MQTTConfigTopicMappingProps {
  topicMapping?: MQTTSubMapping;
  setSubMapping: React.Dispatch<React.SetStateAction<MQTTSubMapping[]>>;
  handleCloseModal: () => void;
  selectedData: GetQueryMQTTTopicMessageSnippet | null;
  newTopicSelect?: {
    topicLabel: string;
    topicOptions: SelectOption[];
    setNewTopicToAdd: React.Dispatch<React.SetStateAction<string>>;
    handleFetchTopicData: (
      topic: string
    ) => Promise<GetQueryMQTTTopicMessageSnippet | null>;
  };
}

const MQTTConfigTopicMapping: React.FC<MQTTConfigTopicMappingProps> = ({
  topicMapping,
  setSubMapping,
  handleCloseModal,
  selectedData,
  newTopicSelect,
}) => {
  const { t } = useLanguageContext();
  const [selectedTimestampNodes, setSelectedTimestampNodes] = useState<string[]>(() =>
    getSelectedTimestamp(topicMapping)
  );
  const [selectedParameters, setSelectedParameters] = useState<string[]>(() =>
    getSelectedParams(topicMapping)
  );
  const [topic, setTopic] = useState<string | null>(topicMapping?.topic || null);

  const treeData = useMemo(
    () => (selectedData ? getNodes(selectedData, []) : []),
    [selectedData]
  );
  const treeDataMapping = getTreeDataMap(treeData);

  // multi-stage
  const [alertMessage, setAlertMessage] = useState<string | null>(null);
  const [alertStatus, setAlertStatus] = useState<FormStatuses>(null);
  const [activeStep, setActiveStep] = useState<number>(0);

  const formStages = newTopicSelect ? FORM_STAGES_WITH_ADD : FORM_STAGES;

  const handleSaveChanges = () => {
    if (!topic) {
      setAlertMessage(t("Bad data"));
      return;
    }

    const timestampMapping = treeDataMapping[selectedTimestampNodes[0]];
    const paramsMapping = selectedParameters.map((item) => treeDataMapping[item]);

    const updatedMapping: MQTTSubMapping = {
      parameter_mappings: paramsMapping,
      timestamp_mapping: timestampMapping,
      topic: topic,
    };

    if (topicMapping) {
      setSubMapping((prev) => {
        return prev.map((item) => {
          if (item.topic === topic) {
            return updatedMapping;
          }
          return item;
        });
      });
    } else {
      setSubMapping((prev) => [...prev, updatedMapping]);
    }

    handleCloseModal();
  };

  const handleOnNextStage = async () => {
    try {
      if (formStages[activeStep] === STAGE_TOPIC) {
        if (!newTopicSelect?.topicLabel) {
          setAlertStatus("warning");
          setAlertMessage(t("You must select a topic"));
          return false;
        }
        setAlertStatus("loading");
        setAlertMessage(t("Loading..."));
        const result = await newTopicSelect.handleFetchTopicData(
          newTopicSelect.topicLabel
        );
        if (!result) {
          throw new Error();
        }
        setTopic(newTopicSelect.topicLabel);
        setAlertStatus(null);
        setAlertMessage(null);
        return true;
      }

      if (formStages[activeStep] === STAGE_TIMESTAMP) {
        if (selectedTimestampNodes.length !== 1) {
          setAlertStatus("warning");
          setAlertMessage(t("You must select one timestamp key"));
          return false;
        }
        return true;
      }

      if (formStages[activeStep] === STAGE_PARAMS) {
        if (!selectedParameters.length) {
          setAlertStatus("warning");
          setAlertMessage(t("You must select at least one parameter"));
          return false;
        }
        handleSaveChanges();
        return false;
      }

      return false;
    } catch (err) {
      setAlertStatus("error");
      setAlertMessage(t("Something went wrong"));
      return false;
    }
  };

  return (
    <MultiStageForm
      steps={useTranslateArray(formStages)}
      activeStep={activeStep}
      setActiveStep={setActiveStep}
      handleOnNextStage={handleOnNextStage}
      alertMessage={alertMessage}
      alertStatus={alertStatus}
      disableNextButton={newTopicSelect && !newTopicSelect?.topicOptions.length}
      mobile
      title=""
    >
      {formStages[activeStep] === STAGE_TOPIC && newTopicSelect ? (
        <Stack spacing={3}>
          {newTopicSelect.topicOptions.length ? (
            <>
              <Select
                label={t("Select new topic")}
                selectOptions={newTopicSelect.topicOptions}
                onChange={(e) => newTopicSelect.setNewTopicToAdd(e.target.value)}
                value={newTopicSelect.topicLabel}
              />
              <Typography variant="body1">
                {t(
                  "For each topic we require a timestamp key and a set of parameter keys. Parameter key is the key for which you would collect data."
                )}
              </Typography>
            </>
          ) : (
            <Typography variant="body1" align="center">
              {t("There aren't any new topics to configure.")}
            </Typography>
          )}
        </Stack>
      ) : null}

      {formStages[activeStep] !== STAGE_TOPIC ? (
        <>
          {!selectedData ? (
            <Typography variant="h4" align="center">
              {t("Loading...")}
            </Typography>
          ) : (
            <>
              {formStages[activeStep] === STAGE_TIMESTAMP ? (
                <Stack spacing={2}>
                  <Typography variant="body1">
                    {t("You can select only one parameter")}
                  </Typography>
                  <CollapsibleSelectTree
                    data={treeData}
                    selected={selectedTimestampNodes}
                    setSelected={setSelectedTimestampNodes}
                    noParentSelections
                  />
                </Stack>
              ) : null}

              {formStages[activeStep] === STAGE_PARAMS ? (
                <Stack spacing={2}>
                  <Typography variant="body1">
                    {t("You can select multiple parameters")}
                  </Typography>
                  <CollapsibleSelectTree
                    data={treeData}
                    selected={selectedParameters}
                    setSelected={setSelectedParameters}
                    noParentSelections
                  />
                </Stack>
              ) : null}
            </>
          )}
        </>
      ) : null}
    </MultiStageForm>
  );
};

export default MQTTConfigTopicMapping;

const getNodes = (
  data: Record<string, any>,
  path: string[]
): CollapsibleTreeItemData[] => {
  const result: CollapsibleTreeItemData[] = [];

  if (isObject(data)) {
    Object.entries(data).forEach(([key, value]) => {
      const childNodes = getNodes(value, [...path, key]);

      result.push({
        data: {
          path: [...path, key],
        },
        id: key,
        uniqueID: key,
        label: key,
        children: childNodes?.length > 0 ? childNodes : undefined,
      });
    });
  }

  return result;
};

const getSelectedTimestamp = (topicMapping?: MQTTSubMapping) => {
  if (topicMapping?.timestamp_mapping.key) {
    return [topicMapping.timestamp_mapping.key];
  }
  return [];
};

const getSelectedParams = (topicMapping?: MQTTSubMapping) => {
  const result: string[] = [];
  topicMapping?.parameter_mappings.forEach((item) => {
    if (item && item.key) {
      result.push(item.key);
    }
  });

  return result;
};

const getTreeDataMap = (data: (CollapsibleTreeItemData | null)[]): ParameterMapping => {
  const removeNulls = data.filter((node) => !!node?.id) as CollapsibleTreeItemData[];

  const result = removeNulls.reduce((acc, curr) => {
    const children = curr.children ? getTreeDataMap(curr.children) : {};

    return {
      ...acc,
      [curr.id]: {
        key: curr.id,
        path: curr.data.path,
      },
      ...children,
    };
  }, {} as ParameterMapping);

  return result;
};
