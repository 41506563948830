import { css, SerializedStyles } from "@emotion/react";
import { Box, Stack } from "@mui/material";
import { useCallback, useEffect, useRef, useState } from "react";
import ReactFlow, {
  useNodesState,
  useEdgesState,
  addEdge,
  useReactFlow,
  ReactFlowProvider,
  Connection,
  Edge,
  Controls,
  MiniMap,
  Panel,
  Node,
  Background,
  BackgroundVariant,
} from "reactflow";
import useTheme from "@mui/material/styles/useTheme";
import { v4 as uuidv4 } from "uuid";
import throttle from "lodash.throttle";
import VerticalAlignCenterIcon from "@mui/icons-material/VerticalAlignCenter";
import AlignHorizontalLeftIcon from "@mui/icons-material/AlignHorizontalLeft";
import "reactflow/dist/style.css";
import {
  PidFlowNewNodeModal,
  PidWorkflowDataField,
  PidWorkflowType,
  PidWorkflowViewMode,
} from "../pidWorkflowUtils";
import cssLayoutStyles from "../../../../Global/Styles/layout";
import { getWorkflowLayoutedElements } from "../../../../Components/SmallComponents/ReactFlow/reactFlowUtils";
import Button from "../../../../Components/MaterialUI/Button";
import PidFlowCreateNewNodeModal from "./Forms/PidFlowCreateNewNodeModal";
import PidWorkflowInitialNode from "./Nodes/Initial/PidWorkflowInitialNode";
import {
  PID_FLOW_NODE_TYPE,
  PID_WORKFLOW_EDIT_MODE_NODE_STYLE,
  PID_WORKFLOW_VIEW_MODE_NODE_STYLE,
} from "./Nodes/pidNodesUtils";
import PidWorkflowTextNode from "./Nodes/Text/PidWorkflowTextNode";
import PidWorkflowSvgNode from "./Nodes/Svg/PidWorkflowSvgNode";
import PidWorkflowInputNode from "./Nodes/Input/PidWorkflowInputNode";
import PidWorkflowBackgroundNode from "./Nodes/Background/PidWorkflowBackgroundNode";
import {
  getQueryPidWorkflowFields,
  getQueryPidWorkflowImages,
} from "../../../../Api/PidWorkflows/apiPidWorkflowsGetQueries";
import {
  GetQueryPidWorkflowFieldsSnippet,
  GetQueryPidWorkflowImagesSnippet,
} from "../../../../Api/PidWorkflows/apiPidWorkflowsSnippets";
import callApi from "../../../../Api/callApi";
import { useAuthedContext } from "../../../../context/AuthContext";
import LoadingBackdrop from "../../../../Components/MaterialUI/LoadingBackdrop";
import { useLanguageContext } from "../../../../context/LanguageContext";

const cssStyles = {
  flowWrapper: css({
    " .react-flow__node": {
      border: "1px solid black",
    },
    " .react-flow__node.selected": {
      border: "1px solid red",
    },
    ".react-flow__edges": {
      zIndex: "500 !important",
    },
  }),
};

interface ReactFlowCustomProps {
  css?: SerializedStyles[] | SerializedStyles;
  className?: string;
  workflow: PidWorkflowType;
  initialNodeUpdated: boolean;
  setWorkflow: React.Dispatch<React.SetStateAction<PidWorkflowType | null>>;
  viewMode: PidWorkflowViewMode;
}

const PidWorkflow: React.FC<ReactFlowCustomProps> = ({
  className,
  workflow,
  initialNodeUpdated,
  setWorkflow,
  viewMode,
}) => {
  return (
    <ReactFlowProvider>
      <PidFlow
        className={className}
        workflow={workflow}
        initialNodeUpdated={initialNodeUpdated}
        setWorkflow={setWorkflow}
        viewMode={viewMode}
      />
    </ReactFlowProvider>
  );
};

export default PidWorkflow;

// -------- KanFlow Logic --------
const fitViewOptions = {
  padding: 0.2,
  maxZoom: 1,
};
const proOptions = { hideAttribution: true };
const nodeTypes = {
  [PID_FLOW_NODE_TYPE.initial]: PidWorkflowInitialNode,
  [PID_FLOW_NODE_TYPE.Text]: PidWorkflowTextNode,
  [PID_FLOW_NODE_TYPE.SVG]: PidWorkflowSvgNode,
  [PID_FLOW_NODE_TYPE.Input]: PidWorkflowInputNode,
  [PID_FLOW_NODE_TYPE.Background]: PidWorkflowBackgroundNode,
};

interface PidFlowProps {
  css?: SerializedStyles[] | SerializedStyles;
  className?: string;
  workflow: PidWorkflowType;
  initialNodeUpdated: boolean;
  setWorkflow: React.Dispatch<React.SetStateAction<PidWorkflowType | null>>;
  viewMode: PidWorkflowViewMode;
}

const PidFlow: React.FC<PidFlowProps> = ({
  className,
  workflow,
  initialNodeUpdated,
  setWorkflow,
  viewMode,
}) => {
  const { t } = useLanguageContext();
  const theme = useTheme();
  const styles = { ...cssStyles, ...cssLayoutStyles };
  const [loading, setLoading] = useState<boolean>(true);
  const reactFlowWrapper = useRef<HTMLDivElement>(null);
  const intervalRef = useRef<any>(null);
  const connectingNodeId = useRef<any>(null);
  const { project } = useReactFlow();
  const { setAuthedUser } = useAuthedContext();

  const [nodes, setNodes, onNodesChange] = useNodesState(workflow.nodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(workflow.edges);

  const [newNodeModal, setNewNodeModal] = useState<PidFlowNewNodeModal>(null);

  console.log("nodes ", nodes);

  useEffect(() => {
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
        intervalRef.current = null;
      }
    };
  }, []);

  useEffect(() => {
    (async () => {
      setLoading(true);
      await handleNodeImages();
      if (viewMode === "View Mode") {
        await fetchDataAndUpdateNodes();
        intervalRef.current = setInterval(fetchDataAndUpdateNodes, 5000);
      } else {
        if (intervalRef.current) {
          clearInterval(intervalRef.current);
          intervalRef.current = null;
        }
        await fetchDataAndUpdateNodes();
      }
      setLoading(false);
      return () => {
        if (intervalRef.current) {
          clearInterval(intervalRef.current);
          intervalRef.current = null;
        }
      };
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewMode]);

  const fetchDataAndUpdateNodes = async () => {
    try {
      const res = await callApi<GetQueryPidWorkflowFieldsSnippet>({
        query: getQueryPidWorkflowFields(workflow.id),
        auth: { setAuthedUser },
      });

      const fieldDataMapping: Record<string, string> = {};
      res.data.forEach((item) => {
        fieldDataMapping[item.node_id] = item.value;
      });

      const updatedNodes = handleUpdateNodesBasedOnMode(
        nodes,
        viewMode,
        fieldDataMapping
      );

      setNodes(updatedNodes);
    } catch (err) {
      console.log("PidFlow err ", err);
    }
  };

  // eslint-disable-next-line
  const updateWorkflowThrottled = useCallback(
    throttle((updatedNodes, updatedEdges) => {
      setWorkflow((prev) => {
        if (prev?.id) {
          return {
            ...prev,
            nodes: updatedNodes,
            edges: updatedEdges,
          };
        }
        return null;
      });
    }, 1000),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const handleNodeImages = async () => {
    try {
      const imagesRes = await callApi<GetQueryPidWorkflowImagesSnippet>({
        query: getQueryPidWorkflowImages(),
        auth: { setAuthedUser },
      });

      const updatedNodes = handleUpdateNodesImages(nodes, imagesRes || []);
      setNodes(updatedNodes);
      console.log("res ", imagesRes);
    } catch (err) {
      console.log("handleNodeImages ", err);
    }
  };

  /**
   * keep workflow nodes up to date
   */
  useEffect(() => {
    updateWorkflowThrottled(nodes, edges);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nodes, edges]);

  /**
   * update nodes when the initial node changes
   * from the edit workflow form
   */
  useEffect(() => {
    setNodes(workflow.nodes);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialNodeUpdated]);

  /** When a node connects to another */
  const onConnect = useCallback((params: Edge | Connection) => {
    setEdges((eds) => addEdge({ ...params, type: "step" }, eds));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // When new connection is initialized
  const onConnectStart = useCallback((_: any, { nodeId }: { nodeId: any }) => {
    connectingNodeId.current = nodeId;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // When connection is completed
  const onConnectEnd = useCallback(
    (event: any) => {
      const targetIsPane = event.target.classList.contains("react-flow__pane");

      if (targetIsPane && reactFlowWrapper.current) {
        // we need to remove the wrapper bounds, in order to get the correct position
        const { top, left } = reactFlowWrapper.current.getBoundingClientRect();
        const id = uuidv4().split("-")[0];

        setNewNodeModal({
          id,
          // we are removing the half of the node width (75) to center the new node
          position: project({
            x: event.clientX - left + 75,
            y: event.clientY - top,
          }),
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [project]
  );

  /** Changes the layout and sorts nodes */
  const handleLayoutChange = useCallback(
    (direction: "TB" | "LR") => {
      const { nodes: layoutedNodes, edges: layoutedEdges } = getWorkflowLayoutedElements(
        nodes,
        edges,
        direction
      );

      setNodes([...layoutedNodes]);
      setEdges([...layoutedEdges]);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [nodes, edges]
  );

  return (
    <>
      <Box
        component="div"
        css={[styles.width100, styles.height100, styles.relative]}
        className={className}
        ref={reactFlowWrapper}
      >
        <Box
          sx={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}
          component="div"
        >
          <LoadingBackdrop loading={loading} />
        </Box>

        <ReactFlow
          css={styles.flowWrapper}
          nodes={nodes}
          edges={edges}
          nodeTypes={nodeTypes}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onConnect={onConnect}
          onConnectStart={onConnectStart}
          onConnectEnd={onConnectEnd}
          fitView
          fitViewOptions={fitViewOptions}
          minZoom={0.5}
          maxZoom={2}
          proOptions={proOptions}
          // Lock in View mode
          nodesDraggable={viewMode === "Edit Mode"}
          nodesConnectable={viewMode === "Edit Mode"}
          elementsSelectable={viewMode === "Edit Mode"}
        >
          {viewMode === "Edit Mode" ? (
            <Panel position="top-right">
              <Stack direction="row" spacing={2}>
                <Button
                  variant="text"
                  size="small"
                  color={theme.palette.mode === "light" ? "info" : "primary"}
                  onClick={() => handleLayoutChange("TB")}
                  startIcon={<VerticalAlignCenterIcon />}
                >
                  {t("Vertical")}
                </Button>
                <Button
                  variant="text"
                  size="small"
                  color={theme.palette.mode === "light" ? "info" : "primary"}
                  onClick={() => handleLayoutChange("LR")}
                  startIcon={<AlignHorizontalLeftIcon />}
                >
                  {t("Horizontal")}
                </Button>
              </Stack>
            </Panel>
          ) : null}

          {viewMode === "Edit Mode" ? (
            <>
              <Controls fitViewOptions={fitViewOptions} />

              <Background
                color={
                  theme.palette.mode === "light" ? theme.palette.grey[100] : "#1a1818"
                }
                variant={BackgroundVariant.Lines}
                gap={70}
              />
            </>
          ) : null}
          <MiniMap nodeStrokeWidth={3} zoomable pannable />
        </ReactFlow>

        <PidFlowCreateNewNodeModal
          newNodeModal={newNodeModal}
          setNewNodeModal={setNewNodeModal}
          setNodes={setNodes}
          setEdges={setEdges}
          connectingNodeId={connectingNodeId}
        />
      </Box>
    </>
  );
};

const handleUpdateNodesBasedOnMode = (
  nodes: Node[],
  viewMode: PidWorkflowViewMode,
  fieldDataMapping: Record<string, string>
): Node[] => {
  const willBeViewMode = viewMode === "View Mode";

  const updatedNodes: Node[] = nodes.map((item) => {
    const isInputNode = item.data.type === PID_FLOW_NODE_TYPE.Input;
    const inputNodeDataField: PidWorkflowDataField | null = isInputNode
      ? {
          ...(item.data?.dataField || {}),
          value: fieldDataMapping?.[item.data?.id || ""],
        }
      : null;

    const nodeData = {
      ...item.data,
      mode: viewMode,
      ...(isInputNode && { dataField: inputNodeDataField }),
    };

    const nodeStyle = {
      ...item.style,
      ...(willBeViewMode
        ? PID_WORKFLOW_EDIT_MODE_NODE_STYLE
        : PID_WORKFLOW_VIEW_MODE_NODE_STYLE),
    };

    return {
      ...item,
      style: nodeStyle,
      // hidden:
      //   viewMode === "View Mode" && item.type === PID_FLOW_NODE_TYPE.initial
      //     ? true
      //     : false,
      data: nodeData,
    };
  });

  return updatedNodes;
};

const handleUpdateNodesImages = (
  nodes: Node[],
  images: GetQueryPidWorkflowImagesSnippet
): Node[] => {
  const imagesMapping: Record<string, string> = {};
  images.forEach((img) => {
    imagesMapping[img.node_id] = img.file_url;
  });

  return nodes.map((item) => {
    const isBgNode = item.data.type === PID_FLOW_NODE_TYPE.Background;
    const imgUrl = imagesMapping[item?.data?.id || ""];

    return {
      ...item,
      data: {
        ...item.data,
        ...(isBgNode && { bgImageUrl: imgUrl || null }),
      },
    };
  });
};
