import { ResponsiveLine, Serie } from "@nivo/line";
import { getChartColors } from "../nivoTheme";
import { Box, Typography } from "@mui/material";
import useTheme from "@mui/material/styles/useTheme";
import React from "react";
import { subDays } from "date-fns";
import cssLayoutStyles from "../../../Global/Styles/layout";
import cssSpacingStyles from "../../../Global/Styles/spacing";
import cssComponentsStyles from "../../../Global/Styles/components";
import { TooltipPoint } from "./indicatorWidgetTypes";
import { formatNumber } from "../../../Global/Utils/commonFunctions";

interface IndicatorWidgetChartProps {
  isInteractive?: boolean;
  chartData: number[];
  decimalPoints: string | number | undefined;
  fixedDecimalValue: boolean | undefined;
}

const IndicatorWidgetChart: React.FC<IndicatorWidgetChartProps> = ({
  isInteractive,
  chartData,
  decimalPoints,
  fixedDecimalValue,
}) => {
  const theme = useTheme();

  const properData: Serie[] = [
    {
      id: "line-1",
      name: "Line 1",
      data: chartData.map((item, index) => ({
        y: item,
        x: subDays(new Date(), index).toISOString(),
      })),
    },
  ];

  return (
    <Box
      component="div"
      sx={{
        width: isInteractive ? theme.breakpoints.values.lg : "45%",
        height: isInteractive ? 300 : 90,
        zIndex: 1,
      }}
    >
      <ResponsiveLine
        data={properData}
        margin={isInteractive ? { top: 40, right: 90, bottom: 43, left: 43 } : undefined}
        xScale={{ type: "point" }}
        yScale={{
          type: "linear",
          stacked: true,
          reverse: false,
        }}
        theme={{
          text: {
            fill: theme.palette.common.black,
          },
          crosshair:
            theme.palette.mode === "dark"
              ? {
                  line: {
                    stroke: theme.palette.common.black,
                    strokeWidth: 1,
                  },
                }
              : undefined,
          tooltip: {
            container: {
              background: theme.palette.common.white,
            },
          },
        }}
        yFormat=" >-.2f"
        axisTop={null}
        axisRight={null}
        axisBottom={
          isInteractive
            ? {
                tickSize: 5,
                tickPadding: 5,
                tickValues: "10",
                tickRotation: 0,
                legendOffset: 36,
                legendPosition: "middle",
                truncateTickAt: 0,
              }
            : null
        }
        axisLeft={
          isInteractive
            ? {
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legendOffset: -40,
                legendPosition: "middle",
                truncateTickAt: 0,
              }
            : null
        }
        enableGridX={false}
        enableGridY={false}
        colors={getChartColors()}
        enablePoints={false}
        enableCrosshair={isInteractive}
        crosshairType="x"
        isInteractive={isInteractive}
        useMesh
        tooltip={(point) => (
          <CustomTooltip
            pointData={point.point.data}
            decimalPoints={decimalPoints}
            fixedDecimalValue={fixedDecimalValue}
          />
        )}
      />
    </Box>
  );
};

export default IndicatorWidgetChart;

interface CustomTooltipProps {
  pointData: TooltipPoint;
  decimalPoints: string | number | undefined;
  fixedDecimalValue: boolean | undefined;
}

const CustomTooltip: React.FC<CustomTooltipProps> = ({
  pointData,
  decimalPoints,
  fixedDecimalValue,
}) => {
  const theme = useTheme();
  const styles = {
    ...cssLayoutStyles,
    ...cssSpacingStyles(theme),
    ...cssComponentsStyles(theme),
  };

  if (!pointData) {
    return null;
  }

  const getFormattedValue = () => {
    try {
      const value = pointData.y;
      if (value instanceof Date) {
        return value.toISOString();
      }
      const result = formatNumber(value, fixedDecimalValue, decimalPoints);
      if (!result) {
        return `${value}`;
      }
      return result;
    } catch (err) {
      return `${pointData.y}`;
    }
  };

  const formattedValue = getFormattedValue();

  return (
    <Box
      component="div"
      css={[
        styles.card,
        styles.flexCenter,
        styles.leftRightPadding2,
        styles.tooltipStyle,
      ]}
      gap={1}
    >
      <Typography variant="body2">{formattedValue}</Typography>
    </Box>
  );
};
