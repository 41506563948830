import { useState } from "react";
import { Avatar, Box, Stack, css } from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import Button from "../../MaterialUI/Button";
import callApi from "../../../Api/callApi";
import {
  postQueryUserDeleteProfilePicture,
  postQueryUserUploadProfilePicture,
} from "../../../Api/Auth/apiAuthPostQueries";
import { useAuthedContext } from "../../../context/AuthContext";
import { PostQueryUserUploadProfilePictureSnippet } from "../../../Api/Auth/apiAuthSnippets";
import Alert from "../../MaterialUI/Alert";
import { FileWithPath } from "react-dropzone";
import FileDropzone from "../../SmallComponents/Dropzone/FileDropzone";
import LabelWithBoldedPart from "../../MaterialUI/LabelWithBoldedPart";
import cssLayoutStyles from "../../../Global/Styles/layout";
import { PostQueryUserUploadProfilePictureInput } from "../../../Api/Auth/apiAuthInputs";
import { FormStatuses } from "../../../Global/Types/commonTypes";
import { useLanguageContext } from "../../../context/LanguageContext";

const cssStyles = {
  accountIcon: css({
    fontSize: "12rem",
    color: "gray",
  }),
  accountImage: css({
    width: "12rem",
    height: "12rem",
  }),
};

export const ProfilePictureModalLayout = () => {
  const { t } = useLanguageContext();
  const styles = { ...cssStyles, ...cssLayoutStyles };
  const { authedUser, setAuthedUser, profilePicture, setProfilePicture } =
    useAuthedContext();
  const [alertMessage, setAlertMessage] = useState<string | null>(null);
  const [alertStatus, setAlertStatus] = useState<FormStatuses | null>(null);
  const [file, setFile] = useState<FileWithPath | null>(null);

  const handleUploadProfilePicture = async () => {
    try {
      setAlertMessage(null);
      setAlertStatus(null);

      if (!file) {
        throw new Error(t("You must upload a profile picture"));
      }

      const input: PostQueryUserUploadProfilePictureInput = {
        image: file,
      };

      const response = await callApi<PostQueryUserUploadProfilePictureSnippet>({
        query: postQueryUserUploadProfilePicture(input),
        auth: { setAuthedUser },
      });

      if (response) {
        setProfilePicture({ url: response.url });
        setAlertMessage(t("Profile picture uploaded successfully"));
        setAlertStatus("success");
        setFile(null);
      } else {
        throw new Error("Error uploading the profile picture");
      }
    } catch (err) {
      console.log("Error uploading profile picture: ", err);
      setAlertMessage(t("Error uploading profile picture"));
      setAlertStatus("error");
    }
  };

  const handleDeleteProfilePicture = async () => {
    try {
      setAlertMessage(null);
      setAlertStatus(null);

      await callApi<any>({
        query: postQueryUserDeleteProfilePicture(),
        auth: { setAuthedUser },
      });

      setProfilePicture({ url: "" });
      setAlertMessage(t("Profile picture deleted successfully"));
      setAlertStatus("success");
    } catch (err) {
      console.log("Error deleting profile picture: ", err);
      setAlertMessage(t("Error deleting profile picture"));
      setAlertStatus("error");
    }
  };

  return (
    <Stack justifyContent="center" alignItems="center">
      {profilePicture?.url ? (
        <Avatar
          css={styles.accountImage}
          src={profilePicture.url}
          alt={`${authedUser?.given_name} ${authedUser?.family_name}`}
        />
      ) : (
        <AccountCircleIcon css={styles.accountIcon} />
      )}

      <Stack direction="row" gap={2} my={2}>
        <Box component="div" css={[styles.flexCenter, styles.flexColumn]}>
          {!file ? (
            <FileDropzone
              setFiles={(files) => setFile(files[0])}
              setErrorMessage={setAlertMessage}
            >
              <Button>{t("Upload Picture")}</Button>
            </FileDropzone>
          ) : (
            <Button onClick={handleUploadProfilePicture}>{t("Upload")}</Button>
          )}

          {file?.name ? (
            <Box component="div" mt={2}>
              <LabelWithBoldedPart text={t("Uploaded file")} bolded={file.name} />
            </Box>
          ) : null}
        </Box>

        {!file && profilePicture?.url && (
          <Button onClick={handleDeleteProfilePicture} color="error">
            {t("Delete")}
          </Button>
        )}
      </Stack>

      <Alert
        message={alertMessage || ""}
        showAlert={!!alertMessage}
        severity={alertStatus}
      />
    </Stack>
  );
};
