import { useState } from "react";
import {
  DaSubscription,
  MQTTSubscription,
  ModbusSubscription,
  UaSubscription,
} from "../../../../Api/DataSources/apiDSDataTypes";
import { formatDateAndTime } from "../../../../Global/Utils/commonFunctions";
import { Box, Stack } from "@mui/material";
import LabelWithBoldedPart from "../../../MaterialUI/LabelWithBoldedPart";
import Button from "../../../MaterialUI/Button";
import Modal from "../../../MaterialUI/Modal";
import OpcDaHistoryModalContent from "./DaConnections/OpcDaHistoryModalContent";
import OpcUaHistoryModalContent from "./UaConnections/OpcUaHistoryModalContent";
import OpcMqttHistoryModalContent from "./MqttConnections/OpcMqttHistoryModalContent";
import OpcModbusHistoryModalContent from "./ModbusConnections/OpcModbusHistoryModalContent";
import cssLayoutStyles from "../../../../Global/Styles/layout";
import { useTranslatedModalTitle } from "../../../../Global/Hooks/useTranslations";
import { useLanguageContext } from "../../../../context/LanguageContext";

const modalTitleTranslations = {
  "Items and Properties": "Items and Properties",
  "Previous Version Difference": "Previous Version Difference",
} as const;

type ModalLabel = keyof typeof modalTitleTranslations;

interface OpcHistoryCardProps {
  connection: {
    da?: {
      sub: DaSubscription;
      previousSub: DaSubscription | null;
    };
    ua?: {
      sub: UaSubscription;
      previousSub: UaSubscription | null;
    };
    mqtt?: {
      sub: MQTTSubscription;
      previousSub: MQTTSubscription | null;
    };
    modbus?: {
      sub: ModbusSubscription;
      previousSub: ModbusSubscription | null;
    };
  };
  version: number;
  isLatest?: boolean;
  startTime: Date | string | null;
  endTime: Date | string | null;
  hasPreviousSub: boolean;
}

const OpcHistoryCard: React.FC<OpcHistoryCardProps> = ({
  connection,
  version,
  isLatest,
  startTime,
  endTime,
  hasPreviousSub,
}) => {
  const { t } = useLanguageContext();
  const styles = { ...cssLayoutStyles };

  const [modalLabel, setModalLabel] = useState<ModalLabel | null>(null);
  const getTranslatedModalTitle = useTranslatedModalTitle(modalTitleTranslations);

  const subStartTime = startTime ? formatDateAndTime(startTime) : "N/A";
  const subEndTime = endTime ? formatDateAndTime(endTime) : "It's active";

  const handleOpen = (label: ModalLabel) => {
    setModalLabel(label);
  };

  return (
    <>
      <Stack justifyContent="space-between" spacing={2} css={styles.height100}>
        <Box component="div">
          <LabelWithBoldedPart
            text={t("History Version")}
            bolded={`${version} ${isLatest ? " - Latest" : ""}`}
          />
          <LabelWithBoldedPart text={t("Started on")} bolded={subStartTime} />
          <LabelWithBoldedPart text={t("Ended on")} bolded={subEndTime} />
        </Box>

        <Stack spacing={1}>
          {connection.mqtt ? null : (
            <Button
              variant="text"
              color="primary"
              onClick={() => handleOpen("Items and Properties")}
            >
              {t("Review Items and Properties")}
            </Button>
          )}
          {!hasPreviousSub ? null : (
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => handleOpen("Previous Version Difference")}
            >
              {t("Compare with Previous")}{" "}
            </Button>
          )}
        </Stack>
      </Stack>

      <Modal
        open={!!modalLabel}
        onClose={() => setModalLabel(null)}
        label={modalLabel ? getTranslatedModalTitle(modalLabel) : ""}
        fullWidth
        maxWidth={"xl"}
      >
        {modalLabel ? (
          <>
            {connection.da ? (
              <OpcDaHistoryModalContent
                sub={connection.da.sub}
                isShowDifference={modalLabel === "Previous Version Difference"}
                previousSub={connection.da.previousSub}
              />
            ) : null}

            {connection.ua ? (
              <OpcUaHistoryModalContent
                sub={connection.ua.sub}
                isShowDifference={modalLabel === "Previous Version Difference"}
                previousSub={connection.ua.previousSub}
              />
            ) : null}

            {connection.mqtt ? (
              <OpcMqttHistoryModalContent
                sub={connection.mqtt.sub}
                isShowDifference={modalLabel === "Previous Version Difference"}
                previousSub={connection.mqtt.previousSub}
              />
            ) : null}

            {connection.modbus ? (
              <OpcModbusHistoryModalContent
                sub={connection.modbus.sub}
                isShowDifference={modalLabel === "Previous Version Difference"}
                previousSub={connection.modbus.previousSub}
              />
            ) : null}
          </>
        ) : null}
      </Modal>
    </>
  );
};

export default OpcHistoryCard;
