import { IconButton, Stack } from "@mui/material";
import { useEffect,useState } from "react";
import CustomIconsTooltip from "../../../SmallComponents/Tooltip/CustomIconsTooltip";
import { useLanguageContext } from "../../../../context/LanguageContext";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import Modal from "../../../MaterialUI/Modal";
import { useTranslatedModalTitle } from "../../../../Global/Hooks/useTranslations";
import CreateInvoice from "./CreateInvoice";
import UploadInvoice from "./UploadInvoice";
import { ModalTitle, modalTitleTranslations } from "./InvoiceTypes";
import { getQueryExportIngoingInvoices } from "../../../../pages/Finance/API/apiFinanceGetQueries";
import { getQueryExportOutgoingInvoices } from "../../../../pages/Finance/API/apiFinanceGetQueries";
import callApi from "../../../../Api/callApi";
import { useAuthedContext } from "../../../../context/AuthContext";


interface InvoiceRightMenuProps {
  setRefreshTable: React.Dispatch<React.SetStateAction<boolean>>;
  tabView: "ingoing" | "outgoing";
}

const InvoiceRightMenu: React.FC<InvoiceRightMenuProps> = ({
  setRefreshTable,
  tabView
}) => {
  console.log(tabView);
  const { setAuthedUser } = useAuthedContext();
  const { t } = useLanguageContext();
  const getTranslatedModalTitle = useTranslatedModalTitle(modalTitleTranslations);

  const [modalTitle, setModalTitle] = useState<ModalTitle | null>(null);

  useEffect(() => {
    console.log("tabView updated:", tabView);
  }, [tabView]);
  
  const handleDownloadInvoice = async () => {
    let query: any = null;
    let filename: string = "";
    console.log(tabView);
    if (tabView === "outgoing") {
      query = getQueryExportOutgoingInvoices();
      filename = "outgoing_invoices.xlsx";
    } else {
      query = getQueryExportIngoingInvoices();
      filename = "ingoing_invoices.xlsx";
    }


    try {
      let result: any = null;
      result = await callApi<any>({
        query: query,
        auth: { setAuthedUser },
      });

      const blob = await result.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
  } catch (error) {
    console.error(error);
  }
}


  return (
    <>
      <Stack alignItems="center">
        <Stack alignItems="center">
          <CustomIconsTooltip tooltipText={t("Add")}>
            <IconButton
              aria-label="add new widget"
              onClick={() => setModalTitle("Create new Invoice")}
            >
              <AddOutlinedIcon />
            </IconButton>
          </CustomIconsTooltip>
        </Stack>
        <Stack alignItems="center">
          <CustomIconsTooltip tooltipText={t("Upload")}>
            <IconButton
              aria-label="upload file"
              onClick={() => setModalTitle("Upload File")}
            >
              <FileUploadIcon />
            </IconButton>
          </CustomIconsTooltip>
        </Stack>
        <Stack alignItems="center">
          <CustomIconsTooltip tooltipText={t("Export")}>
            <IconButton
              aria-label="export file"
              onClick={() => handleDownloadInvoice()}
            >
              <FileDownloadIcon />
            </IconButton>
          </CustomIconsTooltip>
        </Stack>
      </Stack>

      <Modal
        open={!!modalTitle}
        onClose={() => setModalTitle(null)}
        fullWidth
        maxWidth={"sm"}
        label={modalTitle ? getTranslatedModalTitle(modalTitle) : ""}
      >
        {modalTitle === "Create new Invoice" ? (
          <CreateInvoice />
        ) : null}

        {modalTitle === "Upload File" ? (
          <UploadInvoice onCloseModal={() => {
            setModalTitle(null); 
            setRefreshTable((prev) => !prev)
          }} />
        ) : null}

      </Modal>
    </>
  );
};

export default InvoiceRightMenu;

