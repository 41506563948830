import {
  TableGridConfiguration,
  TableGridHandleSaveCellChanges,
  tableGridAddNewRowData,
} from "./tableGridUtils";
import { useState, useCallback, useEffect, useMemo, memo } from "react";
import { Box, useMediaQuery } from "@mui/material";
import TableGrid from "./TableGrid";
import { SerializedStyles } from "@emotion/react";
import MobileTableGrid from "./MobileTableGrid/MobileTableGrid";
import {
  MobileTableGridFormattedRow,
  mobileTableGridHandleFormatRows,
} from "./MobileTableGrid/mobileTableGridUtils";
import constructTableGridColumns, { TableGridColumnSchema } from "./constructTableGrid";
import LoadingBackdrop from "../../MaterialUI/LoadingBackdrop";
import { MRT_TableState } from "material-react-table";
import { useAuthedContext } from "../../../context/AuthContext";
import callApi from "../../../Api/callApi";
import { postQueryUserPreference } from "../../../Api/UserPreferences/apiUserPreferencesPostQueries";
import { getQueryUserPreference } from "../../../Api/UserPreferences/apiUserPreferencesGetQueries";
import Modal from "../../MaterialUI/Modal";
import { useLanguageContext } from "../../../context/LanguageContext";
import TableGridConfigModal from "./TableGridConfigModal";
import { FormStatuses } from "../../../Global/Types/commonTypes";
import { PostQueryUserPreferenceInput } from "../../../Api/UserPreferences/apiUserPreferencesInputs";
import isEqual from "lodash.isequal";

type TableUserPreferences<T extends Record<string, any>> = {
  state: MRT_TableState<T> | null;
  colSchema: TableGridColumnSchema[];
};

interface ResponsiveTableGridProps<T extends Record<string, any>> {
  css?: SerializedStyles[] | SerializedStyles;
  className?: string;
  rows: T[];
  setRows?: React.Dispatch<React.SetStateAction<T[]>>;
  colSchema: TableGridColumnSchema[];
  editMode?: boolean;
  setEditMode?: React.Dispatch<React.SetStateAction<boolean>>;
  responsive: "mobile" | "desktop" | "responsive";
  configuration?: TableGridConfiguration;
  isStatic?: boolean;
  onRowClick?: (row: T) => void;
  loading?: boolean;
  backdropLoading?: boolean;
  onSaveRows?: (rows: T[], editedRows: T[]) => Promise<void>;
  tableID?: string;
  hideConfigButton?: boolean;
  onLoadingConfigState?: () => void;
  virtualizedColsNumber?: number;
}

const ResponsiveTableGrid = <T extends Record<string, any>>({
  className,
  rows,
  setRows,
  colSchema,
  editMode,
  setEditMode,
  responsive,
  configuration,
  isStatic,
  onRowClick,
  loading,
  backdropLoading,
  onSaveRows,
  tableID,
  hideConfigButton,
  onLoadingConfigState,
  virtualizedColsNumber,
}: ResponsiveTableGridProps<T>) => {
  const [internalRows, setInternalRows] = useState<T[]>(rows); // Current rows
  const [_, setEditedRows] = useState<T[]>([]); // Track edited rows
  const [editedRowIndex, setEditedRowIndex] = useState<number | null>(null);
  const [saveLoading, setSaveLoading] = useState<boolean>(false);
  const [tableUserState, setTableUserState] = useState<TableUserPreferences<T>>({
    state: null,
    colSchema: colSchema,
  });
  const [openConfigModal, setOpenConfigModal] = useState<boolean>(false);
  const [loadingConfigState, setLoadingConfigState] = useState<FormStatuses>("loading");
  const [reFetch, setReFetch] = useState<boolean>(false);

  const mdMediaQuery = useMediaQuery("(max-width:899px)");
  const { setAuthedUser } = useAuthedContext();
  const { t } = useLanguageContext();

  useEffect(() => {
    const dynamicColSchema: TableGridColumnSchema[] = [];
    const currentColsMapping: Record<string, TableGridColumnSchema> = {};
    tableUserState.colSchema.forEach((col) => {
      currentColsMapping[col.id] = col;
    });

    // if col exists use its saved value
    colSchema.forEach((col) => {
      const savedCol = currentColsMapping?.[col.id];
      if (savedCol?.id) {
        dynamicColSchema.push(savedCol);
      } else {
        dynamicColSchema.push(col);
      }
    });

    setTableUserState((prev) => ({ ...prev, colSchema: dynamicColSchema }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [colSchema]);

  useEffect(() => {
    (async () => {
      try {
        if (tableID) {
          setLoadingConfigState("loading");
          const stateData = await callApi<TableUserPreferences<T>>({
            query: getQueryUserPreference(tableID),
            auth: { setAuthedUser },
          });
          if (!stateData?.colSchema && !stateData?.state) {
            throw new Error("Not configured");
          }
          setTableUserState(stateData);
          setLoadingConfigState("success");
        } else {
          // no tableID so no save user preferences
          setLoadingConfigState("error");
        }
      } catch (err) {
        console.log("err ", err);
        setLoadingConfigState(null);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableID, reFetch]);

  useEffect(() => {
    setInternalRows(() => rows);
  }, [rows]);

  useEffect(() => {
    // reset edited rows on mode change
    setEditedRows(() => []);
  }, [editMode]);

  // Compare rows to detect changes
  useEffect(() => {
    handleCompareEditedRows();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [internalRows]);

  useEffect(() => {
    (async () => {
      if (onLoadingConfigState) {
        onLoadingConfigState();
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingConfigState]);

  const handleCompareEditedRows = () => {
    const newEditedRows = internalRows.filter((row, index) => {
      return !isEqual(row, rows[index]);
    });
    setEditedRows(newEditedRows);
    return newEditedRows;
  };

  const handleColSchemaSave = async (newColSchema: TableGridColumnSchema[]) => {
    try {
      if (tableID) {
        setSaveLoading(true);
        const updatedUserPref: TableUserPreferences<T> = {
          state: tableUserState?.state || null,
          colSchema: newColSchema,
        };

        const input: PostQueryUserPreferenceInput = {
          preference_key: tableID,
          preferences: updatedUserPref,
        };

        // save table in user preferences
        // Update existing
        await callApi({
          query: postQueryUserPreference(input),
          auth: { setAuthedUser },
        });
        setReFetch((prev) => !prev);
      }
    } catch (err) {
      console.log("handleColSchemaSave err ", err);
    }
    setOpenConfigModal(false);
    setSaveLoading(false);
  };

  const handleOnReset = async () => {
    setTableUserState({
      state: null,
      colSchema: colSchema,
    });
    setOpenConfigModal(false);
  };

  const handleStateSave = async (newState: MRT_TableState<T>) => {
    try {
      if (tableID) {
        setSaveLoading(true);
        const updatedUserPref: TableUserPreferences<T> = {
          state: newState,
          colSchema: tableUserState.colSchema,
        };

        const input: PostQueryUserPreferenceInput = {
          preference_key: tableID,
          preferences: updatedUserPref,
        };
        // 1. save table in user preferences
        await callApi({
          query: postQueryUserPreference(input),
          auth: { setAuthedUser },
        });
      }

      if (setRows && editMode) {
        // 2. saving table rows
        if (onSaveRows) {
          // func call needed, otherwise, the last change won't
          // be detected
          const newEditedRows = handleCompareEditedRows();
          await onSaveRows(internalRows, newEditedRows);
        }
        setRows(() => internalRows);
        // reset edited rows on save changes
        setEditedRows(() => []);
      }

      setReFetch((prev) => !prev);
    } catch (err) {
      console.log("handleStateSave err ", err);
    }
    setSaveLoading(false);
  };

  const handleSaveCellChanges: TableGridHandleSaveCellChanges = useCallback(
    (rowIndex, colKey, value) => {
      setEditedRowIndex(rowIndex);
      setInternalRows((prev) => {
        const copyRows = structuredClone(prev);
        copyRows[rowIndex][colKey as keyof T] = value;
        return copyRows;
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const columns = useMemo(() => {
    return constructTableGridColumns(tableUserState.colSchema, handleSaveCellChanges);
  }, [handleSaveCellChanges, tableUserState]);

  const [mobileRows, setMobileRows] = useState<MobileTableGridFormattedRow[]>(() =>
    mobileTableGridHandleFormatRows(internalRows, columns)
  );

  useEffect(() => {
    setMobileRows(() => mobileTableGridHandleFormatRows(internalRows, columns));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [internalRows, columns]);

  const handleDeleteRow = useCallback((rowIndex: number) => {
    setInternalRows((prev) => {
      const copyRows = structuredClone(prev);
      copyRows.splice(rowIndex, 1);
      return copyRows;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAddNewRow = useCallback(() => {
    setInternalRows((prev) => {
      const newRow: T = columns.reduce((acc, curr) => {
        const colData = curr.columns?.length
          ? curr.columns.reduce((colAcc, colCurr) => {
              const nestedColData = tableGridAddNewRowData(colCurr);
              return {
                ...colAcc,
                ...nestedColData,
              };
            }, {})
          : tableGridAddNewRowData(curr);

        return {
          ...acc,
          ...colData,
        };
      }, {} as T);

      return [newRow, ...prev];
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columns]);

  const columnStyles = tableUserState.colSchema.map((col) => ({
    id: col.id,
    width: col.width ? `${col.width}px` : "auto",
  }));

  return (
    <>
      {responsive === "mobile" || (responsive === "responsive" && mdMediaQuery) ? (
        <Box component="div" style={{ position: "relative" }}>
          {loadingConfigState === "loading" ? (
            <div style={{ width: "100%", height: "300px" }} />
          ) : (
            <MobileTableGrid
              className={className}
              mobileRows={mobileRows}
              columns={columns}
              editMode={
                editMode
                  ? {
                      handleDeleteRow: handleDeleteRow,
                      handleAddNewRow: handleAddNewRow,
                      handleSaveCellChanges: handleSaveCellChanges,
                    }
                  : undefined
              }
              editedRowIndex={editedRowIndex}
              configuration={configuration}
              loading={loading}
              onSave={(state) => handleStateSave(state as MRT_TableState<T>)}
              tableState={tableUserState?.state as any}
              openConfigModal={() => setOpenConfigModal(true)}
              loadingConfigState={loadingConfigState}
              hideConfigButton={!!hideConfigButton}
            />
          )}
          <LoadingBackdrop
            loading={!!backdropLoading || saveLoading || loadingConfigState === "loading"}
          />
        </Box>
      ) : (
        <Box component="div" style={{ position: "relative" }}>
          {loadingConfigState === "loading" ? (
            <div style={{ width: "100%", height: "300px" }} />
          ) : (
            <TableGrid
              className={className}
              rows={internalRows}
              columns={columns}
              editMode={
                editMode
                  ? {
                      handleDeleteRow: handleDeleteRow,
                      handleAddNewRow: handleAddNewRow,
                      handleSaveCellChanges: handleSaveCellChanges,
                    }
                  : undefined
              }
              setEditMode={setEditMode}
              configuration={configuration}
              isStatic={isStatic}
              // @ts-ignore
              onRowClick={onRowClick}
              loading={loading}
              columnStyles={columnStyles}
              onSave={(state) => handleStateSave(state as MRT_TableState<T>)}
              tableState={tableUserState?.state as any}
              openConfigModal={() => setOpenConfigModal(true)}
              loadingConfigState={loadingConfigState}
              hideConfigButton={!!hideConfigButton}
              virtualizedColsNumber={virtualizedColsNumber}
            />
          )}
          <LoadingBackdrop
            loading={!!backdropLoading || saveLoading || loadingConfigState === "loading"}
          />
        </Box>
      )}

      <Modal
        open={openConfigModal}
        onClose={() => setOpenConfigModal(false)}
        fullWidth
        label={t("Table Configuration")}
      >
        <TableGridConfigModal
          colSchema={tableUserState.colSchema}
          onSave={handleColSchemaSave}
          rows={internalRows}
          tableID={tableID}
          handleClose={handleOnReset}
        />
      </Modal>
    </>
  );
};

export default memo(ResponsiveTableGrid);
