import React from "react";
import {
  Grid,
  IconButton,
  Tooltip,
  Button,
  Divider,
  useTheme,
  Box,
  Typography,
  Link,
} from "@mui/material";
import { Add } from "@mui/icons-material";
import { useLanguageContext } from "../../../context/LanguageContext";
import cssLayoutStyles from "../../../Global/Styles/layout";
import UploadHelpFile from "./UploadHelpFile";
import Alert from "../../MaterialUI/Alert";
import { FormStatuses } from "../../../Global/Types/commonTypes";
import { GetQueryHelpSelectionsForPage } from "../../../Api/Help/apiHelpSnippets";
import { getSelectionOrder, ModalHelpTitle, PageKey } from "./helpUtils";
import { Link as RouterLink } from "react-router-dom";

interface HelpPageButtonsProps {
  isEditMode: boolean;
  canUserEditAddDelete: boolean | undefined;
  alertMessage: string | null;
  formStatus: FormStatuses;
  handleModalOpen: (label: ModalHelpTitle) => void;
  toggleEditMode: () => void;
  handleExitEditMode: () => void;
  allSelections: GetQueryHelpSelectionsForPage;
  setUnsavedEditChanges: React.Dispatch<React.SetStateAction<boolean>>;
  uploadType: "video" | "image" | null;
  setUploadType: React.Dispatch<React.SetStateAction<"video" | "image" | null>>;
  setAlertMessage: React.Dispatch<React.SetStateAction<string | null>>;
  setFormStatus: React.Dispatch<React.SetStateAction<FormStatuses>>;
  setTempSelections: React.Dispatch<React.SetStateAction<GetQueryHelpSelectionsForPage>>;
  setDraftSelections: React.Dispatch<React.SetStateAction<GetQueryHelpSelectionsForPage>>;
  currentPage: PageKey | undefined;
}

const HelpPageButtons: React.FC<HelpPageButtonsProps> = ({
  isEditMode,
  canUserEditAddDelete,
  alertMessage,
  formStatus,
  handleModalOpen,
  toggleEditMode,
  handleExitEditMode,
  allSelections,
  setUnsavedEditChanges,
  uploadType,
  setUploadType,
  setAlertMessage,
  setFormStatus,
  setTempSelections,
  setDraftSelections,
  currentPage,
}) => {
  const { t } = useLanguageContext();
  const theme = useTheme();
  const styles = {
    ...cssLayoutStyles,
  };

  return (
    <Box component="div" marginTop={2}>
      <Grid container alignItems="center" justifyContent="center" mb={1.5} spacing={1.5}>
        {canUserEditAddDelete ? (
          <Grid item xs={12} container justifyContent="center" spacing={2}>
            {isEditMode ? (
              <>
                <Grid item xs={3}>
                  <Button
                    css={[styles.width100, styles.widthLimit15]}
                    onClick={handleExitEditMode}
                    color="error"
                  >
                    {t("Cancel")}
                  </Button>
                </Grid>

                <Grid item>
                  <Tooltip title="Add Text">
                    <IconButton
                      sx={{
                        "&:hover": {
                          color: theme.palette.primary.main,
                        },
                      }}
                      onClick={() => handleModalOpen("Add Help Content")}
                    >
                      <Add />
                    </IconButton>
                  </Tooltip>
                </Grid>

                <Grid item>
                  <UploadHelpFile
                    currentPage={currentPage}
                    order={getSelectionOrder(allSelections)}
                    setUnsavedEditChanges={setUnsavedEditChanges}
                    uploadType={uploadType}
                    setUploadType={setUploadType}
                    setAlertMessage={setAlertMessage}
                    setFormStatus={setFormStatus}
                    setTempSelections={setTempSelections}
                    setDraftSelections={setDraftSelections}
                  />
                </Grid>

                <Grid item xs={3}>
                  <Button
                    css={[styles.width100, styles.widthLimit15]}
                    onClick={() => handleModalOpen("Save Changes")}
                  >
                    {t("Save Changes")}
                  </Button>
                </Grid>

                {alertMessage && (
                  <Grid
                    item
                    xs={12}
                    mt={0.5}
                    display="flex"
                    justifyContent="center"
                    width="100%"
                  >
                    <Alert
                      message={alertMessage}
                      showAlert={!!alertMessage}
                      severity={formStatus}
                    />
                  </Grid>
                )}
              </>
            ) : (
              <Grid container justifyContent="center" mt={2}>
                <Button
                  css={[styles.width100, styles.widthLimit10]}
                  variant="contained"
                  onClick={toggleEditMode}
                >
                  {t("Edit Mode")}
                </Button>
              </Grid>
            )}
          </Grid>
        ) : null}

        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item container xs={8} justifyContent="center" spacing={2}>
          <Grid item xs={6}>
            <Button
              css={[styles.width100, styles.widthLimit15]}
              variant="outlined"
              onClick={() => handleModalOpen("Report Bug")}
            >
              {t("Report Bug")}
            </Button>
          </Grid>

          <Grid item xs={6}>
            <Button
              css={[styles.width100, styles.widthLimit15]}
              variant="outlined"
              onClick={() => handleModalOpen("Propose an Improvement")}
            >
              {t("Propose an Improvement")}
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Divider />
      <Box component="div" marginTop={2} paddingLeft={2}>
        <Typography variant="body2" mb={2}>
          <Link
            component={RouterLink}
            to="/release-notes"
            underline="none"
            color="primary"
          >
            {t("Release Notes")}
          </Link>
        </Typography>
      </Box>
    </Box>
  );
};

export default HelpPageButtons;
