import { IconButton, Menu, MenuItem, Tooltip, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import VideoLibraryIcon from "@mui/icons-material/VideoLibrary";
import PhotoLibraryIcon from "@mui/icons-material/PhotoLibrary";
import { HelpSelection, ModalHelpTitle, PageKey } from "./helpUtils";
import { FileWithPath } from "react-dropzone";
import FileDropzone from "../../SmallComponents/Dropzone/FileDropzone";
import { useLanguageContext } from "../../../context/LanguageContext";
import { FormStatuses } from "../../../Global/Types/commonTypes";
import callApi from "../../../Api/callApi";
import { postQueryHelpUploadPageFile } from "../../../Api/Help/apiHelpPostQueries";
import { useAuthedContext } from "../../../context/AuthContext";
import { GetQueryHelpSelectionsForPage } from "../../../Api/Help/apiHelpSnippets";
import { v4 as uuidv4 } from "uuid";

interface UploadHelpFileProps {
  currentPage: PageKey | undefined;
  order: number;
  setUnsavedEditChanges: React.Dispatch<React.SetStateAction<boolean>>;
  uploadType: "video" | "image" | null;
  setUploadType: React.Dispatch<React.SetStateAction<"video" | "image" | null>>;
  helpSelectionId?: string;
  setAlertMessage: React.Dispatch<React.SetStateAction<string | null>>;
  setFormStatus: React.Dispatch<React.SetStateAction<FormStatuses | null>>;
  setOpenInfo?: React.Dispatch<React.SetStateAction<boolean>>;
  setModalTitle?: React.Dispatch<React.SetStateAction<ModalHelpTitle | null>>;
  setTempSelections: React.Dispatch<React.SetStateAction<GetQueryHelpSelectionsForPage>>;
  setDraftSelections: React.Dispatch<React.SetStateAction<GetQueryHelpSelectionsForPage>>;
  isExistingSelection?: boolean;
}

const UploadHelpFile: React.FC<UploadHelpFileProps> = ({
  currentPage,
  order,
  setUnsavedEditChanges,
  uploadType,
  setUploadType,
  helpSelectionId,
  setAlertMessage,
  setFormStatus,
  setOpenInfo,
  setModalTitle,
  setTempSelections,
  setDraftSelections,
  isExistingSelection,
}) => {
  const theme = useTheme();
  const { t } = useLanguageContext();
  const { setAuthedUser } = useAuthedContext();
  const [selectedFile, setSelectedFile] = useState<FileWithPath | null>(null);
  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);
  const openMenu = Boolean(menuAnchor);

  useEffect(() => {
    if (selectedFile) {
      handleFileUpload();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFile]);

  const handleFileUpload = async () => {
    if (setOpenInfo && setModalTitle) {
      setOpenInfo(false);
      setModalTitle(null);
    }

    if (!selectedFile || !uploadType || !currentPage) return;

    try {
      setAlertMessage(t("Uploading..."));
      setFormStatus("loading");

      const fileUrl = await callApi<string>({
        query: postQueryHelpUploadPageFile(currentPage.id, selectedFile),
        auth: { setAuthedUser },
      });

      const newSelection: HelpSelection = {
        order: order,
        content: fileUrl,
        type: uploadType,
        id: helpSelectionId ? helpSelectionId : uuidv4(),
      };

      if (helpSelectionId) {
        if (isExistingSelection) {
          setDraftSelections((prev) =>
            prev.map((sel) => (sel.id === helpSelectionId ? newSelection : sel))
          );
        } else {
          setTempSelections((prev) =>
            prev.map((sel) => (sel.id === helpSelectionId ? newSelection : sel))
          );
        }
      } else {
        setTempSelections((prev) => [...prev, newSelection]);
      }

      setUnsavedEditChanges(true);
      setAlertMessage(t("Successfully uploaded"));
      setFormStatus("success");
    } catch (error) {
      console.error("Failed to upload file:", error);
      setAlertMessage(t("Failed to upload file"));
      setFormStatus("error");
    }
  };

  const handleUploadTypeChange = (type: "video" | "image") => {
    setUploadType(type);
    setSelectedFile(null);
    setMenuAnchor(null);
  };

  const renderDropzoneMenuItem = (
    label: string,
    icon: JSX.Element,
    fileType: "video" | "image"
  ) => (
    <FileDropzone
      setFiles={(files) => setSelectedFile(files[0])}
      setErrorMessage={setAlertMessage}
      acceptVideos={fileType === "video"}
      acceptImages={fileType === "image"}
    >
      <MenuItem onClick={() => handleUploadTypeChange(fileType)}>
        {icon}
        <Typography>{label}</Typography>
      </MenuItem>
    </FileDropzone>
  );

  return (
    <>
      <Tooltip title={t("Upload File")}>
        <IconButton
          sx={{
            "&:hover": {
              color: theme.palette.primary.main,
            },
          }}
          onClick={(e) => setMenuAnchor(e.currentTarget)}
        >
          <DriveFolderUploadIcon />
        </IconButton>
      </Tooltip>

      <Menu
        anchorEl={menuAnchor}
        open={openMenu}
        onClose={() => setMenuAnchor(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        {renderDropzoneMenuItem(
          t("Upload Video"),
          <VideoLibraryIcon sx={{ mr: 1 }} />,
          "video"
        )}
        {renderDropzoneMenuItem(
          t("Upload Photo"),
          <PhotoLibraryIcon sx={{ mr: 1 }} />,
          "image"
        )}
      </Menu>
    </>
  );
};

export default UploadHelpFile;
