import { Box, Card, IconButton, Stack, Typography, useTheme } from "@mui/material";
import { useState } from "react";
import Button from "../../../MaterialUI/Button";
import { FormStatuses } from "../../../../Global/Types/commonTypes";
import { useLanguageContext } from "../../../../context/LanguageContext";
import Alert from "../../../MaterialUI/Alert";
import { FileWithPath } from "react-dropzone";
import callApi from "../../../../Api/callApi";
import { postQueryUploadInvoice } from "../../../../pages/Finance/API/apiFinancePostQueries";
import { useAuthedContext } from "../../../../context/AuthContext";
import FileDropzone from "../../../SmallComponents/Dropzone/FileDropzone";
import cssSpacingStyles from "../../../../Global/Styles/spacing";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { Invoice, InvoiceType } from "./InvoiceTypes";
import Modal from "../../../MaterialUI/Modal";
import EditInvoice from "./EditInvoice";

const invoiceInitial: Invoice = {
  currency: '',
  customer_name: '',
  customer_vat_number: '',
  date: '',
  discount: 0,
  due_date: '',
  invoice_number: '',
  net_total: 0,
  protocol_id: '',
  purchase_order_id: '',
  quantity: 0,
  sales_order_id: '',
  status: '',
  supplier_name: '',
  supplier_vat_number: '',
  total_excise_amount: 0,
  unit_price: 0,
  vat_amount: 0
};

interface UploadInvoiceProps {
  onCloseModal: () => void;
}

const UploadInvoice: React.FC<UploadInvoiceProps> = ({
  onCloseModal
}) => {
  const theme = useTheme();
  const styles = { ...cssSpacingStyles(theme) };
  const { t } = useLanguageContext();
  const { setAuthedUser } = useAuthedContext();

  const [file, setFile] = useState<FileWithPath | null>(null);
  const [invoice, setInvoice] = useState<Invoice>(invoiceInitial);

  const [formStatus, setFormStatus] = useState<FormStatuses>(null);
  const [alertMessage, setAlertMessage] = useState<string | null>(null);
  const [editModalOpen, setEditModalOpen] = useState<boolean>(false);

  const handleSubmit = async () => {
    if (!file) {
      alert("No file selected.");
      return;
    }

    try {
      setAlertMessage("Uploading...");
      setFormStatus("loading");
      const invoiceType: InvoiceType = { invoice_type: 'ingoing' };
      const invoiceResponse = await callApi<any>({
        query: postQueryUploadInvoice({ file: file, invoice_type: invoiceType.invoice_type, supplier_id: 'string' }),
        auth: { setAuthedUser },
      });
      setEditModalOpen(true);
      setInvoice(invoiceResponse);

      setAlertMessage("Upload successful!");
      setFormStatus("success");
    } catch (error) {
      console.error("Error uploading file:", error);
      if ( error.detail ) {
        // TO DO check error from callApi
        setAlertMessage(error.detail);
      } else {
        setAlertMessage("Invoice has already been processed");
        // setAlertMessage("Upload failed. Please try again.");
      }
    } finally {
      setFormStatus(null);
    }
  };

  return (
    <>
      {file ? (
        <Stack css={[styles.sectionBreak, styles.leftRightPadding1]} spacing={3}>
          <Card>
            <Box component="div" css={styles.fullPadding1}>
              <Stack direction={"row"} spacing={3} alignItems="center">
                <Typography style={{ flex: 1 }} variant="body1">
                  {file.name}
                </Typography>
                <Stack spacing={2} direction="row" alignItems="center">

                  <IconButton
                    aria-label="delete file"
                    onClick={() => {
                      setFile(null);
                      setAlertMessage(null);
                    }}
                  >
                    <DeleteOutlineOutlinedIcon color="error" />
                  </IconButton>
                </Stack>
              </Stack>
            </Box>
          </Card>
        </Stack>
      ) : null}

      <Stack
        css={styles.sectionBreak}
        spacing={2}
        alignItems="center"
        justifyContent="center"
      >
        {
          !file &&
          <FileDropzone
            setFiles={(files) => setFile(files[0])}
            setErrorMessage={setAlertMessage}
            accept={{
              ".pdf": ["application/pdf"],
            }}
          >
            <Button
              color="secondary"
            >
              {t("Upload File")}
            </Button>
          </FileDropzone>
        }

        <Typography variant="caption" color="textSecondary">
          {t("Accepted file formats")}: .pdf
        </Typography>

        {
          file &&
          <Button onClick={handleSubmit} loading={formStatus === "loading"}>
            Submit
          </Button>
        }
      </Stack>

      <Modal
        open={editModalOpen}
        onClose={() => setEditModalOpen(false)}
        fullWidth
        maxWidth={"sm"}
        label={"Edit Invoice"}
      >
        <EditInvoice invoice={invoice} onSubmit={() => {
          setEditModalOpen(false); 
          onCloseModal();
        }} />
      </Modal>

      <Alert
        message={alertMessage || ""}
        showAlert={!!alertMessage}
        severity={formStatus}
      />
    </>
  );
};

export default UploadInvoice;
