import { Query } from "../callApi";

export const getQueryProjects = (): Query => ({
  endpoint: "resource_planning/detailed_planning/projects",
  method: "GET",
});

export const getQueryProject = (id: string): Query => ({
  endpoint: `/resource_planning/detailed_planning/project/${id}`,
  method: "GET",
});
export const getQueryProducts = (): Query => ({
  endpoint: `resource_planning/detailed_planning/products`,
  method: "GET",
});
