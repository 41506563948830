import { Query } from "../callApi";

export const getQueryHelpPageSelections = (page_key_id: string): Query => ({
  endpoint: `help/page/${page_key_id}/selections`,
  method: "GET",
});

export const getQueryHelpPageKeys = (): Query => ({
  endpoint: `help/page_keys`,
  method: "GET",
});

export const getQueryHelpPageKey = (page_key_id: string): Query => ({
  endpoint: `help/page_key/${page_key_id}`,
  method: "GET",
});

export const getQueryHelpDraftSelections = (page_key_id: string): Query => ({
  endpoint: `help/page/${page_key_id}/draft`,
  method: "GET",
});
