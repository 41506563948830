import { useEffect, useState } from "react";
import { YUP_REQUIRED_STRING, YUP_STRING } from "../../../Global/Constants/yupConstants";
import { object } from "yup";
import { FormStatuses, SelectOption } from "../../../Global/Types/commonTypes";
import { Formik } from "formik";
import { Box, Stack, Typography, useTheme } from "@mui/material";
import Button from "../../MaterialUI/Button";
import Alert from "../../MaterialUI/Alert";
import callApi, { ResponseError } from "../../../Api/callApi";
import Select from "../../MaterialUI/FormFields/Select";
import { PostQueryCodeScanningReadCodeSnippet } from "../../../Api/VisionControl/apiVisionControlSnippets";
import CameraOrDeviceUpload from "../VisionControl/LabelRecognition/CameraOrDeviceUpload";
import { FileWithPath } from "react-dropzone";
import Checkbox from "../../MaterialUI/FormFields/Checkbox";
import LabelWithBoldedPart from "../../MaterialUI/LabelWithBoldedPart";
import { postQueryCodeScanningReadCode } from "../../../Api/VisionControl/apiVisionControlPostQueries";
import { PostQueryCodeScanningReadCodeInput } from "../../../Api/VisionControl/apiVisionControlInputs";
import { useAuthedContext } from "../../../context/AuthContext";
import TextField from "../../MaterialUI/FormFields/TextFields";
import cssLayoutStyles from "../../../Global/Styles/layout";
import { useDetectFormsUnsavedChanges } from "../../../Global/Hooks/useDetectFormsUnsavedChanges";
import {
  GetQueryLocationsSnippet,
  GetQueryLocationsTypesSnippets,
} from "../../../Api/Locations/apiLocationSnippets";
import { LocationData } from "../../../Api/Locations/apiLocationsDataTypes";
import {
  getQueryLocationsTypes,
  getQueryLocationTypeParents,
} from "../../../Api/Locations/apiLocationsGetQueries";
import { handleGetSelectOption } from "../../../Global/Utils/commonFunctions";
import { PostQueryCreateLocationInput } from "../../../Api/Locations/apiLocationsInputs";
import {
  postQueryCreateLocation,
  putQueryLocation,
} from "../../../Api/Locations/apiLocationsPostQueries";
import { useLanguageContext } from "../../../context/LanguageContext";

type ScannedImage = {
  data: PostQueryCodeScanningReadCodeSnippet;
  imageUrl: string;
};

const fieldValidation = object({
  locationCode: YUP_REQUIRED_STRING,
  locationName: YUP_REQUIRED_STRING,
  locationType: YUP_REQUIRED_STRING,
  locationParent: YUP_STRING,
});

type MachineFormValues = {
  locationCode: string;
  locationName: string;
  locationType: string;
  locationParent: string;
};

interface MaintainLocationsFormProps {
  locationData: LocationData | null;
  setLocationsData: React.Dispatch<
    React.SetStateAction<GetQueryLocationsSnippet | undefined>
  >;
  fetchLocations: () => void;
  setOpenLocationModal: React.Dispatch<React.SetStateAction<boolean>>;
  handleSetUnsavedChanges: (unsavedChanges: boolean) => void;
  setUnsavedChanges: React.Dispatch<React.SetStateAction<boolean>>;
}

const MaintainLocationsForm: React.FC<MaintainLocationsFormProps> = ({
  locationData,
  setLocationsData,
  fetchLocations,
  setOpenLocationModal,
  handleSetUnsavedChanges,
  setUnsavedChanges,
}) => {
  const { t } = useLanguageContext();
  const theme = useTheme();
  const styles = { ...cssLayoutStyles };
  const { setAuthedUser } = useAuthedContext();
  const [formStatus, setFormStatus] = useState<FormStatuses>(null);
  const [alertMessage, setAlertMessage] = useState<string | null>(null);
  const [file, setFile] = useState<FileWithPath | null>(null);
  const [scannedImage, setScannedImage] = useState<ScannedImage | null>(null);
  const [isCheckedType, setIsCheckedType] = useState<boolean>(false);
  const [isCheckedScan, setIsCheckedScan] = useState<boolean>(true);
  const [selectedLocation, setSelectedLocation] = useState<string | null>(null);
  const [selectedLocationType, setSelectedLocationType] = useState<string | null>(null);
  const [locationTypeOptions, setLocationTypeOptions] = useState<SelectOption[]>([]);
  const [locationTypeParentsOptions, setLocationTypeParentsOptions] = useState<
    SelectOption[]
  >([]);

  const initialValues: MachineFormValues = {
    locationCode: locationData?.location_code || "",
    locationName: locationData?.location
      ? locationData.location.split("/").pop() || ""
      : "",
    locationType: locationData?.location_type || "",
    locationParent: locationData?.location
      ? locationData.location.split("/").slice(0, -1).join("/")
      : "",
  };

  useEffect(() => {
    fetchLocationsTypesOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedLocationType) {
      fetchLocationsTypesPerentsOptions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLocationType]);

  const fetchLocationsTypesOptions = async () => {
    try {
      const locationsTypes = await callApi<GetQueryLocationsTypesSnippets>({
        query: getQueryLocationsTypes,
        auth: { setAuthedUser },
      });

      const options = handleGetSelectOption(locationsTypes.data);
      setLocationTypeOptions(options);
    } catch (err) {
      console.log("Failed to fetch locationsTypes", err.message);
    }
  };

  const fetchLocationsTypesPerentsOptions = async () => {
    try {
      if (!selectedLocationType) return;

      const locationsTypesParents = await callApi<GetQueryLocationsSnippet>({
        query: getQueryLocationTypeParents(selectedLocationType),
        auth: { setAuthedUser },
      });

      const locationParents = locationsTypesParents.map((location) => {
        return { description: location.location, value: location.id };
      });

      const options = locationParents;
      setLocationTypeParentsOptions(options);
    } catch (err) {
      console.log("Failed to fetch locationsTypesParents", err.message);
    }
  };

  useEffect(() => {
    (async () => {
      if (file) {
        try {
          setFormStatus("loading");
          setAlertMessage(t("Loading..."));

          const input: PostQueryCodeScanningReadCodeInput = {
            image: file,
          };
          const scannedData = await callApi<
            PostQueryCodeScanningReadCodeSnippet | ResponseError
          >({
            query: postQueryCodeScanningReadCode(input),
            auth: { setAuthedUser },
          });
          if ("detail" in scannedData) {
            setAlertMessage(t("No code found to read its data"));
            return;
          }
          const imageUrl = URL.createObjectURL(file);

          setScannedImage({
            data: scannedData,
            imageUrl: imageUrl,
          });
          setAlertMessage(null);
          setFormStatus("success");
        } catch (err) {
          console.log("useEffect err ", err.message);
          setAlertMessage(t("Something went wrong"));
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file]);

  const handleFormSubmit = async (values: MachineFormValues) => {
    try {
      setFormStatus("loading");
      setAlertMessage(t("Loading..."));

      if (isCheckedScan && !selectedLocation && !locationData) {
        setFormStatus("error");
        setAlertMessage(t("Please select a location code from the scanned data"));
        return;
      }

      const body: PostQueryCreateLocationInput = {
        location_code: selectedLocation ? selectedLocation : values.locationCode,
        location_name: values.locationName,
        location_type: values.locationType,
        is_deleted: locationData?.is_deleted || false,
        is_location: locationData?.is_location || true,
        is_child_of: values.locationParent,
      };

      if (locationData) {
        const updatedLocation = await callApi<PostQueryCreateLocationInput>({
          query: putQueryLocation(body, locationData.id),
          auth: { setAuthedUser },
        });

        if (updatedLocation) {
          setLocationsData((prev: any) => {
            if (!prev) return prev;

            return prev.map((obj: any) => {
              if (obj.location_code === locationData.location_code) {
                return updatedLocation;
              }
              return obj;
            });
          });
        }
      } else {
        const createdLocation = await callApi<PostQueryCreateLocationInput>({
          query: postQueryCreateLocation(body),
          auth: { setAuthedUser },
        });

        if (createdLocation) {
          setLocationsData((prev: any) => {
            if (!prev) return prev;

            return [...prev, createdLocation];
          });
        }
      }

      setFormStatus("success");
      fetchLocations();
      setAlertMessage(t("Location updated successfully"));
      setUnsavedChanges(false);
      setOpenLocationModal(false);
    } catch (err) {
      console.log("MaintenanceMachineForm, handleFormSubmit() err: ", err.message);
      setFormStatus("error");
      setAlertMessage(err.message);
    }
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={handleFormSubmit}
        validationSchema={fieldValidation}
        enableReinitialize
      >
        {({ handleSubmit, handleChange, touched, errors, values }) => {
          useDetectFormsUnsavedChanges(initialValues, values, handleSetUnsavedChanges);

          return (
            <form onSubmit={handleSubmit}>
              <Stack gap={3}>
                <Select
                  name="locationType"
                  selectOptions={locationTypeOptions}
                  label={t("Location Type")}
                  value={values.locationType}
                  onChange={(e) => {
                    setSelectedLocationType(e.target.value);
                    handleChange(e);
                  }}
                  error={touched.locationType && !!errors.locationType}
                  helperText={touched.locationType && errors.locationType}
                  disabled={formStatus === "loading"}
                />

                {values.locationType && values.locationType !== "Site" ? (
                  <Select
                    name="locationParent"
                    selectOptions={locationTypeParentsOptions}
                    label={t("Location")}
                    value={values.locationParent}
                    onChange={handleChange}
                    error={touched.locationParent && !!errors.locationParent}
                    helperText={touched.locationParent && errors.locationParent}
                    disabled={formStatus === "loading"}
                  />
                ) : null}

                {!scannedImage && !locationData ? (
                  <Stack
                    width="100%"
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                  >
                    <Checkbox
                      label={t("Scan a code")}
                      checked={isCheckedScan}
                      onChange={() => {
                        if (isCheckedType) {
                          setIsCheckedType(false);
                        }
                        setIsCheckedScan((prev) => !prev);
                      }}
                      disabled={formStatus === "loading"}
                    />
                    <Checkbox
                      label={t("Type a code")}
                      checked={isCheckedType}
                      onChange={() => {
                        if (isCheckedScan) {
                          setIsCheckedScan(false);
                        }
                        setIsCheckedType((prev) => !prev);
                      }}
                      disabled={formStatus === "loading"}
                    />
                  </Stack>
                ) : null}

                {isCheckedScan && !locationData
                  ? !scannedImage && (
                      <Box component="div">
                        <CameraOrDeviceUpload
                          file={file}
                          setFile={setFile}
                          buttonText={t("Upload Code")}
                        />
                      </Box>
                    )
                  : null}

                {isCheckedType ? (
                  <TextField
                    name="locationCode"
                    label={`${values.locationType || t("Location")} ${t("Code")}`}
                    value={values.locationCode}
                    onChange={handleChange}
                    error={touched.locationCode && !!errors.locationCode}
                    helperText={touched.locationCode && errors.locationCode}
                    disabled={formStatus === "loading"}
                  />
                ) : null}

                {scannedImage ? (
                  <Typography component="p" variant="body1" mb={2}>
                    {t("Please select a location code of the scanned data")}:
                  </Typography>
                ) : null}

                {scannedImage ? (
                  <Stack width="100%" height="180px" overflow="auto" gap={1}>
                    {scannedImage.data.map((item, index) => (
                      <Stack
                        key={`code-data-${index}`}
                        css={{
                          backgroundColor:
                            selectedLocation === item.data ? theme.palette.grey[900] : "",
                          cursor: "pointer",
                        }}
                        spacing={0.5}
                        onClick={() => setSelectedLocation(item.data)}
                      >
                        <Typography component="p" variant="body1" mb={2}>
                          {`${index + 1}. ${t("Scanned Code Data")}`}
                        </Typography>

                        <LabelWithBoldedPart text={t("Type")} bolded={item.type} />
                        <LabelWithBoldedPart text={t("Data")} bolded={item.data} />
                      </Stack>
                    ))}
                  </Stack>
                ) : null}

                <TextField
                  name="locationName"
                  label={`${values.locationType || t("Location")} ${t("Name")}`}
                  value={values.locationName}
                  onChange={handleChange}
                  error={touched.locationName && !!errors.locationName}
                  helperText={touched.locationName && errors.locationName}
                  disabled={formStatus === "loading"}
                />

                <Stack spacing={1} justifyContent={"center"} alignItems={"center"}>
                  <Button
                    type="submit"
                    loading={formStatus === "loading"}
                    disabled={formStatus === "loading"}
                    css={[styles.width100, styles.widthLimit10]}
                  >
                    {locationData ? t("Update") : t("Create")}
                  </Button>

                  <Alert
                    message={alertMessage || ""}
                    showAlert={!!alertMessage}
                    severity={formStatus}
                  />
                </Stack>
              </Stack>
            </form>
          );
        }}
      </Formik>
    </>
  );
};

export default MaintainLocationsForm;
