import { useState } from "react";
import MultiStageForm from "../../../Components/SmallComponents/MultiStageForm/MultiStageForm";
import { useTranslateArray } from "../../../Global/Hooks/useTranslations";
import { FormStatuses } from "../../../Global/Types/commonTypes";
import { Divider, IconButton, Stack, Typography, useMediaQuery } from "@mui/material";
import { useLanguageContext } from "../../../context/LanguageContext";
import { FileWithPath } from "react-dropzone";
import { formatDateAndTime } from "../../../Global/Utils/commonFunctions";
import FileDropzone from "../../../Components/SmallComponents/Dropzone/FileDropzone";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { ElpromFileMeta, ElpromFileTypes } from "../API/apiElpromSnippets";
import { useAuthedContext } from "../../../context/AuthContext";
import { PostQueryElpromUploadSupcoInput } from "../API/apiElpromInputs";
import callApi from "../../../Api/callApi";
import {
  postQueryElpromUploadKso,
  postQueryElpromUploadPrices,
  postQueryElpromUploadPriorities,
  postQueryElpromUploadSupco,
} from "../API/apiElpromPostQueries";

const STAGES = ["Priorities", "Supco", "KSO", "Prices"];
type FilesMapping = Record<ElpromFileTypes, ElpromFileMeta>;

interface ElpromFilesStageFormProps {
  filesMeta: ElpromFileMeta[];
  handleFetchTableData: () => Promise<void>;
}

const ElpromFilesStageForm: React.FC<ElpromFilesStageFormProps> = ({
  filesMeta,
  handleFetchTableData,
}) => {
  const { t } = useLanguageContext();
  const sxMediaQuery = useMediaQuery("(max-width:599px)");

  const [alertMessage, setAlertMessage] = useState<string | null>(null);
  const [alertStatus, setAlertStatus] = useState<FormStatuses>(null);
  const [activeStep, setActiveStep] = useState<number>(0);

  const [prioritiesFile, setPrioritiesFile] = useState<FileWithPath | null>(null);
  const [supcoFile, setSupcoFile] = useState<FileWithPath | null>(null);
  const [ksoFile, setKsoFile] = useState<FileWithPath | null>(null);
  const [pricesFile, setPricesFile] = useState<FileWithPath | null>(null);

  const filesMapping = getFilesMapping(filesMeta);
  const { setAuthedUser } = useAuthedContext();

  const handleOnNextStage = async () => {
    try {
      if (activeStep === 1 && !supcoFile) {
        setAlertMessage(t("You must upload Supco file"));
        setAlertStatus("warning");
        return false;
      }
      if (activeStep === 2 && !ksoFile) {
        setAlertMessage(t("You must upload KSO file"));
        setAlertStatus("warning");
        return false;
      }

      if (activeStep === 3) {
        setAlertMessage(t("Files are being uploaded"));
        setAlertStatus("loading");
        if (prioritiesFile) {
          await callApi<PostQueryElpromUploadSupcoInput>({
            query: postQueryElpromUploadPriorities({ file: prioritiesFile }),
            auth: { setAuthedUser },
          });
        }
        if (supcoFile) {
          await callApi<PostQueryElpromUploadSupcoInput>({
            query: postQueryElpromUploadSupco({ file: supcoFile }),
            auth: { setAuthedUser },
          });
        }
        if (ksoFile) {
          await callApi<PostQueryElpromUploadSupcoInput>({
            query: postQueryElpromUploadKso({ file: ksoFile }),
            auth: { setAuthedUser },
          });
        }
        if (pricesFile) {
          await callApi<PostQueryElpromUploadSupcoInput>({
            query: postQueryElpromUploadPrices({ file: pricesFile }),
            auth: { setAuthedUser },
          });
        }
        await handleFetchTableData();
        setAlertMessage(t("Successfully uploaded"));
        setAlertStatus("success");
      }

      return true;
    } catch (err) {
      console.log("err ", err);
      setAlertStatus("error");
      setAlertMessage(t("Something went wrong"));
      return false;
    }
  };

  return (
    <MultiStageForm
      steps={useTranslateArray(STAGES)}
      activeStep={activeStep}
      setActiveStep={setActiveStep}
      handleOnNextStage={handleOnNextStage}
      alertMessage={alertMessage}
      alertStatus={alertStatus}
      disableNextButton={alertStatus === "success" || alertStatus === "loading"}
      disablePrevButton={
        activeStep === 0 || alertStatus === "success" || alertStatus === "loading"
      }
      lastNextButtonLabel={
        activeStep === STAGES.length - 1 ? t("Confirm and Upload Files") : t("Next")
      }
      title={t("Upload Files")}
      mobile={sxMediaQuery}
    >
      <>
        {activeStep === 0 ? (
          <Card
            item={filesMapping.priorities}
            setAlertMessage={setAlertMessage}
            alertStatus={alertStatus}
            setFile={setPrioritiesFile}
            currentUploadFile={prioritiesFile?.name || null}
          />
        ) : null}

        {activeStep === 1 ? (
          <Card
            item={filesMapping.supco}
            setAlertMessage={setAlertMessage}
            alertStatus={alertStatus}
            setFile={setSupcoFile}
            currentUploadFile={supcoFile?.name || null}
          />
        ) : null}

        {activeStep === 2 ? (
          <Card
            item={filesMapping.kso}
            setAlertMessage={setAlertMessage}
            alertStatus={alertStatus}
            setFile={setKsoFile}
            currentUploadFile={ksoFile?.name || null}
          />
        ) : null}

        {activeStep === 3 ? (
          <Card
            item={filesMapping.prices}
            setAlertMessage={setAlertMessage}
            alertStatus={alertStatus}
            setFile={setPricesFile}
            currentUploadFile={pricesFile?.name || null}
          />
        ) : null}
      </>
    </MultiStageForm>
  );
};

export default ElpromFilesStageForm;

interface CardProps {
  item: ElpromFileMeta;
  setAlertMessage: React.Dispatch<React.SetStateAction<string | null>>;
  alertStatus: FormStatuses;
  setFile: React.Dispatch<React.SetStateAction<FileWithPath | null>>;
  currentUploadFile: string | null;
}

const Card: React.FC<CardProps> = ({
  item,
  setAlertMessage,
  alertStatus,
  setFile,
  currentUploadFile,
}) => {
  return (
    <Stack
      spacing={2}
      direction="row"
      alignItems="center"
      divider={<Divider flexItem orientation="vertical" />}
    >
      <Typography variant="body1">
        {" "}
        Last Uploaded: {currentUploadFile || item.file_name}
      </Typography>
      <Typography variant="body1">
        {currentUploadFile
          ? "Uploaded in this form"
          : formatDateAndTime(item.uploaded_at)}
      </Typography>

      <FileDropzone
        setFiles={(files) => setFile(files[0])}
        setErrorMessage={setAlertMessage}
        accept={{
          ".xlsx, .xls, csv": [
            "application/vnd.ms-excel",
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            "text/csv",
          ],
        }}
      >
        <IconButton aria-label="replace file" disabled={alertStatus === "loading"}>
          <FileUploadIcon color="primary" />
        </IconButton>
      </FileDropzone>
    </Stack>
  );
};

const getFilesMapping = (filesMeta: ElpromFileMeta[]): FilesMapping => {
  const result = {} as FilesMapping;
  filesMeta.forEach((item) => {
    if (item.file_type === "kso") {
      result.kso = item;
    }
    if (item.file_type === "prices") {
      result.prices = item;
    }
    if (item.file_type === "priorities") {
      result.priorities = item;
    }
    if (item.file_type === "supco") {
      result.supco = item;
    }
  });

  return result;
};
