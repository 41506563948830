import { Box, Stack, Tab, Tabs, useTheme } from "@mui/material";
import cssLayoutStyles from "../../../Global/Styles/layout";
import cssSpacingStyles from "../../../Global/Styles/spacing";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import LoadingBackdrop from "../../../Components/MaterialUI/LoadingBackdrop";
import { FormStatuses } from "../../../Global/Types/commonTypes";

const AccountingPage: React.FC<any> = ({
}) => {
  // const { t } = useLanguageContext();
  const theme = useTheme();
  const styles = {
    ...cssLayoutStyles,
    ...cssSpacingStyles(theme),
  };
  const location = useLocation();

  const [pageStatus, setPageStatus] = useState<FormStatuses>(null);
  const [tabView, setTabView] = useState<number>(location.state?.tab || 0);

  useEffect(() => {
    setPageStatus("loading");
    setPageStatus(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Stack alignItems={"center"} justifyContent="center">
        <Box sx={{ width: { xs: "100%", md: "auto" } }} component="div">
          <Tabs
            css={styles.contentBreak}
            sx={{ overflowX: "auto" }}
            value={tabView}
            onChange={(_: any, val: React.SetStateAction<number>) => setTabView(val)}
            aria-label="configure tabs"
            variant="scrollable"
            scrollButtons="auto"
          >
            <Tab label={"Accounts"} />
            <Tab label="Second tab" />
          </Tabs>
        </Box>
      </Stack>

      {pageStatus === "loading" ? (
        <Box css={styles.width100} sx={{ height: "10rem" }} component="div">
          <LoadingBackdrop loading={pageStatus === "loading"} />
        </Box>
      ) : (
        tabView === 0 ? (
          <>
            Accounting
          </>
        ) : null
      )}
    </>
  );
};

export default AccountingPage;
