import { Box, IconButton, useTheme } from "@mui/material";
import { WidgetGridItem } from "../../LargeComponents/WidgetsGrid/widgetsGridUtils";
import { ExcellenceGridItemData, ExcellenceItemFilter } from "./excellenceUtils";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import cssComponentsStyles from "../../../Global/Styles/components";
import {
  GraphqlFilter,
  GraphqlFiltersData,
  handleExcellenceParamToGraphqlFilters,
} from "../../SmallComponents/GraphqlFilters/graphqlFiltersUtils";
import { useEffect, useState } from "react";
import GraphqlFiltersModal from "../../SmallComponents/GraphqlFilters/GraphqlFiltersModal";
import { ExcellenceParameter } from "../../../GraphQL/Excellence/graphQLTypesExcellence";

interface ExcellenceWidgetTempFiltersProps {
  widgetItem: WidgetGridItem<ExcellenceGridItemData>;
  widgetIndex: number;
  itemFilters: ExcellenceItemFilter[];
  setItemFilters: React.Dispatch<React.SetStateAction<ExcellenceItemFilter[]>>;
  parameters: ExcellenceParameter[];
  timeParameters: ExcellenceParameter[];
  setRefetchOnFilters: React.Dispatch<React.SetStateAction<boolean>>;
}

const ExcellenceWidgetTempFilters: React.FC<ExcellenceWidgetTempFiltersProps> = ({
  widgetItem,
  widgetIndex,
  itemFilters,
  setItemFilters,
  parameters,
  timeParameters,
  setRefetchOnFilters,
}) => {
  const theme = useTheme();
  const styles = {
    ...cssComponentsStyles(theme),
  };
  const [openModal, setOpenModal] = useState<boolean>(false);

  const sourceData = getSource(widgetItem, parameters, timeParameters);

  const [filtersToUse, setFiltersToUse] = useState<GraphqlFilter[]>(
    getAllFilters(sourceData, itemFilters, widgetIndex)
  );

  useEffect(() => {
    setItemFilters((prev) => prev.filter((item) => item.itemIndex !== widgetIndex));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [widgetIndex]);

  const handleOnSave = (currentFilters: GraphqlFilter[]) => {
    const newItemFilters = getNewItemFilters(itemFilters, currentFilters, widgetIndex);

    setFiltersToUse(getAllFilters(sourceData, newItemFilters, widgetIndex));
    setItemFilters(newItemFilters);
    setRefetchOnFilters((prev) => !prev);
  };

  const handleResetToCore = () => {
    setItemFilters((prev) =>
      prev.map((item) => {
        if (item.itemIndex === widgetIndex) {
          return {
            ...item,
            currentFilters: sourceData?.currentFilters || [],
          };
        }
        return item;
      })
    );
    setFiltersToUse(getAllFilters(sourceData, [], widgetIndex));
    setRefetchOnFilters((prev) => !prev);
  };

  if (!sourceData?.selectedContainers.length) {
    return null;
  }

  return (
    <Box component="div">
      <IconButton onClick={() => setOpenModal(true)}>
        <FilterAltOutlinedIcon css={styles.greyIcon} />
      </IconButton>

      <GraphqlFiltersModal
        openFilters={openModal}
        setOpenFilters={setOpenModal}
        graphqlFilters={filtersToUse}
        onSave={handleOnSave}
        data={sourceData.filtersData}
        selectedContainers={sourceData.selectedContainers}
        onResetToCoreFilters={handleResetToCore}
      />
    </Box>
  );
};

export default ExcellenceWidgetTempFilters;

type SourceData = {
  filtersData: GraphqlFiltersData[];
  selectedContainers: string[];
  currentFilters: GraphqlFilter[];
} | null;

const getSource = (
  widgetItem: WidgetGridItem<ExcellenceGridItemData>,
  parameters: ExcellenceParameter[],
  timeParameters: ExcellenceParameter[]
): SourceData => {
  const selectedContainers = getSelectedContainers(widgetItem);
  const currentFilters = getCurrentFilters(widgetItem);

  const isTimeChart = !!widgetItem.widget.chart?.timeChart;

  return {
    currentFilters,
    selectedContainers,
    filtersData: handleExcellenceParamToGraphqlFilters(
      isTimeChart ? timeParameters : parameters
    ),
  };
};

const getCurrentFilters = (
  widgetItem: WidgetGridItem<ExcellenceGridItemData>
): GraphqlFilter[] => {
  const result: GraphqlFilter[] = [];
  const chart = widgetItem.widget.chart;
  const areaChartFilters = chart?.areaChart?.config.graphqlFilters;
  const lineChartFilters = chart?.lineChart?.config.graphqlFilters;
  const barChartFilters = chart?.barChart?.config.graphqlFilters;
  const columnChartFilters = chart?.columnChart?.config.graphqlFilters;
  const pieChartFilters = chart?.pieChart?.config.graphqlFilters;
  const donutChartFilters = chart?.donutChart?.config.graphqlFilters;
  const radarChartFilters = chart?.radarChart?.config.graphqlFilters;
  const timeChartFilters = chart?.timeChart?.config.graphqlFilters;
  const tableFilters = chart?.table?.config.graphqlFilters;
  const indicatorFilters = chart?.indicatorWidget?.config.graphqlFilters;

  if (areaChartFilters?.length) {
    return areaChartFilters;
  }
  if (lineChartFilters?.length) {
    return lineChartFilters;
  }
  if (barChartFilters?.length) {
    return barChartFilters;
  }
  if (columnChartFilters?.length) {
    return columnChartFilters;
  }
  if (pieChartFilters?.length) {
    return pieChartFilters;
  }
  if (donutChartFilters?.length) {
    return donutChartFilters;
  }
  if (radarChartFilters?.length) {
    return radarChartFilters;
  }
  if (timeChartFilters?.length) {
    return timeChartFilters;
  }
  if (tableFilters?.length) {
    return tableFilters;
  }
  if (indicatorFilters?.length) {
    return indicatorFilters;
  }

  return result;
};

const getSelectedContainers = (
  widgetItem: WidgetGridItem<ExcellenceGridItemData>
): string[] => {
  const result: string[] = [];
  const chart = widgetItem.widget.chart;
  const areaChartSchema = chart?.areaChart?.dataSchema;
  const lineChartSchema = chart?.lineChart?.dataSchema;
  const barChartSchema = chart?.barChart?.dataSchema;
  const columnChartSchema = chart?.columnChart?.dataSchema;
  const pieChartSchema = chart?.pieChart?.dataSchema;
  const donutChartSchema = chart?.donutChart?.dataSchema;
  const radarChartSchema = chart?.radarChart?.dataSchema;
  const timeChartSchema = chart?.timeChart?.dataSchema;
  const tableSchema = chart?.table?.dataSchema;
  const indicatorSchema = chart?.indicatorWidget?.dataSchema;

  if (areaChartSchema) {
    result.push(areaChartSchema.x.container);
    result.push(...areaChartSchema.y.map((item) => item.container));
  }
  // line
  if (lineChartSchema) {
    result.push(lineChartSchema.x.container);
    result.push(...lineChartSchema.y.map((item) => item.container));
  }
  // bar
  if (barChartSchema) {
    result.push(...barChartSchema.group.map((item) => item.container));
    if (barChartSchema.legend?.container) {
      result.push(barChartSchema.legend.container);
    }
    if (barChartSchema.operationParameter?.container) {
      result.push(barChartSchema.operationParameter.container);
    }
  }
  // column
  if (columnChartSchema) {
    result.push(...columnChartSchema.group.map((item) => item.container));
    if (columnChartSchema.legend?.container) {
      result.push(columnChartSchema.legend.container);
    }
    if (columnChartSchema.operationParameter?.container) {
      result.push(columnChartSchema.operationParameter.container);
    }
  }
  // pie
  if (pieChartSchema) {
    result.push(...pieChartSchema.legend.map((item) => item.container));
    result.push(...pieChartSchema.parameters.map((item) => item.container));
  }
  // donut
  if (donutChartSchema) {
    result.push(...donutChartSchema.legend.map((item) => item.container));
    result.push(...donutChartSchema.parameters.map((item) => item.container));
  }
  // radar
  if (radarChartSchema) {
    result.push(...radarChartSchema.group.map((item) => item.container));
    if (radarChartSchema.legend?.container) {
      result.push(radarChartSchema.legend.container);
    }
    if (radarChartSchema.operationParameter?.container) {
      result.push(radarChartSchema.operationParameter.container);
    }
  }
  // line
  if (timeChartSchema) {
    result.push(...timeChartSchema.parameters.map((item) => item.container));
  }
  // table
  if (tableSchema) {
    result.push(tableSchema.container);
  }
  // table
  if (tableSchema) {
    result.push(tableSchema.container);
  }
  // indicator
  if (indicatorSchema) {
    result.push(indicatorSchema.parameter.container);
  }
  return result;
};

const getAllFilters = (
  sourceData: SourceData,
  itemFilters: ExcellenceItemFilter[],
  widgetIndex: number
): GraphqlFilter[] => {
  const thisWidgetFilters = itemFilters.find((item) => item.itemIndex === widgetIndex);

  if (thisWidgetFilters?.isConfigured) {
    const uniqueFilters = new Map<string, GraphqlFilter>();
    const allFilters = [...(thisWidgetFilters?.currentFilters || [])];

    allFilters.forEach((item) => {
      uniqueFilters.set(item.paramID, item);
    });

    return Array.from(uniqueFilters.values());
  }

  return sourceData?.currentFilters || [];
};

const getNewItemFilters = (
  prev: ExcellenceItemFilter[],
  currentFilters: GraphqlFilter[],
  widgetIndex: number
): ExcellenceItemFilter[] => {
  const result = prev.filter((item) => item.itemIndex !== widgetIndex);

  return [
    ...result,
    {
      itemIndex: widgetIndex,
      currentFilters,
      isConfigured: true,
    },
  ];
};
