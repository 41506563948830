import {
  ApolloClient,
  InMemoryCache,
  HttpLink,
  ApolloProvider,
  from,
  split,
  fromPromise,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { isLocalHostEnv, getCookie } from "../Global/Utils/commonFunctions";
import {
  handleFetchUserAccessToken,
  handleUserSignOut,
} from "../context/authContextUtils";
import {
  COOKIE_ACCESS_TOKEN,
  COOKIE_REFRESH_TOKEN,
} from "../Global/Constants/commonConstants";
import { getMainDefinition } from "@apollo/client/utilities";
import { createClient } from "graphql-ws";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";

const IS_LOCAL_HOST = isLocalHostEnv();
let tokenRefreshPromise: Promise<any> = Promise.resolve();
// @ts-ignore
let isRefreshing: boolean = false;

const errorLink = onError(({ forward, graphQLErrors, networkError, operation }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    );
  }

  if (networkError) {
    // if invalid access token
    if (networkError.name === "ServerError") {
      const refreshToken = getCookie(COOKIE_REFRESH_TOKEN);
      isRefreshing = true;
      tokenRefreshPromise = handleFetchUserAccessToken(refreshToken);
      tokenRefreshPromise
        .then(() => (isRefreshing = false))
        .catch(() => handleUserSignOut());
    }
    return fromPromise(tokenRefreshPromise).flatMap(() => forward(operation));
  }

  // Return undefined to indicate that the error is not handled
  return undefined;
});

const httpLink = new HttpLink({
  uri: IS_LOCAL_HOST
    ? "http://localhost:3000/api/graphql/"
    : `https://${process.env.REACT_APP_SUBDOMAIN}.${process.env.REACT_APP_DOMAIN}/api/graphql/`,
});

// Configure GraphQLWsLink for subscriptions
const wsLink = new GraphQLWsLink(
  createClient({
    url: IS_LOCAL_HOST
      ? "ws://localhost:3000/api/graphql/"
      : `wss://${process.env.REACT_APP_SUBDOMAIN}.${process.env.REACT_APP_DOMAIN}/api/graphql/`,
    connectionParams: async () => {
      const accessToken = getCookie(COOKIE_ACCESS_TOKEN); // Retrieve the accessToken cookie
      const refreshToken = getCookie(COOKIE_REFRESH_TOKEN); // Retrieve the accessToken cookie
      return {
        headers: {
          Cookie: `accessToken=${accessToken} refreshToken=${refreshToken}`,
        },
      };
    },
    // Add error event listener for logging purposes
    on: {
      connected: () => console.log("WebSocket connected successfully"),
      closed: (event) => console.log("WebSocket closed:", event),
      error: (error) => console.error("WebSocket error:", error),
    },
  })
);
console.log("wsLink ", wsLink);

// Use split for proper link routing
const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" && definition.operation === "subscription"
    );
  },
  wsLink,
  httpLink
);

interface GraphQlProviderProps {
  children: React.ReactNode;
}

const GraphQlProvider: React.FC<GraphQlProviderProps> = ({ children }) => {
  const client = new ApolloClient({
    link: from([errorLink, splitLink]),
    credentials: "include",
    cache: new InMemoryCache({
      addTypename: false,
    }),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: "no-cache",
      },
      query: {
        fetchPolicy: "no-cache",
        errorPolicy: "all",
      },
    },
  });

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default GraphQlProvider;
