import { IndicatorWidgetData } from "../EditExcellenceChartForms/excellenceChartFormUtils";
import { TrendDirection } from "../IndicatorWidget/indicatorWidgetUtils";

export const indicatorWidgetDefaultData: IndicatorWidgetData = {
  latest: 810,
  average: 210.96,
  trendAverage: TrendDirection.UP,
  trendLatest: TrendDirection.UP,
  chart: [100, 55, 300, 270, 810],
};

export default indicatorWidgetDefaultData;
