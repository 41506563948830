import React, { ReactElement } from "react";
import { Tooltip, Typography, useTheme } from "@mui/material";

interface CustomIconsTooltipProps {
  tooltipText: string;
  children: ReactElement<any, any>;
}

const CustomIconsTooltip: React.FC<CustomIconsTooltipProps> = ({
  tooltipText,
  children,
}) => {
  const theme = useTheme();

  return (
    <Tooltip
      title={
        <Typography
          variant="body2"
          sx={{
            color: "white",
            borderRadius: theme.shape.borderRadius,
            paddingRight: 0.5,
            paddingLeft: 0.5,
          }}
        >
          {tooltipText}
        </Typography>
      }
      placement="left"
    >
      {children}
    </Tooltip>
  );
};

export default CustomIconsTooltip;
