import {
  AutocompleteGroupedOption,
  AutocompleteOption,
  FormStatuses,
  SelectOption,
} from "../../../Global/Types/commonTypes";
import { ExcellenceParameter } from "../../../GraphQL/Excellence/graphQLTypesExcellence";
import { useEffect, useMemo, useState } from "react";
import { handleGetSelectOption } from "../../../Global/Utils/commonFunctions";
import isValid from "date-fns/isValid";
import { Box, IconButton, Stack, Theme, Tooltip, Typography } from "@mui/material";
import DateAndTimePicker from "../../MaterialUI/DateTimePickers/DateAndTimePicker";
import Autocomplete from "../../MaterialUI/FormFields/Autocomplete";
import AutocompleteGrouped from "../../MaterialUI/FormFields/AutocompleteGrouped";
import useTheme from "@mui/material/styles/useTheme";
import cssSpacingStyles from "../../../Global/Styles/spacing";
import cssLayoutStyles from "../../../Global/Styles/layout";
import { css } from "@emotion/react";
import {
  PieAggregationFunction,
  PieChartDataSchema,
  PieChartParameter,
} from "./pieChartTypes";
import Select from "../../MaterialUI/FormFields/Select";
import AddIcon from "@mui/icons-material/Add";
import { ExcellenceParamMapping } from "../EditExcellenceChartForms/excellenceChartFormUtils";
import { useLanguageContext } from "../../../context/LanguageContext";
import {
  GraphqlFilter,
  handleExcellenceParamToGraphqlFilters,
} from "../../SmallComponents/GraphqlFilters/graphqlFiltersUtils";
import GraphqlFilters from "../../SmallComponents/GraphqlFilters/GraphqlFilters";
import Modal from "../../MaterialUI/Modal";
import { FiltersModalLayoutData, getParamOptions } from "../excellenceUtils";
import { RemoveFiltersWarning } from "../ExcellenceSmallComponents";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import FilterAltOffRounded from "@mui/icons-material/FilterAltOffRounded";

const AGGREGATE_OPTIONS: SelectOption[] = Object.entries(PieAggregationFunction).map(
  ([key, value]) => ({ description: key, value: value })
);
const AGGREGATE_STRING_OPTIONS: SelectOption[] = [
  { description: "Count", value: PieAggregationFunction.Count },
];

const cssStyles = (theme: Theme) => ({
  autocompleteGroup: css({
    position: "sticky",
    top: "-8px",
    padding: "4px 10px",
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.secondary.light,
  }),
  autocompleteList: css({
    padding: 0,
  }),
});

type ParamRow = {
  parameter: AutocompleteGroupedOption | null;
  aggregation: "" | PieAggregationFunction;
};

const EMPTY_PARAM_ROW: ParamRow = {
  aggregation: "",
  parameter: null,
};

const EMPTY_GROUPED_OPTION: AutocompleteGroupedOption = {
  value: "",
  groupName: "",
  description: "",
};

interface EditPieChartDataSchemaProps {
  parameters: ExcellenceParameter[];
  dataSchema: PieChartDataSchema | null;
  handleUpdateDataSchema: (schema: PieChartDataSchema | null) => void;
  dateFrom: Date | null;
  setDateFrom: React.Dispatch<React.SetStateAction<Date | null>>;
  dateTo: Date | null;
  setDateTo: React.Dispatch<React.SetStateAction<Date | null>>;
  selectedConnection: SelectOption;
  setSelectedConnection: React.Dispatch<React.SetStateAction<SelectOption>>;
  graphqlFilters: GraphqlFilter[];
  setGraphqlFilters: React.Dispatch<React.SetStateAction<GraphqlFilter[]>>;
  initialSetupComplete: boolean;
  setInitialSetupComplete: React.Dispatch<React.SetStateAction<boolean>>;
  paramMapping: ExcellenceParamMapping | null;
  isLegendEnabled: boolean;
  setLegendFilter: (isVisible: boolean | null) => void;
}

const EditPieChartDataSchema: React.FC<EditPieChartDataSchemaProps> = ({
  parameters,
  dataSchema,
  handleUpdateDataSchema,
  dateFrom,
  setDateFrom,
  dateTo,
  setDateTo,
  selectedConnection,
  setSelectedConnection,
  graphqlFilters,
  setGraphqlFilters,
  initialSetupComplete,
  setInitialSetupComplete,
  paramMapping,
  isLegendEnabled,
  setLegendFilter,
}) => {
  const theme = useTheme();
  const styles = {
    ...cssSpacingStyles(theme),
    ...cssStyles(theme),
    ...cssLayoutStyles,
  };
  const { t } = useLanguageContext();
  // options
  const [nodeOptions, setNodeOptions] = useState<AutocompleteGroupedOption[]>([]);
  const [connectionOptions, setConnectionOptions] = useState<SelectOption[]>([]);

  const [legends, setLegends] = useState<AutocompleteGroupedOption[]>([]);
  const [parameter, setParameter] = useState<ParamRow>(EMPTY_PARAM_ROW);
  const [appliedFiltersModal, setAppliedFiltersModal] = useState<FiltersModalLayoutData>({
    isOpen: false,
    data: { value: '', description: '' }
  });

  // system-used
  const [formStatus, setFormStatus] = useState<FormStatuses>(null);
  const [reUpdateSchema, setReUpdateSchema] = useState<boolean>(false);

  const operationType = paramMapping?.[parameter.parameter?.value || ""]?.type;
  const paramOptions = useMemo(
    () => getParamOptions(selectedConnection.value, parameters, legends, parameter.parameter),
    [selectedConnection.value, parameters, legends, parameter.parameter]
  );

  useEffect(() => {
    if (graphqlFilters.length) {
      const selectedConnections: AutocompleteOption[] = Array.from(
        new Map(
          graphqlFilters.map((item) => [item.containerId, {
            value: item.containerId,
            description: item.containerName
          }])
        ).values()
      );
      setSelectedConnection(selectedConnections[0]);
    }
    if (selectedConnection.value && !parameter) {
      setParameter(EMPTY_PARAM_ROW);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (dataSchema && paramMapping) {
      const selectedConnectionState: string = 
        (dataSchema.parameters.length && dataSchema.parameters[0].container) ||
        (dataSchema.legend.length ? dataSchema?.legend[0].container : '');

      // const selectedConnectionsSet: Set<string> = new Set();

      // for (const item of dataSchema.parameters) {
      //   selectedConnectionsSet.add(item.container);
      // }
      // if (dataSchema.legend) {
      //   selectedConnectionsSet.add(dataSchema.legend.container);
      // }
      // const selectedConnectionsArr: SelectOption[] = Array.from(
      //   selectedConnectionsSet
      // ).map((item) => {
      //   const conn = Object.values(paramMapping).find(
      //     (param) => param.connection === item
      //   );
      //   const connName = conn?.connectionName || item;

      //   return {
      //     value: item,
      //     description: connName,
      //   };
      // });

      if ( dataSchema.parameters.length ) {
        const parameterDs = dataSchema.parameters[0];
        setParameter({
          aggregation: parameterDs.aggregation ?? '',
          parameter: {
            value: parameterDs.parameterId,
            groupName: parameterDs.container,
            description: paramMapping?.[parameterDs.parameterId]?.paramName || parameterDs.parameterId,
          },
        });
      } else {
        setParameter(EMPTY_PARAM_ROW);
      }

      const legends: AutocompleteGroupedOption[] = dataSchema?.legend.map((item) => ({
        value: item.parameterId,
        groupName: item.container,
        description: paramMapping?.[item.parameterId].paramName || item.parameterId,
      }));

      const conn = Object.values(paramMapping).find(
        (param) => param.connection === selectedConnectionState
      );
      const connName = conn?.connectionName || selectedConnectionState;

      setSelectedConnection({
        value: selectedConnectionState,
        description: connName,
      });
      setLegends(legends);
      setInitialSetupComplete(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paramMapping]);

  useEffect(() => {
    const connectionsRecord: Record<string, string> = {};
    parameters.forEach((item) => {
      connectionsRecord[item.container] = item.containerName;
    });

    const allConnectionOptions: SelectOption[] = Object.entries(connectionsRecord).map(
      ([key, value]) => ({
        value: key,
        description: value,
      })
    );

    handleGetSelectOption([...new Set(parameters.map((item) => item.container))]);

    setConnectionOptions(allConnectionOptions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parameters]);

  useEffect(() => {
    const paramOptions = handleGetParamOptions(parameters, selectedConnection);
    setNodeOptions(paramOptions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedConnection]);

  useEffect(() => {
    const startTime = dateFrom && isValid(dateFrom) ? dateFrom.toISOString() : null;
    const endTime = dateTo && isValid(dateTo) ? dateTo.toISOString() : null;
    if (initialSetupComplete && !selectedConnection.value) {
      handleUpdateDataSchema(null);
    } else {
      if (legends?.length && paramMapping) {
        const mapLegends = legends
          .map((legend) => ({
            parameterId: paramMapping?.[legend.value]?.paramID,
            container: paramMapping?.[legend.value]?.connection,
          }))
          .filter((item): item is PieChartParameter => !!item.parameterId && !!item.container);
        if ( mapLegends.length ) {
          const updatedSchema: PieChartDataSchema = {
            startTime,
            endTime,
            parameters: parameter.parameter ? [{
              parameterId: parameter.parameter?.value,
              container: paramMapping[parameter.parameter!.value].connection,
              aggregation: parameter.aggregation,
            }] : [],
            legend: mapLegends,
          };
          handleUpdateDataSchema(updatedSchema);
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reUpdateSchema]);

  const onDateRangeChange = async (val: Date | null, type: "dateTo" | "dateFrom") => {
    try {
      let fullDataRow: boolean = false;

      if (type === "dateTo") {
        setDateTo(val);
        fullDataRow = !!dateFrom && isValid(dateFrom);
      } else {
        setDateFrom(val);
        fullDataRow = !!dateTo && isValid(dateTo);
      }

      if (val && isValid(val) && fullDataRow) {
        setReUpdateSchema((prev) => !prev);
      }
    } catch (err) {
      console.log("err onDateRangeChange() ", err);
      setFormStatus("error");
    }
  };

  const handleAddNewLegend = () => {
    setLegends((prev) => [...prev, EMPTY_GROUPED_OPTION]);
  };

  const handleOnNodeChange = (val: AutocompleteGroupedOption) => {
    setParameter(() => {
      if (val === null) {
        setReUpdateSchema((prev) => !prev);
        return {
          parameter: null,
          aggregation: "" as PieAggregationFunction,
        };
      } else {
        return {
          parameter: val,
          aggregation: "" as PieAggregationFunction,
        };
      }
    });
  };

  const handleOnAggregateChange = (val: string) => {
    setParameter((prev) => ({
      ...prev,
      aggregation: val as PieAggregationFunction,
    }));
    if ( val ) {
      setReUpdateSchema((prev) => !prev);
    }
  };

  const handleOnLegendChange = (val: AutocompleteGroupedOption, legend: AutocompleteGroupedOption) => {
    if (val === null) {
      setLegends((prev) => prev.filter((item) => item.value !== legend.value));
    } else {
      setLegends((prev) => prev.map((item) => 
        item.value === legend.value ? { ...item, value: val.value } : item
      ));
    }

    setReUpdateSchema((prev) => !prev);
    if (!parameter) {
      setParameter(EMPTY_PARAM_ROW);
    }
  };

  const handleOnConnectionChange = (val: SelectOption) => {
    if (!val && graphqlFilters.length) {
      setAppliedFiltersModal({ isOpen: true, data: { value: "", description: "" } });
    } else if (val && selectedConnection.value !== val.value && graphqlFilters.length) {
      setAppliedFiltersModal({ isOpen: true, data: { value: val.value, description: val.description } });
    } else {
      confirmChangeConnection(val);
    }
    if ( legends.length === 0 ) {
      setLegendFilter(true);
      setLegends([EMPTY_GROUPED_OPTION]);
    } else {
      setLegendFilter(false);
    }
  };

  const confirmChangeConnection = (val: SelectOption) => {
    setSelectedConnection(val || { value: "", description: "" });
    setParameter(EMPTY_PARAM_ROW);
    if (!val) {
      setReUpdateSchema((prev) => !prev);
    }
    setGraphqlFilters([]);
    setAppliedFiltersModal({ isOpen: false, data: { value: '', description: '' } });
  }

  if (!parameters?.length) {
    return (
      <Box component="div">
        <Typography variant="h3" color="error">
          {t("You don't have access to any active connections")}
        </Typography>
      </Box>
    );
  }

  if (formStatus === "error") {
    return (
      <Box component="div">
        <Typography variant="h3" color="error">
          {t("Something went wrong")}
        </Typography>
      </Box>
    );
  }

  return (
    <Stack
      css={styles.contentBreak}
      spacing={3}
      alignItems="center"
      justifyContent="center"
    >
      <Stack css={styles.width100} spacing={3} direction={{ xs: "column", sm: "row" }}>
        <DateAndTimePicker
          css={styles.width100}
          label={t("Date from")}
          value={dateFrom}
          onChange={(val) => onDateRangeChange(val, "dateFrom")}
        />
        <DateAndTimePicker
          css={styles.width100}
          label={t("Date to")}
          value={dateTo}
          onChange={(val) => onDateRangeChange(val, "dateTo")}
        />
      </Stack>

      <Stack css={styles.width100} spacing={3} direction={{ xs: "column", sm: "row" }}>
        <Autocomplete
          css={styles.width100}
          label={t("Selected Connection")}
          options={connectionOptions}
          value={selectedConnection}
          handleOnChange={handleOnConnectionChange}
          disabled={formStatus === "loading"}
        />

        <Box css={[styles.flex, styles.flexAlignItemsEnd]} component="div">
          <GraphqlFilters
            key={graphqlFilters.length}
            data={handleExcellenceParamToGraphqlFilters(parameters)}
            graphqlFilters={graphqlFilters}
            setGraphqlFilters={setGraphqlFilters}
            selectedContainers={[selectedConnection.value]}
            customOperations={[{ value: "EQ", description: "Equals" }]}
          />
        </Box>
      </Stack>

      {selectedConnection.value ? (
        <Stack css={styles.width100} direction="row" spacing={3} alignItems="center">
          <Box component="div" style={{ flex: 1 }}>
            {legends.map((legend, index) => {
              const selectedOption = nodeOptions.find((option) => option.value === legend.value) || null;

              return (
                <AutocompleteGrouped
                  key={index}
                  css={styles.width100}
                  options={paramOptions}
                  label={t("Legend")}
                  value={selectedOption}
                  handleOnChange={(e: AutocompleteGroupedOption) => handleOnLegendChange(e, legend)}
                />
              );
            })}
          </Box>
          {legends.length ? (
            <>
              <Tooltip title={t("Add New Legend")}>
                <IconButton css={styles.marginLeftAuto} onClick={handleAddNewLegend}>
                  <AddIcon />
                </IconButton>
              </Tooltip>
              <IconButton onClick={() => setLegendFilter(null)} style={{marginLeft: "15px"}}>
                {isLegendEnabled ? <FilterAltIcon /> : <FilterAltOffRounded />}
              </IconButton>
            </>
          ) : null}
          
        </Stack>
      ) : null}

      {legends.length && parameter ? (
        <Stack
          css={[styles.width100, styles.overflowYAuto]}
          sx={{ maxHeight: "250px" }}
          gap={3}
        >
          <Stack
            direction="row"
            gap={3}
            alignItems="center"
          >
            <AutocompleteGrouped
              css={styles.width100}
              label={t("Parameter")}
              options={paramOptions}
              value={parameter.parameter ? {
                value: parameter.parameter.value,
                groupName: parameter.parameter.groupName,
                description: parameter.parameter.description
              } : null}
              handleOnChange={(e: AutocompleteGroupedOption) =>
                handleOnNodeChange(e)
              }
              disabled={formStatus === "loading"}
            />
            <Select
              selectOptions={
                operationType === "string" ? AGGREGATE_STRING_OPTIONS : AGGREGATE_OPTIONS
              }
              label={t("Aggregate Function")}
              value={parameter.aggregation}
              onChange={(e) => handleOnAggregateChange(e.target.value)}
              disabled={formStatus === "loading"}
            />
          </Stack>
        </Stack>
      ) : null}

      <Modal
        open={appliedFiltersModal.isOpen}
        onClose={() => setAppliedFiltersModal(prev => ({ ...prev, isOpen: false }))}
        fullWidth
        label={t("Warning")}
      >
        <RemoveFiltersWarning
          confirmChangeConnection={() => confirmChangeConnection(appliedFiltersModal.data)}
          onClose={() => setAppliedFiltersModal(prev => ({ ...prev, isOpen: false }))}
        />
      </Modal>
    </Stack>
  );
};

export default EditPieChartDataSchema;

const handleGetParamOptions = (
  parameters: ExcellenceParameter[],
  selectedConnection: SelectOption
): AutocompleteGroupedOption[] => {
  const result =
    parameters
      .map((item) => ({
        groupName: item.container,
        value: item.id,
        description: item.name,
      }))
      .filter((node) =>
        // selectedConnectionsArr.some((sub) => sub.value === node.groupName)
        node.groupName === selectedConnection.value
      ) || [];

  const resultWithGroupNames = result.map((item) => {
    // const found = selectedConnectionsArr.find((conn) => conn.value === item.groupName);
    const found = selectedConnection.value === item.groupName ? selectedConnection : null;
    const name = found?.description || item.groupName;
    return {
      ...item,
      groupName: name,
    };
  });

  return resultWithGroupNames;
};
