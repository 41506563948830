import { Stack } from "@mui/material";
import Select from "../../../Components/MaterialUI/FormFields/Select";
import { SelectOption } from "../../../Global/Types/commonTypes";
import { handleGetSelectOption } from "../../../Global/Utils/commonFunctions";
import { useState } from "react";
import LongTermProductForm from "./LongTermProductForm";
import LongTermMachineForm from "./LongTermMachineForm";
import { LongPlanningProductRow } from "./longTermPlanningUtils";
import cssLayoutStyles from "../../../Global/Styles/layout";
import { useLanguageContext } from "../../../context/LanguageContext";
import { useTranslateSchemeArray } from "../../../Global/Hooks/useTranslations";

const formOptions: SelectOption[] = handleGetSelectOption(["Add Product", "Add Machine"]);

interface LongTermAddNewProps {
  onSubmit: () => Promise<void>;
  allProducts: LongPlanningProductRow[];
  handleSetUnsavedChanges: (unsavedChanges: boolean) => void;
  setUnsavedChanges: React.Dispatch<React.SetStateAction<boolean>>;
}

const LongTermAddNew: React.FC<LongTermAddNewProps> = ({
  onSubmit,
  allProducts,
  handleSetUnsavedChanges,
  setUnsavedChanges,
}) => {
  const { t } = useLanguageContext();
  const styles = { ...cssLayoutStyles };
  const [selectedForm, setSelectedForm] = useState<string>("");

  return (
    <Stack mb={3} spacing={3}>
      <Select
        css={[styles.width100, styles.widthLimit20]}
        selectOptions={useTranslateSchemeArray(formOptions)}
        label={t("Select Options")}
        onChange={(e) => setSelectedForm(e.target.value)}
        value={selectedForm}
      />

      {selectedForm === "Add Product" ? (
        <LongTermProductForm
          product={null}
          onSubmit={onSubmit}
          handleSetUnsavedChanges={handleSetUnsavedChanges}
          setUnsavedChanges={setUnsavedChanges}
        />
      ) : null}

      {selectedForm === "Add Machine" ? (
        <LongTermMachineForm
          machine={null}
          onSubmit={onSubmit}
          allProducts={allProducts}
          handleSetUnsavedChanges={handleSetUnsavedChanges}
          setUnsavedChanges={setUnsavedChanges}
        />
      ) : null}
    </Stack>
  );
};

export default LongTermAddNew;
