import ResponsiveTableGrid from "../../SmallComponents/TableGrid/ResponsiveTableGrid";
import { TableGridColumnSchema } from "../../SmallComponents/TableGrid/constructTableGrid";
import { useTranslateFields } from "../../../Global/Hooks/useTranslations";
import { StockRow } from "../MyWarehouses/stocksUtils";
// import { useState } from "react";
// import { ModalLayoutData } from "../../SmallComponents/TableGrid/CellActionButton";
// import Modal from "../../MaterialUI/Modal";
// import HistoryTrackingModalLayout from "./HistoryTrackingModalLayout";
// import CommentModalLayout from "./CommentModalLayout";
// import { useLanguageContext } from "../../../context/LanguageContext";

interface ObjectTrackingTableProps {
  stocksRows: StockRow[];
  stocksColumns: TableGridColumnSchema[];
  pageLoading: boolean;
}

const ObjectTrackingTable: React.FC<ObjectTrackingTableProps> = ({
  stocksRows,
  stocksColumns,
  pageLoading,
}) => {
  // const { t } = useLanguageContext();
  // const [openInfo, setOpenInfo] = useState<boolean>(false);
  // const [modalData, setModalData] = useState<ModalLayoutData | null>(null);

  return (
    <>
      <ResponsiveTableGrid
        rows={stocksRows}
        // rows={handleFilterData(setOpenInfo, setModalData, stocksRows)}
        colSchema={useTranslateFields(stocksColumns, ["label"])}
        editMode={false}
        responsive="responsive"
        configuration={{
          density: "compact",
        }}
        loading={pageLoading}
        tableID="ObjectTrackingTable_table"
      />

      {/* {modalData ? (
        <Modal
          open={openInfo}
          fullWidth
          label={modalData.type === "history" ? t("History Tracking") : t("Comments")}
          onClose={() => setOpenInfo(false)}
        >
          {modalData.type === "history" ? (
            <HistoryTrackingModalLayout projectId={modalData.data} />
          ) : null}
          {modalData.type === "comments" ? <CommentModalLayout /> : null}
        </Modal>
      ) : null} */}
    </>
  );
};

export default ObjectTrackingTable;

// const handleFilterData = (
//   setOpenInfo: React.Dispatch<React.SetStateAction<boolean>>,
//   setModalData: React.Dispatch<React.SetStateAction<ModalLayoutData | null>>,
//   stocksRows: StockRow[]
// ): Object[] => {
//   if (!stocksRows) {
//     return [];
//   }

//   return stocksRows.map((stocksRow) => ({
//     objectCode: availability.object_code,
//     objectType: availability.object_type,
//     lastScanned: availability.last_scan,
//     lastLocation: (
//       <Tooltip title={availability.last_location}>
//         <Typography>
//           {availability.last_location.includes("/") &&
//           availability.last_location.split("/").length > 2
//             ? `.../${availability.last_location.split("/").slice(2).join("/")}`
//             : availability.last_location}
//         </Typography>
//       </Tooltip>
//     ),
//     currentLocation: (
//       <Tooltip title={availability.current_location}>
//         <Typography>
//           {availability.current_location.includes("/") &&
//           availability.current_location.split("/").length > 2
//             ? `.../${availability.current_location.split("/").slice(2).join("/")}`
//             : availability.current_location}
//         </Typography>
//       </Tooltip>
//     ),
//     status: availability.status,
//     comment: (
//       <CellActionButton
//         text="View All"
//         icon={<VisibilityIcon />}
//         handleOnClick={() => {
//           setOpenInfo(true);
//           setModalData({ type: "comments", data: "" });
//         }}
//       />
//     ),
//     history: (
//       <CellActionButton
//         text="View"
//         icon={<HistoryIcon />}
//         handleOnClick={() => {
//           setOpenInfo(true);
//           setModalData({ type: "history", data: "" });
//         }}
//       />
//     ),
//   }));
// };
