import React from "react";
import { useLanguageContext } from "../../../context/LanguageContext";

interface HelpContentDisplayProps {
  type: "text" | "image" | "video";
  content: string;
}

const HelpContentDisplay: React.FC<HelpContentDisplayProps> = ({ type, content }) => {
  const { t } = useLanguageContext();

  if (type === "text") {
    return <div dangerouslySetInnerHTML={{ __html: content }} />;
  }

  if (type === "image") {
    return (
      <div
        style={{
          position: "relative",
          paddingBottom: "56.25%",
          height: 0,
          overflow: "hidden",
          maxWidth: "100%",
          backgroundColor: "black",
        }}
      >
        <img
          src={`https://${content}`}
          alt="Help Image"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            objectFit: "cover",
          }}
        />
      </div>
    );
  }

  if (type === "video") {
    return (
      <div
        style={{
          position: "relative",
          paddingBottom: "56.25%",
          height: 0,
          overflow: "hidden",
          maxWidth: "100%",
          backgroundColor: "black",
        }}
      >
        <video
          controls
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            objectFit: "cover",
          }}
        >
          <source src={`https://${content}`} />
          {t("Your browser does not support the video tag.")}
        </video>
      </div>
    );
  }

  return null;
};

export default HelpContentDisplay;
