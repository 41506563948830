import {
  ExcellenceChartProps,
  LineAreaHeatMapScatterPlotData,
} from "../../../../ExcellenceWidgets/EditExcellenceChartForms/excellenceChartFormUtils";
import heatMapDefaultData from "../../../../ExcellenceWidgets/AdvancedWidgets/AdvancedDefaultConfig/heatMapDefaultData";
import { useTheme } from "@mui/material";
import { useLazyQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { useLanguageContext } from "../../../../../context/LanguageContext";
import cssSpacingStyles from "../../../../../Global/Styles/spacing";
import { FormStatuses } from "../../../../../Global/Types/commonTypes";
import LoadingBackdrop from "../../../../MaterialUI/LoadingBackdrop";
import Alert from "../../../../MaterialUI/Alert";
import { graphQlQueryExcellenceAreaChartData } from "../../../../../GraphQL/Excellence/graphQLQueriesExcellence";
import AreaChart from "../../../../ExcellenceWidgets/AreaChart/AreaChart";
import {
  AreaChartConfiguration,
  AreaChartDataSchema,
} from "../../../../ExcellenceWidgets/AreaChart/areaChartTypes";
import {
  buildGraphqlFilters,
  GraphqlFilter,
} from "../../../../SmallComponents/GraphqlFilters/graphqlFiltersUtils";

interface ExcellenceAreaChartProps extends ExcellenceChartProps {
  data: LineAreaHeatMapScatterPlotData | null;
  configuration: AreaChartConfiguration;
  schema: AreaChartDataSchema | null;
}
const ExcellenceAreaChart: React.FC<ExcellenceAreaChartProps> = ({
  className,
  data,
  configuration,
  isStatic,
  schema,
  resetChartFetch,
  dataFetchFromParent,
  // parameters,
  tempFilters,
  refetchOnFilters,
}) => {
  const theme = useTheme();
  const styles = {
    ...cssSpacingStyles(theme),
  };
  const { t } = useLanguageContext();
  const [updatedData, setUpdatedData] = useState<LineAreaHeatMapScatterPlotData | null>(
    data
  );
  const [fetchStatus, setFetchStatus] = useState<FormStatuses>(null);
  const [chartToUse, setChartToUse] = useState<boolean>(true);
  const [initialFetchDone, setInitialFetchDone] = useState<boolean>(false);

  const [getAreaData, { loading: loadingData }] = useLazyQuery(
    graphQlQueryExcellenceAreaChartData
  );

  // const paramMapping: Record<string, string> | undefined = parameters?.reduce(
  //   (acc, curr) => {
  //     return {
  //       ...acc,
  //       [curr.id]: curr.name,
  //     };
  //   },
  //   {}
  // );

  useEffect(() => {
    (async () => {
      if (initialFetchDone) {
        await handleFetch(tempFilters);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetchOnFilters]);

  useEffect(() => {
    setChartToUse((prev) => !prev);
  }, [updatedData]);

  useEffect(() => {
    (async () => {
      if (!dataFetchFromParent) {
        await handleFetch(configuration?.graphqlFilters);
        setInitialFetchDone(true);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataFetchFromParent, resetChartFetch]);

  useEffect(() => {
    if (dataFetchFromParent) {
      setUpdatedData(data);
    }
  }, [dataFetchFromParent, data]);

  const handleFetch = async (graphqlFilters: GraphqlFilter[] | undefined) => {
    if (schema) {
      try {
        setFetchStatus("loading");
        const result = await getAreaData({
          variables: {
            input: {
              ...schema,
              filters: buildGraphqlFilters(graphqlFilters),
            },
          },
        });
        if (!result.data || result.error) {
          throw new Error(JSON.stringify(result));
        }
        const resultData = result.data.areaChartData.chartData;

        setUpdatedData(resultData);
        setFetchStatus(null);
      } catch (err) {
        console.log("err ", err);
        setFetchStatus("error");
      }
    }
  };

  return (
    <>
      {chartToUse ? (
        <AreaChart
          className={className}
          configuration={configuration}
          data={data || heatMapDefaultData}
          isStatic={isStatic}
          schema={schema}
        />
      ) : null}

      {!chartToUse ? (
        <AreaChart
          className={className}
          configuration={configuration}
          data={data || heatMapDefaultData}
          isStatic={isStatic}
          schema={schema}
        />
      ) : null}

      {isStatic ? null : <LoadingBackdrop loading={loadingData} />}

      <Alert
        css={styles.reverseContentBreak}
        message={t("Something went wrong")}
        showAlert={fetchStatus === "error"}
        severity={"error"}
      />
    </>
  );
};

export default ExcellenceAreaChart;
