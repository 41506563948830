import { useEffect, useState } from "react";
import { MaintenanceMachineTask } from "../../../../../Api/Maintenance/apiMaintenanceDataTypes";
import { SelectOption } from "../../../../../Global/Types/commonTypes";
import {
  MaintenanceMachineTaskType,
  MaintenancePlanTemplateApplyChangesOption,
  maintenancePlanTemplateApplyChangesOptions,
} from "../maintenancePlanUtils";
import { Box, Stack, Typography } from "@mui/material";
import LabelWithBoldedPart from "../../../../MaterialUI/LabelWithBoldedPart";
import Button from "../../../../MaterialUI/Button";
import Select from "../../../../MaterialUI/FormFields/Select";
import cssLayoutStyles from "../../../../../Global/Styles/layout";
import Collapse from "../../../../MaterialUI/Collapse";
import RadioGroup from "../../../../MaterialUI/FormFields/RadioGroup";
import { useLanguageContext } from "../../../../../context/LanguageContext";
import { useTranslateSchemeArray } from "../../../../../Global/Hooks/useTranslations";

const typeOptions: SelectOption[] = [
  {
    value: "repetitive",
    description: "Repetitive Task Template",
  },
  {
    value: "task",
    description: "This Task Only",
  },
];

interface MaintenancePlanDeleteFormProps {
  task: MaintenanceMachineTask;
  handleOnDelete: () => Promise<void>;
}

const MaintenancePlanDeleteForm: React.FC<MaintenancePlanDeleteFormProps> = ({
  task,
}) => {
  const { t } = useLanguageContext();
  const styles = { ...cssLayoutStyles };

  const [taskType, setTaskType] = useState<MaintenanceMachineTaskType | "">("");
  const [applyChangesTo, setApplyChangesTo] =
    useState<MaintenancePlanTemplateApplyChangesOption>("All Open Tasks");

  useEffect(() => {
    return () => {
      setTaskType("");
    };
  }, []);

  const handleDelete = async () => {
    try {
    } catch (err) {
      console.log("handleDelete err ", err);
    }
  };

  return (
    <Stack spacing={3}>
      <Box component="div">
        <LabelWithBoldedPart text={t("Task ID")} bolded={task.id} inverse />
        <LabelWithBoldedPart
          text={t("Description")}
          bolded={task.task_description}
          inverse
        />
      </Box>

      {task.task_template_id ? (
        <Stack spacing={3}>
          <Typography variant="body1">
            {t(
              "If you choose to delete the task template, all relevant tasks created from this template will be deleted."
            )}
          </Typography>
          <Select
            label={t("Type")}
            value={taskType}
            onChange={(e) => setTaskType(e.target.value as MaintenanceMachineTaskType)}
            selectOptions={useTranslateSchemeArray(typeOptions)}
          />

          {taskType === "repetitive" ? (
            <Collapse in={taskType === "repetitive"}>
              <RadioGroup
                label={t("Tasks to Delete")}
                value={applyChangesTo}
                onChange={(e) =>
                  setApplyChangesTo(
                    e.target.value as MaintenancePlanTemplateApplyChangesOption
                  )
                }
                radioOptions={useTranslateSchemeArray(
                  maintenancePlanTemplateApplyChangesOptions
                )}
              />
            </Collapse>
          ) : null}
        </Stack>
      ) : null}

      <Stack css={styles.width100} alignItems="center" justifyContent="center">
        <Button css={styles.widthLimit20} color="error" onClick={handleDelete}>
          {t("Confirm Deletion")}
        </Button>
      </Stack>
    </Stack>
  );
};

export default MaintenancePlanDeleteForm;
