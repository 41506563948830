import { useEffect, useState } from "react";
import callApi from "../../Api/callApi";
import { useAuthedContext } from "../../context/AuthContext";
import {
  GetQueryMaintenanceMachinesSnippet,
  GetQueryMaintenanceTasksSnippet,
} from "../../Api/Maintenance/apiMaintenanceSnippets";
import {
  getQueryMaintenanceMachines,
  getQueryMaintenanceTasks,
} from "../../Api/Maintenance/apiMaintenanceGetQueries";
import { Box, IconButton, Stack, useTheme } from "@mui/material";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { useOutletContext } from "react-router-dom";
import { AppRouterProps } from "../../Layout/layoutVariables";
import Button from "../../Components/MaterialUI/Button";
import cssLayoutStyles from "../../Global/Styles/layout";
import cssSpacingStyles from "../../Global/Styles/spacing";
import MaintenancePlanTable from "../../Components/PageComponents/Maintenance/MaintenancePlan/MaintenancePlanTable/MaintenancePlanTable";
import {
  MaintenancePlanTableTaskRow,
  MaintenancePlanAddEditFormResult,
  handleGetMaintenancePlanTableRows,
  MaintenancePlanModalType,
} from "../../Components/PageComponents/Maintenance/MaintenancePlan/maintenancePlanUtils";
import Modal from "../../Components/MaterialUI/Modal";
import MaintenancePlanAddForm from "../../Components/PageComponents/Maintenance/MaintenancePlan/MaintenancePlanTable/MaintenancePlanAddForm";
import MaintenancePlanModal from "../../Components/PageComponents/Maintenance/MaintenancePlan/MaintenancePlanModal/MaintenancePlanModal";
import { MaintenanceMachineTask } from "../../Api/Maintenance/apiMaintenanceDataTypes";
import MaintenancePlanCalendar from "../../Components/PageComponents/Maintenance/MaintenancePlan/MaintenancePlanCalendar/MaintenancePlanCalendar";
import { useLanguageContext } from "../../context/LanguageContext";
import CustomIconsTooltip from "../../Components/SmallComponents/Tooltip/CustomIconsTooltip";
import PageTitle from "../../Components/SmallComponents/PageTitle/PageTitle";

const MaintenancePlan: React.FC = () => {
  const { t } = useLanguageContext();
  const theme = useTheme();
  const styles = { ...cssLayoutStyles, ...cssSpacingStyles(theme) };
  const [machines, setMachines] = useState<GetQueryMaintenanceMachinesSnippet>([]);
  const [machineTasksData, setMachineTasksData] =
    useState<GetQueryMaintenanceTasksSnippet>();
  const [rows, setRows] = useState<MaintenancePlanTableTaskRow[]>([]);
  const [tasksMapping, setTasksMapping] = useState<
    Record<string, MaintenanceMachineTask>
  >({});

  const [modalType, setModalType] = useState<MaintenancePlanModalType>(null);
  const [openAddModal, setOpenAddModal] = useState<boolean>(false);
  const [calendarView, setCalendarView] = useState<boolean>(false);

  const { setAuthedUser } = useAuthedContext();
  const { smMediaQuery, setExtraRightNavMenu, setExtraTopNavMenu } =
    useOutletContext<AppRouterProps>();
  const [unsavedChanges, setUnsavedChanges] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      try {
        const machines = await callApi<GetQueryMaintenanceMachinesSnippet>({
          query: getQueryMaintenanceMachines,
          auth: { setAuthedUser },
        });

        setMachines(machines);

        await handleFetchAllTasks();
      } catch (error) {
        console.log("There was an error fetching machines tasks", error);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (smMediaQuery) {
      setExtraTopNavMenu(null);
      setExtraRightNavMenu(null);
    } else {
      setExtraTopNavMenu(<PageTitle title={t("Maintenance Plan")} />);
      setExtraRightNavMenu(
        <CreateNewTaskMenu
          handleAdd={() => {
            setOpenAddModal(true);
          }}
        />
      );
    }

    return () => {
      setExtraTopNavMenu(null);
      setExtraRightNavMenu(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [smMediaQuery]);

  const handleFetchAllTasks = async () => {
    try {
      const maintenanceTasks = await callApi<GetQueryMaintenanceTasksSnippet>({
        query: getQueryMaintenanceTasks(1000),
        auth: { setAuthedUser },
      });

      const mapping: Record<string, MaintenanceMachineTask> = {};

      maintenanceTasks.forEach((task) => {
        mapping[task.id] = task;
      });

      setMachineTasksData(maintenanceTasks);
      setTasksMapping(mapping);

      setRows(handleGetMaintenancePlanTableRows(setModalType, maintenanceTasks));
    } catch (error) {
      console.log("handleFetchAllTasks ", error);
    }
  };

  const handleOnCreateNew = async (value: MaintenancePlanAddEditFormResult) => {
    const task = value.task;
    const template = value.template;
    if (task?.id || template?.tasks?.length) {
      await handleFetchAllTasks();
    }
  };

  const handleModalSubmit = async (_?: MaintenancePlanAddEditFormResult) => {
    if (modalType) {
      await handleFetchAllTasks();
    }
  };

  const handleSetUnsavedChanges = (unsavedChanges: boolean) => {
    if (unsavedChanges) {
      setUnsavedChanges(true);
    }
  };

  return (
    <>
      <Stack
        css={[styles.width100, styles.contentBreak]}
        spacing={2}
        alignItems="center"
        justifyContent="space-between"
        direction="row"
      >
        <Box component="div" />
        {smMediaQuery ? (
          <PageTitle css={styles.labelBreak} title={t("Maintenance Plan")} />
        ) : null}
        <Button
          onClick={() => setCalendarView((prev) => !prev)}
          disabled={!machineTasksData}
        >
          {calendarView ? t("Table") : t("Calendar")} {t("View")}
        </Button>
      </Stack>

      {calendarView && machineTasksData ? (
        <MaintenancePlanCalendar
          data={machineTasksData || null}
          setModalType={setModalType}
        />
      ) : null}

      {!calendarView ? (
        <MaintenancePlanTable
          rows={rows}
          loading={!machineTasksData}
          hideColumns={[
            "comments",
            "documents",
            "responsible",
            "workHours",
            "executionDuration",
            "extraDescription",
          ]}
        />
      ) : null}

      <Modal
        open={openAddModal}
        fullWidth
        label={t("Add Task")}
        onClose={() => setOpenAddModal(false)}
        unsavedChanges={unsavedChanges}
        setUnsavedChanges={setUnsavedChanges}
      >
        <MaintenancePlanAddForm
          machines={machines}
          handleOnCreate={handleOnCreateNew}
          handleSetUnsavedChanges={handleSetUnsavedChanges}
          setUnsavedChanges={setUnsavedChanges}
        />
      </Modal>

      {modalType !== null && tasksMapping?.[modalType.taskID] ? (
        <MaintenancePlanModal
          modalData={modalType}
          setModalType={setModalType}
          machines={machines}
          handleSubmit={handleModalSubmit}
          task={tasksMapping[modalType.taskID]}
        />
      ) : null}
    </>
  );
};

export default MaintenancePlan;

interface CreateNewTaskMenuProps {
  handleAdd: () => void;
}

const CreateNewTaskMenu: React.FC<CreateNewTaskMenuProps> = ({ handleAdd }) => {
  const { t } = useLanguageContext();
  return (
    <Stack alignItems="center">
      <CustomIconsTooltip tooltipText={t("Add")}>
        <IconButton aria-label="create new project" onClick={handleAdd}>
          <AddOutlinedIcon />
        </IconButton>
      </CustomIconsTooltip>
    </Stack>
  );
};

/**
 *
 * task working hours form to have rest time
 * also to have who user did it
 *
 */
