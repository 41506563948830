import { Query } from "../callApi";
import { PostQueryStockInput, PutQueryStocksInput } from "./apiSupplyChainInputs";

export const deleteQueryStocks = (instance_id: string): Query => ({
  endpoint: `procurement_and_materials/materials/material-instances/${instance_id}`,
  method: "DELETE",
});

export const putQueryStocks = (
  instance_id: string,
  input: PutQueryStocksInput
): Query => ({
  endpoint: `procurement_and_materials/materials/material-instances/${instance_id}`,
  method: "PUT",
  variables: input,
});

export const postQueryStocks = (input: PostQueryStockInput): Query => ({
  endpoint: `procurement_and_materials/materials/material-instances`,
  method: "POST",
  variables: input,
});
