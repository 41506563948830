import { useLanguageContext } from "../../context/LanguageContext";
import { SelectOption } from "../Types/commonTypes";

export const useTranslatedModalTitle = <T extends Record<string, string>>(
  translations: T
) => {
  const { t } = useLanguageContext();
  return (key: keyof T) => t(translations[key] as string);
};

export const useTranslateSchemeArray = (schemeArray: SelectOption[]) => {
  const { t } = useLanguageContext();

  return schemeArray.map((item) => ({
    value: item.value,
    description: t(item.description),
  }));
};

export const useTranslateFields = <T extends Record<string, any>>(
  items: T[],
  fieldsToTranslate: (keyof T)[],
  nestedFieldKey: keyof T = "columns"
): T[] => {
  const { t } = useLanguageContext();

  const translateItem = (item: T): T => {
    const translatedItem = { ...item };

    fieldsToTranslate.forEach((field) => {
      if (typeof translatedItem[field] === "string") {
        translatedItem[field] = t(translatedItem[field] as string) as T[keyof T];
      }
    });

    const nestedItems = translatedItem[nestedFieldKey] as unknown as T[] | undefined;
    if (nestedItems && Array.isArray(nestedItems)) {
      translatedItem[nestedFieldKey] = useTranslateFields(
        nestedItems,
        fieldsToTranslate,
        nestedFieldKey
      ) as unknown as T[keyof T];
    }

    return translatedItem;
  };

  return items.map((item) => translateItem(item));
};

export const useTranslateArray = (array: string[]) => {
  const { t } = useLanguageContext();

  return array.map((item) => t(item));
};
