import { Box, Stack, Typography } from "@mui/material";
import { KanbanWorkflowsGeneralNodeData } from "../../../../../kanbanWorkflowUtils";
import LabelWithBoldedPart from "../../../../../../../Components/MaterialUI/LabelWithBoldedPart";
import { useLanguageContext } from "../../../../../../../context/LanguageContext";

interface NodeGeneralDataModalProps {
  data: KanbanWorkflowsGeneralNodeData;
}

const NodeGeneralDataModal: React.FC<NodeGeneralDataModalProps> = ({ data }) => {
  const { t } = useLanguageContext();
  return (
    <Stack spacing={1}>
      {data?.preConditions?.length ? (
        <Box component="div">
          <Box component="div" sx={{ marginBottom: "8px" }}>
            <Typography mb={1} variant="h4" align="center" color="textSecondary">
              {t("Pre-conditions")}
            </Typography>
            <Stack spacing={1}>
              {data.preConditions.map((item) => (
                <LabelWithBoldedPart
                  key={item.id}
                  bolded={`${item.comparison_operator} ${item.value}`}
                  text={item.data_field.name}
                  inverse
                />
              ))}
            </Stack>
          </Box>
        </Box>
      ) : (
        <LabelWithBoldedPart text={t("Pre-conditions")} bolded={""} />
      )}

      <LabelWithBoldedPart text={t("ID")} bolded={data.id} />
      <LabelWithBoldedPart text={t("Name")} bolded={data.name || ""} />
      <LabelWithBoldedPart text={t("Template")} bolded={data.template_name || ""} />
      <LabelWithBoldedPart
        text={t("Attributes")}
        bolded={
          data?.template_attributes?.length ? data.template_attributes.join(", ") : ""
        }
      />

      {data?.postConditions?.length ? (
        <Box component="div">
          <Box component="div" sx={{ marginTop: "12px" }}>
            <Typography mb={1} variant="h4" align="center" color="textSecondary">
              {t("Pre-conditions")}
            </Typography>
            <Stack spacing={1}>
              {data.postConditions.map((item) => (
                <LabelWithBoldedPart
                  key={item.id}
                  bolded={`${item.comparison_operator} ${item.value}`}
                  text={item.data_field.name}
                  inverse
                />
              ))}
            </Stack>
          </Box>
        </Box>
      ) : (
        <LabelWithBoldedPart text={t("Pre-conditions")} bolded={""} />
      )}
    </Stack>
  );
};

export default NodeGeneralDataModal;
