import { SelectOption } from "../../../Global/Types/commonTypes";

export const objectTrackingModalTitleTranslations = {
  "Create Object": "Create Object",
  "Update Location": "Update Location",
  "Checkout Location": "Checkout Location",
} as const;

export type ObjectTrackingModalTitle = keyof typeof objectTrackingModalTitleTranslations;

export type CreateObjectFormValues = {
  type: string;
  material_master: string;
  locationType: string;
  location: string;
  quantity: number | null;
};

export type ChangeObjectLocationFormValues = {
  locationType: string;
  location: string;
};

export type ObjectTackingType = "Material" | "Semi-Product" | "Product";

export const typeSelectOptions: SelectOption[] = [
  { value: "Material", description: "Material" },
  { value: "Semi-Product", description: "Semi-Product" },
  { value: "Product", description: "Product" },
];
