import { Stack, IconButton, Typography, Theme, Menu } from "@mui/material";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import PanToolAltOutlinedIcon from "@mui/icons-material/PanToolAltOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import PublishIcon from "@mui/icons-material/Publish";
import { useState } from "react";
import { FormStatuses } from "../../../../Global/Types/commonTypes";
import { css } from "@emotion/react";
import useTheme from "@mui/material/styles/useTheme";
import cssSpacingStyles from "../../../../Global/Styles/spacing";
import AddNewDynamicFormField from "./AddNewDynamicFormField";
import {
  DynamicFormGridItemData,
  DynamicFormGridItemFormField,
  DynamicFormGridLayoutSettings,
  dynamicFromHandlePrepareWidgetsData,
} from "./dynamicFormsUtils";
import { WidgetGridItem } from "../../../LargeComponents/WidgetsGrid/widgetsGridUtils";
import { v4 as uuidv4 } from "uuid";
import {
  DeleteQueryOperationsForm,
  PostQueryOperationsWidgetsInput,
} from "../../../../Api/Operations/apiOperationsInputs";
import {
  deleteQueryOperationsForm,
  postQueryOperationsWidgets,
} from "../../../../Api/Operations/apiOperationsPostQueries";
import {
  OperationsWidget,
  PostQueryOperationsWidgetsSnippet,
} from "../../../../Api/Operations/apiOperationsSnippets";
import callApi from "../../../../Api/callApi";
import { useNavigate } from "react-router-dom";
import { useAuthedContext } from "../../../../context/AuthContext";
import Modal from "../../../MaterialUI/Modal";
import Button from "../../../MaterialUI/Button";
import UpdateDynamicFormLayout from "./UpdateDynamicFormLayout";
import CustomIconsTooltip from "../../../SmallComponents/Tooltip/CustomIconsTooltip";
import { useLanguageContext } from "../../../../context/LanguageContext";

const cssStyles = (theme: Theme) => ({
  addMenu: css({
    background: theme.palette.common.white,
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
  }),
});

type ModalTitle = "Edit layout configuration" | "Confirm layout delete" | null;

interface DynamicFormItemExtraPageMenuProps {
  setWidgetItems: React.Dispatch<
    React.SetStateAction<WidgetGridItem<DynamicFormGridItemData>[]>
  >;
  setInitialWidgetItems: React.Dispatch<
    React.SetStateAction<WidgetGridItem<DynamicFormGridItemData>[]>
  >;
  widgetItems: WidgetGridItem<DynamicFormGridItemData>[];
  settings: DynamicFormGridLayoutSettings;
  setUnsavedChanges: React.Dispatch<React.SetStateAction<boolean>>;
  setTitle: React.Dispatch<React.SetStateAction<string | null>>;
}

const DynamicFormItemExtraPageMenu: React.FC<DynamicFormItemExtraPageMenuProps> = ({
  setWidgetItems,
  setInitialWidgetItems,
  widgetItems,
  settings,
  setUnsavedChanges,
  setTitle,
}) => {
  const { t } = useLanguageContext();
  const theme = useTheme();
  const styles = {
    ...cssStyles(theme),
    ...cssSpacingStyles(theme),
  };
  const [formStatus, setFormStatus] = useState<FormStatuses>(null);
  const [modalTitle, setModalTitle] = useState<ModalTitle>(null);
  const [addNewMenuAnchor, setAddNewMenuAnchor] = useState<null | HTMLElement>(null);
  const [unsavedEditChanges, setUnsavedEditChanges] = useState<boolean>(false);

  console.log(unsavedEditChanges);

  const openAddNewMenu = Boolean(addNewMenuAnchor);
  const { setAuthedUser } = useAuthedContext();
  const navigate = useNavigate();

  const handleOpenAddNewMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAddNewMenuAnchor(event.currentTarget);
  };
  const handleCloseAddNewMenu = () => {
    setAddNewMenuAnchor(null);
  };

  const handleSetUnsavedChanges = (unsavedChanges: boolean) => {
    if (unsavedChanges) {
      setUnsavedEditChanges(true);
    }
  };

  const handleAddNewWidget = (field: DynamicFormGridItemFormField, title: string) => {
    const newItemLayout = {
      i: uuidv4().split("-")[0],
      x: 0,
      y: 0,
      w: 6,
      h: 5,
      isResizable: true,
      isBounded: false,
    };
    const newWidget: WidgetGridItem<DynamicFormGridItemData> = {
      layout: {
        xl: newItemLayout,
        lg: newItemLayout,
        md: newItemLayout,
        sm: newItemLayout,
        xs: newItemLayout,
      },
      widget: {
        formField: {
          ...field,
        },
      },
      title,
    };

    // IMPORTANT!!! Keep the newly added item
    // as the first element of the array. Look at logic for
    // handleOnLayoutChange() in the <WidgetsGridLayout/> component
    setWidgetItems((prev) => [newWidget, ...prev]);
    handleCloseAddNewMenu();
  };

  const handleSaveWidgets = async () => {
    try {
      setFormStatus("loading");

      const charts: OperationsWidget[] = widgetItems.map((item) => {
        const { id, ...other } = item;
        return {
          content: other,
          ...(id && { id: id }),
        };
      });

      const saveChangesInput: PostQueryOperationsWidgetsInput = {
        layoutID: settings.id,
        widgets: charts,
      };

      const result = await callApi<PostQueryOperationsWidgetsSnippet>({
        query: postQueryOperationsWidgets(saveChangesInput),
        auth: { setAuthedUser },
      });

      setWidgetItems(dynamicFromHandlePrepareWidgetsData(result.widgets));
      setInitialWidgetItems(dynamicFromHandlePrepareWidgetsData(result.widgets));

      setFormStatus("success");
      setUnsavedChanges(false);
    } catch (err) {
      console.log("handleSaveWidgets - save excellence widgets data ", err);
      setFormStatus("error");
    }
  };

  const handleDeleteLayout = async () => {
    try {
      setFormStatus("loading");
      const redirectURL = "/GIANT-Operations-Suite/Forms-Designer";

      const input: DeleteQueryOperationsForm = settings.id;

      await callApi<string>({
        query: deleteQueryOperationsForm(input),
        auth: { setAuthedUser },
      });

      setFormStatus(null);
      navigate({
        pathname: redirectURL,
      });
    } catch (err) {
      console.log("handleDeleteLayout() func ", err);
      setFormStatus("error");
    }
  };

  return (
    <>
      <Stack spacing={1} justifyContent="flex-end" direction="column">
        <Stack alignItems="center">
          <CustomIconsTooltip tooltipText={t("Add")}>
            <IconButton
              aria-label="add new widget"
              onClick={handleOpenAddNewMenu}
              disabled={formStatus === "loading"}
            >
              <AddOutlinedIcon />
            </IconButton>
          </CustomIconsTooltip>
        </Stack>
        <Stack alignItems="center">
          <CustomIconsTooltip tooltipText={t("Edit")}>
            <IconButton
              aria-label="edit layout settings"
              onClick={() => setModalTitle("Edit layout configuration")}
              disabled={formStatus === "loading"}
            >
              <EditOutlinedIcon />
            </IconButton>
          </CustomIconsTooltip>
        </Stack>

        <Stack alignItems="center">
          <CustomIconsTooltip tooltipText={t("Publish")}>
            <IconButton aria-label="duplicate dashboard" disabled>
              <PublishIcon />
            </IconButton>
          </CustomIconsTooltip>
        </Stack>

        <Stack alignItems="center">
          <CustomIconsTooltip tooltipText={t("Select")}>
            <IconButton aria-label="select custom variant" disabled>
              <PanToolAltOutlinedIcon />
            </IconButton>
          </CustomIconsTooltip>
        </Stack>
        <Stack alignItems="center">
          <CustomIconsTooltip tooltipText={t("Delete")}>
            <IconButton
              aria-label="delete layout"
              onClick={() => setModalTitle("Confirm layout delete")}
              disabled={formStatus === "loading"}
            >
              <DeleteOutlineOutlinedIcon />
            </IconButton>
          </CustomIconsTooltip>
        </Stack>
        <Stack alignItems="center">
          <CustomIconsTooltip tooltipText={t("Save")}>
            <IconButton
              aria-label="save changes"
              onClick={handleSaveWidgets}
              disabled={formStatus === "loading"}
            >
              <SaveOutlinedIcon />
            </IconButton>
          </CustomIconsTooltip>
        </Stack>
      </Stack>

      <Menu
        anchorEl={addNewMenuAnchor}
        open={openAddNewMenu}
        onClose={handleCloseAddNewMenu}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <AddNewDynamicFormField
          css={[styles.addMenu, styles.labelBreak]}
          handleAddNewFormField={(formField, title) =>
            handleAddNewWidget(formField, title)
          }
        />
      </Menu>

      <Modal
        open={!!modalTitle}
        onClose={() => setModalTitle(null)}
        fullWidth
        maxWidth="sm"
        label={modalTitle}
        unsavedChanges={unsavedEditChanges}
        setUnsavedChanges={setUnsavedEditChanges}
      >
        {modalTitle === "Confirm layout delete" ? (
          <Stack spacing={2} justifyContent="center" alignItems="center">
            <Typography>Clicking the below button will delete this form</Typography>
            <Button onClick={handleDeleteLayout} loading={formStatus === "loading"}>
              Confirm Delete
            </Button>
          </Stack>
        ) : null}

        {modalTitle === "Edit layout configuration" ? (
          <UpdateDynamicFormLayout
            settings={settings}
            handleCloseModal={() => setModalTitle(null)}
            setTitle={setTitle}
            handleSetUnsavedChanges={handleSetUnsavedChanges}
            setUnsavedChanges={setUnsavedEditChanges}
          />
        ) : null}
      </Modal>
    </>
  );
};

export default DynamicFormItemExtraPageMenu;
