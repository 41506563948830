import { Query } from "../callApi";
import { GetQueryPidSingleParamInput } from "./apiPidWorkflowsInputs";

export const getQueryPidWorkflows = (): Query => ({
  endpoint: "layouts/p_and_id/workflows",
  method: "GET",
});

export const getQueryPidSingleWorkflow = (id: string): Query => ({
  endpoint: `layouts/p_and_id/workflow/${id}`,
  method: "GET",
});

export const getQueryPidWorkflowFields = (id: string): Query => ({
  endpoint: `layouts/p_and_id/workflow/${id}/data`,
  method: "GET",
});

export const getQueryPidAllSvgs = (): Query => ({
  endpoint: "layouts/p_and_id/categories/all-svgs",
  method: "GET",
});

export const getQueryPidSingleParam = (input: GetQueryPidSingleParamInput): Query => ({
  endpoint: `layouts/p_and_id/parameter`,
  method: "GET",
  variables: input,
});

export const getQueryPidWorkflowImages = (): Query => ({
  endpoint: `layouts/p_and_id/images`,
  method: "GET",
});
