import { SerializedStyles } from "@emotion/react";
import { Box, useTheme, Typography } from "@mui/material";
import { LineAreaHeatMapScatterPlotData } from "../../EditExcellenceChartForms/excellenceChartFormUtils";
import { ResponsiveHeatMap, ComputedCell, HeatMapDatum } from "@nivo/heatmap";
import {
  DynamicGridChartType,
  getChartColors,
  getCurrentColorScheme,
} from "../../nivoTheme";
import { scaleLinear } from "d3-scale";
import { HeatMapConfig, HeatMapDataSchema } from "./heatMapTypes";
import { formatNumber } from "../../../../Global/Utils/commonFunctions";
import { useRef } from "react";
import { CustomTooltip } from "../../ExcellenceSmallComponents";

interface HeatmapProps {
  css?: SerializedStyles[] | SerializedStyles;
  className?: string;
  configuration: HeatMapConfig;
  data: LineAreaHeatMapScatterPlotData;
  isStatic?: boolean;
  schema: HeatMapDataSchema | null;
  paramMapping?: Record<string, string>;
}

const Heatmap: React.FC<HeatmapProps> = ({
  className,
  data,
  isStatic,
  configuration,
}) => {
  const theme = useTheme();
  const chartContainerRef = useRef<HTMLDivElement>(null);
  const colorScale = scaleLinear<string>().domain([-100, 100]).range(getChartColors());

  const customColorFunction = (
    cell: Omit<
      ComputedCell<HeatMapDatum>,
      "opacity" | "borderColor" | "labelTextColor" | "color"
    >
  ): string => {
    return colorScale(cell.value as number);
  };

  const getFormattedValue = (value: number | null) => {
    const result = formatNumber(
      value,
      configuration.fixedDecimalValue,
      configuration.decimalPoints
    );
    if (!result) {
      return `${value}`;
    }
    return `${result}`;
  };

  const chartMargin = {
    top: isStatic ? 10 : 50,
    right: isStatic ? 10 : 100,
    bottom: isStatic ? 10 : 60,
    left: isStatic ? 10 : 100,
  };

  const renderHeatmapTooltipContent = (cell: ComputedCell<HeatMapDatum>) => (
    <Typography>
      {cell.serieId} - {cell.data?.x}: <strong>{getFormattedValue(cell.value)}</strong>
    </Typography>
  );

  return (
    <Box className={className} ref={chartContainerRef} component="div">
      <ResponsiveHeatMap
        data={data}
        margin={chartMargin}
        valueFormat=">-.2s"
        isInteractive={isStatic ? false : true}
        label={(d) => getFormattedValue(d.value)}
        labelTextColor={
          getCurrentColorScheme() === DynamicGridChartType.GIANT_PALETTE_ONE ||
          getCurrentColorScheme() === DynamicGridChartType.GIANT_PALETTE_TWO
            ? {
                from: "color",
                modifiers: [["darker", 10]],
              }
            : "#FFFFFF"
        }
        axisTop={
          isStatic
            ? null
            : {
                tickSize: 5,
                tickPadding: 5,
                tickRotation: -90,
                legend: "",
                legendOffset: 46,
              }
        }
        axisRight={
          isStatic
            ? null
            : {
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: "country",
                legendPosition: "middle",
                legendOffset: 85,
              }
        }
        axisLeft={
          isStatic
            ? null
            : {
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: "country",
                legendPosition: "middle",
                legendOffset: -85,
              }
        }
        colors={customColorFunction}
        theme={{
          text: {
            fill: theme.palette.common.black,
          },
          tooltip: {
            container: {
              background: theme.palette.common.white,
            },
          },
        }}
        emptyColor="#555555"
        legends={[
          {
            anchor: "bottom",
            translateX: 0,
            translateY: 30,
            length: 400,
            thickness: 8,
            direction: "row",
            tickPosition: "after",
            tickSize: 3,
            tickSpacing: 4,
            tickOverlap: false,
            tickFormat: ">-.2s",
            title: "Value →",
            titleAlign: "start",
            titleOffset: 4,
          },
        ]}
        tooltip={(tooltipProps) => {
          const { cell } = tooltipProps;
          return (
            <CustomTooltip
              data={cell}
              tooltipColor={cell.color}
              renderContent={renderHeatmapTooltipContent}
            />
          );
        }}
      />
      <Box component="div" css={{ display: "flex", justifyContent: "flex-end" }}>
        {getChartColors().map((color, index) => (
          <Box
            component="div"
            key={`color-key-${index}`}
            css={{
              display: "flex",
              alignItems: "center",
              marginRight: "10px",
            }}
          >
            <Box
              component="div"
              css={{
                width: "20px",
                height: "10px",
                backgroundColor: color,
                marginRight: "5px",
              }}
            />
            <Typography variant="body2">{index}</Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default Heatmap;
