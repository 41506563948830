import { BoxPlotData } from "../../ExcellenceWidgets/AdvancedWidgets/BoxPlot/boxPlotTypes";
import { SunburstData } from "../../ExcellenceWidgets/AdvancedWidgets/Sunburst/sunburstTypes";
import {
  BarColumnRadarData,
  ExcellenceTableData,
  LineAreaHeatMapScatterPlotData,
  PieDonutChartData,
} from "../../ExcellenceWidgets/EditExcellenceChartForms/excellenceChartFormUtils";
import { ExcellenceTableConfiguration } from "../../ExcellenceWidgets/ExcellenceTable/editExcellenceTabletypes";
import {
  handleSwitchFromChartDataToTable,
  switchBarRadarDataToTable,
  switchBoxPlotDataToTable,
  switchLineAreaHeatMapDataToTable,
  switchPieDataToTable,
  switchSunburstDataToTable,
} from "../../ExcellenceWidgets/ExcellenceTable/switchExcellenceTableUtils";
import { ExcellenceChartKeyType } from "../../PageComponents/Excellence/excellenceUtils";
import { ExcellenceDateTimeConfigurationData } from "../DateTimeConfiguration/dateTimeConfigurationUtils";
import { DynamicGridChartOptions } from "./dynamicGridUtils";

export type ExcellenceSwitchIncomingData = {
  dataSchema: any;
  dateTimeConfig: ExcellenceDateTimeConfigurationData | null;
  lineAreaHeatMapChartData?: LineAreaHeatMapScatterPlotData;
  pieDonutChartData?: PieDonutChartData;
  barColumnRadarData?: BarColumnRadarData;
  excellenceTableData?: ExcellenceTableData;
  tableConfig?: ExcellenceTableConfiguration;
  boxPlotData?: BoxPlotData;
  sunburstData?: SunburstData;
  configuration: any;
};

export const handleSwitchChartType = (
  currentType: ExcellenceChartKeyType,
  newType: ExcellenceChartKeyType,
  chart: DynamicGridChartOptions | undefined
): DynamicGridChartOptions | null => {
  const incomingData = handleGetSwitchIncomingData(currentType, chart, newType);
  const dataSchema = incomingData?.dataSchema;
  const lineAreaHeatMapChartData = incomingData?.lineAreaHeatMapChartData;
  const pieDonutChartData = incomingData?.pieDonutChartData;
  const barColumnRadarData = incomingData?.barColumnRadarData;
  const excellenceTableData = incomingData?.excellenceTableData;
  const tableConfig = incomingData?.tableConfig;
  const boxPlotChartData = incomingData?.boxPlotData;
  const sunburstChartData = incomingData?.sunburstData;

  switch (newType) {
    case "lineChart": {
      if (lineAreaHeatMapChartData) {
        return {
          [currentType]: undefined,
          lineChart: {
            config: incomingData.configuration,
            data: lineAreaHeatMapChartData,
            dataSchema: dataSchema || null,
          },
        };
      }
    }
    case "timeChart": {
      if (lineAreaHeatMapChartData) {
        return {
          [currentType]: undefined,
          timeChart: {
            config: incomingData.configuration,
            data: lineAreaHeatMapChartData,
            dataSchema: dataSchema || null,
            dateTimeConfig: incomingData.configuration || null,
          },
        };
      }
    }
    case "barChart": {
      if (barColumnRadarData || dataSchema) {
        return {
          [currentType]: undefined,
          barChart: {
            config: {
              ...incomingData.configuration,
              isColumn: false,
            },
            data: barColumnRadarData || [],
            dataSchema: dataSchema || null,
          },
        };
      }
    }
    case "radarChart": {
      if (barColumnRadarData || dataSchema) {
        return {
          [currentType]: undefined,
          radarChart: {
            config: incomingData.configuration,
            data: barColumnRadarData || [],
            dataSchema: dataSchema || null,
          },
        };
      }
    }
    case "columnChart": {
      if (barColumnRadarData) {
        return {
          [currentType]: undefined,
          columnChart: {
            config: {
              ...incomingData.configuration,
              isColumn: true,
            },
            data: barColumnRadarData,
            dataSchema: dataSchema || null,
          },
        };
      }
    }
    case "areaChart": {
      if (lineAreaHeatMapChartData) {
        return {
          [currentType]: undefined,
          areaChart: {
            config: incomingData.configuration,
            data: lineAreaHeatMapChartData,
            dataSchema: dataSchema || null,
          },
        };
      }
    }
    case "pieChart": {
      if (pieDonutChartData || dataSchema) {
        return {
          [currentType]: undefined,
          pieChart: {
            config: incomingData.configuration,
            data: pieDonutChartData || null,
            dataSchema: dataSchema || null,
          },
        };
      }
    }
    case "donutChart": {
      if (pieDonutChartData || dataSchema) {
        return {
          [currentType]: undefined,
          donutChart: {
            config: incomingData.configuration,
            data: pieDonutChartData || null,
            dataSchema: dataSchema || null,
          },
        };
      }
    }
    case "table": {
      if (excellenceTableData && tableConfig) {
        return {
          [currentType]: undefined,
          table: {
            config: {
              dataFormat: tableConfig.dataFormat,
              tableGridConfig: incomingData.configuration,
            },
            data: excellenceTableData,
            dataSchema: dataSchema || null,
          },
        };
      }
    }
    case "heatMap": {
      if (lineAreaHeatMapChartData) {
        return {
          [currentType]: undefined,
          heatMap: {
            config: incomingData.configuration,
            data: lineAreaHeatMapChartData,
            dataSchema: dataSchema || null,
          },
        };
      }
    }
    case "boxPlot": {
      if (boxPlotChartData) {
        return {
          [currentType]: undefined,
          boxPlot: {
            config: incomingData.configuration,
            data: boxPlotChartData,
            dataSchema: dataSchema || null,
          },
        };
      }
    }
    case "sunburst": {
      if (sunburstChartData) {
        return {
          [currentType]: undefined,
          sunburst: {
            config: incomingData.configuration,
            data: sunburstChartData,
            dataSchema: dataSchema || null,
          },
        };
      }
    }

    default: {
      return null;
    }
  }
};

export const getExcellenceChartType = (
  chart: DynamicGridChartOptions | undefined
): ExcellenceChartKeyType | null => {
  if (!chart) {
    return null;
  }

  const entries = Object.entries(chart);
  const found = entries.find((item) => Boolean(item[1]));
  if (found) {
    return found[0] as ExcellenceChartKeyType;
  }

  return null;
};

const handleGetSwitchIncomingData = (
  currentType: ExcellenceChartKeyType,
  chart: DynamicGridChartOptions | undefined,
  newType: ExcellenceChartKeyType
): ExcellenceSwitchIncomingData | null => {
  const switchToTable = newType === "table";

  const lineChartData = chart?.lineChart?.data;
  const timeChartData = chart?.timeChart?.data;
  const barChartData = chart?.barChart?.data;
  const barChartSchema = chart?.barChart?.dataSchema;
  const columnChartData = chart?.columnChart?.data;
  const columnChartSchema = chart?.columnChart?.dataSchema;
  const pieChartData = chart?.pieChart?.data;
  const pieChartSchema = chart?.pieChart?.dataSchema;
  const donutChartData = chart?.donutChart?.data;
  const donutChartSchema = chart?.donutChart?.dataSchema;
  const radarChartData = chart?.radarChart?.data;
  const radarChartSchema = chart?.radarChart?.dataSchema;
  const areaChartData = chart?.areaChart?.data;
  const tableData = chart?.table?.data;
  const tableConfig = chart?.table?.config;
  const tableDataSchema = chart?.table?.dataSchema;
  // Advanced charts
  const boxPlotData = chart?.boxPlot?.data;
  const heatMapData = chart?.heatMap?.data;
  const sunburstData = chart?.sunburst?.data;

  switch (currentType) {
    // Excellence Charts
    case "lineChart": {
      if (lineChartData) {
        const switchResult = switchToTable
          ? switchLineAreaHeatMapDataToTable(lineChartData)
          : undefined;
        return {
          lineAreaHeatMapChartData: lineChartData,
          excellenceTableData: switchResult ? switchResult.data : undefined,
          tableConfig: switchResult ? switchResult.config : undefined,
          dataSchema: chart.lineChart?.dataSchema,
          configuration: chart.lineChart?.config,
          dateTimeConfig: null,
        };
      }
    }
    case "timeChart": {
      if (timeChartData) {
        const switchResult = switchToTable
          ? switchLineAreaHeatMapDataToTable(timeChartData)
          : undefined;
        return {
          lineAreaHeatMapChartData: timeChartData,
          excellenceTableData: switchResult ? switchResult.data : undefined,
          tableConfig: switchResult ? switchResult.config : undefined,
          dataSchema: chart.timeChart?.dataSchema,
          dateTimeConfig: chart.timeChart?.dateTimeConfig || null,
          configuration: chart.timeChart?.config,
        };
      }
    }
    case "barChart": {
      if (barChartData || barChartSchema) {
        const switchResult =
          switchToTable && barChartData
            ? switchBarRadarDataToTable(barChartData)
            : undefined;
        return {
          barColumnRadarData: barChartData,
          excellenceTableData: switchResult ? switchResult.data : undefined,
          tableConfig: switchResult ? switchResult.config : undefined,
          dataSchema: chart.barChart?.dataSchema,
          dateTimeConfig: null,
          configuration: chart.barChart?.config,
        };
      }
    }
    case "columnChart": {
      if (columnChartData || columnChartSchema) {
        const switchResult =
          switchToTable && columnChartData
            ? switchBarRadarDataToTable(columnChartData)
            : undefined;
        return {
          barColumnRadarData: columnChartData,
          excellenceTableData: switchResult ? switchResult.data : undefined,
          tableConfig: switchResult ? switchResult.config : undefined,
          dataSchema: chart.columnChart?.dataSchema,
          dateTimeConfig: null,
          configuration: chart.columnChart?.config,
        };
      }
    }
    case "pieChart": {
      if (pieChartData || pieChartSchema) {
        const switchResult =
          switchToTable && pieChartData ? switchPieDataToTable(pieChartData) : undefined;
        return {
          pieDonutChartData: pieChartData || undefined,
          excellenceTableData: switchResult ? switchResult.data : undefined,
          tableConfig: switchResult ? switchResult.config : undefined,
          dataSchema: chart.pieChart?.dataSchema,
          dateTimeConfig: null,
          configuration: chart.pieChart?.config,
        };
      }
    }
    case "donutChart": {
      if (donutChartData || donutChartSchema) {
        const switchResult =
          switchToTable && donutChartData
            ? switchPieDataToTable(donutChartData)
            : undefined;
        return {
          pieDonutChartData: donutChartData || undefined,
          excellenceTableData: switchResult ? switchResult.data : undefined,
          tableConfig: switchResult ? switchResult.config : undefined,
          dataSchema: chart.donutChart?.dataSchema,
          dateTimeConfig: null,
          configuration: chart.donutChart?.config,
        };
      }
    }
    case "radarChart": {
      if (radarChartData || radarChartSchema) {
        const switchResult =
          switchToTable && radarChartData
            ? switchBarRadarDataToTable(radarChartData)
            : undefined;
        return {
          barColumnRadarData: radarChartData,
          excellenceTableData: switchResult ? switchResult.data : undefined,
          tableConfig: switchResult ? switchResult.config : undefined,
          dataSchema: chart.radarChart?.dataSchema,
          dateTimeConfig: null,
          configuration: chart.radarChart?.config,
        };
      }
    }
    case "areaChart": {
      if (areaChartData) {
        const switchResult = switchToTable
          ? switchLineAreaHeatMapDataToTable(areaChartData)
          : undefined;
        return {
          lineAreaHeatMapChartData: areaChartData,
          excellenceTableData: switchResult ? switchResult.data : undefined,
          tableConfig: switchResult ? switchResult.config : undefined,
          dataSchema: chart.areaChart?.dataSchema,
          dateTimeConfig: null,
          configuration: chart.areaChart?.config,
        };
      }
    }
    case "table": {
      if (tableData && tableConfig) {
        const result = handleSwitchFromChartDataToTable(
          tableDataSchema,
          null,
          tableData,
          newType,
          tableConfig.dataFormat,
          tableConfig.tableGridConfig
        );
        return result;
      }
    }
    // Advanced Charts
    case "boxPlot": {
      if (boxPlotData) {
        const switchResult = switchToTable
          ? switchBoxPlotDataToTable(boxPlotData)
          : undefined;
        return {
          excellenceTableData: switchResult ? switchResult.data : undefined,
          tableConfig: switchResult ? switchResult.config : undefined,
          dataSchema: chart.boxPlot?.dataSchema,
          dateTimeConfig: null,
          configuration: chart.boxPlot?.config,
        };
      }
    }
    case "heatMap": {
      if (heatMapData) {
        const switchResult = switchToTable
          ? switchLineAreaHeatMapDataToTable(heatMapData)
          : undefined;
        return {
          lineAreaHeatMapChartData: heatMapData,
          excellenceTableData: switchResult ? switchResult.data : undefined,
          tableConfig: switchResult ? switchResult.config : undefined,
          dataSchema: chart.heatMap?.dataSchema,
          dateTimeConfig: null,
          configuration: chart.heatMap?.config,
        };
      }
    }
    case "sunburst": {
      if (sunburstData) {
        const switchResult = switchToTable
          ? switchSunburstDataToTable(sunburstData)
          : undefined;
        return {
          excellenceTableData: switchResult ? switchResult.data : undefined,
          tableConfig: switchResult ? switchResult.config : undefined,
          dataSchema: chart.sunburst?.dataSchema,
          dateTimeConfig: null,
          configuration: chart.sunburst?.config,
        };
      }
    }

    default: {
      return null;
    }
  }
};

export type PeriodModeDates = {
  startDate: Date;
  endDate: Date;
};
