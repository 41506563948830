import { Box, Card, IconButton, Stack, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { FormStatuses } from "../../../../Global/Types/commonTypes";
import { useAuthedContext } from "../../../../context/AuthContext";
import callApi from "../../../../Api/callApi";
import cssSpacingStyles from "../../../../Global/Styles/spacing";
import { FileWithPath } from "react-dropzone";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import DownloadIcon from "@mui/icons-material/Download";
import { KanbanTaskTemplate, KanbanTemplateFile } from "../taskManagerUtils";
import Button from "../../../../Components/MaterialUI/Button";
import FileDropzone from "../../../../Components/SmallComponents/Dropzone/FileDropzone";
import Alert from "../../../../Components/MaterialUI/Alert";
import Modal from "../../../../Components/MaterialUI/Modal";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  deleteQueryKanbanTaskTemplateFile,
  postQueryKanbanTaskTemplateUploadFile,
} from "../../../KanbanWorkflows/api/mutations";
import { useLanguageContext } from "../../../../context/LanguageContext";

interface KanTaskTemplateFilesProps {
  handleCloseModal: () => void;
  onFileUpload: () => Promise<void>;
  template: KanbanTaskTemplate;
}

const KanTaskTemplateFiles: React.FC<KanTaskTemplateFilesProps> = ({
  handleCloseModal,
  onFileUpload,
  template,
}) => {
  const { t } = useLanguageContext();
  const theme = useTheme();
  const styles = { ...cssSpacingStyles(theme) };

  const [alertMessage, setAlertMessage] = useState<string | null>(null);
  const [uploadedFiles, setUploadedFiles] = useState<KanbanTemplateFile[]>(
    template.files
  );

  const [alertStatus, setAlertStatus] = useState<FormStatuses | null>(null);
  const [file, setFile] = useState<FileWithPath | null>(null);
  const [deleteFileID, setDeleteFileID] = useState<string | null>(null);

  const { setAuthedUser } = useAuthedContext();

  useEffect(() => {
    (async () => {
      if (file) {
        setAlertMessage(null);
        setAlertStatus(null);

        try {
          const nameExists = uploadedFiles.some((item) => item.file_name === file.name);
          if (nameExists) {
            setAlertMessage(t("File with this name exists"));
            setAlertStatus("warning");
            return;
          }
          setAlertMessage(t("Loading..."));
          setAlertStatus("loading");

          const result = await callApi<any>({
            query: postQueryKanbanTaskTemplateUploadFile(
              template.template_id,
              template.id,
              file
            ),
            auth: { setAuthedUser },
          });

          setAlertMessage(t("File uploaded successfully"));
          setAlertStatus("success");
          setUploadedFiles((prev) => [...prev, result]);

          await onFileUpload();
        } catch (error) {
          setFile(null);
          console.log("err: ", error);
          setAlertMessage(t("File has not been processed - incorrect file data"));
          setAlertStatus("error");
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file]);

  const handleDownloadFile = async (link: string) => {
    try {
      if (!link) {
        return;
      }
      const downloadLink = document.createElement("a");
      downloadLink.href = link;

      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    } catch (err) {
      console.log("err: ", err);
      setAlertMessage(t("Something went wrong"));
      setAlertStatus("error");
    }
  };

  const handleOpenFile = (link: string) => {
    window.open(link, "_blank");
  };

  const handleConfirmDelete = async () => {
    try {
      if (!deleteFileID) {
        return;
      }
      setAlertMessage(t("Loading..."));
      setAlertStatus("loading");
      await callApi({
        query: deleteQueryKanbanTaskTemplateFile(deleteFileID),
        auth: { setAuthedUser },
      });
      setUploadedFiles((prev) => prev.filter((item) => item.id !== deleteFileID));
      setDeleteFileID(null);
      setAlertMessage(null);
      setAlertStatus(null);
      await onFileUpload();
    } catch (err) {
      console.log("err ", err);
      setAlertMessage(t("Something went wrong"));
      setAlertStatus("error");
    }
  };

  return (
    <Box component="div">
      <Typography css={styles.contentBreak} variant="body1">
        {t("Uploaded files are available for each task derived from this template")}
      </Typography>

      {uploadedFiles.length ? (
        <Stack css={[styles.sectionBreak, styles.leftRightPadding1]} spacing={3}>
          {uploadedFiles.map((item) => (
            <Card key={item.id}>
              <Box component="div" css={styles.fullPadding1}>
                <Stack direction={"row"} spacing={3} alignItems="center">
                  <Typography style={{ flex: 1 }} variant="body1">
                    {item.file_name}
                  </Typography>
                  <Stack spacing={2} direction="row" alignItems="center">
                    <IconButton
                      aria-label="open file"
                      onClick={() => handleOpenFile(item.url)}
                      color="primary"
                    >
                      <VisibilityIcon />
                    </IconButton>
                    <IconButton
                      aria-label="download file"
                      onClick={() => handleDownloadFile(item.url)}
                    >
                      <DownloadIcon color="secondary" />
                    </IconButton>

                    <IconButton
                      aria-label="delete file"
                      onClick={() => setDeleteFileID(item.id)}
                    >
                      <DeleteOutlineOutlinedIcon color="error" />
                    </IconButton>
                  </Stack>
                </Stack>
              </Box>
            </Card>
          ))}
        </Stack>
      ) : null}
      <Stack
        css={styles.sectionBreak}
        spacing={2}
        alignItems="center"
        justifyContent="center"
      >
        <FileDropzone
          setFiles={(files) => setFile(files[0])}
          setErrorMessage={setAlertMessage}
        >
          <Button
            disabled={alertStatus === "loading"}
            color="secondary"
            loading={alertStatus === "loading"}
          >
            {t("Upload File")}
          </Button>
        </FileDropzone>
      </Stack>

      <Stack spacing={2} alignItems="center" justifyContent="center">
        <Alert
          css={styles.reverseContentBreak}
          message={alertMessage || ""}
          showAlert={!!alertMessage}
          severity={alertStatus}
        />

        {alertStatus === "success" ? (
          <Button onClick={handleCloseModal}>{t("Complete")}</Button>
        ) : null}
      </Stack>

      <Modal
        open={!!deleteFileID}
        onClose={() => setDeleteFileID(null)}
        fullWidth
        label={t("Confirm File Deletion")}
      >
        <Stack spacing={3} alignItems="center" justifyContent="center">
          <Typography variant="body1">
            {t("Selected file will be lost for all new tasks")}
          </Typography>

          <Button
            disabled={alertStatus === "loading"}
            color="error"
            onClick={handleConfirmDelete}
          >
            {t("Confirm Delete File")}
          </Button>

          <Alert
            message={alertMessage || ""}
            showAlert={!!alertMessage}
            severity={alertStatus}
          />
        </Stack>
      </Modal>
    </Box>
  );
};

export default KanTaskTemplateFiles;
