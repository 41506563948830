import { Box, Stack } from "@mui/material";
import LabelWithBoldedPart from "../../../MaterialUI/LabelWithBoldedPart";
import { useLanguageContext } from "../../../../context/LanguageContext";
import { formatNumber } from "../../../../Global/Utils/commonFunctions";
import { Invoice } from "./InvoiceTypes";

interface InvoiceViewInfoProps {
  invoice: Invoice | null;
}

const InvoiceViewInfo: React.FC<InvoiceViewInfoProps> = ({ invoice }) => {
  const { t } = useLanguageContext();
  if (!invoice) {
    return null;
  }

  return (
    <Box component="div">
      <Stack spacing={1} mb={2}>
      {/* 
      vat_amount: number;
      actions?: React.ReactNode; 
      */}
        <LabelWithBoldedPart text={t("Currency")} bolded={invoice.currency} />
        <LabelWithBoldedPart text={t("Customer Name")} bolded={invoice.customer_name} />
        <LabelWithBoldedPart text={t("Customer VAT Number")} bolded={invoice.customer_vat_number} />
        <LabelWithBoldedPart text={t("Date")} bolded={invoice.date} />
        <LabelWithBoldedPart
          text={t("Discount")}
          bolded={`${formatNumber(invoice.discount, true)}`}
        />
        <LabelWithBoldedPart text={t("Due Date")} bolded={invoice.due_date} />
        <LabelWithBoldedPart text={t("Invoice Number")} bolded={invoice.invoice_number} />
        <LabelWithBoldedPart
          text={t("NET Total")}
          bolded={`${formatNumber(invoice.net_total, true)}`}
        />
        <LabelWithBoldedPart text={t("Protocol ID")} bolded={invoice.protocol_id} />
        <LabelWithBoldedPart text={t("Purchase Order ID")} bolded={invoice.purchase_order_id} />
        <LabelWithBoldedPart text={t("Quantity")} bolded={invoice.quantity} />
        <LabelWithBoldedPart text={t("Sales Order ID")} bolded={invoice.sales_order_id} />
        <LabelWithBoldedPart text={t("Status")} bolded={invoice.status} />
        <LabelWithBoldedPart text={t("Supplier VAT Number")} bolded={invoice.supplier_vat_number} />
        <LabelWithBoldedPart
          text={t("Total Excise Amount")}
          bolded={`${formatNumber(invoice.total_excise_amount, true)}`}
        />
        <LabelWithBoldedPart
          text={t("Unit Price")}
          bolded={`${formatNumber(invoice.unit_price, true)}`}
        />
        <LabelWithBoldedPart
          text={t("VAT Amount")}
          bolded={`${formatNumber(invoice.vat_amount, true)}`}
        />
      </Stack>
    </Box>
  );
};

export default InvoiceViewInfo;
