import { useLanguageContext } from "../../../context/LanguageContext";
import { StockRow } from "../MyWarehouses/stocksUtils";
import { useState } from "react";
import { FormStatuses } from "../../../Global/Types/commonTypes";
import { useAuthedContext } from "../../../context/AuthContext";
import callApi from "../../../Api/callApi";
import { putQueryStocks } from "../../../Api/SupplyChain/apiSupplyChainPostQueries";
import { PutQueryStocksInput } from "../../../Api/SupplyChain/apiSupplyChainInputs";
import { useDetectFormsUnsavedChanges } from "../../../Global/Hooks/useDetectFormsUnsavedChanges";
import { Formik } from "formik";
import { object } from "yup";
import {
  YUP_NUMBER_REQUIRED_NUMBER,
  YUP_STRING,
} from "../../../Global/Constants/yupConstants";
import { Grid, Stack, useTheme } from "@mui/material";
import TextField from "../../MaterialUI/FormFields/TextFields";
import Button from "../../MaterialUI/Button";
import Alert from "../../MaterialUI/Alert";
import cssLayoutStyles from "../../../Global/Styles/layout";
import cssSpacingStyles from "../../../Global/Styles/spacing";
import cssComponentsStyles from "../../../Global/Styles/components";

const fieldValidation = object({
  material_code: YUP_STRING,
  instance_code: YUP_STRING,
  name: YUP_STRING,
  quantity: YUP_NUMBER_REQUIRED_NUMBER,
  location: YUP_STRING,
  status: YUP_STRING,
});

interface StockEditFormProps {
  stock: StockRow | null;
  onSubmit: () => Promise<void>;
  setOpenInfo: React.Dispatch<React.SetStateAction<boolean>>;
  handleSetUnsavedChanges: (unsavedChanges: boolean) => void;
  setUnsavedChanges: React.Dispatch<React.SetStateAction<boolean>>;
}

type FormValues = {
  material_code: string;
  instance_code: string;
  name: string;
  quantity: number | string;
  location: string;
  status: string;
};

const StockEditForm: React.FC<StockEditFormProps> = ({
  stock,
  onSubmit,
  setOpenInfo,
  handleSetUnsavedChanges,
  setUnsavedChanges,
}) => {
  const { t } = useLanguageContext();
  const theme = useTheme();
  const styles = {
    ...cssLayoutStyles,
    ...cssSpacingStyles(theme),
    ...cssComponentsStyles(theme),
  };
  const [formStatus, setFormStatus] = useState<FormStatuses>(null);
  const [alertMessage, setAlertMessage] = useState<string | null>(null);
  const { setAuthedUser } = useAuthedContext();

  const initialValues: FormValues = {
    material_code: stock?.material_code || "",
    instance_code: stock?.instance_code || "",
    name: stock?.name || "",
    quantity: stock?.quantity || "",
    location: stock?.location || "",
    status: stock?.status || "",
  };

  const handleFormSubmit = async (values: FormValues) => {
    try {
      setFormStatus("loading");
      setAlertMessage(null);

      await handleEdit(values, stock?.instance_id || "");
      setOpenInfo(false);
      await onSubmit();
      setFormStatus("success");
      setUnsavedChanges(false);
      setAlertMessage(t("Success!"));
    } catch (error) {
      console.log("Edit Stock error", error);
      setFormStatus("error");
      setAlertMessage(t("Something went wrong"));
    }
  };

  const handleEdit = async (values: FormValues, instanceId: string) => {
    const input: PutQueryStocksInput = {
      quantity: Number(values.quantity),
    };
    await callApi<string>({
      query: putQueryStocks(instanceId, input),
      auth: { setAuthedUser },
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleFormSubmit}
      validationSchema={fieldValidation}
    >
      {({ handleSubmit, handleChange, values }) => {
        useDetectFormsUnsavedChanges(initialValues, values, handleSetUnsavedChanges);

        return (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  name="material_code"
                  label={t("Material Code")}
                  onChange={handleChange}
                  value={values.material_code}
                  disabled={true}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="instance_code"
                  label={t("Instance Code")}
                  onChange={handleChange}
                  value={values.instance_code}
                  disabled={true}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="name"
                  label={t("Name")}
                  onChange={handleChange}
                  value={values.name}
                  disabled={true}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="quantity"
                  label={t("Quantity")}
                  onChange={handleChange}
                  value={values.quantity}
                  disabled={formStatus === "loading"}
                  numberField={true}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="location"
                  label={t("Location")}
                  onChange={handleChange}
                  value={values.location}
                  disabled={true}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="status"
                  label={t("Status")}
                  onChange={handleChange}
                  value={values.status}
                  disabled={true}
                />
              </Grid>
            </Grid>

            <Stack spacing={1} justifyContent={"center"} alignItems={"center"} mt={2}>
              <Button
                type="submit"
                onClick={() => handleFormSubmit(values)}
                loading={formStatus === "loading"}
                css={[styles.width100, styles.widthLimit15]}
              >
                {t("Submit")}
              </Button>

              <Alert
                message={alertMessage || ""}
                showAlert={!!alertMessage}
                severity={formStatus}
                css={styles.reverseLabelBreak}
              />
            </Stack>
          </form>
        );
      }}
    </Formik>
  );
};

export default StockEditForm;
