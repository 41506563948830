import { Box, IconButton, Stack, useTheme } from "@mui/material";
import { ColorPicker, useColor } from "react-color-palette";
import "react-color-palette/css";
import Modal from "../../MaterialUI/Modal";
import { useEffect, useState } from "react";
import { useLanguageContext } from "../../../context/LanguageContext";
import CircleIcon from "@mui/icons-material/Circle";
import Button from "../../MaterialUI/Button";

interface ColorSelectorProps {
  colorString?: string;
  onChangeColor: (color: string) => void;
}

const ColorSelector: React.FC<ColorSelectorProps> = ({ colorString, onChangeColor }) => {
  const theme = useTheme();
  const [open, setOpen] = useState<boolean>(false);
  const [color, setColor] = useColor(colorString || theme.palette.common.white);
  const { t } = useLanguageContext();

  useEffect(() => {
    onChangeColor(color.hex);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [color]);

  return (
    <Box component="div">
      <IconButton onClick={() => setOpen(true)} size="small">
        <CircleIcon
          sx={{
            color: color.hex,
            border: `1px solid ${theme.palette.common.black}`,
            borderRadius: "100%",
          }}
        />
      </IconButton>

      <Modal
        open={open}
        onClose={() => setOpen(false)}
        fullWidth
        label={t("Select Color")}
      >
        <ColorPicker color={color} onChange={setColor} />;
        <Stack spacing={2} justifyContent="center" alignItems="center">
          <Button onClick={() => setOpen(false)}>{t("Confirm")}</Button>
        </Stack>
      </Modal>
    </Box>
  );
};

export default ColorSelector;
