import { Box, IconButton, Stack, Tooltip, Typography, useTheme } from "@mui/material";
import Button from "../../MaterialUI/Button";
import { useLanguageContext } from "../../../context/LanguageContext";
import {
  calcTableFooterAggregations,
  TableAggregationKey,
  TableFooterData,
  TableGridColumnSchema,
} from "./constructTableGrid";
import { useState } from "react";
import cssSpacingStyles from "../../../Global/Styles/spacing";
import Accordion from "../../MaterialUI/Accordion";
import Select from "../../MaterialUI/FormFields/Select";
import { SelectOption } from "../../../Global/Types/commonTypes";
import { handleGetSelectOption } from "../../../Global/Utils/commonFunctions";
import { tableAggregationOptions, TableGridColAlign } from "./tableGridUtils";
import cssLayoutStyles from "../../../Global/Styles/layout";
import ColorSelector from "../ColorSelector/ColorSelector";
import Checkbox from "../../MaterialUI/FormFields/Checkbox";
import TextField from "../../MaterialUI/FormFields/TextFields";
import RestoreIcon from "@mui/icons-material/Restore";
import { useAuthedContext } from "../../../context/AuthContext";
import callApi from "../../../Api/callApi";
import { deleteQueryUserPreference } from "../../../Api/UserPreferences/apiUserPreferencesPostQueries";

const alignTextOptions: SelectOption<TableGridColAlign>[] = handleGetSelectOption(
  ["left", "right", "center"],
  true
);

interface TableGridConfigModalProps {
  onSave: (newColSchema: TableGridColumnSchema[]) => Promise<void>;
  colSchema: TableGridColumnSchema[];
  rows: Record<string, any>[];
  tableID: string | undefined;
  handleClose: () => void;
}

const TableGridConfigModal: React.FC<TableGridConfigModalProps> = ({
  onSave,
  colSchema,
  rows,
  tableID,
  handleClose,
}) => {
  const theme = useTheme();
  const styles = { ...cssSpacingStyles(theme), ...cssLayoutStyles };
  const [colSchemaState, setColSchemaState] =
    useState<TableGridColumnSchema[]>(colSchema);
  const [resetLoading, setResetLoading] = useState<boolean>(false);

  console.log("colSchema ", colSchema);

  const { t } = useLanguageContext();
  const { setAuthedUser } = useAuthedContext();

  const handleChangeSimple = (valKey: string, newValIndex: number, val: any) => {
    setColSchemaState((prev) =>
      prev.map((col, index) => {
        if (index === newValIndex) {
          return {
            ...col,
            [valKey]: val,
          };
        }
        return col;
      })
    );
  };

  const handleSave = async () => {
    const formattedColSchema: TableGridColumnSchema[] = colSchemaState.map((col) => {
      if (col.type === "number" && col.aggregationFn?.length) {
        const aggregatedValues = handleGetAggregations(
          col.aggregationFn,
          rows,
          col.id,
          col.formatNumbDecimals
        );

        return {
          ...col,
          ...aggregatedValues,
        };
      }
      return col;
    });

    await onSave(formattedColSchema);
  };

  const handleReset = async () => {
    try {
      if (!tableID) {
        return;
      }
      setResetLoading(true);
      await callApi({
        query: deleteQueryUserPreference(tableID),
        auth: { setAuthedUser },
      });
    } catch (err) {
      console.log("handleReset err ", err);
    }
    setResetLoading(false);
    handleClose();
  };

  return (
    <Box component="div">
      <Stack css={styles.contentBreak} spacing={3}>
        {colSchemaState.map((col, index) => (
          <Accordion key={`${col?.id}-${index}`} label={col.label}>
            <Stack spacing={0.5}>
              <Stack spacing={3} direction="row" alignItems="center">
                <Typography variant="body1">Background Color:</Typography>
                <ColorSelector
                  colorString={col.colBgColor}
                  onChangeColor={(color) =>
                    handleChangeSimple("colBgColor", index, color)
                  }
                />
              </Stack>
              <Select
                css={styles.widthLimit10}
                label={"Align Text"}
                selectOptions={alignTextOptions}
                value={col.alignCol || ""}
                onChange={(e) => handleChangeSimple("alignCol", index, e.target.value)}
              />
              <Checkbox
                label="Disable Editing"
                checked={!!col.disableEditing}
                onChange={(e) =>
                  handleChangeSimple("disableEditing", index, e.target.checked)
                }
              />
              {col.type === "number" ? (
                <>
                  <Stack spacing={4} direction="row" alignItems="center">
                    <Checkbox
                      label={t("Format Number")}
                      defaultChecked={!!col.formatNumb}
                      onChange={(e) =>
                        handleChangeSimple("formatNumb", index, e.target.checked)
                      }
                      value={!!col.formatNumb}
                    />
                    {!!col.formatNumb ? (
                      <TextField
                        css={styles.widthLimit10}
                        label={t("Decimal Points")}
                        onChange={(e) =>
                          handleChangeSimple("formatNumbDecimals", index, e.target.value)
                        }
                        value={!col.formatNumb ? 0 : col.formatNumbDecimals}
                        numberField
                        noThousandSeparator
                      />
                    ) : null}
                  </Stack>
                  <Select
                    label={"Aggregations"}
                    selectOptions={tableAggregationOptions}
                    value={col.aggregationFn || []}
                    onChange={(e) =>
                      handleChangeSimple("aggregationFn", index, e.target.value)
                    }
                    multiple
                  />
                </>
              ) : null}
            </Stack>
          </Accordion>
        ))}
      </Stack>

      <Stack
        spacing={2}
        justifyContent="space-between"
        alignItems="center"
        direction="row"
      >
        <div />
        <Button onClick={handleSave} disabled={resetLoading}>
          {t("Save Changes")}
        </Button>
        {tableID ? (
          <Tooltip title="Reset Configuration">
            <IconButton onClick={handleReset} disabled={resetLoading} color="error">
              <RestoreIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <div />
        )}
      </Stack>
    </Box>
  );
};

export default TableGridConfigModal;

type Aggregations = {
  aggregationFn?: Array<TableAggregationKey>;
  footerAggregations?: TableFooterData;
};

const handleGetAggregations = (
  operations: TableAggregationKey[],
  rows: Record<string, any>[],
  colID: string,
  decimalPoints?: number
): Aggregations => {
  if (!operations.length) {
    return {
      footerAggregations: undefined,
      aggregationFn: undefined,
    };
  }

  return {
    footerAggregations: calcTableFooterAggregations(
      operations,
      rows.map((item) => item[colID]),
      decimalPoints
    ),
    aggregationFn: operations,
  };
};
