import { Grid, useTheme } from "@mui/material";
import QuillEditor from "../../SmallComponents/QuillEditor/QuillEditor";
import cssLayoutStyles from "../../../Global/Styles/layout";
import cssSpacingStyles from "../../../Global/Styles/spacing";
import Button from "../../MaterialUI/Button";
import { HelpSelection, ModalHelpTitle } from "./helpUtils";
import { useDetectFormsUnsavedChanges } from "../../../Global/Hooks/useDetectFormsUnsavedChanges";
import { GetQueryHelpSelectionsForPage } from "../../../Api/Help/apiHelpSnippets";
import { useLanguageContext } from "../../../context/LanguageContext";
import { v4 as uuidv4 } from "uuid";

interface AddHelpContentFormProps {
  order: number;
  setModalTitle: React.Dispatch<React.SetStateAction<ModalHelpTitle | null>>;
  setOpenInfo?: React.Dispatch<React.SetStateAction<boolean>>;
  handleSetUnsavedChanges: (unsavedChanges: boolean) => void;
  editorContent: string;
  setEditorContent: React.Dispatch<React.SetStateAction<string>>;
  helpSelection?: HelpSelection;
  setUnsavedEditChanges: React.Dispatch<React.SetStateAction<boolean>>;
  setTempSelections: React.Dispatch<React.SetStateAction<GetQueryHelpSelectionsForPage>>;
  setDraftSelections: React.Dispatch<React.SetStateAction<GetQueryHelpSelectionsForPage>>;
  isExistingSelection?: boolean;
}

const AddHelpContentForm: React.FC<AddHelpContentFormProps> = ({
  order,
  setModalTitle,
  setOpenInfo,
  handleSetUnsavedChanges,
  editorContent,
  setEditorContent,
  helpSelection,
  setUnsavedEditChanges,
  setTempSelections,
  setDraftSelections,
  isExistingSelection,
}) => {
  const { t } = useLanguageContext();
  const theme = useTheme();
  const styles = {
    ...cssLayoutStyles,
    ...cssSpacingStyles(theme),
  };

  useDetectFormsUnsavedChanges(
    helpSelection ? helpSelection.content : "",
    editorContent,
    handleSetUnsavedChanges
  );

  const handleSave = () => {
    const newSelection: HelpSelection = {
      order: helpSelection ? helpSelection.order : order,
      content: editorContent,
      type: "text",
      id: helpSelection ? helpSelection.id : uuidv4(),
    };

    if (helpSelection) {
      if (isExistingSelection) {
        setDraftSelections((prev) =>
          prev.map((sel) => (sel.id === helpSelection.id ? newSelection : sel))
        );
      } else {
        setTempSelections((prev) =>
          prev.map((sel) => (sel.id === helpSelection.id ? newSelection : sel))
        );
      }
    } else {
      setTempSelections((prev) => [...prev, newSelection]);
    }

    setEditorContent("");
    setUnsavedEditChanges(true);
    setModalTitle(null);
    if (setOpenInfo) {
      setOpenInfo(false);
    }
  };

  return (
    <Grid container gap={2} justifyContent="space-between">
      <Grid item xs={12}>
        <QuillEditor
          label={t("Add your text here")}
          css={[styles.width100, styles.labelBreak]}
          editorState={editorContent}
          onEditorStateChange={(state: string) => {
            setEditorContent(state);
          }}
        />
      </Grid>

      <Grid item xs={12} mt={2} container justifyContent="center">
        <Button css={[styles.widthLimit20]} onClick={handleSave}>
          {t("Save Changes")}
        </Button>
      </Grid>
    </Grid>
  );
};

export default AddHelpContentForm;
