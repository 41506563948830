import { Stack, InputAdornment, Box } from "@mui/material";
import Alert from "../../../MaterialUI/Alert";
import Button from "../../../MaterialUI/Button";
import Select from "../../../MaterialUI/FormFields/Select";
import TextField from "../../../MaterialUI/FormFields/TextFields";
import CameraOrDeviceUpload from "./CameraOrDeviceUpload";
import { PostQueryLabelRecognitionTestImageInput } from "../../../../Api/VisionControl/apiVisionControlInputs";
import { postQueryLabelRecognitionTestImage } from "../../../../Api/VisionControl/apiVisionControlPostQueries";
import { PostQueryLabelRecognitionTestImageSnippet } from "../../../../Api/VisionControl/apiVisionControlSnippets";
import callApi from "../../../../Api/callApi";
import { useEffect, useState } from "react";
import { handleGetSelectOption } from "../../../../Global/Utils/commonFunctions";
import { useAuthedContext } from "../../../../context/AuthContext";
import { FormStatuses } from "../../../../Global/Types/commonTypes";
import { FileWithPath } from "react-dropzone";
import cssLayoutStyles from "../../../../Global/Styles/layout";
import { AllLabelImages, LabelRecognitionTestedModalData } from "./labelRecognitionUtils";
import Collapse from "../../../MaterialUI/Collapse";
import { useLanguageContext } from "../../../../context/LanguageContext";

interface UploadSampleLabelProps {
  allLabelImages: AllLabelImages | null;
  fetchAllLabels: (type: "all" | "master" | "tested") => Promise<void>;
  setTestedModalData: React.Dispatch<
    React.SetStateAction<LabelRecognitionTestedModalData | null>
  >;
  setNewImageModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const UploadSampleLabel: React.FC<UploadSampleLabelProps> = ({
  allLabelImages,
  fetchAllLabels,
  setTestedModalData,
  setNewImageModal,
}) => {
  const { t } = useLanguageContext();
  const styles = {
    ...cssLayoutStyles,
  };
  const [masterLabelName, setMasterLabelName] = useState<string>("");
  const [testFormStatus, setTestFormStatus] = useState<FormStatuses>(null);
  const [testAlert, setTestAlert] = useState<string | null>(null);
  const [testFile, setTestFile] = useState<FileWithPath | null>(null);
  const [sampleLabelFilename, setSampleLabelFilename] = useState<string>("");

  const { setAuthedUser } = useAuthedContext();
  const masterLabelSelectOptions = handleGetSelectOption(
    (allLabelImages?.master || []).map((item) => item.image_name)
  );

  /**
   * When file is uploaded, if filename input field
   * for this file is empty, autofill it
   */
  useEffect(() => {
    if (testFile?.name) {
      if (!sampleLabelFilename) {
        const nameArr = testFile.name.split(".");
        const name = nameArr.slice(0, nameArr.length - 1).join(".");
        setSampleLabelFilename(name);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [testFile]);

  const handleUploadTestLabel = async () => {
    try {
      if (!masterLabelName) {
        setTestFormStatus("warning");
        setTestAlert(t("You must select an original label"));
        return;
      }
      if (!testFile) {
        setTestFormStatus("warning");
        setTestAlert(t("You must upload a sample label"));
        return;
      }
      if (!sampleLabelFilename) {
        setTestFormStatus("warning");
        setTestAlert(t("You must specify an image name"));
        return;
      }
      setTestFormStatus("loading");
      setTestAlert(t("Loading..."));

      const input: PostQueryLabelRecognitionTestImageInput = {
        master_label_name: masterLabelName,
        test_image_file: testFile,
        file_name: sampleLabelFilename,
      };
      const testedLabel = await callApi<PostQueryLabelRecognitionTestImageSnippet>({
        query: postQueryLabelRecognitionTestImage(input),
        auth: { setAuthedUser },
      });

      if (!testedLabel?.url) {
        throw new Error(JSON.stringify(testedLabel));
      }
      await fetchAllLabels("tested");

      const masterUrl = allLabelImages?.master.find(
        (item) => item.image_name === masterLabelName
      )?.url;

      setNewImageModal(false);
      setTestedModalData({
        masterLabelName: masterLabelName,
        masterLabelUrl: masterUrl || "",
        testedLabelName: testFile.name,
        testedLabelUrl: testedLabel.url,
        confidence: testedLabel.confidence,
      });
      setTestFormStatus("success");
      setTestAlert(t("Success"));
      setTestFile(null);
      setSampleLabelFilename("");
      setMasterLabelName("");
    } catch (err) {
      setTestFormStatus("error");
      setTestAlert(t("Something went wrong"));
      console.log("handleUploadTestLabel err", err);
    }
  };

  return (
    <>
      <Stack spacing={3}>
        <Select
          css={styles.width100}
          selectOptions={masterLabelSelectOptions}
          label={t("Original label file name")}
          value={masterLabelName}
          onChange={(e) => setMasterLabelName(e.target.value)}
        />

        <Collapse in={!!testFile}>
          <TextField
            css={styles.width100}
            value={sampleLabelFilename}
            label={t("Friendly image name")}
            onChange={(e) => setSampleLabelFilename(e.target.value)}
            InputProps={
              testFile
                ? {
                    endAdornment: (
                      <InputAdornment position="end">
                        {`.${testFile.type.split("/")[1]}`}
                      </InputAdornment>
                    ),
                  }
                : undefined
            }
          />
        </Collapse>

        <CameraOrDeviceUpload file={testFile} setFile={setTestFile} />

        <Alert message={testAlert} showAlert={!!testAlert} severity={testFormStatus} />
        <Box component="div" css={styles.flexCenter}>
          <Button
            css={[styles.widthLimit15, styles.width100]}
            onClick={handleUploadTestLabel}
          >
            {t("Get Result")}
          </Button>
        </Box>
      </Stack>
    </>
  );
};

export default UploadSampleLabel;
