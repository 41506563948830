import {
  Box,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import TextField from "../../../../MaterialUI/FormFields/TextFields";
import useTheme from "@mui/material/styles/useTheme";
import cssLayoutStyles from "../../../../../Global/Styles/layout";
import cssSpacingStyles from "../../../../../Global/Styles/spacing";
import { useCallback, useEffect, useState } from "react";
import debounce from "lodash.debounce";
import { UaConnectionParametersData, UaSecurity } from "./uaConnectionTypes";
import Button from "../../../../MaterialUI/Button";
import Modal from "../../../../MaterialUI/Modal";
import RadioGroup from "../../../../MaterialUI/FormFields/RadioGroup";
import Collapse from "../../../../MaterialUI/Collapse";
import Select from "../../../../MaterialUI/FormFields/Select";
import { handleGetSelectOption } from "../../../../../Global/Utils/commonFunctions";
import Alert from "../../../../MaterialUI/Alert";
import { css } from "@emotion/react";
import Checkbox from "../../../../MaterialUI/FormFields/Checkbox";
import { CONNECTION_BULK_UPLOAD_INFO_TEXT } from "../createEdiConnectionUtils";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import cssComponentsStyles from "../../../../../Global/Styles/components";
import {
  useTranslatedModalTitle,
  useTranslateSchemeArray,
} from "../../../../../Global/Hooks/useTranslations";
import { useLanguageContext } from "../../../../../context/LanguageContext";

const cssStyles = () => ({
  securityText: css({
    display: "flex",
    alignItems: "flex-end",
  }),
  securityButton: css({
    paddingTop: "20px",
    display: "flex",
    alignItems: "flex-end",
  }),
});

const SECURITY_MODES = ["None", "Sign", "Sign & Encrypt"];
const SECURITY_POLICIES = [
  "Basic128Esa15 ",
  "Basic256 ",
  "Basic256Sha256",
  "Aes128Sha256RsaOaep",
  "Aes256Sha256RsaPss",
];
const USER_AUTHENTICATION_OPTIONS = [
  "None",
  "Username and Password",
  "User Certificate",
  "Single Sign on Token",
];

const INITIAL_SECURITY: UaSecurity = {
  mode: "",
  policy: "",
  userAuth: "",
  usernamePass: null,
};

const modalTitleTranslations = {
  "Configure Security": "Configure Security",
  "File Upload": "File Upload",
} as const;

type ModalType = keyof typeof modalTitleTranslations;

interface UaConnectionParametersProps {
  data: UaConnectionParametersData;
  handleUpdateData: (data: UaConnectionParametersData) => void;
  handleUpdateSecurity: (data: UaSecurity) => void;
  isLoading: boolean;
  security: UaSecurity | null;
  isFileUpload: boolean;
  setIsFileUpload: React.Dispatch<React.SetStateAction<boolean>>;
  isEdit: boolean;
}

const UaConnectionParameters: React.FC<UaConnectionParametersProps> = ({
  data,
  handleUpdateData,
  isLoading,
  security,
  handleUpdateSecurity,
  isFileUpload,
  setIsFileUpload,
  isEdit,
}) => {
  const { t } = useLanguageContext();
  const theme = useTheme();
  const xsMediaQuery = useMediaQuery("(max-width:599px)");

  const styles = {
    ...cssSpacingStyles(theme),
    ...cssLayoutStyles,
    ...cssStyles(),
    ...cssComponentsStyles(theme),
  };
  const [stateData, setStateData] = useState<UaConnectionParametersData>(data);
  const [stateSecurity, setStateSecurity] = useState<UaSecurity>(
    security || INITIAL_SECURITY
  );
  const [modalType, setModalType] = useState<ModalType | null>(null);
  const getTranslatedModalTitle = useTranslatedModalTitle(modalTitleTranslations);

  useEffect(() => {
    if (JSON.stringify(data) !== JSON.stringify(stateData)) {
      setStateData(() => data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (JSON.stringify(data) !== JSON.stringify(stateData)) {
      debounceUpdateData(stateData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateData]);

  useEffect(() => {
    if (security && JSON.stringify(security) !== JSON.stringify(stateSecurity)) {
      setStateSecurity(() => security);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [security]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceUpdateData = useCallback(debounce(handleUpdateData, 500), []);

  const handleChangeStateData = (key: string, value: string) => {
    setStateData((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const handleCloseModal = () => {
    setStateSecurity(() => security || INITIAL_SECURITY);
    setModalType(null);
  };

  const handleSaveSecurity = () => {
    handleUpdateSecurity(stateSecurity);
    setModalType(null);
  };

  const handleChangeStateSecurity = (key: string, value: string) => {
    if (key === "username" || key === "password") {
      setStateSecurity((prev) => {
        const usernamePass = prev?.usernamePass || {
          username: "",
          password: "",
        };
        return {
          ...prev,
          usernamePass: {
            ...usernamePass,
            [key]: [value],
          },
        };
      });
    } else {
      setStateSecurity((prev) => ({
        ...prev,
        [key]: value,
      }));
    }
  };

  return (
    <Box component="div" p={3}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={5.5}>
          <Stack css={[styles.width100, styles.labelBreak]} spacing={2}>
            <TextField
              label={t("Server URL")}
              placeholder="OPC UA server URL"
              value={stateData.server}
              onChange={(e) => handleChangeStateData("server", e.target.value)}
              fullWidth
              disabled={isEdit || isLoading}
            />
            <Stack direction="row" spacing={4}>
              <TextField
                css={styles.widthLimit10}
                label={t("Update Rate")}
                value={stateData.updateRate}
                onChange={(e) => handleChangeStateData("updateRate", e.target.value)}
                fullWidth
                disabled={isLoading}
                InputProps={{
                  endAdornment: <InputAdornment position="start">ms</InputAdornment>,
                }}
                numberField
              />
              <TextField
                label={t("Connection Name")}
                value={stateData.connectionName}
                onChange={(e) => handleChangeStateData("connectionName", e.target.value)}
                fullWidth
                disabled={isLoading}
              />
            </Stack>

            <Stack direction="row" spacing={2} alignItems="center">
              <IconButton size="small" onClick={() => setModalType("File Upload")}>
                <InfoOutlinedIcon css={styles.infoIcon} />
              </IconButton>
              <Checkbox
                label={t("Use File Upload")}
                checked={isFileUpload}
                onChange={(e) => setIsFileUpload(e.target.checked)}
              />
            </Stack>
          </Stack>
        </Grid>

        <Grid item xs={12} sm={1}>
          <Divider style={{ margin: "auto", width: "1px" }} orientation="vertical" />
        </Grid>

        <Grid item xs={12} sm={5.5}>
          <Stack css={styles.height100} spacing={1.5}>
            <Box component="div" css={styles.securityText}>
              <Typography variant="body1">
                {t("Security Mode")}:{" "}
                <Typography variant="body1" component="span" fontWeight={600}>
                  {security?.mode || "None"}
                </Typography>
              </Typography>
            </Box>

            <Box component="div" css={styles.securityText}>
              <Typography css={styles.securityText} variant="body1">
                {t("Security Policy")}:{" "}
                <Typography variant="body1" component="span" fontWeight={600}>
                  {security?.policy || "N/A"}
                </Typography>
              </Typography>
            </Box>

            <Box component="div" css={styles.securityText}>
              <Typography css={styles.securityText} variant="body1">
                {t("User Authentication Method")}:{" "}
                <Typography variant="body1" component="span" fontWeight={600}>
                  {security?.userAuth || "None"}
                </Typography>
              </Typography>
            </Box>

            <Box component="div" css={styles.securityButton}>
              <Button
                onClick={() => setModalType("Configure Security")}
                variant="outlined"
                color="secondary"
              >
                {t("Configure Security")}
              </Button>
            </Box>
          </Stack>
        </Grid>
      </Grid>

      <Modal
        open={!!modalType}
        onClose={handleCloseModal}
        fullWidth
        maxWidth={modalType === "Configure Security" ? "md" : "sm"}
        label={modalType ? getTranslatedModalTitle(modalType) : ""}
      >
        {modalType === "File Upload" ? (
          <Box component="div" css={styles.fullPadding1}>
            <Typography>{t(CONNECTION_BULK_UPLOAD_INFO_TEXT)}</Typography>
          </Box>
        ) : null}

        {modalType === "Configure Security" ? (
          <>
            <Grid css={styles.contentBreak} container spacing={3}>
              <Grid item xs={12} sm={6}>
                <Stack
                  css={xsMediaQuery ? styles.width100 : styles.height100}
                  spacing={2}
                  direction={{ xs: "column", sm: "row" }}
                >
                  <Stack css={styles.width100}>
                    <RadioGroup
                      css={styles.contentBreak}
                      value={stateSecurity.mode}
                      onChange={(e) => handleChangeStateSecurity("mode", e.target.value)}
                      radioOptions={useTranslateSchemeArray(
                        handleGetSelectOption(SECURITY_MODES)
                      )}
                      label={t("Security Mode")}
                    />

                    <Box component="div" css={styles.marginTopAuto}>
                      <Collapse
                        css={[styles.width100, styles.widthLimit25]}
                        in={!!stateSecurity.mode && stateSecurity.mode !== "None"}
                      >
                        <Select
                          css={styles.contentBreak}
                          selectOptions={handleGetSelectOption(SECURITY_POLICIES)}
                          value={stateSecurity.policy}
                          label={t("Security Policy")}
                          onChange={(e) =>
                            handleChangeStateSecurity("policy", e.target.value)
                          }
                          fullWidth
                        />
                      </Collapse>
                    </Box>
                  </Stack>

                  <Divider
                    orientation={xsMediaQuery ? "horizontal" : "vertical"}
                    flexItem
                  />
                </Stack>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Stack spacing={2}>
                  <Select
                    value={stateSecurity.userAuth}
                    onChange={(e) =>
                      handleChangeStateSecurity("userAuth", e.target.value)
                    }
                    selectOptions={useTranslateSchemeArray(
                      handleGetSelectOption(USER_AUTHENTICATION_OPTIONS)
                    )}
                    label={t("User Authentication Method")}
                  />

                  {stateSecurity.userAuth === "Username and password" ? (
                    <Stack spacing={2}>
                      <TextField
                        value={stateSecurity.usernamePass?.username || ""}
                        label={t("Username")}
                        onChange={(e) =>
                          handleChangeStateSecurity("username", e.target.value)
                        }
                        type="text"
                      />

                      <TextField
                        value={stateSecurity.usernamePass?.password || ""}
                        label={t("Password")}
                        onChange={(e) =>
                          handleChangeStateSecurity("password", e.target.value)
                        }
                        type="text"
                      />
                    </Stack>
                  ) : null}

                  <Alert
                    css={styles.marginTopAuto}
                    severity={"warning"}
                    showAlert={
                      !!stateSecurity.userAuth &&
                      stateSecurity.userAuth !== "None" &&
                      stateSecurity.userAuth !== "Username and password"
                    }
                    message={t(
                      "Please contact your admin for this authentication method."
                    )}
                  />
                </Stack>
              </Grid>
            </Grid>

            <Button
              css={[styles.marginHorizontalAuto, styles.block]}
              variant="contained"
              onClick={handleSaveSecurity}
            >
              {t("Confirm")}
            </Button>
          </>
        ) : null}
      </Modal>
    </Box>
  );
};

export default UaConnectionParameters;
