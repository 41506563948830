import { Formik } from "formik";
import { useDetectFormsUnsavedChanges } from "../../../Global/Hooks/useDetectFormsUnsavedChanges";
import { object } from "yup";
import {
  YUP_NUMBER_REQUIRED_NUMBER,
  YUP_REQUIRED_STRING,
} from "../../../Global/Constants/yupConstants";
import {
  CreateObjectFormValues,
  ObjectTrackingModalTitle,
  ObjectTackingType,
  typeSelectOptions,
} from "./objectTrackingUtils";
import { Grid, Stack, useTheme } from "@mui/material";
import { useTranslateSchemeArray } from "../../../Global/Hooks/useTranslations";
import Select from "../../MaterialUI/FormFields/Select";
import { useEffect, useState } from "react";
import { FormStatuses, SelectOption } from "../../../Global/Types/commonTypes";
import { useLanguageContext } from "../../../context/LanguageContext";
import Button from "../../MaterialUI/Button";
import cssLayoutStyles from "../../../Global/Styles/layout";
import cssSpacingStyles from "../../../Global/Styles/spacing";
import cssComponentsStyles from "../../../Global/Styles/components";
import Alert from "../../MaterialUI/Alert";
import { GetQueryMaterialsAndProductsSnippet } from "../../../Api/SupplyChain/apiSupplyChainSnippets";
import callApi from "../../../Api/callApi";
import { useAuthedContext } from "../../../context/AuthContext";
import { getQueryMaterialsAndProducts } from "../../../Api/SupplyChain/apiSupplyChainGetQueries";
import {
  GetQueryLocationsSnippet,
  GetQueryLocationsTypesSnippets,
} from "../../../Api/Locations/apiLocationSnippets";
import {
  getQueryLocations,
  getQueryLocationsTypes,
} from "../../../Api/Locations/apiLocationsGetQueries";
import TextField from "../../MaterialUI/FormFields/TextFields";
import { handleGetSelectOption } from "../../../Global/Utils/commonFunctions";
import { PostQueryStockInput } from "../../../Api/SupplyChain/apiSupplyChainInputs";
import { postQueryStocks } from "../../../Api/SupplyChain/apiSupplyChainPostQueries";

const fieldValidation = object({
  type: YUP_REQUIRED_STRING,
  material_master: YUP_REQUIRED_STRING,
  locationType: YUP_REQUIRED_STRING,
  location: YUP_REQUIRED_STRING,
  quantity: YUP_NUMBER_REQUIRED_NUMBER,
});

const initialValues: CreateObjectFormValues = {
  type: "",
  material_master: "",
  locationType: "",
  location: "",
  quantity: null,
};

interface CreateObjectFormProps {
  handleSetUnsavedChanges: (unsavedChanges: boolean) => void;
  setUnsavedChanges: React.Dispatch<React.SetStateAction<boolean>>;
  setModalTitle: React.Dispatch<React.SetStateAction<ObjectTrackingModalTitle | null>>;
  scannedImageDataType: string;
  handleCancel: () => void;
  refreshTable: () => void;
}

const CreateObjectForm: React.FC<CreateObjectFormProps> = ({
  handleSetUnsavedChanges,
  setUnsavedChanges,
  setModalTitle,
  scannedImageDataType,
  handleCancel,
  refreshTable
}) => {
  const { t } = useLanguageContext();
  const theme = useTheme();
  const styles = {
    ...cssLayoutStyles,
    ...cssSpacingStyles(theme),
    ...cssComponentsStyles(theme),
  };
  const { setAuthedUser } = useAuthedContext();
  const [formStatus, setFormStatus] = useState<FormStatuses>(null);
  const [alertMessage, setAlertMessage] = useState<string | null>(null);
  const [materialMasterOptions, setMaterialMasterOptions] = useState<SelectOption[]>([]);
  const [locationTypeOptions, setLocationTypeOptions] = useState<SelectOption[]>([]);
  const [selectedLocationType, setSelectedLocationType] = useState<string | null>(null);
  const [locationOptions, setLocationOptions] = useState<SelectOption[]>([]);

  useEffect(() => {
    fetchLocationsTypesOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedLocationType) {
      fetchLocationsTypesLocationsOptions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLocationType]);

  const fetchMaterialMaster = async (type: ObjectTackingType) => {
    try {
      const data = await callApi<GetQueryMaterialsAndProductsSnippet>({
        query: getQueryMaterialsAndProducts(type),
        auth: { setAuthedUser },
      });

      setMaterialMasterOptions(
        data.rows.map((row) => {
          return {
            value: row.id,
            description: row.name,
          };
        })
      );
    } catch (error) {
      console.log("There was an error: ", error);
    }
  };

  const fetchLocationsTypesLocationsOptions = async () => {
    try {
      if (!selectedLocationType) return;

      const allLocations = await callApi<GetQueryLocationsSnippet>({
        query: getQueryLocations,
        auth: { setAuthedUser },
      });

      const options = allLocations
        .filter((location) => location.location_type === selectedLocationType)
        .map((option) => {
          return {
            value: option.id,
            description: option.location,
          };
        });

      setLocationOptions(options);
    } catch (err) {
      console.log("Failed to fetch locationsTypesParents", err.message);
    }
  };

  const fetchLocationsTypesOptions = async () => {
    try {
      const locationsTypes = await callApi<GetQueryLocationsTypesSnippets>({
        query: getQueryLocationsTypes,
        auth: { setAuthedUser },
      });

      const options = handleGetSelectOption(locationsTypes.data);
      setLocationTypeOptions(options);
    } catch (err) {
      console.log("Failed to fetch locationsTypes", err.message);
    }
  };

  const handleFormSubmit = async (values: CreateObjectFormValues) => {
    try {
      setFormStatus("loading");
      setAlertMessage(null);

      if (
        !values.type ||
        !values.material_master ||
        !values.locationType ||
        !values.location ||
        !values.quantity
      ) {
        setFormStatus("warning");
        setAlertMessage(t("All required fields must be filled out"));
        return;
      }

      const input: PostQueryStockInput = {
        material_master_id: values.material_master,
        location_id: values.location,
        quantity: values.quantity,
        instance_code: scannedImageDataType,
        status: "Available",
      };

      await callApi({
        query: postQueryStocks(input),
        auth: { setAuthedUser },
      });
      refreshTable();
      handleCancel();
      setFormStatus("success");
      setUnsavedChanges(false);
      setModalTitle(null);
    } catch (error) {
      console.error("CreateObjectForm handleFormSubmit error:", error);
      setFormStatus("error");
      setAlertMessage(t("Something went wrong"));
    }
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={handleFormSubmit}
        validationSchema={fieldValidation}
      >
        {({
          handleSubmit,
          handleChange,
          values,
          // setFieldValue,
          // setValues,
        }) => {
          useDetectFormsUnsavedChanges(initialValues, values, handleSetUnsavedChanges);

          return (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Select
                    selectOptions={useTranslateSchemeArray(typeSelectOptions)}
                    name="type"
                    label={t("Type")}
                    onChange={(e) => {
                      handleChange(e);
                      fetchMaterialMaster(e.target.value as ObjectTackingType);
                    }}
                    value={values.type}
                    disabled={formStatus === "loading"}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Select
                    selectOptions={materialMasterOptions}
                    name="material_master"
                    label={t("Material Master")}
                    onChange={handleChange}
                    value={values.material_master}
                    disabled={formStatus === "loading"}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Select
                    name="locationType"
                    selectOptions={locationTypeOptions}
                    label={t("Location Type")}
                    value={values.locationType}
                    onChange={(e) => {
                      setSelectedLocationType(e.target.value);
                      handleChange(e);
                    }}
                    disabled={formStatus === "loading"}
                  />
                </Grid>

                {values.locationType ? (
                  <Grid item xs={12}>
                    <Select
                      name="location"
                      selectOptions={locationOptions}
                      label={t("Location")}
                      value={values.location}
                      onChange={handleChange}
                      disabled={formStatus === "loading"}
                    />
                  </Grid>
                ) : null}

                <Grid item xs={12}>
                  <TextField
                    name="quantity"
                    label={t("Quantity")}
                    onChange={handleChange}
                    value={values.quantity}
                    disabled={formStatus === "loading"}
                    numberField={true}
                  />
                </Grid>
              </Grid>

              <Stack spacing={1} justifyContent={"center"} alignItems={"center"} mt={2}>
                <Button
                  type="submit"
                  onClick={() => handleFormSubmit(values)}
                  loading={formStatus === "loading"}
                  css={[styles.width100, styles.widthLimit15]}
                >
                  {t("Submit")}
                </Button>

                <Alert
                  message={alertMessage || ""}
                  showAlert={!!alertMessage}
                  severity={formStatus}
                  css={styles.reverseLabelBreak}
                />
              </Stack>
            </form>
          );
        }}
      </Formik>
    </>
  );
};

export default CreateObjectForm;
