import { Box, Typography, useTheme, Stack } from "@mui/material";
import { PredictiveMeintenanceStatisticsType } from "./predictiveMeintenanceUtils";
import AvTimerOutlinedIcon from "@mui/icons-material/AvTimerOutlined";
import ThumbUpAltRoundedIcon from "@mui/icons-material/ThumbUpAltRounded";
import { useTranslateFields } from "../../../../Global/Hooks/useTranslations";

interface PredictiveMeintenanceStatisticsCardProps {
  statiscticsData: PredictiveMeintenanceStatisticsType | null;
}

const PredictiveMeintenanceStatisticsCards: React.FC<
  PredictiveMeintenanceStatisticsCardProps
> = ({ statiscticsData }) => {
  const theme = useTheme();

  if (!statiscticsData) {
    return null;
  }

  const statisticsArray = [
    { label: "Days Left (Average)", value: statiscticsData.averageDaysLeft },
    { label: "Days Left (Worst)", value: statiscticsData.worstDaysLeft },
    { label: "Days Left (Best)", value: statiscticsData.bestDaysLeft },
    { label: "Model Prediction Quality", value: statiscticsData.modelPredictionQuality },
  ];

  return (
    <Box display="flex" justifyContent="space-between" width="100%" component="div">
      {useTranslateFields(statisticsArray, ["label"]).map((stat, index) => (
        <Box
          key={index}
          flex={1}
          padding={2}
          sx={{
            backgroundColor: theme.palette.primary.main,
            color: "white",
            borderRadius: 1,
          }}
          marginX={1}
          component="div"
        >
          <Stack direction="row" alignItems="center" spacing={2}>
            {stat.label === "Model Prediction Quality" ? (
              <ThumbUpAltRoundedIcon sx={{ fontSize: 32 }} />
            ) : (
              <AvTimerOutlinedIcon sx={{ fontSize: 32 }} />
            )}

            <Box textAlign="left" component="div">
              <Typography variant="h4">{stat.value}</Typography>
              <Typography variant="body2">{stat.label}</Typography>
            </Box>
          </Stack>
        </Box>
      ))}
    </Box>
  );
};

export default PredictiveMeintenanceStatisticsCards;
