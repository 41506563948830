import { Box, Stack, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { Node } from "reactflow";
import cssSpacingStyles from "../../../../../../Global/Styles/spacing";
import cssLayoutStyles from "../../../../../../Global/Styles/layout";
import Button from "../../../../../../Components/MaterialUI/Button";
import Modal from "../../../../../../Components/MaterialUI/Modal";
import { PID_FLOW_NODE_TYPE } from "../pidNodesUtils";
import { PidWorkflowsBackgroundNodeData } from "./pidBackgroundNodeUtils";
import FileDropzone from "../../../../../../Components/SmallComponents/Dropzone/FileDropzone";
import { FileWithPath } from "react-dropzone";
import { FormStatuses } from "../../../../../../Global/Types/commonTypes";
import Alert from "../../../../../../Components/MaterialUI/Alert";
import callApi from "../../../../../../Api/callApi";
import { useAuthedContext } from "../../../../../../context/AuthContext";
import { postQueryPidWorkflowUploadImage } from "../../../../../../Api/PidWorkflows/apiPidWorkflowsPostQueries";
import { PostQueryPidWorkflowUploadImageSnippet } from "../../../../../../Api/PidWorkflows/apiPidWorkflowsSnippets";
import { useLanguageContext } from "../../../../../../context/LanguageContext";
import { useTranslatedModalTitle } from "../../../../../../Global/Hooks/useTranslations";

const modalTitleTranslations = {
  "Add New Template": "Add New Template",
  "Condition Details": "Condition Details",
} as const;

type ModalType = keyof typeof modalTitleTranslations;

interface PidNodeBackgroundFormProps {
  handleCreateSubmit?: (data: PidWorkflowsBackgroundNodeData) => void;
  handleEditSubmit?: (data: PidWorkflowsBackgroundNodeData) => void;
  data?: PidWorkflowsBackgroundNodeData;
  nodes: Node<PidWorkflowsBackgroundNodeData>[];
  handleSetUnsavedChanges: (unsavedChanges: boolean) => void;
  setUnsavedChanges: React.Dispatch<React.SetStateAction<boolean>>;
  unsavedChanges: boolean;
}

const PidNodeBackgroundForm: React.FC<PidNodeBackgroundFormProps> = ({
  handleCreateSubmit,
  handleEditSubmit,
  setUnsavedChanges,
  unsavedChanges,
  data,
}) => {
  const { t } = useLanguageContext();
  const theme = useTheme();
  const styles = { ...cssSpacingStyles(theme), ...cssLayoutStyles };
  const [openModal, setOpenModal] = useState<ModalType | null>(null);
  const getTranslatedModalTitle = useTranslatedModalTitle(modalTitleTranslations);
  const [file, setFile] = useState<FileWithPath | null>(null);
  const [alertMessage, setAlertMessage] = useState<string | null>(null);
  const [alertStatus, setAlertStatus] = useState<FormStatuses | null>(null);
  const [bgImageUrl, setBgImageUrl] = useState<string | null>(null);

  const nodeType = PID_FLOW_NODE_TYPE.Background;
  const nodeID = data?.id || uuidv4().split("-")[0];
  const { setAuthedUser } = useAuthedContext();

  useEffect(() => {
    (async () => {
      if (file) {
        setAlertMessage(null);
        setAlertStatus(null);
        try {
          setAlertMessage(t("Loading..."));
          setAlertStatus("loading");

          const resp = await callApi<PostQueryPidWorkflowUploadImageSnippet>({
            query: postQueryPidWorkflowUploadImage(nodeID, { file }),
            auth: { setAuthedUser },
          });
          if (resp.url) {
            setBgImageUrl(resp.url);
          }

          setAlertMessage(t("File uploaded successfully"));
          setAlertStatus("success");
        } catch (error) {
          setFile(null);
          console.log("err: ", error);
          setAlertMessage(t("File has not been processed - incorrect file data"));
          setAlertStatus("error");
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file]);

  const handleFormSubmit = () => {
    const current = new Date().toISOString();
    const result: PidWorkflowsBackgroundNodeData = {
      id: nodeID,
      type: nodeType,
      label: "",
      bgColor: theme.palette.background.paper,
      createdOn: current,
      updatedOn: current,
      mode: "Edit Mode",
      bgImageUrl,
    };

    if (handleCreateSubmit) {
      handleCreateSubmit(result);
    }
    if (handleEditSubmit) {
      handleEditSubmit(result);
    }
    setUnsavedChanges(false);
  };

  return (
    <Stack css={[styles.textBreak, styles.width100]} spacing={4}>
      <FileDropzone
        setFiles={(files) => setFile(files[0])}
        setErrorMessage={setAlertMessage}
      >
        <Stack alignItems="center" justifyContent="center">
          <Button
            disabled={alertStatus === "success" || alertStatus === "loading"}
            color="secondary"
          >
            {t("Upload Image")}
          </Button>
        </Stack>
      </FileDropzone>

      <Box component="div" css={styles.flexCenter}>
        <Button
          css={[styles.width100, styles.widthLimit20]}
          onClick={handleFormSubmit}
          disabled={alertStatus === "loading"}
        >
          {t("Save Changes")}
        </Button>
      </Box>

      <Alert
        message={alertMessage || ""}
        showAlert={!!alertMessage}
        severity={alertStatus}
      />

      <Modal
        onClose={() => setOpenModal(null)}
        open={!!openModal}
        fullWidth
        label={openModal ? getTranslatedModalTitle(openModal) : ""}
        unsavedChanges={unsavedChanges}
        setUnsavedChanges={setUnsavedChanges}
      ></Modal>
    </Stack>
  );
};

export default PidNodeBackgroundForm;
