import { IconButton, Stack, Typography } from "@mui/material";
import {
  GetQueryOEELossesSnippet,
  GetQueryOEEMappedCategoriesWithSubcategoriesSnippet,
} from "../../../Api/OEE/apiOEESnippets";
import ResponsiveTableGrid from "../../SmallComponents/TableGrid/ResponsiveTableGrid";
import { TableGridColumnSchema } from "../../SmallComponents/TableGrid/constructTableGrid";
import CellActionButton, {
  ModalLayoutData,
} from "../../SmallComponents/TableGrid/CellActionButton";
import { useState } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Modal from "../../MaterialUI/Modal";
import { capitalizeFirstLetterOfEachWord } from "../../../Global/Utils/commonFunctions";
import OEEAddLossForm from "./OEEAddLossForm";
import {
  AutocompleteGroupedOption,
  AutocompleteOption,
} from "../../../Global/Types/commonTypes";
import { ModalTitle } from "./oEEUtils";
import Button from "../../MaterialUI/Button";
import cssLayoutStyles from "../../../Global/Styles/layout";
import callApi from "../../../Api/callApi";
import { postQueryLossDelete } from "../../../Api/OEE/apiOEEPostQueries";
import { useAuthedContext } from "../../../context/AuthContext";
import { useTranslateFields } from "../../../Global/Hooks/useTranslations";
import { useLanguageContext } from "../../../context/LanguageContext";

const colSchema: TableGridColumnSchema[] = [
  { id: "asset", label: "Asset", type: "string" },
  { id: "type", label: "Type", type: "string" },
  { id: "maincategory", label: "Main Category", type: "string" },
  { id: "subcategory", label: "Subcategory", type: "string" },
  { id: "start_time", label: "Start", type: "dateTime" },
  { id: "end_time", label: "End", type: "dateTime" },
  { id: "note", label: "Explanation", type: "button" },
  { id: "actions", label: "Actions", type: "button" },
];

interface OEELossesTableProps {
  oEELosses: GetQueryOEELossesSnippet;
  assetTypeOptions: AutocompleteOption[];
  oEEMainCategoriesOptions: AutocompleteGroupedOption[];
  oEEMappedCategoriesWithSubcategories: GetQueryOEEMappedCategoriesWithSubcategoriesSnippet;
  setModalTitle: React.Dispatch<React.SetStateAction<ModalTitle | null>>;
  fetchOEEData: () => void;
}

const OEELossesTable: React.FC<OEELossesTableProps> = ({
  oEELosses,
  assetTypeOptions,
  oEEMainCategoriesOptions,
  oEEMappedCategoriesWithSubcategories,
  setModalTitle,
  fetchOEEData,
}) => {
  const { t } = useLanguageContext();
  const styles = { ...cssLayoutStyles };
  const { setAuthedUser } = useAuthedContext();
  const [openInfo, setOpenInfo] = useState<boolean>(false);
  const [modalData, setModalData] = useState<ModalLayoutData | null>(null);
  const [unsavedChanges, setUnsavedChanges] = useState<boolean>(false);

  const getModalLabel = (modalData: ModalLayoutData | null): string => {
    if (!modalData) return "";

    switch (modalData.type) {
      case "LossesForm":
        return t("Edit Loss");
      case "note":
        return t("Loss Notes");
      case "deleteForm":
        return t("Delete Loss");
      default:
        return "";
    }
  };

  const handleDeleteLoss = async (lossId: string) => {
    try {
      const deletedLoss = await callApi({
        query: postQueryLossDelete(lossId),
        auth: { setAuthedUser },
      });

      if (deletedLoss) {
        fetchOEEData();
        setOpenInfo(false);
      }
    } catch (err) {
      console.log("OEELossDelete err: ", err.message);
    }
  };

  const handleSetUnsavedChanges = (unsavedChanges: boolean) => {
    if (unsavedChanges) {
      setUnsavedChanges(true);
    }
  };

  return (
    <>
      {oEELosses ? (
        <ResponsiveTableGrid
          rows={handleFilterData(setOpenInfo, setModalData, oEELosses)}
          colSchema={useTranslateFields(colSchema, ["label"])}
          editMode={false}
          responsive="responsive"
          configuration={{
            density: "compact",
          }}
          tableID="OEELossesTable_table"
        />
      ) : (
        <Typography component="p" variant="body1">
          {t("Loading...")}
        </Typography>
      )}

      {modalData ? (
        <Modal
          open={openInfo}
          fullWidth
          label={getModalLabel(modalData)}
          onClose={() => setOpenInfo(false)}
          unsavedChanges={unsavedChanges}
          setUnsavedChanges={setUnsavedChanges}
        >
          {modalData.type === "note" ? modalData.data : null}
          {modalData.type === "LossesForm" ? (
            <OEEAddLossForm
              assetTypeOptions={assetTypeOptions}
              oEEMainCategoriesOptions={oEEMainCategoriesOptions}
              oEEMappedCategoriesWithSubcategories={oEEMappedCategoriesWithSubcategories}
              setModalTitle={setModalTitle}
              setOpenInfo={setOpenInfo}
              fetchOEEData={fetchOEEData}
              lossData={modalData.data.data}
              lossId={modalData.data.id}
              handleSetUnsavedChanges={handleSetUnsavedChanges}
              setUnsavedChanges={setUnsavedChanges}
            />
          ) : null}
          {modalData.type === "deleteForm" ? (
            <Stack spacing={2}>
              <Typography variant="body1">
                {t("Are you sure you want to delete this loss?")}
              </Typography>

              <Stack css={styles.width100} alignItems="center" justifyContent="center">
                <Button
                  css={styles.widthLimit20}
                  color="error"
                  onClick={() => handleDeleteLoss(modalData.data)}
                >
                  {t("Confirm Deletion")}
                </Button>
              </Stack>
            </Stack>
          ) : null}
        </Modal>
      ) : null}
    </>
  );
};

export default OEELossesTable;

const handleFilterData = (
  setOpenInfo: React.Dispatch<React.SetStateAction<boolean>>,
  setModalData: React.Dispatch<React.SetStateAction<ModalLayoutData | null>>,
  oEELosses: GetQueryOEELossesSnippet | undefined
) => {
  const { t } = useLanguageContext();
  if (!oEELosses) return [];

  return oEELosses.map((loss) => {
    return {
      asset: loss.asset,
      type: capitalizeFirstLetterOfEachWord(loss.type),
      maincategory: loss.maincategory,
      subcategory: loss.subcategory,
      start_time: loss.start_time,
      end_time: loss.end_time,
      note: (
        <CellActionButton
          text={t("View Note")}
          icon={<VisibilityIcon />}
          handleOnClick={() => {
            setOpenInfo(true);
            setModalData({ type: "note", data: loss.note });
          }}
        />
      ),
      actions: (
        <Stack direction="row" justifyContent="flex-end" alignItems="center">
          <IconButton
            onClick={() => {
              setOpenInfo(true);
              setModalData({ type: "LossesForm", data: { data: loss, id: loss.id } });
            }}
          >
            <EditIcon />
          </IconButton>
          <IconButton
            onClick={() => {
              setOpenInfo(true);
              setModalData({ type: "deleteForm", data: loss.id });
            }}
          >
            <DeleteIcon />
          </IconButton>
        </Stack>
      ),
    };
  });
};
