import { SerializedStyles, css } from "@emotion/react";
import { Box, Typography } from "@mui/material";
import {
  DynamicGridChartOptions,
  DynamicGridHandleSaveChartData,
} from "../../SmallComponents/DynamicGridLayout.tsx/dynamicGridUtils";
import EditAreaChartForm from "../AreaChart/EditAreaChartForm";
import EditPieChartForm from "../PieChart/EditPieChartForm";
import EditBarChartForm from "../BarChart/EditBarChartForm";
import EditRadarChartForm from "../RadarChart/EditRadarChartForm";
import EditExcellenceTableForm from "../ExcellenceTable/EditExcellenceTableForm";
import EditGaugeWidgetForm from "../GaugeWidget/EditGaugeWidgetForm";
import EditTextWidgetForm from "../TextWidget/EditTextWidgetForm";
import EditIndicatorWidgetForm from "../IndicatorWidget/EditIndicatorWidgetForm";
import { ExcellenceParameter } from "../../../GraphQL/Excellence/graphQLTypesExcellence";
import EditBoxPlotForm from "../AdvancedWidgets/BoxPlot/EditBoxPlotForm";
import EditHeatMapForm from "../AdvancedWidgets/HeatMap/EditHeatMapForm";
import { useLanguageContext } from "../../../context/LanguageContext";
import EditTimeChartForm from "../TimeChart/EditTimeChartForm";
import EditLineChartForm from "../LineChart/EditLineChartForm";

const cssStyles = {
  chart: css({
    height: "500px",
  }),
  gaugeChart: css({
    height: "400px",
    width: "100%",
  }),
};

interface EditDynamicChartFormProps {
  css?: SerializedStyles[] | SerializedStyles;
  className?: string;
  chart: DynamicGridChartOptions | undefined;
  handleSaveChanges: DynamicGridHandleSaveChartData;
  widgetTitle: string;
  parameters: { parameters: ExcellenceParameter[] };
  timeParameters: { parameters: ExcellenceParameter[] };
  handleSetUnsavedChanges: (unsavedChanges: boolean) => void;
  widgetID: string;
}

const EditDynamicChartForm: React.FC<EditDynamicChartFormProps> = ({
  className,
  chart,
  handleSaveChanges,
  widgetTitle,
  parameters,
  timeParameters,
  handleSetUnsavedChanges,
  widgetID,
}) => {
  const styles = { ...cssStyles };
  const { t } = useLanguageContext();

  if (!chart) {
    return (
      <Box component="div" className={className}>
        <Typography variant="h3" color="error">
          {t("Wrong Chart Data Provided")}
        </Typography>
      </Box>
    );
  }

  return (
    <Box component="div" className={className}>
      {chart?.lineChart ? (
        <EditLineChartForm
          chartClass={styles.chart}
          config={chart.lineChart.config}
          dataSchema={chart.lineChart.dataSchema || null}
          data={chart.lineChart.data || null}
          widgetTitle={widgetTitle}
          handleSaveChanges={handleSaveChanges}
          parameters={parameters}
          handleSetUnsavedChanges={handleSetUnsavedChanges}
        />
      ) : null}
      {chart?.timeChart ? (
        <EditTimeChartForm
          chartClass={styles.chart}
          config={chart.timeChart.config}
          dataSchema={chart.timeChart.dataSchema || null}
          data={chart.timeChart.data || null}
          widgetTitle={widgetTitle}
          handleSaveChanges={handleSaveChanges}
          parameters={timeParameters}
          dateTimeConfig={chart.timeChart?.dateTimeConfig || null}
          handleSetUnsavedChanges={handleSetUnsavedChanges}
        />
      ) : null}
      {chart?.areaChart ? (
        <EditAreaChartForm
          chartClass={styles.chart}
          config={chart.areaChart.config}
          dataSchema={chart.areaChart.dataSchema || null}
          data={chart.areaChart.data || null}
          widgetTitle={widgetTitle}
          handleSaveChanges={handleSaveChanges}
          parameters={parameters}
          handleSetUnsavedChanges={handleSetUnsavedChanges}
        />
      ) : null}
      {chart?.pieChart ? (
        <EditPieChartForm
          chartClass={styles.chart}
          config={chart.pieChart.config}
          dataSchema={chart.pieChart.dataSchema || null}
          data={chart.pieChart.data || null}
          widgetTitle={widgetTitle}
          handleSaveChanges={handleSaveChanges}
          parameters={parameters}
          handleSetUnsavedChanges={handleSetUnsavedChanges}
        />
      ) : null}
      {chart?.donutChart ? (
        <EditPieChartForm
          chartClass={styles.chart}
          config={chart.donutChart.config}
          dataSchema={chart.donutChart.dataSchema || null}
          data={chart.donutChart.data || null}
          widgetTitle={widgetTitle}
          handleSaveChanges={handleSaveChanges}
          parameters={parameters}
          isDonut
          handleSetUnsavedChanges={handleSetUnsavedChanges}
        />
      ) : null}
      {chart?.barChart ? (
        <EditBarChartForm
          chartClass={styles.chart}
          config={chart.barChart.config}
          dataSchema={chart.barChart.dataSchema || null}
          data={chart.barChart.data || null}
          widgetTitle={widgetTitle}
          handleSaveChanges={handleSaveChanges}
          parameters={parameters}
          handleSetUnsavedChanges={handleSetUnsavedChanges}
        />
      ) : null}
      {chart?.columnChart ? (
        <EditBarChartForm
          chartClass={styles.chart}
          config={chart.columnChart.config}
          dataSchema={chart.columnChart.dataSchema || null}
          data={chart.columnChart.data || null}
          widgetTitle={widgetTitle}
          handleSaveChanges={handleSaveChanges}
          parameters={parameters}
          isColumnChart
          handleSetUnsavedChanges={handleSetUnsavedChanges}
        />
      ) : null}
      {chart?.radarChart ? (
        <EditRadarChartForm
          chartClass={styles.chart}
          config={chart.radarChart.config}
          dataSchema={chart.radarChart.dataSchema || null}
          data={chart.radarChart.data || null}
          widgetTitle={widgetTitle}
          handleSaveChanges={handleSaveChanges}
          parameters={parameters}
          handleSetUnsavedChanges={handleSetUnsavedChanges}
        />
      ) : null}
      {chart?.table ? (
        <EditExcellenceTableForm
          chartClass={styles.chart}
          config={chart.table.config}
          dataSchema={chart.table.dataSchema || null}
          data={chart.table.data || null}
          widgetTitle={widgetTitle}
          handleSaveChanges={handleSaveChanges}
          parameters={parameters}
          handleSetUnsavedChanges={handleSetUnsavedChanges}
          widgetID={widgetID}
        />
      ) : null}
      {chart?.gaugeWidget ? (
        <EditGaugeWidgetForm
          chartClass={styles.gaugeChart}
          config={chart.gaugeWidget.config}
          dataSchema={chart.gaugeWidget.dataSchema || null}
          data={chart.gaugeWidget.data}
          widgetTitle={widgetTitle}
          handleSaveChanges={handleSaveChanges}
          parameters={parameters}
          handleSetUnsavedChanges={handleSetUnsavedChanges}
        />
      ) : null}
      {chart?.textWidget ? (
        <EditTextWidgetForm
          chartClass={styles.chart}
          config={chart.textWidget.config}
          schema={chart.textWidget.dataSchema || null}
          widgetTitle={widgetTitle}
          handleSaveChanges={handleSaveChanges}
          parameters={parameters}
          dataSchema={null}
          handleSetUnsavedChanges={handleSetUnsavedChanges}
        />
      ) : null}
      {chart?.indicatorWidget ? (
        <EditIndicatorWidgetForm
          chartClass={styles.chart}
          config={chart.indicatorWidget.config}
          dataSchema={chart.indicatorWidget.dataSchema || null}
          data={chart.indicatorWidget.data}
          widgetTitle={widgetTitle}
          handleSaveChanges={handleSaveChanges}
          parameters={parameters}
          handleSetUnsavedChanges={handleSetUnsavedChanges}
        />
      ) : null}

      {/* Advanced ----- */}

      {chart?.boxPlot ? (
        <EditBoxPlotForm
          chartClass={styles.chart}
          config={chart.boxPlot.config}
          dataSchema={chart.boxPlot.dataSchema || null}
          data={chart.boxPlot.data || null}
          widgetTitle={widgetTitle}
          handleSaveChanges={handleSaveChanges}
          parameters={parameters}
          handleSetUnsavedChanges={handleSetUnsavedChanges}
        />
      ) : null}

      {chart?.heatMap ? (
        <EditHeatMapForm
          chartClass={styles.chart}
          config={chart.heatMap.config}
          dataSchema={chart.heatMap.dataSchema || null}
          data={chart.heatMap.data || null}
          widgetTitle={widgetTitle}
          handleSaveChanges={handleSaveChanges}
          parameters={parameters}
          handleSetUnsavedChanges={handleSetUnsavedChanges}
        />
      ) : null}
    </Box>
  );
};

export default EditDynamicChartForm;
