import {
  ChartConfigFormValuesWithTitle,
  EMPTY_UNIT,
  unitsOfMeasureOptions,
} from "../EditExcellenceChartForms/excellenceChartFormUtils";
import { Grid, Typography, useTheme } from "@mui/material";
import TextField from "../../MaterialUI/FormFields/TextFields";
import { IndicatorWidgetConfiguration } from "./indicatorWidgetTypes";
import { AutocompleteOption, SelectOption } from "../../../Global/Types/commonTypes";
import RadioGroup from "../../MaterialUI/FormFields/RadioGroup";
import { handleGetSelectOption } from "../../../Global/Utils/commonFunctions";
import { UnitOfMeasure } from "../../../Api/Excellence/apiExcellenceSnippets";
import { useEffect, useState } from "react";
import Autocomplete from "../../MaterialUI/FormFields/Autocomplete";
import cssLayoutStyles from "../../../Global/Styles/layout";
import cssSpacingStyles from "../../../Global/Styles/spacing";
import Checkbox from "../../MaterialUI/FormFields/Checkbox";
import { useLanguageContext } from "../../../context/LanguageContext";

type KeyOfConfigValue =
  keyof ChartConfigFormValuesWithTitle<IndicatorWidgetConfiguration>;

interface EditIndicatorWidgetConfigProps {
  config: ChartConfigFormValuesWithTitle<IndicatorWidgetConfiguration>;
  setUpdatedConfig: React.Dispatch<
    React.SetStateAction<ChartConfigFormValuesWithTitle<IndicatorWidgetConfiguration>>
  >;
  unitsOfMeasure: UnitOfMeasure[] | null;
}

const EditIndicatorWidgetConfig: React.FC<EditIndicatorWidgetConfigProps> = ({
  config,
  setUpdatedConfig,
  unitsOfMeasure,
}) => {
  const theme = useTheme();
  const { t } = useLanguageContext();
  const unitsOptions = unitsOfMeasureOptions(unitsOfMeasure);
  const [selectedUnit, setSelectedUnit] = useState<AutocompleteOption | null>(
    getUnit(unitsOptions, config.unitsOfMeasure)
  );

  const styles = {
    ...cssSpacingStyles(theme),
    ...cssLayoutStyles,
  };

  useEffect(() => {
    const options = unitsOfMeasureOptions(unitsOfMeasure);
    setSelectedUnit(getUnit(options, config.unitsOfMeasure));
  }, [config.unitsOfMeasure, unitsOfMeasure]);

  const handleUpdateFormValue = (key: KeyOfConfigValue, value: string | boolean) => {
    setUpdatedConfig((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const handleOnUnitsChange = (option: AutocompleteOption | null) => {
    const unitId = option?.value;
    if (!unitId) {
      return;
    }

    handleUpdateFormValue("unitsOfMeasure", unitId === EMPTY_UNIT ? "" : unitId);

    const foundUnit = unitsOfMeasure?.find((unit) => unit.id === unitId);
    if (foundUnit) {
      handleUpdateFormValue("symbol", `(${foundUnit.symbol})`);
    } else {
      handleUpdateFormValue("symbol", "");
    }
  };

  return (
    <Grid css={styles.contentBreak} spacing={2} container>
      <Grid item xs={12} sm={6}>
        <TextField
          label={t("Title")}
          onChange={(e) => handleUpdateFormValue("title", e.target.value)}
          value={config.title}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          label={t("Subtitle")}
          onChange={(e) => handleUpdateFormValue("subtitle", e.target.value)}
          value={config.subtitle}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <Checkbox
          label={t("Fixed Decimal Value")}
          defaultChecked={!!config.fixedDecimalValue}
          onChange={(e) => handleUpdateFormValue("fixedDecimalValue", e.target.checked)}
          value={!!config.fixedDecimalValue}
        />
      </Grid>
      {config.fixedDecimalValue ? (
        <Grid item xs={12} sm={6}>
          <TextField
            label={t("Decimal Points")}
            onChange={(e) => handleUpdateFormValue("decimalPoints", e.target.value)}
            value={config.decimalPoints === undefined ? 0 : config.decimalPoints}
            numberField
            noThousandSeparator
          />
        </Grid>
      ) : (
        <Grid item xs={12} sm={6}></Grid>
      )}

      <Grid item xs={12} sm={6}>
        <RadioGroup
          aria-labelledby="avg-value"
          name="avg-value-radios"
          label={t("Choose value type")}
          onChange={(e) => handleUpdateFormValue("valueType", e.target.value)}
          radioOptions={handleGetSelectOption([t("Average"), t("Latest")])}
          value={config.valueType}
          defaultChecked
          defaultValue={config.valueType}
          row
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <Autocomplete
          label={t("Units of measure")}
          options={unitsOptions}
          handleOnChange={handleOnUnitsChange}
          value={selectedUnit}
          disabled={!unitsOfMeasure?.length}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <Typography variant="body2">With {config.tolerance}% tolerence</Typography>
      </Grid>
    </Grid>
  );
};

export default EditIndicatorWidgetConfig;

const getUnit = (unitsOptions: SelectOption[], unitsOfMeasure: string) => {
  return !unitsOptions.length
    ? null
    : unitsOfMeasure
    ? {
        value: unitsOfMeasure,
        description:
          unitsOptions.find((unit) => unit.value === unitsOfMeasure)?.description || "",
      }
    : null;
};
