import { Box, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import cssSpacingStyles from "../../Global/Styles/spacing";
import cssLayoutStyles from "../../Global/Styles/layout";
import MaterialsTable from "./MaterialsTable";
import { useAuthedContext } from "../../context/AuthContext";
import callApi from "../../Api/callApi";
import { getQueryMaterialsAndProducts } from "../../Api/SupplyChain/apiSupplyChainGetQueries";
import { GetQueryMaterialsAndProductsSnippet } from "../../Api/SupplyChain/apiSupplyChainSnippets";
import { useLanguageContext } from "../../context/LanguageContext";
import { useOutletContext } from "react-router-dom";
import { AppRouterProps } from "../../Layout/layoutVariables";
import PageTitle from "../../Components/SmallComponents/PageTitle/PageTitle";

const MaterialsPage: React.FC = () => {
  const { t } = useLanguageContext();
  const theme = useTheme();
  const styles = { ...cssSpacingStyles(theme), ...cssLayoutStyles };
  const [tableData, setTableData] = useState<GetQueryMaterialsAndProductsSnippet>();
  const [loading, setLoading] = useState<boolean>(true);

  const { setAuthedUser } = useAuthedContext();
  const { smMediaQuery, setExtraTopNavMenu } = useOutletContext<AppRouterProps>();

  useEffect(() => {
    if (smMediaQuery) {
      setExtraTopNavMenu(null);
    } else {
      setExtraTopNavMenu(<PageTitle title={t("Materials Management")} />);
    }

    return () => {
      setExtraTopNavMenu(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [smMediaQuery]);

  useEffect(() => {
    (async () => {
      try {
        const materials = await callApi<GetQueryMaterialsAndProductsSnippet>({
          query: getQueryMaterialsAndProducts("Material"),
          auth: { setAuthedUser },
        });

        setTableData(materials);
      } catch (error) {
        console.log("There was an error: ", error);
      }
      setLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box component="div">
      {smMediaQuery ? (
        <PageTitle css={styles.labelBreak} title={t("Materials Management")} />
      ) : null}

      <MaterialsTable tableData={tableData} loading={loading} />
    </Box>
  );
};

export default MaterialsPage;
