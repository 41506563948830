import { Query } from "../callApi";
import { PostQueryReportBugProposeAnImprovementInput } from "./apiReportBugProposeAnImpromementInputs";

export const postQueryReportBugProposeAnImprovement = (
  input: PostQueryReportBugProposeAnImprovementInput
): Query => ({
  endpoint: `bugs/bugs`,
  method: "POST",
  variables: input,
  multipartForm: true,
});
