import { Box, Grid, Stack, Typography, useTheme } from "@mui/material";
import {
  KanbanTask,
  KanbanTaskField,
  KanbanTemplateFieldType,
} from "../taskManagerUtils";
import { useEffect, useRef, useState } from "react";
import TextField from "../../../../Components/MaterialUI/FormFields/TextFields";
import DatePicker from "../../../../Components/MaterialUI/DateTimePickers/DatePicker";
import DateAndTimePicker from "../../../../Components/MaterialUI/DateTimePickers/DateAndTimePicker";
import TimePicker from "../../../../Components/MaterialUI/DateTimePickers/TimePicker";
import { FormStatuses, SelectOption } from "../../../../Global/Types/commonTypes";
import Alert from "../../../../Components/MaterialUI/Alert";
import cssLayoutStyles from "../../../../Global/Styles/layout";
import cssSpacingStyles from "../../../../Global/Styles/spacing";
import Button from "../../../../Components/MaterialUI/Button";
import { useAuthedContext } from "../../../../context/AuthContext";
import callApi, { Query } from "../../../../Api/callApi";
import { postQueryKanbanTaskUpdateFields } from "../../../KanbanWorkflows/api/mutations";
import { isValid } from "date-fns";
import Checkbox from "../../../../Components/MaterialUI/FormFields/Checkbox";
import Select from "../../../../Components/MaterialUI/FormFields/Select";
import { handleGetSelectOption } from "../../../../Global/Utils/commonFunctions";
import { useDetectFormsUnsavedChanges } from "../../../../Global/Hooks/useDetectFormsUnsavedChanges";
import { useLanguageContext } from "../../../../context/LanguageContext";

type StateValue = {
  data_id: string;
  value: string;
};
interface EditKanTaskFieldsProps {
  task: KanbanTask;
  onSubmit: () => Promise<void>;
  handleSetUnsavedChanges: (unsavedChanges: boolean) => void;
  setUnsavedChanges: React.Dispatch<React.SetStateAction<boolean>>;
}

const EditKanTaskFields: React.FC<EditKanTaskFieldsProps> = ({
  task,
  onSubmit,
  handleSetUnsavedChanges,
  setUnsavedChanges,
}) => {
  const { t } = useLanguageContext();
  const theme = useTheme();
  const styles = { ...cssSpacingStyles(theme), ...cssLayoutStyles };
  const [formStatus, setFormStatus] = useState<FormStatuses>(null);
  const [formAlert, setFormAlert] = useState<string | null>(null);
  const [allDateValues, setAllDateValues] = useState<StateValue[]>(
    handleGetFieldsData(task)
  );
  const initialValues = useRef(handleGetFieldsData(task)).current;

  const { setAuthedUser } = useAuthedContext();

  useDetectFormsUnsavedChanges(initialValues, allDateValues, handleSetUnsavedChanges);

  const handleChange = (field: KanbanTaskField, newValue: any) => {
    console.log(newValue);
    setAllDateValues((prev) =>
      prev.map((item) => {
        if (item.data_id === field.id) {
          return {
            ...item,
            value: newValue,
          };
        }
        return item;
      })
    );
  };

  const handleSubmit = async () => {
    try {
      setFormStatus("loading");
      setFormAlert(t("Saving data..."));

      await callApi<KanbanTask[]>({
        query: postQueryKanbanTaskUpdateFields(task.id, allDateValues),
        auth: { setAuthedUser },
      });

      await onSubmit();
      setFormStatus("success");
      setUnsavedChanges(false);
      setFormAlert(t("Changes successfully saved"));
    } catch (err) {
      setFormStatus("error");
      setFormAlert(t("Something went wrong"));
    }
  };

  return (
    <Box component="div">
      {task.tasks_data.length ? (
        <Box component="div">
          <Grid container spacing={3}>
            {task.tasks_data.map((field) => (
              <Grid xs={12} sm={6} item key={field.id}>
                <DynamicField
                  type={field.data_field.type}
                  label={field.data_field.name}
                  value={getSingleFieldValue(allDateValues, field)}
                  handleChange={(val) => handleChange(field, val)}
                  fieldURL={field.data_field.url || null}
                  readonly={!!field.data_field.readonly}
                />
              </Grid>
            ))}
          </Grid>

          <Stack
            css={styles.reverseContentBreak}
            spacing={3}
            alignItems="center"
            justifyContent="center"
          >
            <Box component="div">
              <Button
                loading={formStatus === "loading"}
                disabled={formStatus === "success"}
                onClick={handleSubmit}
              >
                {t("Save Changes")}
              </Button>
            </Box>

            <Alert
              css={[styles.widthLimit20]}
              message={formAlert || ""}
              showAlert={!!formAlert}
              severity={formStatus}
            />
          </Stack>
        </Box>
      ) : (
        <Typography variant="body1">
          {t("Task doesn't have any form attributes")}
        </Typography>
      )}
    </Box>
  );
};

export default EditKanTaskFields;

interface DynamicFieldProp {
  type: KanbanTemplateFieldType;
  label: string;
  value: any;
  fieldURL: string | null;
  handleChange: (val: any) => void;
  readonly: boolean;
}

const DynamicField: React.FC<DynamicFieldProp> = ({
  type,
  handleChange,
  label,
  fieldURL,
  value,
  readonly,
}) => {
  const { t } = useLanguageContext();
  const styles = { ...cssLayoutStyles };
  const [dropdownFetchStatus, setDropdownFetchStatus] = useState<FormStatuses>(null);
  const [dropdownFetchAlert, setDropdownFetchAlert] = useState<string | null>(null);
  const [dropdownOptions, setDropdownOptions] = useState<SelectOption[]>([]);
  const { setAuthedUser } = useAuthedContext();

  const dateValue = value && isValid(new Date(value)) ? new Date(value) : null;

  useEffect(() => {
    (async () => {
      try {
        if (type !== "dropdown" || !fieldURL) {
          return;
        }
        setDropdownFetchStatus("loading");
        setDropdownFetchAlert(null);

        const urlToUse = fieldURL.split("/api/v1/");
        const extraQuery: Query = {
          endpoint: urlToUse[1],
          method: "GET",
        };
        const dropdownData = await callApi<string[]>({
          query: extraQuery,
          auth: { setAuthedUser },
        });

        setDropdownOptions(handleGetSelectOption(dropdownData));
        setDropdownFetchStatus(null);
        setDropdownFetchAlert(null);
      } catch (err) {
        setDropdownFetchStatus("error");
        setDropdownFetchAlert(t("Could not fetch dropdown data"));
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  switch (type) {
    case "string": {
      return (
        <TextField
          fullWidth
          label={label}
          value={value}
          onChange={(e) => handleChange(e.target.value)}
          disabled={readonly}
        />
      );
    }
    case "date": {
      return (
        <DatePicker
          css={styles.width100}
          label={label}
          value={dateValue}
          onChange={(val) => handleChange(val)}
          disabled={readonly}
        />
      );
    }
    case "datetime": {
      return (
        <DateAndTimePicker
          css={styles.width100}
          label={label}
          value={dateValue}
          onChange={(val) => handleChange(val)}
          disabled={readonly}
        />
      );
    }
    case "time": {
      return (
        <TimePicker
          css={styles.width100}
          label={label}
          value={dateValue}
          onChange={(val) => handleChange(val)}
          disabled={readonly}
        />
      );
    }
    case "float": {
      return (
        <TextField
          fullWidth
          label={label}
          value={value}
          onChange={(e) => handleChange(e.target.value)}
          numberField
          disabled={readonly}
        />
      );
    }
    case "int": {
      return (
        <TextField
          fullWidth
          label={label}
          value={value}
          onChange={(e) => handleChange(e.target.value)}
          numberField
          disabled={readonly}
        />
      );
    }
    case "boolean": {
      return (
        <Checkbox
          label={label}
          value={Boolean(value)}
          checked={Boolean(value)}
          onChange={() => handleChange(!Boolean(value))}
          disabled={readonly}
        />
      );
    }
    case "dropdown": {
      return (
        <Stack style={{ width: "100%" }} spacing={2}>
          <Select
            fullWidth
            label={label}
            value={value || ""}
            onChange={(e) => handleChange(e.target.value)}
            selectOptions={dropdownOptions}
            disabled={readonly || dropdownFetchStatus === "loading"}
          />

          {!dropdownFetchAlert ? null : (
            <Alert
              message={dropdownFetchAlert || ""}
              showAlert={!!dropdownFetchAlert}
              severity={dropdownFetchStatus}
            />
          )}
        </Stack>
      );
    }
  }
};

const handleGetFieldsData = (task: KanbanTask): StateValue[] => {
  return task.tasks_data.map((item) => ({
    data_id: item.id,
    value: item.data_value,
  }));
};

const getSingleFieldValue = (allValues: StateValue[], field: KanbanTaskField) => {
  const result = allValues.find((item) => item.data_id === field.id);
  return result?.value;
};
